import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectComponet, Spinner } from '../../../../../../Componentes';
import { getTranslate } from '../../../../../../Helpers';
import { GetLookupItem } from '../../../../../../Serviecs';
import { TripsFeaturesEnum } from '../../../../../../Enums';
import { FeaturesManagementDialog } from './FeaturesUtilities/FeaturesManagementDialog';
import './FeaturesComponent.scss';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@material-ui/core';

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};
const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

const FeaturesComponent = (props) => {
  const [openCreateItemDialog, setOpenCreateItemDialog] = useState(false);
  const [checked, setChecked] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [selectedFeature, setSelectedFeature] = useState();
  const [featureResponse, setFeatureResponse] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [rightGroup, setRightGroup] = useState([]);
  const [leftGroup, setLeftGroup] = useState([]);
  const [isType, setIsType] = useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    if (props.state && props.state.tripFeatures && props.state.tripFeatures[0]) {
      props.state.tripFeatures.map((item) =>
        item.lookupItemId ? null : (item.lookupItemId = item.lookupsId)
      );
      setRight(props.state.tripFeatures);
    }
  }, [props.state]);

  const GetLookupFeature = useCallback(async () => {
    const res = await GetLookupItem(TripsFeaturesEnum && TripsFeaturesEnum.FeatureType.id);
    setFeatureResponse(res);
  }, []);
  const GetFeatureType = useCallback(async () => {
    setIsLoading(true);
    const res = await GetLookupItem(
      TripsFeaturesEnum && TripsFeaturesEnum.FeatuerDetails.id,
      selectedType
    );
    setLeft(res.filter((el) => right.findIndex((f) => f.lookupItemId === el.lookupItemId) === -1));
    setSelectedItem(featureResponse.filter((el) => el.lookupItemId === selectedType));
    setIsLoading(false);
  }, [featureResponse, right, selectedType]);

  useEffect(() => {
    GetLookupFeature();
  }, [GetLookupFeature]);
  useEffect(() => {
    if (selectedType) GetFeatureType();
  }, [GetFeatureType, selectedType]);

  useEffect(() => {
    if (right) {
      const list = [];
      right.map((item) => {
        if (
          list.findIndex(
            (i) => i.parentLookupItemId !== null && i.parentLookupItemId === item.parentLookupItemId
          ) === -1
        )
          list.push({
            parentLookupItemId: item.parentLookupItemId,
            parentLookupItemName: item.parentLookupItemName,
          });
        return null;
      });
      setRightGroup(list);
    }
  }, [props, right]);
  useEffect(() => {
    const list = [];
    left.map((item) => {
      if (
        list.findIndex(
          (i) => i.parentLookupItemId !== null && i.parentLookupItemId === item.parentLookupItemId
        ) === -1
      )
        list.push({
          parentLookupItemId: item.parentLookupItemId,
          parentLookupItemName: item.parentLookupItemName,
        });
      return null;
    });
    setLeftGroup(list);
  }, [left]);

  const handleAllRight = () => {
    setRight(right.concat(left));
    if (props.globalRightChanged) props.globalRightChanged(right.concat(left));
    setLeft([]);
    props.setState({
      id: props.featuresIdsInput,
      value: right.concat(left).map((el) => el.lookupItemId),
    });
    props.setState({
      id: 'tripFeatures',
      value: right.concat(left),
    });
  };
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    if (props.globalRightChanged) props.globalRightChanged(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    props.setState({
      id: props.featuresIdsInput,
      value: right.concat(leftChecked).map((el) => el.lookupItemId),
    });
    props.setState({
      id: 'tripFeatures',
      value: right.concat(leftChecked),
    });
  };
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    if (props.globalRightChanged) props.globalRightChanged(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    props.setState({
      id: props.featuresIdsInput,
      value: not(right, rightChecked).map((el) => el.lookupItemId),
    });
    props.setState({
      id: 'tripFeatures',
      value: not(right, rightChecked),
    });
  };
  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    if (props.globalRightChanged) props.globalRightChanged([]);
    props.setState({ id: props.featuresIdsInput, value: [] });
    props.setState({ id: 'tripFeatures', value: [] });
  };
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const updateLocalRight = useCallback(() => {
    setRight(props.globalRight);
  }, [props.globalRight]);
  useEffect(() => {
    if (props.globalRight) updateLocalRight();
  }, [props.globalRight, updateLocalRight]);
  const customList = (items, isRight) => (
    <div className="features-wrapper padding-feature">
      <Paper>
        <List className="feature-container">
          {isRight &&
            rightGroup &&
            rightGroup.map((value) => (
              <>
                <ListSubheader className="list-subheader">
                  {value && value.parentLookupItemName}
                </ListSubheader>

                {items
                  .filter((f) => f.parentLookupItemId === value.parentLookupItemId)
                  .map((item, index) => (
                    <ListItem
                      key={`rightFeatureRef${index + 1}`}
                      role="listitem"
                      button
                      onClick={handleToggle(item)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={checked.indexOf(item) !== -1}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText id={item.lookupItemId} primary={item.lookupItemName} />
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedFeature(item);
                          setIsEdit(true);
                          setOpenCreateItemDialog(true);
                        }}
                      >
                        <span className="mdi mdi-pencil" />
                      </IconButton>
                    </ListItem>
                  ))}
              </>
            ))}

          {!isRight &&
            leftGroup &&
            leftGroup.map((value) => (
              <>
                <ListSubheader className="list-subheader">
                  {value && value.parentLookupItemName}
                </ListSubheader>
                {items
                  .filter((f) => f.parentLookupItemId === value.parentLookupItemId)
                  .map((item, index) => (
                    <ListItem
                      key={`leftFeatureRef${index + 1}`}
                      role="listitem"
                      button
                      onClick={handleToggle(item)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={checked.indexOf(item) !== -1}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText id={item.lookupItemId} primary={item.lookupItemName} />
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedFeature(item);
                          setIsType(false);
                          setIsEdit(true);
                          setOpenCreateItemDialog(true);
                        }}
                      >
                        <span className="mdi mdi-pencil" />
                      </IconButton>
                    </ListItem>
                  ))}
                <ListItem className="last-item" />
              </>
            ))}
          {selectedType && !isRight && (
            <ListItem className="feature-button">
              <Button
                size="small"
                className="btns theme-solid"
                onClick={() => {
                  setIsEdit(false);
                  setIsType(false);
                  setOpenCreateItemDialog(true);
                }}
              >
                {getTranslate()(`${props.translationPath}add-feature-details`)}
              </Button>
            </ListItem>
          )}
        </List>
      </Paper>
    </div>
  );

  return (
    <div className="features-wrapper">
      <div className="json-form-wrapper padding-feature">
        <Spinner isActive={isLoading} />
        <div className="header-items-wrapper">
          {props.featureDescription && (
            <div className="header-item mb-3">
              <span className="d-flex w-100 mb-2 fw-bold c-black-light">
                {getTranslate()(`${props.translationPath}features`)}
              </span>
              <span className="d-flex w-100">
                {getTranslate()(`${props.translationPath}${props.featureDescription}`)}
              </span>
            </div>
          )}
          <div className="header-item">
            <Typography>{getTranslate()(`${props.translationPath}feature-type`)}</Typography>
            <SelectComponet
              idRef="featureTypeRef"
              data={featureResponse}
              valueInput="lookupItemId"
              textInput="lookupItemName"
              value={selectedType}
              onSelectChanged={(e) => setSelectedType(e.target.value)}
            />
          </div>
          <div className="header-item">
            <Button
              className="btns theme-solid"
              onClick={() => {
                setIsEdit(false);
                setIsType(true);
                setOpenCreateItemDialog(true);
              }}
            >
              <span className="mdi mdi-plus" />{' '}
              {getTranslate()(`${props.translationPath}add-feature`)}
            </Button>
          </div>
        </div>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item>{customList(left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" className="features-buttons">
              <Button
                size="small"
                className="btns"
                onClick={handleAllRight}
                disabled={left.length === 0}
                aria-label="move all right"
              >
                <span className="mdi mdi-chevron-double-right" />
              </Button>
              <Button
                size="small"
                className="btns"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                <span className="mdi mdi-chevron-right" />
              </Button>
              <Button
                size="small"
                className="btns"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                <span className="mdi mdi-chevron-left" />
              </Button>
              <Button
                size="small"
                className="btns"
                onClick={handleAllLeft}
                disabled={right.length === 0}
                aria-label="move all left"
              >
                <span className="mdi mdi-chevron-double-left" />
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(right, true)}</Grid>
        </Grid>
        <FeaturesManagementDialog
          setLoading={setIsLoading}
          isType={isType}
          open={openCreateItemDialog}
          setOpen={setOpenCreateItemDialog}
          translationPath={props.translationPath}
          reloadData={() => {
            if (isType && !isEdit) {
              GetLookupFeature();
            } else {
              GetFeatureType();
            }
          }}
          selectedItem={selectedItem}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          selectedFeature={selectedFeature}
        />
      </div>
    </div>
  );
};

export { FeaturesComponent };
FeaturesComponent.propTypes = {
  featuresIdsInput: PropTypes.string,
  featureDescription: PropTypes.string,
  globalRight: PropTypes.instanceOf(Array),
  globalRightChanged: PropTypes.func,
};
FeaturesComponent.defaultProps = {
  featuresIdsInput: 'tripFeaturesIds',
  featureDescription: undefined,
  globalRight: undefined,
  globalRightChanged: undefined,
};
