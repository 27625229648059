/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './OfferToursCardComponent.scss';
import { ButtonBase } from '@material-ui/core';
import { getDownloadableLink, getTranslate } from '../../../../../../../../Helpers';
import { LoadableImageComponant, Spinner } from '../../../../../../../../Componentes';
import { DefaultImagesEnum, LoadableImageEnum } from '../../../../../../../../Enums';
import { getLanguage } from 'react-switch-lang';

export const OfferToursCardComponent = ({
  data,
  draggable,
  onCardClicked,
  onLoadMore,
  isLoading,
  translationPath,
  onDrag,
  isFullWidth,
  filterData,
  filterInput,
  keyRef,
  dragEnd,
  touchEnd,
  onTouchMove,
  onDragOver,
  dragFrom,
  currentDragOverItem,
  onDragItem,
  onRemoveTourClicked,
  loadingEachTour,
}) => {
  const delayTimer = useRef(null);
  const cardContainer = useRef(null);
  const dragHandler = useCallback(
    (item, index) => (event) => {
      if (onDrag) onDrag(item, index, event);
    },
    [onDrag]
  );
  const dragOverHandler = useCallback(
    (item, index) => (event) => {
      if (onDragOver) onDragOver(item, index, event);
    },
    [onDragOver]
  );

  const getIsFiltered = useCallback(
    (currentItem) => {
      return (
        filterData &&
        filterInput &&
        filterData.findIndex((item) => item && item[filterInput] === currentItem[filterInput]) !==
          -1
      );
    },
    [filterData, filterInput]
  );
  const isActiveHandler = useCallback(
    (tourId) => {
      return (
        (loadingEachTour && loadingEachTour.findIndex((element) => element === tourId) !== -1) ||
        false
      );
    },
    [loadingEachTour]
  );
  const removeOfferTourHandler = useCallback(
    (index) => () => {
      if (onRemoveTourClicked) onRemoveTourClicked(index);
    },
    [onRemoveTourClicked]
  );
  // useEventListener('scroll', onScrollHandler);
  useEffect(() => {
    if (
      data &&
      data.result &&
      data.result.length < data.totalCount &&
      dragFrom === 'tour' &&
      onLoadMore &&
      cardContainer &&
      cardContainer.current &&
      cardContainer.current.parent &&
      cardContainer.current.parent.clientHeight > 200 &&
      cardContainer.current.parent.scrollHeight <= cardContainer.current.parent.clientHeight &&
      !isLoading
    )
      onLoadMore();
  }, [data, dragFrom, isLoading, onLoadMore]);

  useEffect(
    () => () => {
      if (delayTimer.current) clearTimeout(delayTimer.current);
    },
    []
  );
  return (
    <div
      className={`offer-tour-card-component-wrapper${(dragFrom === 'offer' && ' is-offer') || ''}`}
      ref={cardContainer}>
      {data &&
        data.result &&
        data.result.map((item, index) => (
          <React.Fragment key={`${keyRef}${index + 1}`}>
            {!getIsFiltered(item) && (
              <div className={`offer-tour-card-wrapper${(isFullWidth && ' w-100') || ''}`}>
                {currentDragOverItem &&
                  dragFrom === 'offer' &&
                  index === currentDragOverItem.index &&
                  (currentDragOverItem.dragOver !== onDragItem.from ||
                    (currentDragOverItem.dragOver === onDragItem.from &&
                      currentDragOverItem.index - 1 !== onDragItem.index &&
                      currentDragOverItem.index !== onDragItem.index)) && (
                    <span
                      className={`drop-navigator${
                        (currentDragOverItem.isBefore && ' is-drop') || ''
                      }`}>
                      {getTranslate()(`${translationPath}drop-here`)}
                    </span>
                  )}
                <a
                  draggable={draggable}
                  onClick={onCardClicked && onCardClicked(item, index)}
                  className={`cards-wrapper${(draggable && ' is-draggable') || ''}`}
                  data-id={item.tourId}
                  data-from={dragFrom}
                  onDrag={(draggable && dragHandler(item, index)) || undefined}
                  onDragOver={(draggable && dragOverHandler(item, index)) || undefined}
                  onTouchStart={(draggable && dragHandler(item, index)) || undefined}
                  onTouchMove={(draggable && onTouchMove(item, index)) || undefined}
                  onDragEnd={(draggable && dragEnd) || undefined}
                  onTouchEnd={(draggable && touchEnd) || undefined}>
                  {dragFrom === 'offer' && (
                    <ButtonBase
                      className='btns-icon theme-transparent c-danger p-absolute-t-2-r-2'
                      onClick={removeOfferTourHandler(index)}>
                      <span className='mdi mdi-close c-danger'></span>
                    </ButtonBase>
                  )}
                  <div className='cards-body'>
                    <div className='d-flex flex-wrap'>
                      <div className='item-wrapper w-100 mxw-calc'>
                        <span className='item-header px-2'>{item.tourName}</span>
                      </div>
                      <div className='w-100 p-relative d-flex flex-wrap'>
                        <Spinner isActive={isActiveHandler(item.tourId)} isAbsolute />
                        <div className='item-wrapper'>
                          <span className='item-header'>
                            <span className='mdi mdi-road-variant px-2' />
                          </span>
                          <span className='item-body'>{item.totalDestinations}</span>
                        </div>
                        <div className='item-wrapper'>
                          <span className='item-header'>
                            <span className='mdi mdi-clock-time-five px-2' />
                          </span>
                          <span className='item-body'>
                            <span>{item.totalDuration}</span>
                          </span>
                        </div>
                        <div className='item-wrapper'>
                          <span className='item-header'>
                            <span className='mdi mdi-cash-multiple px-2' />
                          </span>
                          <span className='item-body'>{item.discountPrice}</span>
                        </div>
                        <div className='item-wrapper'>
                          <span className='item-header'>
                            <span className='mdi mdi-counter px-2' />
                          </span>
                          <span className='item-body'>
                            <span>{(item.tripsTours && item.tripsTours.length) || 0}</span>
                            <span className='px-1'>
                              {getTranslate()(`${translationPath}trips`)}
                            </span>
                          </span>
                        </div>
                        <div className='item-wrapper pt-3 px-3'>
                          {item.tripsTours && item.tripsTours.length > 0 && (
                            <div className='slide-images mb-0'>
                              {item.tripsTours.map((image, index) => (
                                <LoadableImageComponant
                                  classes='slide-image'
                                  key={`tripImageSliderRef${index + 1}`}
                                  type={LoadableImageEnum.div.key}
                                  alt={getTranslate()(`${translationPath}trip-cover-image`)}
                                  src={
                                    (image.coverTripImageId &&
                                      getDownloadableLink(image.coverTripImageId)) ||
                                    DefaultImagesEnum.buildings.defaultImg
                                  }
                                  style={{
                                    zIndex: item.tripsTours.length - index,
                                    transform: `translateX(${
                                      (getLanguage() === 'ar' &&
                                        -(index * (100 / item.tripsTours.length))) ||
                                      index * (100 / item.tripsTours.length)
                                    }%)`,
                                  }}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                {currentDragOverItem &&
                  dragFrom === 'offer' &&
                  index === currentDragOverItem.index &&
                  (currentDragOverItem.dragOver !== onDragItem.from ||
                    (currentDragOverItem.dragOver === onDragItem.from &&
                      currentDragOverItem.index + 1 !== onDragItem.index &&
                      currentDragOverItem.index !== onDragItem.index)) && (
                    <span
                      className={`drop-navigator after${
                        (!currentDragOverItem.isBefore && ' is-drop') || ''
                      }`}>
                      {getTranslate()(`${translationPath}drop-here`)}
                    </span>
                  )}
              </div>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

OfferToursCardComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  loadingEachTour: PropTypes.arrayOf(PropTypes.number),
  currentDragOverItem: PropTypes.shape({
    index: PropTypes.number,
    isBefore: PropTypes.bool,
    dragOver: PropTypes.string,
  }),
  onDragItem: PropTypes.shape({
    item: PropTypes.instanceOf(Object),
    index: PropTypes.number,
    from: PropTypes.string,
  }),
  draggable: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onCardClicked: PropTypes.func,
  isLoading: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  translationPath: PropTypes.string,
  filterInput: PropTypes.string,
  filterData: PropTypes.instanceOf(Array),
  keyRef: PropTypes.string,
  dragFrom: PropTypes.string,
  dragEnd: PropTypes.func,
  onDrag: PropTypes.func,
  touchEnd: PropTypes.func,
  onTouchMove: PropTypes.func,
  onRemoveTourClicked: PropTypes.func,
  onDragOver: PropTypes.func,
};
OfferToursCardComponent.defaultProps = {
  translationPath: 'home.tours.offeredToursView.offerToursCardComponent.',
  loadingEachTour: [],
  draggable: false,
  isFullWidth: false,
  onCardClicked: undefined,
  onLoadMore: undefined,
  isLoading: undefined,
  filterData: undefined,
  filterInput: undefined,
  keyRef: 'offerToursCardRef',
  onDrag: undefined,
  dragEnd: undefined,
  dragFrom: undefined,
  touchEnd: undefined,
  onTouchMove: undefined,
  currentDragOverItem: undefined,
  onDragItem: undefined,
  onRemoveTourClicked: undefined,
  onDragOver: undefined,
};
