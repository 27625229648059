import React, { useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import { ViewTypesEnum } from '../../Enums';

const ViewTypes = ({ onTypeChanged, activeTypes }) => {
  const [activeType, setActiveType] = useState(ViewTypesEnum.cards.key);
  const viewTypeClicked = useCallback(
    (actionType) => {
      if (actionType !== activeType) {
        setActiveType(actionType);
        onTypeChanged(actionType);
      }
    },
    [onTypeChanged, activeType]
  );
  const getViewTypesValue = (key) => Object.values(ViewTypesEnum).find((item) => item.key === key);
  return (
    <div className='view-types-wrapper'>
      {activeTypes.map((item) => (
        <Button
          className={`btns-view-type${activeType === getViewTypesValue(item).key ? ' active' : ''}`}
          key={getViewTypesValue(item).key}
          onClick={() => viewTypeClicked(getViewTypesValue(item).key)}>
          <span className={getViewTypesValue(item).classes} />
        </Button>
      ))}
    </div>
  );
};
ViewTypes.propTypes = {
  onTypeChanged: PropTypes.func,
  activeTypes: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(ViewTypesEnum).map((item) => item.key))
  ),
};
ViewTypes.defaultProps = {
  onTypeChanged: undefined,
  activeTypes: Object.values(ViewTypesEnum).map((item) => item.key),
};
export { ViewTypes };
