import React from 'react';
import { DeleteRoleByRolesId } from '../../../../../../Serviecs/Roleservices/roleServices';
import { Button, DialogTitle, DialogActions, Dialog } from '@material-ui/core';
import { getTranslate, showSuccess } from '../../../../../../Helpers';

const DeleteRoleDialog = (props) => {
  const handleDeleteButton = async () => {
    await DeleteRoleByRolesId(props.deletedId);
    props.close();
    props.reloadData();
    showSuccess(getTranslate()('Administration.Roles.DeleteDialog.NotificationDeleteGroup'));
  };

  return (
    <Dialog open={props.open} aria-labelledby='form-dialog-title' className='Dialogteam'>
      <DialogTitle id='form-dialog-title'>
        {getTranslate()('Administration.Roles.DeleteDialog.DeleteText')} {props.name}?
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => props.close()}
          color='primary'
          className='btns theme-solid bg-cancel'>
          {getTranslate()('Administration.Roles.DeleteDialog.Cancel')}
        </Button>
        <Button className='btns theme-solid' onClick={handleDeleteButton} variant='contained'>
          {getTranslate()('Administration.Roles.DeleteDialog.Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRoleDialog;
