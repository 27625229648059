export const TourStatusEnum = {
  booked: {
    key: 'booked',
    value: 'booked',
    color: 'c-danger',
    bgColor: 'bg-danger',
  },
  notBooked: {
    key: 'notBooked',
    value: 'not-booked',
    color: 'c-secondary',
    bgColor: 'bg-secondary',
  },
};
