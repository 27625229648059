import React, { useState, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import { CreateSection, UpdateSection, GetSectionInfoById } from '../../../../../Serviecs';
import { useTitle } from '../../../../../Hooks/useTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { SectionViewDragDrop } from './SectionViewDragDrop';
import { Inputs, SelectComponet, Spinner } from '../../../../../Componentes';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import {
  getErrorByName,
  getHistory,
  GetParams,
  getTranslate,
  showSuccess,
} from '../../../../../Helpers';
import {
  FilterTypeEnum,
  MobileDesignTypeEnum,
  WebDesignTypeEnum,
  PublishTypeEnumvalue,
} from '../../../../../Enums';
import {
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
  Popover,
  FormGroup,
  FormControl,
  InputAdornment,
  FormControlLabel,
} from '@material-ui/core';
import Joi from 'joi';
import moment from 'moment';
import './SectionViewManagement.scss';
const translationPath = 'SectionView.';

export const SectionViewManagement = () => {
  const [editId, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCustome, setIsCustome] = useState(false);
  const [activeCustomIndex, setActiveCustomIndex] = useState(null);
  const [alwaysShow, setAlwaysShow] = useState(false);
  const [mobileShow, setMobileShow] = useState(false);
  const [webShow, setWebShow] = useState(false);
  const [isBoth, setIsBoth] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState({
    applicationSectionTours: [],
    applicationSectionTypes: [],
  });
  const open = Boolean(anchorEl);
  const [dateState, setDateState] = useState([
    {
      startDate: moment().toDate(),
      endDate: moment().add(7, 'd').toDate(),
      key: 'selection',
    },
  ]);
  const [state, setState] = useState({
    name: '',
    startDate: moment(dateState[0].startDate).format(),
    endDate: moment(dateState[0].endDate).format(),
    mobileDesign: 0,
    webDesign: 0,
    description: '',
    isPublish: false,
    orderWeb: 0,
    publishType: 1,
    orderMobile: 0,
    applicationSectionFilters: [
      {
        numberOfTours: '',
        filterType: 0,
      },
    ],
  });
  useTitle(
    getTranslate()(
      `${
        editId !== null ? `${translationPath}update-section` : `${translationPath}add-new-section`
      }`
    )
  );

  const schema = Joi.object({
    name: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}section-name-is-required`),
      }),
    description: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}description-is-required`),
      }),
    applicationSectionFilters: Joi.array()
      .items({
        numberOfTours: Joi.number()
          .required()
          .messages({
            'number.base': getTranslate()(`${translationPath}num-of-tour-is-required`),
          }),
      })
      .options({
        abortEarly: false,
        allowUnknown: true,
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const result = state.applicationSectionFilters.findIndex((item) => item.filterType === 5);
    if (result === -1 && isCustome) setIsCustome(false);
  }, [state, isCustome]);

  const saveHandeler = async () => {
    setIsSubmitted(true);
    const res = await CreateSection(state);
    if (res) {
      getHistory().push('/home/sections');
      showSuccess(getTranslate()(`${translationPath}section-created-successfully`));
    }
  };

  const getSectionInfoById = async (id) => {
    setIsLoading(true);
    const result = await GetSectionInfoById(id);
    setState(result);
    setValue(
      result.applicationSectionFilters[
        result &&
          result.applicationSectionFilters.findIndex(
            (item) =>
              item.applicationSectionTours.length > 0 || item.applicationSectionTours.length > 0
          )
      ]
    );
    setIsBoth(result.webDesign > 0 && result.mobileDesign > 0 ? true : false);
    setWebShow(result.webDesign > 0 ? true : false);
    setMobileShow(result.mobileDesign > 0 ? true : false);
    setAlwaysShow(result.startDate === null || result.endDate === null ? true : false);
    setIsLoading(false);
  };

  useEffect(() => {
    const editId = GetParams('id');
    if (editId !== null) {
      setId(+editId);
      getSectionInfoById(editId);
    }
  }, []);

  const editHandler = async () => {
    setIsSubmitted(true);
    const res = await UpdateSection(editId, state);
    if (res) {
      getHistory().push('/home/sections');
      showSuccess(getTranslate()(`${translationPath}section-updated-successfully`));
    }
  };

  return (
    <div className='view-wrapper sections-management-wrapper px-2'>
      <div className='header-section'>
        <Spinner isActive={isLoading} />
        <div className='filter-section px-2'>
          <div className='section'>
            {editId !== null ? (
              <Button className='btns theme-solid' onClick={editHandler}>
                {getTranslate()(`${translationPath}update-section`)}
              </Button>
            ) : (
              <Button className='btns theme-solid' onClick={saveHandeler}>
                {getTranslate()(`${translationPath}save`)}
              </Button>
            )}
            <Button
              className='btns theme-solid bg-cancel'
              onClick={() => {
                getHistory().push('/home/sections');
              }}>
              {getTranslate()(`${translationPath}cancel`)}
            </Button>
          </div>
        </div>
      </div>
      <div className='sections-management-content px-3'>
        <div className={`sections-content-postion ${isCustome ? 'is-left' : 'is-center'}`}>
          {getTranslate()(`${translationPath}section-information`)}
          <div className='content-subtitle'>
            {getTranslate()(`${translationPath}section-subtitle`)}
          </div>
          <div className='content-wrapper'>
            <div className='form-rows'>
              <div className='form-row'>
                <div className='form-item'>
                  <Inputs
                    inputPlaceholder='section-name'
                    labelValue='section-name'
                    idRef='sectionNameRef'
                    value={state.name}
                    helperText={getErrorByName(schema, 'name').message}
                    error={getErrorByName(schema, 'name').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    onInputChanged={(event) => setState({ ...state, name: event.target.value })}
                    translationPath={translationPath}
                  />
                </div>
              </div>
              <div className='form-row'>
                <div className='form-item'>
                  <FormControl>
                    <div>{getTranslate()(`${translationPath}show-in`)}</div>
                    <FormGroup className='checkbox-form'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={webShow}
                            onChange={(e) => {
                              setWebShow(!webShow);
                              if (e.target.checked === false) setIsBoth(false);
                              if (e.target.checked === true)
                                setState({ ...state, publishType: PublishTypeEnumvalue.Web.value });
                              if (mobileShow === true)
                                setState({ ...state, publishType: PublishTypeEnumvalue.Mobile.value });
                              if (e.target.checked && mobileShow) {
                                setIsBoth(true);
                                setState({ ...state, publishType: PublishTypeEnumvalue.Both.value });
                              }
                            }}
                            name='websiteDesign'
                          />
                        }
                        label={getTranslate()(`${translationPath}website`)}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={mobileShow}
                            onChange={(e) => {
                              setMobileShow(!mobileShow);
                              if (e.target.checked === false) {
                                setIsBoth(false);
                                setState({ ...state, publishType: PublishTypeEnumvalue.Web.value });
                              }
                              if (e.target.checked === true)
                                setState({ ...state, publishType: PublishTypeEnumvalue.Mobile.value });
                              if (webShow === true)
                                setState({ ...state, publishType: PublishTypeEnumvalue.Web.value });
                              if (e.target.checked && webShow) {
                                setIsBoth(true);
                                setState({ ...state, publishType: PublishTypeEnumvalue.Both.value });
                              }
                            }}
                            name='mobileDesign'
                          />
                        }
                        label={getTranslate()(`${translationPath}mobile`)}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isBoth}
                            onChange={(e) => {
                              setIsBoth(!isBoth);
                              setWebShow(!isBoth);
                              setMobileShow(!isBoth);
                              if (e.target.checked === true)
                                setState({ ...state, publishType: PublishTypeEnumvalue.Both.value});
                              else setState({ ...state, publishType: PublishTypeEnumvalue.Web.value});
                            }}
                            name='both'
                          />
                        }
                        label={getTranslate()(`${translationPath}both`)}
                      />
                      {mobileShow ? (
                        <div className='form-row'>
                          <div className='form-item'>
                            <Typography>
                              {getTranslate()(`${translationPath}mobile-design`)}
                            </Typography>
                            <div className='w-100 mt-2 add-filter'>
                              <SelectComponet
                                idRef='mobileRef'
                                data={MobileDesignTypeEnum}
                                emptyItem={{
                                  value: 0,
                                  text: 'none',
                                }}
                                valueInput='value'
                                textInput='key'
                                classes='theme-solid'
                                paddingReverse='2.5rem'
                                value={state.mobileDesign}
                                onSelectChanged={(event) => {
                                  setState({ ...state, mobileDesign: event.target.value });
                                }}
                                translationPath={translationPath}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {webShow ? (
                        <div className='form-row'>
                          <div className='form-item'>
                            <Typography>
                              {getTranslate()(`${translationPath}website-design`)}
                            </Typography>
                            <div className='w-100 mt-2 add-filter'>
                              <SelectComponet
                                idRef='websiteRef'
                                data={WebDesignTypeEnum}
                                emptyItem={{
                                  value: 0,
                                  text: 'none',
                                }}
                                valueInput='value'
                                textInput='key'
                                classes='theme-solid'
                                paddingReverse='2.5rem'
                                value={state.webDesign}
                                onSelectChanged={(event) => {
                                  setState({ ...state, webDesign: event.target.value });
                                }}
                                translationPath={translationPath}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={alwaysShow}
                          onChange={(event) => {
                            setAlwaysShow(!alwaysShow);
                            if (event.target.checked)
                              setState({ ...state, startDate: null, endDate: null });
                            else
                              setState({
                                ...state,
                                startDate: moment(dateState[0].startDate).format(),
                                endDate: moment(dateState[0].endDate).format(),
                              });
                          }}
                          name='alwaysShow'
                        />
                      }
                      label={getTranslate()(`${translationPath}always-show`)}
                    />
                    <div className='content-description'>
                      {getTranslate()(`${translationPath}always-description`)}
                    </div>
                  </FormControl>
                </div>
              </div>
              {!alwaysShow && (
                <div className='form-row'>
                  <div className='form-item'>
                    <FormControl className='input-wrapper flex-wrap content-date'>
                      <Typography>{getTranslate()(`${translationPath}date`)}</Typography>
                      <TextField
                        variant='outlined'
                        fullWidth
                        value={
                          dateState &&
                          dateState[0] &&
                          `From: ${moment(dateState[0].startDate).format(
                            'DD/MM/YYYY'
                          )}, To: ${moment(dateState[0].endDate).format('DD/MM/YYYY')}`
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <DateRangeRoundedIcon />
                            </InputAdornment>
                          ),
                        }}
                        onClick={(e) => handleClick(e)}
                      />
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}>
                        <DateRange
                          months={2}
                          direction='horizontal'
                          ranges={dateState}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          onChange={(item) => setDateState([item.selection])}
                        />
                      </Popover>
                    </FormControl>
                  </div>
                </div>
              )}
              <div className='form-row'>
                <div className='form-item'>
                  <FormControl>
                    <FormGroup className='checkbox-form'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.isPublish}
                            onChange={(e) => {
                              setState({ ...state, isPublish: e.target.checked });
                            }}
                            name='isPublish'
                          />
                        }
                        label={getTranslate()(`${translationPath}is-publish`)}
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
              <div className='form-row'>
                <div className='form-item'>
                  <Typography>{getTranslate()(`${translationPath}add-filter`)}</Typography>
                  {state.applicationSectionFilters.map((item, i) => (
                    <div className='w-100 mt-2 add-filter type-items' key={`${'section' + i}`}>
                      <div
                        className={`type-item ${
                          state.applicationSectionFilters[i].filterType === 0
                            ? 'disable-add'
                            : 'add-button-color'
                        }`}>
                        <SelectComponet
                          idRef={`filterRef${i}`}
                          data={FilterTypeEnum.filter(
                            (el) =>
                              state.applicationSectionFilters.findIndex(
                                (f) => f.filterType === el.value
                              ) === -1 || item.filterType === el.value
                          )}
                          emptyItem={{
                            value: 0,
                            text: 'none',
                          }}
                          valueInput='value'
                          textInput='key'
                          classes='theme-solid'
                          paddingReverse='2.5rem'
                          value={item.filterType}
                          translationPath={translationPath}
                          onSelectChanged={(event) => {
                            setState((items) => {
                              items.applicationSectionFilters[i].filterType = event.target.value;
                              return { ...items };
                            });
                          }}
                        />
                        <IconButton
                          disabled={state.applicationSectionFilters[i].filterType === 0}
                          className={`mdi mdi-${
                            i < state.applicationSectionFilters.length - 1 ? 'minus' : 'plus'
                          }`}
                          onClick={() => {
                            setState((items) => {
                              if (i < items.applicationSectionFilters.length - 1)
                                items.applicationSectionFilters.splice(i, 1);
                              else
                                items.applicationSectionFilters.push({
                                  filterType: 0,
                                  numberOfTours: '',
                                });
                              return { ...items };
                            });
                          }}
                        />
                      </div>
                      <div className='num-of-tours'>
                        {item.filterType !== 5 ? (
                          <Inputs
                            type='number'
                            startAdornment={
                              <IconButton>
                                <VisibilityIcon />
                              </IconButton>
                            }
                            inputPlaceholder='num-of-tours'
                            idRef={`numOfToursRef${i}`}
                            value={item.numberOfTours}
                            helperText={
                              getErrorByName(schema, `applicationSectionFilters.${i}.numberOfTours`)
                                .message
                            }
                            error={
                              getErrorByName(schema, `applicationSectionFilters.${i}.numberOfTours`)
                                .error
                            }
                            isWithError
                            isSubmitted={isSubmitted}
                            translationPath={translationPath}
                            onInputChanged={(event) => {
                              const value = (event.target.value && +event.target.value) || null;
                              setState((items) => {
                                items.applicationSectionFilters[i].numberOfTours = value;
                                return { ...items };
                              });
                            }}
                          />
                        ) : (
                          <Button
                            onClick={() => {
                              setIsCustome(true);
                              setActiveCustomIndex(i);
                            }}
                            className='theme-solid bg-secondary c-white'>
                            {getTranslate()(`${translationPath}choose-tour`)}
                          </Button>
                        )}
                        <div className='v-line' />
                        <div className='h-line' />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='form-row'>
                <div className='form-item'>
                  <Inputs
                    inputPlaceholder='description'
                    labelValue='description'
                    idRef='descriptionRef'
                    value={state.description}
                    helperText={getErrorByName(schema, 'description').message}
                    error={getErrorByName(schema, 'description').error}
                    isWithError
                    multiline
                    rows={4}
                    isSubmitted={isSubmitted}
                    onInputChanged={(event) =>
                      setState({ ...state, description: event.target.value })
                    }
                    translationPath={translationPath}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`sections-management-content-right px-3 ${isCustome ? 'is-open' : 'is-closed'}`}>
        {isCustome && (
          <SectionViewDragDrop
            setState={setState}
            activeCustomIndex={activeCustomIndex}
            value={value}
            setValue={setValue}
          />
        )}
      </div>
    </div>
  );
};
