import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import './UserManagementView.scss';
import {
  getErrorByName,
  getHistory,
  GetParams,
  getTranslate,
  showError,
  showSuccess,
} from '../../../../../Helpers';
import {
  CheckExistEmail,
  CheckExistPhone,
  CheckExistUserName,
  CreateOrganizationUser,
  EditOrganizationUserProfile,
  lookupItemsGetId,
  ActiveOrganizationUser,
  GetAllRolesByUserId,
} from '../../../../../Serviecs';
import Joi from 'joi';
import {
  emailExpression,
  mediumStringRegex,
  phoneExpression,
  strongStringRegex,
} from '../../../../../Utils';
import Lookups from '../../../../../Assets/JSON/StaticLookupsIds.json';
import {
  AutocompleteComponent,
  Inputs,
  PhonesComponent,
  ProgressComponet,
  Spinner,
  UploaderComponent,
} from '../../../../../Componentes';
import { DefaultImagesEnum } from '../../../../../Enums';
import { getLanguage } from 'react-switch-lang';
import moment from 'moment';
import { Button, ButtonBase } from '@material-ui/core';
import { useTitle } from '../../../../../Hooks/useTitle';
import { AssignRoleDialog } from '../UserUtilties';
import { useDispatch } from 'react-redux';
import { LoginActions } from '../../../../../Stores/Actions';

const translationPath = 'Administration.Users.UsersManagementView.';
export const UserManagementView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    else if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  };
  const [isLoadingFields, setIsLoadingFields] = useState({
    username: false,
    email: false,
    phoneNumber: false,
  });
  const [isExistUsername, setIsExistUsername] = useState(null);
  const [isExistEmail, setIsExistEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [activeUserItem, setActiveUserItem] = useState(null);
  const [isExistPhoneNumber, setIsExistPhoneNumber] = useState(null);
  const usernameExistTimer = useRef(null);
  const emailExistTimer = useRef(null);
  const phoneNumberExistTimer = useRef(null);
  const userDefault = {
    firstName: '',
    secondName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    profileImg: null,
    whatsAppNumber: '',
    nationality: null,
  };
  const [state, setState] = useReducer(reducer, userDefault);
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpenRole, setIsOpenRole] = useState(false);
  useTitle(getTranslate()(`${translationPath}${(userId && 'edit-user') || 'add-new-user'}`));
  const schema = Joi.object({
    firstName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}first-name-is-required`),
      }),
    secondName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}second-name-is-required`),
      }),
    lastName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}last-name-is-required`),
      }),
    userName:
      (!userId &&
        Joi.string()
          .required()
          .custom(
            (value, helpers) =>
              (isExistUsername && isExistUsername.isExist && helpers.error('any.invalid')) || value,
            getTranslate()(`${translationPath}username-is-already-exist`)
          )
          .messages({
            'any.invalid': getTranslate()(`${translationPath}username-is-already-exist`),
            'string.empty': getTranslate()(`${translationPath}username-is-required`),
          })) ||
      Joi.any(),
    email:
      (!userId &&
        Joi.string()
          .required()
          .regex(emailExpression)
          .custom(
            (value, helpers) =>
              (isExistEmail && isExistEmail.isExist && helpers.error('any.invalid')) || value,
            getTranslate()(`${translationPath}email-is-already-exist`)
          )
          .messages({
            'any.invalid': getTranslate()(`${translationPath}email-is-already-exist`),
            'string.empty': getTranslate()(`${translationPath}email-is-required`),
            'string.pattern.base': getTranslate()('shared.invalid-email'),
          })) ||
      Joi.any(),
    password:
      (!userId &&
        Joi.string()
          .required()
          .messages({
            'string.empty': getTranslate()(`${translationPath}password-is-required`),
          })) ||
      Joi.any(),
    confirmPassword:
      (!userId &&
        Joi.string()
          .required()
          .regex(new RegExp('^' + state.password + '$'))
          .messages({
            'string.empty': getTranslate()(`${translationPath}confirm-password-is-required`),
            'string.pattern.base': getTranslate()('shared.password-not-matched'),
          })) ||
      Joi.any(),
    phoneNumber:
      (!userId &&
        Joi.string()
          .required()
          .regex(phoneExpression)
          .custom(
            (value, helpers) =>
              (isExistPhoneNumber && isExistPhoneNumber.isExist && helpers.error('any.invalid')) ||
              value,
            getTranslate()(`${translationPath}mobile-number-is-already-exist`)
          )
          .messages({
            'any.invalid': getTranslate()(`${translationPath}mobile-number-is-already-exist`),
            'string.empty': getTranslate()(`${translationPath}mobile-number-is-required`),
            'string.pattern.base': getTranslate()('shared.invalid-mobile-number'),
          })) ||
      Joi.any(),
    whatsAppNumber:
      (!userId &&
        Joi.string()
          .required()
          .regex(phoneExpression)
          .messages({
            'string.empty': getTranslate()(`${translationPath}whatsapp-number-is-required`),
            'string.pattern.base': getTranslate()('shared.invalid-whatsapp-number'),
          })) ||
      Joi.any(),
    nationality: Joi.object()
      .required()
      .messages({
        // 'any.required': getTranslate()(`${translationPath}nationality-is-required`),
        'object.base': getTranslate()(`${translationPath}nationality-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  // console.log(schema);
  const getNationalities = async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: Lookups.Country,
    });
    setCountries(res);
    setIsLoading(false);
  };
  const getUserById = useCallback(async () => {
    setIsLoading(true);
    // const res =
    await ActiveOrganizationUser(userId);
    // setActiveUserItem(res);
    setActiveUserItem(JSON.parse(localStorage.getItem('activeUserItem')));
    setIsLoading(false);
  }, [userId]);
  const getUserRolesById = useCallback(async () => {
    setIsLoading(true);
    const response = await GetAllRolesByUserId(userId, 1, 9999999);
    setRoles((response && response.result) || []);
    setIsLoading(false);
  }, [userId]);
  const getIsExistUserName = useCallback(async () => {
    setIsLoadingFields((items) => ({ ...items, username: true }));
    const response = await CheckExistUserName(state.userName);
    setIsLoadingFields((items) => ({ ...items, username: false }));
    setIsExistUsername(response);
  }, [state.userName]);
  const getIsExistPhoneNumber = useCallback(async () => {
    setIsLoadingFields((items) => ({ ...items, phoneNumber: true }));
    const response = await CheckExistPhone(
      (!state.phoneNumber.startsWith('+') && '+' + state.phoneNumber) || state.phoneNumber
    );
    setIsLoadingFields((items) => ({ ...items, phoneNumber: false }));
    setIsExistPhoneNumber(response);
  }, [state.phoneNumber]);
  const getIsExistEmail = useCallback(async () => {
    setIsLoadingFields((items) => ({ ...items, email: true }));
    const response = await CheckExistEmail(state.email);
    setIsLoadingFields((items) => ({ ...items, email: false }));
    setIsExistEmail(response);
  }, [state.email]);

  const getExistUsernameValidation = useCallback(() => {
    if (usernameExistTimer.current) clearTimeout(usernameExistTimer.current);
    usernameExistTimer.current = setTimeout(() => {
      getIsExistUserName();
    }, 500);
  }, [getIsExistUserName]);

  const getExistEmailValidation = useCallback(() => {
    if (emailExistTimer.current) clearTimeout(emailExistTimer.current);
    emailExistTimer.current = setTimeout(() => {
      getIsExistEmail();
    }, 500);
  }, [getIsExistEmail]);

  const getExistPhoneNumberValidation = useCallback(() => {
    if (phoneNumberExistTimer.current) clearTimeout(phoneNumberExistTimer.current);
    phoneNumberExistTimer.current = setTimeout(() => {
      getIsExistPhoneNumber();
    }, 500);
  }, [getIsExistPhoneNumber]);

  useEffect(() => {
    if (state.userName !== '') getExistUsernameValidation();
  }, [getExistUsernameValidation, state.userName]);

  useEffect(() => {
    if (state.email && state.email !== '' && state.email.match(emailExpression))
      getExistEmailValidation();
  }, [getExistEmailValidation, state.email]);

  useEffect(() => {
    if (state.phoneNumber && state.phoneNumber !== '' && state.phoneNumber.match(phoneExpression))
      getExistPhoneNumberValidation();
  }, [getExistPhoneNumberValidation, state.phoneNumber]);

  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    setIsLoading(true);
    const saveDto = {
      ...state,
      phoneNumber:
        (state.phoneNumber && !state.phoneNumber.startsWith('+') && '+' + state.phoneNumber) ||
        state.phoneNumber ||
        '',
      whatsAppNumber:
        (state.whatsAppNumber &&
          !state.whatsAppNumber.startsWith('+') &&
          '+' + state.whatsAppNumber) ||
        (state.whatsAppNumber &&
          state.whatsAppNumber.match(phoneExpression) &&
          state.whatsAppNumber) ||
        '',
      nationalityId: state.nationality.lookupItemId,
    };
    const res =
      (userId && (await EditOrganizationUserProfile(userId, saveDto))) ||
      (await CreateOrganizationUser(saveDto));
    setIsLoading(false);
    if (res) {
      if (userId) showSuccess(getTranslate()(`${translationPath}user-updated-successfully`));
      else showSuccess(getTranslate()(`${translationPath}user-created-successfully`));
      if (JSON.parse(localStorage.getItem('session')).userId === userId) {
        const updatedState = JSON.parse(localStorage.getItem('session'));
        const update = { ...updatedState, profileImg: state.profileImg };
        localStorage.setItem('session', JSON.stringify(update));
        //   UpdateAction(update);
        dispatch(LoginActions.update(update));
      }
      getHistory().push('/home/Users');
    } else if (userId) showError(getTranslate()(`${translationPath}user-update-failed`));
    else showError(getTranslate()(`${translationPath}user-create-failed`));
  };
  const manageRolesClicked = () => {
    setIsOpenRole(true);
  };
  useEffect(() => {
    getNationalities();
  }, []);
  useEffect(() => {
    console.log('entered');
    const editId = GetParams('id');
    if (editId !== null) setUserId(editId);
  }, []);
  useEffect(() => {
    if (activeUserItem)
      setState({
        id: 'edit',
        value: {
          ...activeUserItem,
          nationality:
            (activeUserItem.nationalityId &&
              countries.find(
                (item) => activeUserItem && item.lookupItemId === activeUserItem.nationalityId
              )) ||
            null,
          password: '',
          confirmPassword: '',
          whatsAppNumber: activeUserItem.whatsAppNumber || '',
          phoneNumber: activeUserItem.phoneNumber || '',
        },
      });
  }, [activeUserItem, countries]);
  useEffect(() => {
    if (userId) {
      getUserById();
      getUserRolesById();
    }
  }, [getUserById, getUserRolesById, userId]);
  useEffect(() => {
    return () => {
      if (usernameExistTimer.current) clearTimeout(usernameExistTimer.current);
      if (emailExistTimer.current) clearTimeout(emailExistTimer.current);
      if (phoneNumberExistTimer.current) clearTimeout(phoneNumberExistTimer.current);
    };
  }, []);
  return (
    <div className="user-management-view view-wrapper">
      <Spinner isActive={isLoading} />
      <form noValidate onSubmit={saveHandler} className="management-form-content">
        <div className="view-management-header mb-2 px-2">
          <Button type="submit" className="btns theme-solid mx-2 mb-2">
            <span>
              {getTranslate()(`${translationPath}${(userId && 'edit-user') || 'add-new-user'}`)}
            </span>
          </Button>
          <Button
            className="btns theme-solid bg-cancel mb-2 mx-2"
            onClick={() => getHistory().push('/home/Users')}
          >
            <span>{getTranslate()(`${translationPath}cancel`)}</span>
          </Button>
        </div>
        <div className="body-content">
          <div className="information-section">
            <div className="information-box-wrapper">
              <div className="information-section-content">
                <div className="image-wrapper">
                  <UploaderComponent
                    idRef="profileImgRef"
                    circleDefaultImage={DefaultImagesEnum.man.defaultImg}
                    initUploadedFiles={
                      (state &&
                        state.profileImg && [{ uuid: state.profileImg, fileName: 'user-image' }]) ||
                      []
                    }
                    uploadedChanged={(files) =>
                      setState({
                        id: 'profileImg',
                        value: (files.length > 0 && files[0].uuid) || null,
                      })
                    }
                  />
                </div>
                {userId && activeUserItem && (
                  <div className="information-content-wrapper">
                    <div>
                      <span className="fz-30px">{activeUserItem.fullName}</span>
                    </div>
                    <div className="mb-2">
                      <span className="fw-bold">
                        <span>{getTranslate()(`${translationPath}Username`)}:</span>
                        <span className="px-2">{activeUserItem.userName}</span>
                      </span>
                    </div>
                    <div className="roles-wrapper">
                      <div className="roles-content">
                        {roles.map((item, index) => (
                          <span key={`userRolesRef${index + 1}`} className="px-1">
                            <span>{item.roles.rolesName}</span>
                            {index < roles.length - 1 && <span>,</span>}
                          </span>
                        ))}
                      </div>
                      <ButtonBase
                        className="btns theme-outline roles-button"
                        onClick={manageRolesClicked}
                      >
                        <span>{getTranslate()(`${translationPath}manage-roles`)}</span>
                        <span className="mdi mdi-menu-swap" />
                      </ButtonBase>
                    </div>
                  </div>
                )}
                {!userId && (
                  <div className="information-content-wrapper">
                    <div className="form-item">
                      <Inputs
                        inputPlaceholder="FirstName"
                        labelValue="FirstName"
                        idRef="firstNameRef"
                        value={state.firstName || ''}
                        isWithError
                        isSubmitted={isSubmitted}
                        helperText={getErrorByName(schema, 'firstName').message}
                        error={getErrorByName(schema, 'firstName').error}
                        onInputChanged={(event) =>
                          setState({ id: 'firstName', value: event.target.value })
                        }
                        translationPath={translationPath}
                      />
                    </div>
                    <div className="form-item">
                      <Inputs
                        inputPlaceholder="SecondName"
                        labelValue="SecondName"
                        idRef="secondNameRef"
                        value={state.secondName || ''}
                        isWithError
                        isSubmitted={isSubmitted}
                        helperText={getErrorByName(schema, 'secondName').message}
                        error={getErrorByName(schema, 'secondName').error}
                        onInputChanged={(event) =>
                          setState({ id: 'secondName', value: event.target.value })
                        }
                        translationPath={translationPath}
                      />
                    </div>
                    <div className="form-item">
                      <Inputs
                        inputPlaceholder="LastName"
                        labelValue="LastName"
                        idRef="lastNameRef"
                        value={state.lastName || ''}
                        isWithError
                        isSubmitted={isSubmitted}
                        helperText={getErrorByName(schema, 'lastName').message}
                        error={getErrorByName(schema, 'lastName').error}
                        onInputChanged={(event) =>
                          setState({ id: 'lastName', value: event.target.value })
                        }
                        translationPath={translationPath}
                      />
                    </div>
                    <div className="form-item">
                      <AutocompleteComponent
                        idRef="nationalityRef"
                        labelValue="nationality"
                        inputPlaceholder="nationality"
                        translationPath={translationPath}
                        value={state.nationality}
                        options={countries}
                        chipsLabel={(option) =>
                          (option.lookupItemName && option.lookupItemName) || ''
                        }
                        getOptionLabel={(option) =>
                          (option.lookupItemName && option.lookupItemName) || ''
                        }
                        multiple={false}
                        withoutSearchButton
                        helperText={getErrorByName(schema, 'nationality').message}
                        error={getErrorByName(schema, 'nationality').error}
                        isWithError
                        isSubmitted={isSubmitted}
                        onChange={(event, newValue) => {
                          setState({ id: 'nationality', value: newValue });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {userId && activeUserItem && (
                <div className="information-section-footer">
                  <div className="separator-h"></div>
                  <div className="footer-content-wrapper">
                    <div className="footer-section">
                      <div className="section-item">
                        <span className="mdi mdi-cellphone-android"></span>
                        <span className="px-2">{activeUserItem.phoneNumber || 'N/A'}</span>
                      </div>
                      <div className="section-item">
                        <span className="mdi mdi-email-outline"></span>
                        <span className="px-2">{activeUserItem.email || 'N/A'}</span>
                      </div>
                      <div className="section-item">
                        <span className="mdi mdi-whatsapp"></span>
                        <span className="px-2">{activeUserItem.whatsAppNumber || 'N/A'}</span>
                      </div>
                    </div>
                    <div className="footer-section">
                      <div className="section-item">
                        <span className="mdi mdi-map-marker-outline"></span>
                        <span className="px-2">
                          {getTranslate()(`${translationPath}nationality`)}:
                        </span>
                        <span>
                          {(activeUserItem.nationalityId &&
                            countries.find(
                              (item) => item.lookupItemId === activeUserItem.nationalityId
                            ) &&
                            countries.find(
                              (item) => item.lookupItemId === activeUserItem.nationalityId
                            ).lookupItemName) ||
                            'N/A'}
                        </span>
                      </div>
                      <div className="section-item">
                        <span className="mdi mdi-calendar"></span>
                        <span className="px-2">
                          {getTranslate()(`${translationPath}register`)}:
                        </span>
                        <span>
                          {(activeUserItem.createdOn &&
                            moment(activeUserItem.createdOn)
                              .locale(getLanguage())
                              .format('DD, MMM YYYY')) ||
                            'N/A'}
                        </span>
                      </div>
                      <div className="section-item">
                        <span className="mdi mdi-handshake-outline"></span>
                        <span className="px-2">
                          {getTranslate()(`${translationPath}data-source`)}:
                        </span>
                        <span>{activeUserItem.dataSource || 'N/A'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {!userId && (
            <div className="account-dialog-section">
              <div className="dialog-header">
                {getTranslate()(`${translationPath}account-details`)}
              </div>
              <div className="dialog-body">
                <div className="form-item">
                  <Inputs
                    inputPlaceholder="Username"
                    labelValue="Username"
                    idRef="usernameRef"
                    value={state.userName}
                    isWithError
                    isSubmitted={isSubmitted}
                    isLoading={isLoadingFields.username}
                    withLoader
                    helperText={getErrorByName(schema, 'userName').message}
                    error={getErrorByName(schema, 'userName').error}
                    afterIconClasses={
                      (!isLoadingFields.username &&
                        ((isExistUsername &&
                          isExistUsername.isExist &&
                          'mdi mdi-close-outline c-danger mt-1') ||
                          (!getErrorByName(schema, 'userName').error &&
                            'mdi mdi-check-outline c-success mt-1'))) ||
                      null
                    }
                    onInputChanged={(event) =>
                      setState({ id: 'userName', value: event.target.value })
                    }
                    translationPath={translationPath}
                  />
                </div>
                <div className="form-item">
                  <Inputs
                    inputPlaceholder="Email"
                    labelValue="Email"
                    idRef="emailRef"
                    value={state.email || ''}
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, 'email').message}
                    error={getErrorByName(schema, 'email').error}
                    isLoading={isLoadingFields.email}
                    withLoader
                    onInputChanged={(event) => setState({ id: 'email', value: event.target.value })}
                    translationPath={translationPath}
                    afterIconClasses={
                      (!isLoadingFields.email &&
                        ((isExistEmail &&
                          isExistEmail.isExist &&
                          'mdi mdi-close-outline c-danger mt-1') ||
                          (!getErrorByName(schema, 'email').error &&
                            'mdi mdi-check-outline c-success mt-1'))) ||
                      null
                    }
                  />
                </div>
                <div className="form-item">
                  <PhonesComponent
                    country="ae"
                    idRef="phoneNumberRef"
                    labelValue="Mobile"
                    translationPath={translationPath}
                    value={state.phoneNumber}
                    helperText={getErrorByName(schema, 'phoneNumber').message}
                    error={getErrorByName(schema, 'phoneNumber').error}
                    inputPlaceholder="Mobile"
                    isSubmitted={isSubmitted}
                    isLoading={isLoadingFields.phoneNumber}
                    afterIconClasses={
                      (!isLoadingFields.phoneNumber &&
                        ((isExistPhoneNumber &&
                          isExistPhoneNumber.isExist &&
                          'mdi mdi-close-outline c-danger mt-1') ||
                          (!getErrorByName(schema, 'phoneNumber').error &&
                            'mdi mdi-check-outline c-success mt-1'))) ||
                      null
                    }
                    onInputChanged={(value) => setState({ id: 'phoneNumber', value })}
                  />
                </div>
                <div className="form-item">
                  <PhonesComponent
                    country="ae"
                    idRef="whatsappNumberRef"
                    labelValue="whatsapp"
                    translationPath={translationPath}
                    value={state.whatsAppNumber}
                    helperText={getErrorByName(schema, 'whatsAppNumber').message}
                    error={getErrorByName(schema, 'whatsAppNumber').error}
                    inputPlaceholder="whatsapp"
                    isSubmitted={isSubmitted}
                    onInputChanged={(value) => setState({ id: 'whatsAppNumber', value })}
                  />
                </div>
                <div className="form-item">
                  <Inputs
                    inputPlaceholder="Password"
                    labelValue="Password"
                    idRef="passwordRef"
                    value={state.password}
                    type="password"
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, 'password').message}
                    error={getErrorByName(schema, 'password').error}
                    onInputChanged={(event) =>
                      setState({ id: 'password', value: event.target.value })
                    }
                    translationPath={translationPath}
                  />
                </div>
                <div className="form-item">
                  <Inputs
                    inputPlaceholder="confirm-password"
                    labelValue="confirm-password"
                    idRef="confirmPasswordRef"
                    value={state.confirmPassword}
                    type="password"
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, 'confirmPassword').message}
                    error={getErrorByName(schema, 'confirmPassword').error}
                    onInputChanged={(event) =>
                      setState({ id: 'confirmPassword', value: event.target.value })
                    }
                    translationPath={translationPath}
                  />
                </div>
                <div className="w-100 pb-130px mb-3">
                  <ProgressComponet
                    inSameLine
                    value={
                      (state.password.match(strongStringRegex) && 100) ||
                      (state.password.match(mediumStringRegex) && 50) ||
                      (state.password !== '' && 25) ||
                      0
                    }
                    isTextColored
                    progressText={
                      (state.password.match(strongStringRegex) &&
                        getTranslate()(`${translationPath}strong-password`)) ||
                      (state.password.match(mediumStringRegex) &&
                        getTranslate()(`${translationPath}medium-password`)) ||
                      (state.password !== '' &&
                        getTranslate()(`${translationPath}weak-password`)) ||
                      getTranslate()(`${translationPath}password-is-blank`)
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {userId && (
            <div className="account-dialog-section">
              <div className="dialog-header">
                {getTranslate()(`${translationPath}profile-details`)}
              </div>
              <div className="dialog-body">
                <div className="form-item">
                  <Inputs
                    inputPlaceholder="FirstName"
                    labelValue="FirstName"
                    idRef="firstNameRef"
                    value={state.firstName || ''}
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, 'firstName').message}
                    error={getErrorByName(schema, 'firstName').error}
                    onInputChanged={(event) =>
                      setState({ id: 'firstName', value: event.target.value })
                    }
                    translationPath={translationPath}
                  />
                </div>
                <div className="form-item">
                  <Inputs
                    inputPlaceholder="SecondName"
                    labelValue="SecondName"
                    idRef="secondNameRef"
                    value={state.secondName || ''}
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, 'secondName').message}
                    error={getErrorByName(schema, 'secondName').error}
                    onInputChanged={(event) =>
                      setState({ id: 'secondName', value: event.target.value })
                    }
                    translationPath={translationPath}
                  />
                </div>
                <div className="form-item">
                  <Inputs
                    inputPlaceholder="LastName"
                    labelValue="LastName"
                    idRef="lastNameRef"
                    value={state.lastName || ''}
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, 'lastName').message}
                    error={getErrorByName(schema, 'lastName').error}
                    onInputChanged={(event) =>
                      setState({ id: 'lastName', value: event.target.value })
                    }
                    translationPath={translationPath}
                  />
                </div>
                <div className="form-item">
                  <AutocompleteComponent
                    idRef="nationalityRef"
                    labelValue="nationality"
                    inputPlaceholder="nationality"
                    translationPath={translationPath}
                    value={state.nationality}
                    options={countries}
                    chipsLabel={(option) => (option.lookupItemName && option.lookupItemName) || ''}
                    getOptionLabel={(option) =>
                      (option.lookupItemName && option.lookupItemName) || ''
                    }
                    multiple={false}
                    withoutSearchButton
                    helperText={getErrorByName(schema, 'nationality').message}
                    error={getErrorByName(schema, 'nationality').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    onChange={(event, newValue) => {
                      setState({ id: 'nationality', value: newValue });
                    }}
                  />
                </div>
                <div className="form-item">
                  <PhonesComponent
                    country="ae"
                    idRef="whatsappNumberRef"
                    labelValue="whatsapp"
                    translationPath={translationPath}
                    value={state.whatsAppNumber}
                    helperText={getErrorByName(schema, 'whatsAppNumber').message}
                    error={getErrorByName(schema, 'whatsAppNumber').error}
                    inputPlaceholder="whatsapp"
                    isSubmitted={isSubmitted}
                    onInputChanged={(value) => setState({ id: 'whatsAppNumber', value })}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
      {activeUserItem && userId && (
        <AssignRoleDialog
          userFullName={activeUserItem.fullName || ''}
          userId={userId}
          isOpen={isOpenRole}
          isOpenChanged={() => {
            setIsOpenRole(false);
            // setActiveUserItem(null);
          }}
          reloadData={() => {
            // setFilter((item) => ({ ...item, pageIndex: 0 }));
            // setActiveUserItem(null);
            // recall roles
            getUserRolesById();
          }}
        />
      )}
    </div>
  );
};

// UserManagementView.defaultProps = {
//   activeUserItem: undefined,
// };
