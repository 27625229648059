import { Grid } from '@material-ui/core'
import React,{useState,useEffect,useCallback} from 'react'
import './UserStatsComponent.scss'
import {GetPSTVisits} from './../../../../../Serviecs'
import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';
import { getTranslate } from ' ./../../src/Helpers';
import {kFormatter} from './../../../../../Helpers/FormattingHelper'


export const  WebsiteVisitors=()=> {
    const translationPath = 'TotalVisetorStat.';
  const [isLoading, setIsLoading] = useState(false);
  const [state,setState] =useState();
  const [name, setName] = useState('');

  const GetVisitor = useCallback(async () => {
    setIsLoading(true);
    const Status = await GetPSTVisits();
    if (Status) {
     setName( Status.totalVisits>0?'green':'red')
      setState(Status);
      setIsLoading(false);
    }
  }, []);
  
  useEffect(() => {
    GetVisitor();
  }, [GetVisitor]);

    return (
       
        <div className='Stats'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className='StatsTitleDiv'>
        <p className='titleParagraph'>{getTranslate()(`${translationPath}Website-Visitors`)}</p>
        </div>

        <Grid container spacing={2}>

        <Grid xs={6}>
        <div className='statsDiv'>
        <p className='statsData'>
        <span> {state && kFormatter(state.totalVisits) }</span> <span> {getTranslate()(`${translationPath}visitor`)}</span>
       
        </p>
        <p className='statsPercentage'>
        <span> {state &&  kFormatter(state.totalVisits) }</span> <span> {getTranslate()(`${translationPath}visitor`)} <span> {getTranslate()(`${translationPath}This-Week`) } </span> </span>
       
        </p>

        </div>

        </Grid>

        <Grid xs={6}>
        <div className={`${name}stats`}>
        </div>

        </Grid>
        
        </Grid>
            
        </div>
    )
}
