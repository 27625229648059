export const ReservedTourStatus = [
  {
    key: 'UpComing',
    value: 1,
  },
  {
    key: 'DiverStarting',
    value: 2,
  },
  {
    key: 'TourStarting',
    value: 3,
  },
  {
    key: 'TourEnding',
    value: 4,
  },
  {
    key: 'DiverEnding',
    value: 5,
  },
  {
    key: 'Missed',
    value: 6,
  },
];
