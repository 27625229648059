import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import { getTranslate, getHistory, useEventListener } from '../../../../Helpers';
import { HomeRoutes, MainMenu } from '../../../../Routes';
import { Divider } from '@material-ui/core';

const getActiveGroup = () => {
  const activeSubMenu = HomeRoutes.find((item) =>
    item.isExact
      ? window.location.pathname.match(item.layout ? item.layout + item.path : item.path)
      : window.location.pathname.includes(item.layout ? item.layout + item.path : item.path)
  );
  if (!activeSubMenu) {
    const mainMenu = MainMenu.find((item) =>
      item.routerLink && item.routerLinkActiveOptions.exact
        ? window.location.pathname.match(item.routerLink)
        : window.location.pathname.includes(item.routerLink)
    );
    return mainMenu ? mainMenu.groupId : -1;
  }
  return activeSubMenu.groupId;
};
const getActiveSubItem = (exact, routerLink) =>
  exact
    ? window.location.pathname.match(routerLink)
    : window.location.pathname.includes(routerLink);
const getSortedSubMenu = (openGroupId) =>
  HomeRoutes.filter((item) => item.groupId === openGroupId && item.showInMenu).sort(
    (a, b) => a.order - b.order
  );
const MainMenuView = () => {
  const [activeGroupId, setActiveGroupId] = useState(getActiveGroup());
  const [openGroupId, setOpenGroupId] = useState(-1);
  const [hoverSubmenuIndex, setHoverSubmenuIndex] = useState(-1);
  const [groupName, setGroupName] = useState('');
  const [menu] = useState(MainMenu);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [scrollFromTo, setScrollFromTo] = useState(0);
  useEffect(() => () => {
    setActiveGroupId(getActiveGroup());
    setScrollFromTo(document.documentElement.scrollTop);
  });

  const menuClicked = useCallback(
    (groupId, routerLink) => {
      setOpenGroupId(groupId);
      if (routerLink) {
        setActiveGroupId(groupId);
        getHistory().push(routerLink);
      }
    },
    [setOpenGroupId]
  );
  useEventListener('scroll', () => {
    if (openGroupId !== -1 && getSortedSubMenu(openGroupId).length > 0)
      setScrollFromTo(document.documentElement.scrollTop);
  });
  const subMenuClicked = useCallback((groupId) => setActiveGroupId(groupId), [setActiveGroupId]);
  const submenuHover = useCallback((index) => setHoverSubmenuIndex(index), [setHoverSubmenuIndex]);

  return (
    <div
      className={`menu-wrapper${getSortedSubMenu(openGroupId).length === 0 ? ' is-closed' : ''}`}
      onMouseLeave={() => {
        setOpenGroupId(-1);
      }}>
      <div className='menu'>
        <div className='menu-content'>
          {menu
            .sort((a, b) => a.order - b.order)
            .map((item) => (
              <ButtonBase
                onClick={() => {
                  if (item.groupId === 1) getHistory().push('/home');
                }}
                key={`menu${item.groupId}`}
                disabled={item.isDisabled}
                className={`btns-menu${activeGroupId === item.groupId ? ' active' : ''}`}
                onMouseEnter={(e) => {
                  if (item.groupId !== 1) {
                    setAnchorEl(e.currentTarget);
                    setGroupName(item.name);
                    menuClicked(item.groupId, item.routerLink);
                  } else {
                    setOpenGroupId(-1);
                  }
                }}
                onTouchStart={(e) => {
                  if (item.groupId !== 1) {
                    setAnchorEl(e.currentTarget);
                    setGroupName(item.name);
                    menuClicked(item.groupId, item.routerLink);
                  } else {
                    setOpenGroupId(-1);
                  }
                }}>
                <span
                  className={
                    activeGroupId === item.groupId || openGroupId === item.groupId
                      ? item.iconActive
                      : item.icon
                  }
                />
              </ButtonBase>
            ))}
        </div>
      </div>
      <div className='submenu-wrapper'>
        <div
          className='animated-open-left'
          style={{ top: anchorEl && anchorEl.getBoundingClientRect().top + scrollFromTo - 65 }}>
          <div className='open-close-left'>
            {openGroupId !== -1 && getSortedSubMenu(openGroupId).length > 0 && (
              <>
                <div className='menuTriangleDown' />
                <div className='menuTriangleUp' />
                <div className='menuTriangleLeft' />
                {groupName && (
                  <>
                    <div className='menuGroupName'>{getTranslate()(groupName)}</div>
                    <Divider />
                  </>
                )}
                <div className='menuNavLink'>
                  {getSortedSubMenu(openGroupId).map((item, index) => (
                    <NavLink
                      key={`submenu${index + 1}`}
                      to={item.layout ? item.layout + item.path : item.path}
                      exact={item.isExact}
                      activeClassName='active'
                      className='btns-submenu'
                      onMouseEnter={() => {
                        submenuHover(index);
                      }}
                      onClick={() => subMenuClicked(item.groupId)}
                      disabled={item.isDisabled}>
                      <span
                        className={
                          getActiveSubItem(
                            item.isExact,
                            item.layout ? item.layout + item.path : item.path
                          ) || hoverSubmenuIndex === index
                            ? item.iconActive
                            : item.icon
                        }
                      />
                      {getTranslate()(item.name)}
                    </NavLink>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { MainMenuView };
