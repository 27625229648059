import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';
import { showError } from '../../Helpers';

export const GetActivePromoCodes = async (pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Payment/PromoCode/GetAllActivePromoCode?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
    return result;
  } catch (e) {
    showError('Error Retriving PromoCodes');
  }
};

export const GetAllPromoCodes = async (pageIndex, pageSize, search = '') => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Payment/PromoCode/GetAllPromoCode?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
    );
    return result;
  } catch (e) {
    showError('Error Retriving PromoCodes');
  }
};

export const GetPromoCodeById = async (promoCodeId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Payment/PromoCode/GetPromoCodeById/${promoCodeId}`
    );
    return result;
  } catch (e) {
    showError('Error Retriving PromoCodes');
  }
};

export const CreatePublicPromoCode = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Payment/PromoCode/CreatePublicPromoCode`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Creating PromoCode');
  }
};

export const CreatePrivatePromoCode = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Payment/PromoCode/CreatePrivatePromoCode`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Creating PromoCode');
  }
};

export const GeneratePromoCode = async () => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Payment/PromoCode/GeneratePromoCode`
    );
    return result;
  } catch (e) {
    showError('Error Generating PromoCode');
  }
};

export const DeletePromoCode = async (promoCodeId) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/Payment/PromoCode/DeletePromoCode/${promoCodeId}`
    );
    return result;
  } catch (e) {
    showError('Error Generating PromoCode');
  }
};

export const UpdatePrivatePromoCode = async (promoCodeId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Payment/PromoCode/UpdatePrivatePromoCode/${promoCodeId}`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Updating PromoCode');
  }
};

export const UpdatePublicPromoCode = async (promoCodeId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Payment/PromoCode/UpdatePublicPromoCode/${promoCodeId}`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Updating PromoCode');
  }
};

export const ActivatePromoCode = async (promoCodeId) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Payment/PromoCode/ActivatePromoCode/${promoCodeId}`
    );
    return result;
  } catch (e) {
    showError('Error Updating PromoCode');
  }
};

export const DeactivatePromoCode = async (promoCodeId) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Payment/PromoCode/DeactivatePromoCode/${promoCodeId}`
    );
    return result;
  } catch (e) {
    showError('Error Updating PromoCode');
  }
};
