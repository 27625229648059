import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import { getTranslate, getHistory, GetParams } from '../../../../Helpers';
import { RadiosGroupComponent, Spinner } from '../../../../Componentes';
import { ApplicationUserForgotPassword } from '../../../../Serviecs';
import { organizationsId, applicationId } from '../../../../Assets/Configration/Configration.json';
import '../../LoginViews/LoginView/LoginView.scss';

const translationPath = 'login.';

const ResetPasswordOptionsView = (props) => {
  const [radioValue, setRadioValue] = useState('1');
  const [emailOptions, setEmailOptions] = useState();
  const [phoneOptions, setPhoneOptions] = useState();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();

  useEffect(() => {
    setEmailOptions(GetParams('email').split('?')[0]);
    setPhoneOptions(GetParams('email').split('?phone=')[1].split('?userId=')[0]);
    setUserId(GetParams('email').split('?userId=')[1]);
  }, [props.match]);

  const handleOptionsSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const options = await ApplicationUserForgotPassword({
      userId: userId,
      identity: radioValue === '1' ? emailOptions : phoneOptions,
      organizationsId: organizationsId,
      applicationsId: applicationId,
    });
    if (options)
      getHistory().push(
        `/account/VerificationCodeView?identityVerificationId=${
          options.identityVerificationId
        }?email=${emailOptions}?phone=${phoneOptions}?isEmail=${radioValue === '1' ? true : false}`
      );
    setLoading(false);
  };

  return (
    <>
      <div className='forget-password-text'>
        <Spinner isActive={loading} />
        <Typography className='forget-password'>
          {getTranslate()(`${translationPath}we-need-to-verify-your-identity`)}
        </Typography>
        <Typography>
          {getTranslate()(`${translationPath}don’t-worry-everything-will-be-fixed-soon`)}
        </Typography>
      </div>
      <form noValidate className='form-wrapper' onSubmit={handleOptionsSubmit}>
        <div className='forget-password-radio'>
          <RadiosGroupComponent
            data={[
              { value: '1', label: emailOptions },
              { value: '0', label: phoneOptions },
            ]}
            idRef='Actions'
            onSelectedRadioChanged={(e) => setRadioValue(e.target.value)}
            value={radioValue}
            name='Active'
            labelInput='label'
            valueInput='value'
          />
        </div>
        <div className='d-flex-v-center-h-between'>
          <Button
            onClick={() => getHistory().push('/account/login')}
            className='btns cancel-forget-passwrod '>
            {getTranslate()(`${translationPath}cancel`)}
          </Button>
          <Button className='btns submit-forget-password' type='submit'>
            {getTranslate()(`${translationPath}send-code`)}
          </Button>
        </div>
      </form>
    </>
  );
};

export { ResetPasswordOptionsView };
