
import React from 'react';
import {SwitchRoute} from "../../../Componentes/Route/SwitchRoute";
import {FormBuilderRoutes} from "../../../Routes";


export const FormBuilderLayout = () => (
  <>
      <div className="content-wrapper" >
          <SwitchRoute routes={FormBuilderRoutes} />
      </div>

  </>
    );
