import React, { useState, useCallback, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import './LocationsAndSoldTours.scss';
import { getTranslate } from ' ./../../src/Helpers';
import { SelectComponet, Spinner } from '../../../../../Componentes';
import { TotalToursEnum } from '../../../../../Enums';
import { GetTopSoldTours } from '../../../../../Serviecs';
import moment from 'moment';

export const SoldTours=()=> {
  const [dropDownValue, setDropDownValue] = useState(0);
  const [dateRange, setDateRange] = useState({
    numOfTours: '5',
    startDate: moment().add(-7, 'd').format('YYYY-MM-DD'),
    endDate:moment().format('YYYY-MM-DD') ,
  });
  const [isOpen, setIsOpen] = useState(false);
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const translationPath = 'TotalSoldTours.';
  const [isLoading, setIsLoading] = useState(true);
  const [pieValue,setpieValue] = useState(true);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#147AD6',
          '#FFDB69',
          '#FA9266',
          '#F15740',
          '#56A591',
          '#79D2DE',
        ],
      },
    ],
  });
  const openHandler = () => {
    setIsOpen(true);
  };
  const closeHandler = () => {
    setIsOpen(false);
  };

  const dropDwonDateRange = (value) => {
    setDropDownValue(value);
    switch (value) {
      case 0:
        setDateRange((items)=>({
          ...items,
          startDate: moment().add(-7, 'd').format('YYYY-MM-DD'),
          endDate:  moment().format('YYYY-MM-DD'),
        }));
        break;
      case 1:
        setDateRange((items) =>({
          ...items,
          startDate: moment().add(-1, 'M').format('YYYY-MM-DD'),
          endDate:moment().format('YYYY-MM-DD'),
        }));
        break;
      case 2:
        setDateRange((items) =>({
          ...items,
          startDate: moment().add(-1, 'y').format('YYYY-MM-DD'),
          endDate:moment().format('YYYY-MM-DD'),
        }));
        break;
        
    default:
    }
  };

  const GetTopTours = useCallback(async () => {
    setIsLoading(true);
    const Status = await GetTopSoldTours(dateRange);
    if (Status) {
   
      setChartData((items) => {
         items.labels = Status.topSoldTours.map((item) => item.tourName);
         items.labels=[ ...items.labels,'Other'];
         items.datasets[0].data=Status.topSoldTours.map((item)=>item.totalTourSalesIncome)
         items.datasets[0].data=[...items.datasets[0].data,Status.otherSoldToursIncome]
        items.datasets[0].data.reduce(reducer) ===0? setpieValue(false):setpieValue(true)

        return { ...items };
      });
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownValue]);

  useEffect(() => {
    GetTopTours();
  }, [GetTopTours]);

  return (
    <div className='Locations'>
    <Spinner isActive={isLoading} isAbsolute />
      <div className='LocationsTitle'>
        <p className='LocationsPargraph'>{getTranslate()(`${translationPath}Top-sold-tours`)} </p>
        <div className='SoldTours-select'>
          <SelectComponet
            isOpen={isOpen}
            onOpen={openHandler}
            onClose={closeHandler}
            initialSelectValue={0}
            data={TotalToursEnum}
            value={dropDownValue}
            textInput='value'
            valueInput='key'
            translationPathForData={translationPath}
            onSelectChanged={(event) => {
              dropDwonDateRange(event.target.value);
             
            }}
          />
        </div>
      </div>

      <Grid container spacing={2}>
        <div className='chartjs'>
          {pieValue ? (
            <Pie
              data={chartData}
              options={{
                legend: {
                  display: false,
                },

                responsive: true,
              }}
            />
          ) : (
            <div className='NoData TotalSold'> </div>
          )}
        </div>
      </Grid>

      <div className='Contenater'>
        <div className='lables-Sold-wrapper'>
          <div className='Total-TourName'>
            <div className='sold-tourName'>{getTranslate()(`${translationPath}Tour-name`)} </div>
            <div className='sold-total'>{getTranslate()(`${translationPath}Total`)} </div>
          </div>

          {chartData.datasets &&
            chartData.labels.map((label, index) => (
              <div class Name='Soldline'>
                <div key={index} className='chart-item'>
                  <div>
                    <span
                      className='mdi mdi-circle-medium column'
                      style={{
                        color: `${chartData.datasets[0].backgroundColor[index]}`,
                      }}
                    />

                    <span>{label}</span>
                  </div>

                  <div>
                    {chartData &&
                      chartData.datasets[0] &&
                      chartData.datasets[0].data.length > index && (
                        <>
                          <span>{chartData.datasets[0].data[index]}</span>
                        </>
                      )}
                  </div>
                </div>
                <hr />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
