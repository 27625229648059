import config from '../Assets/Configration/Configration';

const signalR = require('@aspnet/signalr');

export function TrackingHub() {
  return new signalR.HubConnectionBuilder()
    .withUrl(`${config.wsAddress}/WsPSTViewing/TrackingHub?source=${config.source}`, {
      accessTokenFactory: () => `Bearer ${JSON.parse(localStorage.getItem('session')).token}`,
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .build();
}
