import React, { useReducer, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { getTranslate } from "../../../../../Helpers";

const translationPath = "Lookups.CreateLookupTypeDialog.";

export const LookupsTypesCreateDialog = (props) => {
  const reducer = (state, action) => {
    if (action.reset) return {};

    return { ...state, [action.id]: action.value };
  };
  const [state, setState] = useReducer(reducer, props.item);
  const validate = () =>
    state &&
    state.lookupTypeDescription &&
    state.lookupTypeDescription !== "" &&
    state.lookupTypeName &&
    state.lookupTypeName !== "";

  useEffect(() => {
    if (props.isEdit) {
      for (const lookupTypeId in props.item)
        setState({ id: lookupTypeId, value: props.item[lookupTypeId] });
    }
  }, [props.isEdit, props.item]);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.setOpen(false);
        setState({ reset: true });
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          props.setOpen(false);
          props.isEdit ? props.onSave(state) : props.onCreate(state);
          setState({ reset: true });
        }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          {getTranslate()(`${translationPath}LookupType`)}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} className="mb-3">
              <FormControl className="input-wrapper flex-wrap">
                <label htmlFor="lookupTypeName" className="texts-form">
                  {getTranslate()(
                    getTranslate()(`${translationPath}LookupName`)
                  )}
                </label>
                <TextField
                  required
                  className="inputs theme-form-builder"
                  id="lookupTypeName"
                  value={
                    state && state.lookupTypeName ? state.lookupTypeName : ""
                  }
                  onChange={(e) => setState(e.target)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="input-wrapper flex-wrap">
                <label htmlFor="lookupTypeName" className="texts-form">
                  {getTranslate()(
                    getTranslate()(`${translationPath}LookupDescription`)
                  )}
                </label>
                <TextField
                  id="lookupTypeDescription"
                  className="inputs theme-form-builder"
                  required
                  multiline
                  rowsMax={10}
                  value={
                    state && state.lookupTypeDescription
                      ? state.lookupTypeDescription
                      : ""
                  }
                  onChange={(e) => setState(e.target)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.setOpen(false);
              setState({ reset: true });
            }}
            className="btns theme-solid bg-cancel"
          >
            {getTranslate()(`${translationPath}cancel`)}
          </Button>
          <Button
            disabled={!validate()}
            className="btns theme-solid"
            type="submit"
          >
            {getTranslate()(`${translationPath}save`)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
