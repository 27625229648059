import React, { useEffect, useCallback,useState } from 'react';
import { getTranslate } from '../../../Helpers';
import { useTitle } from '../../../Hooks';
import { GetDefaultCarType } from '../../../Serviecs';
import { CarTypeActions } from '../../../Stores/Actions';
import { useDispatch } from 'react-redux';
import { DashboardTabEnum } from '../../../../src/Enums';
import './DashboardView.scss';
import {SubNavComponent} from './../DashboardView/DashboardUtilities/SubNavComponenet/SubNavComponent'
import {TourStatusComponent} from './DashboardUtilities/TourStatusComponent/TourStatusComponent';
import {TotalChartViewComponent} from './DashboardUtilities/TotalChartViewComponent/TotalChartViewComponent';
import {LocationsAndSoldTours} from './DashboardUtilities/LocationsAndSoldTours/LocationsAndSoldTours'
import {UsersStatsComponent} from './DashboardUtilities/UserStatsComponent/UsersStatsComponent'
import { UpComingTours } from './DashboardUtilities/UpComingToursComponent/UpComingTours';
import { CarsActivity } from './DashboardUtilities/CarsActivityComponent/CarsActivity';
import { UsersEngagement } from './DashboardUtilities/UsersEngagementComponent/UsersEngagement';
import { TotalSalesComponent } from './DashboardUtilities/TotalSalesComponent/TotalSalesComponent';
import { NewUserComponent } from './DashboardUtilities/NewUsersComponent/NewUserComponent';
import { TotalSourceComponent } from './DashboardUtilities/TotalSourceComponent/TotalSourceComponent';
import { Grid } from '@material-ui/core';
import { Locations } from './DashboardUtilities/LocationsAndSoldTours/Locations';
import { TotalTours } from './DashboardUtilities/TotalChartViewComponent/TotalTours';
import { TotalSales } from './DashboardUtilities/UserStatsComponent/TotalSales';
import { TotalSoldTours } from './DashboardUtilities/LocationsAndSoldTours/TotalSoldTours';
import { SoldTours } from './DashboardUtilities/LocationsAndSoldTours/SoldTours';
import { TotalDrivers } from './DashboardUtilities/TotalChartViewComponent/TotalDrivers';
import { TotalCars } from './DashboardUtilities/TotalChartViewComponent/TotalCars';
import { TotalUsers } from './DashboardUtilities/TotalChartViewComponent/TotalUsers';
import { WebsiteVisitors } from './DashboardUtilities/UserStatsComponent/WebsiteVisitors';
import { MobileAppVisitors } from './DashboardUtilities/UserStatsComponent/MobileAppVisitors';
import { TotalVisitor } from './DashboardUtilities/UserStatsComponent/TotalVisitors';

const translationPath = 'dashboard.';

export const DashboardView = () => {

  const [selectView,setSelectView] =useState(0)

  

  const size = useWindowSize();

  const dispatch = useDispatch();

  useTitle(getTranslate()(`${translationPath}dashboard`));

  const getCarType = useCallback(async () => {

    const response = await GetDefaultCarType();

    if (response) {
      dispatch(CarTypeActions.carTypeSuccess(response));
      localStorage.setItem('carTypeResponse', JSON.stringify(response));
    }
    
  }, [dispatch]);

  useEffect(() => {
    getCarType();
  }, [getCarType]);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState();
  
    useEffect(() => {
      function handleResize() {

        setWindowSize( window.innerWidth);
      }
      
 
      window.addEventListener("resize", handleResize);
      
      handleResize();
      
      
      return () => window.removeEventListener("resize", handleResize);
    }, []); 
  
    return windowSize;
  }


  return (

    <div className='view-wrapper dashboard-wrapper' >
      <SubNavComponent size={size} setSelectView={setSelectView} selectView={selectView}/>
{selectView===DashboardTabEnum.All.value &&
<div className='All'>
<div><TourStatusComponent/></div>  
<div><TotalChartViewComponent/></div>
<div><UsersStatsComponent/></div>
<div><LocationsAndSoldTours/></div>
<Grid container spacing={1} className='Tables-charts'>
<Grid  item xs={12} md={8}  lg={8} className='tables' >
<div className='UpComingTable'><UpComingTours/> </div>
<div className='CarsTable'><CarsActivity/></div>
<div className='UsersChart'><UsersEngagement/></div>
</Grid>
<Grid  item xs={12} md={4} lg={4} className='Charts'>
<div className='Sales'><TotalSalesComponent/></div>
<div className='New-User'><NewUserComponent/></div>
<div className='Total-Source'> <TotalSourceComponent/> </div>
</Grid>
</Grid>
</div>}

{selectView===DashboardTabEnum.Sales.value && 
  <Grid className='SalesComponent' container spacing={2} >
  
  <Grid xs={12} md={6} lg={4}>
<div className='TotalSales'><TotalSales/></div>
<div className='TotalSalesComponent'><TotalSalesComponent/> </div>
</Grid>

<Grid xs={12} md={6} lg={4}>
<div className='SoldTours'><SoldTours/> </div>

</Grid>
<Grid xs={12} md={6} lg={4}>

<div className='TotalSoldTours'><TotalSoldTours/> </div>
</Grid>
  </Grid>
}

{selectView===DashboardTabEnum.Tours.value &&
  <Grid className='ToursComponent' container spacing={2} >
  <Grid xs={12} md={6} lg={12}>
  <div className='TourStatusComponent'><TourStatusComponent/></div> 
  </Grid>
  
  <Grid xs={12} md={6} lg={4}>
  <div className='TotalTours'><TotalTours/> </div>
<div className='Locations-tours'><Locations/></div>
  </Grid>
  <Grid className='Cars-Tabel' xs={12} md={6} lg={8}>
  <div className='UpComingTours'><UpComingTours/> </div> 
  </Grid>



</Grid>}

{selectView===DashboardTabEnum.Users.value &&
<Grid className='Users' container >
<Grid container >

<Grid xs={12} md={6} lg={4}  className='WebsiteVisitors'><WebsiteVisitors/></Grid>
<Grid xs={12} md={6} lg={4} className='MobileAppVisitors'><MobileAppVisitors/></Grid>
<Grid xs={12} md={6} lg={4}  className='TotalVisitor'><TotalVisitor/></Grid>
</Grid>


<Grid container>
<Grid className='Users-Engag' xs={12} md={9} lg={7} >
<div className='UsersEngagement'><UsersEngagement/></div>
<div className='Users-Source'> 
<div className='TotalSourceComponent'><TotalSourceComponent/></div>
<div className='TotalUsers-Tab'><TotalUsers/></div>
</div>

</Grid>
<Grid className='Users-New'  xs={12} md={9} lg={5}>
<div className='NewUserComponent'><NewUserComponent/></div>

</Grid>


</Grid>

</Grid>}



{selectView===DashboardTabEnum.Cars.value &&
<Grid container spacing={2} className='CarsComponent'>
<Grid xs={12} md={6} lg={4}>
<div className='TotalDrivers'><TotalDrivers/></div>
<div className='TotalCars'><TotalCars/></div>
</Grid>
<Grid className='Cars-Tabel' xs={12} md={6} lg={8}>
<div className='UpComingTours'><UpComingTours/> </div>
<div className='CarsActivity'><CarsActivity/> </div>
</Grid>

</Grid>}
     
 </div>
  );
};
