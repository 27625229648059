import React from 'react'
import './SubNavComponent.scss';
import { ButtonBase } from '@material-ui/core';
import { getTranslate } from ' ./../../src/Helpers';
import { DashboardTabEnum } from '../../../../../Enums';

const translationPath = 'Sub-Nav.';


export const SubNavComponent=({size ,setSelectView,selectView})=> {
  

    return (

        

<div className="SubNav" style={{'--divWidth':size-100+'px'}}>
<div className="SubNavBorder">
<ButtonBase onClick={()=>setSelectView(DashboardTabEnum.All.value)} className={selectView===0 ?'btns theme-transparent c-warning':'btns theme-transparent c-black-light' }> {getTranslate()(`${translationPath}All`)}</ButtonBase>
<ButtonBase onClick={()=>setSelectView(DashboardTabEnum.Sales.value)}  className={selectView===1 ?'btns theme-transparent c-warning':'btns theme-transparent c-black-light' }> {getTranslate()(`${translationPath}Sales`)}</ButtonBase>
<ButtonBase  onClick={()=>setSelectView(DashboardTabEnum.Tours.value)} className={selectView===2 ?'btns theme-transparent c-warning':'btns theme-transparent c-black-light' }> {getTranslate()(`${translationPath}Tours`)}</ButtonBase>
<ButtonBase  onClick={()=>setSelectView(DashboardTabEnum.Users.value)} className={selectView===3 ?'btns theme-transparent c-warning':'btns theme-transparent c-black-light' }> {getTranslate()(`${translationPath}Users`)}</ButtonBase>
<ButtonBase  onClick={()=>setSelectView(DashboardTabEnum.Cars.value)} className={selectView===4?'btns theme-transparent c-warning':'btns theme-transparent c-black-light' }> {getTranslate()(`${translationPath}Cars`)}</ButtonBase>
</div>
</div>
    )
}
