import React from 'react';
import NotFound from '../../Assets/Images/defaults/404.png';
import { Grid } from '@material-ui/core';
import { getTranslate } from '../../Helpers';
import './NotFound.scss';
import { useTitle } from '../../Hooks/useTitle';

export const NotFoundLayout = () => {
  const translationPath = 'NotFoundViews.';
  useTitle(getTranslate()(`${translationPath}page-not-found`));
  return (
    navigator.onLine && (
      <Grid container className='no-data-result'>
        <Grid item className='no-data-text'>
          <h1 className='no-data-title'>404</h1>
          <h3 className='no-data-result-subtitle'>
            {getTranslate()(`${translationPath}Error-page-not-found`)}
          </h3>
        </Grid>
        <Grid item>
          <img
            src={NotFound}
            alt={getTranslate()(`${translationPath}not-found`)}
            className='no-data-img'
          />
        </Grid>
      </Grid>
    )
  );
};
