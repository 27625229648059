import { OfferedToursManagementView, OfferedToursView } from '../../Views/Home';

export const OffersRoutes = [
  {
    path: '/add',
    name: 'home.tours.offeredToursView.add-new-offer',
    component: OfferedToursManagementView,
    layout: '/home/offered-tours',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'home.tours.offeredToursView.offered-tours',
        isDisabled: false,
        route: '/home/offered-tours/view',
      },
      {
        name: 'home.tours.offeredToursView.add-new-offer',
        isDisabled: true,
        route: '/home/offered-tours/add',
      },
    ],
  },
  {
    path: '/edit',
    name: 'home.tours.offeredToursView.edit-offer',
    component: OfferedToursManagementView,
    layout: '/home/offered-tours',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'home.tours.offeredToursView.offered-tours',
        isDisabled: false,
        route: '/home/offered-tours/view',
      },
      {
        name: 'home.tours.offeredToursView.edit-offer',
        isDisabled: true,
        route: '/home/offered-tours/edit',
      },
    ],
  },
  {
    path: '/view',
    name: 'home.tours.offeredToursView.offered-tours',
    component: OfferedToursView,
    layout: '/home/offered-tours',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'home.tours.offeredToursView.offered-tours',
        isDisabled: false,
        route: '/home/offered-tours/view',
      },
    ],
  },
];
