import React from 'react';
import { getDownloadableLink, getTranslate } from '../../../../../../../Helpers';
import {
  Rating,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import './WebsitePointsToursView.scss';
import { Button, IconButton } from '@material-ui/core';
import { LoadableImageComponant } from '../../../../../../../Componentes';

export const PointsToursView = ({ translationPath, tours }) => {
  return (
    <div className='points-tours-wrapper'>
      <div className='points-tours-title'>
        {getTranslate()(`${translationPath}recommended-tours`)}
      </div>
      <div className='points-tours-content'>
        {tours &&
          tours.result.map((item, i) => {
            return (
              <div className='points-tours-cards' key={i}>
                <div className='points-card-title mb-1'>{item.tourName}</div>
                <div className='points-card-description mb-2'>
                  {getTranslate()(`${translationPath}price`)}:
                  <div className='tour-price'>
                    {item.totalFromPriceAfterDiscount}
                    {'  AED'}
                  </div>
                </div>
                <div className='points-card-timeline'>
                  <Timeline
                    className='timeline-wrapper'
                    align={localStorage.getItem('localization').isRtl ? 'right' : 'left'}>
                    {item.tourSummaryTrips.map((el) => (
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <div className='points-card-timeline-body'>
                            <div className='item-card-wrapper'>
                              <div className='rating-wrapper'>
                                <Rating value={el.totalTripRating} />
                                <div className='rating-amount'>
                                  {el.totalTripReview + '  '}
                                  {getTranslate()(`${translationPath}reviews`)}
                                </div>
                              </div>
                            </div>
                            <div className='item-card-image mt-2'>
                              <LoadableImageComponant
                                src={getDownloadableLink(el.tripCoverImage)}
                                alt={el.tripName}
                                classes='img-center'
                              />
                            </div>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </div>
                <div className='points-card-footer'>
                  <div className='points-card-add-cart'>
                    <Button>{getTranslate()(`${translationPath}add-to-cart`)}</Button>
                  </div>
                  <div className='favourite-heart'>
                    <IconButton>
                      <span className='mdi mdi-heart' />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
