import React from 'react';
import PropTypes from 'prop-types';
import { getDownloadableLink, getTranslate } from '../../../../../Helpers';
import {
  Rating,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { LoadableImageComponant } from '../../../../../Componentes';
import { DefaultImagesEnum, LoadableImageEnum } from '../../../../../Enums';
import './PendingTourDetailsComponent.scss';

export const PendingTourDetailsComponent = ({ data, translationPath }) => {
  return (
    <div className='pending-tour-details-component-wrapper c-black-light'>
      <div className='d-flex-v-center-h-between'>
        <span className='fz-16px texts-truncate px-2'>
          {data.tourDetailsDto && data.tourDetailsDto.tourName}
        </span>
      </div>
      <div className='d-flex-v-center mb-2'>
        <span className='mdi mdi-cash-multiple px-2' />
        <span>{getTranslate()(`${translationPath}total-price`)}:</span>
        <span className='px-2 texts-truncate'>
          {data.tourDetailsDto && data.tourDetailsDto.totalFromPrice}
        </span>
      </div>
      <div className='d-flex-v-center mb-2'>
        <span className='mdi mdi-cash-multiple px-2' />
        <span>{getTranslate()(`${translationPath}total-price-after-discount`)}:</span>
        <span className='px-2 texts-truncate'>
          {data.tourDetailsDto && data.tourDetailsDto.totalFromPriceAfterDiscount}
        </span>
      </div>
      <div className='trips-timeline-details-wrapper'>
        <Timeline
          className='timeline-wrapper'
          align={
            localStorage.getItem('localization') && localStorage.getItem('localization').isRtl
              ? 'right'
              : 'left'
          }>
          {data.tourDetailsDto &&
            data.tourDetailsDto.tourSummaryTrips &&
            data.tourDetailsDto.tourSummaryTrips.map((subItem, index) => (
              <TimelineItem key={`tripDetailsTimelineKey${index + 1}`}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                  <div className='cards-header'>
                    <div className='item-wrapper'>
                      <span className='item-header'>{subItem.tripName}</span>
                    </div>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        {subItem.totalTripRating !== null &&
                          subItem.totalTripRating !== undefined && (
                            <Rating
                              name='trip-rating'
                              defaultValue={5}
                              value={subItem.totalTripRating}
                              precision={0.5}
                              size='small'
                              icon={<span className='mdi mdi-star' />}
                              disabled
                              emptyIcon={<span className='mdi mdi-star-outline' />}
                            />
                          )}
                      </span>
                      <span className='item-body'>
                        {`${subItem.totalTripReview || 0} ${getTranslate()(
                          `${translationPath}reviews`
                        )}`}
                      </span>
                    </div>
                  </div>
                  <div className='cards-body'>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-clock-time-five px-2' />
                        <span>{getTranslate()(`${translationPath}duration`)}:</span>
                      </span>
                      <span className='item-body'>{subItem.tripDurationFormat || 'N/A'}</span>
                    </div>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-cash-multiple px-2' />
                        <span>{getTranslate()(`${translationPath}price`)}:</span>
                      </span>
                      <span className='item-body'>{subItem.tripPrice || 'N/A'}</span>
                    </div>
                    <div className='item-wrapper mb-3'>
                      <span className='item-header'>
                        <span className='mdi mdi-map-marker px-2' />
                        <span>{getTranslate()(`${translationPath}address`)}:</span>
                      </span>
                      <span className='item-body'>{subItem.address || 'N/A'}</span>
                    </div>
                  </div>
                  <div className='cards-footer'>
                    <LoadableImageComponant
                      classes='item-wrapper trip-cover-image'
                      type={LoadableImageEnum.div.key}
                      alt={getTranslate()(`${translationPath}trip-cover-image`)}
                      src={
                        (subItem.tripCoverImage && getDownloadableLink(subItem.tripCoverImage)) ||
                        DefaultImagesEnum.buildings.defaultImg
                      }
                    />
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      </div>
    </div>
  );
};

PendingTourDetailsComponent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  translationPath: PropTypes.string.isRequired,
};
