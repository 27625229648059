import React, { useState } from 'react';
import { Breadcrumb } from '../../Componentes';
import { Header, MainMenuView, OpenCloseView } from '../../Views/Home';
import {setLoading, setSideMenuIsOpenCallback, sideMenuIsOpenUpdate} from '../../Helpers/MeddilwareHelper';
import {SwitchRoute} from "../../Componentes/Route/SwitchRoute";
import {HomeRoutes} from "../../Routes/HomeRoutes";

const HomeLayout = () => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  setSideMenuIsOpenCallback(setSideMenuIsOpen);
    // eslint-disable-next-line no-unused-vars
    const [showLoading, setShowLoading] = React.useState(false);
  const [headerHeight, setHeaderHeight] = useState(70);
    const changeShowLoading = (flag) => {
        setShowLoading(flag);
    };
    setLoading(changeShowLoading);

  return (
    <>
      <Header headerHeightChanged={(hh) => setHeaderHeight(hh)} />
      <div className="container" style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}>
        <MainMenuView />
        <div className="content-wrapper">
          <Breadcrumb />
          <SwitchRoute routes={HomeRoutes} />
        </div>
        <OpenCloseView isOpen={sideMenuIsOpen} isOpenClicked={() => sideMenuIsOpenUpdate()} />
      </div>
    </>
  );
};

export { HomeLayout };
