import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import {
  getDownloadableLink,
  getHistory,
  getTranslate,
  languageChange,
  showError,
  showSuccess,
} from '../../../../Helpers';
import { CollapseComponent, Spinner } from '../../../../Componentes';
import { OrganizationUserSearch, uploadFile, UpdateMyProfileImage } from '../../../../Serviecs';
import { LoginActions } from '../../../../Stores/Actions';
import { bindActionCreators } from 'redux';

const FirstLettersExp = /\b(\w)/gm;

const translationLocation = 'home.headerView.userMenuView.';
const UserMenuView = memo(({ loginResponse, logout, UpdateAction }) => {
  const [imageReq, setImageReq] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [languageToggler, setLanguageToggler] = useState(false);
  const uploaderRef = useRef(null);
  const [languages] = useState([
    {
      key: 'en',
      value: `${translationLocation}english`,
    },
    {
      key: 'ar',
      value: `${translationLocation}arabic`,
    },
  ]);
  const fileChanged = useCallback(async (event) => {
    if (!event.target.value) return;
    console.log(event.target.files[0]);
    setIsLoading(true);
    const response = await uploadFile({ file: event.target.files[0] });
    if (response) {
      const profileImageRes = await UpdateMyProfileImage(response.uuid);
      if (profileImageRes) {
        console.log(profileImageRes);
        
        if (JSON.parse(localStorage.getItem('session')).userId === profileImageRes.id) {
          const updatedState = JSON.parse(localStorage.getItem('session'));
          const update = { ...updatedState, profileImg: profileImageRes.profileImg };
          localStorage.setItem('session', JSON.stringify(update));
          UpdateAction(update);
        }
        if(localStorage.getItem('activeUserItem') && JSON.parse(localStorage.getItem('activeUserItem')).id === profileImageRes.id){
          const updateActiveUser = JSON.parse(localStorage.getItem('activeUserItem'));
          localStorage.setItem('activeUserItem', JSON.stringify({...updateActiveUser, profileImg: profileImageRes.profileImg }));
          if (window.location.pathname.includes('home/Users/edit'))
          window.location.href = `/home/Users/edit?id=${profileImageRes.id}`;
        }
        showSuccess(getTranslate()(`${translationLocation}image-changed-successfully`));
      } else showError(getTranslate()(`${translationLocation}save-image-failed`));
      setIsLoading(false);
    } else {
      showError(getTranslate()(`${translationLocation}upload-image-failed`));
      setIsLoading(false);
    }
  }, [UpdateAction]);
  const getOrganizationUserSearch = useCallback(async () => {
    setIsLoading(true);
    const response = await OrganizationUserSearch({
      // userStatusId: 2,
      userName: loginResponse.userName,
      pageIndex: 0,
      pageSize: 100,
    });
    if (response && response.result) {
      setIsLoading(false);
      localStorage.setItem(
        'activeUserItem',
        JSON.stringify(response.result.find((item) => item.id === loginResponse.userId))
      );
      if (window.location.pathname.includes('home/Users/edit'))
        window.location.href = `/home/Users/edit?id=${loginResponse.userId}`;
      else getHistory().push(`/home/Users/edit?id=${loginResponse.userId}`);
    } else setIsLoading(false);
  }, [loginResponse]);
  const editProfileHandler = () => {
    getOrganizationUserSearch();
  };
  useEffect(() => {
    if (loginResponse) setImageReq(loginResponse);
  }, [loginResponse]);
  const languageClicked = useCallback(languageChange);

  const languageTogglerClicked = () => {
    setLanguageToggler(!languageToggler);
  };
  return (
    <div className="cards p-relative">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="card-content">
        <div className="d-flex-column-center">
          <div className="p-relative">
            {loginResponse && loginResponse.fullName && (!imageReq || !imageReq.profileImg) && (
              <Avatar className="avatars-wrapper mb-3">
                {loginResponse.fullName.match(FirstLettersExp).join('')}
              </Avatar>
            )}
            {imageReq && imageReq.profileImg && (
              <img
                src={getDownloadableLink(imageReq.profileImg)}
                alt={getTranslate()('home.headerView.user-image')}
                className="user-img"
              />
            )}
            <input
              type="file"
              className="d-none"
              onChange={fileChanged}
              accept="image/*"
              ref={uploaderRef}
            />
            <ButtonBase
              className="btns-icon theme-outline-dark btns-small user-btn"
              onClick={() => console.log(uploaderRef.current.click())}
            >
              <span className="mdi mdi-camera" />
            </ButtonBase>
          </div>
          <p className="c-primary">{loginResponse && loginResponse.fullName}</p>
          <ButtonBase className="btns theme-solid mb-2 editProfileBtn" onClick={editProfileHandler}>
            <span>{getTranslate()(`${translationLocation}edit-profile`)}</span>
          </ButtonBase>
          <div className="separator-h" />
          <ButtonBase className="btns theme-menu">
            <span className="icons i-activities" />{' '}
            <span className="mx-3">{getTranslate()(`${translationLocation}my-activities`)}</span>
          </ButtonBase>
          <div className="separator-h" />
          <ButtonBase className="btns theme-menu">
            <span className="mdi mdi-cog-outline" />{' '}
            <span className="mx-3">{getTranslate()(`${translationLocation}setting`)}</span>
          </ButtonBase>
          <div className="separator-h" />
          <ButtonBase className="btns theme-menu" onClick={languageTogglerClicked}>
            <span className="mdi mdi-translate" />{' '}
            <span className="mx-3">{getTranslate()(`${translationLocation}language`)}</span>
          </ButtonBase>
          <div className="separator-h mb-2" />

          <CollapseComponent
            isOpen={languageToggler}
            classes="w-100 px-3"
            component={
              <>
                {languages.map((item, index) => (
                  <React.Fragment key={`languages${item.key}`}>
                    <ButtonBase
                      className="btns theme-menu"
                      onClick={() => languageClicked(item.key)}
                    >
                      {getTranslate()(item.value)}
                    </ButtonBase>
                    <div
                      className={`separator-h${index === languages.length - 1 ? ' mb-2' : ''}`}
                    />
                  </React.Fragment>
                ))}
              </>
            }
          />
        </div>
      </div>
      <div className="card-footer">
        <ButtonBase className="btns theme-outline mb-2" onClick={logout}>
          <span>{getTranslate()(`${translationLocation}logout`)}</span>
        </ButtonBase>
      </div>
    </div>
  );
});
const mapStateToProps = (state) => {
  const {
    LoginReducer: { loginResponse },
  } = state;
  return {
    loginResponse,
  };
};
UserMenuView.propTypes = {
  loginResponse: PropTypes.shape(undefined),
  logout: PropTypes.func.isRequired,
};

UserMenuView.defaultProps = {
  loginResponse: null,
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateAction: bindActionCreators(LoginActions.update, dispatch),
  };
};
const store = connect(mapStateToProps, mapDispatchToProps)(UserMenuView);
export { store as UserMenuView };
