import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { PropTypes } from 'prop-types';
import { Inputs } from './Inputs';

function AutocompleteComponent({
  onChange,
  options,
  getOptionLabel,
  renderOption,
  defaultValue,
  isLoading,
  value,
  onKeyUp,
  onKeyDown,
  inputValue,
  onInputChange,
  getOptionDisabled,
  chipsLabel,
  chipsDisabled,
  translationPath,
  labelValue,
  inputPlaceholder,
  idRef,
  wrapperClasses,
  autocompleteClasses,
  variant,
  multiple,
  isDisabled,
  isRequired,
  inputClasses,
  inputWrapperClasses,
  helperText,
  error,
  isWithError,
  isSubmitted,
  inputLabel,
  withoutSearchButton,
  buttonOptions,
  disableClearable,
  getOptionSelected,
  renderTags,
  overInputTextIcon,
  inputStartAdornment,
  filterOptions
}) {
  return (
    <div className={wrapperClasses}>
      {!withoutSearchButton && (
        // <Button className="btns-icon theme-transparent mx-2">
        <span className="c-black-light mdi mdi-magnify mdi-24px mx-1" />
        // </Button>
      )}
      <Autocomplete
        multiple={multiple}
        disableClearable={disableClearable}
        id={idRef}
        className={autocompleteClasses}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        defaultValue={defaultValue}
        loading={isLoading}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onChange={onChange}
        getOptionDisabled={getOptionDisabled}
        disabled={isDisabled}
        filterOptions={filterOptions}
        renderTags={
          renderTags ||
          ((tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                className="autocomplete-chip"
                label={chipsLabel && chipsLabel(option, index)}
                key={`${idRef}chip${index + 1}`}
                {...getTagProps({ index })}
                disabled={chipsDisabled(option, index)}
              />
            )))
        }
        renderInput={(params) => (
          <Inputs
            idRef={idRef}
            label={inputLabel}
            labelValue={labelValue}
            autoCompleteParams={params}
            inputPlaceholder={inputPlaceholder}
            variant={variant}
            isWithError={isWithError}
            isSubmitted={isSubmitted}
            // renderParams={params}
            isRequired={isRequired}
            wrapperClasses={inputWrapperClasses}
            fieldClasses={inputClasses}
            startAdornment={inputStartAdornment}
            overInputTextIcon={overInputTextIcon}
            translationPath={translationPath}
            buttonOptions={buttonOptions}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </div>
  );
}
AutocompleteComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionSelected: PropTypes.func,
  disableClearable: PropTypes.bool,
  chipsLabel: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  defaultValue: PropTypes.instanceOf(Array),
  isLoading: PropTypes.bool,
  multiple: PropTypes.bool,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  getOptionDisabled: PropTypes.func,
  renderOption: PropTypes.func,
  renderTags: PropTypes.func,
  chipsDisabled: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  translationPath: PropTypes.string,
  labelValue: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  idRef: PropTypes.string,
  wrapperClasses: PropTypes.string,
  autocompleteClasses: PropTypes.string,
  variant: PropTypes.string,
  inputWrapperClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  helperText: PropTypes.string,
  inputLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  isWithError: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  inputStartAdornment: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
  overInputTextIcon: PropTypes.string,
  withoutSearchButton: PropTypes.bool,
  buttonOptions: PropTypes.shape({
    className: PropTypes.string,
    iconClasses: PropTypes.string,
    onActionClicked: PropTypes.func,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
};
AutocompleteComponent.defaultProps = {
  defaultValue: undefined,
  value: undefined,
  isLoading: false,
  inputValue: undefined,
  onInputChange: undefined,
  getOptionSelected: undefined,
  renderTags: undefined,
  getOptionDisabled: undefined,
  renderOption: undefined,
  chipsDisabled: () => false,
  translationPath: '',
  labelValue: undefined,
  inputPlaceholder: undefined,
  overInputTextIcon: undefined,
  idRef: 'autocompleteRef',
  wrapperClasses: 'autocomplete-wrapper px-0',
  autocompleteClasses: 'autocomplete theme-solid-border',
  variant: 'outlined',
  inputWrapperClasses: 'w-100',
  inputClasses: 'inputs theme-solid-border w-100',
  withoutSearchButton: false,
  chipsLabel: undefined,
  onKeyUp: undefined,
  onKeyDown: undefined,
  helperText: undefined,
  isWithError: false,
  isSubmitted: false,
  inputLabel: undefined,
  multiple: true,
  isDisabled: false,
  isRequired: false,
  error: false,
  buttonOptions: undefined,
  inputStartAdornment: undefined,
  disableClearable: false,
};
export { AutocompleteComponent };
