import React from 'react';
import NoSearchResult from '../../Assets/Images/defaults/NoSearchResult.png';
import './NoSearchResult.scss';
import { getTranslate } from '../../Helpers';
import { Grid } from '@material-ui/core';

export const NoSearchResultComponent = () => {
  const translationPath = 'NotFoundViews.';

  return (
    navigator.onLine && (
      <Grid container className='no-search-result'>
        <Grid item className='no-result-text'>
          <h1 className='no-result-title'>{getTranslate()(`${translationPath}No-Result-Found`)}</h1>
          <h3 className='no-search-result-subtitle'>
            {getTranslate()(`${translationPath}Try-to-search-with-different-keywords`)}
          </h3>
        </Grid>
        <Grid item>
          <img
            src={NoSearchResult}
            alt={getTranslate()(`${translationPath}No-Result-Found`)}
            className='no-search-img'
          />
        </Grid>
      </Grid>
    )
  );
};
