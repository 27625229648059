import { CarActivitiesView } from '../../Views/Home';

export const MobilePagesRoutes = [
  {
    path: '/view',
    name: 'home.mobilePages.carActivitiesView.car-activities',
    component: CarActivitiesView,
    layout: '/home/car-activities',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'home.mobilePages.carActivitiesView.car-activities',
        isDisabled: false,
        route: '/home/car-activities/view',
      },
    ],
  },
];
