import React, { useEffect, useCallback, useState, useRef } from 'react';
import { getTranslate } from ' ./../../src/Helpers';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';
import { useTitle } from '../../../Hooks/useTitle';
import { InvoiceCard, InvoiceDetailes } from './InvoiceUtilities';
import { GetAllInvoices } from '../../../Serviecs';
// import { InvoiceEnum } from '../../../Enums';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { addDays } from 'date-fns';
import moment from 'moment';
import {
  Button,
  ButtonBase,
  FormControl,
  InputAdornment,
  Popover,
  TextField,
} from '@material-ui/core';
import {
  Spinner,
  DateRangePickerComponent,
  NoDataFoundComponent,
  NoSearchResultComponent,
  NoContentComponent,
  AutocompleteComponent,
  // SelectComponet,
} from '../../../Componentes';
import './InvoiceView.scss';
const translationPath = 'Invoice.';

const valuetext = (value) => {
  return value;
};
const marks = [
  {
    value: 1000,
    label: '1000',
  },
  {
    value: 5000,
    label: '5000',
  },
  {
    value: 10000,
    label: '10000',
  },
  {
    value: 15000,
    label: '15000',
  },
  {
    value: 20000,
    label: '20000',
  },
];

export const InvoiceView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const filterDefault = {
    fromDate: null,
    toDate: null,
    priceFrom: null,
    priceTo: null,
    search: '',
    invoiceNumber: '',
  };
  const dateRangeDefault = {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
  };
  const [filter, setFilter] = useState(filterDefault);
  const [responseResult, setResponseResult] = useState(() => ({ result: [], totalCount: 0 }));
  const [dateRange, setDateRange] = useState(dateRangeDefault);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [, setActiveItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [priceValue, setPriceValue] = React.useState([5000, 10000]);
  const [priceFrom, setPriceFrom] = useState(100);
  const [priceTo, setPriceTo] = useState(20000);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [reloaded] = useState(false);
  const [searchType, setSearchType] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  // const [paymentMethod, setPaymentMethod] = useState(-1);
  const searchTimer = useRef(null);
  const open = Boolean(anchorEl);
  const openPrice = Boolean(anchorE2);
  useTitle(getTranslate()(`${translationPath}Invoice`));

  const GetAllInvoice = useCallback(
    async (pageIndex, pageSize) => {
      setIsLoading(true);
      const res = await GetAllInvoices(pageIndex, pageSize, filter);
      if (res && res.totalCount === 0) {
        setIsFirstLoad(false);
      }
      if (reloaded) setResponseResult({ result: [], totalCount: 0 });
      else {
        if (page === 0)
          setResponseResult({
            result: (res && res.result) || [],
            totalCount: (res && res.totalCount) || 0,
          });
        else {
          setResponseResult((item) => ({
            result: (item && item.result && item.result.concat((res && res.result) || [])) || [],
            totalCount: (res && res.totalCount) || 0,
          }));
        }
      }

      setIsLoading(false);
    },
    [filter, page, reloaded]
  );

  const onCardClicked = (item) => {
    setActiveItem(item);
    sideMenuComponentUpdate(
      <InvoiceDetailes activeItem={item} filter={filter} translationPath={translationPath} />
    );
    sideMenuIsOpenUpdate(true);
  };

  useEffect(() => {
    GetAllInvoice(page + 1, rowsPerPage);
  }, [GetAllInvoice, page, rowsPerPage]);

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePriceClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handlePriceClose = () => {
    setAnchorE2(null);
  };

  const handlePriceChange = (e, newValue) => {
    setPriceValue(newValue);
    setPriceFrom(newValue[0]);
    setPriceTo(newValue[1]);
  };

  return (
    <div className='view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'></div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <AutocompleteComponent
                idRef='invoiceSearchRef'
                translationPath={translationPath}
                options={[
                  {
                    key: 1,
                    label: getTranslate()(`${translationPath}Search`),
                    value: searchInputValue,
                  },
                  {
                    key: 2,
                    label: getTranslate()(`${translationPath}invoice-number`),
                    value: searchInputValue,
                  },
                ]}
                getOptionLabel={(option) => `${option.label}: ${option.value || ''}`}
                inputPlaceholder='Search'
                inputClasses='inputs theme-solid'
                getOptionSelected={(option) => searchType && searchType.key === option.key}
                getOptionDisabled={(option) => {
                  if (!option.value) return true;
                  if (
                    option.key === 2 &&
                    !(
                      option.value.toLowerCase().startsWith('inv') &&
                      option.value.toLowerCase().includes('/') &&
                      option.value.indexOf('/') !== option.value.length - 1 &&
                      !isNaN(
                        option.value.substring(option.value.indexOf('/') + 1, option.value.length)
                      )
                    ) &&
                    isNaN(option.value)
                  )
                    return true;
                  return false;
                }}
                value={searchType}
                inputValue={searchInputValue}
                onInputChange={(event) => {
                  setSearchInputValue(
                    (event && event.target && event.target.value) ||
                      (searchType && searchType.value) ||
                      ''
                  );
                  if (
                    event &&
                    event.target &&
                    searchType &&
                    searchType.value !== event.target.value
                  )
                    setSearchType(null);
                }}
                // onKeyUp={searchHandler}
                onChange={(e, newValue) => {
                  setSearchType(newValue);
                  let toSendValue = null;
                  if (newValue)
                    if (
                      (newValue.value.toLowerCase().startsWith('inv') &&
                        newValue.value.toLowerCase().includes('/') &&
                        newValue.value.indexOf('/') !== newValue.value.length - 1 &&
                        !isNaN(
                          newValue.value.substring(
                            newValue.value.indexOf('/') + 1,
                            newValue.value.length
                          )
                        )) ||
                      newValue.key === 2
                    )
                      if (isNaN(newValue.value))
                        toSendValue = {
                          key: 2,
                          value: +newValue.value.substring(
                            newValue.value.indexOf('/') + 1,
                            newValue.value.length
                          ),
                        };
                      else toSendValue = { key: 2, value: +newValue.value };
                    else toSendValue = { key: 1, value: newValue.value };
                  setPage(0);
                  setFilter((items) => ({
                    ...items,
                    search: (toSendValue && toSendValue.key === 1 && toSendValue.value) || null,
                    invoiceNumber:
                      (toSendValue &&
                        toSendValue.key === 2 &&
                        toSendValue &&
                        toSendValue.value !== 0 &&
                        toSendValue.value) ||
                      0 ||
                      null,
                  }));
                }}
                multiple={false}
                // withoutSearchButton
              />
              {/* <FormControl className='input-wrapper'>
                <TextField
                  className='inputs theme-solid'
                  value={searchedItem}
                  onKeyUp={() => {
                    time = setTimeout(() => {
                      setFilter((item) => ({ ...item, search: searchedItem }));
                    }, 500);
                  }}
                  onKeyDown={() => {
                    clearTimeout(time);
                  }}
                  onChange={(e) => setSearchedItem(e.target.value)}
                  label={getTranslate()(`${translationPath}Search`)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {searchedItem && (
                          <IconButton
                            onClick={() => {
                              setSearchedItem('');
                              setFilter((item) => ({ ...item, search: '' }));
                            }}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl> */}
            </div>
          </div>
        </div>
        <div className='second-filter-section pt-2 c-black-light px-2'>
          <span className='text-nowrap px-2'>{getTranslate()(`${translationPath}filter-by`)}</span>
          <div className=''>
            <div className='px-2'>
              <FormControl className='input-wrapper flex-wrap inputs theme-solid'>
                <TextField
                  variant='outlined'
                  value={
                    dateRange &&
                    `From: ${moment(dateRange.startDate).format('DD/MM/yyyy')}, To: ${moment(
                      dateRange.endDate
                    ).format('DD/MM/yyyy')}`
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <span className='mdi mdi-calendar-range' />
                        {getTranslate()(`${translationPath}date`)}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end' className='mdi-icons-color'>
                        {open ? (
                          <span className='mdi mdi-chevron-up' />
                        ) : (
                          <span className='mdi mdi-chevron-down' />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onClick={(e) => handleClick(e)}
                />
                <Popover
                  className='invoice-container'
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}>
                  <div className='btns-container'>
                    <Button
                      onClick={() => {
                        setFilter((item) => ({
                          ...item,
                          fromDate: '',
                          toDate: '',
                        }));
                      }}>
                      {getTranslate()(`${translationPath}all-dates`)}
                    </Button>
                    <DateRangePickerComponent
                      ranges={[dateRange]}
                      onDateChanged={(selectedDate) => {
                        setDateRange((items) => ({
                          ...items,
                          startDate: selectedDate.selection.startDate,
                          endDate: selectedDate.selection.endDate,
                        }));
                        setFilter((item) => ({
                          ...item,
                          fromDate: selectedDate.selection.startDate,
                          toDate: selectedDate.selection.endDate,
                        }));
                      }}
                    />
                    <Button
                      onClick={() => {
                        setDateRange((items) => ({
                          ...items,
                          startDate: new Date(),
                          endDate: new Date(),
                        }));
                        setFilter((item) => ({
                          ...item,
                          fromDate: new Date(),
                          toDate: new Date(),
                        }));
                      }}>
                      {getTranslate()(`${translationPath}today`)}
                    </Button>
                    <Button
                      onClick={() => {
                        const date = new Date();
                        date.setDate(date.getDate() - 1);
                        setDateRange((items) => ({
                          ...items,
                          startDate: date,
                          endDate: date,
                        }));
                        setFilter((item) => ({
                          ...item,
                          fromDate: date,
                          toDate: date,
                        }));
                      }}>
                      {getTranslate()(`${translationPath}yesterday`)}
                    </Button>
                    <Button
                      onClick={() => {
                        const date = new Date();
                        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        setDateRange((items) => ({
                          ...items,
                          startDate: firstDay,
                          endDate: lastDay,
                        }));
                        setFilter((item) => ({
                          ...item,
                          fromDate: firstDay,
                          toDate: lastDay,
                        }));
                      }}>
                      {getTranslate()(`${translationPath}this-month`)}
                    </Button>
                    <Button
                      onClick={() => {
                        const date = new Date();
                        const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                        const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
                        setDateRange((items) => ({
                          ...items,
                          startDate: firstDay,
                          endDate: lastDay,
                        }));
                        setFilter((item) => ({
                          ...item,
                          fromDate: firstDay,
                          toDate: lastDay,
                        }));
                      }}>
                      {getTranslate()(`${translationPath}past-month`)}
                    </Button>
                    <Button
                      onClick={() => {
                        const date = new Date();
                        const firstDay = new Date(date.getFullYear(), date.getMonth() - 3, 1);
                        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        setDateRange((items) => ({
                          ...items,
                          startDate: firstDay,
                          endDate: lastDay,
                        }));
                        setFilter((item) => ({
                          ...item,
                          fromDate: firstDay,
                          toDate: lastDay,
                        }));
                      }}>
                      {getTranslate()(`${translationPath}past-3-month`)}
                    </Button>
                  </div>
                </Popover>
              </FormControl>
            </div>
          </div>
          <div className='px-2'>
            <FormControl className='input-wrapper flex-wrap inputs theme-solid'>
              <TextField
                variant='outlined'
                value={
                  getTranslate()(`${translationPath}from`) +
                  priceFrom +
                  '  ' +
                  getTranslate()(`${translationPath}to`) +
                  priceTo
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <span className='mdi mdi-cash' />
                      {getTranslate()(`${translationPath}price`)}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end' className='mdi-icons-color'>
                      {open ? (
                        <span className='mdi mdi-chevron-up' />
                      ) : (
                        <span className='mdi mdi-chevron-down' />
                      )}
                    </InputAdornment>
                  ),
                }}
                onClick={(e) => handlePriceClick(e)}
              />
              <Popover
                className='invoice-container'
                open={openPrice}
                anchorEl={anchorE2}
                onClose={handlePriceClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <div className='price-slider'>
                  <Typography id='range-slider' gutterBottom>
                    {getTranslate()(`${translationPath}price`)}
                  </Typography>
                  <Slider
                    onClick={() => {
                      searchTimer.current = setTimeout(() => {
                        setFilter((items) => ({
                          ...items,
                          priceFrom: priceFrom,
                          priceTo: priceTo,
                        }));
                      }, 300);
                    }}
                    marks={marks}
                    min={100}
                    step={10}
                    max={20000}
                    value={priceValue}
                    onChange={handlePriceChange}
                    getAriaValueText={valuetext}
                  />
                </div>
              </Popover>
            </FormControl>
          </div>
          {/* <div className='px-2 section'>
            <SelectComponet
              idRef='agentStatusRef'
              data={InvoiceEnum}
              emptyItem={{
                value: -1,
                text: 'none',
              }}
              valueInput='key'
              textInput='value'
              classes='theme-solid filter-changes'
              paddingReverse='5rem'
              value={paymentMethod}
              overInputText='payment-method'
              onSelectChanged={(event) => {
                setFilter((items) => ({ ...items, paymentMethod: event.target.value }));
                setPaymentMethod(event.target.value);
              }}
              overInputTextIcon='mdi mdi-circle-medium px-1'
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div> */}
          <div className=''>
            <ButtonBase
              className='btns theme-solid'
              onClick={() => {
                setDateRange(dateRangeDefault);
                setPriceFrom(100);
                setPriceTo(20000);
                // setPaymentMethod(-1);
                setFilter(filterDefault);
              }}>
              <span>{getTranslate()(`${translationPath}reset`)}</span>
            </ButtonBase>
          </div>
          <div className='section' />
        </div>
      </div>
      <NoDataFoundComponent />
      {responseResult &&
        responseResult.result &&
        responseResult.result.length === 0 &&
        !filter.search &&
        !filter.invoiceNumber &&
        !isFirstLoad && <NoContentComponent />}
      {responseResult &&
        responseResult.totalCount === 0 &&
        ((filter.search && filter.search.length !== 0) ||
          (filter.invoiceNumber && filter.invoiceNumber.length !== 0)) &&
        !isFirstLoad && <NoSearchResultComponent />}
      <InvoiceCard
        data={responseResult}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        filter={filter}
        onCardClicked={onCardClicked}
        translationPath={translationPath}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};
