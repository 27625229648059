import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Spinner,
  Inputs,
  DialogComponent,
  NoContentComponent,
  NoDataFoundComponent,
} from './../../../Componentes';
import {
  getTranslate,
  getHistory,
  showSuccess,
  showError,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from '../../../Helpers';
import { Button } from '@material-ui/core';
import { ActionsEnum } from '../../../Enums';
import { DeleteTour, GetAllReadyTours } from '../../../Serviecs';
import { ToursCardComponent, TourDetailsComponent } from './ToursUtilities';
import { useTitle } from '../../../Hooks/useTitle';
const translationPath = 'home.tours.toursView.';

export const ToursView = () => {
  // const [activeActionType, setActiveActionType] = useState(ViewTypesEnum.cards.key);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [filter, setFilter] = useState({
    pageSize: 10,
    pageIndex: 0,
    search: '',
  });
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [tours, setTours] = useState({ result: [], totalCount: 0 });
  const searchTimer = useRef(null);
  useTitle(getTranslate()(`${translationPath}tours`));

  // const onTypeChanged = (activeType) => {
  //   setActiveActionType(activeType);
  // };
  const addNewTour = () => {
    getHistory().push('/home/tours/add');
  };
  const getTours = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllReadyTours(filter);
    if (!res || (res && res.totalCount === 0)) {
      setisFirstLoad(false);
    }
    if (filter.pageIndex === 0) {
      setTours({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setTours((item) => ({
        result: item.result.concat((res && res.result) || []),
        totalCount: (res && res.totalCount) || 0,
      }));
    }
    setIsLoading(false);
  }, [filter]);
  const deleteHandler = useCallback(async () => {
    setIsLoading(true);
    const res = await DeleteTour(activeItem.tourId);
    if (res !== undefined) {
      showSuccess(getTranslate()(`${translationPath}tour-deleted-successfully`));
      setFilter((items) => ({ ...items, pageIndex: 0 }));
    } else showError(getTranslate()(`${translationPath}tour-delete-failed`));
    setIsOpenConfirm(false);
    setIsLoading(false);
  }, [activeItem.tourId]);
  const onActionClicked = useCallback(
    (actionEnum, item) => (event) => {
      event.stopPropagation();
      if (actionEnum === ActionsEnum.reportEdit.key)
        getHistory().push(`/home/tours/edit?id=${item.tourId}`);
      else {
        setIsOpenConfirm(true);
        setActiveItem(item);
      }
    },
    []
  );
  const onCardClicked = useCallback(
    (item) => () => {
      sideMenuComponentUpdate(
        <TourDetailsComponent
          tourId={item.tourId}
          translationPath={translationPath}
          onDeleteClicked={async (item) => {
            setActiveItem(item);
            setIsOpenConfirm(true);
          }}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    []
  );
  const onLoadMoreHandler = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: item.pageIndex + 1 }));
  }, []);
  const searchHandler = (e) => {
    const value = e.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((items) => ({ ...items, pageIndex: 0, search: value }));
    }, 700);
  };
  useEffect(() => {
    getTours();
  }, [getTours, filter]);
  useEffect(() => {
    return () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    };
  }, []);

  return (
    <div className='tours-view view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <Button className='btns theme-solid' onClick={addNewTour}>
              {getTranslate()(`${translationPath}add-new-tour`)}
            </Button>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <Inputs
                idRef='toursSearchRef'
                variant='outlined'
                fieldClasses='inputs theme-solid'
                translationPath={translationPath}
                label='filter'
                beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                onKeyUp={searchHandler}
                inputPlaceholder='search-by-tour-name'
              />
            </div>
            {/* <div>
              <ViewTypes
                onTypeChanged={onTypeChanged}
                activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
                className="mb-3"
              />
            </div> */}
          </div>
        </div>
      </div>
      <NoDataFoundComponent />
      {((tours && !Array.isArray(tours.result)) || tours.result.length === 0) &&
        !isFirstLoad &&
        !filter.search && <NoContentComponent />}
      <ToursCardComponent
        data={tours}
        filter={filter}
        onLoadMore={onLoadMoreHandler}
        onActionClicked={onActionClicked}
        onCardClicked={onCardClicked}
        translationPath={translationPath}
        isLoading={isLoading}
        isFirstLoad={isFirstLoad}
      />
      <DialogComponent
        titleText='confirm-message'
        saveText='confirm'
        saveType='button'
        maxWidth='sm'
        dialogContent={
          <div className='d-flex-column-center'>
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span>{getTranslate()(`${translationPath}delete-description`)}</span>
          </div>
        }
        saveClasses='btns theme-solid bg-danger w-100 mb-0 mx-0 br-0'
        isOpen={isOpenConfirm}
        onSaveClicked={deleteHandler}
        translationPath={translationPath}
        onCloseClicked={() => setIsOpenConfirm(false)}
        onCancelClicked={() => setIsOpenConfirm(false)}
      />
    </div>
  );
};
