/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useEffect } from 'react';
import { getDownloadableLink, getTranslate } from '../../../../../Helpers';
import { IconButton } from '@material-ui/core';
import { getLanguage } from 'react-switch-lang';
import { GetAllToursBySectionId } from '../../../../../Serviecs';
import { LoadableImageComponant } from '../../../../../Componentes';
import moment from 'moment';
import './SectionReorderCards.scss';

export const SectionReorderCards = ({
  data,
  section,
  draggable,
  translationPath,
  onDrag,
  dragEnd,
  touchEnd,
  onDragOver,
  onTouchMove,
  dragFrom,
  currentDragOverItem,
  onDragItem,
}) => {
  const [cardCount, setCardCount] = useState([2]);
  const [toursCount, setToursCount] = useState([]);
  const [sectionInfo, setSectionInfo] = useState([]);

  const dragHandler = useCallback(
    (item, index) => (event) => {
      if (onDrag) onDrag(item, index, event, dragFrom);
    },
    [onDrag, dragFrom]
  );

  useEffect(() => {
    if (data && data.result)
      setCardCount(() => {
        const count = [];
        data.result.map(() => count.push(2));
        return count;
      });
  }, [data]);

  const handelImageClick = useCallback(
    (value, item, index) => async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setCardCount((items) => {
        items[index] += value;
        return [...items];
      });

      const result = await GetAllToursBySectionId(
        cardCount[index] - 1,
        1,
        item.applicationSectionId
      );
      setSectionInfo((items) => {
        const info = items.findIndex((el) => el.applicationSectionId === item.applicationSectionId);
        if (info === -1) {
          items.push({
            applicationSectionId: item.applicationSectionId,
            tourName: result && result.result[0].tourName,
            coverTripImageId: result && result.result[0].coverTripImageId,
            dragFrom,
          });
        } else {
          items[info].applicationSectionId = item.applicationSectionId;
          items[info].tourName = result && result.result[0].tourName;
          items[info].coverTripImageId = result && result.result[0].coverTripImageId;
        }
        return [...items];
      });

      setToursCount((items) => {
        items[index] = result && result.totalCount;
        return [...items];
      });
    },
    [cardCount, dragFrom]
  );

  const getInfo = useCallback(
    (id) => {
      return sectionInfo.find(
        (item) => item.applicationSectionId === id && item.dragFrom === dragFrom
      );
    },
    [dragFrom, sectionInfo]
  );

  const CardBody = ({ item, index }) => (
    <div
      className={`${section === 'unpublish' ? 'unpublish-card-wrapper' : 'trips-card-wrapper'}`}
      key={`card${index + 1}`}>
      <div>
        <a
          draggable={draggable}
          className={`drag-card-wrapper tour-padding${(draggable && ' is-draggable') || ''}`}
          data-id={item.applicationSectionId}
          data-from={dragFrom}
          onDragOver={(onDragOver && onDragOver(index, dragFrom)) || undefined}
          onDrag={(draggable && dragHandler(item, index)) || undefined}
          onTouchStart={(draggable && dragHandler(item, index)) || undefined}
          onTouchMove={(draggable && onTouchMove(item, index)) || undefined}
          onDragEnd={(draggable && dragEnd) || undefined}
          onTouchEnd={(draggable && touchEnd) || undefined}>
          <div className='trip-card-wrapper' key={`card${index + 1}`}>
            <div className='cards-wrapper tour-padding'>
              <div className='cards-header'>
                <div className='trip-cover-image'>
                  <div className='section-cover-wrapper'>
                    <div className='section-cover-image'>
                      <div className='loadable-image-name'>
                        <LoadableImageComponant
                          src={getDownloadableLink(
                            !getInfo(item.applicationSectionId)
                              ? (item&&item.applicationSectionTour&&item.applicationSectionTour.coverTripImageId)||''
                              : getInfo(item.applicationSectionId).coverTripImageId
                          )}
                          alt={
                            !getInfo(item.applicationSectionId)
                              ?  (item&&item.applicationSectionTour&&item.applicationSectionTour.tourName)||''
                              : getInfo(item.applicationSectionId).tourName
                          }
                          classes='section-load-image'
                        />
                        <div className='tour-name-wrapper'>
                          <div className='tour-number'>
                            {!getInfo(item.applicationSectionId)
                              ?  (item&&item.applicationSectionTour&&item.applicationSectionTour.tourName)||''
                              : getInfo(item.applicationSectionId).tourName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='section-cover-placeholder'></div>
                    <div className='section-cover-placeholder2'></div>
                    <div
                      className={`${
                        toursCount[index] === cardCount[index]
                          ? 'section-cover-button-right-disabled'
                          : 'section-cover-button-right'
                      }`}>
                      <IconButton
                        className={`${
                          getLanguage() !== 'ar' ? 'mdi mdi-chevron-right' : 'mdi mdi-chevron-left'
                        }`}
                        disabled={toursCount[index] === cardCount[index]}
                        onClick={handelImageClick(1, item, index)}
                      />
                    </div>
                    <div
                      className={`${
                        !cardCount[index] ||
                        cardCount[index] <= 2 ||
                        !getInfo(item.applicationSectionId)
                          ? 'section-cover-button-left-disabled'
                          : 'section-cover-button-left'
                      }`}>
                      <IconButton
                        className={`${
                          getLanguage() !== 'ar' ? 'mdi mdi-chevron-left' : 'mdi mdi-chevron-right'
                        }`}
                        disabled={
                          !cardCount[index] ||
                          cardCount[index] <= 2 ||
                          !getInfo(item.applicationSectionId)
                        }
                        onClick={handelImageClick(-1, item, index)}
                      />
                    </div>
                    {(item.endDate === null || item.startDate === null) && (
                      <div className='all-time-section'>
                        {getTranslate()(`${translationPath}all-time`)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='cards-footer'>
                <div className='item-wrapper'>
                  <span className='item-header px-1'>{item.name}</span>
                </div>
              </div>

              <div className='cards-body'>
                <div className='item-wrapper'>
                  <span className='item-header'>
                    <span className='mdi mdi-calendar px-2' />
                    <span>{getTranslate()(`${translationPath}view-from`)}:</span>
                  </span>
                  <span className='item-body card-date'>
                    {!item.startDate
                      ? getTranslate()(`${translationPath}all-time`)
                      : moment(item.startDate).format('DD/MM/YYYY')}
                  </span>
                </div>

                <div className='item-wrapper'>
                  <span className='item-header'>
                    <span className='mdi mdi-clock-time-five px-2' />
                    <span>{getTranslate()(`${translationPath}num-tours`)}:</span>
                  </span>
                  <span className='item-body num-of-tours'>{item.numberOfTours}</span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );

  return (
    <div className='sections-cards-conponent-wrapper'>
      {data &&
        data.result &&
        data.result.map((item, index) => (
          <React.Fragment key={`${index}-card`}>
            {currentDragOverItem &&
              index === currentDragOverItem.index &&
              (currentDragOverItem.dragOver === onDragItem.from ||
                (currentDragOverItem.dragOver === onDragItem.from &&
                  currentDragOverItem.index - 1 !== onDragItem.index &&
                  currentDragOverItem.index !== onDragItem.index)) &&
              currentDragOverItem.dragOver === dragFrom && (
                <span
                  className={`drop-navigator${(currentDragOverItem.isBefore && ' is-drop') || ''}${
                    (false && ' not-allowed') || ''
                  }`}>
                  {getTranslate()(`${translationPath}drop-here`)}
                </span>
              )}
            <CardBody item={item} index={index} />
            {!currentDragOverItem ||
              (currentDragOverItem &&
                index === currentDragOverItem.index &&
                (currentDragOverItem.dragOver !== onDragItem.from ||
                  (currentDragOverItem.dragOver === onDragItem.from &&
                    currentDragOverItem.index !== onDragItem.index &&
                    currentDragOverItem.index + 1 !== onDragItem.index)) &&
                currentDragOverItem.dragOver === dragFrom && (
                  <span
                    className={`drop-navigator${
                      (!currentDragOverItem.isBefore && ' is-drop') || ''
                    }${(false && ' not-allowed') || ''}`}>
                    <span>{getTranslate()(`${translationPath}drop-here`)}</span>
                  </span>
                ))}
          </React.Fragment>
        ))}
    </div>
  );
};
