/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useReducer } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone-uploader';
import GetAppIcon from '@material-ui/icons/GetApp';
import config from '../../../../Assets/Configration/Configration.json';
import { getDownloadableLink, getTranslate } from '../../../../Helpers';
import { StepperComponent } from '../../../../Componentes';

const translationPath = 'home.formBuilder.dialogs.uploadDialog.';
export function UploadDialog(props) {
  const reducer = (state, action) => ({ ...state, [action.id]: action.value });
  const [state, setState] = useReducer(reducer, props.initialState);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});

  const totalSteps = () => props.initialState.selected.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    // It's the last step, but not all steps have been completed,
    // find the first step that has been completed
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? props.initialState.selected.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('file', file);

    return {
      url: `${config.serverAddress}/FileManager/File/Upload`,
      method: 'POST',
      body,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('session')).token}`,
      },
    };
  };

  const handleChangeStatus = (e, status, lable) => {
    if (status === 'done') {
      if (state[lable])
        setState({ id: lable, value: [...state[lable], JSON.parse(e.xhr.response)] });
      else setState({ id: lable, value: [JSON.parse(e.xhr.response)] });
    }
  };

  return (
    <>
      <Dialog className='dialog' open={props.open} maxWidth='lg'>
        <form
          noValidate
          onSubmit={(event) => {
            event.preventDefault();
            props.onChange(state);
            props.closeDialog();
          }}>
          <DialogTitle>{getTranslate()(`${translationPath}upload-attachments`)}</DialogTitle>
          <DialogContent>
            <div className='w-100 mb-2'>
              <StepperComponent
                steps={props.initialState.selected}
                labelInput='lookupItemName'
                onStepperClick={(index) => {
                  handleStep(index);
                }}
                activeStep={activeStep}
              />
            </div>

            {props.initialState.selected.map(
              (label) =>
                props.initialState.selected[activeStep].lookupItemName === label.lookupItemName && (
                  <Dropzone
                    getUploadParams={getUploadParams}
                    onChangeStatus={(e, status) => {
                      handleChangeStatus(e, status, label.lookupItemName);
                    }}
                    key={`uploaderRef${label}`}
                    inputContent={getTranslate()(`${translationPath}drag-&-drop-files`)}
                    styles={{ dropzone: { minHeight: 100 } }}
                  />
                )
            )}

            {props.initialState.selected.map(
              (label) =>
                props.initialState.selected[activeStep].lookupItemName === label.lookupItemName &&
                state[label.lookupItemName] &&
                Array.isArray(state[label.lookupItemName]) && (
                  <div className='mxh-150P h-100P attachment' key={`uploadedRef${label}`}>
                    {state[label.lookupItemName].map((value) => (
                      <Grid container>
                        <Grid item xs={12} md={10}>
                          {value.fileName}
                        </Grid>
                        <Grid item xs={12} md={2} className='text-align-right'>
                          <IconButton
                            color='primary'
                            onClick={() => {
                              const a = document.createElement('a');
                              a.style.display = 'none';
                              a.href = getDownloadableLink(value.uuid);
                              a.target = '_blank';
                              // the filename you want
                              a.download = value.fileName;
                              document.body.appendChild(a);
                              a.click();
                            }}>
                            <GetAppIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                )
            )}
          </DialogContent>

          <DialogActions>
            {activeStep !== 0 && (
              <Button
                onClick={() => {
                  handleBack();
                }}
                className='btns theme-solid bg-warning'>
                <span className='mdi mdi-chevron-double-left' />
                {getTranslate()('shared.back')}
              </Button>
            )}
            {!isLastStep() && (
              <Button
                onClick={() => {
                  handleNext();
                }}
                className='btns theme-solid bg-secondary'>
                {getTranslate()('shared.next')}
                <span className='mdi mdi-chevron-double-right' />
              </Button>
            )}
            <Button
              className='btns theme-solid bg-cancel'
              onClick={() => {
                props.closeDialog();
              }}>
              {getTranslate()('shared.cancel')}
            </Button>
            {isLastStep() && (
              <Button className='btns theme-solid' type='submit'>
                {getTranslate()('shared.save')}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
