import React,{ useEffect,useState, useCallback} from 'react';
import './TourStatusComponents.scss';
import Grid from '@material-ui/core/Grid';
import {GetTourStatus} from './../../../../../Serviecs'
import { getTranslate } from ' ./../../src/Helpers';
import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';

const translationPath = 'TourStatus.';
export const TourStatusComponent=()=> {

    const [isLoading, setIsLoading] = useState(false);
    const [tourStatus, setTourStatus] = useState({});
   
    const GetAllTourStatus = useCallback(async () => {
       setIsLoading(true);
       const Status = await GetTourStatus();
       if(Status){
        setTourStatus(Status)
        setIsLoading(false);
       }
       
      }, []);

    useEffect(() => {
        GetAllTourStatus()
    },[GetAllTourStatus]);

    return (
        <div className="TourStatus">
        <Grid container spacing={2}>
    
        <Grid  item xs={8} sm={6} md={6} lg={3}>
        
         <div className="TourCard">
         <Spinner isActive={isLoading} isAbsolute />
            <div className="pendingTours">
            </div>
        <div className='StatusDiv'>
        <p>{getTranslate()(`${translationPath}Pending Tours`)}</p>
        <h4 className='ToursNum  c-black'>{tourStatus.pendingTours}</h4>
        </div>
        </div>
      </Grid>

      <Grid  item xs={8} sm={6} md={6} lg={3}>
      <div className="TourCard">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="ongoingTours">
        </div>
      <div className='StatusDiv'>
      <p>{getTranslate()(`${translationPath}Ongoing Tours`)}</p>
      <h4 className='ToursNum  c-black'>{tourStatus.ongoingTours}</h4>
      </div>
      </div>
    </Grid>

    <Grid  item xs={8} sm={6} md={6} lg={3}>
    <div className="TourCard">
    <Spinner isActive={isLoading} isAbsolute />
    <div className="upComingTours">
        </div>
    <div className='StatusDiv'>
    <p>{getTranslate()(`${translationPath}UpComing Tours`)}</p>
    <h4 className='ToursNum  c-black'>{tourStatus.upComingTours}</h4>
    </div>

    </div>
  </Grid>

  <Grid  item xs={8} sm={6} md={6} lg={3}>
  <div className="TourCard">
  <Spinner isActive={isLoading} isAbsolute />
  <div className="completedTours">
        
        </div>
  <div className='StatusDiv'>
  <p>{getTranslate()(`${translationPath}Completed Tours`)}</p>
  <h4 className='ToursNum  c-black'>{tourStatus.completedTours}</h4>
  </div>
  </div>
</Grid>

       </Grid>      
        </div>
    )
}
