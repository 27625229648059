import config from './../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';

export const UpdateTour = async (tripId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Tours/${tripId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
export const CreateTour = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Tours/CreateTour`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
export const GetAllToursPrimary = async ({ pageSize, pageIndex, search }) => {
  try {
    const queryList = [];
    (pageSize || pageSize === 0) && queryList.push(`pageSize=${pageSize}`);
    (pageIndex || pageIndex === 0) && queryList.push(`pageIndex=${pageIndex + 1}`);
    search && queryList.push(`search=${search}`);

    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Tours/getAllTours?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const GetRecommendedTours = async ({ pageSize, pageIndex, search }) => {
  try {
    const queryList = [];
    (pageSize || pageSize === 0) && queryList.push(`pageSize=${pageSize}`);
    (pageIndex || pageIndex === 0) && queryList.push(`pageIndex=${pageIndex + 1}`);
    search && queryList.push(`search=${search}`);

    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Tours/GetRecommendedTourCities?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const GetAllReadyTours = async ({ pageSize, pageIndex, search, typeId }) => {
  try {
    const queryList = [];
    (pageSize || pageSize === 0) && queryList.push(`pageSize=${pageSize}`);
    (pageIndex || pageIndex === 0) && queryList.push(`pageIndex=${pageIndex + 1}`);
    (typeId || typeId === 0) && queryList.push(`typeId=${typeId}`);
    search && queryList.push(`search=${search}`);

    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Tours/GetAllReadyTours?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const GetTourById = async (tourId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Tours/GetTourById/${tourId}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const GetReadyTourById = async (tourId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Tours/GetReadyTourById/${tourId}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const DeleteTour = async (tourId) => {
  try {
    const result = await HttpServices.delete(`${config.serverAddress}/PSTViewing/Tours/${tourId}`);
    return result;
  } catch (e) {
    return;
  }
};
