import config from './../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';

export const CreateTrip = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Trips/CreateTrip`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
export const UpdateTrip = async (tripId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Trips/${tripId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
export const GetAllTrips = async ({ pageSize, pageIndex, search }) => {
  try {
    const queryList = [];
    (pageSize || pageSize === 0) && queryList.push(`pageSize=${pageSize}`);
    (pageIndex || pageIndex === 0) && queryList.push(`pageIndex=${pageIndex + 1}`);
    search && queryList.push(`search=${search}`);

    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Trips/getAllTrips?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const GetAllTripCanInserted = async ({ pageSize, pageIndex, body }) => {
  try {
    const queryList = [];
    (pageSize || pageSize === 0) && queryList.push(`pageSize=${pageSize}`);
    (pageIndex || pageIndex === 0) && queryList.push(`pageIndex=${pageIndex + 1}`);

    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Trips/GetAllTripCanInserted?${queryList.join('&')}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
export const TripValidation = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Trips/TripValidation`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
export const GetTripById = async (tripId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Trips/GetTripById/${tripId}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const DeleteTrip = async (tripId) => {
  try {
    const result = await HttpServices.delete(`${config.serverAddress}/PSTViewing/Trips/${tripId}`);
    return result;
  } catch (e) {
    return;
  }
};

export const GetLookupItem = async (lookupTypetId, lookupParentId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem/${lookupTypetId}?lookupParentId=${lookupParentId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const featureItemsPost = async (body) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/Lookups/LookupItem`, body);
    return result;
  } catch (e) {
    return;
  }
};

export const featureItemsPut = async (lookupItemId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Lookups/LookupItem/${lookupItemId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
