import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';

const getform = async (formName) => {
  try {
    const result = await HttpServices.get(`${config.serverAddress}/FormBuilder/Forms/${formName}`);
    const mappedResult = [
      {
        form_id: result.formsId,
        form_name: result.formsName,
        form_content: result.formsContent,
      },
    ];
    return mappedResult;
  } catch (e) {
    return;
  }
};

const updateform = async (payload, formId) => {
  try {
    return await HttpServices.put(`${config.serverAddress}/FormBuilder/Forms/${formId}`, payload);
  } catch (error) {
    console.error(error);
  }
};

const formByIdGet = async (payload) => {
  const { formname } = payload;
  try {
    const result = await HttpServices.get(`${config.serverAddress}/FormBuilder/Forms/${formname}`);
    return result;
  } catch (e) {
    return;
  }
};

const GetForms = async (pageIndex, pageSize, searchedItem) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/FormBuilder/Forms/${pageIndex}/${pageSize}?search=${searchedItem}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export { getform, updateform, formByIdGet, GetForms };
