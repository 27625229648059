import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { getTranslate } from '../../Helpers';
import { FormHelperText } from '@material-ui/core';
import { CheckboxesComponent } from './CheckboxesComponent';

const SelectComponet = ({
  data,
  onSelectChanged,
  classes,
  menuClasses,
  keyValue,
  textInput,
  value,
  valueInput,
  translationPath,
  translationPathForData,
  emptyItem,
  keyLoopBy,
  isRequired,
  idRef,
  labelClasses,
  labelValue,
  variant,
  multiple,
  error,
  helperText,
  isWithError,
  onSelectBlur,
  isSubmitted,
  isOpen,
  onOpen,
  onClose,
  overInputText,
  paddingReverse,
  overInputTextIcon,
  getIsChecked,
  renderValue,
  isDisabled,
}) => {
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  return (
    <FormControl
      className={`select-wrapper ${classes}${
        value && (!emptyItem || value !== emptyItem.value) ? ' select-filled' : ''
      }${((overInputText || overInputTextIcon) && ' over-input-text-wrapper') || ''}`}
    >
      {labelValue && (
        <label htmlFor={idRef} className={labelClasses}>
          {getTranslate()(`${translationPath}${labelValue}`)}
        </label>
      )}
      <div className="d-flex w-100 p-relative">
        {(overInputText || overInputTextIcon) && (
          <span className="over-input-text">
            {overInputTextIcon && <span className={overInputTextIcon} />}
            {overInputText && getTranslate()(`${translationPath}${overInputText}`)}
          </span>
        )}
        <Select
          labelId={`${idRef}-label`}
          id={idRef}
          value={value}
          disabled={isDisabled}
          open={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          multiple={multiple}
          onChange={
            ((onSelectChanged || isWithError) &&
              ((event) => {
                if (!isBlurOrChanged) setIsBlurOrChanged(true);
                if (onSelectChanged) onSelectChanged(event);
              })) ||
            undefined
          }
          renderValue={renderValue}
          className="selects"
          onBlur={(event) => {
            setIsBlurOrChanged(true);
            if (onSelectBlur) onSelectBlur(event);
          }}
          error={
            (isWithError && (isBlurOrChanged || isSubmitted) && error) ||
            (!isWithError && !isBlurOrChanged && error)
          }
          MenuProps={{
            className: `select-menu-wrapper ${menuClasses}`,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          displayEmpty
          required={isRequired}
          variant={variant}
          SelectDisplayProps={{
            style: (localStorage.getItem('localization') &&
              JSON.parse(localStorage.getItem('localization')).isRtl && {
                paddingRight: paddingReverse,
              }) || {
              paddingLeft: paddingReverse,
            },
          }}
          inputProps={{ readOnly: false }}
        >
          {emptyItem && (
            <MenuItem
              style={emptyItem.isHiddenOnOpen ? { display: 'none' } : {}}
              value={emptyItem.value}
              disabled={emptyItem.isDisabled}
            >
              {getTranslate()(`${translationPath + emptyItem.text}`)}
            </MenuItem>
          )}
          {data.map((item, index) => (
            <MenuItem
              value={valueInput ? item[valueInput] : item}
              key={keyLoopBy && keyValue ? keyValue + item[keyLoopBy] : `selection${index + 1}`}
            >
              {getIsChecked && (
                <CheckboxesComponent
                  idRef={`${idRef}Checkbox${index + 1}`}
                  checked={getIsChecked(item)}
                />
              )}
              {getTranslate()(`${translationPathForData + (textInput ? item[textInput] : item)}`)}
            </MenuItem>
          ))}
        </Select>
      </div>
      {helperText &&
        ((isWithError && (isBlurOrChanged || isSubmitted) && error && (
          <FormHelperText>{helperText}</FormHelperText>
        )) ||
          (!isWithError && (
            <FormHelperText>{getTranslate()(`${translationPath}${helperText}`)}</FormHelperText>
          )))}
    </FormControl>
  );
};
SelectComponet.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.oneOfType([PropTypes.any]),
  isRequired: PropTypes.bool,
  multiple: PropTypes.bool,
  onSelectChanged: PropTypes.func.isRequired,
  emptyItem: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    text: PropTypes.string,
    isDisabled: PropTypes.bool,
    isHiddenOnOpen: PropTypes.bool,
  }),
  translationPath: PropTypes.string,
  translationPathForData: PropTypes.string,
  classes: PropTypes.string,
  menuClasses: PropTypes.string,
  valueInput: PropTypes.string,
  textInput: PropTypes.string,
  keyValue: PropTypes.string,
  keyLoopBy: PropTypes.string,
  idRef: PropTypes.string,
  labelClasses: PropTypes.string,
  labelValue: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  paddingReverse: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overInputText: PropTypes.string,
  overInputTextIcon: PropTypes.string,
  isWithError: PropTypes.bool,
  onSelectBlur: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isSubmitted: PropTypes.bool,
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  getIsChecked: PropTypes.func,
  renderValue: PropTypes.func,
};
SelectComponet.defaultProps = {
  isRequired: false,
  multiple: false,
  emptyItem: null,
  translationPath: '',
  translationPathForData: '',
  classes: 'theme-solid',
  menuClasses: '',
  textInput: undefined,
  value: undefined,
  valueInput: undefined,
  keyValue: null,
  keyLoopBy: null,
  idRef: 'selectRef',
  labelClasses: 'texts-form',
  labelValue: undefined,
  variant: 'standard',
  error: undefined,
  helperText: undefined,
  paddingReverse: '.5rem',
  overInputText: undefined,
  isWithError: undefined,
  onSelectBlur: undefined,
  isSubmitted: undefined,
  isOpen: undefined,
  onOpen: undefined,
  onClose: undefined,
  isDisabled: undefined,
  overInputTextIcon: undefined,
  getIsChecked: undefined,
  renderValue: undefined,
};
export { SelectComponet };
