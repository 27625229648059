export const ContactSupportEnum = [
  {
    key: 'ComplainTypeId',
    value: 'Contact Support',
  },
  {
    key: 'Title',
    value: 'Subject',
  },
  {
    key: 'Content',
    value: 'Content',
  },
  {
    key: 'CreatedOn',
    value: 'Created On',
  },
];
