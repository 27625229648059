import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { getTranslate } from '../../../../../../../../../Helpers';
import { PopoverComponent } from '../../../../../../../../../Componentes';
import './TimeConfirmPopover.scss';

export const TimeConfirmPopover = ({
  isRepeatedChanged,
  translationPath,
  handleClose,
  attachedWith,
}) => {
  return (
    <PopoverComponent
      idRef="isRepeatedPopoverRef"
      handleClose={handleClose}
      attachedWith={attachedWith}
      popoverClasses="popover-wrapper confirm-popover"
      component={
        <div className="confirm-wrapper">
          <div className="confirm-header">
            <span className="confirm-title-text">
              {getTranslate()(`${translationPath}confirm-message`)}
            </span>
            <Button className="btns-icon theme-solid bg-danger mx-2 mb-2" onClick={handleClose}>
              <span className="mdi mdi-close"></span>
            </Button>
          </div>
          <div className="confirm-body">
            <div className="d-flex-column-center">
              <span className="mdi mdi-check-outline c-warning mdi-48px" />
              <span>{getTranslate()(`${translationPath}confirm-description`)}</span>
            </div>
          </div>
          <div className="confirm-footer">
            <Button onClick={handleClose} className="btns theme-outline mx-2 mb-2">
              <span>{getTranslate()(`${translationPath}cancel`)}</span>
            </Button>
            <Button
              onClick={isRepeatedChanged}
              className="btns theme-solid bg-danger w-100 mb-0 mx-0 br-0"
            >
              <span>{getTranslate()(`${translationPath}confirm`)}</span>
            </Button>
          </div>
        </div>
      }
    />
  );
};

TimeConfirmPopover.propTypes = {
  isRepeatedChanged: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  attachedWith: PropTypes.instanceOf(Object),
};
TimeConfirmPopover.defaultProps = {
  attachedWith: undefined,
};
