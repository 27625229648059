import React,{ useState,useEffect,useCallback} from 'react'
import { Grid } from 'react-bootstrap';
import {Doughnut} from 'react-chartjs-2';
import './TotalChartViewComponent.scss'
import {GetDriversStatistics} from './../../../../../Serviecs'
import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';
import { getTranslate } from ' ./../../src/Helpers';

export const TotalDrivers=()=>{
  const kFormatter=(num)=> {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}
  const translationPath = 'TotalDrivers.';
  const [isLoading, setIsLoading] = useState(false);
    const [chartData,setChartData] =useState({
    labels: ['Drivers' , 'Active Drivers'],
    
  
    datasets:[
      {

        data:[],
        backgroundColor:[
          '#580DBF',
          '#EC6666',
        ]
      }
    ]
})

const GetTotalDrivers = useCallback(async () => {
  setIsLoading(true);
  const Status = await GetDriversStatistics();
  if (Status) {
    setChartData((items) => {
      items.datasets[0].data=Object.values(Status)
      return items
    });
    setIsLoading(false);
  }
}, []);

useEffect(() => {
  GetTotalDrivers();
}, [GetTotalDrivers]);


    return (

      <div className='Total'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='titleDiv'>
      <p className='titleParagraph'>{getTranslate()(`${translationPath}Total Drivers`)}</p>
      </div>

    
      <Grid row>
      
      <Grid md={2} className='chartGrid'>
        
      <div className='ChartDiv'> 
      <p className='percentage-number'>{kFormatter(chartData.datasets[0].data[0])}</p>
        <Doughnut   
      data={chartData}
      options={{
                legend:{
          display:false,
        },
        
        responsive: true
        
      }}
    />
      
      </div>
    
      </Grid>
      <Grid >
      <Grid>
      <Grid className='list'>
      
      <ul> 
      { chartData && chartData.datasets[0].backgroundColor.map((Color, index) =>(
        <li style={{color:`${Color}` ,margin: '0 0 3px 0 '}} key={index}></li>
        ))}
      </ul>
     
      </Grid>
      <Grid className='btns theme-transparent c-black-liGht legend'> 
      <ul>
      {chartData.datasets &&
        chartData.labels.map((label, index) => <li key={index}>{getTranslate()(`${translationPath}${label}`)}</li>)}
    </ul>
    </Grid>

      <Grid  className='btns theme-transparent c-black-liGht legend'>
      <ul>
      {chartData.datasets &&chartData.datasets[0].data&&
        chartData.datasets[0].data.map((items, index) => <li key={index}>{items}</li>)}
   
      </ul>
       
    </Grid>
      
    
       
      </Grid>
      
      </Grid>
      
      
      </Grid>
      
    </div>

      
        

     
        
      
    )
}
