import React, { useState } from 'react';
import List from '@material-ui/core/List';
import { Grid } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { UploaderComponent } from '../../../../../../Componentes';
import './FileComponent.scss';
import { getTranslate } from '../../../../../../Helpers';
const FileComponent = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const funGetuuid = (files) => {
    for (let i = 0; i < files.length; i++) {
      return files[i].uuid;
    }
  };
  const translationPath = 'home.trips.tripsManagementView.';
  return (
    <div className="file-wrapper imagesuploder">
      <Grid container spacing={2} direction="row">
        <Grid item xs={6} sm={2}>
          <List component="nav" ria-label="contacts">
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemIcon>
                <p className="mdi mdi-image-multiple"></p>
              </ListItemIcon>
              <ListItemText primary={getTranslate()(`${translationPath}Galary`)} />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemIcon>
                <p className="mdi mdi-camera"></p>
              </ListItemIcon>
              <ListItemText primary={getTranslate()(`${translationPath}360File`)} />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              <ListItemIcon>
                <p className="mdi mdi-virtual-reality"></p>
              </ListItemIcon>
              <ListItemText primary={getTranslate()(`${translationPath}VRFile`)} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6} sm={3} className="counter-text">
          {selectedIndex === 0 && (
            <UploaderComponent
              idRef="uploadedItemImages"
              multiple
              initUploadedFiles={
                (props.state.items &&
                  props.state.items.itemImages.map((item) => ({
                    uuid: item.fileId,
                    fileName: item.fileName,
                  }))) ||
                []
              }
              uploadedChanged={(files) =>
                props.setState({
                  id: 'items',
                  value: {
                    ...props.state.items,
                    itemImages: files.map((item) => ({
                      fileId: item.uuid,
                      fileName: item.fileName,
                    })),
                  },
                })
              }
            />
          )}
          {selectedIndex === 1 && (
            <UploaderComponent
              idRef="uploaded360File"
              accept="*"
              initUploadedFiles={
                (props.state.file360 && [
                  {
                    uuid: props.state.file360,
                    fileName: getTranslate()(`${translationPath}file-360`) + '.png',
                  },
                ]) ||
                []
              }
              uploadedChanged={(files) =>
                props.setState({
                  id: 'file360',
                  value: funGetuuid(files) || null,
                })
              }
            />
          )}{' '}
          {selectedIndex === 2 && (
            <UploaderComponent
              idRef="uploadedVRFile"
              accept="*"
              initUploadedFiles={
                (props.state.fileVr && [
                  {
                    uuid: props.state.fileVr,
                    fileName: getTranslate()(`${translationPath}vr-file`) + '.png',
                  },
                ]) ||
                []
              }
              uploadedChanged={(files) => {
                props.setState({
                  id: 'fileVr',
                  value: funGetuuid(files) || null,
                });
              }}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export { FileComponent };
