import React, { useReducer, useState, useRef, useEffect, useCallback } from 'react';
import { Button } from '@material-ui/core';
import {
  getTranslate,
  getHistory,
  GetParams,
  showError,
  showSuccess,
  getErrorByName,
} from '../../../../Helpers';
import { GoogleMapsComponent, TabsComponent } from '../../../../Componentes';
import {
  Inputs,
  AutocompleteComponent,
  PopoverComponent,
  Spinner,
  UploaderComponent,
} from '../../../../Componentes';
import './TripsManagementView.scss';
import {
  lookupItemsGetId,
  GetForms,
  UpdateTrip,
  CreateTrip,
  GetTripById,
} from '../../../../Serviecs';
import Lookups from '../../../../Assets/JSON/StaticLookupsIds.json';
import { DefaultImagesEnum } from '../../../../Enums';

import {
  ItemsComponent,
  FeaturesComponent,
  TimeComponent,
  FileComponent,
} from './TripManagementUtilities';
import Joi from 'joi';
const translationPath = 'home.trips.tripsManagementView.';

export const TripsManagementView = () => {
  const reducer = useCallback((state, action) => {
    if (action.parentId) {
      if (action.id === 'replace')
        state[action.parentId].splice(action.parentIndex, 1, action.value);
      if (action.id === 'splice')
        state[action.parentId].splice(action.parentIndex, 0, action.value);
      else if (action.id === 'delete') state[action.parentId].splice(action.parentIndex, 1);
      else if (action.id === 'push') state[action.parentId].push(action.value);
      else state[action.parentId][action.id] = action.value;
      return { ...state };
    } else if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    else if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  }, []);
  const [location, setLocation] = useState('');
  const [state, setState] = useReducer(reducer, {
    tripName: '',
    tripPrice: '',
    items: {
      itemName: null,
      itemJson: null,
      formsId: null,
      itemImages: [],
    },
    // adultPrice: 0.0,
    // childPrice: 0.0,
    // infantPrice: 0.0,
    notes: '',
    highLights: '',
    description: '',
    includes: '',
    cityId: null,
    address: '',
    latitude: null,
    longitude: null,
    // from: null,
    // to: null,
    tripDuration: 0,
    coverTripImageId: null,
    fileVr: null,
    file360: null,
    tripDates: [],
    tripFeaturesIds: [],
    tripFeatures: [],
  });
  const [tripType, setTripType] = useState(null);
  const [city, setCity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [id, setId] = useState(() => null);
  const [attachedWith, setAttachedWith] = useState(null);
  // const [weekDays, setWeekDays] = useState([]);
  const tabChangeHandler = (e, newTap) => {
    setActiveTab(newTap);
  };
  const [tripsData, setTripsData] = useState([]);
  const [cityData, setCityData] = useState([]);

  const [filter, setFilter] = useState({ pageIndex: 0, pageSize: 10, formTypeSearch: '' });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const locationRef = useRef(null);
  const searchTimer = useRef(null);
  const openMapClicked = () => {
    setAttachedWith(locationRef.current);
  };
  const openMapCloseClicked = () => {
    setAttachedWith(null);
  };
  const tripDateChanged = useCallback((newTripDateItem, editIndex) => {
    if ((newTripDateItem && editIndex) || (newTripDateItem && editIndex === 0))
      setState({
        parentId: 'tripDates',
        id: 'replace',
        parentIndex: editIndex,
        value: newTripDateItem,
      });
    else if (newTripDateItem)
      setState({
        parentId: 'tripDates',
        id: 'push',
        value: newTripDateItem,
      });
    else
      setState({
        parentId: 'tripDates',
        parentIndex: editIndex,
        id: 'delete',
      });
  }, []);
  const getCityData = useCallback(async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: Lookups.Cities,
      lookupParentId: Lookups.UAECities,
    });
    setCityData(res);
    setIsLoading(false);
  }, []);
  const getFormsTypes = useCallback(async () => {
    setIsLoading(true);
    const res = await GetForms(filter.pageIndex + 1, filter.pageSize, filter.formTypeSearch);
    setTripsData((res && res.result) || []);
    setIsLoading(false);
  }, [filter.formTypeSearch, filter.pageIndex, filter.pageSize]);

  const searchHandler = (event) => {
    const value = event.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter({ ...filter, formTypeSearch: value });
    }, 700);
  };
  const schema = Joi.object({
    tripName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}trip-name-is-required`),
      }),
    tripPrice: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}trip-price-is-required`),
      }),
    latitude: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}location-is-required`),
      }),
    cityId: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}city-is-required`),
      }),
    coverTripImageId: Joi.string()
      .required()
      .messages({
        'string.base': getTranslate()(`${translationPath}cover-image-is-required`),
      }),
    items: Joi.object({
      formsId: Joi.number()
        .required()
        .messages({
          'number.base': getTranslate()(`${translationPath}trip-type-is-required`),
        }),
    }).options({
      abortEarly: false,
      allowUnknown: true,
    }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error && getErrorByName(schema, 'coverTripImageId').error) {
      showError(getErrorByName(schema, 'coverTripImageId').message);
      return;
    }
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    setIsLoading(true);
    let response = null;
    if (id) response = await UpdateTrip(id, state);
    else response = await CreateTrip(state);
    setIsLoading(false);
    if (response) {
      if (id) showSuccess(getTranslate()(`${translationPath}trip-updated-successfully`));
      else showSuccess(getTranslate()(`${translationPath}trip-saved-successfully`));
      getHistory().push('/home/trips/view');
    } else if (id) showError(getTranslate()(`${translationPath}trip-updating-failed`));
    else showError(getTranslate()(`${translationPath}trip-saving-failed`));
  };
  const getEditInit = useCallback(async () => {
    if (id === null) return;
    setIsLoading(true);
    const res = await GetTripById(id);
    setState({
      id: 'edit',
      value: {
        ...res,
        notes: res.notes || '',
        tripFeatures: res.featureInformations,
        tripFeaturesIds: res.featureInformations.map((el) => el.lookupsId),
      },
    });
    if (res.latitude && res.longitude) setLocation(res.latitude + ',' + res.longitude);
    setCity(cityData.find((item) => res && res.cityId === item.lookupItemId) || null);
    setTripType(
      tripsData.find((item) => res && res.items && res.items.formsId === item.formsId) || null
    );
    setIsLoading(false);
  }, [cityData, id, tripsData]);
  const locationChangeHandler = (event) => {
    console.log(event);
    let locationSeparated = event.target.value;
    locationSeparated = locationSeparated.replace(/[^.,\d]+/g, '');
    setLocation(locationSeparated);
    locationSeparated = locationSeparated.split(',');

    if (locationSeparated.length >= 2) {
      setState({ id: 'latitude', value: +locationSeparated[0] });
      setState({ id: 'longitude', value: +locationSeparated[1] });
    } else {
      setState({ id: 'latitude', value: null });
      setState({ id: 'longitude', value: null });
    }
  };
  useEffect(() => {
    if (id !== null);
    getEditInit();
  }, [getEditInit, id]);
  useEffect(() => {
    if (state.items && state.tripName !== state.items.itemName)
      setState({
        parentId: 'items',
        id: 'itemName',
        value: state.tripName,
      });
  }, [state.items, state.tripName]);
  useEffect(() => {
    // dataInit();
    const editId = GetParams('id');
    if (editId !== null) setId(+editId);
  }, []);
  useEffect(() => {
    getFormsTypes();
  }, [getFormsTypes]);

  useEffect(() => {
    getCityData();
  }, [getCityData]);
  useEffect(() => {
    return () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    };
  }, []);
  return (
    <div className="trips-management-view view-wrapper">
      <Spinner isActive={isLoading} />
      <form noValidate onSubmit={saveHandler} className="trip-form-content">
        <div className="trip-header mb-2 px-2">
          <Button type="submit" className="btns theme-solid mx-2 mb-2">
            <span>{getTranslate()(`${translationPath}save-trip`)}</span>
          </Button>
          <Button
            className="btns theme-solid bg-cancel mb-2 mx-2"
            onClick={() => getHistory().push('/home/trips/view')}
          >
            <span>{getTranslate()(`${translationPath}cancel`)}</span>
          </Button>
        </div>
        <div className="form-rows">
          <div className="form-row">
            <div className="form-item">
              <Inputs
                inputPlaceholder="trip-name"
                labelValue="trip-name"
                idRef="tripNameRef"
                value={state.tripName}
                helperText={getErrorByName(schema, 'tripName').message}
                error={getErrorByName(schema, 'tripName').error}
                isWithError
                isSubmitted={isSubmitted}
                onInputChanged={(event) => setState({ id: 'tripName', value: event.target.value })}
                translationPath={translationPath}
              />
            </div>
            <div className="form-item">
              <Inputs
                inputPlaceholder="trip-time-period"
                labelValue="trip-time-period"
                idRef="tripTimePeriodRef"
                value={state.tripDuration}
                startAdornment={<span>{getTranslate()(`${translationPath}m`)}</span>}
                type="number"
                onInputChanged={(event) => {
                  const floatHandler = () => {
                    const price = event.target.value.toString().split('.');
                    if (price.length === 2) return Number(event.target.value).toFixed(0);
                    else return Number(event.target.value).toFixed(0);
                  };
                  setState({
                    id: 'tripDuration',
                    value: floatHandler(),
                  });
                }}
                translationPath={translationPath}
              />
            </div>
            <div className="form-item">
              <Inputs
                inputPlaceholder="trip-price"
                labelValue="trip-price"
                idRef="tripPriceRef"
                value={state.tripPrice}
                helperText={getErrorByName(schema, 'tripPrice').message}
                error={getErrorByName(schema, 'tripPrice').error}
                isWithError
                isSubmitted={isSubmitted}
                type="number"
                overInputText="AED"
                onInputChanged={(event) => {
                  const floatHandler = () => {
                    const price = event.target.value.toString().split('.');
                    if (price.length === 2 && price[1].length > 3)
                      return Number(event.target.value).toFixed(3);
                    else return Number(event.target.value);
                  };
                  setState({
                    id: 'tripPrice',
                    value: floatHandler(),
                  });
                }}
                translationPath={translationPath}
              />
            </div>
            <div className="form-item">
              <AutocompleteComponent
                idRef="cityRef"
                labelValue="city"
                translationPath={translationPath}
                options={cityData}
                getOptionLabel={(option) => (option.lookupItemName && option.lookupItemName) || ''}
                chipsLabel={(option) => (option.lookupItemName && option.lookupItemName) || ''}
                inputPlaceholder="city"
                getOptionSelected={(option) => state.cityId === option.lookupItemId}
                value={city}
                helperText={getErrorByName(schema, 'cityId').message}
                error={getErrorByName(schema, 'cityId').error}
                isWithError
                isSubmitted={isSubmitted}
                onChange={(e, value) => {
                  setCity(value);
                  setState({
                    id: 'cityId',
                    value: (value && value.lookupItemId) || null,
                  });
                }}
                multiple={false}
                withoutSearchButton
              />
            </div>
            <div className="form-item">
              <Inputs
                inputPlaceholder="location"
                labelValue="location"
                idRef="locationRef"
                refs={locationRef}
                value={location}
                onInputChanged={locationChangeHandler}
                helperText={getErrorByName(schema, 'latitude').message}
                error={getErrorByName(schema, 'latitude').error}
                isWithError
                isSubmitted={isSubmitted}
                translationPath={translationPath}
              />
              <div className="d-flex-v-center-h-end">
                <Button className="btns c-secondary mt-2" onClick={openMapClicked}>
                  <span>{getTranslate()(`${translationPath}open-map`)}</span>
                </Button>
              </div>

              <PopoverComponent
                idRef="mapPopoverRef"
                handleClose={openMapCloseClicked}
                attachedWith={attachedWith}
                component={
                  <GoogleMapsComponent
                    locations={
                      (state.latitude && [
                        {
                          latitude: state.latitude,
                          longitude: state.longitude,
                        },
                      ]) ||
                      []
                    }
                    onSearchLocationChanged={(searchValue) => {
                      if (searchValue) {
                        setLocation(searchValue.lat + ',' + searchValue.lng);
                        setState({ id: 'latitude', value: searchValue.lat });
                        setState({ id: 'longitude', value: searchValue.lng });
                      }
                    }}
                    center={
                      (state.latitude && { lat: state.latitude, lng: state.longitude }) || undefined
                    }
                    onClick={(location) => {
                      setLocation(location.lat + ',' + location.lng);
                      setState({ id: 'latitude', value: location.lat });
                      setState({ id: 'longitude', value: location.lng });
                    }}
                  />
                }
              />
            </div>
            <div className="form-item">
              <AutocompleteComponent
                idRef="tripTypeRef"
                labelValue="trip-type"
                translationPath={translationPath}
                options={tripsData}
                getOptionLabel={(option) => (option.formsName && option.formsName) || ''}
                inputPlaceholder="trip-type"
                getOptionSelected={(option) => state.items.formsId === option.formsId}
                value={tripType}
                helperText={getErrorByName(schema, 'formsId').message}
                error={getErrorByName(schema, 'formsId').error}
                isWithError
                isSubmitted={isSubmitted}
                onKeyUp={searchHandler}
                onChange={(e, value) => {
                  console.log(value);
                  setTripType({ ...value });
                  setState({
                    parentId: 'items',
                    id: 'formsId',
                    value: (value && value.formsId) || null,
                  });
                  if (state.items && state.items.itemJson)
                    setState({
                      parentId: 'items',
                      id: 'itemJson',
                      value: null,
                    });
                }}
                multiple={false}
                withoutSearchButton
              />
            </div>
            <div className="form-item">
              <Inputs
                inputPlaceholder="address"
                labelValue="address"
                idRef="addressRef"
                value={state.address}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  setState({ id: 'address', value: event.target.value });
                }}
              />
            </div>

            <div className="form-item">
              <Inputs
                inputPlaceholder="notes"
                labelValue="notes"
                idRef="notesRef"
                value={state.notes}
                // multiline
                // rows={5}
                onInputChanged={(event) =>
                  setState({
                    id: 'notes',
                    value: event.target.value,
                  })
                }
                translationPath={translationPath}
              />
            </div>
            <div className="form-item">
              <Inputs
                inputPlaceholder="description"
                labelValue="description"
                idRef="descriptionRef"
                value={state.description}
                multiline
                rows={5}
                onInputChanged={(event) =>
                  setState({
                    id: 'description',
                    value: event.target.value,
                  })
                }
                translationPath={translationPath}
              />
            </div>
          </div>
          <div className="form-row cover-image-wrapper">
            <UploaderComponent
              idRef="coverImgRef"
              circleDefaultImage={DefaultImagesEnum.trip.defaultImg}
              initUploadedFiles={
                (state.coverTripImageId && [
                  {
                    uuid: state.coverTripImageId,
                    fileName: getTranslate()(`${translationPath}cover-image`) + '.png',
                  },
                ]) ||
                []
              }
              uploadedChanged={(files) =>
                setState({
                  id: 'coverTripImageId',
                  value: (files.length > 0 && files[0].uuid) || null,
                })
              }
            />
          </div>
        </div>
      </form>
      <div className="px-3 w-100">
        <div className="form-row">
          <TabsComponent
            data={[
              { title: 'items' },
              { title: 'trip-times' },
              { title: 'features' },
              { title: 'files' },
            ]}
            currentTab={activeTab}
            translationPath={translationPath}
            labelInput="title"
            onTabChanged={tabChangeHandler}
          />
          <div className="tab-content-wrapper">
            {activeTab === 0 && tripType && tripType.formsName && (
              <ItemsComponent
                itemJson={(state.items && JSON.parse(state.items.itemJson)) || null}
                tripType={tripType}
                city={city}
                cityData={cityData}
                onItemDateChanged={(itemData, formItemData) => {
                  const itemDataLocal = (state.items && JSON.parse(state.items.itemJson)) || {};
                  itemDataLocal[formItemData.field.id] = itemData;
                  setState({
                    parentId: 'items',
                    id: 'itemJson',
                    value: JSON.stringify(itemDataLocal),
                  });
                }}
                translationPath={translationPath}
              />
            )}
            {/* replace div with TimeComponent */}

            {activeTab === 1 && <TimeComponent state={state} tripDateChanged={tripDateChanged} />}

            {activeTab === 2 && (
              <FeaturesComponent
                featureId={id}
                state={state}
                setState={setState}
                translationPath={translationPath}
              />
            )}
            {/* replace div with FilesComponent */}
            {activeTab === 3 && <FileComponent state={state} setState={setState} />}
          </div>
        </div>
      </div>
    </div>
  );
};
