import { ToursManagementView, ToursView } from '../../Views/Home';

export const ToursRoutes = [
  {
    path: '/add',
    name: 'home.tours.toursManagementView.add-new-tour',
    component: ToursManagementView,
    layout: '/home/tours',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'home.tours.toursView.tours',
        isDisabled: false,
        route: '/home/tours/view',
      },
      {
        name: 'home.tours.toursManagementView.add-new-tour',
        isDisabled: true,
        route: '/home/tours/add',
      },
    ],
  },
  {
    path: '/edit',
    name: 'home.tours.toursManagementView.edit-tour',
    component: ToursManagementView,
    layout: '/home/tours',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'home.tours.toursView.tours',
        isDisabled: false,
        route: '/home/tours/view',
      },
      {
        name: 'home.tours.toursManagementView.edit-tour',
        isDisabled: true,
        route: '/home/tours/edit',
      },
    ],
  },
  {
    path: '/view',
    name: 'home.tours.toursView.tours',
    component: ToursView,
    layout: '/home/tours',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'home.tours.toursView.tours',
        isDisabled: false,
        route: '/home/tours/view',
      },
    ],
  }
];
