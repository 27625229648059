import { call, put, takeEvery } from 'redux-saga/effects';
import { GetDefaultCarType } from '../../../Serviecs';
import { CarTypeActions } from '../../Actions';
import { CarTypeStatus } from '../../States';

function* fetchCarType(action) {
  console.log(action);

  try {
    const results = yield call(GetDefaultCarType, action.payload);
    yield put(CarTypeActions.carTypeSuccess(results));
  } catch (err) {
    yield put(CarTypeActions.carTypeError(err));
  }
}

export function* watchCarType() {
  yield takeEvery(CarTypeStatus.REQUEST, fetchCarType);
}
