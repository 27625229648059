import config from '../../Assets/Configration/Configration';
import { HttpServices } from '../HttpServices/HttpServices';

export const GetAllParking = async ({ pageSize, pageIndex, search }) => {
  try {
    const queryList = [];
    queryList.push(`pageSize=${pageSize}`);
    queryList.push(`pageIndex=${pageIndex }`);
    search && queryList.push(`search=${search}`);
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Parking/GetAllParking/?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetParkingById = async (payload) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}
      /PSTViewing/Parking/{parkingId}${payload}`,
      payload
    );
    return result;
  } catch (e) {
    return;
  }
};

export const AddParking = async (payload) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/PSTViewing/Parking`, payload);
    return result;
  } catch (e) {
    return;
  }
};

export const EditaParking = async (payload, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Parking/${payload}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
export const DelteParking = async (payload) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/PSTViewing/Parking/${payload}`,
      payload
    );
    return result;
  } catch (e) {
    return;
  }
};

export const countryservis = async (body) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem/1/10?lookupTypeName=city&lookupParentId=26642`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

export const SearchParking = async (pageIndex, pageSize, search) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Parking/GetAllParking/?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const ActivateParking = async (payload, body)=> {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Parking/ActivateParking/${payload}?isActive=${body}`,
      payload
    );
    return result;
  } catch (e) {
    if (e.response && e.response) throw e.response;
    else throw e;
  }
};
