import         React    , { useEffect }              from 'react'                            ;
import {Button,TextField,DialogTitle,DialogContent,DialogActions,Dialog,Grid,FormControl,Select,Typography,Divider,InputAdornment,IconButton}
                                                 from '@material-ui/core'                    ;
import { CarTypes, PassengerType, EditCarTypes } from '../../../../Serviecs/Cars/CarsService';
import                Radio                      from '@material-ui/core/Radio'              ;
import              RadioGroup                   from '@material-ui/core/RadioGroup'         ;
import           FormControlLabel                from '@material-ui/core/FormControlLabel'   ;
import            ClearRoundedIcon               from '@material-ui/icons/ClearRounded'      ;
import {getTranslate,showSuccess,getErrorByName} from '../../../../Helpers'                  ;
import      {       UploadPicture      }         from '../../../../Serviecs/Cars/CarsService';
import      {      ActivateTypeCar     }         from '../../../../Serviecs/Cars/CarsService';
import   { Spinner, Inputs, SelectComponet }     from '../../../../Componentes'              ;
import                    Joi                    from 'joi'                                  ;
import                                                './../CarsView.scss'                   ;
import                                                './DialogAddCar.scss'                  ;
const              translationPath                 =  'Cars.DialogAddEditTypeCar.'           ;

const DialogAddTypeCar = (props) => {
  const [typeName, setTypeName] = React.useState('');
  const [noOfSets, setnoOfSets] = React.useState('');
  const [carModel, setcarModel] = React.useState('');
  const [classTypeId, setclassTypeId] = React.useState('');
  const [sameCityPickupPrice, setsameCityPickupPrice] = React.useState('');
  const [sameCityDropoffPrice, setsameCityDropoffPrice] = React.useState('');
  const [differentCityPickupPrice, setdifferentCityPickupPrice] = React.useState('');
  const [differentCityDropoffPrice, setdifferentCityDropoffPrice] = React.useState('');
  const [distence1KPrice, setdistence1KPrice] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [Adult, setAdult] = React.useState();
  const [Passenger, setPassenger] = React.useState();
  const [Children, setChildren] = React.useState();
  const [ImageUploadedcount, setImageUploadedcount] = React.useState(false);
  const [Infant, setInfant] = React.useState();
  const [Adultprice, setAdultprice] = React.useState('');
  const [Passengerprice, setPassengerprice] = React.useState('');
  const [Childrenprice, setChildrenprice] = React.useState('');
  const [Infantprice, setInfantprice] = React.useState('');
  const [PassengerT, setPassengerT] = React.useState([]);
  const [value, setValue] = React.useState('');
  const [result, setResult] = React.useState('');
  const hiddenFileInput = React.useRef(null);
  const [uploadImage, setUploadImage] = React.useState('');

  const  ClearData = () =>
{
  setTypeName('');setnoOfSets('');setcarModel('');setclassTypeId('');setsameCityPickupPrice('');setsameCityDropoffPrice('') ;
        setdifferentCityPickupPrice('');setdifferentCityDropoffPrice('');setUploadImage(''); setdistence1KPrice('')         ;
              setAdultprice('');setPassengerprice('');setChildrenprice('');setInfantprice('');setResult('')                 ;
}                                                                                                                           ;
  useEffect(() => {
    if (props.isEdit) {
      setTypeName(props.CarDetails.typeName);
      setnoOfSets(props.CarDetails.noOfSets);
      setcarModel(props.CarDetails.carModel);
      setclassTypeId(props.CarDetails.classTypeId);
      setsameCityPickupPrice(props.CarDetails.sameCityPickupPrice);
      setsameCityDropoffPrice(props.CarDetails.sameCityDropoffPrice);
      setdifferentCityPickupPrice(props.CarDetails.differentCityPickupPrice);
      setdifferentCityDropoffPrice(props.CarDetails.differentCityDropoffPrice);
      setdistence1KPrice(props.CarDetails.distence1KPrice);
      setResult(props.CarDetails.imageFileId);
      props.CarDetails.status === 'InActive' ? setValue('false') : setValue('true');
      const values = props.CarDetails.carPassengersPrices;

      values &&values.map((values) => {
          if (values.passengerTypeId === 19118) {
            setAdult(values.passengerTypeId);
            setAdultprice(values.price);
          }
          if (values.passengerTypeId === 19117) {
            setPassenger(values.passengerTypeId);
            setPassengerprice(values.price);
          }
          if (values.passengerTypeId === 19120) {
            setInfant(values.passengerTypeId);
            setInfantprice(values.price);
          }
          if (values.passengerTypeId === 19119) {
            setChildren(values.passengerTypeId);
            setChildrenprice(values.price);
          }
          return null;
        });
    } else {
      ClearData ();   setChildren('');   setPassenger('');    setInfant('');     setAdult('');
      PassengerT.map((PassengerT) => {
        if (PassengerT.lookupItemName === 'Adult') {
          setAdult(PassengerT.lookupItemId);
        }
        if (PassengerT.lookupItemName === 'Main Passenger') {
          setPassenger(PassengerT.lookupItemId);
        }
        if (PassengerT.lookupItemName === 'Infant') {
          setInfant(PassengerT.lookupItemId);
        }
        if (PassengerT.lookupItemName === 'Child') {
          setChildren(PassengerT.lookupItemId);
        }
        return null;
      });
    }
  }, [
    props.CarDetails.status,props.CarDetails.carTypesId,props.CarDetails.typeName,props.CarDetails.carModel,
    props.CarDetails.classTypeId,props.CarDetails.sameCityPickupPrice,props.CarDetails.sameCityDropoffPrice,
    props.CarDetails.differentCityPickupPrice, props.CarDetails.imageFileId,props.CarDetails.noOfSets,
             props.CarDetails.differentCityDropoffPrice,props.CarDetails.distence1KPrice,
                     props.CarDetails.carPassengersPrices,props.isEdit,PassengerT,
  ]);
  const selectedFileHandler = async (e) => {
    setResult('');
    setLoading(true) 
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    setUploadImage(e.target.files[0].name);
    setResult(await UploadPicture(formData));
    setLoading(false)
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const Getcountry = async () => {
    const res = await PassengerType();
    setPassengerT(res&&res?res:[]);
  };

  useEffect(() => {
    Getcountry();
  }, []);

  const schema = Joi.object({
    typeName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}ValidationErrorTypeName`),
      }),
    noOfSets: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrornoOfSets`),
      }),
    sameCityPickupPrice: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrorSameCityprice`),
      }),
    sameCityDropoffPrice: Joi.number()

      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrosetsameCityDropoffPrice`),
      }),
    differentCityPickupPrice: Joi.number()

      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrodifferentCityPickupPrice`),
      }),
    differentCityDropoffPrice: Joi.number()

      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrordifferentCityDropoffPrice`),
      }),
    distence1KPrice: Joi.number()

      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrordistence1KPrice`),
      }),
    Passengerprice: Joi.number()

      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrorPassengerPrice`),
      }),
    Adultprice: Joi.number()
    
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrorPassengertypeprices`),
      }),
    Childrenprice: Joi.number()

      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErroChildrenprice`),
      }),
    Infantprice: Joi.number()

      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErroInfant`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({
      typeName,noOfSets,sameCityPickupPrice,sameCityDropoffPrice,distence1KPrice,differentCityPickupPrice,
                 differentCityDropoffPrice,Passengerprice,Adultprice,Childrenprice,Infantprice,
    });

  let maxOffset = 20;
  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }
  return (
    <Dialog open={props.open} onClose={props.close} className='sliderDialogs  diloge'>
      <Spinner isActive={loading} />
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          props.close();
        }}>
            <div className='colse-button'>
          <Button
            className='MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid bg-danger mx-2 mb-2'
            title={getTranslate()(`${translationPath}Cancel`)}
            type='button'
            onClick={() => {
              props.close();
              ClearData ();
              props.setIsEdit();
            }}>
            <span className='MuiButton-label'>
              <span className='mdi mdi-close'></span>
            </span>
            <span class='MuiTouchRipple-root'></span>
          </Button>
        </div>
        <DialogTitle className='dialogTitle'>
          <Typography>
            {props.isEdit
              ? getTranslate()(`${translationPath}EdirTypeCar`) + props.CarDetails.typeName
              : getTranslate()(`${translationPath}AddNewTypeCar`)}
          </Typography>
          <Divider />
        {  (!props.isEdit) ?
          <Grid
            container
            justify='flex-end'
            title={getTranslate()(`Cars.DialogAddEditTypeCar.empty`)} >
         <IconButton   onClick={() => {      ClearData ();     props.setIsEdit();     }}><p className='mdi mdi-eraser clearbutton'></p></IconButton>   
          </Grid> :''}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={4}
            style={{ marginBottom: 20, paddingLeft: '107px', paddingRight: '107px' }}>
            <Grid container item xs={6}>
              <Typography> {getTranslate()(`${translationPath}TypeName`)}</Typography>
              <Grid container justify='center'>
                <Inputs
                  idRef='typeName'
                  translationPath={translationPath}
                  isWithError
                  helperText={getErrorByName(schema, 'typeName').message}
                  inputPlaceholder='TypeName'
                  error={getErrorByName(schema, 'typeName').error}
                  value={typeName}
                  onInputChanged={(e) => setTypeName(e.target.value)}
                />
              </Grid>
              <Grid container justify='center'>
                <Inputs
                  idRef='noOfSets'
                  labelValue='noOfSets'
                  translationPath={translationPath}
                  isWithError
                  type={'number'}
                  helperText={getErrorByName(schema, 'noOfSets').message}
                  inputPlaceholder='noOfSets'
                  error={getErrorByName(schema, 'noOfSets').error}
                  value={noOfSets}
                  onInputChanged={(e) => setnoOfSets(e.target.value)}
                />
              </Grid>
              <Grid container justify='center'>
                <FormControl className='input-wrapper flex-wrap textField'>
                  <Typography> {getTranslate()(`${translationPath}UploadImage`)}</Typography>
                  <TextField
                    disabled
                    value={uploadImage}
                    size='small'
                    variant='outlined'
                    fullWidth
                    placeholder={
                      props.isEdit
                        ? result
                        : getTranslate()(`${translationPath}UploadImage`)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {result && (
                            <>
                              <Button  onClick={() => {    setUploadImage('');  setResult('');   setImageUploadedcount(true) ; }}>
                                {' '} {getTranslate()(`${translationPath}EditImage`)}<ClearRoundedIcon /></Button>                     
                            </>
                          )}
                          {!result && (
                            <>
                              <Button
                                color='primary'
                                onClick={() =>  {   hiddenFileInput.current.click() ;   setImageUploadedcount(true) ;      }}>
                                {getTranslate()(`${translationPath}BrowseImage`)}
                              </Button>
                              <input
                                type='file'
                                accept='image/*'
                                ref={hiddenFileInput}
                                onChange={(e) => selectedFileHandler(e)}
                                id='upload-photo'
                              />
                            </>
                          )}
                        </InputAdornment>
                      ),
                    }}></TextField>
                  {!result && (
                    <span className='imageUpload'>
                      {' '}
                     <span className={ImageUploadedcount !== true ? '' : "vald"  }> {getTranslate()(`${translationPath}NoImageUploaded`)}</span>
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid container justify='center'>
                <FormControl
                  className='input-wrapper flex-wrap textField'
                  size='small'
                  variant='outlined'>
                  <Typography> {getTranslate()(`${translationPath}CarModel`)}</Typography>

                  <SelectComponet
                    idRef='CarModel'
                    data={allYears}
                    isWithError
                    // helperText={getErrorByName(schema, 'color').message}
                    emptyItem={{
                      value: '',
                      text: getTranslate()(`${translationPath}CarModel`) ,
                    }}
                    //error={getErrorByName(schema, 'color').error}
                    value={carModel}
                    onSelectChanged={(e) => setcarModel(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid container justify='center'>
                <FormControl
                  className='input-wrapper flex-wrap textField'
                  size='small'
                  variant='outlined'>
                  <Typography> {getTranslate()(`${translationPath}ClassType`)}</Typography>
                  <Select
                    inputPlaceholder={getTranslate()(`${translationPath}ClassType`)}
                    native
                    fullWidth
                    value={classTypeId}
                    onChange={(e) => setclassTypeId(e.target.value)}>
                    <option aria-label='None' value=''  >{getTranslate()(`${translationPath}ClassType`)}</option>
                    <option value='VIB GOLD'>VIP Gold</option>
                    <option value='VIB'>VIP</option>
                    <option value='VIB'>Business</option>
                    <option value='Normal'>Economy</option>
                  </Select>
                </FormControl>
              </Grid>{' '}
            </Grid>
            <Grid container item xs={6}>
              <Grid>
                <Grid container justify='center' spacing={1}>
                  <Grid item xs={6}>
                  <Typography>{getTranslate()(`${translationPath}SameCity`)}</Typography>
                    <FormControl className='input-wrapper'>
                      <Inputs
                        idRef='sameCityPickupPrice'
                        isWithError
                        type='number'
                        translationPath={translationPath}
                        helperText={getErrorByName(schema, 'sameCityPickupPrice').message}
                        inputPlaceholder='SameCity'
                        overInputText="AED"
                        error={getErrorByName(schema, 'sameCityPickupPrice').error}
                        value={sameCityPickupPrice}
                        onInputChanged={(e) => setsameCityPickupPrice(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                  <Typography>{getTranslate()(`${translationPath}differentCityDropoffPrice`)}</Typography>
                    <FormControl className='input-wrapper'>
                      <Inputs
                        idRef='sameCityDropoffPrice'
                        isWithError
                        type='number'
                        translationPath={translationPath}
                        overInputText="AED"
                        helperText={getErrorByName(schema, 'sameCityDropoffPrice').message}
                        inputPlaceholder='differentCityDropoffPrice'
                        error={getErrorByName(schema, 'sameCityDropoffPrice').error}
                        value={sameCityDropoffPrice}
                        onInputChanged={(e) => setsameCityDropoffPrice(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                
                <Grid container justify='center' spacing={1}>
                  <Grid item xs={6}>
                  <Typography>{getTranslate()(`${translationPath}DifferentCity`)}</Typography>
                    <FormControl className='input-wrapper'>
                      <Inputs
                        idRef='differentCityPickupPrice'
                        isWithError
                        type='number'
                        overInputText="AED"
                        translationPath={translationPath}
                        helperText={getErrorByName(schema, 'differentCityPickupPrice').message}
                        inputPlaceholder='differentCityPickupPrice'
                        error={getErrorByName(schema, 'differentCityPickupPrice').error}
                        value={differentCityPickupPrice}
                        onInputChanged={(e) => setdifferentCityPickupPrice(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                  <Typography>{getTranslate()(`${translationPath}differentCityDropoffPrice`)}</Typography>
                    <FormControl className='input-wrapper'>
                      <Inputs
                        idRef='differentCityDropoffPrice'
                        isWithError
                        type='number'
                        overInputText="AED"
                        translationPath={translationPath}
                        helperText={getErrorByName(schema, 'differentCityDropoffPrice').message}
                        inputPlaceholder='differentCityDropoffPrice'
                        error={getErrorByName(schema, 'differentCityDropoffPrice').error}
                        value={differentCityDropoffPrice}
                        onInputChanged={(e) => setdifferentCityDropoffPrice(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container justify='center'>
                  <FormControl className='input-wrapper flex-wrap textField'>
                    <Typography> {getTranslate()(`${translationPath}distence1KPrice`)}</Typography>
                    <Inputs
                      idRef='distence1KPrice'
                      isWithError
                      type='number'
                      overInputText="AED"
                      translationPath={translationPath}
                      helperText={getErrorByName(schema, 'distence1KPrice').message}
                      inputPlaceholder='distence1KPrice'
                      error={getErrorByName(schema, 'distence1KPrice').error}
                      value={distence1KPrice}
                      onInputChanged={(e) => setdistence1KPrice(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid container justify='center' spacing={1}>
                  <Grid item xs={12} className='textField actiongroup '>
                    <Typography>
                      {getTranslate()(`${translationPath}Passengertypeprices`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                  <Grid  >
                      <Typography> {getTranslate()(`${translationPath}ValidationErroPassenger-price`)}</Typography>
                    </Grid>
                    <FormControl className='input-wrapper '>
                      <Inputs
                        idRef='Passengerprice'
                        isWithError
                        type='number'
                        overInputText="AED"
                        translationPath={translationPath}
                        helperText={getErrorByName(schema, 'Passengerprice').message}
                        inputPlaceholder='ValidationErroPassenger-price'
                        error={getErrorByName(schema, 'Passengerprice').error}
                        value={Passengerprice}
                        onInputChanged={(e) => setPassengerprice(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                  <Grid >
                      <Typography> {getTranslate()(`${translationPath}ValidationErroadult-price`)}</Typography>
                    </Grid>
                    <FormControl className='input-wrapper'>
                      <Inputs
                        idRef='Adultprice'
                        isWithError
                        type='number'
                        overInputText="AED"
                        translationPath={translationPath}
                        helperText={getErrorByName(schema, 'Adultprice').message}
                        inputPlaceholder='ValidationErroadult-price'
                        error={getErrorByName(schema, 'Adultprice').error}
                        value={Adultprice}
                        onInputChanged={(e) => setAdultprice(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container justify='center' spacing={1} className={ (!props.isEdit) ? "spaceon" : ''}>
                  <Grid item xs={6}>
                  <Grid>
                      <Typography> {getTranslate()(`${translationPath}Children`)}</Typography>
                    </Grid>
                    <FormControl className='input-wrapper'>
                      <Inputs
                        idRef='Childrenprice'
                        isWithError
                        type='number'
                        overInputText="AED"
                        translationPath={translationPath}
                        helperText={getErrorByName(schema, 'Childrenprice').message}
                        inputPlaceholder='Children'
                        error={getErrorByName(schema, 'Childrenprice').error}
                        value={Childrenprice}
                        onInputChanged={(e) => setChildrenprice(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                  <Grid  >
                      <Typography> {getTranslate()(`${translationPath}Infant`)}</Typography>
                    </Grid>
                    <FormControl className='input-wrapper'>
                      <Inputs
                        idRef='Infantprice'
                        isWithError
                        type='number'
                        overInputText="AED"
                        translationPath={translationPath}
                        helperText={getErrorByName(schema, 'Infantprice').message}
                        inputPlaceholder='Infant'
                        error={getErrorByName(schema, 'Infantprice').error}
                        value={Infantprice}
                        onInputChanged={(e) => setInfantprice(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {props.isEdit === true ? (
                  <Grid container justify='center' spacing={1}>
                    <Grid item xs={12} className='action'>
                    <Typography> {getTranslate()(`${translationPath}status`)}</Typography>
                      <RadioGroup
                        aria-label='gender'
                        name='gender1'
                        value={value}
                        row
                        onChange={handleChange}>
                        <FormControlLabel
                          value='false'
                          control={<Radio color='primary' />}
                          label={getTranslate()(`${translationPath}Inactive`)}
                        />
                        <FormControlLabel
                          value='true'
                          control={<Radio color='primary' />}
                          label={getTranslate()(`${translationPath}Active`)}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Grid container justify='center'>
          {' '}
          <Button
            onClick={() => {   props.close();   ClearData ();   props.setIsEdit();   }}
            color='primary'
            className='btns bg-cancel'>
            {getTranslate()(`${translationPath}Cancel`)}
          </Button>
        </Grid>
        <DialogActions>
          <Grid container justify='space-around'></Grid>
        </DialogActions>
        <div
          className={
             typeName === '' ||noOfSets === '' ||carModel === '' ||classTypeId === ''||sameCityDropoffPrice === '' ||result === ''||Adultprice === '' 
                  ||sameCityPickupPrice === '' ||distence1KPrice === '' ||differentCityDropoffPrice === '' ||differentCityPickupPrice === ''
                                  ||Passengerprice === '' ||Childrenprice === '' ||Infantprice === '' ||result === ''
              ? 'DisableddoneBtn'
              : 'doneBtnContainer'
          }>
          <Grid container justify='center'>
            <Grid item xs={12} className='doneBtn'>
              <Button
                disabled={
                  typeName === '' ||noOfSets === '' ||carModel === '' ||classTypeId === '' ||sameCityDropoffPrice === '' ||sameCityPickupPrice === '' ||
                             distence1KPrice === '' ||differentCityDropoffPrice === '' ||differentCityPickupPrice === '' ||Adultprice === '' 
                                           ||Passengerprice === '' ||Childrenprice === '' ||Infantprice === '' ||result === ''
                    ? true
                    : false
                }
                className='btns theme-solid'
                onClick={async () => {
                  setLoading(true);
                  props.isEdit === false
                    ? await CarTypes({
                        typeName: typeName,
                        noOfSets: +noOfSets,
                        carModel: +carModel,
                        classTypeId: classTypeId,
                        sameCityPickupPrice: +sameCityPickupPrice,
                        sameCityDropoffPrice: +sameCityDropoffPrice,
                        differentCityPickupPrice: +differentCityPickupPrice,
                        differentCityDropoffPrice: +differentCityDropoffPrice,
                        distence1KPrice: +distence1KPrice,
                        imageFileId: result.uuid,
                        carPassengersPrices: [
                          {
                            carTypesId: 0,
                            passengerTypeId: +Adult,
                            price: +Adultprice,
                          },
                          {
                            carTypesId: 0,
                            passengerTypeId: +Passenger,
                            price: +Passengerprice,
                          },
                          {
                            carTypesId: 0,
                            passengerTypeId: +Children,
                            price: +Childrenprice,
                          },
                          {
                            carTypesId: 0,
                            passengerTypeId: +Infant,
                            price: +Infantprice,
                          },
                        ],
                      })
                    : (await EditCarTypes(props.CarDetails.carTypesId, {
                        typeName: typeName,
                        noOfSets: +noOfSets,
                        carModel: +carModel,
                        classTypeId: classTypeId,
                        sameCityPickupPrice: +sameCityPickupPrice,
                        sameCityDropoffPrice: +sameCityDropoffPrice,
                        differentCityPickupPrice: +differentCityPickupPrice,
                        differentCityDropoffPrice: +differentCityDropoffPrice,
                        distence1KPrice: +distence1KPrice,
                        imageFileId: result.uuid ? result.uuid : result,
                        carPassengersPrices: [
                          {
                            carTypesId: 0,
                            passengerTypeId: +Adult,
                            price: +Adultprice,
                          },
                          {
                            carTypesId: 0,
                            passengerTypeId: +Passenger,
                            price: +Passengerprice,
                          },
                          {
                            carTypesId: 0,
                            passengerTypeId: +Children,
                            price: +Childrenprice,
                          },
                          {
                            carTypesId: 0,
                            passengerTypeId: +Infant,
                            price: +Infantprice,
                          },
                        ],
                      })) && (await ActivateTypeCar(+props.CarDetails.carTypesId, value));
                                     props.reloadData();  props.close();
                  if (props.isEdit === false) {
                    showSuccess(getTranslate()(`${translationPath}NotificationshowADDSuccess`));
                    ClearData()
                  } else {
                    showSuccess(getTranslate()(`${translationPath}NotificationshowEditSuccess`));
                  }
                  setLoading(false);
                }}>
                {getTranslate()(`${translationPath}Done`)}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </Dialog>
  );
};
export default DialogAddTypeCar;
