import React,{ useState , useCallback, useEffect} from 'react'
import { Line } from 'react-chartjs-2';
import './UsersEngagement.scss'
import { getTranslate } from ' ./../../src/Helpers';
import { GetUserEngagementStatistics } from './../../../../../Serviecs';
import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';

export const UsersEngagement = () => {
  const translationPath = 'UserEngagement.';
  const [isLoading, setIsLoading] = useState(true);
 
    const [chartData,setChartData] = useState({
        labels: ['January','February','March','April','May','June','July','August','September','October','November','December'],
        datasets: [
            {

           label:'Active Count',
                fill: false,
                borderColor:'#4791FF',
                data: [],
               
              },
              {
                label:'InActive Count',
                fill: false,
                borderColor:'#02BC77',
                data: [],
               
              },  
              {
                label:'Dormant Count',
                fill: false,
                borderColor:'#F15740',
                data: [],
               
              }, 
        ],
      });

      const GetUserEngagement = useCallback(async () => {
        setIsLoading(true);
        const Status = await GetUserEngagementStatistics();
        if (Status) {
          setChartData((items) => {
            Status && Status.map((engagement)=>(
              (items.datasets[0].data.push(engagement.activeCount),
              items.datasets[1].data.push(engagement.inActiveCount),
              items.datasets[2].data.push(engagement.dormantCount))
            ));
            return {...items};
          });
          setIsLoading(false);
     
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
      useEffect(() => {
        GetUserEngagement();
      }, [GetUserEngagement]);
    
      
    return (
        <div className='UsersEngagement'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className="UsersEngagementTitle"> 
        <p className="UsersEngagementPargraph">{getTranslate()(`${translationPath}User Engagement`)}</p>
        </div>
        <div className='LineChart'> 

     { !isLoading&&  <Line
        
              data={chartData}
              options={{
                  responsive: true,
                        legend:{
                  display:false,
                },
                scales: {
                    yAxes: [

                      {
                        
                        ticks: {
                          min: 0,
                         
                        },
                   
                      },
                    ],
                    xAxes: [
                      {
                       gridLines: {
                          display: false,
                        },
                      },
                    ],
                  },
                
              }}
              
            />}
            <hr/>
            <div className='lables-Engagement'>
            {chartData.datasets &&
              chartData.datasets.map((label, index) => (
                <div key={index} className='chart-item'>
                  <span
                    className='mdi mdi-circle-medium column'
                    style={{
                      color: `${label.borderColor}`,
                    }}
                  />
                  <span>{label.label}</span>
    
                </div>
              ))}
          </div>
        
        </div>
        </div>
    )
}
