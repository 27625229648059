import config from '../../Assets/Configration/Configration';
import { HttpServices } from '../HttpServices/HttpServices';
import { LogoutAction } from '../../Helpers';
import { showError, getTranslate } from '../../Helpers';

export const LoginService = async (payload) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/ApplicationLogin`,
      payload
    );
    return result;
  } catch (e) {
    if (e.response && e.response) throw e.response;
    else throw e;
  }
};

export const RefreshToken = async (payload) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/RefreshToken`,
      payload
    );
    return result;
  } catch (e) {
    LogoutAction()();
    //throw e;
  }
};

export const GetApplicationUserForgotPasswordOptions = async (
  identity,
  applicationId,
  organizationId
) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Identity/Account/GetApplicationUserForgotPasswordOptions/${identity}/${applicationId}/${organizationId}`
    );
    return result;
  } catch (e) {
    showError(getTranslate()('login.the-identity-could-not-be-found'));
  }
};

export const ApplicationUserForgotPassword = async (payload) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/ApplicationUserForgotPassword`,
      payload
    );
    return result;
  } catch (e) {
    showError(getTranslate()('Error retriving data'));
  }
};

export const VerifyApplicationUserCode = async (payload) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/VerifyApplicationUserCode`,
      payload
    );
    return result;
  } catch (e) {
    showError(getTranslate()('login.wrong-verification-code'));
  }
};

export const ApplicationUserResetPassword = async (payload) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/ApplicationUserResetPassword`,
      payload
    );
    return result;
  } catch (e) {
    if (e.response && e.response) throw e.response;
    else throw e;
  }
};
