export const TripsFeaturesEnum = {
  FeatureType: {
    key: 'FeatureType',
    value: 'FeatureType',
    id: 1143,
  },
  FeatuerDetails: {
    key: 'FeatuerDetails',
    value: 'FeatuerDetails',
    id: 1144,
  },
};
