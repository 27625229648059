import React from 'react';
import { Button, DialogTitle, DialogActions, Dialog, Typography } from '@material-ui/core';
import { DeleteContentPages } from '../../../../../Serviecs/PageContentServices/PageContentServices';
import { showSuccess, getTranslate } from '../../../../../Helpers';

const PageContentDeleteDialog = (props) => {
  const translationPath = 'PageContents.';

  const handleDeleteButton = async () => {
    props.setOpen(false);
    await DeleteContentPages(props.item.pageContentId);
    showSuccess('Page Deleted Successfully');
    props.reloadData();
  };

  return (
    <div className='deleteSlider'>
      <Dialog open={props.open}>
        <DialogTitle className='sliderTitle'>
          <Typography style={{ fontSize: 16 }}>
            {getTranslate()(`${translationPath}AreYouSureYouWantToDelete`)}
            {'  '}
            {props.item.pageName}?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => props.setOpen(false)} color='primary' className='btns bg-cancel'>
            {getTranslate()(`${translationPath}Cancel`)}
          </Button>
          <Button
            style={{ width: 90, height: 30 }}
            className='btns theme-solid'
            onClick={handleDeleteButton}>
            {getTranslate()(`${translationPath}Confirm`)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { PageContentDeleteDialog };
