import React, { useEffect, useCallback, useState } from 'react';
import { IconButton, TextField, Button, FormControl } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { ViewTypes, Spinner } from '../../Componentes';
import { ViewTypesEnum } from '../../Enums';
import { FormBuilderGridView } from './FormBuilderGridView/FormBuilderGridView';
import { FormBuilderListView } from './FormBuilderListView/FormBuilderListView';
import { GetForms } from '../../Serviecs';
import { getTranslate } from '../../Helpers';
import { useTitle } from '../../Hooks/useTitle';
import { NoDataFoundComponent, NoContentComponent } from '../../Componentes';

const PickFormBuilder = () => {
  const [loading, setLoading] = useState(false);
  const [searchedItem, setSearchedItem] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [formsResponse, setFormsResponse] = useState({});
  const [activeActionType, setActiveActionType] = useState(ViewTypesEnum.cards.key);
  let time = setTimeout(() => {}, 300);
  useTitle(getTranslate()(`SideMenuView.FormBuilder`));

  const GetMyForms = async (pageIndex, PageSize, searchedItem) => {
    setLoading(true);
    const res = await GetForms(pageIndex, PageSize, searchedItem);
    setFormsResponse(res);
    if (res && res.totalCount === 0) {
      setisFirstLoad(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    GetMyForms(pageIndex, pageSize, '');
  }, [setFormsResponse, pageIndex, pageSize]);

  const handleClearSearch = () => {
    setSearchedItem('');
    GetMyForms(pageIndex, pageSize, '');
  };

  const onTypeChanged = useCallback(
    (activeType) => {
      setActiveActionType(activeType);
    },
    [setActiveActionType]
  );

  const handleAddForm = () => {};

  return (
    <div className='view-wrapper'>
      <Spinner isActive={loading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <Button className='btns theme-solid bg-secondary' onClick={handleAddForm}>
              {getTranslate()('home.formBuilderView.AddForm')}
            </Button>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <FormControl className='input-wrapper'>
                <Button className='btns-icon theme-transparent m  x-2'>
                  <span className='mdi mdi-magnify' />
                </Button>
                <TextField
                  className='inputs theme-solid'
                  value={searchedItem}
                  onKeyUp={() => {
                    time = setTimeout(() => {
                      GetMyForms(pageIndex, pageSize, searchedItem);
                    }, 500);
                  }}
                  onKeyDown={() => {
                    clearTimeout(time);
                  }}
                  onChange={(e) => setSearchedItem(e.target.value)}
                  label={getTranslate()('home.formBuilderView.SearchForm')}
                  variant='outlined'
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {searchedItem && (
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <ViewTypes
              onTypeChanged={onTypeChanged}
              activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
              className='mb-3'
            />
          </div>
        </div>
      </div>
      <NoDataFoundComponent />
      {formsResponse && formsResponse.result && searchedItem.length === 0 && !isFirstLoad && (
        <NoContentComponent />
      )}
      {activeActionType === ViewTypesEnum.tableView.key ? (
        <FormBuilderListView
          searchedItem={searchedItem}
          formsResponse={formsResponse}
          page={pageIndex}
          setPage={setPageIndex}
          rowsPerPage={pageSize}
          setRowsPerPage={setPageSize}
          reloadData={async () => await GetMyForms(pageIndex, pageSize, searchedItem)}
          isFirstLoad={isFirstLoad}
        />
      ) : (
        <FormBuilderGridView
          searchedItem={searchedItem}
          formsResponse={formsResponse}
          reloadData={async () => await GetMyForms(pageIndex, pageSize, searchedItem)}
          isFirstLoad={isFirstLoad}
        />
      )}
    </div>
  );
};
export default PickFormBuilder;
