import { PromoCodeView, AddNewPromoCode } from '../../Views/Home';

export const PromoCodeRoutes = [
  {
    id: 32,
    path: '/AddNewPromoCode',
    name: 'PromoCode.add-new-promo-code',
    component: AddNewPromoCode,
    layout: '/home/PromoCode',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 1,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'PromoCode.PromoCode',
        isDisabled: false,
        route: '/home/PromoCode',
      },
      {
        name: 'PromoCode.add-new-promo-code',
        isDisabled: false,
        route: '/home/PromoCode/AddNewPromoCode',
      },
    ],
  },
  {
    id: 33,
    path: '/UpdatePromoCode',
    name: 'PromoCode.update-promo-code',
    component: AddNewPromoCode,
    layout: '/home/PromoCode',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 1,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'PromoCode.PromoCode',
        isDisabled: false,
        route: '/home/PromoCode',
      },
      {
        name: 'PromoCode.update-promo-code',
        isDisabled: false,
        route: '/home/PromoCode/UpdatePromoCode',
      },
    ],
  },
  {
    id: 30,
    path: '/PromoCode',
    name: 'PromoCode.PromoCode',
    component: PromoCodeView,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 1,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'PromoCode.PromoCode',
        isDisabled: false,
        route: '/home/PromoCode',
      },
    ],
  },
];
