import React, { useState } from 'react';
import { DeleteSlider } from '../../../../../Serviecs/SliderViewServices/SliderViewServices';
import { showSuccess, getTranslate, showError } from '../../../../../Helpers';
import { DialogComponent, Spinner } from '../../../../../Componentes';

const DeleteSliderDialog = (props) => {
  const [loading, setLoading] = useState(false);
  const translationPath = 'SliderView.';

  const deleteHandler = async () => {
    setLoading(true);
    const res = await DeleteSlider(props.item.sliderId);
    setLoading(false);
    if (res) showError(getTranslate()(`${translationPath}${res}`));
    else {
      showSuccess(`${getTranslate()(`${translationPath}slider-deleted-successfully`)}`);
      props.setReloaded(true);
      props.reloadData();
    }
    props.setOpen(false);
  };

  return (
    <DialogComponent
      titleText='confirm-message'
      saveText='Confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <Spinner isActive={loading} />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>{`${getTranslate()(`${translationPath}AreYouSureYouWantToDelete`)}  ${
            props.item.sliderName
          }?`}</span>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mb-0 mx-0 br-0'
      isOpen={props.open}
      onSaveClicked={deleteHandler}
      onCloseClicked={() => props.setOpen(false)}
      onCancelClicked={() => props.setOpen(false)}
      translationPath={translationPath}
    />
  );
};

export { DeleteSliderDialog };
