import React, { useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { getDownloadableLink, getTranslate } from '../../../../../../../Helpers';
import Rating from '@material-ui/lab/Rating';
import './WebsiteRectangleBestTours.scss';
import { LoadableImageComponant } from '../../../../../../../Componentes';

export const WebsiteRectangleBestTours = ({ translationPath, tours }) => {
  const [rating, setRating] = useState(2);

  return (
    <div className='BestTours-Seaction'>
      <div className='best-tours-title'>
        {getTranslate()(`${translationPath}tours-picked-for-you`)}
      </div>
      <div className='flex-container'>
        {tours &&
          tours.result.map((item, i) => {
            return (
              i < 3 && (
                <div className='card-border'>
                  <div
                    className={`${
                      i !== 1 ? 'first-card-container' : 'first-card-container center-card-wrapper'
                    }`}>
                    <div className='favourite-heart'>
                      <IconButton>
                        <span className='mdi mdi-heart-outline' />
                      </IconButton>
                    </div>
                    <LoadableImageComponant
                      src={getDownloadableLink(item.galleryList[0])}
                      alt={item.tourName}
                      classes='img-center'
                    />
                    <div className='text-big-bold text-big-bold-center'>{item.tourName}</div>
                    <div className='text-small'>
                      {item.tourSummaryTrips.length}{' '}
                      {getTranslate()(`${translationPath}distinations`)}
                    </div>
                    <div className='text-small rating-wrapper'>
                      <Rating
                        name='simple-controlled'
                        value={rating}
                        onChange={(event, newValue) => {
                          setRating(newValue);
                        }}
                      />
                      160 {getTranslate()(`${translationPath}reviews`)}
                    </div>
                    <div className='text-small'>{getTranslate()(`${translationPath}price`)}</div>
                    <div className='text-big-bold'>
                      {item.totalFromPriceAfterDiscount} AED{' '}
                      <Button>{getTranslate()(`${translationPath}add-to-cart`)}</Button>
                    </div>
                  </div>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
};
