import React, { useEffect, useCallback, useState, useRef } from 'react';
import { getTranslate } from ' ./../../src/Helpers';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';
import { DefaultImagesEnum, BasketReportEnum } from '../../../Enums';
import { useTitle } from '../../../Hooks/useTitle';
import { AgentsCardComponent, AgentsDetailsComponent } from './ReportBasketUtilities';
import { GetAllUsersBasket } from '../../../Serviecs';
import { addDays } from 'date-fns';
import moment from 'moment';
import {
  Button,
  ButtonBase,
  FormControl,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from '@material-ui/core';
import {
  Spinner,
  OrderByComponent,
  DateRangePickerComponent,
  NoDataFoundComponent,
  NoSearchResultComponent,
  NoContentComponent,
} from '../../../Componentes';
import ClearIcon from '@material-ui/icons/Clear';
import './ReportBasket.scss';
const translationPath = 'ReportBasketView.';

export const ReportBasketView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const filterDefault = {
    search: '',
    orderByType: null,
    orderColumnName: null,
    basketTourStartDate: null,
    basketTourStartEnd: null,
  };
  const sortDefult = {
    orderBy: {
      key: 0,
      value: null,
    },
    column: {
      key: 0,
      value: null,
    },
  };
  const dateRangeDefault = {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
  };
  const [filter, setFilter] = useState(filterDefault);
  const [sort, setSort] = useState(sortDefult);
  const [responseResult, setResponseResult] = useState(() => ({ result: [], totalCount: 0 }));
  const [dateRange, setDateRange] = useState(dateRangeDefault);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [, setActiveItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(14);
  const [reloaded] = useState(false);
  const [searchedItem, setSearchedItem] = useState('');
  const searchTimer = useRef(null);
  const open = Boolean(anchorEl);
  let time = setTimeout(() => {}, 300);

  useTitle(getTranslate()(`${translationPath}ReportBasketView`));

  const getRandomDefaultImage = () =>
    DefaultImagesEnum.boys.defaultImgs[Math.floor(Math.random() * 4)];

  const getAllBasketTours = useCallback(
    async (pageIndex, pageSize) => {
      setIsLoading(true);
      const res = await GetAllUsersBasket(pageIndex, pageSize, {
        ...filter,
        orderColumnName: filter.orderColumnName || 'FirstName',
      });

      if (res && res.result)
        res.result.map((item) => (item.defaultImage = getRandomDefaultImage()));

      if (res && res.totalCount === 0) {
        setIsFirstLoad(false);
      }

      if (reloaded) setResponseResult({ result: [], totalCount: 0 });
      else {
        if (page === 0) setResponseResult(res);
        else {
          setResponseResult((item) => ({
            result: item.result.concat(res.result),
            totalCount: res.totalCount,
          }));
        }
      }

      setIsLoading(false);
    },
    [filter, page, reloaded]
  );

  const onCardClicked = (item) => {
    setActiveItem(item);
    sideMenuComponentUpdate(
      <AgentsDetailsComponent activeItem={item} filter={filter} translationPath={translationPath} />
    );
    sideMenuIsOpenUpdate(true);
  };

  useEffect(() => {
    getAllBasketTours(page + 1, rowsPerPage);
  }, [getAllBasketTours, page, rowsPerPage]);

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'></div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <FormControl className='input-wrapper'>
                <Button className='btns-icon theme-transparent mx-2'>
                  <span className='mdi mdi-magnify' />
                </Button>
                <TextField
                  className='inputs theme-solid'
                  value={searchedItem}
                  onKeyUp={() => {
                    time = setTimeout(() => {
                      setFilter((item) => ({ ...item, search: searchedItem }));
                    }, 500);
                  }}
                  onKeyDown={() => {
                    clearTimeout(time);
                  }}
                  onChange={(e) => setSearchedItem(e.target.value)}
                  label={getTranslate()(`${translationPath}Search`)}
                  placeholder={getTranslate()(`${translationPath}search-by-client-name`)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {searchedItem && (
                          <IconButton
                            onClick={() => {
                              setSearchedItem('');
                              setFilter((item) => ({ ...item, search: '' }));
                            }}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className='second-filter-section pt-2 c-black-light px-2'>
          <span className='text-nowrap px-2'>{getTranslate()(`${translationPath}filter-by`)}</span>
          <div>
            <div className='w-100 px-2'>
              <OrderByComponent
                selectedOrder={sort}
                selectedOrderChanged={(selectedOrder) => {
                  setSort((items) => ({
                    ...items,
                    orderBy: selectedOrder,
                  }));
                  if (sort.column.key) {
                    setFilter((item) => ({
                      ...item,
                      orderColumnName: sort.column.key,
                      orderByType: selectedOrder.key - 1,
                    }));
                  }
                }}
                selectedColumnChanged={(selectedColumn) => {
                  setSort((items) => ({
                    ...items,
                    column: selectedColumn,
                    orderBy: {
                      ...items.orderBy,
                      key: selectedColumn.key !== 0 ? items.orderBy.key : 0,
                    },
                  }));
                  setFilter((item) => ({
                    ...item,
                    orderColumnName: selectedColumn.key,
                    orderByType: selectedColumn.key !== 0 ? item.orderByType : -1,
                  }));
                }}
                columnsKeyValues={BasketReportEnum}
                translationPath={translationPath}
              />
            </div>
          </div>
          <div className='px-2'>
            <FormControl className='input-wrapper flex-wrap inputs theme-solid'>
              <TextField
                variant='outlined'
                value={
                  dateRange &&
                  `From: ${moment(dateRange.startDate).format('DD/MM/yyyy')}, To: ${moment(
                    dateRange.endDate
                  ).format('DD/MM/yyyy')}`
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <span className='mdi mdi-calendar-range' />
                      {getTranslate()(`${translationPath}date`)}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end' className='mdi-icons-color'>
                      {open ? (
                        <span className='mdi mdi-chevron-up' />
                      ) : (
                        <span className='mdi mdi-chevron-down' />
                      )}
                    </InputAdornment>
                  ),
                }}
                onClick={(e) => handleClick(e)}
              />
              <Popover
                className='report-basket-container'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <div className='btns-container'>
                  <Button
                    onClick={() => {
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: '',
                        basketTourStartEnd: '',
                      }));
                    }}>
                    {getTranslate()(`${translationPath}all-dates`)}
                  </Button>
                  <DateRangePickerComponent
                    ranges={[dateRange]}
                    onDateChanged={(selectedDate) => {
                      setDateRange((items) => ({
                        ...items,
                        startDate: selectedDate.selection.startDate,
                        endDate: selectedDate.selection.endDate,
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: selectedDate.selection.startDate,
                        basketTourStartEnd: selectedDate.selection.endDate,
                      }));
                    }}
                  />
                  <Button
                    onClick={() => {
                      setDateRange((items) => ({
                        ...items,
                        startDate: new Date(),
                        endDate: new Date(),
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: new Date(),
                        basketTourStartEnd: new Date(),
                      }));
                    }}>
                    {getTranslate()(`${translationPath}today`)}
                  </Button>
                  <Button
                    onClick={() => {
                      const date = new Date();
                      date.setDate(date.getDate() - 1);
                      setDateRange((items) => ({
                        ...items,
                        startDate: date,
                        endDate: date,
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: date,
                        basketTourStartEnd: date,
                      }));
                    }}>
                    {getTranslate()(`${translationPath}yesterday`)}
                  </Button>
                  <Button
                    onClick={() => {
                      const date = new Date();
                      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                      setDateRange((items) => ({
                        ...items,
                        startDate: firstDay,
                        endDate: lastDay,
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: firstDay,
                        basketTourStartEnd: lastDay,
                      }));
                    }}>
                    {getTranslate()(`${translationPath}this-month`)}
                  </Button>
                  <Button
                    onClick={() => {
                      const date = new Date();
                      const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                      const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
                      setDateRange((items) => ({
                        ...items,
                        startDate: firstDay,
                        endDate: lastDay,
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: firstDay,
                        basketTourStartEnd: lastDay,
                      }));
                    }}>
                    {getTranslate()(`${translationPath}past-month`)}
                  </Button>
                  <Button
                    onClick={() => {
                      const date = new Date();
                      const firstDay = new Date(date.getFullYear(), date.getMonth() - 3, 1);
                      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                      setDateRange((items) => ({
                        ...items,
                        startDate: firstDay,
                        endDate: lastDay,
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: firstDay,
                        basketTourStartEnd: lastDay,
                      }));
                    }}>
                    {getTranslate()(`${translationPath}past-3-month`)}
                  </Button>
                </div>
              </Popover>
            </FormControl>
          </div>
          <div className='px-2'>
            <ButtonBase
              className='btns theme-solid'
              onClick={() => {
                setDateRange(dateRangeDefault);
                setSort(sortDefult);
                setFilter(filterDefault);
              }}>
              <span>{getTranslate()(`${translationPath}reset`)}</span>
            </ButtonBase>
          </div>
        </div>
      </div>
      <NoDataFoundComponent />
      {responseResult.result.length === 0 && !filter.search && !isFirstLoad && (
        <NoContentComponent />
      )}
      {responseResult &&
        responseResult.totalCount === 0 &&
        filter.search.length !== 0 &&
        !isFirstLoad && <NoSearchResultComponent />}
      <AgentsCardComponent
        data={responseResult}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        filter={filter}
        onCardClicked={onCardClicked}
        translationPath={translationPath}
        isLoading={isLoading}
      />
    </div>
  );
};
