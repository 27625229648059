import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';

const GetAllOrganizationUser = async (pageIndex, pageSize, userStatusId) => {
  try {
    const GetUrl = () => {
      let url = '';
      if (userStatusId === null) {
        url = `${config.serverAddress}/Identity/Account/GetAllApplicationUser/${pageIndex}/${pageSize}`;
      } else {
        url = `${config.serverAddress}/Identity/Account/GetAllApplicationUser/${pageIndex}/${pageSize}?userStatusId=${userStatusId}`;
      }
      return url;
    };
    const result = await HttpServices.get(GetUrl());
    return result;
  } catch (e) {
    return;
  }
};

const CancelOrganizationUser = async (userId) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Identity/Account/CancelApplicationUser/${userId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const UpdateMyProfileImage = async (fileId) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Identity/Account/UpdateMyProfileImage`,
      { fileId }
    );
    return result;
  } catch (e) {
    return;
  }
};

const DeleteOrganizationUser = async (userId) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/Identity/Account/DeleteApplicationUser/${userId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const ActiveOrganizationUser = async (userId) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Identity/Account/ActiveApplicationUser/${userId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const EditOrganizationUserProfile = async (userId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Identity/Account/EditApplicationUserProfile/${userId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

const CreateOrganizationUser = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/CreateApplicationUser`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

const UploadUserPicture = async (body) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/FileManager/File/Upload`, body);
    return result;
  } catch (e) {
    return;
  }
};

const GetLookupItem = async (typeName) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem?lookupTypeName=${typeName}`
    );
    return result;
  } catch (e) {
    return;
  }
};
const CheckExistUserName = async (username) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/CheckExistUserName`,
      {
        identity: username,
      }
    );
    return result;
  } catch (e) {
    return;
  }
};
const CheckExistEmail = async (username) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/CheckExistEmail`,
      {
        identity: username,
      }
    );
    return result;
  } catch (e) {
    return;
  }
};
const CheckExistPhone = async (username) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/CheckExistPhone`,
      {
        identity: username,
      }
    );
    return result;
  } catch (e) {
    return;
  }
};
const OrganizationUserSearch = async (body) => {
  body.pageIndex += 1;
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/ApplicationUserSearch`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

const AssignRolesToUser = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Authorization/Roles/AssignRolesToUser`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

const GetAllRolesByUserId = async (userId, pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Authorization/Roles/GetAllRolesByUsersId/${userId}/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const countryservis = async (body) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem?lookupTypeName=country`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

const RemoveRolesFromUser = async (userRolesId) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/Authorization/Roles/RemoveRolesFromUser/${userRolesId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const GetToursInformation = async (userId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Tours/GetToursInformationByUserId/${userId}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export {
  RemoveRolesFromUser,
  GetAllOrganizationUser,
  CancelOrganizationUser,
  ActiveOrganizationUser,
  EditOrganizationUserProfile,
  CreateOrganizationUser,
  UploadUserPicture,
  GetLookupItem,
  OrganizationUserSearch,
  DeleteOrganizationUser,
  AssignRolesToUser,
  GetAllRolesByUserId,
  countryservis,
  CheckExistUserName,
  CheckExistEmail,
  CheckExistPhone,
  GetToursInformation,
  UpdateMyProfileImage,
};
