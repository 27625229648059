import React, { useState, useCallback, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import './LocationsAndSoldTours.scss';
import { GetTotalSoldToursStatistics } from '../../../../../Serviecs';
import { SelectComponet ,Spinner} from '../../../../../Componentes';
import { TotalToursEnum } from '../../../../../Enums';
import moment from 'moment';
import { getTranslate } from ' ./../../src/Helpers';
import { getLanguage } from 'react-switch-lang';

export const TotalSoldTours = () => {

  const translationPath = 'TotalSoldTours.';
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const [isLoading, setIsLoading] = useState(true);
  const [barValue, setBarValue] = useState(true);
  const [dropDownValue, setDropDownValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [chartData, setChartData] = useState({
    startDate:'',
    endDate:'',
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: '#7388A95A',
      },
    ],
  });

  const openHandler = () => {
    setIsOpen(true);
  };
  const closeHandler = () => {
    setIsOpen(false);
  };

  const GetTotalSoldTour = useCallback(async () => {
    setIsLoading(true);
    const Status = await GetTotalSoldToursStatistics(dropDownValue);
    if (Status) {
  
      setChartData((items) => {
        items.startDate=Status.startDate;
        items.endDate=Status.endDate;
        items.labels = Status.dateUnitSalesList.map((item) => item.name);
       items.datasets[0].data=Status.dateUnitSalesList.map((item)=>item.value)
        items.datasets[0].backgroundColor = chartData.datasets[0].data.map(
          (item, index) =>
            (index ===
              chartData.datasets[0].data.reduce(
                (iMax, x, i, arr) => (x > arr[iMax] ? i : iMax),
                0
              ) &&
              '#F55200') ||
            '#7388A95A'
        );
        items.datasets[0].data.reduce(reducer) ===0? setBarValue(false):setBarValue(true) 
     
        return { ...items };

      });
      setIsLoading(false);
     }

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownValue]);

  useEffect(() => {
    GetTotalSoldTour();
  }, [GetTotalSoldTour]);

  return (
    <div className='Locations'>
    <Spinner isActive={isLoading} isAbsolute />
      <div className='LocationsTitle'>
        <p className='LocationsPargraph Select'>{getTranslate()(`${translationPath}Total-sold-tours`)} </p>
        <div className='SoldTours-select'>
        <SelectComponet
          isOpen={isOpen}
          onOpen={openHandler}
          onClose={closeHandler}
          initialSelectValue={0}
          data={TotalToursEnum}
          value={dropDownValue}
          textInput='value'
          valueInput='key'
          translationPathForData={translationPath}
          onSelectChanged={(event) => setDropDownValue(event.target.value)}
        />
      </div>
      </div>

      <div className="ToursDate">
      {`${moment(chartData.startDate).locale(getLanguage()).format('DD MMM')} - ${moment(chartData.endDate).locale(getLanguage()).format('DD MMM')}`}
      
      </div>
      <div className="TargetGool">
      </div>
      

      <Grid container spacing={2}>
        <Grid xs={12}>
        {barValue ? 
           <Bar
          data={chartData}
          base={['0', '25', '50', '75', '100']}
          options={{
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 0.5,
                  ticks: {
                    min: 0,
                    stepSize: 25,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 0.6,
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          }}
        /> : <div className='NoData TotalTours '> </div>} 
         
        </Grid>
      </Grid>
    </div>
  );
};
