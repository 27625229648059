/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getDownloadableLink, getTranslate, useEventListener } from '../../../../../Helpers';
import { ActionsEnum } from '../../../../../Enums';
import { Button, IconButton } from '@material-ui/core';
import { LoadableImageComponant, NoSearchResultComponent } from '../../../../../Componentes';
import { getLanguage } from 'react-switch-lang';
import moment from 'moment';
import { GetAllToursBySectionId } from '../../../../../Serviecs';
import './SectionViewCardComponent.scss';

export const RecommendedToursCardComponent = ({
  data,
  filter,
  translationPath,
  onLoadMore,
  onActionClicked,
  isLoading,
  isFirstLoad,
  previewHandler,
  setIsMobile,
  setIsWebsite,
  setActiveItem,
}) => {
  const [cardCount, setCardCount] = useState([2]);
  const [tourImage, setTourImage] = useState([]);
  const [toursCount, setToursCount] = useState([]);
  const [tourName, setTourName] = useState([]);

  const onScrollHandler = useCallback(() => {
    if (
      data &&
      data.result &&
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - 5 &&
      data.result.length < data.totalCount &&
      isLoading !== true
    )
      onLoadMore();
  }, [data, isLoading, onLoadMore]);
  useEventListener('scroll', onScrollHandler);
  useEffect(() => {
    if (
      data &&
      data.result &&
      data.result.length < data.totalCount &&
      document.body.scrollHeight <= document.body.clientHeight &&
      isLoading !== true
    )
      onScrollHandler();
  }, [data, isLoading, onScrollHandler]);

  const onCardClicked = async (item, index) => {
    // const result = await GetAllToursBySectionId(cardCount[index] - 1, 1, item.applicationSectionId);
  };

  const onCardImageClicked = async (item, index) => {
    const result = await GetAllToursBySectionId(cardCount[index] - 1, 1, item.applicationSectionId);
    setTourName((items) => {
      items[index] = result && result.result[0].tourName;
      return [...items];
    });
    setTourImage((items) => {
      items[index] = result && result.result[0].coverTripImageId;
      return [...items];
    });
    setToursCount((items) => {
      items[index] = result && result.totalCount;
      return [...items];
    });
    setActiveItem(item);
  };

  useEffect(() => {
    setCardCount(() => {
      const count = [];
      data.result.map(() => count.push(2));
      return count;
    });
  }, [data]);

  const handleCardClick = (item) => {
    setActiveItem(item);
    if (item.webDesign !== 0) {
      setIsWebsite(true);
      setIsMobile(false);
    }
    if (item.mobileDesign !== 0) {
      setIsMobile(true);
      setIsWebsite(false);
    }
    if (item.mobileDesign !== 0 && item.webDesign !== 0) {
      setIsMobile(true);
      setIsWebsite(true);
    }
    previewHandler(item.applicationSectionId);
  };

  return (
    <div className='trip-card-conponent-wrapper'>
      {data && data.totalCount === 0 && filter.search && !isFirstLoad ? (
        <NoSearchResultComponent />
      ) : (
        <>
          {data &&
            data.result &&
            data.result.map((item, index) => (
              <div className='trip-card-wrapper' key={`card${index + 1}`}>
                <a
                  onClick={() => onCardClicked(item, index)}
                  className='cards-wrapper tour-padding'>
                  <div className='cards-header'>
                    <div className='trip-cover-image'>
                      <div className='section-cover-wrapper'>
                        <div className='section-cover-image' onClick={() => handleCardClick(item)}>
                          <div className='preview-hover'>
                            <div className='preview-wrapper'>
                              <div className='preview-text'>
                                {getTranslate()(`${translationPath}preview`)}
                              </div>
                              <div className='preview-icon'>
                                <span className='mdi mdi-monitor-cellphone' />
                                <div className='hover-tour-number'>
                                  {cardCount[index] === 1 || !tourName[index]
                                    ? (item && item.applicationSectionTour && item.applicationSectionTour.tourName) || ""
                                    : tourName[index]}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='loadable-image-name'>
                            <LoadableImageComponant
                              src={getDownloadableLink(
                                !tourImage[index]
                                  ? (item && item.applicationSectionTour && item.applicationSectionTour.coverTripImageId) || ""
                                  : tourImage[index]
                              )}
                              alt={
                                !tourName[index]
                                  ? (item && item.applicationSectionTour && item.applicationSectionTour.tourName) || ""
                                  : tourName[index]
                              }
                              classes='section-load-image'
                            />
                            <div className='tour-name-wrapper'>
                              <div className='tour-number'>
                                {!tourName[index]
                                  ? (item && item.applicationSectionTour && item.applicationSectionTour.tourName) || ""
                                  : tourName[index]}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='section-cover-placeholder'></div>
                        <div className='section-cover-placeholder2'></div>
                        <div
                          className={`${toursCount[index] === cardCount[index]
                              ? 'section-cover-button-right-disabled'
                              : 'section-cover-button-right'
                            }`}>
                          <IconButton
                            className={`${getLanguage() !== 'ar'
                                ? 'mdi mdi-chevron-right'
                                : 'mdi mdi-chevron-left'
                              }`}
                            disabled={toursCount[index] === cardCount[index]}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setCardCount((items) => {
                                items[index] += 1;
                                return [...items];
                              });
                              onCardImageClicked(item, index);
                            }}
                          />
                        </div>
                        <div
                          className={`${!cardCount[index] || cardCount[index] <= 2 || !tourImage[index]
                              ? 'section-cover-button-left-disabled'
                              : 'section-cover-button-left'
                            }`}>
                          <IconButton
                            className={`${getLanguage() !== 'ar'
                                ? 'mdi mdi-chevron-left'
                                : 'mdi mdi-chevron-right'
                              }`}
                            disabled={
                              !cardCount[index] || cardCount[index] <= 2 || !tourImage[index]
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setCardCount((items) => {
                                items[index] -= 1;
                                return [...items];
                              });
                              onCardImageClicked(item, index);
                            }}
                          />
                        </div>
                        {(item.endDate === null || item.startDate === null) && (
                          <div className='all-time-section'>
                            {getTranslate()(`${translationPath}all-time`)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='cards-footer'>
                    <div className='item-wrapper'>
                      <span className='item-header px-1'>{item.name}</span>
                    </div>
                    <div className='item-wrapper'>
                      {item.isPublish ? (
                        <span className='item-body active-tour'>
                          {getTranslate()(`${translationPath}published`)}
                        </span>
                      ) : (
                        <span className='item-body inactive-tour'>
                          {getTranslate()(`${translationPath}unpublished`)}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='cards-body'>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-calendar px-2' />
                        <span>{getTranslate()(`${translationPath}view-from`)}:</span>
                      </span>
                      <span className='item-body card-date'>
                        {!item.startDate
                          ? getTranslate()(`${translationPath}all-time`)
                          : moment(item.startDate).format('DD/MM/YYYY')}
                      </span>
                    </div>

                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-clock-time-five px-2' />
                        <span>{getTranslate()(`${translationPath}num-tours`)}:</span>
                      </span>
                      <span className='item-body num-of-tours'>{item.numberOfTours}</span>
                    </div>

                    <div className='item-actions'>
                      <div className='item-wrapper actions'>
                        <Button
                          className='btns theme-outline'
                          onClick={onActionClicked(ActionsEnum.reportView.key, item, index)}>
                          <span className='mdi mdi-eye-outline' />
                        </Button>
                        <Button
                          className='btns theme-outline'
                          onClick={onActionClicked(ActionsEnum.reportEdit.key, item, index)}>
                          <span className='mdi mdi-file-document-edit-outline' />
                        </Button>
                        <Button
                          className='btns theme-outline'
                          onClick={onActionClicked(ActionsEnum.delete.key, item, index)}>
                          <span className='mdi mdi-trash-can-outline' />
                        </Button>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </>
      )}
    </div>
  );
};
RecommendedToursCardComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onActionClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  translationPath: PropTypes.string,
};
RecommendedToursCardComponent.defaultProps = {
  translationPath: 'RecommendedToursView.',
};
