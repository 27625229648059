import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';

export const GetAllBasketTours = async (pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/BasketTours/GetAllBasketTours?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetAllUsersBasket = async (pageIndex, pageSize, body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/BasketTours/GetAllUsersBasket?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

export const DeleteBasketTour = async (basketTourId) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/PSTViewing/BasketTours/${basketTourId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetBasketTourById = async (basketTourId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/BasketTours/GetBasketTourById/${basketTourId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetAllBasketTourByUserId = async (userId, fromDate, toDate) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/BasketTours/GetAllBasketTourByUserId/${userId}?fromDate=${fromDate}&toDate=${toDate}`
    );
    return result;
  } catch (e) {
    return;
  }
};
