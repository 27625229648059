
import React, {useState} from 'react';
import {Button, Grid, Typography} from '@material-ui/core';
import {getDownloadableLink} from '../../../../Helpers';
import {AdsDeleteDialog} from '../AdsViewUtilities/AdsViewDialogs/AdsDeleteDialog';
import {getTranslate} from '../../../../Helpers';
import {LoadableImageComponant} from "../../../../Componentes";
const AdsViewSlide = ({item, reloadData, setIsEdit, setAdsInfo, setAdsDialog}) => {
    const [deleteDialog, setDeleteDialog] = useState(false);

    const translationPath = 'Advertisements.';

    return (
        <form
            className='advance-search-wrapper sliderSidePanel adsView'
            noValidate
            onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}>
            <Grid container spacing={1}>
                <Grid item xs={12} className='sliderName'>
                    <Typography>{item.advertisementName}</Typography>
                    <Button onClick={() => setDeleteDialog(true)}>
                        {getTranslate()(`${translationPath}Delete`)}
                    </Button>
                </Grid>
                <Grid item xs={12} className={item.isActive !== true ? 'inactiveSlider' : 'sliderStatus'}>
                    <Typography>{item.isActive === true ? 'Active' : 'Inactive'}</Typography>
                </Grid>
                <Grid item xs={12} className='pageContent'>
                    <span className='mdi mdi-clock'/>
                    <Typography className='contentTag'>{getTranslate()(`${translationPath}ID`)}</Typography>
                    <Typography>{item.advertisementId}</Typography>
                </Grid>
                <Grid item xs={12} className='pageContent'>
                    <span className='mdi mdi-bullhorn'/>
                    <Typography className='contentTag'>{getTranslate()(`${translationPath}Type`)}</Typography>
                    <Typography>{item.advertisementType === 0 ? 'Link' : 'Tour'}</Typography>
                </Grid>
                <Grid item xs={12} className='pageContent'>
                    <span className='mdi mdi-calendar-month'/>
                    <Typography className='contentTag'>{getTranslate()(`${translationPath}From`)}</Typography>
                    <Typography>
                        {item.advertisementFromtDate.split('T')[0] +
                        `  ,${getTranslate()(`${translationPath}To`)} ` +
                        item.advertisementToDate.split('T')[0]}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LoadableImageComponant
                        classes={'adImage'}
                        src={getDownloadableLink(item.advertisementImageFileId)}
                        alt={'Ad'}></LoadableImageComponant>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        className='btns theme-solid w-100 editBtn'
                        onClick={() => {
                            setAdsInfo(item);
                            setIsEdit(true);
                            setAdsDialog(true);
                        }}>
                        {getTranslate()(`${translationPath}Edit`)}
                    </Button>
                </Grid>
            </Grid>
            <AdsDeleteDialog
                open={deleteDialog}
                setOpen={setDeleteDialog}
                item={item}
                reloadData={reloadData}
            />
        </form>
    );
};
export {AdsViewSlide};
