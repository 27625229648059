import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { ActionsButtonsEnum } from '../../Enums';
import { SelectComponet } from './SelectComponet';
import { getTranslate } from '../../Helpers';

const translationPath = 'actions-buttons.';
const ActionsButtonsComponent = ({
  onActionButtonChanged,
  onActionsButtonClicked,
  onFormTypeSelectChanged,
  withType,
  typeData,
}) => {
  const [activeAction, setActiveAction] = useState(() => ActionsButtonsEnum[1]);
  const onSelectChanged = useCallback(
    (selectedValue) => {
      setActiveAction(selectedValue);
      if (onActionButtonChanged) onActionButtonChanged(selectedValue.id);
    },
    [onActionButtonChanged, setActiveAction]
  );
  return (
    <div className="actions-buttons-wrapper">
      <div className="d-inline-flex">
        {(!withType || activeAction !== ActionsButtonsEnum[1]) && (
          <Button
            type="button"
            onClick={() => onActionsButtonClicked(activeAction.id)}
            className={`btns theme-solid ${activeAction.classes}`}
          >
            <span>{getTranslate()(translationPath + activeAction.buttonLabel)}</span>
          </Button>
        )}
        {withType && activeAction === ActionsButtonsEnum[1] && (
          <SelectComponet
            data={typeData}
            initialSelectValue={-1}
            emptyItem={{ value: -1, text: 'add', isHiddenOnOpen: true }}
            value="id"
            translationPath={translationPath}
            onSelectChanged={onFormTypeSelectChanged}
            classes="theme-action-buttons bg-secondary c-white mx-2"
            idValue="contactsActionsRef"
            keyValue="actionsbuttons"
            keyLoopBy="id"
            translationPathForData={translationPath}
            input="name"
          />
        )}
      </div>
      <div className="d-inline-flex">
        <SelectComponet
          data={Object.values(ActionsButtonsEnum)}
          initialSelectValue={ActionsButtonsEnum[1]}
          onSelectChanged={onSelectChanged}
          classes="theme-action-buttons"
          idValue="contactsActionsRef"
          keyValue="actionsbuttons"
          keyLoopBy="id"
          translationPathForData={translationPath}
          input="label"
        />
      </div>
    </div>
  );
};
ActionsButtonsComponent.propTypes = {
  onActionButtonChanged: PropTypes.func,
  onFormTypeSelectChanged: PropTypes.func,
  onActionsButtonClicked: PropTypes.func.isRequired,
  withType: PropTypes.bool,
  typeData: PropTypes.instanceOf(Array),
};

ActionsButtonsComponent.defaultProps = {
  onActionButtonChanged: null,
  onFormTypeSelectChanged: undefined,
  withType: false,
  typeData: [],
};

export { ActionsButtonsComponent };
