import React, { useState, useEffect, useCallback } from 'react';
import './TotalSalesComponent.scss';
import { CanvasJSChart } from 'canvasjs-react-charts';
import { getTranslate } from ' ./../../src/Helpers';
import { GetTotalSalesStatistics } from './../../../../../Serviecs';
import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';
import { TotalToursEnum } from '../../../../../Enums';
import { SelectComponet } from '../../../../../Componentes';

export const TotalSalesComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const translationPath = 'TotalSalesFunnel.';
  const [dropDownValue, setDropDownValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [options, setOptions] = useState({
    data: [
      {
        type: 'funnel',
        colorSet: 'greenShades',
        dataPoints: [
          { label: 'In SaveLater', y: 1, color: '#F15740' },
          { label: 'In Basket', y: 1, color: '#FFDB69' },
          { label: 'Sold But Not Executed', y: 1, color: '#147AD6' },
          { label: 'Sold And Executed', y: 1, color: '#56A591' },
        ],
      },
    ],
  });

  const GetSalesStatistics = useCallback(async () => {
    setIsLoading(true);
    const Status = await GetTotalSalesStatistics(dropDownValue);
    if (Status) {
      setOptions((items) => {
        Object.keys(Status).map(
          (el, i) => (items.data[0].dataPoints[i] = { ...items.data[0].dataPoints[i], label: el })
        );
        Object.values(Status).map(
          (el, i) =>
            (items.data[0].dataPoints[i] = { ...items.data[0].dataPoints[i], y: parseInt(el) })
        );
        return items;
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownValue]);

  useEffect(() => { 
    GetSalesStatistics();
  }, [GetSalesStatistics]);


  const openHandler = () => {
    setIsOpen(true);
  };
  const closeHandler = () => {
    setIsOpen(false);
  };


  return (
    <div className='SalesFunnel'>
    <Spinner isActive={isLoading} isAbsolute />
      <div className='SalesTitle'>
        <p className='SalesPargraph'>{getTranslate()(`${translationPath}Total Sales`)}</p>
        <div className='Upcoming-select'>
        <SelectComponet
        isOpen={isOpen}
        onOpen={openHandler}
        onClose={closeHandler}
        initialSelectValue={0}
        data={TotalToursEnum}
        value={dropDownValue}
        textInput='value'
        valueInput='key'
        translationPathForData={translationPath}
        onSelectChanged={(event) => {
          setDropDownValue(event.target.value);
        }}
      />  
      </div>
        </div>
      <div className='funnel-Chart'>
        <CanvasJSChart options={options} />
      </div>
    </div>
  );
};
