import { RolesView, AddRoleView, RolesListView } from '../../Views/Home';

export const RolesRoutes = [
  {
    id: 13,
    path: '/View',
    name: 'SideMenuView.Administration.Roles',
    component: RolesListView,
    layout: '/home/Roles',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Roles',
        isDisabled: false,
        route: '/home/Roles/View',
      },
    ],
  },
  {
    id: 12,
    path: '/Details',
    name: 'SideMenuView.Administration.Roles',
    component: RolesView,
    layout: '/home/Roles',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Roles',
        isDisabled: false,
        route: '/home/Roles/View',
      },
      {
        name: 'Administration.Roles.Roles.RolesDetails',
        isDisabled: false,
        route: '/home/Roles/Details',
      },
    ],
  },
  {
    id: 10,
    path: '/AddRole',
    name: 'Administration.Roles.AddRole',
    component: AddRoleView,
    layout: '/home/Roles',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Roles',
        isDisabled: false,
        route: '/home/Roles/View',
      },
      {
        name: 'Administration.Roles.AddRole.AddRole',
        isDisabled: false,
        route: '/home/Roles/AddRole',
      },
    ],
  },
  {
    id: 11,
    path: '/EditRole',
    name: 'SideMenuView.Administration.EditRole',
    component: AddRoleView,
    layout: '/home/Roles',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Roles',
        isDisabled: false,
        route: '/home/Roles/View',
      },
      {
        name: 'Administration.Roles.AddRole.UpdateRole',
        isDisabled: false,
        route: '/home/Roles/UpdateRole',
      },
    ],
  },
];
