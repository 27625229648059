import React, { useState,useCallback,useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import './LocationsAndSoldTours.scss';
import { GetTripStatistics } from '../../../../../Serviecs';
import {Spinner} from '../../../../../Componentes';
import { getTranslate } from ' ./../../src/Helpers';

 export const Locations=()=> {
  const translationPath = 'Locations.';
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const [isLoading, setIsLoading] = useState(true);
  const [doughnutValue, setDoughnutValue] = useState(true);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#147AD6',
          '#FFDB69',
          '#FA9266',
          '#F15740',
          '#56A591',
          '#79D2DE',
          '#580DBF'
        ],
      },
    ],
  });

  const GetTotalTrip = useCallback(async () => {
    setIsLoading(true);
    const Status = await GetTripStatistics();
    if (Status) {
      setChartData((items) => {
        items.labels=Status.tripLocationCountList.map((item)=>item.formsName)
        items.datasets[0].data=Status.tripLocationCountList.map((item)=>item.locationCount)
        items.datasets[0].data.reduce(reducer) ===0? setDoughnutValue(false):setDoughnutValue(true);

        return {...items}
      });
      setIsLoading(false);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetTotalTrip();
  }, [GetTotalTrip]);

  return (
    <div className='Locations'>
    <Spinner isActive={isLoading} isAbsolute />
      <div className='LocationsTitle'>
        <p className='LocationsPargraph'>{getTranslate()(`${translationPath}Total-locations`)} </p>
      </div>

      <Grid container spacing={2}>
        <Grid xs={12} className='chartDiv'>
        {doughnutValue ? 
          
          <Doughnut
            data={chartData}
            options={{
              legend: {
                display: false,
              },

              responsive: true,
            }}
          />  : <div className='NoData Location'> </div>}
        </Grid>
      </Grid>
      <div className='lables-wrapper'>
        {chartData.datasets &&
          chartData.labels.map((label, index) => (
            <div key={index} className='chart-item'>
              <span
                className='mdi mdi-circle-medium column'
                style={{
                  color: `${chartData.datasets[0].backgroundColor[index]}`,
                }}
              />
              <span>{label}</span>
            
            </div>
          ))}
      </div>
    </div>
  );
}
