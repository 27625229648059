/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CheckBoxRole } from '../CheckBoxRole/CheckBoxRole';
import { Spinner } from '../../../../../../Componentes';
import {
  GetAllApplicationService,
  GetAllComponentsByAppServiceId,
  GetAllPermissionsByComponentsId,
  RemovePermissionsFromRole,
  EditRoleName,
  GetAllPermissionsByRoleId,
  AssignPermissionsToRole,
  PostRole,
} from '../../../../../../Serviecs/Roleservices/roleServices';
import { getTranslate, showSuccess, GetParams } from '../../../../../../Helpers';
import './AddRole.scss';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const AddRoleView = (props) => {
  const [editRole, setEditRole] = React.useState(false);
  const [roleName, setRoleName] = React.useState('');
  const [roleId, setRoleId] = React.useState('');
  const [perName, setPerName] = React.useState([]);
  const [state, setState] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [appService, setAppService] = React.useState({});
  const [components, setComponents] = React.useState({});
  const [permissionsResponse, setPermissionsResponse] = React.useState({});
  const [rolePermissions, setRolePermissions] = React.useState({});

  useEffect(() => {
    if (GetParams('id')) {
      setEditRole(true);
      setRoleId(GetParams('id').split('?')[0]);
      setRoleName(GetParams('id').split('?')[1].split('name=')[1]);
    }
  }, []);

  const GetMyPermissions = async (comId, pageIndex, PageSize) => {
    setLoading(true);
    setPermissionsResponse(await GetAllPermissionsByComponentsId(comId, pageIndex, PageSize));
    setLoading(false);
  };

  const GetRolePermissions = async (roleId, pageIndex, PageSize) => {
    setRolePermissions(await GetAllPermissionsByRoleId(roleId, pageIndex, PageSize));
  };

  const GetComponents = async (appId, pageIndex, PageSize) => {
    const res = await GetAllComponentsByAppServiceId(appId, pageIndex, PageSize);
    setComponents(res);
    if (res.result && res.result[0]) GetMyPermissions(res.result[0].componentsId, 1, 100);
  };

  const GetAppService = async (pageIndex, PageSize) => {
    setLoading(true);
    setAppService(await GetAllApplicationService(pageIndex, PageSize));
    setLoading(false);
  };

  useEffect(() => {
    GetAppService(1, 10);
  }, [setAppService]);

  const handleEditRole = async () => {
    await EditRoleName(roleId, {
      rolesName: roleName,
    });

    const compare = [];
    const removed = [];
    if (stateArr[0]) {
      stateArr.map((s) => {
        if (rolePermissions && rolePermissions.result) {
          rolePermissions.result.map((role) => {
            if (s.permissionsId === role.permissions.permissionsId)
              compare.push(role.rolePermissionsId);
          });
        }
      });
    }
    removeArr.map((r) => {
      removed.push(r.id);
    });
    const removedFromRole = removed.concat(compare);
    await RemovePermissionsFromRole(removedFromRole);

    let addedToRole = [];
    stateArr.map((a) => {
      addedToRole.push({
        permissionsId: a.permissionsId,
        rolesId: roleId,
        accessTypesId: a.selected,
      });
    });
    if (addedToRole.length !== 0) await AssignPermissionsToRole(addedToRole);

    showSuccess(getTranslate()('Administration.Roles.AddRole.NotificationUpdateRole'));
    addedToRole = [];
  };
  const handleRoleSave = async () => {
    const roleRes = await PostRole({
      rolesName: roleName,
    });

    let checkedList = [];
    stateArr.map((el) => {
      checkedList.push({
        permissionsId: el.permissionsId,
        rolesId: roleRes.rolesId,
        accessTypesId: el.selected,
      });
    });

    if (checkedList.length !== 0) await AssignPermissionsToRole(checkedList);

    setRoleName('');
    setExpanded(false);
    showSuccess(getTranslate()('Administration.Roles.AddRole.NotificationAddRole'));
    checkedList = [];
  };

  let stateArr = [];
  let removeArr = [];
  const selectArr = [];

  state.map((x, i) => {
    if (!state[i][perName[i]].checked) {
      if (rolePermissions && rolePermissions.result) {
        rolePermissions.result.map((role) => {
          if (role.permissions.permissionsId === state[i][perName[i]].id) {
            removeArr.push({
              id: role.rolePermissionsId,
              permissionsId: state[i][perName[i]].id,
            });
          }
        });
      }
    }
    if (state[i][perName[i]].selected < 5) {
      if (rolePermissions && rolePermissions.result) {
        rolePermissions.result.map((role) => {
          if (role.permissions.permissionsId === state[i][perName[i]].id) {
            selectArr.push({
              id: role.rolePermissionsId,
              permissionsId: state[i][perName[i]].id,
            });
          }
        });
      }
    }
    if (state[i][perName[i]].checked) {
      stateArr.push({
        permissionsId: state[i][perName[i]].id,
        selected: state[i][perName[i]].selected,
      });
    }
  });

  const permissions = (
    <Grid container>
      {permissionsResponse &&
        permissionsResponse.result &&
        permissionsResponse.result.map((per, i) => {
          const index = state.findIndex((f) => f[per.permissionsName]);

          return (
            <Grid item xs={3} key={i}>
              <CheckBoxRole
                select={selectArr}
                remove={removeArr}
                key={i}
                item={per}
                role={rolePermissions}
                check={
                  state[index] && state[index][per.permissionsName]
                    ? state[index][per.permissionsName].checked
                    : false
                }
                selected={
                  state[index] && state[index][per.permissionsName]
                    ? state[index][per.permissionsName].selected
                    : 1
                }
                onChange={(e) => {
                  try {
                    const index = state.findIndex(
                      (f) => f[per.permissionsName] && f[per.permissionsName].name === e.name
                    );
                    if (state[index] && state[index][per.permissionsName])
                      state[index][per.permissionsName] = { ...e };
                    else {
                      setPerName([...perName, per.permissionsName]);
                      setState([
                        ...state,
                        {
                          [per.permissionsName]: {
                            checked: e.checked,
                            selected: e.selected,
                            name: e.name,
                            id: e.id,
                          },
                        },
                      ]);
                    }
                  } catch (error) {
                    return;
                  }
                }}
              />
            </Grid>
          );
        })}
    </Grid>
  );

  const tabs = (
    <div className='roleTabs'>
      {components && components.result && components.result[0] ? (
        <>
          <Spinner isActive={loading} />
          <AppBar position='static' color='default'>
            <Tabs
              variant='scrollable'
              scrollButtons='auto'
              value={value}
              onChange={(e, v) => setValue(v)}
              indicatorColor='primary'>
              {components &&
                components.result &&
                components.result.map((tab, i) => (
                  <Tab
                    key={i}
                    label={tab.componentsName}
                    {...a11yProps(i)}
                    onClick={() => {
                      setPermissionsResponse(null);
                      GetMyPermissions(tab.componentsId, 1, 10);
                    }}
                  />
                ))}
            </Tabs>
          </AppBar>
          <Paper>
            <TabPanel value={value} index={value}>
              {permissions}
            </TabPanel>
          </Paper>
        </>
      ) : (
        getTranslate()('Administration.Roles.Roles.NoData')
      )}
    </div>
  );

  const collapsePanels = (
    <>
      {appService &&
        appService.result &&
        appService.result.map((panel, i) => (
          <ExpansionPanel
            key={i}
            expanded={expanded === panel.applicationServicesId}
            onChange={(e, isExpanded) => {
              setExpanded(isExpanded ? panel.applicationServicesId : false);
            }}>
            <ExpansionPanelSummary
              key={i}
              onClick={() => {
                if (editRole) GetRolePermissions(roleId, 1, 100);

                GetComponents(panel.applicationServicesId, 1, 100);
                setValue(0);
                setPermissionsResponse(null);
              }}
              expandIcon={<ExpandMoreIcon />}>
              <Typography key={i}>{panel.applicationServicesName}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails key={i}>{tabs}</ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
    </>
  );

  const colorCheckbox = (
    <div className='colorCheckBoxes'>
      <FormControlLabel
        className='form-control-label globalCheckBox'
        control={
          <Checkbox
            className='checkbox-wrapper'
            checkedIcon={<span className='mdi mdi-check' />}
            indeterminateIcon={<span className='mdi mdi-minus' />}
            checked
          />
        }
        label={getTranslate()('Administration.Roles.AddRole.Global')}
      />
      <FormControlLabel
        className='form-control-label localCheckBox'
        control={
          <Checkbox
            className='checkbox-wrapper'
            checkedIcon={<span className='mdi mdi-check' />}
            indeterminateIcon={<span className='mdi mdi-minus' />}
            checked
          />
        }
        label={getTranslate()('Administration.Roles.AddRole.Local')}
      />
      <FormControlLabel
        className='form-control-label deepCheckBox'
        control={
          <Checkbox
            className='checkbox-wrapper'
            checkedIcon={<span className='mdi mdi-check' />}
            indeterminateIcon={<span className='mdi mdi-minus' />}
            checked
          />
        }
        label={getTranslate()('Administration.Roles.AddRole.Deep')}
      />
      <FormControlLabel
        className='form-control-label basicCheckBox'
        control={
          <Checkbox
            className='checkbox-wrapper'
            checkedIcon={<span className='mdi mdi-check' />}
            indeterminateIcon={<span className='mdi mdi-minus' />}
            checked
          />
        }
        label={getTranslate()('Administration.Roles.AddRole.Basic')}
      />
    </div>
  );

  return (
    <div className='AddRole'>
      <div className='paperWraper'>
        <Grid container spacing={2} justify='center' alignItems='center'>
          <Grid item container spacing={2} lg={8} sm={12} xl={8} xs={12}>
            <Grid item container>
              <Grid item xs={6}>
                <TextField
                  className='inputs theme-solid'
                  size='small'
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                  label={getTranslate()('Administration.Roles.AddRole.Role')}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={6}>
                {colorCheckbox}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {collapsePanels}
            </Grid>
          </Grid>
          <Grid item lg={8} sm={12} xl={8} xs={12}>
            {editRole ? (
              <Button className='btns theme-solid' onClick={handleEditRole}>
                {getTranslate()('Administration.Roles.AddRole.UpdateRole')}
              </Button>
            ) : (
              <Button className='btns theme-solid' onClick={handleRoleSave}>
                {getTranslate()('Administration.Roles.AddRole.AddRole')}
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export { AddRoleView };
