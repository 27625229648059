import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, TextField, CircularProgress, Button } from '@material-ui/core';
import { getTranslate } from '../../Helpers';

export const Inputs = ({
  value,
  isRequired,
  isDisabled,
  idRef,
  onInputChanged,
  fieldClasses,
  wrapperClasses,
  labelClasses,
  translationPath,
  labelValue,
  error,
  helperText,
  withLoader,
  autoCompleteParams,
  isLoading,
  variant,
  label,
  inputPlaceholder,
  rows,
  multiline,
  type,
  onInputBlur,
  onKeyUp,
  buttonOptions,
  max,
  maxLength,
  min,
  minLength,
  step,
  endAdornment,
  startAdornment,
  beforeIconClasses,
  afterIconClasses,
  multiple,
  refs,
  isWithError,
  isSubmitted,
  overInputText,
  overInputTextIcon,
}) => {
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  return (
    <FormControl className={wrapperClasses}>
      {labelValue && (
        <label htmlFor={idRef} className={`${labelClasses}${isDisabled ? ' disabled' : ''}`}>
          {getTranslate()(`${translationPath}${labelValue}`)}
        </label>
      )}
      <div className="d-flex-center w-100">
        {beforeIconClasses && <span className={`mx-2 ${beforeIconClasses}`} />}
        <div className="p-relative d-flex w-100">
          <TextField
            // {...renderParams}
            {...autoCompleteParams}
            ref={refs}
            required={isRequired}
            disabled={isDisabled}
            className={fieldClasses}
            id={idRef}
            label={label && getTranslate()(`${translationPath}${label}`)}
            placeholder={
              inputPlaceholder && getTranslate()(`${translationPath}${inputPlaceholder}`)
            }
            variant={variant}
            helperText={
              helperText &&
              ((isWithError && (isBlurOrChanged || isSubmitted) && error && helperText) ||
                (!isWithError && getTranslate()(`${translationPath}${helperText}`)))
            }
            value={value}
            error={
              (isWithError && (isBlurOrChanged || isSubmitted) && error) ||
              (!isWithError && !isBlurOrChanged && error)
            }
            rows={rows}
            onKeyUp={onKeyUp}
            type={type}
            multiline={multiline}
            onChange={
              ((onInputChanged || isWithError) &&
                ((event) => {
                  if (!isBlurOrChanged) setIsBlurOrChanged(true);
                  if (onInputChanged) onInputChanged(event);
                })) ||
              undefined
            }
            onBlur={(event) => {
              setIsBlurOrChanged(true);
              if (onInputBlur) onInputBlur(event);
            }}
            inputProps={{
              max,
              maxLength,
              min,
              minLength,
              step,
              multiple,
              ...autoCompleteParams.inputProps,
            }}
            InputProps={{
              endAdornment:
                (withLoader && !endAdornment && (
                  <>{isLoading ? <CircularProgress color="inherit" size={20} /> : null}</>
                )) ||
                endAdornment,
                startAdornment:
                  (!startAdornment && (overInputText || overInputTextIcon) && (
                    <span className="px-1">
                      {overInputTextIcon && <span className={overInputTextIcon} />}
                      {overInputText && getTranslate()(`${translationPath}${overInputText}`)}
                    </span>
                  )) ||
                  startAdornment,
                ...autoCompleteParams.InputProps,

            }}
          />
          {afterIconClasses && <span className={`mx-2 ${afterIconClasses}`} />}
        </div>
        {buttonOptions && (
          <Button
            className={`ml-2 ${buttonOptions.className}`}
            onClick={buttonOptions.onActionClicked}
            disabled={buttonOptions.isDisabled}
            required={buttonOptions.isRequired}
          >
            <span className={buttonOptions.iconClasses} />
          </Button>
        )}
      </div>
    </FormControl>
  );
};

Inputs.propTypes = {
  value: PropTypes.oneOfType([PropTypes.any]),
  autoCompleteParams: PropTypes.instanceOf(Object),
  startAdornment: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
  endAdornment: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
  onInputChanged: PropTypes.func,
  onInputBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  idRef: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  withLoader: PropTypes.bool,
  multiline: PropTypes.bool,
  fieldClasses: PropTypes.string,
  wrapperClasses: PropTypes.string,
  beforeIconClasses: PropTypes.string,
  afterIconClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  translationPath: PropTypes.string,
  labelValue: PropTypes.string,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  type: PropTypes.string,
  max: PropTypes.string,
  maxLength: PropTypes.string,
  min: PropTypes.string,
  minLength: PropTypes.string,
  step: PropTypes.string,
  rows: PropTypes.number,
  isWithError: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  multiple: PropTypes.bool,
  overInputText: PropTypes.string,
  overInputTextIcon: PropTypes.string,
  refs: PropTypes.instanceOf(Object),
  buttonOptions: PropTypes.shape({
    className: PropTypes.string,
    iconClasses: PropTypes.string,
    onActionClicked: PropTypes.func,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
};
Inputs.defaultProps = {
  autoCompleteParams: {},
  onInputChanged: undefined,
  onInputBlur: undefined,
  onKeyUp: undefined,
  value: undefined,
  isRequired: false,
  isDisabled: false,
  error: false,
  multiline: false,
  fieldClasses: 'inputs theme-solid-border', // inputs theme-underline
  labelClasses: 'texts-form',
  wrapperClasses: 'input-wrapper flex-wrap',
  beforeIconClasses: undefined,
  afterIconClasses: undefined,
  translationPath: '',
  variant: 'standard',
  max: undefined,
  maxLength: undefined,
  min: undefined,
  minLength: undefined,
  step: undefined,
  labelValue: null,
  label: null,
  inputPlaceholder: null,
  helperText: '',
  withLoader: false,
  isLoading: false,
  isWithError: false,
  isSubmitted: false,
  type: 'text',
  rows: 1,
  refs: undefined,
  startAdornment: undefined,
  endAdornment: undefined,
  buttonOptions: null,
  overInputTextIcon: undefined,
  overInputText: undefined,
};
