import React, { useState, useCallback, useEffect, useRef } from 'react';
import { GetAllSections, UpdateSectionsOrder } from '../../../../../Serviecs';
import { getHistory, getTranslate, showSuccess } from '../../../../../Helpers';
import { SectionReorderCards } from './SectionsReorderCards';
import { useTitle } from '../../../../../Hooks/useTitle';
import { Spinner } from '../../../../../Componentes';
import { Button } from '@material-ui/core';
import './SectionViewReOrder.scss';
const translationPath = 'SectionView.';

export const SectionViewReOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [unpublishedSections, setUnpublishedSections] = useState({ result: [], totalCount: 0 });
  const [onDragItem, setOnDragItem] = useState(null);
  const [currentDragOverItem, setCurrentDragOverItem] = useState(null);
  const [reOrderedMobile, setReOrderedMobile] = useState([]);
  const [reOrderedWebsite, setReOrderedWebsite] = useState([]);
  const [droppedTrips, setDroppedTrips] = useState({
    mobile: { result: [], totalCount: 0 },
    website: { result: [], totalCount: 0 },
  });
  const draggingElement = useRef(null);
  const dragOverTouchItem = useRef(null);
  useTitle(getTranslate()(`${translationPath}sections-re-order`));

  const dragOverHandler = (event) => {
    event.preventDefault();
  };
  const fakeElementGenerator = (event) => {
    if (event.type === 'touchstart') {
      draggingElement.current = document.createElement('div');
      draggingElement.current.classList.add('drag-card-wrapper');
      draggingElement.current.classList.add('p-absolute');
      draggingElement.current.classList.add('fake-dragging-item');
      draggingElement.current.setAttribute(
        'style',
        `top:${event.targetTouches[0].clientY + document.documentElement.scrollTop}px;left:${
          event.targetTouches[0].clientX
        }px`
      );
      draggingElement.current.appendChild(event.target.parentElement.cloneNode(true));
      document.body.appendChild(draggingElement.current);
    }
  };
  const dragHandler = (item, index, event) => {
    if (!onDragItem || onDragItem.index !== index) setOnDragItem({ item, index, from: 'mobile' });
    fakeElementGenerator(event);
  };
  const dragTourTripHandler = (item, index, event) => {
    if (!onDragItem || onDragItem.index !== index) setOnDragItem({ item, index, from: 'website' });
    fakeElementGenerator(event);
  };
  const dragOverTourHandler = useCallback(
    (index, from) => (event) => {
      event.preventDefault();
      const center =
        event.target.getBoundingClientRect().bottom +
        window.scrollY -
        event.target.offsetHeight / 2;
      const isBefore =
        (true) ||
        (event.pageY <= center &&
          currentDragOverItem &&
          currentDragOverItem.index - 1 !== onDragItem.index);
      if (!currentDragOverItem || index !== currentDragOverItem.index)
        setCurrentDragOverItem({ index, dragOver: from, isBefore });
    },
    [currentDragOverItem, onDragItem]
  );
  const onTouchMoveHandler = useCallback(
    () => (event) => {
      console.log('draggingElement: ', draggingElement);
      draggingElement.current.setAttribute(
        'style',
        `top:${event.targetTouches[0].clientY + document.documentElement.scrollTop}px;left:${
          event.targetTouches[0].clientX
        }px`
      );
      dragOverTouchItem.current = document.elementFromPoint(
        event.targetTouches[0].clientX,
        event.targetTouches[0].clientY
      );
      if (!dragOverTouchItem.current || !dragOverTouchItem.current.classList) return;
      if (dragOverTouchItem.current.classList.contains('drag-card-wrapper')) {
        if (currentDragOverItem) setCurrentDragOverItem(null);
        return;
      }
      const dragOverFrom = dragOverTouchItem.current.getAttribute('data-from');
      const index = droppedTrips.mobile.result.findIndex(
        (item) => item.applicationSectionId === +dragOverTouchItem.current.getAttribute('data-id')
      );
      if (dragOverFrom === 'mobile' && index !== -1) {
        if (!currentDragOverItem || currentDragOverItem.index !== index)
          setCurrentDragOverItem({
            index,
            dragOver: dragOverTouchItem.current.getAttribute('data-from'),
            isBefore: true,
          });
      }
    },
    [currentDragOverItem, droppedTrips]
  );
  const tripTouchEndHandler = () => {
    draggingElement.current.remove();
    if (
      dragOverTouchItem.current &&
      dragOverTouchItem.current.classList.contains('drag-card-wrapper')
    )
      dropHandler();
    else if (currentDragOverItem) dropHandler();
  };

  const dropHandler = () => {
    if (
      currentDragOverItem &&
      ((currentDragOverItem.index === onDragItem.index &&
        currentDragOverItem.dragOver === onDragItem.from) ||
        currentDragOverItem.dragOver !== onDragItem.from)
    ) {
      setCurrentDragOverItem(null);
      setOnDragItem(null);
      return;
    }
    const localDroppedSections = {
      mobile: [...droppedTrips.mobile.result],
      website: [...droppedTrips.website.result],
    };

    if (currentDragOverItem !== null) {
      if (currentDragOverItem.dragOver === 'mobile')
        if (currentDragOverItem.dragOver === onDragItem.from)
          localDroppedSections.mobile.splice(
            currentDragOverItem.isBefore
              ? currentDragOverItem.index
              : currentDragOverItem.index + 1,
            0,
            localDroppedSections.mobile.splice(onDragItem.index, 1)[0]
          );
        else
          localDroppedSections.mobile.splice(
            currentDragOverItem.isBefore
              ? currentDragOverItem.index
              : currentDragOverItem.index + 1,
            0,
            onDragItem.item
          );
    } else {
      localDroppedSections.mobile.push(onDragItem.item);
    }
    setReOrderedMobile((items) => {
      localDroppedSections.mobile.map((item, i) => {
        const fromIndex = items.findIndex(
          (el) => el.applicationSectionId === item.applicationSectionId
        );
        items[fromIndex].orderMobile = i;
        return item;
      });

      return [...items];
    });

    if (currentDragOverItem !== null) {
      if (currentDragOverItem.dragOver === 'website')
        if (currentDragOverItem.dragOver === onDragItem.from)
          localDroppedSections.website.splice(
            currentDragOverItem.isBefore
              ? currentDragOverItem.index
              : currentDragOverItem.index + 1,
            0,
            localDroppedSections.website.splice(onDragItem.index, 1)[0]
          );
        else
          localDroppedSections.website.splice(
            currentDragOverItem.isBefore
              ? currentDragOverItem.index
              : currentDragOverItem.index + 1,
            0,
            onDragItem.item
          );
    } else localDroppedSections.website.push(onDragItem.item);

    setReOrderedWebsite((items) => {
      localDroppedSections.website.map((item, i) => {
        const fromIndex = items.findIndex(
          (el) => el.applicationSectionId === item.applicationSectionId
        );
        items[fromIndex].orderWeb = i;
        return item;
      });

      return [...items];
    });

    setCurrentDragOverItem(null);
    setOnDragItem(null);
    setDroppedTrips({
      mobile: {
        result: localDroppedSections.mobile,
        totalCount: localDroppedSections.mobile.length,
      },
      website: {
        result: localDroppedSections.website,
        totalCount: localDroppedSections.website.length,
      },
    });
  };

  const getSections = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllSections(1, 30 ,{});
    if (res && res.result) {
      setUnpublishedSections({
        result: res.result.filter((item) => !item.isPublish),
        totalCount: res.result.filter((item) => !item.isPublish).length,
      });

      const orderMobile = res.result.filter(
        (item) => (item.publishType === 2 || item.publishType === 3) && item.isPublish === true
      );
      const orderWebsite = res.result.filter(
        (item) => (item.publishType === 1 || item.publishType === 3) && item.isPublish === true
      );

      setReOrderedMobile(
        orderMobile.map((item) => ({
          applicationSectionId: item.applicationSectionId,
          orderMobile: item.orderMobile,
        }))
      );

      setReOrderedWebsite(
        orderWebsite.map((item) => ({
          applicationSectionId: item.applicationSectionId,
          orderWeb: item.orderWeb,
        }))
      );

      setDroppedTrips({
        mobile: {
          result: orderMobile.sort((a, b) => (a.orderMobile > b.orderMobile ? 1 : -1)),
          totalCount: orderMobile.sort((a, b) => (a.orderMobile > b.orderMobile ? 1 : -1)).length,
        },
        website: {
          result: orderWebsite.sort((a, b) => (a.orderWeb > b.orderWeb ? 1 : -1)),
          totalCount: orderWebsite.sort((a, b) => (a.orderWeb > b.orderWeb ? 1 : -1)).length,
        },
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getSections();
  }, [getSections]);
  const handelCancel = () => {
    getHistory().push('/home/sections');
  };
  const saveHandler = async () => {
    const orderedWebsite = reOrderedWebsite.sort((a, b) => (a.orderWeb > b.orderWeb ? 1 : -1));
    const orderedMobile = reOrderedMobile.sort((a, b) => (a.orderMobile > b.orderMobile ? 1 : -1));
    const finalSort = orderedWebsite.concat(orderedMobile);

    const result = await UpdateSectionsOrder(finalSort);
    if (result) {
      getHistory().push('/home/sections');
      showSuccess(getTranslate()(`${translationPath}section-reordered-successfully`));
    }
  };

  return (
    <div className='view-wrapper re-order-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='header-section px-3'>
        <div className='filter-section header-filter'>
          <div className='section'>
            <Button className='btns theme-solid' onClick={saveHandler}>
              {getTranslate()(`${translationPath}save`)}
            </Button>
            <Button className='btns theme-solid bg-cancel' onClick={handelCancel}>
              {getTranslate()(`${translationPath}cancel`)}
            </Button>
          </div>
        </div>
      </div>
      <div className='re-order-content px-2 mx-3'>
        <div className='re-order-mobile'>
          <div className='re-order-mobile-title'>
            <span className='mdi mdi-cellphone-iphone px-2' />
            {getTranslate()(`${translationPath}mobile`)}
          </div>
          <div
            className='re-order-mobile-content tour-trips-timeline-wrapper'
            onDrop={dropHandler}
            onDragOver={dragOverHandler}>
            <SectionReorderCards
              data={droppedTrips.mobile}
              translationPath={translationPath}
              draggable
              dragFrom='mobile'
              onDrag={dragHandler}
              touchEnd={tripTouchEndHandler}
              onTouchMove={onTouchMoveHandler}
              onDragOver={dragOverTourHandler}
              currentDragOverItem={currentDragOverItem}
              onDragItem={onDragItem}
            />
          </div>
        </div>

        <div className='re-order-website'>
          <div className='re-order-website-title'>
            <span className='mdi mdi-monitor px-2' />
            {getTranslate()(`${translationPath}website`)}
          </div>
          <div
            className='re-order-website-content tour-trips-timeline-wrapper'
            onDrop={dropHandler}
            onDragOver={dragOverHandler}>
            <SectionReorderCards
              data={droppedTrips.website}
              translationPath={translationPath}
              draggable
              dragFrom='website'
              onDrag={dragTourTripHandler}
              touchEnd={tripTouchEndHandler}
              onTouchMove={onTouchMoveHandler}
              onDragOver={dragOverTourHandler}
              currentDragOverItem={currentDragOverItem}
              onDragItem={onDragItem}
            />
          </div>
        </div>

        <div className='re-order-unpublish'>
          <div className='re-order-unpublish-title'>
            {getTranslate()(`${translationPath}unpublish`)}
          </div>
          <div className='re-order-unpublish-content tour-trips-timeline-wrapper'>
            <SectionReorderCards
              data={unpublishedSections}
              translationPath={translationPath}
              section='unpublish'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
