import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DateRangePickerComponent,
  DialogComponent,
  Inputs,
  NoContentComponent,
  NoDataFoundComponent,
  SelectComponet,
  Spinner,
} from '../../../Componentes';
import { OfferedToursStatusEnum, TableActions } from '../../../Enums';
import { getHistory, getTranslate, showError, showSuccess } from '../../../Helpers';
import { useTitle } from '../../../Hooks/useTitle';
import { ButtonBase } from '@material-ui/core';
import { OfferedToursListComponent } from './OfferedToursUtilities';
import { DeleteOffer, GetAllOffers, InActiveOffer, ActiveOffer } from '../../../Serviecs';
const translationPath = 'home.tours.offeredToursView.';
export const OfferedToursView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const filterDefault = {
    pageSize: 10,
    pageIndex: 0,
  };
  const filterByDefault = {
    search: null,
    offerStatus: 0,
    dateRange: {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  };
  const [filter, setFilter] = useState(filterDefault);
  const [filterBy, setFilterBy] = useState(filterByDefault);
  const [offers, setOffers] = useState({ result: [], totalCount: 0 });
  const searchTimer = useRef(null);
  useTitle(getTranslate()(`${translationPath}offered-tours`));
  const inActiveOffer = async (offerId) => {
    setIsLoading(true);
    const result = await InActiveOffer(offerId);
    if (result !== undefined) {
      showSuccess(getTranslate()(`${translationPath}offer-deactivated-successfully`));
      setFilter((items) => ({ ...items, pageIndex: 0 }));
    } else showError(getTranslate()(`${translationPath}offer-deactivate-failed`));
    setIsOpenConfirm(false);
    setIsLoading(false);
  };
  const activeOffer = async (offerId) => {
    setIsLoading(true);
    const result = await ActiveOffer(offerId);
    if (result !== undefined) {
      showSuccess(getTranslate()(`${translationPath}offer-activated-successfully`));
      setFilter((items) => ({ ...items, pageIndex: 0 }));
    } else showError(getTranslate()(`${translationPath}offer-activate-failed`));
    setIsOpenConfirm(false);
    setIsLoading(false);
  };
  const onActionClicked = useCallback((actionEnum, item, index, event) => {
    event.stopPropagation();
    if (actionEnum === TableActions.delete.key) {
      setActiveItem(item);
      setIsOpenConfirm(true);
    } else if (actionEnum === TableActions.edit.key) {
      localStorage.setItem('offeredToursById', JSON.stringify(item));
      getHistory().push(`/home/offered-tours/edit?id=${item.offerId}`);
    } else if (actionEnum === TableActions.close.key) inActiveOffer(item.offerId);
    else if (actionEnum === TableActions.check.key) activeOffer(item.offerId);
  }, []);
  const getAllOffers = useCallback(async () => {
    setIsLoading(true);
    const response = await GetAllOffers(
      {
        ...filter,
      },
      {
        search: filterBy.search,
        offerStatus: filterBy.offerStatus || null,
        startDate: (filterBy.dateRange && filterBy.dateRange.startDate) || null,
        endDate: (filterBy.dateRange && filterBy.dateRange.endDate) || null,
      }
    );
    if (filter.pageIndex === 0) {
      setOffers({
        result: (response && response.result) || [],
        totalCount: (response && response.totalCount) || 0,
      });
    } else {
      setOffers((item) => ({
        result: (item.result && item.result.concat((response && response.result) || [])) || [],
        totalCount: (response && response.totalCount) || 0,
      }));
    }
    setIsLoading(false);
  }, [filter, filterBy]);
  const deleteHandler = useCallback(async () => {
    setIsLoading(true);
    const res = await DeleteOffer(activeItem.offerId);
    if (res !== undefined) {
      showSuccess(getTranslate()(`${translationPath}offer-deleted-successfully`));
      setFilter((items) => ({ ...items, pageIndex: 0 }));
    } else showError(getTranslate()(`${translationPath}offer-delete-failed`));
    setIsOpenConfirm(false);
    setIsLoading(false);
  }, [activeItem]);
  const getIsChangedFilter = () =>
    Object.entries(filterByDefault).findIndex(
      (item) =>
        (typeof item[1] === 'object' &&
          item[1] !== null &&
          Object.entries(item[1]).findIndex((subItem) => subItem[1] !== filterBy[subItem[0]]) !==
            -1) ||
        item[1] !== filterBy[item[0]]
    ) !== -1;
  const activePageChanged = useCallback((e, newPage) => {
    setFilter((item) => ({ ...item, pageIndex: newPage }));
  }, []);
  const itemsPerPageChanged = useCallback((event, newItemsPerPage) => {
    setFilter((items) => ({ ...items, pageIndex: 0, pageSize: newItemsPerPage.props.value }));
  }, []);
  //   const onLoadMoreHandler = useCallback(() => {
  //     setFilter((item) => ({ ...item, pageIndex: item.pageIndex + 1 }));
  //   }, []);
  const resetFilterHandler = () => {
    setFilter((items) => ({ ...items, pageIndex: 0 }));
    setFilterBy(filterByDefault);
  };
  const searchHandler = (e) => {
    const value = e.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilterBy((items) => ({ ...items, search: value }));
    }, 700);
  };
  useEffect(() => {
    getAllOffers();
  }, [getAllOffers, filter, filterBy]);
  const addNewOffer = () => {
    setActiveItem(null);
    getHistory().push('/home/offered-tours/add');
  };
  return (
    <div className='offered-tours-wrapper view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <ButtonBase className='btns theme-solid' onClick={addNewOffer}>
              {getTranslate()(`${translationPath}add-new-offer`)}
            </ButtonBase>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <Inputs
                idRef='offersSearchRef'
                variant='outlined'
                fieldClasses='inputs theme-solid'
                translationPath={translationPath}
                label='filter'
                beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                onKeyUp={searchHandler}
                inputPlaceholder='search-offers'
              />
            </div>
          </div>
        </div>
        <div className='second-filter-section pt-2 c-black-light px-2'>
          <div className='section'>
            <span className='text-nowrap px-2'>
              {getTranslate()(`${translationPath}filter-by`)}
            </span>
            <div className='w-100 px-2'>
              <SelectComponet
                idRef='offerStatusRef'
                data={Object.values(OfferedToursStatusEnum).map((item) => item)}
                emptyItem={{
                  value: 0,
                  text: 'status',
                }}
                valueInput='key'
                textInput='value'
                classes='theme-solid filter-changes'
                paddingReverse='2.5rem'
                value={filterBy.offerStatus}
                onSelectChanged={(event) => {
                  setFilter((items) => ({ ...items, pageIndex: 0 }));
                  setFilterBy((items) => ({
                    ...items,
                    offerStatus: event.target.value,
                  }));
                }}
                overInputTextIcon='mdi mdi-bullhorn-outline px-1'
                translationPath={translationPath}
              />
            </div>
          </div>
          <div className='section px-2'>
            <DateRangePickerComponent
              isWideOvel
              ranges={[filterBy.dateRange]}
              onDateChanged={(selectedDate) => {
                setFilter((items) => ({ ...items, pageIndex: 0 }));
                console.log(selectedDate.selection);
                setFilterBy((items) => ({
                  ...items,
                  dateRange: {
                    ...items.dateRange,
                    ...selectedDate.selection,
                  },
                }));
              }}
            />
          </div>
          <div className='section px-2'>
            <ButtonBase
              className='btns theme-solid'
              disabled={!getIsChangedFilter()}
              onClick={resetFilterHandler}>
              <span>{getTranslate()(`${translationPath}reset`)}</span>
            </ButtonBase>
          </div>
        </div>
      </div>
      <NoDataFoundComponent />
      {offers.result.length === 0 && !filter.search && <NoContentComponent />}
      {offers.result.length > 0 && (
        <OfferedToursListComponent
          data={offers}
          filter={filter}
          onActionClicked={onActionClicked}
          activePageChanged={activePageChanged}
          itemsPerPageChanged={itemsPerPageChanged}
          translationPath={translationPath}
        />
      )}
      <DialogComponent
        titleText='confirm-message'
        saveText='confirm'
        saveType='button'
        maxWidth='sm'
        dialogContent={
          <div className='d-flex-column-center'>
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span>{getTranslate()(`${translationPath}delete-description`)}</span>
          </div>
        }
        saveClasses='btns theme-solid bg-danger w-100 mb-0 mx-0 br-0'
        isOpen={isOpenConfirm}
        onSaveClicked={deleteHandler}
        translationPath={translationPath}
        onCloseClicked={() => setIsOpenConfirm(false)}
        onCancelClicked={() => setIsOpenConfirm(false)}
      />
    </div>
  );
};
