/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getDownloadableLink, getTranslate } from '../../../../../Helpers';
import { DefaultImagesEnum, LoadableImageEnum } from '../../../../../Enums';
import './ToursTripsCardComponent.scss';
import { LoadableImageComponant } from '../../../../../Componentes';

export const ToursTripsCardComponent = ({
  data,
  draggable,
  onCardClicked,
  onLoadMore,
  isLoading,
  translationPath,
  onDrag,
  isFullWidth,
  filterData,
  filterInput,
  keyRef,
  dragEnd,
  touchEnd,
  onTouchMove,
  dragFrom,
}) => {
  const cardContainer = useRef(null);
  const dragHandler = useCallback(
    (item, index) => (event) => {
      if (onDrag) onDrag(item, index, event);
    },
    [onDrag]
  );
  const onScrollHandler = useCallback(
    (e) => {
      if (
        data &&
        data.result &&
        data.result.length < data.totalCount &&
        e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight
      )
        onLoadMore();
    },
    [data, onLoadMore]
  );
  const getIsFiltered = useCallback(
    (currentItem) => {
      return (
        filterData &&
        filterInput &&
        filterData.findIndex((item) => item && item[filterInput] === currentItem[filterInput]) !==
          -1
      );
    },
    [filterData, filterInput]
  );
  // useEventListener('scroll', onScrollHandler);
  useEffect(() => {
    if (
      data &&
      data.result &&
      data.result.length < data.totalCount &&
      onLoadMore &&
      cardContainer &&
      cardContainer.current &&
      cardContainer.current.scrollHeight <= cardContainer.current.clientHeight &&
      !isLoading
    ) {
      console.log(
        cardContainer.current && cardContainer.current.scrollHeight,
        cardContainer.current && cardContainer.current.clientHeight
      );
      onLoadMore();
    }
  }, [data, isLoading, onLoadMore]);
  return (
    <div
      className="tour-trip-card-component-wrapper"
      onScroll={onScrollHandler}
      ref={cardContainer}
    >
      {data &&
        data.result &&
        data.result.map((item, index) => (
          <React.Fragment key={`${keyRef}${index + 1}`}>
            {!getIsFiltered(item) && (
              <div className={`tour-trip-card-wrapper${(isFullWidth && ' w-100') || ''}`}>
                <a
                  draggable={draggable}
                  onClick={onCardClicked && onCardClicked(item, index)}
                  className={`cards-wrapper${(draggable && ' is-draggable') || ''}`}
                  data-id={item.tripId}
                  data-from={dragFrom}
                  onDrag={(draggable && dragHandler(item, index)) || undefined}
                  onTouchStart={(draggable && dragHandler(item, index)) || undefined}
                  onTouchMove={(draggable && onTouchMove(item, index)) || undefined}
                  onDragEnd={(draggable && dragEnd) || undefined}
                  onTouchEnd={(draggable && touchEnd) || undefined}
                >
                  <div className="cards-body">
                    <LoadableImageComponant
                      classes="d-inline-flex trip-cover-image"
                      type={LoadableImageEnum.div.key}
                      alt={getTranslate()(`${translationPath}trip-cover-image`)}
                      src={
                        (item.coverTripImageId && getDownloadableLink(item.coverTripImageId)) ||
                        DefaultImagesEnum.buildings.defaultImg
                      }
                    />
                    <div className="d-flex flex-wrap">
                      <div className="item-wrapper w-100 px-2">
                        <span className="item-header">{item.tripName}</span>
                      </div>
                      <div className="item-wrapper">
                        <span className="item-header">
                          <span className="mdi mdi-home px-2" />
                        </span>
                        <span className="item-body">{item.typeName}</span>
                      </div>
                      <div className="item-wrapper">
                        <span className="item-header">
                          <span className="mdi mdi-clock-time-five px-2" />
                        </span>
                        <span className="item-body">
                          <span>{item.tripDuration}</span>
                        </span>
                      </div>
                      <div className="item-wrapper">
                        <span className="item-header">
                          <span className="mdi mdi-cash-multiple px-2" />
                        </span>
                        <span className="item-body">{item.tripPrice}</span>
                      </div>
                      <div className="item-wrapper">
                        <span className="item-header">
                          <span className="mdi mdi-map-marker px-2" />
                          <span className="item-body fw-normal">{item.address}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
                {item.distanceInfo && <span className="distance-info">{item.distanceInfo}</span>}
              </div>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};
ToursTripsCardComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  draggable: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onCardClicked: PropTypes.func,
  isLoading: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  translationPath: PropTypes.string,
  filterInput: PropTypes.string,
  filterData: PropTypes.instanceOf(Array),
  keyRef: PropTypes.string,
  dragFrom: PropTypes.string,
  dragEnd: PropTypes.func,
  onDrag: PropTypes.func,
  touchEnd: PropTypes.func,
  onTouchMove: PropTypes.func,
};
ToursTripsCardComponent.defaultProps = {
  translationPath: 'home.tours.toursView.toursTripsCardComponent.',
  draggable: false,
  isFullWidth: false,
  onCardClicked: undefined,
  onLoadMore: undefined,
  isLoading: undefined,
  filterData: undefined,
  filterInput: undefined,
  keyRef: 'tourTripCardRef',
  onDrag: undefined,
  dragEnd: undefined,
  dragFrom: undefined,
  touchEnd: undefined,
  onTouchMove: undefined,
};
