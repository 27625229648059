/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './ToursTripsCardComponent.scss';
import { SlideImagesComponent } from '../../../../../../../Componentes';

export const ToursTripsCardComponent = ({
  data,
  draggable,
  onCardClicked,
  onLoadMore,
  isLoading,
  translationPath,
  onDrag,
  isFullWidth,
  filterData,
  filterInput,
  keyRef,
  dragEnd,
  touchEnd,
  onTouchMove,
  dragFrom,
}) => {
  const cardContainer = useRef(null);
  const dragHandler = useCallback(
    (item, index) => (event) => {
      if (onDrag) onDrag(item, index, event);
    },
    [onDrag]
  );
  const onScrollHandler = useCallback(
    (e) => {
      if (
        data &&
        data.result &&
        data.result.length < data.totalCount &&
        e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight
      )
        onLoadMore();
    },
    [data, onLoadMore]
  );
  const getIsFiltered = useCallback(
    (currentItem) => {
      return (
        filterData &&
        filterInput &&
        filterData.findIndex((item) => item && item[filterInput] === currentItem[filterInput]) !==
          -1
      );
    },
    [filterData, filterInput]
  );
  useEffect(() => {
    if (
      data &&
      data.result &&
      data.result.length < data.totalCount &&
      onLoadMore &&
      cardContainer &&
      cardContainer.current &&
      cardContainer.current.scrollHeight <= cardContainer.current.clientHeight &&
      !isLoading
    ) {
      onLoadMore();
    }
  }, [data, isLoading, onLoadMore]);

  return (
    <div
      className='tour-trip-card-component-wrapper'
      onScroll={onScrollHandler}
      ref={cardContainer}>
      {data &&
        data.result &&
        data.result.map((item, index) => (
          <React.Fragment key={`${keyRef}${index + 1}`}>
            {!getIsFiltered(item) && (
              <div className={`tour-trip-card-wrapper${(isFullWidth && ' w-100') || ''}`}>
                <a
                  draggable={draggable}
                  onClick={onCardClicked && onCardClicked(item, index)}
                  className={`cards-wrapper${(draggable && ' is-draggable') || ''}`}
                  data-id={item.tripId}
                  data-from={dragFrom}
                  onDrag={(draggable && dragHandler(item, index)) || undefined}
                  onTouchStart={(draggable && dragHandler(item, index)) || undefined}
                  onTouchMove={(draggable && onTouchMove(item, index)) || undefined}
                  onDragEnd={(draggable && dragEnd) || undefined}
                  onTouchEnd={(draggable && touchEnd) || undefined}>
                  <div className='cards-body tour-card-body'>
                    <div className='flex-wrap w-100'>
                      <div className='item-wrapper w-100'>
                        <span className='texts-truncate item-trip-name'>{item.tourName}</span>
                      </div>

                      <div className='tour-item-wrapper w-100'>
                        <div className='item-wrapper drag-tour-item w-100'>
                          <span className='mdi mdi-map-marker px-2' />
                          <span className='tour-address'>
                            {item.totalDestinations ? item.totalDestinations : 'N/A'}
                          </span>
                        </div>
                        <div className='item-wrapper drag-tour-item w-100'>
                          <span className='mdi mdi-clock-time-five px-2' />
                          <span className='tour-address'>
                            {item.totalDuration ? item.totalDuration : 'N/A'}
                          </span>
                        </div>
                      </div>

                      <div className='tour-item-wrapper w-100'>
                        <div className='item-wrapper drag-tour-item w-100'>
                          <span className='mdi mdi-cash-multiple px-2' />
                          <span className='tour-address'>
                            {item.fromPrice ? item.fromPrice + ' AED' : 'N/A'}
                          </span>
                        </div>
                        <div className='item-wrapper drag-tour-item w-100'>
                          <span className='mdi mdi-map-marker px-2' />
                          <span className='tour-address'>
                            {item.tripsTours ? item.tripsTours.length + ' Trips' : 'N/A'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='drag-tour-images'>
                    <SlideImagesComponent
                      data={(item && item.tripsTours && item.tripsTours) || []}
                      imageInput='coverTripImageId'
                      alt='tour-image'
                      titleText='trip-gallery'
                      translationPath={translationPath}
                      withViewGallery
                    />
                  </div>
                </a>
              </div>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};
ToursTripsCardComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  draggable: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onCardClicked: PropTypes.func,
  isLoading: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  translationPath: PropTypes.string,
  filterInput: PropTypes.string,
  filterData: PropTypes.instanceOf(Array),
  keyRef: PropTypes.string,
  dragFrom: PropTypes.string,
  dragEnd: PropTypes.func,
  onDrag: PropTypes.func,
  touchEnd: PropTypes.func,
  onTouchMove: PropTypes.func,
};
ToursTripsCardComponent.defaultProps = {
  translationPath: 'home.tours.toursView.toursTripsCardComponent.',
  draggable: false,
  isFullWidth: false,
  onCardClicked: undefined,
  onLoadMore: undefined,
  isLoading: undefined,
  filterData: undefined,
  filterInput: undefined,
  keyRef: 'tourTripCardRef',
  onDrag: undefined,
  dragEnd: undefined,
  dragFrom: undefined,
  touchEnd: undefined,
  onTouchMove: undefined,
};
