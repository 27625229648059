import { CarTypeStatus } from '../../States';

const carTypeRequest = (payload) => {
  return { type: CarTypeStatus.REQUEST, payload };
};

const carTypeSuccess = (payload) => {
  return { type: CarTypeStatus.SUCCESS, payload };
};

const carTypeError = (payload) => {
  return { type: CarTypeStatus.ERROR, payload };
};

const carTypeReset = (payload) => {
  return { type: CarTypeStatus.RESET, payload };
};

export const CarTypeActions = {
  carTypeRequest,
  carTypeSuccess,
  carTypeError,
  carTypeReset,
};
