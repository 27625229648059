import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { OfferToursCardComponent } from './OfferToursUtilitlies';
import {
  AutocompleteComponent,
  CheckboxesComponent,
  Inputs,
  Spinner,
} from '../../../../../../Componentes';
import { getTranslate } from '../../../../../../Helpers';
import { GetAllReadyTours, GetForms, GetReadyTourById } from '../../../../../../Serviecs';
import { ButtonBase } from '@material-ui/core';
import './OfferToursComponent.scss';

export const OfferToursComponent = ({
  offerId,
  state,
  onStateChanged,
  droppedTours,
  onDroppedToursChanged,
  filter,
  setFilter,
  isNext,
  onNextChanged,
  translationPath,
  isLoading,
  notCompletlyLoaded,
  notCompletlyLoadedChanged,
  selectedTourTypes,
  onSelectedTourTypesChanged,
}) => {
  const [onDragItem, setOnDragItem] = useState(null);
  const [loadingEachTour, setLoadingEachTour] = useState([]);

  const [currentDragOverItem, setCurrentDragOverItem] = useState(null);
  const draggingElement = useRef(null);
  const dragOverTouchItem = useRef(null);
  const [tourType, setTourType] = useState([]);
  const [isToursLoading, setIsToursLoading] = useState(false);
  const [tours, setTours] = useState({ result: [], totalCount: 0 });
  const [toursTypes, setToursTypes] = useState([]);
  const [tourTypeSearch, setTourTypeSearch] = useState('');
  const searchTimer = useRef(null);
  const searchTourTypesTimer = useRef(null);

  const getTours = useCallback(async () => {
    setIsToursLoading(true);
    const res = await GetAllReadyTours(filter);
    if (filter.pageIndex === 0) {
      setTours({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setTours((item) => ({
        result: item.result.concat((res && res.result) || []),
        totalCount: (res && res.totalCount) || 0,
      }));
    }
    setIsToursLoading(false);
  }, [filter]);
  const getTourTypes = useCallback(async () => {
    setIsToursLoading(true);
    const res = await GetForms(0, 10, tourTypeSearch);
    setToursTypes((res && res.result) || []);
    setIsToursLoading(false);
  }, [tourTypeSearch]);
  const onLoadMoreHandler = useCallback(() => {
    console.log('on load more');
    setFilter((items) => ({ ...items, pageIndex: items.pageIndex + 1 }));
  }, [setFilter]);
  const searchHandler = (event) => {
    const value = event.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((items) => ({ ...items, pageIndex: 0, search: value }));
    }, 700);
  };
  const dragOverHandler = (event) => {
    event.preventDefault();
  };
  const fakeElementGenerator = (event) => {
    if (event.type === 'touchstart') {
      draggingElement.current = document.createElement('div');
      draggingElement.current.classList.add('offer-tour-card-component-wrapper');
      draggingElement.current.classList.add('p-absolute');
      draggingElement.current.classList.add('fake-dragging-item');
      draggingElement.current.setAttribute(
        'style',
        `top:${event.targetTouches[0].clientY + document.documentElement.scrollTop}px;left:${
          event.targetTouches[0].clientX
        }px`
      );
      draggingElement.current.appendChild(event.target.parentElement.cloneNode(true));
      document.body.appendChild(draggingElement.current);
    }
  };
  const dragHandler = (item, index, event) => {
    if (!onDragItem || onDragItem.index !== index) setOnDragItem({ item, index, from: 'tour' });
    fakeElementGenerator(event);
  };
  const dragOfferTourHandler = (item, index, event) => {
    if (!onDragItem || onDragItem.index !== index) setOnDragItem({ item, index, from: 'offer' });
    fakeElementGenerator(event);
  };
  const dragOverOfferHandler = useCallback(
    (item, index, event) => {
      event.preventDefault();
      const center =
        event.target.getBoundingClientRect().bottom +
        window.scrollY -
        event.target.offsetHeight / 2;
      const isBefore =
        (currentDragOverItem && currentDragOverItem.index + 1 === onDragItem.index) ||
        (event.pageY <= center &&
          currentDragOverItem &&
          currentDragOverItem.index - 1 !== onDragItem.index);
      if (
        !currentDragOverItem ||
        index !== currentDragOverItem.index ||
        currentDragOverItem.isBefore !== isBefore ||
        currentDragOverItem.dragOver !== 'offer'
      )
        setCurrentDragOverItem({ index, dragOver: 'offer', isBefore });
    },
    [currentDragOverItem, onDragItem]
  );
  const onTouchMoveHandler = useCallback(
    () => (event) => {
      draggingElement.current.setAttribute(
        'style',
        `top:${event.targetTouches[0].clientY + document.documentElement.scrollTop}px;left:${
          event.targetTouches[0].clientX
        }px`
      );
      dragOverTouchItem.current = document.elementFromPoint(
        event.targetTouches[0].clientX,
        event.targetTouches[0].clientY
      );
      if (!dragOverTouchItem.current || !dragOverTouchItem.current.classList) return;
      if (dragOverTouchItem.current.classList.contains('offer-tour-card-component-wrapper')) {
        if (currentDragOverItem) setCurrentDragOverItem(null);
        return;
      }
      const dragOverFrom = dragOverTouchItem.current.getAttribute('data-from');
      const index = droppedTours.findIndex(
        (item) => item.tourId === +dragOverTouchItem.current.getAttribute('data-id')
      );
      if (dragOverFrom === 'offer' && index !== -1) {
        if (!currentDragOverItem || currentDragOverItem.index !== index)
          setCurrentDragOverItem({
            index,
            dragOver: dragOverTouchItem.current.getAttribute('data-from'),
            isBefore: true,
          });
      }
    },
    [currentDragOverItem, droppedTours]
  );
  const tourTouchEndHandler = () => {
    draggingElement.current.remove();
    if (
      dragOverTouchItem.current &&
      dragOverTouchItem.current.classList.contains('offer-tour-card-component-wrapper')
    )
      dropHandler();
    else if (currentDragOverItem) dropHandler();
  };
  const dropHandler = () => {
    if (
      currentDragOverItem &&
      currentDragOverItem.index === onDragItem.index &&
      currentDragOverItem.dragOver === onDragItem.from
    ) {
      setCurrentDragOverItem(null);
      setOnDragItem(null);
      return;
    }
    const localDroppedTours = [...droppedTours];
    if (currentDragOverItem !== null) {
      if (currentDragOverItem.dragOver === onDragItem.from)
        localDroppedTours.splice(
          currentDragOverItem.isBefore ? currentDragOverItem.index : currentDragOverItem.index + 1,
          0,
          localDroppedTours.splice(onDragItem.index, 1)[0]
        );
      else
        localDroppedTours.splice(
          currentDragOverItem.isBefore ? currentDragOverItem.index : currentDragOverItem.index + 1,
          0,
          onDragItem.item
        );
    } else localDroppedTours.push(onDragItem.item);
    const tourIds = localDroppedTours.map((item) => item && item.tourId);
    onStateChanged('tourIds', tourIds);
    setCurrentDragOverItem(null);
    setOnDragItem(null);
    if (onDroppedToursChanged) onDroppedToursChanged(localDroppedTours);
  };
  const removeOfferTourHandler = useCallback(
    (index) => {
      droppedTours.splice(index, 1);
      onDroppedToursChanged(droppedTours);
      const tourIds = droppedTours.map((item) => item.tourId);
      setFilter((items) => ({
        ...items,
        pageIndex: 0,
      }));
      onStateChanged('tourIds', tourIds);
    },
    [droppedTours, onDroppedToursChanged, onStateChanged, setFilter]
  );
  const searchTourTypesHandler = (event) => {
    const value = event.target.value;
    if (searchTourTypesTimer.current) clearTimeout(searchTourTypesTimer.current);
    searchTourTypesTimer.current = setTimeout(() => {
      setTourTypeSearch(value);
    }, 700);
  };
  const notCompletlyLoadedHandler = useCallback(() => {
    notCompletlyLoaded.map(async (item) => {
      setLoadingEachTour((elements) => [...elements, item]);
      const result = await GetReadyTourById(item);
      if (!result) return;
      setLoadingEachTour((elements) => {
        const elementIndex = elements.findIndex((element) => element === item);
        if (elementIndex !== -1) elements.splice(elementIndex, 1);
        return [...elements];
      });
      const localDroppedToursIndex = droppedTours.findIndex((element) => element.tourId === item);
      if (localDroppedToursIndex !== -1) {
        let total = 0;
        result.tourTrips.map((element) => {
          total = total + (element.trip && element.trip.tripDuration) || 0;
          return undefined;
        });
        droppedTours[localDroppedToursIndex] = {
          ...droppedTours[localDroppedToursIndex],
          discountPrice: result.totalFromPriceAfterDiscount,
          totalDestinations: null,
          totalDuration: `${Math.round(total / 60)} H`,
          tripsTours: result.tourTrips.map((element) => ({
            coverTripImageId: (element.trip && element.trip.coverTripImageId) || null,
          })),
        };
        if (onDroppedToursChanged) onDroppedToursChanged(droppedTours);
      }

      return undefined;
    });
    if (notCompletlyLoadedChanged) notCompletlyLoadedChanged(loadingEachTour);
  }, [
    droppedTours,
    loadingEachTour,
    notCompletlyLoaded,
    notCompletlyLoadedChanged,
    onDroppedToursChanged,
  ]);
  useEffect(() => {
    getTours();
  }, [filter, getTours, offerId]);
  useEffect(() => {
    getTourTypes();
  }, [getTourTypes, tourTypeSearch]);
  useEffect(() => {
    if (
      document.body.scrollHeight === window.innerHeight &&
      tours.totalCount > tours.result.length &&
      !isLoading
    )
      onLoadMoreHandler();
  }, [isLoading, onLoadMoreHandler, state.tourIds.length, tours]);
  const onScrollHandler = useCallback(
    (e) => {
      console.log('scroll');
      if (
        tours &&
        tours.result &&
        tours.result.length < tours.totalCount &&
        e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight
      )
        onLoadMoreHandler();
    },
    [tours, onLoadMoreHandler]
  );
  useEffect(() => {
    if (notCompletlyLoaded.length > 0) notCompletlyLoadedHandler();
  }, [notCompletlyLoaded.length, notCompletlyLoadedHandler]);
  useEffect(() => {
    return () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      if (searchTourTypesTimer.current) clearTimeout(searchTourTypesTimer.current);
    };
  }, []);
  return (
    <div className={`offer-tours-component-wrapper${(isNext && ' is-next') || ''}`}>
      <div className='tours-list-wrapper'>
        <div className='tours-view view-wrapper'>
          <Spinner isActive={isToursLoading} />
          <div className='header-section mb-0'>
            <div className='filter-section'>
              <div className='filter-title d-inline-flex px-2'>
                <span className='texts-truncate c-black-light'>
                  {getTranslate()(`${translationPath}drag-a-tour`)}
                </span>
              </div>
              <div className='d-flex-column p-relative px-2'>
                <Inputs
                  idRef='toursSearchRef'
                  variant='outlined'
                  fieldClasses='inputs theme-solid'
                  translationPath={translationPath}
                  label='filter'
                  beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                  onKeyUp={searchHandler}
                  inputPlaceholder='search-tours'
                />
              </div>
            </div>
            <div className='second-filter-section pt-2 c-black-light px-2'>
              <div className='section px-2'>
                <span className='d-inline-flex-center mdi mdi-tune px-1' />
                <div className='w-100 px-1'>
                  <AutocompleteComponent
                    idRef='tourTypeRef'
                    translationPath={translationPath}
                    options={toursTypes}
                    getOptionLabel={(option) => option.formsName || ''}
                    chipsLabel={(option) => option.formsName || ''}
                    inputPlaceholder='tour-type'
                    getOptionSelected={(option) =>
                      filter.typeIds.findIndex((item) => item === option.formsId) !== -1
                    }
                    value={tourType}
                    onKeyUp={searchTourTypesHandler}
                    onChange={(e, value) => {
                      setTourType(value);
                      setFilter((items) => ({
                        ...items,
                        pageIndex: 0,
                        typeIds:
                          (value && value.length > 0 && value.map((item) => item.formsId)) || null,
                      }));
                    }}
                    withoutSearchButton
                  />
                </div>
              </div>
              <div className='section px-2'>
                <div className='w-100 px-2'>
                  {console.log(toursTypes, state.formIds)}
                  <AutocompleteComponent
                    idRef='tourTypeSelectionRef'
                    translationPath={translationPath}
                    options={toursTypes}
                    getOptionLabel={(option) => option.formsName || ''}
                    chipsLabel={(option) => option.formsName || ''}
                    inputPlaceholder='select-by-tour-type'
                    multiple
                    getOptionSelected={(option) =>
                      state.formIds.findIndex((item) => item === option.formsId) !== -1
                    }
                    renderOption={(option) => (
                      <span>
                        <CheckboxesComponent
                          idRef={`tourTypeSelectionRefCheckbox${option.formsId}`}
                          checked={
                            state.formIds.findIndex((item) => item === option.formsId) !== -1
                          }
                        />
                        <span>{option.formsName}</span>
                      </span>
                    )}
                    value={
                      (selectedTourTypes &&
                        selectedTourTypes.filter(
                          (item) => state.formIds && state.formIds.includes(item.formsId)
                        )) ||
                      []
                    }
                    onKeyUp={searchTourTypesHandler}
                    onChange={(e, value) => {
                      onSelectedTourTypesChanged(value);
                      onStateChanged(
                        'formIds',
                        (value && value.length > 0 && value.map((item) => item.formsId)) || []
                      );
                      // setTourType(value);
                      // setFilter((items) => ({
                      //   ...items,
                      //   pageIndex: 0,
                      //   typeIds:
                      //     (value && value.length > 0 && value.map((item) => item.formsId)) || null,
                      // }));
                    }}
                    withoutSearchButton
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='drag-wrapper' onScroll={onScrollHandler}>
            <OfferToursCardComponent
              data={tours}
              onLoadMore={onLoadMoreHandler}
              filterData={droppedTours}
              filterInput='tourId'
              translationPath={translationPath}
              isLoading={isToursLoading}
              draggable
              dragFrom='tour'
              onDrag={dragHandler}
              touchEnd={tourTouchEndHandler}
              onTouchMove={onTouchMoveHandler}
            />
          </div>
        </div>
      </div>
      <div className='drop-wrapper' onDrop={dropHandler} onDragOver={dragOverHandler}>
        <div className='drop-content-wrapper'>
          <div className='drop-header'>{getTranslate()(`${translationPath}drop-a-tour`)}</div>
          <div className='drop-content'>
            {(
              selectedTourTypes &&
              selectedTourTypes.filter(
                (item) => state.formIds && state.formIds.includes(item.formsId)
              )
            ).map((item, index) => (
              <div key={`selectedTourTypeRef${index}`} className='tours-types-item-wrapper'>
                <span>{getTranslate()(`${translationPath}all`)}</span>
                <span className='px-2'>{item.formsName}</span>
                <ButtonBase
                  className='btns-icon theme-transparent c-danger'
                  onClick={() => {
                    onSelectedTourTypesChanged(selectedTourTypes.splice(index, 1));
                    state.formIds.splice(index, 1);
                    onStateChanged('formIds', state.formIds);
                  }}>
                  <span className='mdi mdi-close c-danger' />
                </ButtonBase>
              </div>
            ))}
            <OfferToursCardComponent
              data={{
                result: droppedTours || [],
                totalCount: (droppedTours && droppedTours.length) || 0,
              }}
              loadingEachTour={loadingEachTour}
              onDragOver={dragOverOfferHandler}
              translationPath={translationPath}
              key='offerRef'
              currentDragOverItem={currentDragOverItem}
              onDragItem={onDragItem}
              onDrag={dragOfferTourHandler}
              onTouchEnd={tourTouchEndHandler}
              onTouchMove={onTouchMoveHandler}
              onRemoveTourClicked={removeOfferTourHandler}
              draggable
              dragFrom='offer'
            />
          </div>
          <div className='drop-footer'>
            <div className='d-inline-flex-center px-2'>
              <ButtonBase className='btns theme-solid' onClick={() => onNextChanged(!isNext)}>
                {getTranslate()(`${translationPath}back`)}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OfferToursComponent.propTypes = {
  offerId: PropTypes.number,
  state: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,
  droppedTours: PropTypes.instanceOf(Array).isRequired,
  onDroppedToursChanged: PropTypes.func.isRequired,
  selectedTourTypes: PropTypes.arrayOf(Object).isRequired,
  onSelectedTourTypesChanged: PropTypes.func.isRequired,
  onNextChanged: PropTypes.func.isRequired,
  notCompletlyLoadedChanged: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  isNext: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  notCompletlyLoaded: PropTypes.arrayOf(PropTypes.number).isRequired,
};
OfferToursComponent.defaultProps = {
  offerId: null,
};
