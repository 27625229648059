import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Avatar, Divider, Grid, Typography } from '@material-ui/core';
import { getDownloadableLink, getTranslate } from '../../../../Helpers';
import { GetPromoCodeById } from '../../../../Serviecs';
import { Spinner, Inputs, LoadableImageComponant } from '../../../../Componentes';
import './PromoCodeSidePanel.scss';

const PromoCodeSidePanel = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const [promoCodeInfo, setpromoCodeInfo] = useState({});
  const [userSearch, setUserSearch] = useState([]);
  const time = useRef(null);
  const translationPath = 'PromoCode.';

  const GetPromoCodeInfo = useCallback(async () => {
    setLoading(true);
    const res = await GetPromoCodeById(item.promoCodeId);
    setpromoCodeInfo(res);
    setUserSearch(res.promoCodeUserUsed.map((el) => el));
    setLoading(false);
  }, [item.promoCodeId]);

  useEffect(() => {
    GetPromoCodeInfo();
  }, [GetPromoCodeInfo]);

  const searchHandler = (e) => {
    const value = e.target.value;
    if (time.current) clearTimeout(time.current);
    time.current = setTimeout(() => {
      setUserSearch(
        userSearch.filter((el) => el.fullName.toUpperCase().includes(value.toUpperCase()))
      );
      if (value === '') {
        setUserSearch(promoCodeInfo.promoCodeUserUsed.map((el) => el));
      }
    }, 500);
  };

  return (
    <Grid container spacing={1} className='promo-code-side-wrapper'>
      <Spinner isActive={loading} />
      <Grid item xs={12} className='promo-name'>
        <Typography>{promoCodeInfo.promoCodeName}</Typography>
      </Grid>
      <Grid item xs={12}>
        {getTranslate()(`${translationPath}used`)}
        {promoCodeInfo.numberOfUse}
        {getTranslate()(`${translationPath}times`)}
      </Grid>
      <Grid
        item
        xs={12}
        className={
          promoCodeInfo.type === 2 ? `promo-status public-promo` : `promo-status private-promo`
        }>
        {promoCodeInfo.type === 2
          ? getTranslate()(`${translationPath}public`)
          : getTranslate()(`${translationPath}private`)}
      </Grid>
      <Grid item xs={12} className='promo-icon'>
        <span className='mdi mdi-calendar-blank-outline' />
        {promoCodeInfo.startDate &&
          getTranslate()(`${translationPath}valid-from`) +
            promoCodeInfo.startDate.split('T')[0] +
            getTranslate()(`${translationPath}valid-to`) +
            promoCodeInfo.endDate.split('T')[0]}
      </Grid>
      <Grid item xs={12} className='promo-icon'>
        <span className='mdi mdi-account-multiple-outline' />
        {getTranslate()(`${translationPath}used`) +
          promoCodeInfo.promoCodeUsersUsed +
          getTranslate()(`${translationPath}times`)}
      </Grid>
      <Grid item xs={12} className='promo-icon'>
        <span className='mdi mdi-brightness-percent' />
        {getTranslate()(`${translationPath}percentage`) + promoCodeInfo.discountPercentage + '%'}
      </Grid>
      <Grid item xs={12} className='promo-icon'>
        <span className='mdi mdi-percent' />
        {getTranslate()(`${translationPath}percentage-max`) +
          getTranslate()(`${translationPath}up-to`) +
          promoCodeInfo.discountLimit}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container className='promo-users c-black-light fw-bold' justify='space-between'>
        <span>{getTranslate()(`${translationPath}users`)}</span>
        <span className='px-2'>
          {getTranslate()(`${translationPath}used`)} {userSearch.length}{' '}
          {getTranslate()(`${translationPath}times`)}
        </span>
      </Grid>
      <Grid item xs={12}>
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section px-2'>
              <div className='d-flex-column p-relative search-section'>
                <Inputs
                  idRef='tripsSearchRef'
                  variant='outlined'
                  fieldClasses='inputs theme-solid'
                  translationPath={translationPath}
                  label='search'
                  onKeyUp={searchHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        {promoCodeInfo &&
          promoCodeInfo.promoCodeUserUsed &&
          userSearch.map((item) =>
            item.profileImage ? (
              <div className='promo-code-users'>
                <LoadableImageComponant
                  classes={'contact-image'}
                  alt={item.fullName}
                  src={getDownloadableLink(item.profileImage)}
                />
                <div className='promo-user-detailes'>
                  <Typography>{item.fullName}</Typography>
                  <Typography variant='h1'>
                    {getTranslate()(`${translationPath}discount`)}
                    {item.totalDiscount}
                  </Typography>
                </div>
              </div>
            ) : (
              <div className='promo-code-users'>
                <Avatar className='contact-image' alt={item.fullName} />
                <div className='promo-user-detailes'>
                  <Typography>{item.fullName}</Typography>
                  <Typography variant='h1'>
                    {getTranslate()(`${translationPath}discount`)}
                    {item.totalDiscount}
                  </Typography>
                </div>
              </div>
            )
          )}
      </Grid>
    </Grid>
  );
};
export { PromoCodeSidePanel };
