import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';
import { showError } from '../../Helpers';

export const GetSliderScreens = async (pageIndex, pageSize, searchedItem) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Screen/GetAllSliders?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchedItem}`
    );
    return result;
  } catch (e) {
    showError('Error Retriving Sliders');
  }
};

export const CreateSlider = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Screen/CreateSlider`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

export const CreateScreen = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Screen/CreateScreen`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Creating Slider Screen');
  }
};

export const DeleteSlider = async (sliderId) => {
  const result = await HttpServices.delete(
    `${config.serverAddress}/PSTViewing/Screen/DeleteSlider/${sliderId}`
  )
    .then(() => {
      return;
    })
    .catch((error) => {
      return (
        (error.response && error.response.data && error.response.data.Message) ||
        'slider-delete-failed'
      );
    });
  return result;
};

export const UpdateSlider = async (sliderId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Screen/UpdateSlider/${sliderId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

export const UpdateScreen = async (screenId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Screen/${screenId}`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Editing Slider Screen');
  }
};

export const DeleteScreen = async (screenId) => {
  // try {
  const result = await HttpServices.delete(`${config.serverAddress}/PSTViewing/Screen/${screenId}`)
    .then(() => {
      return;
    })
    .catch((error) => {
      return (
        (error.response && error.response.data && error.response.data.Message) ||
        'slider-screen-delete-failed'
      );
    });
  return result;
  // } catch (e) {
  //   showError('Error Deleting Slider Screen');
  // }
};

export const UploadSlidePicture = async (body) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/FileManager/File/Upload`, body);
    return result;
  } catch (e) {
    showError('Error Uploading Slider Image');
  }
};

export const UploadScreenImage = async (body) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/FileManager/File/Upload`, body);
    return result;
  } catch (e) {
    showError('Error Uploading Slider Image');
  }
};
