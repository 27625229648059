import React from 'react';
import NotFound from '../../Assets/Images/defaults/404.png';
import { Grid } from '@material-ui/core';
import { getTranslate } from '../../Helpers';
import './NoInternetComponent.scss';

export const NoDataFoundComponent = () => {
  const translationPath = 'NotFoundViews.';

  return (
    !navigator.onLine && (
      <Grid container className='no-data-result'>
        <Grid item className='no-data-text'>
          {getTranslate()(`${translationPath}no-internet-connection`)}
        </Grid>
        <Grid item>
          <img
            src={NotFound}
            alt={getTranslate()(`${translationPath}No-internet`)}
            className='no-data-img'
          />
        </Grid>
      </Grid>
    )
  );
};
