import React, { useCallback, useState, useRef, memo, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
// import IconButton from '@material-ui/core/IconButton';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { getLanguage } from 'react-switch-lang';
import { TableActions } from '../../Enums';
import { getTranslate, useEventListener, isMobileDetector } from '../../Helpers';
import { useOnClickOutside } from '../../Hubs';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const Tables = memo(
  ({
    tableOptions,
    data,
    activePage,
    totalItems,
    activePageChanged,
    itemsPerPageChanged,
    itemsPerPage,
    headerData,
    footerData,
    selectAllOptions,
    sortColumnClicked,
    actionsOptions,
    focusedRowChanged,
    wrapperClasses,
    responsiveWrapperClasses
    // isCollapsed,
  }) => {
    const [currentOrderById, setCurrentOrderById] = useState(-1);
    const [currentOrderDirection, setCurrentOrderDirection] = useState('desc');
    const tableRef = useRef(null);
    // const [open, setOpen] = React.useState(false);
    const [focusedRow, setFocusedRow] = useState(-1);
    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) return -1;
      if (b[orderBy] > a[orderBy]) return 1;
      return 0;
    };
    const getComparator = (order, orderBy) =>
      order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    const createSortHandler = useCallback(
      (columnId) => () => {
        if (!tableOptions) return;
        setCurrentOrderDirection((item) => (item === 'desc' ? 'asc' : 'desc'));
        setCurrentOrderById(columnId);
        if (tableOptions.sortFrom === 2) sortColumnClicked(columnId, currentOrderDirection);
      },
      [currentOrderDirection, tableOptions, sortColumnClicked]
    );
    const stableSort = (array, comparator) => {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    };
    const getCurrentSelectedItem = useCallback(
      (itemIndex) =>
        selectAllOptions
          ? selectAllOptions.selectedRows.findIndex((item) => item === itemIndex + activePage) !==
              -1 || selectAllOptions.isSelectAll
          : false,
      [activePage, selectAllOptions]
    );
    const getCurrentDisabledItem = useCallback(
      (itemIndex) =>
        selectAllOptions
          ? selectAllOptions.disabledRows.findIndex((item) => item === itemIndex + activePage)
          : -1,
      [activePage, selectAllOptions]
    );
    const bodyRowClicked = useCallback(
      (rowIndex) => {
        if (focusedRow === -1 || focusedRow !== rowIndex) {
          setFocusedRow(() => {
            focusedRowChanged(rowIndex);
            return rowIndex;
          });
        } else {
          setFocusedRow(() => {
            focusedRowChanged(-1);
            return -1;
          });
        }
      },
      [focusedRow, focusedRowChanged]
    );
    const getTableActionValue = (key) =>
      Object.values(TableActions).find((item) => item.key === key);
    const getSortDataName = () => {
      const currentHeader = headerData.find((item) => item.id === currentOrderById);
      if (currentHeader) return currentHeader.input;
      return null;
    };
    useOnClickOutside(tableRef, (e) => {
      if (
        e.target &&
        e.target.className &&
        typeof e.target.className === 'string' &&
        (e.target.className.includes('actions-wrapper') ||
          e.target.className.includes('table-action-btn') ||
          e.target.className.includes('table-action-icon'))
      )
        return;
      if (focusedRow !== -1) setFocusedRow(-1);
    });
    const TableTooltip = () => {
      const [lastClientWidth, setLastClientWidth] = useState();
      const [isMobile, setIsMobile] = useState(false);

      const rowRef = document.getElementById(`bodyRowRef${focusedRow * (activePage + 1)}`);
      const [coordinations, setCoordinations] = useState(() => rowRef.getBoundingClientRect());
      const [tableCoordinations, setTableCoordinations] = useState(
        (tableRef && tableRef.current && tableRef.current.getBoundingClientRect()) || { right: 0 }
      );
      const timer = useRef(null);
      const innerTime = useRef(null);
      const sizeRecheckTime = useRef(null);
      const [opacity, setOpacity] = useState(0);
      const [transition, setTransition] = useState(null);

      const updateCoordinations = useCallback(() => {
        setOpacity(0);
        setTransition(null);
        if (timer.current !== null) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          setTransition('opacity 0.3s linear');
          if (innerTime.current !== null) clearTimeout(innerTime.current);
          innerTime.current = setTimeout(() => {
            // do something
            if (rowRef) setCoordinations(rowRef.getBoundingClientRect());
            setOpacity(1);
          }, 300);
        }, 299);
      }, [rowRef]);
      const sizeRechecker = useCallback(() => {
        if (rowRef && rowRef.current) {
          if (!lastClientWidth || rowRef.current.clientWidth !== lastClientWidth) {
            setLastClientWidth(rowRef.current.clientWidth);
            if (sizeRecheckTime.current !== null) clearTimeout(sizeRecheckTime.current);
            sizeRecheckTime.current = setTimeout(() => {
              if (!lastClientWidth || lastClientWidth !== rowRef.current.clientWidth)
                sizeRechecker();
            }, 50);
          }
        }
      }, [lastClientWidth, rowRef]);
      const updateSize = useCallback(() => {
        setOpacity(0);
        setTransition(null);
        setIsMobile(isMobileDetector());
        if (tableRef) setTableCoordinations(tableRef.current.getBoundingClientRect());
        if (timer.current !== null) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          setTransition('opacity 0.3s linear');
          innerTime.current = setTimeout(() => {
            // do something
            if (tableRef) setTableCoordinations(tableRef.current.getBoundingClientRect());
            setOpacity(1);
          }, 249);
        }, 250);
        sizeRechecker();
      }, [sizeRechecker]);

      useEventListener('resize', () => updateSize());
      useEventListener('scroll', updateCoordinations);
      useEffect(() => {
        updateSize();
      }, [updateSize]);
      useEffect(
        () => () => {
          if (timer.current !== null) clearTimeout(timer.current);
          if (sizeRecheckTime.current !== null) clearTimeout(sizeRecheckTime.current);
          if (innerTime.current !== null) clearTimeout(innerTime.current);
        },
        []
      );
      return (
        <div
          className={`table-actions-wrapper ${actionsOptions.classes}`}
          style={{
            top: coordinations.top,
            right:
              getLanguage() !== 'ar'
                ? window.innerWidth - tableCoordinations.right - (isMobile ? -7 : 1)
                : 'initial',
            left: getLanguage() === 'ar' ? tableCoordinations.left : 'initial',
            height: coordinations.height,
            opacity,
            transition,
          }}
        >
          {actionsOptions.actions.map((item, index) => (
            <React.Fragment key={`tableAction${index + 1}`}>
              {(item.enum !== TableActions.externalComponent && (
                <Button
                  className={getTableActionValue(item.enum).buttonClasses}
                  onClick={(event) => {
                    actionsOptions.onActionClicked(item.enum, data[focusedRow], focusedRow, event);
                  }}
                  disabled={item.isDisabled || actionsOptions.isDisabled}
                >
                  <span className={getTableActionValue(item.enum).icon} />
                </Button>
              )) ||
                actionsOptions.externalComponent}
            </React.Fragment>
          ))}
        </div>
      );
    };
    const dataReturn = (dataItem, columnPath) => {
      if (!columnPath.includes('.')) return dataItem[columnPath] || dataItem[columnPath] + '';
      let a = dataItem;
      columnPath.split('.').map((item) => {
        a = a[item];
        return item;
      });
      return a;
    };
    return (
      <div className={`w-100 table-responsive ${responsiveWrapperClasses}`} ref={tableRef}>
        <TableContainer>
          <Table
            className={`table-wrapper ${wrapperClasses}`}
            aria-labelledby="tableTitle"
            size={tableOptions.tableSize} // 'small' or 'medium'
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {/* {isCollapsed && <TableCell></TableCell>} */}
                {selectAllOptions && selectAllOptions.withCheckAll && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectAllOptions.selectedRows.length > 0 &&
                        selectAllOptions.selectedRows.length < totalItems &&
                        !selectAllOptions.isSelectAll
                      }
                      className="checkbox-wrapper theme-secondary"
                      checkedIcon={<span className="mdi mdi-check" />}
                      indeterminateIcon={<span className="mdi mdi-minus" />}
                      checked={
                        (totalItems > 0 && selectAllOptions.selectedRows.length === totalItems) ||
                        selectAllOptions.isSelectAll
                      }
                      disabled={selectAllOptions.isDisableAll}
                      onClick={selectAllOptions.onSelectAllClicked}
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                )}
                {headerData.map((item, index) => (
                  <TableCell
                    key={`headerCell${index + 1}`}
                    sortDirection={
                      item.isSortable && currentOrderById === item.id
                        ? currentOrderDirection
                        : false
                    }
                  >
                    {item.isSortable ? (
                      <TableSortLabel
                        active={currentOrderById === item.id}
                        direction={currentOrderById === item.id ? currentOrderDirection : 'desc'}
                        onClick={createSortHandler(item.id)}
                      >
                        {getTranslate()(item.label)}
                      </TableSortLabel>
                    ) : (
                      getTranslate()(item.label)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(data, getComparator(currentOrderDirection, getSortDataName()))
                .slice(
                  data.length <= itemsPerPage ? 0 : activePage * itemsPerPage,
                  data.length <= itemsPerPage
                    ? itemsPerPage
                    : activePage * itemsPerPage + itemsPerPage
                )
                .map((row, rowIndex) => {
                  const isItemSelected = getCurrentSelectedItem(rowIndex);
                  const isItemDisabled = getCurrentDisabledItem(rowIndex) !== -1;
                  return (
                    <React.Fragment key={`bodyRow${rowIndex * (activePage + 1)}`}>
                      <TableRow
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        id={`bodyRowRef${rowIndex * (activePage + 1)}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          bodyRowClicked(rowIndex);
                        }}
                        className={rowIndex === focusedRow ? 'table-row-overlay' : ''}
                      >
                        {/* {isCollapsed && (
                          <>
                            <TableCell padding="checkbox">
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpen(!open);
                                }}
                              >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>
                          </>
                        )} */}
                        {selectAllOptions && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              checkedIcon={<span className="mdi mdi-check" />}
                              indeterminateIcon={<span className="mdi mdi-minus" />}
                              disabled={selectAllOptions.isDisableAll || isItemDisabled}
                              className="checkbox-wrapper"
                              onClick={(event) => {
                                event.stopPropagation();
                                selectAllOptions.onSelectClicked(rowIndex);
                              }}
                              inputProps={{
                                'aria-labelledby': `body-checkbox${rowIndex}`,
                              }}
                            />
                          </TableCell>
                        )}
                        {headerData.length > 0 &&
                          headerData.map((column, columnIndex) => (
                            <TableCell
                              key={`bodyColumn${columnIndex * (activePage + 1) + rowIndex}`}
                            >
                              {(column.isDate &&
                                moment(dataReturn(row, column.input)).format(
                                  column.dateFormat || tableOptions.dateFormat
                                )) ||
                                (column.component &&
                                  column.component(row, rowIndex, column, columnIndex)) ||
                                dataReturn(row, column.input)}
                            </TableCell>
                          ))}
                      </TableRow>
                      {/* {open && (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>test</TableCell>
                          <TableCell>test</TableCell>
                          <TableCell>test</TableCell>
                          <TableCell>test</TableCell>
                          <TableCell>test</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )} */}
                    </React.Fragment>
                  );
                })}
            </TableBody>
            {footerData.length > 0 && (
              <TableFooter className="footer-wrapper">
                <TableRow>
                  {footerData.map((item, index) => (
                    <TableCell colSpan={item.colSpan} key={`footerCell${index + 1}`}>
                      {item.value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
        {actionsOptions &&
          actionsOptions.actions.length > 0 &&
          !actionsOptions.isDisabled &&
          focusedRow !== -1 && <TableTooltip />}
        <TablePagination
          rowsPerPageOptions={tableOptions.itemsPerPageOptions}
          component="div"
          count={totalItems}
          rowsPerPage={itemsPerPage}
          page={activePage}
          labelRowsPerPage={getTranslate()('tables.rows-per-page')}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${getTranslate()('tables.of')} ${count !== -1 ? count : to}`
          }
          nextIconButtonText={getTranslate()('tables.next-page')}
          backIconButtonText={getTranslate()('tables.previous-page')}
          nextIconButtonProps={{
            className: 'btns-icon theme-transparent mx-2',
          }}
          backIconButtonProps={{
            className: 'btns-icon theme-transparent mx-2',
          }}
          SelectProps={{
            className: 'select-wrapper',
          }}
          onChangePage={activePageChanged}
          onChangeRowsPerPage={itemsPerPageChanged}
          className="pagination-wrapper"
        />
      </div>
    );
  }
);
Tables.propTypes = {
  // isCollapsed: PropTypes.bool,
  tableOptions: PropTypes.shape({
    itemsPerPageOptions: PropTypes.array,
    tableSize: PropTypes.string,
    dateFormat: PropTypes.string,
    sortFrom: PropTypes.number,
  }),
  itemsPerPage: PropTypes.number,
  actionsOptions: PropTypes.shape({
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        enum: PropTypes.oneOf(Object.values(TableActions).map((item) => item.key)),
        isDisabled: PropTypes.bool,
      })
    ),
    externalComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
    classes: PropTypes.string,
    isDisabled: PropTypes.bool,
    isReverceDisabled: PropTypes.bool,
    actionsIsDisabledInput: PropTypes.string,
    onActionClicked: PropTypes.func,
  }),
  activePage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  wrapperClasses: PropTypes.string,
  responsiveWrapperClasses: PropTypes.string,
  selectAllOptions: PropTypes.shape({
    selectedRows: PropTypes.arrayOf(PropTypes.number),
    onSelectAllClicked: PropTypes.func,
    onSelectClicked: PropTypes.func,
    isSelectAll: PropTypes.bool,
    withCheckAll: PropTypes.bool,
    isDisableAll: PropTypes.bool,
    disabledRows: PropTypes.arrayOf(PropTypes.number),
  }),
  activePageChanged: PropTypes.func.isRequired,
  itemsPerPageChanged: PropTypes.func.isRequired,
  sortColumnClicked: PropTypes.func,
  headerData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      isSortable: PropTypes.bool,
      label: PropTypes.string,
      input: PropTypes.string,
      isDate: PropTypes.bool,
      dateFormat: PropTypes.string,
      component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
    })
  ),
  data: PropTypes.instanceOf(Array),
  footerData: PropTypes.arrayOf(
    PropTypes.shape({
      colSpan: PropTypes.number,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Date),
        PropTypes.bool,
      ]),
    })
  ),
  focusedRowChanged: PropTypes.func,
};
Tables.defaultProps = {
  // isCollapsed: false,
  wrapperClasses:'',
  responsiveWrapperClasses:'',
  tableOptions: {
    itemsPerPageOptions: [10, 20, 50, 100],
    tableSize: 'small',
    dateFormat: 'YYYY-MM-DD',
    sortFrom: 1, // 1:front,2:do nothing only send that it change
  },
  itemsPerPage: 10,
  actionsOptions: {
    actions: [
      {
        enum: TableActions.phone.key,
        isDisabled: false,
        externalComponent: null,
      },
      {
        enum: TableActions.email.key,
        isDisabled: false,
        externalComponent: null,
      },
      {
        enum: TableActions.whatsapp.key,
        isDisabled: false,
        externalComponent: null,
      },
    ],
    classes: '',
    isDisabled: false,
    isReverceDisabled: false,
    onActionClicked: () => {},
    actionsIsDisabledInput: null,
  },
  selectAllOptions: null,
  sortColumnClicked: () => {},
  headerData: [
    {
      id: 1,
      isSortable: true,
      label: 'First One',
      input: 'firstOne.nested',
      isDate: false,
    },
    {
      id: 2,
      isSortable: true,
      label: 'Second One',
      input: 'secondOne',
      isDate: true,
    },
  ],
  data: [
    { firstOne: { nested: 'First Row' }, secondOne: '2020/6/9' },
    { firstOne: { nested: 'Second Row' }, secondOne: '2020/6/8' },
  ],
  footerData: [],
  focusedRowChanged: () => {},
};
export { Tables };
