import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  Typography,
  Slider,
  Grid,
  Button,
} from '@material-ui/core';
// import 'date-fns';
import ChipInput from 'material-ui-chip-input';
// import { lookupGet } from '../../../../../Serviecs';
import {
  TitleRule,
  // ContactRule,
  // PropertyOrUnitRule,
  OnchangeCountryRule,
  OnchangeCityRule,
  // ContactDefaultRule,
  // PropertyDefaultRule,
  OnchangeDistrictRule,
  OnchangeCommunityRule,
  LookupRule,
  OnchangeSubCommunityRule,
} from '../../../../../Rule';
import { duplicatePhoneRole, PhoneValidationRole } from '../../../../../Rule/PhoneRule';
import { duplicateEmailRole } from '../../../../../Rule/EmailRule';
import PriceAndPercentage from '../PriceAndPercentage';
import { VisaRule } from '../../../../../Rule/VisaRule';
import { MapDialog } from '../../Dialogs/MapDialog';
import { UploadDialog } from '../../Dialogs/UploadDialog';
import { ModelsUnitsDialog } from '../../Dialogs/ModelsUnitsDialog';
import { RepeatedItemDialog } from '../../Dialogs/RepeatedItemDialog';
import {
  Inputs,
  AutocompleteComponent,
  PhonesComponent,
  CheckboxesComponent,
  DatePickerComponent,
} from '../../../../../Componentes';
// import { ContactsDialog } from '../../Dialogs/ContactsDialog';

const ConvertJson = ({ item, setData, setError, index, itemValue, itemList, selectedValues }) => {
  // debugger;
  const id = item.field.id.replace('-', '_');
  const type = item.field.FieldType;
  const label = item.data.title;
  const isRequired = item.field.Required.toUpperCase() === 'TRUE';
  // const errorMsg = item.data.errorMsg;
  const items = item.data.enum;
  const itemRegex = new RegExp(item.data.regExp);
  const [newValue, setNewValue] = useState(itemValue || item.data.valueToEdit || item.data.default);
  const [helperText, setHelperText] = useState('');
  const [errorMsg] = useState(item.data.errorMsg);
  // const [selectOptionsFromLookup, setSelectOptionsFromLookup] = useState([]);
  const [, setNewItems] = useState(
    items
      ? items.map((ele, itemIndex) => ({
          label: ele,
          value: itemIndex,
          checked: false,
        }))
      : null
  );

  const Input = React.useRef(null);
  const [, setLabelWidth] = useState(0);
  const [country] = useState('United Arab Emirates');

  // const fetchlookup = useCallback(async () => {
  //   const result = await lookupGet({ lookupType: item.data.api });
  //   if (result && Array.isArray(result) && selectOptionsFromLookup.length === 0)
  //     setSelectOptionsFromLookup(result.map((i) => i.name));
  // }, [item.data.api, selectOptionsFromLookup.length]);
  // useEffect(() => {
  //   if (item && item.data && item.data.api && selectOptionsFromLookup.length === 0) fetchlookup();
  // }, [item, item.data, item.data.api, selectOptionsFromLookup.length]);

  useEffect(() => {
    const countryItem =
      item.data &&
      item.data.enum &&
      item.data.enum.find(
        (element) =>
          element.lookupItemName && item.data && element.lookupItemName === item.data.default
      );
    if (itemValue) setData(index, itemValue);
    else if (item.data.valueToEdit) setData(index, item.data.valueToEdit);
    else if (item.data.default && countryItem) setData(index, countryItem);
    else if (item.data.default) setData(index, item.data.default);

    if (Input.current !== null) setLabelWidth(Input.current.offsetWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, item.data.default, item.data.valueToEdit, item.data.enum]);

  const [openMapDialog, setOpenMapDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [modelsUnitsDialog, setModelsUnitsDialog] = useState(false);
  const [RepeatedDialog, setRepeatedDialog] = useState(false);
  // const [isOpenContactsDialog, setIsOpenContactsDialog] = useState(false);

  // console.log(item);
  let component = <></>;
  switch (type) {
    case 'address':
      switch (item.data.uiType) {
        case 'text':
          component = (
            <Inputs
              idRef={id}
              isRequired={isRequired}
              isDisabled={item.data.isReadonly}
              labelValue={label}
              value={newValue ? newValue.value : ''}
              helperText={helperText}
              error={helperText !== '' || (isRequired && (!newValue || newValue.value === ''))}
              onInputChanged={(e) => {
                setHelperText('');
                setError(index, '');
                if (!itemRegex.test(e.target.value)) {
                  setHelperText(errorMsg);
                  setError(index, errorMsg);
                }
                setNewValue({ value: e.target.value, type: item.data.addressType });
                setData(index, { value: e.target.value, type: item.data.addressType });
                if (!duplicateEmailRole(item, itemList, selectedValues)) {
                  setHelperText('Duplicate Email Value');
                  setError(index, 'Duplicate Email Value');
                }
              }}
            />
          );
          break;
        case 'map':
          component = (
            <>
              <Inputs
                idRef={id}
                isRequired={isRequired}
                isDisabled
                labelValue={label}
                value={itemValue ? `${itemValue.latitude} , ${itemValue.longitude}` : ''}
                helperText={helperText}
                error={helperText !== '' || (isRequired && (!newValue || newValue === ''))}
                buttonOptions={{
                  className: 'btns-icon theme-outline c-blue-lighter',
                  iconClasses: 'mdi mdi-map-marker',
                  isDisabled: false,
                  isRequired: false,
                  onActionClicked: () => {
                    setOpenMapDialog(true);
                  },
                }}
              />
              {itemValue && (
                <MapDialog
                  open={openMapDialog}
                  onChange={(e) => {
                    setNewValue({ ...e });
                    setData(index, { ...e });
                  }}
                  initialState={itemValue}
                  closeDialog={() => {
                    setOpenMapDialog(false);
                  }}
                />
              )}
            </>
          );
          break;
        case 'select':
          LookupRule(item);
          component = (
            <AutocompleteComponent
              idRef={id}
              isRequired={isRequired}
              // disableClearable={isRequired}
              multiple={item.data.multi ? item.data.multi === 'true' : false}
              value={
                item.data.multi && item.data.multi === 'true'
                  ? (itemValue && [...itemValue]) || []
                  : itemValue || null
              }
              defaultValue={null}
              options={
                item.data.enum ||
                (item.data.multi && item.data.multi === 'true'
                  ? (itemValue && [...itemValue]) || []
                  : itemValue || null)
              }
              getOptionSelected={(option) =>
                (item.data.multi &&
                  item.data.multi === 'true' &&
                  itemValue.findIndex(
                    (element) =>
                      (element.lookupItemId && element.lookupItemId === option.lookupItemId) ||
                      option === element
                  ) !== -1) ||
                (itemValue.lookupItemId && itemValue.lookupItemId === option.lookupItemId) ||
                option.lookupItemName === item.data.default ||
                option === itemValue
              }
              getOptionLabel={(option) => option.lookupItemName || option}
              chipsLabel={(option) => option.lookupItemName || option}
              onChange={(e, v) => {
                console.log(item);
                if (v == null) {
                  setNewValue(v);
                  setData(index, v);
                  return;
                }
                OnchangeCountryRule(id, setData, item, itemList, v);
                OnchangeCityRule(id, v, itemList, setData, item);
                OnchangeDistrictRule(id, setData, item, itemList, v);
                OnchangeCommunityRule(id, setData, item, itemList, v);
                OnchangeSubCommunityRule(id, setData, item, itemList, v);
                const vUpdate = v;
                vUpdate.addressType = item.data.addressType;
                setNewValue(vUpdate);
                setData(index, vUpdate);
              }}
              withoutSearchButton
              isDisabled={
                item.data.isReadonly || item.field.id === 'country' || item.field.id === 'city'
              }
              variant="standard"
              labelValue={label}
            />
          );
          break;
        default:
          break;
      }
      break;
    case 'communication':
      switch (item.data.CommunicationType) {
        case 'Phone':
          component = (
            <>
              <PhonesComponent
                idRef={id}
                isValid={() =>
                  !duplicatePhoneRole(type, item, itemList, selectedValues) ||
                  PhoneValidationRole(newValue ? newValue.phone : '')
                }
                labelValue={label}
                country={country}
                required={isRequired}
                value={
                  newValue === null || newValue === undefined || !newValue.phone
                    ? item.data.defaultCountryCode
                    : newValue.phone
                }
                onInputChanged={(value) => {
                  if (value.length > 14) return;
                  if (selectedValues[index]) {
                    setNewValue({
                      ...selectedValues[index],
                      phone: value,
                      typeId: item.data.lookupItem,
                    });
                    setData(index, {
                      ...selectedValues[index],
                      phone: value,
                      typeId: item.data.lookupItem,
                    });
                  } else {
                    setNewValue({ phone: value, typeId: item.data.lookupItem });
                    setData(index, { phone: value, typeId: item.data.lookupItem });
                  }
                }}
                buttonOptions={{
                  className: 'btns-icon theme-solid bg-blue-lighter',
                  iconClasses: 'mdi mdi-plus',
                  isDisabled: !(newValue && newValue.phone && newValue.phone.length >= 9),
                  isRequired: false,
                  onActionClicked: () => {
                    if (newValue && newValue.phone && newValue.phone.length >= 9)
                      setRepeatedDialog(true);
                  },
                }}
              />
              <RepeatedItemDialog
                open={RepeatedDialog}
                item={item}
                type="phone"
                initialState={newValue}
                label={label}
                closeDialog={() => {
                  setRepeatedDialog(false);
                }}
                selectedValues={selectedValues}
                itemList={itemList}
                onChange={(value) => {
                  setNewValue({ ...value, phone: newValue.phone, typeId: item.data.lookupItem });
                  setData(index, {
                    ...value,
                    phone: newValue.phone,
                    typeId: item.data.lookupItem,
                  });
                }}
              />
            </>
          );
          break;
        case 'Email':
          component = (
            <>
              <Inputs
                idRef={id}
                isRequired={isRequired}
                isDisabled={item.data.isReadonly}
                labelValue={label}
                value={newValue && newValue.email ? newValue.email : ''}
                helperText={helperText}
                error={helperText !== '' || (isRequired && (!newValue || newValue === ''))}
                onInputChanged={(e) => {
                  setHelperText('');
                  setError(index, '');
                  if (!itemRegex.test(e.target.value)) {
                    setHelperText(errorMsg);
                    setError(index, errorMsg);
                  }
                  if (selectedValues[index]) {
                    setNewValue({
                      ...selectedValues[index],
                      email: e.target.value,
                      typeId: item.data.lookupItem,
                    });
                    setData(index, {
                      ...selectedValues[index],
                      email: e.target.value,
                      typeId: item.data.lookupItem,
                    });
                  } else {
                    setNewValue({ email: e.target.value, typeId: item.data.lookupItem });
                    setData(index, { email: e.target.value, typeId: item.data.lookupItem });
                  }

                  if (!duplicateEmailRole(item, itemList, selectedValues)) {
                    setHelperText('Duplicate Email Value');
                    setError(index, 'Duplicate Email Value');
                  }
                }}
                buttonOptions={{
                  className: 'btns-icon theme-solid bg-blue-lighter',
                  iconClasses: 'mdi mdi-plus',
                  isDisabled: !(
                    newValue &&
                    itemRegex.test(newValue.email) &&
                    newValue.email &&
                    newValue.email.length > 0
                  ),
                  isRequired: false,
                  onActionClicked: () => {
                    if (
                      newValue &&
                      itemRegex.test(newValue.email) &&
                      newValue.email &&
                      newValue.email.length > 0
                    )
                      setRepeatedDialog(true);
                  },
                }}
              />
              <RepeatedItemDialog
                open={RepeatedDialog}
                item={item}
                type="email"
                initialState={newValue}
                label={label}
                closeDialog={() => {
                  setRepeatedDialog(false);
                }}
                selectedValues={selectedValues}
                itemList={itemList}
                onChange={(value) => {
                  setNewValue({ ...value, email: newValue.email, typeId: item.data.lookupItem });
                  setData(index, {
                    ...value,
                    email: newValue.email,
                    typeId: item.data.lookupItem,
                  });
                }}
              />
            </>
          );
          break;
        case 'SocialMedia':
          component = (
            <Inputs
              idRef={id}
              isRequired={isRequired}
              isDisabled={item.data.isReadonly}
              labelValue={label}
              value={newValue && newValue.link ? newValue.link : ''}
              helperText={helperText}
              error={helperText !== '' || (isRequired && (!newValue || newValue === ''))}
              onInputChanged={(e) => {
                setHelperText('');
                setError(index, '');
                if (!itemRegex.test(e.target.value)) {
                  setHelperText(errorMsg);
                  setError(index, errorMsg);
                }
                setNewValue({ link: e.target.value, typeId: item.data.lookupItem });
                setData(index, { link: e.target.value, typeId: item.data.lookupItem });
              }}
            />
          );
          break;
        default:
          break;
      }
      break;
    case 'UnitsModels':
      component = (
        <Grid container>
          <div className="w-100">
            <label htmlFor={id} className="texts-form">
              {label}
            </label>
            <div className="chip-input-wrapper">
              <ChipInput
                className="chip-input theme-form-builder"
                id={id}
                value={newValue.Models ? newValue.Models : []}
                onAdd={(chip) => {
                  if (newValue.Models) {
                    setNewValue({ ...newValue, Models: [...newValue.Models, chip] });
                    setData(index, { ...newValue });
                  } else {
                    setNewValue({ Models: [chip] });
                    setData(index, { ...newValue });
                  }
                }}
                onDelete={(chip, itemIndex) => {
                  if (newValue.Models) newValue.Models.splice(itemIndex, 1);
                  delete newValue[chip];
                  setNewValue({ ...newValue });
                  setData(itemIndex, { ...newValue });
                }}
                variant="standard"
              />
              <Button
                className="ml-2 btns-icon theme-solid bg-blue-lighter"
                onClick={() => {
                  if (newValue.Models && newValue.Models.length > 0) setModelsUnitsDialog(true);
                }}
              >
                <span className="mdi mdi-plus" />
              </Button>
            </div>
          </div>
          {/* <Grid item xs={12} md={1}> */}
          {newValue.Models && (
            <ModelsUnitsDialog
              initialState={newValue}
              items={items}
              open={modelsUnitsDialog}
              closeDialog={() => {
                setModelsUnitsDialog(false);
              }}
              onChange={(value) => {
                setData(index, { ...value, Models: newValue.Models });
                setNewValue({ ...value, Models: newValue.Models });
              }}
            />
          )}
          {/* </Grid> */}
        </Grid>
      );
      break;
    case 'UploadFiles':
      LookupRule(item);
      component = (
        <>
          <AutocompleteComponent
            idRef={id}
            isRequired={isRequired}
            // disableClearable={isRequired}
            multiple={item.data.multi ? item.data.multi === 'true' : false}
            selectedValues={
              item.data.multi && item.data.multi === 'true'
                ? (itemValue && [...itemValue.selected]) || []
                : itemValue || null
            }
            defaultValue={null}
            options={item && item.data ? item.data.enum : []}
            getOptionSelected={(option) =>
              (item.data.multi &&
                item.data.multi === 'true' &&
                ((itemValue && [...itemValue.selected]) || []).findIndex(
                  (element) =>
                    (element.lookupItemId && element.lookupItemId === option.lookupItemId) ||
                    option === element
                ) !== -1) ||
              (itemValue.lookupItemId && itemValue.lookupItemId === option.lookupItemId) ||
              option === itemValue
            }
            getOptionLabel={(option) => option.lookupItemName || option}
            chipsLabel={(option) => option.lookupItemName || option}
            onChange={(e, v) => {
              if (v == null) {
                setNewValue(v);
                setData(index, v);
                return;
              }
              setNewValue({ ...itemValue, selected: v });
              setData(index, { ...itemValue, selected: v });
            }}
            withoutSearchButton
            variant="standard"
            labelValue={label}
            buttonOptions={{
              className: 'btns-icon theme-solid bg-blue-lighter',
              iconClasses: 'mdi mdi-attachment',
              isDisabled: !(itemValue && itemValue.selected && itemValue.selected.length > 0),
              isRequired: false,
              onActionClicked: () => {
                if (itemValue && itemValue.selected && itemValue.selected.length > 0)
                  setOpenUploadDialog(true);
              },
            }}
          />
          {itemValue && itemValue.selected && itemValue.selected.length > 0 && (
            <UploadDialog
              open={openUploadDialog}
              onChange={(e) => {
                setNewItems(e);
                setData(index, { ...e, selected: itemValue.selected });
              }}
              initialState={itemValue}
              closeDialog={() => {
                setOpenUploadDialog(false);
              }}
            />
          )}
        </>
      );
      break;
    case 'MapField':
      component = (
        <Grid container>
          <Inputs
            idRef={id}
            isRequired={isRequired}
            isDisabled
            labelValue={label}
            value={itemValue ? `${itemValue.latitude} , ${itemValue.longitude}` : ''}
            helperText={helperText}
            error={helperText !== '' || (isRequired && (!newValue || newValue === ''))}
            buttonOptions={{
              className: 'btns-icon theme-outline c-blue-lighter',
              iconClasses: 'mdi mdi-map-marker',
              isDisabled: false,
              isRequired: false,
              onActionClicked: () => {
                setOpenMapDialog(true);
              },
            }}
          />
          {itemValue && (
            <MapDialog
              open={openMapDialog}
              onChange={(e) => {
                setNewValue({ ...e });
                setData(index, { ...e });
              }}
              initialState={itemValue}
              closeDialog={() => {
                setOpenMapDialog(false);
              }}
            />
          )}
        </Grid>
      );

      break;
    case 'textField':
      component = (
        <Inputs
          idRef={id}
          isRequired={isRequired}
          isDisabled={item.data.isReadonly}
          labelValue={label}
          value={itemValue || ''}
          helperText={helperText}
          error={helperText !== '' || (isRequired && (!itemValue || itemValue === ''))}
          onInputChanged={(e) => {
            setHelperText('');
            setError(index, '');
            if (!itemRegex.test(e.target.value)) {
              setHelperText(errorMsg);
              setError(index, errorMsg);
            }
            setNewValue(e.target.value);
            setData(index, e.target.value);
            if (!duplicateEmailRole(item, itemList, selectedValues)) {
              setHelperText('Duplicate Email Value');
              setError(index, 'Duplicate Email Value');
            }
          }}
        />
      );
      break;
    case 'textarea':
      component = (
        <Inputs
          idRef={id}
          isRequired={isRequired}
          isDisabled={item.data.isReadonly}
          labelValue={label}
          value={newValue}
          helperText={errorMsg}
          error={errorMsg !== '' || (isRequired && (!newValue || newValue === ''))}
          multiline
          rows={4}
          onInputChanged={(e) => {
            setNewValue(e.target.value);
            setData(index, e.target.value);
          }}
        />
      );
      break;
    case 'select':
      LookupRule(item);
      component = (
        <AutocompleteComponent
          idRef={id}
          isRequired={isRequired}
          // disableClearable={isRequired}
          multiple={item.data.multi ? item.data.multi === 'true' : false}
          getOptionSelected={(option) =>
            (item.data.multi &&
              item.data.multi === 'true' &&
              itemValue.findIndex(
                (element) =>
                  (element.lookupItemId && element.lookupItemId === option.lookupItemId) ||
                  option === element
              ) !== -1) ||
            (itemValue.lookupItemId && itemValue.lookupItemId === option.lookupItemId) ||
            option === itemValue
          }
          value={
            item.data.multi && item.data.multi === 'true'
              ? (itemValue && [...itemValue]) || []
              : itemValue || null
          }
          // defaultValue={itemValue}
          options={item.data.enum}
          getOptionLabel={(option) => option.lookupItemName || option}
          chipsLabel={(option) => option.lookupItemName || option}
          onChange={(event, v) => {
            if (v == null) {
              setNewValue(v);
              setData(index, v);
              return;
            }
            TitleRule(id, setData, item, itemList, v);
            OnchangeCountryRule(id, setData, item, itemList, v);
            OnchangeCityRule(id, v, itemList, setData, item);
            OnchangeDistrictRule(id, setData, item, itemList, v);
            OnchangeCommunityRule(id, setData, item, itemList, v);
            OnchangeSubCommunityRule(id, setData, item, itemList, v);
            setNewValue(v);
            setData(index, v);
          }}
          withoutSearchButton
          isDisabled={item.data.isReadonly}
          variant="standard"
          labelValue={label}
        />
      );
      break;

    case 'alt-date':
      if (newValue === undefined) setNewValue(null);

      component = (
        <DatePickerComponent
          required={isRequired}
          idRef={id}
          labelValue={label}
          value={newValue}
          helperText={errorMsg}
          maxDate={item.data.maxDate ? new Date(item.data.maxDate) : undefined}
          minDate={item.data.minDate ? new Date(item.data.minDate) : undefined}
          onDateChanged={(e) => {
            VisaRule(id, item, itemList, e);
            setNewValue(e);
            setData(index, e);
          }}
        />
      );
      break;

    case 'checkboxes': {
      let result = [];
      if (newValue) result = [...newValue];
      component = (
        <CheckboxesComponent
          data={item.data.items.enum || []}
          onSelectedCheckboxChanged={(e) => {
            if (e.target.checked && result.indexOf(e.target.value) === -1)
              result.push(e.target.value);
            else result.splice(result.indexOf(e.target.value), 1);
            setNewValue(result);
            setData(index, result);
          }}
          isRow
          idRef={id}
          labelValue={label}
          required={isRequired}
          checked={(selectedItem) => result.indexOf(selectedItem) !== -1}
        />
      );
      break;
    }
    case 'radio':
      component = (
        <div>
          <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup id={id} value={newValue} onChange={(e) => setData(index, e.target.value)}>
              {item.data.enum.map((ele) => (
                <FormControlLabel value={ele} control={<Radio />} label={ele} />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      );
      break;

    case 'checkbox':
      component = (
        <CheckboxesComponent
          data={[newValue]}
          onSelectedCheckboxChanged={(e) => {
            setData(index, e.target.checked);
          }}
          isRow
          labelValue={label}
          idRef={id}
          value={newValue}
        />
      );
      break;

    case 'phone':
      component = (
        <>
          <PhonesComponent
            idRef={id}
            isValid={() =>
              !duplicatePhoneRole(type, item, itemList, selectedValues) ||
              PhoneValidationRole(newValue ? newValue.phone : '')
            }
            labelValue={label}
            country={country}
            required={isRequired}
            value={
              newValue === null || newValue === undefined || !newValue.phone
                ? item.data.defaultCountryCode
                : newValue.phone
            }
            onInputChanged={(value) => {
              if (value.length > 14) return;
              setNewValue({ phone: value, typeId: item.data.lookupItem });
              setData(index, { phone: value, typeId: item.data.lookupItem });
            }}
            buttonOptions={{
              className: 'btns-icon theme-solid bg-blue-lighter',
              iconClasses: 'mdi mdi-plus',
              isDisabled: !(newValue && newValue.phone && newValue.phone.length >= 9),
              isRequired: false,
              onActionClicked: () => {
                if (newValue && newValue.phone && newValue.phone.length >= 9)
                  setRepeatedDialog(true);
              },
            }}
          />
          <RepeatedItemDialog
            open={RepeatedDialog}
            item={item}
            type={type}
            initialState={newValue}
            label={label}
            closeDialog={() => {
              setRepeatedDialog(false);
            }}
            selectedValues={selectedValues}
            itemList={itemList}
            onChange={(value) => {
              setNewValue({ ...value, phone: newValue.phone });
              setData(index, { ...value, phone: newValue.phone });
            }}
          />
        </>
      );
      break;

    case 'rangeField': {
      const marks = [
        {
          value: Number(item.data.minNumber),
          label: `${Number(item.data.minNumber)}`,
        },

        {
          value: Number(item.data.maxNumber),
          label: `${Number(item.data.maxNumber)}`,
        },
      ];
      component = (
        <>
          <Typography gutterBottom>{label}</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xl={6} lg={6} sm={6} xs={12}>
              <Inputs
                idRef={id}
                labelValue="From"
                value={newValue ? newValue[0] : Number(item.data.minNumber)}
                onInputChanged={(e) => {
                  if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                    const v = newValue || [];
                    v[0] = Number(e.target.value);
                    setNewValue(v);
                    setData(index, v);
                  }
                }}
                onInputBlur={(e) => {
                  if (Number(e.target.value) < Number(item.data.minNumber)) {
                    const v = newValue || [];
                    v[0] = Number(item.data.minNumber);
                    setNewValue(v);
                    setData(index, v);
                  } else if (Number(e.target.value) > Number(item.data.maxNumber)) {
                    const v = newValue || [];
                    v[0] = Number(item.data.maxNumber);
                    setNewValue(v);
                    setData(index, v);
                  } else {
                    const v = newValue || [];
                    v[0] = Number(e.target.value);
                    setNewValue(v);
                    setData(index, v);
                  }
                }}
              />
            </Grid>
            <Grid item xl={6} lg={6} sm={6} xs={12}>
              <Inputs
                idRef={id}
                labelValue="To"
                value={newValue ? newValue[1] : Number(item.data.maxNumber)}
                onInputChanged={(e) => {
                  if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                    const v = newValue || [];
                    v[1] = Number(e.target.value);
                    setNewValue(v);
                    setData(index, v);
                  }
                }}
                onInputBlur={(e) => {
                  if (Number(e.target.value) < Number(item.data.minNumber)) {
                    const v = newValue || [];
                    v[1] = Number(marks[1].value);

                    setNewValue(v);
                    setData(index, v);
                  } else if (Number(e.target.value) > Number(item.data.maxNumber)) {
                    const v = newValue || [];
                    v[1] = Number(item.data.maxNumber);
                    setNewValue(v);
                    setData(index, v);
                  } else {
                    const v = newValue || [];
                    v[1] = Number(e.target.value);
                    setNewValue(v);
                    setData(index, v);
                  }
                }}
              />
            </Grid>
          </Grid>
          <div className="w-100 px-3">
            <Slider
              required={isRequired}
              className="sliders"
              aria-labelledby="range-slider"
              valueLabelDisplay="auto"
              onChange={(event, value) => {
                if (value[0] !== newValue[0] || value[1] !== newValue[1]) {
                  setNewValue(value);
                  setData(index, value);
                }
              }}
              value={
                newValue
                  ? [Number(newValue[0]), Number(newValue[1])]
                  : [Number(item.data.minNumber), Number(item.data.maxNumber)]
              }
              step={200}
              marks={marks}
              min={Number(item.data.minNumber)}
              max={Number(item.data.maxNumber)}
            />
          </div>
        </>
      );
      break;
    }
    // case 'searchField':
    //   ContactDefaultRule(item, setNewValue);
    //   PropertyDefaultRule(item, setNewValue);
    //   component = (
    //     <>
    //       <AutocompleteComponent
    //         idRef={id}
    //         isRequired={isRequired}
    //         // disableClearable={isRequired}
    //         multiple={false}
    //         selectedValues={itemValue || null}
    //         defaultValue={undefined}
    //         data={item.data.enum || []}
    //         inputClasses="inputs theme-form-builder"
    //         displayLabel={(option) => (option ? option.name : '')}
    //         onChange={(e, v) => {
    //           setNewValue(v);
    //           setData(index, v);
    //         }}
    //         onInputChange={(e, v) => {
    //           ContactRule(item, v, setNewValue);
    //           PropertyRule(item, v, setNewValue);
    //           PropertyOrUnitRule(item, v, setNewValue, itemList, selectedValues);
    //         }}
    //         withoutSearchButton
    //         wrapperClasses="input-wrapper flex-wrap"
    //         isDisabled={item.data.isReadonly}
    //         variant="standard"
    //         labelValue={label}
    //         buttonOptions={{
    //           className: 'btns-icon theme-outline c-blue-lighter',
    //           iconClasses: itemValue ? 'mdi mdi-eye-outline' : 'mdi mdi-plus',
    //           isDisabled: false,
    //           isRequired: false,
    //           onActionClicked: itemValue
    //             ? () => {
    //                 const itemIndex = itemList.findIndex(
    //                   (effectedItem) =>
    //                     effectedItem.data.title.replace('*', '').trim() === item.data.dependOn
    //                 );
    //                 let l = {};
    //                 if (selectedValues[itemIndex])
    //                   l = { ...itemValue, itemId: selectedValues[itemIndex] };
    //                 else l = { ...itemValue, itemId: item.data.searchKey };
    //                 if (l.itemId && l.itemId.toLowerCase() === 'contact') l.itemId = CONTACTS;
    //                 if (l.itemId && l.itemId.toLowerCase() === 'property') {
    //                   l.itemId = PROPERTIES;
    //                   l.type = '1';
    //                 }
    //                 if (l.itemId && l.itemId.toLowerCase() === 'unit') l.itemId = UNITS;

    //                 localStorage.setItem('current', JSON.stringify(l));

    //                 setIsOpenContactsDialog(true);
    //               }
    //             : () => {
    //                 const itemIndex = itemList.findIndex(
    //                   (effectedItem) =>
    //                     effectedItem.data.title.replace('*', '').trim() === item.data.dependOn
    //                 );

    //                 let l = {};
    //                 if (selectedValues[itemIndex]) l = { itemId: selectedValues[itemIndex] };
    //                 else l = { itemId: item.data.searchKey };

    //                 if (item.data.dependOn && !selectedValues[itemIndex]) return;

    //                 if (l.itemId && l.itemId.toLowerCase() === 'contact') l.itemId = CONTACTS;

    //                 if (l.itemId && l.itemId.toLowerCase() === 'property') {
    //                   l.itemId = PROPERTIES;
    //                   l.type = '1';
    //                 }
    //                 if (l.itemId && l.itemId.toLowerCase() === 'unit') l.itemId = UNITS;

    //                 localStorage.setItem('current', JSON.stringify(l));

    //                 setIsOpenContactsDialog(true);
    //               },
    //         }}
    //       />
    //     </>
    //   );
    //   break;

    case 'PriceAndPercentage':
      component = (
        <>
          <PriceAndPercentage
            type={item.data.controlType}
            currency="AED"
            value={newValue}
            onChange={(e) => {
              if (e === null) return;
              setNewValue(e);
              setData(index, e);
            }}
          />
        </>
      );
      break;

    default:
      break;
  }

  return component;
};
export default ConvertJson;
