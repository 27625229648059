import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Masonry from 'masonry-layout';

export const MasonryComponent = ({ children, itemClassName, customMonitor, executeDelay }) => {
  const masonryWrapperRef = useRef(null);
  const masonryRef = useRef(null);
  useEffect(() => {
    if (!masonryWrapperRef.current || (!itemClassName && (!children || children.length === 0)))
      return;
    masonryRef.current = new Masonry(masonryWrapperRef.current, {
      itemSelector: `.${itemClassName || children[0].props.className}`,
      gutter: 0,
      // fitWidth: true,
      horizontalOrder: true,
      percentPosition: true,
    });
    setTimeout(() => {
      masonryRef.current.layout();
    }, executeDelay);
  }, [children, itemClassName, customMonitor, executeDelay]);
  return (
    <div className='masonry-wrapper' ref={masonryWrapperRef}>
      {children}
    </div>
  );
};

MasonryComponent.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  itemClassName: PropTypes.string,
  customMonitor: PropTypes.instanceOf(Array),
  executeDelay: PropTypes.number,
};
MasonryComponent.defaultProps = {
  itemClassName: undefined,
  customMonitor: undefined,
  executeDelay: 0,
};
