import React, { useReducer } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Step,
  StepButton,
  Stepper,
  TextField,
} from '@material-ui/core';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone-uploader';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getDownloadableLink } from '../../../../Helpers';
import config from '../../../../Assets/Configration/Configration.json';

export function ModelsUnitsDialog(props) {
  const reducer = (state, action) => ({ ...state, [action.id]: action.value });
  const [state, setState] = useReducer(reducer, props.initialState);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});
  const totalSteps = () => props.initialState.Models.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          props.initialState.selected.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('file', file);

    return {
      url: `${config.serverAddress}/FileManager/File/Upload`,
      method: 'POST',
      body,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('session')).token}`,
      },
    };
  };

  const handleChangeStatus = (e, status, lable) => {
    if (status === 'done') {
      if (state[lable] && state[lable].files) {
        const data = JSON.parse(e.xhr.response);
        setState({ id: lable, value: { ...state[lable], files: [...state[lable].files, data] } });
      } else {
        const data = JSON.parse(e.xhr.response);
        setState({ id: lable, value: { ...state[lable], files: [data] } });
      }
    }
  };

  return (
    <>
      <Dialog open={props.open} className='dialog' maxWidth='lg'>
        <DialogTitle> Models Units </DialogTitle>
        <DialogContent>
          <div className='w-100'>
            <Stepper nonLinear activeStep={activeStep}>
              {props.initialState.Models.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={handleStep(index)} completed={completed[index]}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>

          {props.initialState.Models.map(
            (label) =>
              props.initialState.Models[activeStep] === label && (
                <Grid container spacing={5}>
                  {props.items.map((value) => (
                    <Grid item xs={12} md={6}>
                      <TextField
                        id={value}
                        style={{ width: '100%' }}
                        label={value}
                        variant='outlined'
                        value={
                          state && state[label] && state[label][value] ? state[label][value] : ''
                        }
                        onChange={(e) => {
                          const data = {};
                          data[value] = e.target.value;
                          //  console.log(data);
                          setState({ id: label, value: { ...state[label], ...data } });
                        }}
                      />
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <Dropzone
                      getUploadParams={getUploadParams}
                      onChangeStatus={(e, status) => {
                        handleChangeStatus(e, status, label);
                      }}
                      inputContent='Drag Files'
                      styles={{ dropzone: { minHeight: 100, maxHeight: 150 } }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className='mxh-150P h-100P attachment'>
                      {state[label] &&
                        state[label].files &&
                        state[label].files.map((value) => (
                          <Grid container>
                            <Grid item xs={12} md={10}>
                              {value.fileName}{' '}
                            </Grid>
                            <Grid item xs={12} md={2} className='text-align-right'>
                              <IconButton
                                color='primary'
                                onClick={() => {
                                  const a = document.createElement('a');
                                  a.style.display = 'none';
                                  a.href = getDownloadableLink(value.uuid);
                                  a.target = '_blank';
                                  // the filename you want
                                  a.download = value.fileName;
                                  document.body.appendChild(a);
                                  a.click();
                                }}>
                                <GetAppIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                    </div>
                  </Grid>
                </Grid>
              )
          )}
        </DialogContent>

        <DialogActions>
          {activeStep !== 0 && (
            <Button
              variant='outline'
              onClick={() => {
                handleBack();
              }}
              color='primary'>
              Back
            </Button>
          )}
          {!isLastStep() && (
            <Button
              variant='outline'
              onClick={() => {
                handleNext();
              }}
              color='primary'>
              Next
            </Button>
          )}

          {isLastStep() && (
            <Button
              variant='outline'
              onClick={() => {
                props.onChange(state);
                props.closeDialog();
              }}
              color='primary'>
              Save
            </Button>
          )}
          <Button
            variant='outline'
            onClick={() => {
              props.closeDialog();
            }}
            color='secondary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
