import React, { useEffect, useState, useRef, useCallback } from 'react';
import Joi from 'joi';
import 'cropperjs/dist/cropper.min.css';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import moment from 'moment';
import { getDownloadableLink } from '../../../../../Helpers';
import '../../../SliderView/SliderViewUtilities/SliderViewDialogs/SliderDialogs.scss';
import { showSuccess, getTranslate, getErrorByName, showError } from '../../../../../Helpers';
import { AutocompleteComponent, CropperComponent, Inputs } from '../../../../../Componentes';
import {
  CreateAdvertisements,
  UploadAdsImage,
  EditAdvertisements,
} from '../../../../../Serviecs/AdsViewServices/AdsViewServices';
import {
  Grid,
  Button,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Dialog,
  FormControl,
  Typography,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import { DateRange } from 'react-date-range';
import { GetAllReadyTours, GetReadyTourById } from '../../../../../Serviecs';

const AdsViewDialog = (props) => {
  const [radioValue, setRadioValue] = useState('1');
  const [adName, setAdName] = useState('');
  const [uploadImage, setUploadImage] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [result, setResult] = useState('');
  const [editImage, setEditImage] = useState(false);
  const [adLink, setAdLink] = useState('');
  const [tourResponse, setTourResponse] = useState({});
  const [tourInfo, setTourInfo] = useState(null);
  const [viewUploadeImage, setviewUploadeImage] = useState('');
  const [advertisementType, setAdvertisementType] = useState('0');
  const [croppedImage, setCroppedImage] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [filter, setFilter] = useState({
    pageSize: 10,
    pageIndex: 0,
    search: '',
  });
  const hiddenFileInput = useRef(null);
  const time = useRef(null);
  const translationPath = 'Advertisements.';
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateState, setDateState] = useState([
    {
      startDate: moment().toDate(),
      endDate: moment().add(7, 'd').toDate(),
      key: 'selection',
    },
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const schema = Joi.object({
    adName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}ad-name-is-required`),
      }),
    uploadImage: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}image-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ adName, uploadImage });

  const handleTourResponse = useCallback(async () => {
    const res = await GetAllReadyTours(filter);
    setTourResponse(res);
  }, [filter]);
  const getTourById = useCallback(async (id) => {
    const res = await GetReadyTourById(id);
    setTourResponse(res);
    setTourInfo(res);
  }, []);
  const searchHandler = (e) => {
    const value = e.target.value;
    if (time.current) clearTimeout(time.current);
    time.current = setTimeout(() => {
      setFilter((items) => ({ ...items, search: value }));
    }, 700);
  };
  useEffect(() => {
    handleTourResponse();
  }, [filter, handleTourResponse]);

  let defaultValue =
    tourResponse &&
    tourResponse.result &&
    tourResponse.result.filter((el) => props.adsInfo.tourId === el.tourId);

  const handleCancelBtn = () => {
    props.setOpen(false);
    props.reloadData();
    if (!props.isEdit) {
      setAdName('');
      setRadioValue('1');
      setUploadImage('');
      defaultValue = [];
      setResult('');
      setEditImage(false);
      setAdLink('');
      setAdvertisementType('0');
      setIsDefault(false);
      setCroppedImage(null);
    }
  };

  const selectedFileHandler = async (e) => {
    setResult('');
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    setUploadImage(e.target.files[0].name);
    setResult(await UploadAdsImage(formData));
  };

  const viewimage = (e) => {
    selectedFileHandler(e);
    const files = e.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      const dataURL = e.target.result;
      setviewUploadeImage(dataURL);
    };
    setEditImage(true);
  };

  const handleDoneBtn = async () => {
    setIsSubmitted(true);
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }

    if (props.isEdit) {
      const res = await EditAdvertisements(props.adsInfo.advertisementId, {
        advertisementName: adName,
        advertisementImageFileId: croppedImage ? croppedImage.uuid : result,
        advertisementType: +advertisementType,
        advertisementLink: adLink,
        tourId: (tourInfo && tourInfo.tourId ? tourInfo.tourId : props.adsInfo.tourId) || null,
        advertisementFromtDate: moment(dateState[0].startDate).format('YYYY-MM-DDTHH:mm:ss'),
        advertisementToDate: moment(dateState[0].endDate).format('YYYY-MM-DDTHH:mm:ss'),
        isDefault: isDefault,
        isActive: radioValue === '1' ? true : false,
      });

      if (res) {
        showSuccess(`${getTranslate()(`${translationPath}AdvertisementEditedSuccessfully`)}`);
        props.setReloaded(true);
        setAdvertisementType('0');
        props.setOpen(false);
        setCroppedImage(null);
        props.reloadData();
      }
    } else {
      const res = await CreateAdvertisements({
        advertisementName: adName,
        advertisementImageFileId: croppedImage.uuid,
        advertisementType: +advertisementType,
        advertisementLink: adLink,
        tourId: tourInfo && tourInfo.tourId ? tourInfo.tourId : '',
        advertisementFromtDate: moment(dateState[0].startDate).format('YYYY-MM-DDTHH:mm:ss'),
        advertisementToDate: moment(dateState[0].endDate).format('YYYY-MM-DDTHH:mm:ss'),
        isDefault: isDefault,
        isActive: radioValue === '1' ? true : false,
      });

      if (res) {
        showSuccess(`${getTranslate()(`${translationPath}AdvertisementCreatedSuccessfully`)}`);
        setAdName('');
        setCroppedImage(null);
        setRadioValue('1');
        setUploadImage('');
        setAdvertisementType('0');
        setResult('');
        setAdLink('');
        setTourResponse('');
        setEditImage(false);
        setIsDefault(false);
        props.setReloaded(true);
        setAdvertisementType('0');
        props.setOpen(false);
        setCroppedImage(null);
        props.reloadData();
      }
    }
  };

  useEffect(() => {
    if (props.isEdit) {
      setEditImage(true);
      setAdName(props.adsInfo.advertisementName);
      setRadioValue(props.adsInfo.isActive === true ? '1' : '0');
      setIsDefault(props.adsInfo.isDefault);
      setResult(props.adsInfo.advertisementImageFileId);
      setAdvertisementType(props.adsInfo.advertisementType + '');
      setUploadImage('Edit Image');
      if (props.adsInfo && props.adsInfo.tourId) {
        getTourById(props.adsInfo.tourId);
      }
      // setFilter((items)=>{})
      setAdLink(props.adsInfo.advertisementLink);
      setDateState([
        {
          startDate: moment(props.adsInfo.advertisementFromtDate, 'YYYY-MM-DDTHH:mm:ss').toDate(),
          endDate: moment(props.adsInfo.advertisementToDate, 'YYYY-MM-DDTHH:mm:ss').toDate(),
          key: 'selection',
        },
      ]);
    } else {
      setDateState([
        {
          startDate: moment().toDate(),
          endDate: moment().add(7, 'd').toDate(),
          key: 'selection',
        },
      ]);
      setAdName('');
      setUploadImage('');
      setCroppedImage(null);
      setRadioValue('1');
      setAdvertisementType('0');
      setResult('');
      setEditImage(false);
    }
    props.setReloaded(false);
  }, [
    getTourById,
    props,
    props.adsInfo.advertisementFromtDate,
    props.adsInfo.advertisementImageFileId,
    props.adsInfo.advertisementName,
    props.adsInfo.advertisementToDate,
    props.adsInfo.advertisementType,
    props.adsInfo.isActive,
    props.adsInfo.isDefault,
    props.adsInfo.tourId,
    props.isEdit,
  ]);
  useEffect(() => {
    return () => {
      if (time.current) clearTimeout(time.current);
    };
  }, []);
  return (
    <>
      <Dialog
        open={props.open}
        className='sliderDialogs'
        maxWidth={editImage && result ? 'md' : 'sm'}>
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <DialogTitle className='dialogTitle'>
            <Typography>{props.isEdit ? 'Edit Advertisment' : 'Add New Advertisment'}</Typography>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={editImage && result ? 6 : 12}
                container
                justify='center'
                alignItems='center'>
                <Grid item xs={8} className='mb-3'>
                  <FormControl className='input-wrapper flex-wrap textField'>
                    <Inputs
                      helperText={getErrorByName(schema, 'adName').message}
                      error={getErrorByName(schema, 'adName').error}
                      isWithError
                      isSubmitted={isSubmitted}
                      idRef='Ad Name'
                      labelValue={getTranslate()(`${translationPath}AdName`)}
                      value={adName}
                      onInputChanged={(e) => setAdName(e.target.value)}
                      inputPlaceholder={getTranslate()(`${translationPath}AdName`)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl className='input-wrapper flex-wrap textField selectData'>
                    <Typography>{getTranslate()(`${translationPath}Date`)}</Typography>
                    <TextField
                      variant='outlined'
                      fullWidth
                      value={
                        dateState &&
                        dateState[0] &&
                        `From: ${moment(dateState[0].startDate).format('DD/MM/yyyy')}, To: ${moment(
                          dateState[0].endDate
                        ).format('DD/MM/yyyy')}`
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <DateRangeRoundedIcon />
                          </InputAdornment>
                        ),
                      }}
                      onClick={(e) => handleClick(e)}
                    />
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}>
                      <DateRange
                        months={2}
                        direction='horizontal'
                        ranges={dateState}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        onChange={(item) => setDateState([item.selection])}
                      />
                    </Popover>
                  </FormControl>
                </Grid>
                <Grid item xs={8} className='mb-3'>
                  <FormControl className='input-wrapper flex-wrap textField activeField'>
                    <RadioGroup value={radioValue} onChange={(e) => setRadioValue(e.target.value)}>
                      <Typography>{getTranslate()(`${translationPath}Actions`)}</Typography>
                      <Grid container spacing={4}>
                        <Grid item>
                          <FormControlLabel
                            label={getTranslate()(`${translationPath}Active`)}
                            control={<Radio value='1' color='primary' />}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            label={getTranslate()(`${translationPath}Inactive`)}
                            control={<Radio value='0' color='primary' />}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={8} className='mb-3'>
                  <FormControl className='input-wrapper flex-wrap textField activeField'>
                    <RadioGroup
                      value={advertisementType}
                      onChange={(e) => setAdvertisementType(e.target.value)}>
                      <Typography>{getTranslate()(`${translationPath}Type`)}</Typography>
                      <Grid container spacing={4}>
                        <Grid item>
                          <FormControlLabel
                            label={getTranslate()(`${translationPath}Link`)}
                            control={<Radio value='0' color='primary' />}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            label={getTranslate()(`${translationPath}Tour`)}
                            control={<Radio value='1' color='primary' />}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                  {advertisementType === '0' ? (
                    <FormControl className='input-wrapper flex-wrap textField'>
                      <Inputs
                        helperText={getErrorByName(schema, 'adLink').message}
                        error={getErrorByName(schema, 'adLink').error}
                        isWithError
                        isSubmitted={isSubmitted}
                        idRef='Link'
                        labelValue={getTranslate()(`${translationPath}Link`)}
                        value={adLink}
                        onInputChanged={(e) => setAdLink(e.target.value)}
                        inputPlaceholder={getTranslate()(`${translationPath}Link`)}
                      />
                    </FormControl>
                  ) : (
                    <AutocompleteComponent
                      helperText={getErrorByName(schema, 'tourInfo').message}
                      error={getErrorByName(schema, 'tourInfo').error}
                      isWithError
                      isSubmitted={isSubmitted}
                      idRef='tourInfo'
                      options={tourResponse && tourResponse.result ? tourResponse.result : []}
                      getOptionLabel={(option) => option.tourName || ''}
                      getOptionSelected={(option) => tourInfo.tourId === option.tourId}
                      value={tourInfo}
                      defaultValue={(Array.isArray(defaultValue) && defaultValue[0]) || []}
                      onChange={(e, v) => {
                        setTourInfo(v);
                      }}
                      onKeyUp={searchHandler}
                      inputPlaceholder='SelectTour'
                      translationPath={translationPath}
                      withoutSearchButton
                      multiple={false}
                    />
                  )}
                </Grid>
                <Grid item xs={8} className='mb-3'>
                  <FormControl className='input-wrapper flex-wrap checkBox activeField'>
                    <FormControlLabel
                      label={getTranslate()(`${translationPath}Default`)}
                      control={
                        <Checkbox
                          checked={isDefault}
                          onClick={() => setIsDefault(!isDefault)}
                          color='primary'
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8} className='mb-3'>
                  <FormControl className='input-wrapper flex-wrap textField'>
                    <Typography htmlFor='uploadImage'>
                      {getTranslate()(`${translationPath}UploadImage`)}
                    </Typography>
                    <TextField
                      helperText={isSubmitted ? getErrorByName(schema, 'uploadImage').message : ''}
                      error={isSubmitted ? getErrorByName(schema, 'uploadImage').error : false}
                      id='uploadImage'
                      disabled
                      value={uploadImage}
                      size='small'
                      variant='outlined'
                      fullWidth
                      placeholder={
                        props.isEdit
                          ? getTranslate()(`${translationPath}EditImage`)
                          : getTranslate()(`${translationPath}UploadImage`)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {result && (
                              <>
                                <IconButton
                                  onClick={() => {
                                    setIsSubmitted(true);
                                    setUploadImage('');
                                    setEditImage(false);
                                    setCroppedImage(null);
                                    setResult('');
                                  }}>
                                  <ClearRoundedIcon />
                                </IconButton>
                              </>
                            )}
                            {!result && (
                              <>
                                <Button onClick={() => hiddenFileInput.current.click()}>
                                  {getTranslate()(`${translationPath}BrowseImage`)}
                                </Button>
                                <input
                                  type='file'
                                  accept='image/*'
                                  ref={hiddenFileInput}
                                  onChange={(e) => viewimage(e)}
                                  id='upload-photo'
                                />
                              </>
                            )}
                          </InputAdornment>
                        ),
                      }}></TextField>
                    {!result && (
                      <span className='imageUpload'>
                        {getTranslate()(`${translationPath}NoImageUploaded`)}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={6} container>
                {editImage && (
                  <CropperComponent
                    cropperImage={
                      props.isEdit
                        ? result.uuid
                          ? viewUploadeImage
                          : getDownloadableLink(result)
                        : viewUploadeImage
                    }
                    aspectRatio={19 / 5}
                    cropperImageChanged={(croppedImage) => {
                      setCroppedImage(croppedImage);
                      setEditImage(false);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item>
              <Button onClick={handleCancelBtn} color='primary' className='btns'>
                {getTranslate()(`${translationPath}Cancel`)}
              </Button>
            </Grid>
          </DialogActions>
          <div
            className={
              croppedImage === null || adName === '' || result === ''
                ? 'DisableddoneBtn'
                : 'doneBtnContainer'
            }>
            <Grid container justify='center'>
              <Grid item xs={12} className='doneBtn'>
                <Button
                  disabled={croppedImage === null || adName === '' || result === ''}
                  className='btns theme-solid'
                  onClick={handleDoneBtn}>
                  {getTranslate()(`${translationPath}Done`)}
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export { AdsViewDialog };
