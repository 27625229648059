import React, { useState, useCallback, useRef, useEffect, useReducer } from 'react';
import {
  Spinner,
  Inputs,
  TimelineComponent,
  AutocompleteComponent,
} from './../../../../Componentes';
import {
  getHistory,
  getTranslate,
  getErrorByName,
  showError,
  GetParams,
  showSuccess,
} from '../../../../Helpers';
import { Button } from '@material-ui/core';
import {
  CreateTour,
  UpdateTour,
  GetAllTripCanInserted,
  TripValidation,
  GetForms,
  GetReadyTourById,
} from '../../../../Serviecs';
import { ToursTripsCardComponent } from '../ToursUtilities';
import './ToursManagementView.scss';
import Joi from 'joi';
import {
  TourDiscountsFeesComponent,
  TourInformationComponent,
  TourPoliciesComponent,
  ExtraFeesComponent,
} from './ToursManagementUtilities';
import { FeaturesComponent } from '../../Trips/TripsManagementView/TripManagementUtilities';

const translationPath = 'home.tours.toursManagementView.';

export const ToursManagementView = () => {
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onDragItem, setOnDragItem] = useState(null);
  const [currentDragOverItem, setCurrentDragOverItem] = useState(null);
  const draggingElement = useRef(null);
  const dragOverTouchItem = useRef(null);
  const [isNext, setIsNext] = useState(true);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    else if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  }, []);
  const [state, setState] = useReducer(reducer, {
    tourName: '',
    tourAbout: '',
    tripIds: [],
    bookingPolicyIds: [],
    tourFeaturesIds: [],
    discountPercentage: null,
    discountValue: null,
    discountLimit: null,
    tourExtraFee: [],
  });
  const [droppedTrips, setDroppedTrips] = useState([]);
  const [tripType, setTripType] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const schema = Joi.object({
    tourName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}tour-name-is-required`),
      }),
    tourAbout: Joi.string().allow(''),
    tripIds: Joi.array()
      .min(1)
      .required()
      .messages({
        'array.min': getTranslate()(`${translationPath}please-select-at-least-one-trip`),
      }),
    bookingPolicyIds: Joi.array()
      .min(1)
      .required()
      .messages({
        'array.min': getTranslate()(`${translationPath}please-select-at-least-one-booking-policy`),
      }),
    tourFeaturesIds: Joi.array(),
    discountPercentage: Joi.number()
      .max(100)
      .allow(null)
      .messages({
        'number.max': getTranslate()(`${translationPath}must-be-less-than-or-equal-to-100`),
      }),
    discountValue: Joi.number().allow(null),
    discountLimit: Joi.number().allow(null),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const [isTripsLoading, setIsTripsLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    body: {
      tripIds: [],
      typeId: null,
      search: '',
    },
  });
  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error && getErrorByName(schema, 'tripIds').error) {
      showError(getErrorByName(schema, 'tripIds').message);
      return;
    }
    if (schema.error && getErrorByName(schema, 'tourFeaturesIds').error) {
      showError(getErrorByName(schema, 'tourFeaturesIds').message);
      return;
    }
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    setIsLoading(true);
    let response = null;
    if (id) response = await UpdateTour(id, state);
    else response = await CreateTour(state);
    setIsLoading(false);
    if (response) {
      if (id) showSuccess(getTranslate()(`${translationPath}tour-updated-successfully`));
      else showSuccess(getTranslate()(`${translationPath}tour-saved-successfully`));
      getHistory().push('/home/tours/view');
    } else if (id) showError(getTranslate()(`${translationPath}tour-updating-failed`));
    else showError(getTranslate()(`${translationPath}tour-saving-failed`));
  };
  const [trips, setTrips] = useState({ result: [], totalCount: 0 });
  const [tripsTypes, setTripsTypes] = useState([]);
  const [tripTypeSearch, setTripTypeSearch] = useState('');
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const searchTimer = useRef(null);
  const searchTripTypesTimer = useRef(null);

  const getTrips = useCallback(async () => {
    setIsTripsLoading(true);
    const res = await GetAllTripCanInserted(filter);
    if (filter.pageIndex === 0) {
      setTrips({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setTrips((item) => ({
        result: item.result.concat((res && res.result) || []),
        totalCount: (res && res.totalCount) || 0,
      }));
    }
    setIsTripsLoading(false);
  }, [filter]);
  const getTripTypes = useCallback(async () => {
    setIsTripsLoading(true);
    const res = await GetForms(0, 10, tripTypeSearch);
    setTripsTypes((res && res.result) || []);
    setIsTripsLoading(false);
  }, [tripTypeSearch]);
  const getTourFeatures = (localDroppedTrips) => {
    const gatherFeatures = [];
    localDroppedTrips.map((item) =>
      item.tripFeatures.forEach((subItem) => {
        if (!gatherFeatures.includes(subItem.tripFeaturesId)) gatherFeatures.push(subItem);
      })
    );
    setSelectedFeatures((items) => {
      const filtered = gatherFeatures.filter(
        (item) =>
          items.findIndex(
            (element) =>
              element.lookupItemId === item.lookupsId &&
              item.parentLookupItemId === element.parentLookupItemId
          ) === -1
      );
      filtered.map((element) =>
        items.push({
          lookupItemCode: element.lookupItemCode,
          lookupItemName: element.lookupItemName,
          lookupTypeName: element.lookupTypeName,
          lookupItemId: element.lookupsId,
          parentLookupItemId: element.parentLookupItemId,
          parentLookupItemName: element.parentLookupItemName,
        })
      );
      return [...items];
    });
  };
  useEffect(() => {
    setState({ id: 'tourFeaturesIds', value: selectedFeatures.map((item) => item.lookupItemId) });
  }, [selectedFeatures]);
  const tripValidation = useCallback(
    async (currentDropTrips) => {
      setIsValidationLoading(true);
      const res = await TripValidation({
        tripIds: filter.body.tripIds || [],
        newTripId: onDragItem && onDragItem.item && onDragItem.item.tripId,
        tripPosition:
          (currentDragOverItem &&
            (currentDragOverItem.isBefore
              ? currentDragOverItem.index
              : currentDragOverItem.index + 1)) ||
          currentDropTrips.length - 1,
      });
      if (res && res.isValid) {
        if (res.mapRoute) {
          res.mapRoute.forEach((item) => {
            const fromIndex = currentDropTrips.findIndex(
              (element) => item.fromTripId === element.tripId
            );
            const toIndex = currentDropTrips.findIndex(
              (element) => item.toTripId === element.tripId
            );
            const distanceInfo = `${item.distance} - ${item.duration} ${getTranslate()(
              `${translationPath}from`
            )} ${currentDropTrips[fromIndex].tripName} ${item.duration} ${getTranslate()(
              `${translationPath}to`
            )} ${currentDropTrips[toIndex].tripName}`;
            currentDropTrips[fromIndex].distanceInfo = distanceInfo;
            currentDropTrips[toIndex].distanceInfo = distanceInfo;
          });
        }
        const tripIds = currentDropTrips.map((item) => item.tripId);
        setFilter((items) => ({
          ...items,
          pageIndex: 0,
          body: { ...items.body, tripIds },
        }));
        setDroppedTrips(currentDropTrips);
        getTourFeatures(currentDropTrips);
        setState({ id: 'tripIds', value: tripIds });
      } else showError(getTranslate()(`${translationPath}invalid-trip-location`));
      setCurrentDragOverItem(null);
      setOnDragItem(null);

      setIsValidationLoading(false);
    },
    [currentDragOverItem, filter.body.tripIds, onDragItem]
  );
  const getEditInit = useCallback(async () => {
    if (id === null) return;
    setIsLoading(true);
    const res = await GetReadyTourById(id);
    const tripIds = (res && res.tourTrips && res.tourTrips.map((item) => item.tripId)) || [];
    const tourFeaturesIds =
      (res && res.tourFeatures && res.tourFeatures.map((item) => item.lookupsId)) || [];
    const bookingPolicyIds =
      (res && res.bookingPolicy && res.bookingPolicy.map((item) => item.lookupsId)) || [];
    setSelectedFeatures(
      (res &&
        res.tourFeatures &&
        res.tourFeatures.map((item) => ({
          //   description: null
          // isEditable: item.lookupItemCode
          // lookupItemCode: item.lookupItemCode,
          lookupItemId: item.lookupsId,
          lookupItemName: item.lookupItemName,
          // order: 0
          parentLookupItemId: item.parentLookupItemId,
          parentLookupItemName: item.parentLookupItemName,
          // parentLookupTypeId: 1143
          // parentLookupTypeName: "FeatuerType"
        }))) ||
        []
    );
    setDroppedTrips(
      (res &&
        res.tourTrips &&
        res.tourTrips.map((item) => ({
          tripId: item.tripId,
          tripName: (item && item.trip && item.trip.tripName) || '',
          tripPrice: (item && item.trip && item.trip.tripPrice) || '',
          tripDuration: (item && item.trip && item.trip.tripDuration) || '',
          coverTripImageId: (item && item.trip && item.trip.coverTripImageId) || '',
          tripFeatures: (item && item.trip && item.trip.featureInformations) || '',
          address: (item && item.trip && item.trip.address) || '',
          typeName: (item && item.trip && item.trip.forms && item.trip.forms.formsName) || '',
        }))) ||
        []
    );
    setFilter((items) => ({ ...items, body: { ...items.body, tripIds } }));
    setState({
      id: 'edit',
      value: {
        tourName: res.tourName || '',
        discountPercentage: res.discountPercentage===0?null:res.discountPercentage,
        discountValue: res.discountValue===0? null:res.discountValue,
        discountLimit: res.discountLimit===0?null:res.discountLimit,
        tripIds,
        tourFeaturesIds,
        bookingPolicyIds,
        tourAbout: res.tourAbout || '',
        tourExtraFee: res.tourExtraFeeDto || [],
      },
    });
    setIsLoading(false);
  }, [id]);
  useEffect(() => {
    if (id !== null);
    getEditInit();
  }, [getEditInit, id]);
  const onLoadMoreHandler = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: item.pageIndex + 1 }));
  }, []);
  const searchHandler = (event) => {
    const value = event.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((items) => ({ ...items, pageIndex: 0, body: { ...items.body, search: value } }));
    }, 700);
  };
  const dragOverHandler = (event) => {
    event.preventDefault();
  };
  const fakeElementGenerator = (event) => {
    if (event.type === 'touchstart') {
      draggingElement.current = document.createElement('div');
      draggingElement.current.classList.add('tour-trip-card-component-wrapper');
      draggingElement.current.classList.add('p-absolute');
      draggingElement.current.classList.add('fake-dragging-item');
      draggingElement.current.setAttribute(
        'style',
        `top:${event.targetTouches[0].clientY + document.documentElement.scrollTop}px;left:${
          event.targetTouches[0].clientX
        }px`
      );
      draggingElement.current.appendChild(event.target.parentElement.cloneNode(true));
      document.body.appendChild(draggingElement.current);
    }
  };
  const dragHandler = (item, index, event) => {
    if (!onDragItem || onDragItem.index !== index) setOnDragItem({ item, index, from: 'trip' });
    fakeElementGenerator(event);
  };
  const dragTourTripHandler = (item, index, event) => {
    if (!onDragItem || onDragItem.index !== index) setOnDragItem({ item, index, from: 'tour' });
    fakeElementGenerator(event);
  };
  const dragOverTourHandler = useCallback(
    (index) => (event) => {
      event.preventDefault();
      // const x=event.pageX-(event.target as HTMLDivElement).getBoundingClientRect().bottom;
      // console.log((event.target.parentElement as HTMLDivElement).getBoundingClientRect(), event.offsetY);
      const center =
        event.target.getBoundingClientRect().bottom +
        window.scrollY -
        event.target.offsetHeight / 2;
      const isBefore =
        (currentDragOverItem && currentDragOverItem.index + 1 === onDragItem.index) ||
        (event.pageY <= center &&
          currentDragOverItem &&
          currentDragOverItem.index - 1 !== onDragItem.index);
      if (
        !currentDragOverItem ||
        index !== currentDragOverItem.index ||
        currentDragOverItem.isBefore !== isBefore ||
        currentDragOverItem.dragOver !== 'tour'
      )
        setCurrentDragOverItem({ index, dragOver: 'tour', isBefore });
    },
    [currentDragOverItem, onDragItem]
  );
  const onTouchMoveHandler = useCallback(
    () => (event) => {
      draggingElement.current.setAttribute(
        'style',
        `top:${event.targetTouches[0].clientY + document.documentElement.scrollTop}px;left:${
          event.targetTouches[0].clientX
        }px`
      );
      dragOverTouchItem.current = document.elementFromPoint(
        event.targetTouches[0].clientX,
        event.targetTouches[0].clientY
      );
      if (!dragOverTouchItem.current || !dragOverTouchItem.current.classList) return;
      if (dragOverTouchItem.current.classList.contains('tour-trips-timeline-wrapper')) {
        if (currentDragOverItem) setCurrentDragOverItem(null);
        return;
      }
      const dragOverFrom = dragOverTouchItem.current.getAttribute('data-from');
      const index = droppedTrips.findIndex(
        (item) => item.tripId === +dragOverTouchItem.current.getAttribute('data-id')
      );
      if (
        dragOverFrom === 'tour' &&
        index !== -1
        // (!currentDragOverItem || currentDragOverItem.index !== index)
      ) {
        // const center =
        //   event.targetTouches[0].target.parentElement.getBoundingClientRect().y +
        //   window.scrollY -
        //   event.targetTouches[0].target.parentElement.offsetHeight / 2;
        // const isBefore = event.targetTouches[0].pageY <= center;
        // const center =
        //   event.targetTouches[0].target.getBoundingClientRect().bottom +
        //   window.scrollY -
        //   event.targetTouches[0].target.offsetHeight / 2;
        // const isBefore = event.targetTouches[0].pageY <= center;
        // console.log(
        //   event.targetTouches[0].target.parentElement.offsetHeight,
        //   event.targetTouches[0].target.parentElement.getBoundingClientRect(),
        //   event.targetTouches[0].pageY,
        //   dragOverTouchItem.current
        // );
        // const isBefore = false;
        if (
          !currentDragOverItem ||
          currentDragOverItem.index !== index
          // currentDragOverItem.isBefore !== isBefore
        )
          setCurrentDragOverItem({
            index,
            dragOver: dragOverTouchItem.current.getAttribute('data-from'),
            isBefore: true,
          });
      }
    },
    [currentDragOverItem, droppedTrips]
  );
  const tripTouchEndHandler = () => {
    draggingElement.current.remove();
    if (
      dragOverTouchItem.current &&
      dragOverTouchItem.current.classList.contains('tour-trips-timeline-wrapper')
    )
      dropHandler();
    else if (currentDragOverItem) dropHandler();
  };
  const dropHandler = () => {
    if (
      currentDragOverItem &&
      currentDragOverItem.index === onDragItem.index &&
      currentDragOverItem.dragOver === onDragItem.from
    ) {
      setCurrentDragOverItem(null);
      setOnDragItem(null);
      return;
    }
    const localDroppedTrips = [...droppedTrips];
    if (currentDragOverItem !== null) {
      if (currentDragOverItem.dragOver === onDragItem.from)
        localDroppedTrips.splice(
          currentDragOverItem.isBefore ? currentDragOverItem.index : currentDragOverItem.index + 1,
          0,
          localDroppedTrips.splice(onDragItem.index, 1)[0]
        );
      else
        localDroppedTrips.splice(
          currentDragOverItem.isBefore ? currentDragOverItem.index : currentDragOverItem.index + 1,
          0,
          onDragItem.item
        );
    } else localDroppedTrips.push(onDragItem.item);
    if (localDroppedTrips.length <= 1) {
      const tripIds = localDroppedTrips.map((item) => item.tripId);
      setFilter((items) => ({
        ...items,
        pageIndex: 0,
        body: { ...items.body, tripIds: tripIds },
      }));
      setState({ id: 'tripIds', value: tripIds });
      setCurrentDragOverItem(null);
      setOnDragItem(null);
      setDroppedTrips(localDroppedTrips);
      getTourFeatures(localDroppedTrips);
    } else tripValidation(localDroppedTrips);
  };
  const removeTourTripHandler = useCallback(
    (index) => () => {
      droppedTrips.splice(index, 1);
      setDroppedTrips(droppedTrips);
      const tripIds = droppedTrips.map((item) => item.tripId);
      setFilter((items) => ({
        ...items,
        pageIndex: 0,
        body: { ...items.body, tripIds },
      }));
      setState({
        id: 'tripIds',
        value: tripIds,
      });
    },
    [droppedTrips]
  );
  const searchTripTypesHandler = (event) => {
    const value = event.target.value;
    if (searchTripTypesTimer.current) clearTimeout(searchTripTypesTimer.current);
    searchTripTypesTimer.current = setTimeout(() => {
      setTripTypeSearch(value);
    }, 700);
  };
  useEffect(() => {
    getTrips();
    const editId = GetParams('id');
    if (editId !== null) setId(+editId);
  }, [filter, getTrips]);
  useEffect(() => {
    getTripTypes();
  }, [getTripTypes, tripTypeSearch]);
  useEffect(() => {
    if (
      document.body.scrollHeight === window.innerHeight &&
      trips.totalCount > trips.result.length &&
      !isLoading
    )
      onLoadMoreHandler();
  }, [isLoading, onLoadMoreHandler, state.tripIds.length, trips]);
  useEffect(() => {
    return () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      if (searchTripTypesTimer.current) clearTimeout(searchTripTypesTimer.current);
    };
  }, []);

  return (
    <div className='tours-management-view view-wrapper'>
      <Spinner isActive={isLoading} />
      <form noValidate onSubmit={saveHandler} className='tour-form-content'>
        <div className='tour-header mb-2 px-2'>
          <Button type='submit' className='btns theme-solid mx-2 mb-2'>
            <span>{getTranslate()(`${translationPath}save-tour`)}</span>
          </Button>
          <Button
            className='btns theme-solid bg-cancel mb-2 mx-2'
            onClick={() => getHistory().push('/home/tours/view')}>
            <span>{getTranslate()(`${translationPath}cancel`)}</span>
          </Button>
        </div>
        <div className='tour-trips-wrapper'>
          <div className={`trips-list-wrapper${(!isNext && ' is-back') || ''}`}>
            <div className='trips-view view-wrapper'>
              <Spinner isActive={isTripsLoading} />
              <div className='header-section'>
                <div className='filter-section'>
                  <div className='filter-title d-inline-flex px-2'>
                    <span className='texts-truncate c-black-light'>
                      {getTranslate()(`${translationPath}drop-a-trip`)}
                    </span>
                  </div>
                  <div className='d-flex-column p-relative px-2'>
                    <Inputs
                      idRef='tripsSearchRef'
                      variant='outlined'
                      fieldClasses='inputs theme-solid'
                      translationPath={translationPath}
                      label='filter'
                      beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                      onKeyUp={searchHandler}
                      inputPlaceholder='search-trips'
                    />
                  </div>
                </div>
                <div className='second-filter-section pt-2 c-black-light px-2'>
                  <div className='section px-2'>
                    <span className='d-inline-flex-center mdi mdi-tune px-1' />
                    <div className='w-100 px-1'>
                      <AutocompleteComponent
                        idRef='tripTypeRef'
                        translationPath={translationPath}
                        options={tripsTypes}
                        getOptionLabel={(option) => (option.formsName && option.formsName) || ''}
                        inputPlaceholder='trip-type'
                        getOptionSelected={(option) => filter.body.typeId === option.formsId}
                        value={tripType}
                        onKeyUp={searchTripTypesHandler}
                        onChange={(e, value) => {
                          setTripType(value);
                          setFilter((items) => ({
                            ...items,
                            pageIndex: 0,
                            body: { ...items.body, typeId: (value && value.formsId) || null },
                          }));
                        }}
                        multiple={false}
                        withoutSearchButton
                      />
                    </div>
                  </div>
                </div>
              </div>
              <ToursTripsCardComponent
                data={trips}
                onLoadMore={onLoadMoreHandler}
                filterData={droppedTrips}
                filterInput='tripId'
                translationPath={translationPath}
                isLoading={isTripsLoading}
                draggable
                dragFrom='trip'
                onDrag={dragHandler}
                touchEnd={tripTouchEndHandler}
                onTouchMove={onTouchMoveHandler}
              />
            </div>
          </div>

          <div
            className={`tour-form-wrapper${(!isNext && ' is-back') || ''}`}
            onDrop={dropHandler}
            onDragOver={dragOverHandler}>
            <div className='form-content'>
              <div className='tour-trips-timeline-wrapper'>
                <TimelineComponent
                  data={droppedTrips}
                  onDragOver={dragOverTourHandler}
                  onDrag={dragTourTripHandler}
                  onTouchEnd={tripTouchEndHandler}
                  onTouchMove={onTouchMoveHandler}
                  draggable
                  getTimelineContent={(item, index) => (
                    <React.Fragment>
                      {currentDragOverItem &&
                        index === currentDragOverItem.index &&
                        (currentDragOverItem.dragOver !== onDragItem.from ||
                          (currentDragOverItem.dragOver === onDragItem.from &&
                            currentDragOverItem.index - 1 !== onDragItem.index &&
                            currentDragOverItem.index !== onDragItem.index)) && (
                          <span
                            className={`drop-navigator${
                              (currentDragOverItem.isBefore && ' is-drop') || ''
                            }${(false && ' not-allowed') || ''}`}>
                            {getTranslate()(`${translationPath}drop-here`)}
                            {isValidationLoading && (
                              <span className='px-2 mdi mdi-autorenew mdi-spin c-secondary' />
                            )}
                          </span>
                        )}
                      <div className='p-relative'>
                        <Button
                          className='btns-icon theme-transparent c-danger p-absolute-t-2-r-3'
                          onClick={removeTourTripHandler(index)}>
                          <span className='mdi mdi-close c-danger'></span>
                        </Button>
                        <ToursTripsCardComponent
                          data={{ result: [{ ...item }] }}
                          translationPath={translationPath}
                          isFullWidth
                          key='tourRef'
                          dragFrom='tour'
                        />
                      </div>
                      {(isValidationLoading && !currentDragOverItem) ||
                        (currentDragOverItem &&
                          index === currentDragOverItem.index &&
                          (currentDragOverItem.dragOver !== onDragItem.from ||
                            (currentDragOverItem.dragOver === onDragItem.from &&
                              currentDragOverItem.index !== onDragItem.index &&
                              currentDragOverItem.index + 1 !== onDragItem.index)) && (
                            <span
                              className={`drop-navigator${
                                (!currentDragOverItem.isBefore && ' is-drop') || ''
                              }${(false && ' not-allowed') || ''}`}>
                              <span>{getTranslate()(`${translationPath}drop-here`)}</span>
                              {isValidationLoading && (
                                <span className='px-2 mdi mdi-autorenew mdi-spin c-secondary' />
                              )}
                            </span>
                          ))}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className='form-footer'>
              <div className='d-inline-flex-center px-2'>
                <Button
                  onClick={() => setIsNext((element) => !element)}
                  className='btns theme-solid'>
                  {getTranslate()(`${translationPath}${(isNext && 'next') || 'back'}`)}
                </Button>
              </div>
              <div className='trip-total'>
                <div className='d-inline-flex-center px-2'>
                  <span className='mdi mdi-clock-time-five' />
                  <span className='px-2'>{getTranslate()(`${translationPath}total-time`)}:</span>
                  <span>
                    {droppedTrips.reduce(
                      (total, item) =>
                        (item && item.tripDuration && (total += item.tripDuration)) || 0,
                      0
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={`tours-section-wrapper${(!isNext && ' is-back') || ''}`}>
            <div className='tours-section-item'>
              <TourInformationComponent
                state={state}
                onInformationChange={(id, value) => setState({ id, value })}
                isSubmitted={isSubmitted}
                schema={schema}
                translationPath={translationPath}
              />
            </div>
            <div className='tours-section-item'>
              <FeaturesComponent
                featureId={id}
                featureDescription='tour-features-description'
                state={state}
                setState={setState}
                globalRight={selectedFeatures}
                globalRightChanged={(features) => setSelectedFeatures(features)}
                featuresIdsInput='tourFeaturesIds'
                translationPath={translationPath}
              />
            </div>

            <div className='tours-section-item'>
              <TourDiscountsFeesComponent
                state={state}
                onDiscountFeesChange={(id, value) => setState({ id, value })}
                isSubmitted={isSubmitted}
                schema={schema}
                translationPath={translationPath}
              />
            </div>

            <div className='tours-section-item'>
              <ExtraFeesComponent
                state={state}
                onExtraFeesChange={(id, value) => setState({ id, value })}
                isSubmitted={isSubmitted}
                schema={schema}
                translationPath={translationPath}
              />
            </div>
            <div className='tours-section-item'>
              <TourPoliciesComponent
                state={state}
                onPoliciesChange={(id, value) => setState({ id, value })}
                isSubmitted={isSubmitted}
                schema={schema}
                translationPath={translationPath}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
