import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  FormControl,
  Typography,
  Divider,
} from '@material-ui/core';
import {
  CreateContentPages,
  EditContentPages,
} from '../../../../../Serviecs/PageContentServices/PageContentServices';
import ReactQuill from 'react-quill';
import Joi from 'joi';
import { showSuccess, getTranslate, getErrorByName, showError } from '../../../../../Helpers';
import { Inputs } from './../../../../../Componentes';
import 'react-quill/dist/quill.snow.css';
import '../../../SliderView/SliderViewUtilities/SliderViewDialogs/SliderDialogs.scss';

const PageContentDialog = (props) => {
  const [pageName, setPageName] = useState('');
  const [contentTitle, setContentTitle] = useState('');
  const [contentBody, setContentBody] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const translationPath = 'PageContents.';

  const schema = Joi.object({
    pageName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}page-name-is-required`),
      }),
    contentTitle: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}content-title-is-required`),
      }),
    contentBody: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}content-body-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ pageName, contentTitle, contentBody });

  const handleDoneBtn = async () => {
    setIsSubmitted(true);
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }

    if (props.isEdit) {
      await EditContentPages(props.item.pageContentId, {
        pageName: pageName,
        contentTitle: contentTitle,
        contentBody: contentBody,
      });
      showSuccess('Page Edited Successfully');
    } else {
      await CreateContentPages({
        pageName: pageName,
        contentTitle: contentTitle,
        contentBody: contentBody,
      });
      showSuccess('Page Created Successfully');
    }
    props.setReloaded(true);
    props.reloadData();
    props.setOpen(false);
    setPageName('');
    setContentBody('');
    setContentTitle('');
  };

  useEffect(() => {
    if (props.isEdit) {
      setPageName(props.item.pageName);
      setContentTitle(props.item.contentTitle);
      setContentBody(props.item.contentBody);
    } else {
      setPageName('');
      setContentTitle('');
      setContentBody('');
    }
    props.setReloaded(false);
  }, [props, props.isEdit, props.item.contentBody, props.item.contentTitle, props.item.pageName]);

  return (
    <>
      <Dialog open={props.open} keepMounted className='sliderDialogs'>
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <DialogTitle className='dialogTitle'>
            <Typography>{props.isEdit ? 'Edit Page' : 'Add New Page'}</Typography>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid container justify='center'>
              <Grid item xs={8} className='mb-3'>
                <FormControl className='input-wrapper flex-wrap textField'>
                  <Inputs
                    helperText={getErrorByName(schema, 'pageName').message}
                    error={getErrorByName(schema, 'pageName').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    idRef='Page Name'
                    value={pageName}
                    onInputChanged={(e) => setPageName(e.target.value)}
                    labelValue={getTranslate()(`${translationPath}PageName`)}
                    inputPlaceholder={getTranslate()(`${translationPath}PageName`)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <FormControl className='input-wrapper flex-wrap textField selectData'>
                  <Inputs
                    helperText={getErrorByName(schema, 'contentTitle').message}
                    error={getErrorByName(schema, 'contentTitle').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    idRef='Page Title'
                    value={contentTitle}
                    onInputChanged={(e) => setContentTitle(e.target.value)}
                    labelValue={getTranslate()(`${translationPath}Title`)}
                    inputPlaceholder={getTranslate()(`${translationPath}Title`)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <FormControl className='input-wrapper flex-wrap textField contentBody'>
                  <Typography>{getTranslate()(`${translationPath}Body`)}</Typography>
                  <ReactQuill
                    helperText={getErrorByName(schema, 'contentBody').message}
                    error={getErrorByName(schema, 'contentBody').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    idRef='Body'
                    placeholder={getTranslate()(`${translationPath}TypeHere`)}
                    value={contentBody}
                    onChange={(e) => setContentBody(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justify='space-evenly'>
              <Grid item>
                <Button
                  onClick={() => {
                    props.setOpen(false);
                    props.reloadData();
                    if (!props.isEdit) {
                      setPageName('');
                      setContentBody('');
                      setContentTitle('');
                    }
                  }}
                  color='primary'
                  className='btns'>
                  {getTranslate()(`${translationPath}Cancel`)}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
          <div
            className={
              pageName === '' || contentBody === '' || contentTitle === ''
                ? 'DisableddoneBtn'
                : 'doneBtnContainer'
            }>
            <Grid container justify='center'>
              <Grid item xs={12} className='doneBtn'>
                <Button
                  disabled={pageName === '' || contentBody === '' || contentTitle === ''}
                  className='btns theme-solid'
                  onClick={handleDoneBtn}>
                  {getTranslate()(`${translationPath}Done`)}
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export { PageContentDialog };
