import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import './PopoverComponent.scss';
export const PopoverComponent = ({
  component,
  idRef,
  attachedWith,
  handleClose,
  popoverClasses,
  anchorOrigin,
  transformOrigin,
  style,
}) => {
  const open = Boolean(attachedWith);
  return (
    <Popover
      id={idRef}
      open={open}
      anchorEl={attachedWith}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      style={style}
      transformOrigin={transformOrigin}
      className={popoverClasses}>
      {component}
    </Popover>
  );
};

PopoverComponent.propTypes = {
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node])
    .isRequired,
  attachedWith: PropTypes.instanceOf(Object),
  idRef: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  popoverClasses: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom', 'center', 'left', 'right']),
    horizontal: PropTypes.oneOf(['top', 'bottom', 'center', 'left', 'right']),
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom', 'center', 'left', 'right']),
    horizontal: PropTypes.oneOf(['top', 'bottom', 'center', 'left', 'right']),
  }),
};
PopoverComponent.defaultProps = {
  attachedWith: undefined,
  style: undefined,
  popoverClasses: 'popover-wrapper',
  handleClose: undefined,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
};
