import { lookupItemsGetId } from '../Serviecs';
import { getLook as look } from '../Helpers';

export async function LookupRule(item) {
  if (!item.data.enum) item.data.enum = [];
  if (!item.data.lookup) return;
  if (item.data.lookup === '') return;
  if (item.field.id === 'city' || item.field.id === 'city_') return;
  if (item.field.id.toLowerCase() === 'district' || item.field.id.toLowerCase() === 'district_')
    return;
  if (item.field.id.toLowerCase() === 'community' || item.field.id.toLowerCase() === 'community_')
    return;
  if (
    item.field.id.toLowerCase() === 'sub_community'
    || item.field.id.toLowerCase() === 'sub_community_'
  )
    return;

  if (look() && !look().find((f) => f === item.field.id)) {
    const result = await lookupItemsGetId({ lookupTypeId: item.data.lookup, lookupParentId: null });
    if (item.data.enum.length === 0) result.map((items) => item.data.enum.push(items));

    look().push(item.field.id);
  }
}
