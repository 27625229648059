import React, { useCallback } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { getTranslate } from '../../Helpers';

export const CheckboxesComponent = ({
  data,
  labelValue,
  ariaLabel,
  translationPath,
  translationPathForData,
  onSelectedCheckboxChanged,
  key,
  isDisabledInput,
  isDisabled,
  labelInput,
  label,
  wrapperClasses,
  checkboxClasses,
  labelClasses,
  isRequired,
  idRef,
  checked,
  indeterminate,
  isRow,
  tabIndex,
  disableRipple,
}) => {
  const onChangeHandler = useCallback(
    (item, index) => (event) => {
      if (onSelectedCheckboxChanged) onSelectedCheckboxChanged(item, index, event);
    },
    [onSelectedCheckboxChanged]
  );
  return (
    <FormControl required={isRequired} className={`${wrapperClasses}`} component="fieldset">
      {labelValue && (
        <label htmlFor={idRef} className={labelClasses}>
          {getTranslate()(`${translationPath}${labelValue}`)}
        </label>
      )}
      {!data && (
        <FormControlLabel
          disabled={isDisabled}
          className="form-control-label"
          tabIndex={tabIndex}
          control={
            <Checkbox
              className={`${checkboxClasses}`}
              icon={<span className="i-unchecked" />}
              checkedIcon={<span className="mdi mdi-check" />}
              indeterminateIcon={<span className="mdi mdi-minus" />}
              checked={checked}
              disableRipple={disableRipple}
              indeterminate={indeterminate}
            />
          }
          label={getTranslate()(`${translationPath}${label}`)}
          onChange={onSelectedCheckboxChanged}
          id={idRef}
        />
      )}
      {data && (
        <FormGroup
          aria-label={
            ariaLabel ? getTranslate()(`${translationPathForData}${ariaLabel}`) : 'Checkbox Group'
          }
          row={isRow}
          className="checkbox-group-wrapper"
          id={idRef}
        >
          {data.map((item, index) => (
            <FormControlLabel
              key={`${key}${index + 1}`}
              disabled={isDisabledInput ? item[isDisabledInput] : isDisabled}
              className="form-control-label"
              onChange={onChangeHandler(item, index)}
              control={
                <Checkbox
                  className={`${checkboxClasses}`}
                  icon={<span className="i-unchecked" />}
                  checkedIcon={<span className="mdi mdi-check" />}
                  indeterminateIcon={<span className="mdi mdi-minus" />}
                  checked={checked(item, index)}
                  indeterminate={(indeterminate && indeterminate(item, index)) || undefined}
                />
              }
              label={
                label || labelInput
                  ? getTranslate()(`${translationPathForData}${item[labelInput]}`)
                  : item
              }
            />
          ))}
        </FormGroup>
      )}
    </FormControl>
  );
};

CheckboxesComponent.propTypes = {
  data: PropTypes.instanceOf(Array),
  idRef: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  labelValue: PropTypes.string,
  translationPath: PropTypes.string,
  translationPathForData: PropTypes.string,
  onSelectedCheckboxChanged: PropTypes.func,
  checked: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  indeterminate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  key: PropTypes.string,
  isDisabledInput: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isRow: PropTypes.bool,
  labelInput: PropTypes.string,
  label: PropTypes.string,
  wrapperClasses: PropTypes.string,
  checkboxClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  tabIndex: PropTypes.number,
  disableRipple: PropTypes.bool,
};
CheckboxesComponent.defaultProps = {
  data: undefined,
  labelValue: '',
  label: '',
  ariaLabel: null,
  isRow: false,
  translationPath: '',
  translationPathForData: '',
  // value: undefined,
  onSelectedCheckboxChanged: undefined,
  checked: undefined,
  indeterminate: undefined,
  key: 'checkboxGroups',
  isDisabledInput: null,
  isDisabled: false,
  isRequired: false,
  // valueInput: null,
  labelInput: null,
  wrapperClasses: 'checkbox-groups-wrapper',
  checkboxClasses: 'checkbox-wrapper',
  labelClasses: 'texts-form',
  tabIndex: undefined,
  disableRipple: false,
};
