import React, { memo, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import moment from 'moment';
import { getTranslate } from '../../../../Helpers';
import {Calendar} from "../../../../Componentes";

const translationPath = 'home.headerView.eventsView.';
const EventsView = memo(({ isOpen, top }) => {
  const [date, changeDate] = useState(new Date());
  const [events] = useState([{
    date: new Date(),
    header: 'May 2020',
    text: 'Friday',
    description: 'Call Ahmad alkhateed'
  }]);
  const selectedDays = events.map((item) => moment(item.date).format('D'));
  return (
    <Collapse in={isOpen} className="collapses absolute-collapse" style={{ top }}>
      <div className="cards">
        <div className="card-header">
          <p className="texts-large">{`8 ${getTranslate()(`${translationPath}new`)}`}</p>
          <p>{getTranslate()(`${translationPath}user-events`)}</p>
        </div>
        <div className="card-content p-0">
          <Calendar
            selectedDateChanged={changeDate}
            selectedDate={date}
            events={{ selectedDays }}
          />
          {events.map((item, index) => (
            <React.Fragment key={`notifications${index + 1}`}>
              <div className="separator-h top-m5px" />
              <ButtonBase className="btns theme-wide br-0">
                <span className="fz-30px">20</span>
                {' '}
                <div className="d-inline-flex-column-center-v mx-3">
                  <div className="d-inline-flex-column-center-v mb-2">
                    <span className="texts-large">{item.header}</span>
                    <span className="c-gray-secondary">{item.text}</span>
                  </div>
                  <p className="mb-1">{`${item.date.getHours() > 12 ? item.date.getHours() - 12 : item.date.getHours()}:${item.date.getMinutes()} ${item.date.getHours() > 12 ? 'PM' : 'AM'} - ${item.description}`}</p>
                </div>
              </ButtonBase>
            </React.Fragment>
          ))}

          <div className="separator-h mb-3 top-m5px" />
        </div>
        <div className="card-footer separator-h s-gray-primary pt-2">
          <ButtonBase className="btns theme-outline mb-1">
            <span>{getTranslate()('home.headerView.notificationsView.view-all')}</span>
          </ButtonBase>
        </div>
      </div>
    </Collapse>
  );
});
EventsView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired
};
export { EventsView };
