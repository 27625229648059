import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Spinner,
  Inputs,
  NoContentComponent,
  NoDataFoundComponent,
  DialogComponent,
  DateRangePickerComponent,
  SelectComponet,
} from "../../../Componentes";
import {
  getTranslate,
  getHistory,
  showSuccess,
  showError,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from "../../../Helpers";
import {
  Button,
  ButtonBase,
  FormControl,
  InputAdornment,
  Popover,
  TextField,
} from "@material-ui/core";
import {
  DeleteSection,
  GetAllSections,
  GetAllToursPreviewBySectionId,
} from "../../../Serviecs";
import {
  RecommendedToursCardComponent,
  SectionViewPreviewDialog,
} from "./SectionViewUtilities";
import { CarTypeActions } from "../../../Stores/Actions";
import { useTitle } from "../../../Hooks/useTitle";
import { ActionsEnum } from "../../../Enums";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import "./SectionView.scss";
import { FilterTypeEnum } from "./../../../Enums/FilterTypeEnum";
import { PublishTypeEnum } from "./../../../Enums/PublishTypeEnum";
const translationPath = "SectionView.";
const SectionsView = ({ carTypeResponse }) => {
  const filterDefault = {
    pageSize: 10,
    pageIndex: 0,
  };
  const sortDefult = {
    orderBy: {
      key: 0,
      value: null,
    },
    column: {
      key: 0,
      value: null,
    },
  };
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };

  const [dateRange, setDateRange] = useState(Date.now);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [isWebsite, setIsWebsite] = useState(true);
  const [activeItem, setActiveItem] = useState({});
  const [openPreview, setOpenPreview] = useState(false);
  const [isOpenDetailsMap] = useState(false);
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [trips, setTrips] = useState({ result: [], totalCount: 0 });
  const [tours, setTours] = useState({ result: [], totalCount: 0 });
  const [filter, setFilter] = useState(filterDefault);
  const [carTypeId, setCarTypeId] = useState(null);
  const [, setSort] = useState(sortDefult);
  const searchTimer = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [publishType, setPublishType] = useState(null);
  const [filterType, setFilterType] = useState(null);
  useTitle(getTranslate()(`${translationPath}sections`));

  const addNewTour = () => {
    getHistory().push("/home/sections/add");
  };

  useEffect(() => {
    if (localStorage.getItem("carTypeResponse"))
      setCarTypeId(
        JSON.parse(localStorage.getItem("carTypeResponse")).carTypesId
      );
  }, []);

  const reOrderAll = () => {
    getHistory().push("/home/sections/re-order");
  };

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSections = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllSections(filter.pageIndex + 1, filter.pageSize, {
      filterType,
      publishType,
      ...dateRange,
    });
    setisFirstLoad(false);
    if (filter.pageIndex === 0) {
      setTrips({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setTrips((item) => ({
        result: item.result.concat((res && res.result) || []),
        totalCount: (res && res.totalCount) || 0,
      }));
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filterType, publishType]);

  const getSectionTours = useCallback(
    async (item) => {
      setIsLoading(true);
      const res = await GetAllToursPreviewBySectionId(item, 1, 5, {
        pickupLocationId: 0,
        dropOffLocationId: 0,
        carSearch: [
          {
            carTypeId: localStorage.getItem("carTypeResponse")
              ? carTypeId
              : carTypeResponse.carTypeId,
            adults: [""],
            childrens: [],
            infants: [],
          },
        ],
      });
      setTours(res);
      setIsLoading(false);
    },
    [carTypeResponse, carTypeId]
  );

  const deleteHandler = useCallback(async () => {
    setIsLoading(true);
    const res = await DeleteSection(activeItem.applicationSectionId);
    if (res !== undefined) {
      showSuccess(
        getTranslate()(`${translationPath}section-deleted-successfully`)
      );
      setFilter((items) => ({ ...items, pageIndex: 0 }));
    } else showError(getTranslate()(`${translationPath}section-delete-failed`));
    setIsOpenConfirm(false);
    setIsLoading(false);
  }, [activeItem.applicationSectionId]);

  const previewHandler = useCallback(
    (item) => {
      setOpenPreview(true);
      getSectionTours(item);
    },
    [getSectionTours]
  );

  const onActionClicked = useCallback(
    (actionEnum, item) => (event) => {
      event.stopPropagation();
      setActiveItem(item);
      if (actionEnum === ActionsEnum.reportEdit.key)
        getHistory().push(
          `/home/sections/edit?id=${item.applicationSectionId}`
        );
      else if (actionEnum === ActionsEnum.reportView.key)
        previewHandler(item.applicationSectionId);
      else if (actionEnum === ActionsEnum.delete.key) setIsOpenConfirm(true);
      else {
        setIsOpenConfirm(true);
        setActiveItem(item);
      }
    },
    [previewHandler]
  );

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const onLoadMoreHandler = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: item.pageIndex + 1 }));
  }, []);

  const searchHandler = (e) => {
    const value = e.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((items) => ({ ...items, search: value }));
    }, 700);
  };

  useEffect(() => {
    getSections();
  }, [getSections]);

  return (
    <div className="recommended-tours-wrapper px-2">
      <Spinner isActive={isLoading} />
      <div className="header-section px-2">
        <div className="filter-section header-filter">
          <div className="section">
            <Button className="btns theme-solid" onClick={addNewTour}>
              {getTranslate()(`${translationPath}add-new-section`)}
            </Button>
            <Button
              className="btns theme-solid bg-primary"
              onClick={reOrderAll}
            >
              {getTranslate()(`${translationPath}re-order-all`)}
            </Button>
          </div>
          <div className="section w-50">
            <div className="d-flex-column p-relative">
              <Inputs
                idRef="tripsSearchRef"
                variant="outlined"
                fieldClasses="inputs theme-solid"
                translationPath={translationPath}
                label="search"
                placeholder="search-by-section-name"
                beforeIconClasses="mdi mdi-magnify mdi-24px c-gray-primary"
                onKeyUp={searchHandler}
              />
            </div>
          </div>
        </div>

        <div className="filter-section header-filter px-2">
          <span className="text-nowrap px2 mt-2">
            {getTranslate()(`${translationPath}filter-by`)}
          </span>
          <div className="section filter-font">
            <div className="w-100 px-2">
              <SelectComponet
                idRef="statusRef"
                data={FilterTypeEnum}
                initialSelectValue={null}
                textInput="key"
                valueInput="value"
                classes="theme-solid filter-changes"
                paddingReverse="2.5rem"
                value={filterType}
                onSelectChanged={(event) => setFilterType(event.target.value)}
                overInputTextIcon="mdi mdi-checkbox-blank-circle px-1"
                translationPathForData={translationPath}
              />
            </div>
          </div>
          <div className="section filter-font">
            <div className="w-100 px-2">
              <SelectComponet
                idRef="viewRef"
                data={PublishTypeEnum}
                initialSelectValue={0}
                textInput="key"
                valueInput="value"
                classes="theme-solid filter-changes"
                paddingReverse="2.5rem"
                value={publishType}
                onSelectChanged={(event) => setPublishType(event.target.value)}
                overInputTextIcon="mdi mdi-monitor-cellphone px-1"
                translationPathForData={translationPath}
              />
            </div>
          </div>
          <div className="px-2">
            <FormControl className="input-wrapper flex-wrap inputs theme-solid">
              <TextField
                placeholder={getTranslate()(`${translationPath}Select-Date`)}
                variant="outlined"
                value={
                  dateRange &&
                  dateRange.startDate &&
                  `From: ${moment(dateRange.startDate).format(
                    "DD/MM/yyyy"
                  )}, To: ${moment(dateRange.endDate).format("DD/MM/yyyy")}`
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="mdi mdi-calendar-range" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" className="mdi-icons-color">
                      {open ? (
                        <span className="mdi mdi-chevron-up" />
                      ) : (
                        <span className="mdi mdi-chevron-down" />
                      )}
                    </InputAdornment>
                  ),
                }}
                onClick={(e) => handleClick(e)}
              />
              <Popover
                className="report-basket-container"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className="btns-container">
                  <Button
                    onClick={() => {
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: null,
                        basketTourStartEnd: null,
                      }));
                    }}
                  >
                    {getTranslate()(`${translationPath}all-dates`)}
                  </Button>
                  <DateRangePickerComponent
                    isWideOvel
                    ranges={[dateRange]}
                    onDateChanged={(selectedDate) => {
                      setDateRange((items) => ({
                        ...items,
                        startDate:
                          selectedDate &&
                          selectedDate.range1 &&
                          selectedDate.range1.startDate,
                        endDate:
                          selectedDate &&
                          selectedDate.range1 &&
                          selectedDate.range1.endDate,
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate:
                          selectedDate &&
                          selectedDate.range1 &&
                          selectedDate.range1.startDate,
                        basketTourStartEnd:
                          selectedDate &&
                          selectedDate.range1 &&
                          selectedDate.range1.endDate,
                      }));
                    }}
                  />
                  <Button
                    onClick={() => {
                      setDateRange((items) => ({
                        ...items,
                        startDate: new Date(),
                        endDate: new Date(),
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: new Date(),
                        basketTourStartEnd: new Date(),
                      }));
                    }}
                  >
                    {getTranslate()(`${translationPath}today`)}
                  </Button>
                  <Button
                    onClick={() => {
                      const date = new Date();
                      date.setDate(date.getDate() - 1);
                      setDateRange((items) => ({
                        ...items,
                        startDate: date,
                        endDate: date,
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: date,
                        basketTourStartEnd: date,
                      }));
                    }}
                  >
                    {getTranslate()(`${translationPath}yesterday`)}
                  </Button>
                  <Button
                    onClick={() => {
                      const date = new Date();
                      const firstDay = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        1
                      );
                      const lastDay = new Date(
                        date.getFullYear(),
                        date.getMonth() + 1,
                        0
                      );
                      setDateRange((items) => ({
                        ...items,
                        startDate: firstDay,
                        endDate: lastDay,
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: firstDay,
                        basketTourStartEnd: lastDay,
                      }));
                    }}
                  >
                    {getTranslate()(`${translationPath}this-month`)}
                  </Button>
                  <Button
                    onClick={() => {
                      const date = new Date();
                      const firstDay = new Date(
                        date.getFullYear(),
                        date.getMonth() - 1,
                        1
                      );
                      const lastDay = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        0
                      );
                      setDateRange((items) => ({
                        ...items,
                        startDate: firstDay,
                        endDate: lastDay,
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: firstDay,
                        basketTourStartEnd: lastDay,
                      }));
                    }}
                  >
                    {getTranslate()(`${translationPath}past-month`)}
                  </Button>
                  <Button
                    onClick={() => {
                      const date = new Date();
                      const firstDay = new Date(
                        date.getFullYear(),
                        date.getMonth() - 3,
                        1
                      );
                      const lastDay = new Date(
                        date.getFullYear(),
                        date.getMonth() + 1,
                        0
                      );
                      setDateRange((items) => ({
                        ...items,
                        startDate: firstDay,
                        endDate: lastDay,
                      }));
                      setFilter((item) => ({
                        ...item,
                        basketTourStartDate: firstDay,
                        basketTourStartEnd: lastDay,
                      }));
                    }}
                  >
                    {getTranslate()(`${translationPath}past-3-month`)}
                  </Button>
                </div>
              </Popover>
            </FormControl>
          </div>
          <div className="px-2">
            <ButtonBase
              className="btns theme-solid"
              onClick={() => {
                setDateRange(dateRangeDefault);
                setSort(sortDefult);
                setFilter(filterDefault);
              }}
            >
              <span>{getTranslate()(`${translationPath}reset`)}</span>
            </ButtonBase>
          </div>
          <div className="section w-50"></div>
        </div>
      </div>
      <NoDataFoundComponent />
      {trips.result.length === 0 && !filter.search && !isFirstLoad && (
        <NoContentComponent />
      )}
      {(!activeItem || !isOpenDetailsMap) && (
        <RecommendedToursCardComponent
          data={trips}
          filter={filter}
          onLoadMore={onLoadMoreHandler}
          onActionClicked={onActionClicked}
          translationPath={translationPath}
          isLoading={isLoading}
          isFirstLoad={isFirstLoad}
          previewHandler={previewHandler}
          setIsMobile={setIsMobile}
          setIsWebsite={setIsWebsite}
          setActiveItem={setActiveItem}
        />
      )}
      <SectionViewPreviewDialog
        isMobile={isMobile}
        isWebsite={isWebsite}
        openPreview={openPreview}
        handleClose={() => setOpenPreview(false)}
        tours={tours}
        activeItem={activeItem}
      />
      <DialogComponent
        titleText="confirm-message"
        saveText="confirm"
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <span className="mdi mdi-close-octagon c-danger mdi-48px" />
            <span>
              {getTranslate()(`${translationPath}delete-description`)}
            </span>
          </div>
        }
        saveClasses="btns theme-solid bg-danger w-100 mb-0 mx-0 br-0"
        isOpen={isOpenConfirm}
        onSaveClicked={() => deleteHandler(activeItem)}
        translationPath={translationPath}
        onCloseClicked={() => setIsOpenConfirm(false)}
        onCancelClicked={() => setIsOpenConfirm(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { CarTypeReducer: carTypeResponse } = state;
  return {
    carTypeResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    CarTypeActions: bindActionCreators(CarTypeActions.carTypeSuccess, dispatch),
  };
};

const store = connect(mapStateToProps, mapDispatchToProps)(SectionsView);

export { store as SectionsView };
