import React, { useCallback, useEffect, useState } from 'react';
import CarsList from './CarsUtilties/CarsList/CarsList';
import CarsCard from './CarsUtilties/CarsCard/CarsCard';
import DialogAddTypeCar from './DialogCars/DialogAddTypeCar';
import { TextField, Grid, Button, FormControl, IconButton } from '@material-ui/core';
import { GetAllCarTypes } from './../../../Serviecs/Cars/CarsService';
import { getTranslate } from ' ./../../src/Helpers';
import DialogDeleteCars from ' ../../../src/Views/Home/Cars/DialogCars/DialogDeleteCars';
import DialogAddEditCar from ' ../../../src/Views/Home/Cars/DialogCars/DialogAddEditCar';
import { ViewTypes, Spinner } from '../../../Componentes';
import { ViewTypesEnum } from '../../../Enums';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { useTitle } from '../../../Hooks/useTitle';
import { NoDataFoundComponent, NoContentComponent } from '../../../Componentes';

const CarsView = () => {
  const [Response, SetResponse] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [openDialogDelete, setopenDialogDelete] = React.useState(false);
  const [openDialogAddEditCar, setDialogAddEditCar] = React.useState(false);
  const [carsid, setcarsid] = React.useState();
  const [CarDetails, setCarDetails] = React.useState({});
  const [CarNAME, setCarNAME] = React.useState('');
  const [search, setsearch] = React.useState('');
  const [isEdit, setIsEdit] = React.useState(true);
  const [statecar, setstatecar] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [responseResult, setResponseResult] = useState(() => ({ result: [], totalCount: 0 }));
  const [activeActionType, setActiveActionType] = React.useState(ViewTypesEnum.cards.key);
  let time = setTimeout(() => {}, 300);
  const translationPath = 'Cars.';
  useTitle(getTranslate()(`SideMenuView.MobilePages.Cars`));

  const GetAllCarsTypesApi = useCallback(
    async (pageIndex, pageSize, search) => {
      setLoading(true);
      const AllCarsTypes = await GetAllCarTypes(pageIndex, pageSize, search);
      SetResponse(AllCarsTypes);
      if (AllCarsTypes && AllCarsTypes.totalCount === 0) {
        setisFirstLoad(false);
      }

      if (page === 0) setResponseResult(AllCarsTypes);
      else {
        setResponseResult((item) => ({
          result: item.result.concat(AllCarsTypes.result),
          totalCount: AllCarsTypes.totalCount,
        }));
      }
      setLoading(false);
    },
    [page]
  );
  useEffect(() => {
    GetAllCarsTypesApi(page + 1, rowsPerPage, '');
  }, [GetAllCarsTypesApi, page, rowsPerPage]);

  const [openDialogAddCar, setDialogAddCar] = React.useState(false);

  const onTypeChanged = useCallback(
    (activeType) => {
      setPage(0);
      GetAllCarsTypesApi(1, 10, search);
      setActiveActionType(activeType);
    },
    [GetAllCarsTypesApi, search]
  );

  return (
    <>
      <Spinner isActive={loading} />
      <div>
        <div className='view-wrapper'>
          <div className='header-section'>
            <div className='filter-section px-2'>
              <div className='section'>
                <Button
                  className='btns theme-solid   btcolor '
                  onClick={() => {
                    setIsEdit(false);
                    setDialogAddCar(true);
                  }}>
                  {getTranslate()(`${translationPath}AddNewCar`)}
                </Button>
              </div>
              <div className='section px-2'>
                <div className='d-flex-column p-relative'>
                  <FormControl className='input-wrapper'>
                    <span className='mdi mdi-magnify theme-transparent mx-2' />
                    <TextField
                      className='inputs theme-solid'
                      value={search}
                      onKeyUp={() => {
                        time = setTimeout(() => {
                          GetAllCarsTypesApi(page + 1, rowsPerPage, search);
                        }, 850);
                      }}
                      onKeyDown={() => {
                        clearTimeout(time);
                      }}
                      onChange={(e) => {
                        setPage(0);
                        setsearch('');
                        setsearch(e.target.value);
                      }}
                      label={getTranslate()(`${translationPath}SearchinCars`)}
                      placeholder={getTranslate()(`${translationPath}search-by-car-type-name`)}
                      variant='outlined'
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {search && (
                              <IconButton
                                onClick={() => {
                                  setsearch('');
                                  GetAllCarsTypesApi(page + 1, rowsPerPage, '');
                                }}>
                                <ClearIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ViewTypes
                      onTypeChanged={onTypeChanged}
                      activeTypes={[ViewTypesEnum.cards.key, ViewTypesEnum.tableView.key]}
                      className='mb-3'
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoDataFoundComponent />
      {Response &&
        Response.result &&
        Response.result.length === 0 &&
        search.length === 0 &&
        !isFirstLoad && <NoContentComponent />}
      <Grid>
        {activeActionType === ViewTypesEnum.tableView.key ? (
          <CarsList
            search={search}
            isFirstLoad={isFirstLoad}
            Response={Response}
            setCarNAME={setCarNAME}
            page={page}
            setPage={(x) => setPage(x)}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(x) => setRowsPerPage(x)}
            reloadData={async (pageValue, rowsPerPageValue) =>
              await GetAllCarsTypesApi(pageValue, rowsPerPageValue, '')
            }
            openDialogDelete={() => setopenDialogDelete(true)}
            openDialogAddEditCar={() => setDialogAddEditCar(true)}
            setcarsid={setcarsid}
            setIsEdit={setIsEdit}
            setstatecar={setstatecar}
            setCarDetails={setCarDetails}
            setDialogAddCar={setDialogAddCar}
          />
        ) : (
          <CarsCard
            search={search}
            isFirstLoad={isFirstLoad}
            isLoading={loading}
            Response={responseResult}
            openDialogDelete={() => setopenDialogDelete(true)}
            setcarsid={setcarsid}
            setIsEdit={setIsEdit}
            setstatecar={setstatecar}
            setCarDetails={setCarDetails}
            setCarNAME={setCarNAME}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setDialogAddCar={setDialogAddCar}
            openDialogAddEditCar={() => setDialogAddEditCar(true)}
            reloadData={async () => {
              setResponseResult({ result: [], totalCount: 0 });
              await GetAllCarsTypesApi(1, rowsPerPage, '');
              setPage(0);
            }}
          />
        )}
        {openDialogAddCar && (
          <DialogAddTypeCar
            open={openDialogAddCar}
            close={() => setDialogAddCar(false)}
            CarDetails={CarDetails}
            isEdit={isEdit}
            setIsEdit={() => setIsEdit(false)}
            reloadData={async () => {
              setResponseResult({ result: [], totalCount: 0 });
              await GetAllCarsTypesApi(1, rowsPerPage, '');
              setPage(0);
            }}
          />
        )}
        <DialogDeleteCars
          open={openDialogDelete}
          close={() => setopenDialogDelete(false)}
          delteid={carsid}
          CarNAME={CarNAME}
          reloadData={async () => {
            setResponseResult({ result: [], totalCount: 0 });
            await GetAllCarsTypesApi(1, rowsPerPage, '');
            setPage(0);
          }}
        />
        <DialogAddEditCar
          open={openDialogAddEditCar}
          close={() => setDialogAddEditCar(false)}
          isEdit={isEdit}
          CarDetails={CarDetails}
          statecar={statecar}
          carsid={carsid}
          reloadData={async () => {
            setResponseResult({ result: [], totalCount: 0 });
            await GetAllCarsTypesApi(1, rowsPerPage, '');
            setPage(0);
          }}
        />
      </Grid>
    </>
  );
};
export { CarsView };
