import React, { useState, useCallback, useRef, useEffect } from 'react';
import { SelectComponet, Inputs, TimelineComponent } from './../../../../../Componentes';
import { GetAllReadyTours, GetForms, GetReadyTourById } from '../../../../../Serviecs';
import { getTranslate, GetParams } from '../../../../../Helpers';
import { ToursTripsCardComponent } from './ToursUtilities';
import { Button } from '@material-ui/core';
import './ToursManagementView.scss';
import './SectionViewManagement.scss';
const translationPath = 'SectionView.';

export const SectionViewDragDrop = ({ setState, activeCustomIndex, value, setValue }) => {
  const [isLoading] = useState(false);
  const [onDragItem, setOnDragItem] = useState(null);
  const [currentDragOverItem, setCurrentDragOverItem] = useState(null);
  const draggingElement = useRef(null);
  const dragOverTouchItem = useRef(null);
  const [droppedTrips, setDroppedTrips] = useState([]);
  const [isTripsLoading, setIsTripsLoading] = useState(false);
  const [tours, setTours] = useState({ result: [], totalCount: 0 });
  const [tripsTypes, setTripsTypes] = useState([]);
  const searchTimer = useRef(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    search: '',
  });

  useEffect(() => {
    setState((items) => {
      items.applicationSectionFilters[activeCustomIndex].numberOfTours =
        value.applicationSectionTours.length;
      items.applicationSectionFilters[activeCustomIndex].applicationSectionTours =
        value.applicationSectionTours;
      items.applicationSectionFilters[activeCustomIndex].applicationSectionTypes =
        value.applicationSectionTypes;
      return { ...items };
    });
  }, [value, setState, activeCustomIndex]);

  const getTrips = useCallback(async () => {
    setIsTripsLoading(true);
    const res = await GetAllReadyTours(filter);
    if (filter.pageIndex === 0) {
      setTours({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setTours((item) => ({
        result: item.result.concat((res && res.result) || []),
        totalCount: (res && res.totalCount) || 0,
      }));
    }
    setIsTripsLoading(false);
  }, [filter]);

  const getTripTypes = useCallback(async () => {
    setIsTripsLoading(true);
    const res = await GetForms(0, 10, '');
    setTripsTypes((res && res.result) || []);
    setIsTripsLoading(false);
  }, []);

  const onLoadMoreHandler = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: item.pageIndex + 1 }));
  }, []);
  const searchHandler = (event) => {
    const value = event.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((items) => ({ ...items, search: value }));
    }, 700);
  };
  const dragOverHandler = (event) => {
    event.preventDefault();
  };
  const fakeElementGenerator = (event) => {
    if (event.type === 'touchstart') {
      draggingElement.current = document.createElement('div');
      draggingElement.current.classList.add('tour-trip-card-component-wrapper');
      draggingElement.current.classList.add('p-absolute');
      draggingElement.current.classList.add('fake-dragging-item');
      draggingElement.current.setAttribute(
        'style',
        `top:${event.targetTouches[0].clientY + document.documentElement.scrollTop}px;left:${
          event.targetTouches[0].clientX
        }px`
      );
      draggingElement.current.appendChild(event.target.parentElement.cloneNode(true));
      document.body.appendChild(draggingElement.current);
    }
  };

  const dragHandler = (item, index, event) => {
    if (!onDragItem || onDragItem.index !== index) setOnDragItem({ item, index, from: 'tour' });
    fakeElementGenerator(event);
  };
  const dragTourTripHandler = (item, index, event) => {
    if (!onDragItem || onDragItem.index !== index)
      setOnDragItem({ item, index, from: 'tourSection' });
    fakeElementGenerator(event);
  };

  const dragOverTourHandler = useCallback(
    (index) => (event) => {
      event.preventDefault();
      const center =
        event.target.getBoundingClientRect().bottom +
        window.scrollY -
        event.target.offsetHeight / 2;
      const isBefore =
        (currentDragOverItem && currentDragOverItem.index + 1 === onDragItem.index) ||
        (event.pageY <= center &&
          currentDragOverItem &&
          currentDragOverItem.index - 1 !== onDragItem.index);
      if (
        !currentDragOverItem ||
        index !== currentDragOverItem.index ||
        currentDragOverItem.isBefore !== isBefore ||
        currentDragOverItem.dragOver !== 'tourSection'
      )
        setCurrentDragOverItem({ index, dragOver: 'tourSection', isBefore });
    },
    [currentDragOverItem, onDragItem]
  );

  const onTouchMoveHandler = useCallback(
    () => (event) => {
      draggingElement.current.setAttribute(
        'style',
        `top:${event.targetTouches[0].clientY + document.documentElement.scrollTop}px;left:${
          event.targetTouches[0].clientX
        }px`
      );
      dragOverTouchItem.current = document.elementFromPoint(
        event.targetTouches[0].clientX,
        event.targetTouches[0].clientY
      );
      if (!dragOverTouchItem.current || !dragOverTouchItem.current.classList) return;
      if (dragOverTouchItem.current.classList.contains('tour-trips-timeline-wrapper')) {
        if (currentDragOverItem) setCurrentDragOverItem(null);
        return;
      }
      const dragOverFrom = dragOverTouchItem.current.getAttribute('data-from');
      const index = droppedTrips.findIndex(
        (item) => item.tourId === +dragOverTouchItem.current.getAttribute('data-id')
      );
      if (dragOverFrom === 'tour' && index !== -1) {
        if (!currentDragOverItem || currentDragOverItem.index !== index)
          setCurrentDragOverItem({
            index,
            dragOver: dragOverTouchItem.current.getAttribute('data-from'),
            isBefore: true,
          });
      }
    },
    [currentDragOverItem, droppedTrips]
  );
  const tripTouchEndHandler = () => {
    draggingElement.current.remove();
    if (
      dragOverTouchItem.current &&
      dragOverTouchItem.current.classList.contains('tour-trips-timeline-wrapper')
    )
      dropHandler();
    else if (currentDragOverItem) dropHandler();
  };

  const dropHandler = () => {
    if (
      currentDragOverItem &&
      currentDragOverItem.index === onDragItem.index &&
      currentDragOverItem.dragOver === onDragItem.from
    ) {
      setCurrentDragOverItem(null);
      setOnDragItem(null);
      return;
    }
    const localDroppedTrips = [...droppedTrips];
    if (currentDragOverItem !== null) {
      if (currentDragOverItem.dragOver === onDragItem.from)
        localDroppedTrips.splice(
          currentDragOverItem.isBefore ? currentDragOverItem.index : currentDragOverItem.index + 1,
          0,
          localDroppedTrips.splice(onDragItem.index, 1)[0]
        );
      else
        localDroppedTrips.splice(
          currentDragOverItem.isBefore ? currentDragOverItem.index : currentDragOverItem.index + 1,
          0,
          onDragItem.item
        );
    } else localDroppedTrips.push(onDragItem.item);
    setCurrentDragOverItem(null);
    setOnDragItem(null);
    setDroppedTrips(localDroppedTrips);
    setValue((items) => ({
      ...items,
      applicationSectionTours: [...localDroppedTrips.map((item) => ({ tourId: item.tourId }))],
    }));
  };

  const removeTourTripHandler = useCallback(
    (index) => () => {
      droppedTrips.splice(index, 1);
      setDroppedTrips(droppedTrips);
      setValue({ ...value, applicationSectionTours: droppedTrips });
      const tourIds = droppedTrips.map((item) => item.tourId);
      setFilter((items) => ({
        ...items,
        pageIndex: 0,
        body: { ...items.body, tourIds },
      }));
    },
    [droppedTrips, setValue, value]
  );

  const getTourInfo = async (id) => {
    const result = await GetReadyTourById(id);
    let total = 0;
    result.tourTrips.map((element) => {
      total = total + (element.trip && element.trip.tripDuration) || 0;
      return undefined;
    });
    setDroppedTrips((items) => {
      items.push({
        tourName: result.tourName,
        tourId: result.tourId,
        fromPrice: result.totalFromPriceAfterDiscount,
        totalDestinations: result.tourName.split('- ')[1],
        totalDuration: `${Math.round(total / 60)} H`,
        tripsTours: result.tourTrips.map((element) => ({
          coverTripImageId: (element.trip && element.trip.coverTripImageId) || null,
        })),
      });
      return [...items];
    });
  };

  useEffect(() => {
    getTrips();
  }, [filter, getTrips]);

  useEffect(() => {
    const editId = GetParams('id');
    if (editId !== null) {
      value && value.applicationSectionTours.map((item) => getTourInfo(item.tourId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTripTypes();
  }, [getTripTypes]);

  useEffect(() => {
    if (
      document.body.scrollHeight === window.innerHeight &&
      tours.totalCount < tours.result.length &&
      !isLoading
    )
      onLoadMoreHandler();
  }, [isLoading, onLoadMoreHandler, tours]);

  const result = tripsTypes.filter(
    (item) => value.applicationSectionTypes.findIndex((el) => el.formsId === item.formsId) !== -1
  );

  return (
    <div className='view-wrapper tours-content timeline-padding'>
      <div className='tours-drag select-tour-wrapper'>
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='search-wrapper mt-2'>
              {getTranslate()(`${translationPath}select-tours`)}
              <div className=''>
                <div className='d-flex-column p-relative'>
                  <Inputs
                    idRef='tripsSearchRef'
                    variant='outlined'
                    fieldClasses='inputs theme-solid'
                    translationPath={translationPath}
                    label='search'
                    beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                    onKeyUp={searchHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='drag-filter w-100 px-2'>
          <span className='text-nowrap px-1 mt-1'>
            {getTranslate()(`${translationPath}filter-by`)}
          </span>
          <div className='px-2 w-50'>
            <SelectComponet
              idRef='statusRef'
              data={tripsTypes || []}
              valueInput='formsId'
              textInput='formsName'
              classes='theme-solid filter-changes'
              paddingReverse='2.5rem'
              value={value.applicationSectionTypes}
              onSelectChanged={(e) => {
                '';
              }}
              overInputTextIcon='mdi mdi-checkbox-blank-circle px-1'
              overInputText='type'
              translationPath={translationPath}
            />
          </div>
        </div>

        <div className='drag-filter w-100 px-2 mb-3'>
          <span className='text-nowrap px-1'>{getTranslate()(`${translationPath}select`)}</span>
          <div className='px-2 ml-2 mw-50'>
            <SelectComponet
              multiple
              getIsChecked={(option) =>
                value.applicationSectionTypes.findIndex(
                  (item) => item.formsId === option.formsId
                ) !== -1
              }
              renderValue={(selected) => {
                const result = tripsTypes.filter((item) => selected.includes(item.formsId));
                return result.map((element) => element.formsName).join(', ');
              }}
              idRef='selectRef'
              data={tripsTypes || []}
              valueInput='formsId'
              textInput='formsName'
              classes='theme-solid filter-changes tour-types-check'
              paddingReverse='7.5rem'
              value={value.applicationSectionTypes.map((item) => item.formsId)}
              onSelectChanged={(e) => {
                setValue((items) => ({
                  ...items,
                  applicationSectionTypes: e.target.value.map((item) => ({
                    formsId: item,
                  })),
                }));
              }}
              overInputTextIcon='mdi mdi-checkbox-blank-circle px-1'
              overInputText='tour-type'
              translationPath={translationPath}
            />
          </div>
        </div>
        <div className='select-drag-tours w-100'>
          <div className='tour-trips-wrapper'>
            <div className='trips-view view-wrapper'>
              <ToursTripsCardComponent
                data={tours}
                onLoadMore={onLoadMoreHandler}
                filterData={droppedTrips}
                filterInput='tourId'
                translationPath={translationPath}
                isLoading={isTripsLoading}
                draggable
                dragFrom='tour'
                onDrag={dragHandler}
                touchEnd={tripTouchEndHandler}
                onTouchMove={onTouchMoveHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='tours-drop'>
        {(value.tripsTypes !== '' || value.tourTypes.length !== 0) &&
          result.map((item, i) => (
            <div key={`drop-${i}`} className='selected-tours px-2 mt-2 mb-2'>
              {item.formsName}
            </div>
          ))}

        <div onDrop={dropHandler} onDragOver={dragOverHandler}>
          <div className='form-content'>
            <div className='tour-trips-timeline-wrapper'>
              <TimelineComponent
                data={droppedTrips}
                onDragOver={dragOverTourHandler}
                onDrag={dragTourTripHandler}
                onTouchEnd={tripTouchEndHandler}
                onTouchMove={onTouchMoveHandler}
                draggable
                getTimelineContent={(item, index) => (
                  <React.Fragment>
                    {currentDragOverItem &&
                      index === currentDragOverItem.index &&
                      (currentDragOverItem.dragOver !== onDragItem.from ||
                        (currentDragOverItem.dragOver === onDragItem.from &&
                          currentDragOverItem.index - 1 !== onDragItem.index &&
                          currentDragOverItem.index !== onDragItem.index)) && (
                        <span
                          className={`drop-navigator${
                            (currentDragOverItem.isBefore && ' is-drop') || ''
                          }${(false && ' not-allowed') || ''}`}>
                          {getTranslate()(`${translationPath}drop-here`)}
                        </span>
                      )}
                    <div className='p-relative'>
                      <Button
                        className='btns-icon theme-transparent c-danger p-absolute-t-2-r-3'
                        onClick={removeTourTripHandler(index)}>
                        <span className='mdi mdi-close c-danger'></span>
                      </Button>
                      <ToursTripsCardComponent
                        data={{ result: [{ ...item }] }}
                        translationPath={translationPath}
                        isFullWidth
                        key='tourRef'
                        dragFrom='tourSection'
                      />
                    </div>
                    {!currentDragOverItem ||
                      (currentDragOverItem &&
                        index === currentDragOverItem.index &&
                        (currentDragOverItem.dragOver !== onDragItem.from ||
                          (currentDragOverItem.dragOver === onDragItem.from &&
                            currentDragOverItem.index !== onDragItem.index &&
                            currentDragOverItem.index + 1 !== onDragItem.index)) && (
                          <span
                            className={`drop-navigator${
                              (!currentDragOverItem.isBefore && ' is-drop') || ''
                            }${(false && ' not-allowed') || ''}`}>
                            <span>{getTranslate()(`${translationPath}drop-here`)}</span>
                          </span>
                        ))}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
