import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';
import './TimelineComponent.scss';

export const TimelineComponent = ({
  data,
  getTimelineContent,
  key,
  onDrag,
  onDragOver,
  onDragEnd,
  onTouchEnd,
  onTouchMove,
  draggable,
}) => {
  const dragHandler = useCallback(
    (item, index) => (event) => {
      onDrag(item, index, event);
    },
    [onDrag]
  );
  const onTouchEndHandler = useCallback(
    (index) => (event) => {
      onTouchEnd(index, event);
    },
    [onTouchEnd]
  );
  return (
    <Timeline
      className={`timeline-wrapper${(draggable && ' is-draggable') || ''}`}
      align={localStorage.getItem('localization').isRtl ? 'right' : 'left'}>
      {data &&
        data.map((item, index) => (
          <TimelineItem key={`${key}${index + 1}`}>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent>
              <div
                className='w-100'
                draggable={draggable}
                onDrag={(onDrag && dragHandler(item, index)) || undefined}
                onTouchStart={(onDrag && dragHandler(item, index)) || undefined}
                onTouchMove={(onTouchMove && onTouchMove(item, index)) || undefined}
                onDragEnd={onDragEnd}
                onTouchEnd={(onTouchEnd && onTouchEndHandler(index)) || undefined}
                onDragOver={(onDragOver && onDragOver(index)) || undefined}>
                {(getTimelineContent && getTimelineContent(item, index)) || null}
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};

TimelineComponent.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  key: PropTypes.string,
  onDrop: PropTypes.func,
  onDragOver: PropTypes.func,
  onDragEnd: PropTypes.func,
  draggable: PropTypes.bool,
  getTimelineContent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
};
TimelineComponent.defaultProps = {
  getTimelineContent: undefined,
  onDrop: undefined,
  onDragOver: undefined,
  draggable: false,
  onDragEnd: undefined,
  key: 'timelineKey',
};
