import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Spinner,
  // ViewTypes,
  Inputs,
  DialogComponent,
  NoContentComponent,
  NoDataFoundComponent,
  CollapseComponent,
} from './../../../Componentes';
import {
  getTranslate,
  getHistory,
  showSuccess,
  showError,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from '../../../Helpers';
import { Button } from '@material-ui/core';
import { ActionsEnum } from '../../../Enums';
import { GetAllTrips, DeleteTrip } from '../../../Serviecs';
import { TripsCardComponent, TripDetailsComponent, MapDetailsComponent } from './TripsUtilities';
import { useTitle } from '../../../Hooks/useTitle';
const translationPath = 'home.trips.tripsView.';

export const TripsView = () => {
  // const [activeActionType, setActiveActionType] = useState(ViewTypesEnum.cards.key);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [isOpenDetailsMap, setIsOpenDetailsMap] = useState(false);
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [filter, setFilter] = useState({
    pageSize: 10,
    pageIndex: 0,
    search: '',
  });
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [trips, setTrips] = useState({ result: [], totalCount: 0 });
  const searchTimer = useRef(null);
  useTitle(getTranslate()(`${translationPath}trips`));

  // const onTypeChanged = (activeType) => {
  //   setActiveActionType(activeType);
  // };
  const addNewTrip = () => {
    getHistory().push('/home/trips/add');
  };
  const getTrips = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllTrips(filter);
    setisFirstLoad(false);
    // || activeActionType !== ViewTypesEnum.cards.key
    if (filter.pageIndex === 0) {
      setTrips({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setTrips((item) => ({
        result: item.result.concat((res && res.result) || []),
        totalCount: (res && res.totalCount) || 0,
      }));
    }
    setIsLoading(false);
  }, [filter]);
  // activeActionType,
  const deleteHandler = useCallback(async () => {
    setIsLoading(true);
    const res = await DeleteTrip(activeItem.tripId);
    if (res !== undefined) {
      showSuccess(getTranslate()(`${translationPath}trip-deleted-successfully`));
      setFilter((items) => ({ ...items, pageIndex: 0 }));
    } else showError(getTranslate()(`${translationPath}trip-delete-failed`));
    setIsOpenConfirm(false);
    setIsLoading(false);
  }, [activeItem.tripId]);
  const onActionClicked = useCallback(
    (actionEnum, item, index) => (event) => {
      event.stopPropagation();
      if (actionEnum === ActionsEnum.reportEdit.key)
        getHistory().push(`/home/trips/edit?id=${item.tripId}`);
      else {
        setIsOpenConfirm(true);
        setActiveItem(item);
      }
    },
    []
  );
  const onCardClicked = useCallback(
    (item) => () => {
      sideMenuComponentUpdate(
        <TripDetailsComponent
          tripId={item.tripId}
          translationPath={translationPath}
          onDeleteClicked={async (item) => {
            setActiveItem(item);
            setIsOpenConfirm(true);
          }}
          onMapClick={(item) => () => {
            setActiveItem(item);
            setIsOpenDetailsMap((item) => !item);
          }}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    []
  );

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const onLoadMoreHandler = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: item.pageIndex + 1 }));
  }, []);
  const searchHandler = (e) => {
    const value = e.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((items) => ({ ...items, pageIndex: 0, search: value }));
    }, 700);
  };
  useEffect(() => {
    getTrips();
  }, [getTrips, filter]);
  useEffect(() => {
    return () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    };
  }, []);

  return (
    <div className='trips-view view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <Button className='btns theme-solid' onClick={addNewTrip}>
              {getTranslate()(`${translationPath}add-new-trip`)}
            </Button>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <Inputs
                idRef='tripsSearchRef'
                variant='outlined'
                fieldClasses='inputs theme-solid'
                translationPath={translationPath}
                label='filter'
                beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                onKeyUp={searchHandler}
                inputPlaceholder='search-by-trip-name'
              />
            </div>
            {/* <div>
              <ViewTypes
                onTypeChanged={onTypeChanged}
                activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
                className='mb-3'
              />
            </div> */}
          </div>
        </div>
      </div>
      <NoDataFoundComponent />
      {trips.result.length === 0 && !filter.search && !isFirstLoad && <NoContentComponent />}
      {/* activeActionType === ViewTypesEnum.cards.key && */}
      {(!activeItem || !isOpenDetailsMap) && (
        <TripsCardComponent
          data={trips}
          filter={filter}
          onLoadMore={onLoadMoreHandler}
          onActionClicked={onActionClicked}
          onCardClicked={onCardClicked}
          translationPath={translationPath}
          isLoading={isLoading}
          isFirstLoad={isFirstLoad}
        />
      )}
      {activeItem && (
        <CollapseComponent
          isOpen={isOpenDetailsMap}
          classes=''
          component={
            <MapDetailsComponent
              activeItem={activeItem}
              isOpenDetailsMap={isOpenDetailsMap}
              onCloseClicked={() => {
                setIsOpenDetailsMap(false);
              }}
            />
          }
        />
      )}
      <DialogComponent
        titleText='confirm-message'
        saveText='confirm'
        saveType='button'
        maxWidth='sm'
        dialogContent={
          <div className='d-flex-column-center'>
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span>{getTranslate()(`${translationPath}delete-description`)}</span>
          </div>
        }
        saveClasses='btns theme-solid bg-danger w-100 mb-0 mx-0 br-0'
        isOpen={isOpenConfirm}
        onSaveClicked={deleteHandler}
        translationPath={translationPath}
        onCloseClicked={() => setIsOpenConfirm(false)}
        onCancelClicked={() => setIsOpenConfirm(false)}
      />
    </div>
  );
};
