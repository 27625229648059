import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import { getTranslate } from '../../Helpers';
import { BreadCrumbRoutes } from '../../Routes/BreadCrumbRoutes/BreadCrumbRoutesRoutes';

const getBreadCrumbItems = () => {
  const currentRoute = BreadCrumbRoutes.find(
    (item) => window.location.pathname.split('?')[0] === item.layout + item.path
  );

  if (!currentRoute) return [];
  return currentRoute || [];
};

const Breadcrumb = () => (
  <>
    {window.location.pathname !== '/home' ? (
      <Breadcrumbs aria-label='breadcrumb' className='breadcrumb-wrapper'>
        <NavLink to='/home' exact className='breadcrumb-link' activeClassName='active'>
          {getTranslate()('home.home')}
        </NavLink>
        {Array.isArray(getBreadCrumbItems().breadcrumbs) &&
          getBreadCrumbItems().breadcrumbs.map((item) => (
            <NavLink
              to={item.route}
              key={`breadcrumb${item.itemId}`}
              exact={item.isExact}
              onClick={(event) => {
                if (item.isDisabled) event.preventDefault();
              }}
              className='breadcrumb-link'
              activeClassName='active'>
              {getTranslate()(item.name)}
            </NavLink>
          ))}
      </Breadcrumbs>
    ) : (
      <></>
    )}
  </>
);

export { Breadcrumb };
