import React, { useEffect, useCallback }      from 'react'                                                        ;
import    { EditCar }                         from '../../../../Serviecs/Cars/CarsService'                        ;
import { CreateCarsDto }                      from '../../../../Serviecs/Cars/CarsService'                        ;
import { GetAllParking }                      from '../../../../Serviecs/Parking/ParkingService'                  ;
import { AutocompleteComponent }              from '../../../../Componentes/Controls'                             ;
import { ActivateCar, ApplicationUserSearch } from '../../../../Serviecs/Cars/CarsService'                        ;
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, Grid, FormControl, Typography, Divider, } 
                                              from '@material-ui/core'                                            ;
import Radio                                  from '@material-ui/core/Radio'                                      ;
import RadioGroup                             from '@material-ui/core/RadioGroup'                                 ;
import FormControlLabel                       from '@material-ui/core/FormControlLabel'                           ;
import { getErrorByName }                     from '../../../../Helpers'                                          ;
import Lookups                                from '../../../../Assets/JSON/StaticLookupsIds.json'                ;
import { getTranslate, showSuccess       }    from '../../../../Helpers'                                          ;
import { Spinner, Inputs, SelectComponet }    from '../../../../Componentes'                                      ;
import              Joi                       from 'joi'                                                          ;
import './../CarsView.scss'                                                                                       ;
import './DialogAddCar.scss'                                                                                      ;
const  translationPath                     =      'Cars.DialogAddEditCar.'                                        ;

const DialogAddCar = (props) => {
  const [res, setres] = React.useState([]);
  const [ParkingId, setParkingId] = React.useState('');
  const [carNumber, setcarNumber] = React.useState('');
  const [carColor, setcarColor] = React.useState('#000000');
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [carTypeID, setcarTypeID] = React.useState('');
  const [SearchDriver, setSearchDriver] = React.useState([]);
  const [Drivername] = React.useState('');
  const [Driverid, setDriverid] = React.useState('');
  const [nameofdriver, setnameofdriver] = React.useState('');
  let time = setTimeout(() => {}, 300);
  const GetAllParkingApi = useCallback(async (pageIndex, pageSize) => {
    const AllParkingres = await GetAllParking(pageIndex, pageSize);
    setres(AllParkingres && AllParkingres.result ? AllParkingres.result : []);
  }, []);

  useEffect(() => {
    GetAllParkingApi(1, 10);
  }, [GetAllParkingApi, setres]);

  const ApplicationUserSearchApi = async (name, userTypeId, pageIndex, pageSize) => {
    const AllDriver = await ApplicationUserSearch({ name, userTypeId, pageIndex, pageSize });
    setSearchDriver(AllDriver && AllDriver.result ? AllDriver.result : []);
  };
  useEffect(() => {
    ApplicationUserSearchApi(Drivername.fullName, Lookups.DRIVER, 1, 10);
  }, [Drivername.fullName]);
  useEffect(() => {
    if (props.isEdit) {
      setParkingId(props.ParkingId);
      setcarNumber(props.carNumber);
      setcarColor(props.carColor);
      setcarTypeID(props.carTypeID);
      setDriverid(props.driverId);
      setnameofdriver(props.driverNAME);
      props.status === 'InActive' ? setValue('false') : setValue('true');
    } else {
      setParkingId('');
      setcarNumber('');
      setDriverid('');
      setnameofdriver('');
      setcarColor('#000000');
    }
  }, [
    props.ParkingId,
    props.carNumber,
    props.carColor,
    props.carTypeID,
    props.isEdit,
    props.status,
    props.driverNAME,
    props.driverId,
  ]);
  let Feedback = true;

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const schema = Joi.object({
    carNumber: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}ValidationErrorcarPlateNumber`),
      }),
    Drivername: Joi.string()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrorcarDrivername`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({
      carNumber,
      carColor,
    });

  return (
   
    <Dialog  keepMounted open={props.open} onClose={props.close} className='sliderDialogs'>
      <Spinner isActive={loading} />
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          props.close();
        }}>
        <div className='colse-button'>
          <Button
            className='MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid bg-danger mx-2 mb-2'
            tabindex='0'
            title={getTranslate()(`${translationPath}Cancel`)}
            type='button'
            onClick={() => {
              props.close();
              setParkingId('');
              setcarNumber('');
              setcarColor('#000000');
            }}>
            <span className='MuiButton-label'>
              <span className='mdi mdi-close'></span>
            </span>
            <span className='MuiTouchRipple-root'></span>
          </Button>
        </div>
        <DialogTitle className='dialogTitle'>
          <Typography>
            {props.isEdit !== false
              ? getTranslate()(`${translationPath}EditCar`) + ' ' + props.indexcar
              : getTranslate()(`${translationPath}AddNewCar`)}
          </Typography>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid container justify='center' spacing={2}>
            <Grid container justify='center'>
              <Grid item xs={8} className='mb-3'>
                <FormControl className='input-wrapper flex-wrap textField'>
                  <Typography> {getTranslate()(`${translationPath}Platenumber`)}</Typography>
                  {props.open&&(
                  <Inputs
                    idRef='carNumber'
                    translationPath={translationPath}
                    isWithError
                    helperText={getErrorByName(schema, 'carNumber').message}
                    inputPlaceholder='Platenumber'
                    error={getErrorByName(schema, 'carNumber').error}
                    value={carNumber}
                    onInputChanged={(e) => setcarNumber(e.target.value)}          
                  />)}
                </FormControl>
              </Grid>
              <Grid item xs={8} className='mb-3'>
                <Grid item xs={2}>
                  <FormControl
                    variant='outlined'
                    className='input-wrapper flex-wrap textField colorfiled'
                    size='small'>
                    <Typography> {getTranslate()(`${translationPath}Color`)}</Typography>
                    <Inputs
                      idRef='carNumber'
                      type='color'
                      value={carColor}
                      onInputChanged={(e) => setcarColor(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={8} className='mb-3'>
                <Typography> {getTranslate()(`${translationPath}parkingName`)}</Typography>
                {props.open&&(
                <FormControl
                  variant='outlined'
                  className='input-wrapper flex-wrap textField'
                  size='small'>
                  <SelectComponet
                    idRef='parkingName'
                    data={res}
                    value={ParkingId}
                    onSelectChanged={(e) => setParkingId(e.target.value)}
                    isWithError
                    // helperText={getErrorByName(schema, 'color').message}
                    valueInput='parkingId'
                    textInput='parkingName'
                    emptyItem={{
                      value: '',
                      text: 'Select Parking Name',
                    }}
                    //error={getErrorByName(schema, 'color').error}
                  />
                </FormControl>)}
              </Grid>
              <Grid item xs={8} className='mb-3'>
              {props.open&&(
                <FormControl
                  variant='outlined'
                  className='input-wrapper flex-wrap textField'
                  size='small'>
                  <AutocompleteComponent
                    translationPath={translationPath}
                    value={Drivername.id}
                    inputValue={nameofdriver}
                    getOptionLabel={(option) => option.fullName || ''}
                    options={SearchDriver || []}
                    inputPlaceholder='users'
                    labelValue='users'
                    getOptionSelected={(option) => Drivername.id === option.id}
                    //  helperText={getErrorByName(schema, 'nationality').message}
                    //  error={getErrorByName(schema, 'nationality').error}
                    //  isWithError
                    //  isSubmitted={isSubmitted}
                    onKeyUp={(e) => {
                      const value = e.target.value;
                      time = setTimeout(() => {
                        ApplicationUserSearchApi(value, Lookups.DRIVER, 1, 10);
                      }, 1000);
                    }}
                    onKeyDown={() => {
                      clearTimeout(time);
                    }}
                    onChange={(event, newValue) => {
                      if (newValue == null) {
                        setDriverid(''); 
                      } else {
                        setDriverid(newValue.id);
                      }
                    }}
                    onInputChange={(event, newValue) => {
                      setnameofdriver(newValue);
                    }}
                    multiple={false}
                    withoutSearchButton
                  />
                </FormControl>)}
              </Grid>
              {props.isEdit === true ? (
                props.statecar !== 'InActive' ? (
                  <Grid item xs={8} className='mb-3' >
                    <Typography> {getTranslate()(`${translationPath}status`)}</Typography>
                    <FormControl variant='outlined' className='input-wrapper ' size='medium'>
                      <RadioGroup
                        aria-label='gender'
                        name='gender1'
                        row
                        value={value}
                        onChange={handleChange}>
                        <FormControlLabel
                          value='true'
                          control={<Radio color='primary' />}
                          label={getTranslate()(`${translationPath}Active`)}
                        />
                        <FormControlLabel
                          value='false'
                          control={<Radio color='primary' />}
                          label={getTranslate()(`${translationPath}Inactive`)}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </form>
      <Grid container justify='center'>
        <Button
          onClick={() => {
            props.close();
            setParkingId('');
            setcarNumber('');
            setcarColor('#000000');
          }}
          color='primary'
          className='btns bg-cancel'>
          {getTranslate()(`${translationPath}Cancel`)}
        </Button>
      </Grid>
      <div className={
      (ParkingId === '' || carNumber === '' || carColor === '' || Driverid === '') ? 
      'doneBtnContainercaradddisable' :'doneBtnContainercaradd'  }  >
        <Grid container justify='center'>
          <Grid item xs={12} className='doneBtn'>
            <Button
              disabled={ParkingId === '' || carNumber === '' || carColor === '' || Driverid === ''}
              onClick={async () => {
                setLoading(true);
                try {
                  props.isEdit
                    ? (await EditCar(+props.carsid, {
                        carTypesId: +carTypeID,
                        carNumber: carNumber,
                        carColor: carColor,
                        parkingId: +ParkingId,
                        driverId: Driverid,
                      })) && (await ActivateCar(+props.carsid, JSON.parse(value)))
                    : (Feedback = await CreateCarsDto({
                        carTypesId: +props.carsid,
                        carNumber: carNumber,
                        carColor: carColor,
                        parkingId: +ParkingId,
                        driverId: Driverid,
                      }));

                  if (props.isEdit) {
                    showSuccess(getTranslate()(`${translationPath}NotificationshowEditSuccess`));
                   }else {
                    if (Feedback !== false) {
                      showSuccess(getTranslate()(`${translationPath}NotificationshowADDSuccess`));
                    } 
                    setParkingId('');
                    setcarNumber('');
                    setDriverid('');  
                    setnameofdriver('');
                    setcarColor('#000000');
                  }
                  props.close();
                  setLoading(false);
                  props.reloadData();
                } catch (error) {
                  props.close();
                  setLoading(false);
                  setParkingId('');
                  setcarNumber('');
                  setDriverid('');  
                  setnameofdriver('');
                  setcarColor('#000000');
                }
              }}
              className='btns theme-solid'>
              {getTranslate()(`${translationPath}Done`)}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};
export default DialogAddCar;
