import React from 'react';
import './UserStatsComponent.scss'
import Grid from '@material-ui/core/Grid';
import {TotalVisitor} from './TotalVisitors'
import {WebsiteVisitors} from './WebsiteVisitors'
import {MobileAppVisitors} from './MobileAppVisitors'
import {TotalSales} from './TotalSales'


export const  UsersStatsComponent=()=> {
    

    return (
        <div className="TotalStats">
        <Grid container spacing={2} >

        <Grid  item xs={12} sm={8} md={6} lg={3}>
        <TotalSales/>
      </Grid>

      <Grid  item xs={12} sm={8} md={6} lg={3}>
     <WebsiteVisitors/>
    </Grid>

    <Grid  item xs={12} sm={8} md={6} lg={3}>
   <MobileAppVisitors/>
  </Grid>

  <Grid  item xs={12} sm={8} md={6} lg={3}>
  <TotalVisitor/>
</Grid>

</Grid>
</div>
    )
}
