import React, { memo } from 'react';
import { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';

const Calendar = memo((props) => (
  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale='en'>
    <div className={`calendars${props.hideHeader ? ' hide-header' : ''}${props.wrapperClasses}`}>
      {props.isInputPicker && (
        <KeyboardDatePicker
          disableToolbar
          variant='inline'
          format={props.format}
          margin='normal'
          id='date-picker-inline'
          label='Date picker inline'
          value={props.selectedDate}
          onChange={props.selectedDateChanged}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      )}
      <DatePicker
        autoOk
        orientation='portrait'
        variant='static'
        openTo='date'
        // disableFuture
        value={props.selectedDate}
        onChange={props.selectedDateChanged}
        disablePast={props.disablePast || props.isDisabled}
        disableFuture={props.disableFuture || props.isDisabled}
        disabled={props.isDisabled}
        // labelFunc={(date) => (date ? date.format('YYYY-MM-DD') : '')}
        // dateForm="YYYY-MM-DD"

        renderDay={
          props.dayRender ||
          ((day, selectedDate, isInCurrentMonth, dayComponent) => {
            const isSelected =
              isInCurrentMonth &&
              props.events &&
              props.events.dates &&
              props.events.dates.findIndex((item) => moment(item).isSame(day, 'day')) !== -1;
            if (!isSelected)
              return <span className={props.dayButtonWrapperClasses}>{dayComponent}</span>;
            if (props.selectedDayComponent) props.selectedDayComponent(props.events, dayComponent);
            if (props.onlyColorChanged)
              return (
                <span className={`${props.dayButtonWrapperClasses} have-events`}>
                  {dayComponent}
                </span>
              );
            if (props.withNumber) {
              return (
                <Badge
                  className='badges'
                  badgeContent={
                    props.events.dates.filter((item) => moment(item).isSame(day, 'day')).length
                  }>
                  <span className={`${props.dayButtonWrapperClasses} have-events`}>
                    {dayComponent}
                  </span>
                </Badge>
              );
            }
            return <span>{dayComponent}</span>;
          })
        }
      />
    </div>
  </MuiPickersUtilsProvider>
));
Calendar.propTypes = {
  selectedDateChanged: PropTypes.func.isRequired,
  selectedDate: PropTypes.objectOf(PropTypes.any),
  selectedDayComponent: PropTypes.func,
  dayRender: PropTypes.func,
  hideHeader: PropTypes.bool,
  withNumber: PropTypes.bool,
  onlyColorChanged: PropTypes.bool,
  events: PropTypes.shape({
    dates: PropTypes.instanceOf(Array),
  }),
  format: PropTypes.string,
  isInputPicker: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  wrapperClasses: PropTypes.string,
  dayButtonWrapperClasses: PropTypes.string,
};
Calendar.defaultProps = {
  selectedDate: undefined,
  dayRender: undefined,
  hideHeader: true,
  withNumber: false,
  onlyColorChanged: true,
  selectedDayComponent: undefined,
  events: {
    dates: [],
  },
  format: '',
  isInputPicker: false,
  isDisabled: false,
  disablePast: false,
  disableFuture: false,
  wrapperClasses: '',
  dayButtonWrapperClasses: 'day-button-wrapper',
};
export { Calendar };
