import React, { useEffect, useCallback, useState } from 'react';
import { getTranslate, getDownloadableLink } from '../../../../../../Helpers';
import { LoadableImageComponant , Spinner } from '../../../../../../Componentes';
import { ContactTypeEnum, LoadableImageEnum } from '../../../../../../Enums';
import { getLanguage } from 'react-switch-lang';
import { GetToursInformation } from '../../../../../../Serviecs/UsersServices/userServices';
import './UserSlider.scss';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
const translationPath = 'Administration.Users.Users.';

const UserSlider = ({ item, index }) => {
  const [Tours, setTours] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const GetToursInformationAPI = useCallback(async (UserID) => {
    setIsLoading(true);
    const ToursInfo = await GetToursInformation(UserID);
    setTours(ToursInfo);
    setIsLoading(false);
  }, []);
  useEffect(() => {
    GetToursInformationAPI(item.id);
  }, [GetToursInformationAPI, item]);
  useEffect(() => {}, []);
  return (
    <div className='slideruser'>
      <div className='users-card-component-wrapper'>
        <div className='users-card-wrapper' key={`userCardRef${index + 1}`}>
        <Spinner isActive={isLoading} isAbsolute />
          <div className='cards-wrapper'>
            <div className='cards-header'>
              <div className='item-wrapper'>
                <LoadableImageComponant
                  type={LoadableImageEnum.div.key}
                  classes='user-cover-image'
                  alt={getTranslate()(`${translationPath}user-image`)}
                  src={
                    (item.profileImg && getDownloadableLink(item.profileImg)) ||
                    ContactTypeEnum.man.defaultImg
                  }
                />
              </div>
              <div className='d-flex-column'>
                <div className='item-wrapper px-2'>
                  <span className='item-header'>{item.fullName}</span>
                </div>
                <div className='item-wrapper'>
                  <span className='item-header px-2'>
                    {getTranslate()(`${translationPath}Username`)}:
                  </span>
                  <span className='item-body'>{item.userName}</span>
                </div>
                {item.userTypes && item.userTypes.length > 0 && (
                  <div className='item-wrapper'>
                    <span className='item-body c-secondary'>
                      {item.userTypes &&
                        item.userTypes.map((subItem, subIndex) => (
                          <span key={`userRolesRef${(subIndex + 1) * (index + 1)}`}>
                            {subItem.rolesName}
                            {subIndex < item.userTypes.length - 1 && (
                              <span className='pr-1'>,</span>
                            )}
                          </span>
                        ))}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className='cards-body'>
              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-phone px-2' />
                </span>
                <span className='item-body'>{item.phoneNumber || getTranslate()(`${translationPath}N/A`)} </span>
              </div>
              <div className='item-wrapper flex-nowrap'>
                <div className='texts-truncate d-flex'>
                  <span className='item-header'>
                    <span className='mdi mdi-email-outline px-2' />
                  </span>
                  <span
                    className='item-body texts-truncate d-inline-block'
                    title={item.email || getTranslate()(`${translationPath}N/A`)}>
                    {item.email || getTranslate()(`${translationPath}N/A`)}
                  </span>
                </div>
              </div>
              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-whatsapp px-2' />
                </span>
                <span className='item-body'>{item.whatsAppNumber ||  getTranslate()(`${translationPath}N/A`)  }</span>
              </div>
              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-map-marker px-2' />
                  <span>{getTranslate()(`${translationPath}Place-of-resldence`)} 
                 :
                  </span>
                </span>
                <span className='item-body'>{item.nationalityName ||getTranslate()(`${translationPath}N/A`)}</span>
              </div>
              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-calendar-blank px-2' />
                  <span>{getTranslate()(`${translationPath}register`)}:</span>
                </span>
                <span className='item-body'>
                  {(item.createdOn &&
                    moment(item.createdOn).locale(getLanguage()).format('DD/MM/YYYY')) ||
                    getTranslate()(`${translationPath}N/A`)}
                </span>
              </div>

              <div className='item-wrapper'>
                <span className='item-header'>
                  <span className='mdi mdi-account-check px-2' />
                  <span> {getTranslate()(`${translationPath}Status`)}:</span>
                </span>
                <span className='item-body'>
                  {item.userStatus  === 'Active' ?  getTranslate()(`${translationPath}Active`) :getTranslate()(`${translationPath}Pending`) || getTranslate()(`${translationPath}N/A`)}
                  <span
                    className={item.userStatus === 'Active' ? 'state-online ' : 'state-offline '}
                  />
                </span>
              </div>
              <div className='item-wrapper mb-3'>
                <span className='item-header'>
                  <span className='mdi mdi-handshake-outline px-2' />
                  <span>{getTranslate()(`${translationPath}data-source`)}:</span>
                </span>
                <span className='item-body'>{item.dataSource ||getTranslate()(`${translationPath}N/A`)}</span>
              </div>
            </div>
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
              spacing={2}
              className='CardContenater'>
              <Grid item xs={6}>
                <div className='Cardgrid'>
                  <div className='Contenater-icon'>
                    <span className='mdi mdi-cart px-2 icon' />
                  </div>
                  <div className='textcardsmoll'>{getTranslate()(`${translationPath}Basket`)} </div>
                  <div className='textcard'>
                    {Tours.numberOfTourInTheBasket} {getTranslate()(`${translationPath}TOURS`)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='Cardgrid'>
                  <div className='Contenater-icon'>
                    <span className='mdi mdi-cards-heart px-2 icon' />
                  </div>
                  <div className='textcardsmoll'>
                    {getTranslate()(`${translationPath}Favorite`)}{' '}
                  </div>
                  <div className='textcard'>
                    {Tours.numberOfYourFavoriteTour} {getTranslate()(`${translationPath}TOURS`)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='Cardgrid'>
                  <div className='Contenater-icon'>
                    <span className='mdi mdi-earth px-2 icon' />
                  </div>
                  <div className='textcardsmoll'>{getTranslate()(`${translationPath}Views`)} </div>
                  <div className='textcard'>
                    {Tours.numberOfViewsTours} {getTranslate()(`${translationPath}TOURS`)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='Cardgrid'>
                  <div className='Contenater-icon'>
                    <span className='mdi mdi-earth px-2 icon' />
                  </div>
                  <div className='textcardsmoll'> {getTranslate()(`${translationPath}Creat`)} </div>
                  <div className='textcard'>
                    {Tours.numberOfCustomTourCreated} {getTranslate()(`${translationPath}TOURS`)}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid item xs={12} direction='row' container>
          <Grid item xs={6}>
            <div className='bigCardgridleft'>
              <div>
                <span className='mdi mdi-navigation  px-2  iconbig ' />
                {getTranslate()(`${translationPath}TOURS`)}
              </div>
              <div className='textcardsmollbig'> {getTranslate()(`${translationPath}past`)} </div>
              <span className='textcardbig '>
                {Tours.numberOfPastTour} {getTranslate()(`${translationPath}TOURS`)} 
              </span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className='bigCardgridright'>
              <div>
                <span className='mdi mdi-navccccccigation  px-2  iconbig ' />
              </div>
              <div className='textcardsmollbig2'>
                {getTranslate()(`${translationPath}Reserved`)} 
              </div>
              <div className='textcardbig'>
                {Tours.numberOfUpComingTours} {getTranslate()(`${translationPath}TOURS`)} 
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default UserSlider;
