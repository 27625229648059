/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { DropDownRole } from '../DropDownRole/DropDownRole';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './CheckBoxRole.scss';

const CheckBoxRole = (props) => {
  const [check, setCheck] = React.useState(props.check ? props.check : false);
  const [selected, setSelected] = React.useState(props.selected ? props.selected : 1);

  useEffect(() => {
    if (props.role && props.role.result) {
      props.role.result.map((role) => {
        if (role.permissions && role.permissions.permissionsId === props.item.permissionsId) {
          setCheck(true);
          setSelected(parseInt(role.accessTypesId));
        }
      });
    }
  }, [props.item.permissionsId, props.role]);

  useEffect(() => {
    if (props.remove && props.remove[0]) {
      props.remove.map((r) => {
        if (props.role && props.role.result) {
          props.role.result.map((j) => {
            if (r.permissionsId === props.item.permissionsId) {
              if (r.id === j.rolePermissionsId) {
                setCheck(false);
              }
            }
          });
        }
      });
    }
  }, [props.item.permissionsId, props.remove, props.role]);

  useEffect(() => {
    if (props.select && props.select[0]) {
      props.select.map((s) => {
        if (props.role && props.role.result) {
          props.role.result.map((j) => {
            if (s.permissionsId === props.item.permissionsId) {
              if (s.id === j.rolePermissionsId) {
                setSelected(selected);
              }
            }
          });
        }
      });
    }
  }, [props.item.permissionsId, props.role, props.select, selected]);

  const handleSelected = (sentData) => {
    setSelected(sentData);
    props.onChange({
      checked: check,
      selected: sentData,
      name: props.item.permissionsName,
      id: props.item.permissionsId,
    });
  };

  const handleCheckChange = (event) => {
    setCheck(event.target.checked);
    props.onChange({
      checked: event.target.checked,
      selected: selected,
      name: props.item.permissionsName,
      id: props.item.permissionsId,
    });
  };

  return (
    <Grid container className={check ? '' : 'rolePer'}>
      <Grid
        item
        className={
          selected === 1
            ? 'globalCheckBox'
            : selected === 2
            ? 'localCheckBox'
            : selected === 3
            ? 'deepCheckBox'
            : selected === 4
            ? 'basicCheckBox'
            : ''
        }>
        <FormControlLabel
          className='form-control-label'
          control={
            <Checkbox
              className='checkbox-wrapper'
              checkedIcon={<span className='mdi mdi-check' />}
              indeterminateIcon={<span className='mdi mdi-minus' />}
              checked={check}
              onChange={handleCheckChange}
            />
          }
          label={props.item.permissionsName}
        />
      </Grid>
      <Grid>
        <DropDownRole sendSelected={handleSelected} disabled={!check} />
      </Grid>
    </Grid>
  );
};

export { CheckBoxRole };
