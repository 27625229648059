import React, { useState, useEffect, useCallback } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { GetAllAdvertisements } from '../../../Serviecs/AdsViewServices/AdsViewServices';
import { IconButton, TextField, Button, FormControl } from '@material-ui/core';
import { AdsCardView } from './AdsViewUtilities/AdsCardView';
import { ViewTypes, Spinner } from '../../../Componentes';
import { ViewTypesEnum } from '../../../Enums';
import { AdsListView } from './AdsViewUtilities/AdsListView';
import { AdsViewDialog } from './AdsViewUtilities/AdsViewDialogs/AdsViewDialog';
import { getTranslate } from '../../../Helpers';
import { useTitle } from '../../../Hooks/useTitle';
import { NoDataFoundComponent, NoContentComponent } from '../../../Componentes';

const AdsView = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [searchedItem, setSearchedItem] = useState('');
  const [adsDialog, setAdsDialog] = useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [adsInfo, setAdsInfo] = useState({});
  const [page, setPage] = useState(0);
  const [responseResult, setResponseResult] = useState(() => ({ result: [], totalCount: 0 }));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeActionType, setActiveActionType] = React.useState(ViewTypesEnum.cards.key);
  const [reloaded, setReloaded] = React.useState(false);
  const [isFirstLoad, setisFirstLoad] = useState(true);
  let time = setTimeout(() => {}, 300);
  const translationPath = 'Advertisements.';

  useTitle(getTranslate()(`SideMenuView.MobilePages.Advertisements`));

  const GetAllAds = useCallback(
    async (pageIndex, pageSize, searchedItem) => {
      setLoading(true);
      const res = await GetAllAdvertisements(pageIndex, pageSize, searchedItem);
      setResponse(res);
      if (res && res.totalCount === 0) {
        setisFirstLoad(false);
      }

      if (reloaded) setResponseResult({ result: [], totalCount: 0 });
      else {
        if (page === 0) setResponseResult(res);
        else {
          setResponseResult((item) => ({
            result: item.result.concat(res.result),
            totalCount: res.totalCount,
          }));
        }
      }

      setLoading(false);
    },
    [page, reloaded]
  );

  useEffect(() => {
    GetAllAds(page + 1, rowsPerPage, '');
  }, [GetAllAds, page, rowsPerPage]);

  const onTypeChanged = useCallback(
    (activeType) => {
      setPage(0);
      GetAllAds(1, 10, searchedItem);
      setActiveActionType(activeType);
    },
    [GetAllAds, searchedItem]
  );

  return (
    <div className='view-wrapper sliderView'>
      <Spinner isActive={loading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <Button
              className='btns theme-solid'
              onClick={() => {
                setIsEdit(false);
                setAdsDialog(true);
              }}>
              {getTranslate()(`${translationPath}AddNewAdvertisement`)}
            </Button>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <FormControl className='input-wrapper'>
                <Button className='btns-icon theme-transparent mx-2'>
                  <span className='mdi mdi-magnify' />
                </Button>
                <TextField
                  className='inputs theme-solid'
                  value={searchedItem}
                  onKeyUp={() => {
                    time = setTimeout(() => {
                      GetAllAds(page + 1, rowsPerPage, searchedItem);
                    }, 500);
                  }}
                  onKeyDown={() => {
                    clearTimeout(time);
                  }}
                  onChange={(e) => setSearchedItem(e.target.value)}
                  label={getTranslate()(`${translationPath}Search`)}
                  placeholder={getTranslate()(`${translationPath}search-by-advertisement-name`)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {searchedItem && (
                          <IconButton
                            onClick={() => {
                              setSearchedItem('');
                              GetAllAds(page + 1, rowsPerPage, '');
                            }}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </div>
          <ViewTypes
            onTypeChanged={onTypeChanged}
            activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
            className='mb-3'
          />
        </div>
      </div>
      <div>
        <NoDataFoundComponent />
        {response &&
          response.result &&
          response.result.length === 0 &&
          searchedItem.length === 0 &&
          !isFirstLoad && <NoContentComponent />}
        {activeActionType === ViewTypesEnum.tableView.key ? (
          <AdsListView
            searchedItem={searchedItem}
            isFirstLoad={isFirstLoad}
            setIsEdit={setIsEdit}
            response={response}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setAdsDialog={setAdsDialog}
            setAdsInfo={setAdsInfo}
            adsInfo={adsInfo}
            reloadData={(page, rowsPerPage) => GetAllAds(page, rowsPerPage, '')}
          />
        ) : (
          <AdsCardView
            searchedItem={searchedItem}
            isFirstLoad={isFirstLoad}
            isLoading={loading}
            setAdsInfo={setAdsInfo}
            response={responseResult}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setAdsDialog={setAdsDialog}
            setReloaded={setReloaded}
            reloadData={() => GetAllAds(page, rowsPerPage, '')}
          />
        )}
      </div>
      <AdsViewDialog
        adsInfo={adsInfo}
        open={adsDialog}
        setOpen={setAdsDialog}
        isEdit={isEdit}
        setReloaded={setReloaded}
        setIsEdit={setIsEdit}
        reloadData={() => GetAllAds(page + 1, rowsPerPage, '')}
      />
    </div>
  );
};

export { AdsView };
