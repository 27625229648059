import { RememberMeStatus } from '../../States';

const initialState = 
   localStorage.getItem('RemeberMe')
    ? JSON.parse(localStorage.getItem('RemeberMe'))
    : {};


export const RememberMeReducer = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case RememberMeStatus.StoreRememberMe:
      console.log("enter")
      return { ...state, ...action.payload };
    case RememberMeStatus.ResetRememberMe:
    case RememberMeStatus.RememberMeError:
      return {};

  
    default:
      return state
  }
};
