import moment from 'moment';
export const DateTimeIntersected = (
  startingDateTime,
  endDateTime,
  startingDateTimeCompare,
  endDateTimeCompare,
  compareBy = undefined
) => {
  const notInter =
    (moment(startingDateTime).isBefore(startingDateTimeCompare, compareBy) &&
      moment(endDateTime).isSameOrBefore(startingDateTimeCompare, compareBy)) ||
    (moment(endDateTime).isAfter(endDateTimeCompare, compareBy) &&
      moment(startingDateTime).isSameOrAfter(endDateTimeCompare, compareBy));
  return !notInter;
};
export const DateTimeReservedByBetween = (
  dateTime,
  startingDateTimeCompare,
  endDateTimeCompare,
  compareBy = undefined
) => {
  return moment(dateTime).isBetween(startingDateTimeCompare, endDateTimeCompare, compareBy);
};
export const DateTimeReservedBySame = (
  startingDateTime,
  endDateTime,
  startingDateTimeCompare,
  endDateTimeCompare,
  compareBy = undefined
) => {
  return (
    (moment(startingDateTime).isSame(startingDateTimeCompare, compareBy) ||
      moment(startingDateTime).isSame(endDateTimeCompare, compareBy)) &&
    (moment(endDateTime).isSame(startingDateTimeCompare, compareBy) ||
      moment(endDateTime).isSame(endDateTimeCompare, compareBy))
  );
};
export const DateTimeReservedByAfterAndBefore = (
  startingDateTime,
  endDateTime,
  startingDateTimeCompare,
  endDateTimeCompare,
  compareBy = undefined
) => {
  return (
    moment(startingDateTime).isBefore(startingDateTimeCompare, compareBy) &&
    moment(endDateTime).isAfter(endDateTimeCompare, compareBy)
  );
};
export const dateTimeEndIsAfterStart = (startDateTime, endDateTime, compareBy = undefined) => {
  return moment(endDateTime).isAfter(startDateTime, compareBy);
};
