import config from './../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';

export const GetAllOffers = async ({ pageSize, pageIndex }, filterBy) => {
  try {
    const queryList = [];
    (pageSize || pageSize === 0) && queryList.push(`pageSize=${pageSize}`);
    (pageIndex || pageIndex === 0) && queryList.push(`pageIndex=${pageIndex + 1}`);

    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Offers/GetAllOffer?${queryList.join('&')}`,
      filterBy
    );
    return result;
  } catch (e) {
    return;
  }
};
export const UpdateOffer = async (offerId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Offers/UpdateOffer/${offerId}`,
      body
    )
      .then((data) => data)
      .catch((err) => err.response.data);
    return result;
  } catch (e) {
    return;
  }
};
export const CreateOffer = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Offers/CreateOffer`,
      body
    )
      .then((data) => data)
      .catch((err) => err.response.data);
    return result;
  } catch (e) {
    return;
  }
};
export const GetOfferById = async (offerId, pageIndex = 0, pageSize = 99999) => {
  const queryList = [];
  offerId && queryList.push(`offerId=${offerId}`);
  (pageIndex || pageIndex === 0) && queryList.push(`pageIndex=${pageIndex + 1}`);
  (pageSize || pageSize === 0) && queryList.push(`pageSize=${pageSize}`);
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Offers/GetAllToursByOfferId?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const DeleteOffer = async (offerId) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/PSTViewing/Offers/DeleteOffer/${offerId}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const ActiveOffer = async (offerId) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Offers/ActiveOffer/${offerId}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const InActiveOffer = async (offerId) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Offers/InActiveOffer/${offerId}`
    );
    return result;
  } catch (e) {
    return;
  }
};
