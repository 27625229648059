export const CarStatusEnum = {
  ready: {
    key: 0,
    value: 'ready',
    color: 'c-secondary',
    bgColor: 'bg-secondary',
  },
  busy: {
    key: 1,
    value: 'busy',
    color: 'c-danger',
    bgColor: 'bg-danger',
  },
};
