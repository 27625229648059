import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from '../../Helpers';
import { PopoverComponent } from '../PopoverComponent/PopoverComponent';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { ButtonBase } from '@material-ui/core';
import moment from 'moment';
import './TimeRangePickerComponent.scss';
import MomentUtils from '@date-io/moment';

export const TimeRangePickerComponent = ({
  range,
  rangeChanged,
  popoverIdRef,
  startIdRef,
  endIdRef,
  startLabelValue,
  endLabelValue,
  isDisabledStartTime,
  isDisabledEndTime,
  isDisabled,
  translationPath,
  displayFormat,
  locale,
  maxDate,
  minDate,
  idRef,
  labelValue,
  labelClasses,
  startTimeError,
  endTimeError,
  helperText,
  isSubmitted,
  isWideOvel,
  emptyLabel,
  onClickOutside
}) => {
  const [attachedWith, setAttachedWith] = useState(null);
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  const displayRef = useRef(null);
  const handleClose = useCallback(() => {
    setAttachedWith(null);
   if(onClickOutside) onClickOutside();
  }, [onClickOutside]);
  const openHandler = useCallback(() => {
    if (!isBlurOrChanged) setIsBlurOrChanged(true);
    setAttachedWith(displayRef.current);
  }, [isBlurOrChanged]);
  const startTimeHandler = useCallback(
    (selectedTime) => {
      if (rangeChanged)
        if (range && range.endTime && moment(selectedTime).isAfter(range.endTime))
          rangeChanged({ startTime: range.endTime, endTime: range.endTime });
        else rangeChanged({ startTime: selectedTime, endTime: range.endTime });
    },
    [range, rangeChanged]
  );
  const endTimeHandler = useCallback(
    (selectedTime) => {
      if (rangeChanged)
        if (range && range.startTime && moment(selectedTime).isBefore(range.startTime))
          rangeChanged({ startTime: range.startTime, endTime: range.startTime });
        else rangeChanged({ startTime: range.startTime, endTime: selectedTime });
    },
    [range, rangeChanged]
  );
  useEffect(() => {
    if (minDate && range.startTime && !moment(minDate).isSame(range.startTime, 'day')) {
      rangeChanged({
        startTime: moment(
          moment(minDate).format('YYYY-MM-DD') + moment(range.startTime).format('THH:mm:ssZ')
        ).toDate(),
        endTime: range.endTime,
      });
    }
  }, [minDate, range, rangeChanged]);
  useEffect(() => {
    if (maxDate && range.endTime && !moment(maxDate).isSame(range.endTime, 'day')) {
      rangeChanged({
        startTime: range.startTime,
        endTime: moment(
          moment(maxDate).format('YYYY-MM-DD') + moment(range.endTime).format('THH:mm:ssZ')
        ).toDate(),
      });
    }
  }, [endTimeHandler, maxDate, range, rangeChanged]);
  return (
    <div className="time-range-picker-component">
      {labelValue && (
        <label htmlFor={idRef} className={`${labelClasses}${isDisabled ? ' disabled' : ''}`}>
          {getTranslate()(`${translationPath}${labelValue}`)}
        </label>
      )}
      <ButtonBase
        id={idRef}
        className={`display-wrapper${
          ((startTimeError || endTimeError) && (isBlurOrChanged || isSubmitted) && ' has-error') ||
          ''
        }${(isWideOvel && ' wide-ovel') || ''}`}
        disabled={isDisabled}
        ref={displayRef}
        onClick={openHandler}
      >
        <div className="d-inline-flex-center">
          <span className="mdi mdi-clock-time-five px-2" />
          <span className="description-text">{getTranslate()(`${translationPath}time`)}:</span>
        </div>
        <div className="d-inline-flex-center c-secondary fw-bold px-2">
          {(range && range.startTime && range.endTime && (
            <>
              <span
                className={
                  (startTimeError && (isBlurOrChanged || isSubmitted) && 'start-time-error') ||
                  undefined
                }
              >
                {moment(range.startTime).format(displayFormat)}
              </span>
              <span className="px-1">-</span>
              <span
                className={
                  (endTimeError && (isBlurOrChanged || isSubmitted) && 'end-time-error') ||
                  undefined
                }
              >
                {moment(range.endTime).format(displayFormat)}
              </span>
            </>
          )) || (
            <span className="description-text">
              {getTranslate()(`${translationPath}${emptyLabel}`)}
            </span>
          )}
        </div>
        <span className={`c-secondary px-2 mdi mdi-chevron-${(attachedWith && 'up') || 'down'}`} />
      </ButtonBase>
      {helperText && (isBlurOrChanged || isSubmitted) && (
        <span className="error-wrapper">{helperText}</span>
      )}
      <PopoverComponent
        idRef={popoverIdRef}
        handleClose={handleClose}
        attachedWith={attachedWith}
        component={
          <div className="time-range-pickers-wrapper w-100 p-2">
            <div className="picker-section">
              {startLabelValue && (
                <label
                  htmlFor={startIdRef}
                  className={`texts-form${isDisabledStartTime || isDisabled ? ' disabled' : ''}`}
                >
                  {getTranslate()(`${translationPath}${startLabelValue}`)}
                </label>
              )}
              <MuiPickersUtilsProvider
                libInstance={moment}
                className="datepicker-wrapper"
                utils={MomentUtils}
                locale={locale}
              >
                <TimePicker
                  autoOk
                  variant="static"
                  openTo="hours"
                  orientation="portrait"
                  disabled={isDisabledStartTime || isDisabled}
                  id={startIdRef}
                  value={range.startTime}
                  onChange={startTimeHandler}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="picker-section">
              {endLabelValue && (
                <label
                  htmlFor={endIdRef}
                  className={`texts-form${isDisabledEndTime || isDisabled ? ' disabled' : ''}`}
                >
                  {getTranslate()(`${translationPath}${endLabelValue}`)}
                </label>
              )}
              <MuiPickersUtilsProvider
                libInstance={moment}
                className="datepicker-wrapper"
                utils={MomentUtils}
                locale={locale}
              >
                <TimePicker
                  autoOk
                  variant="static"
                  openTo="hours"
                  disabled={isDisabledEndTime || isDisabled}
                  id={endIdRef}
                  orientation="portrait"
                  value={range.endTime}
                  onChange={endTimeHandler}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        }
      />
    </div>
  );
};

TimeRangePickerComponent.propTypes = {
  range: PropTypes.shape({
    startTime: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
    endTime: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
    startDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
  }).isRequired,
  rangeChanged: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func,
  popoverIdRef: PropTypes.string,
  startIdRef: PropTypes.string,
  endIdRef: PropTypes.string,
  startLabelValue: PropTypes.string,
  endLabelValue: PropTypes.string,
  isDisabledStartTime: PropTypes.bool,
  isDisabledEndTime: PropTypes.bool,
  isDisabled: PropTypes.bool,
  translationPath: PropTypes.string,
  displayFormat: PropTypes.string,
  locale: PropTypes.string,
  idRef: PropTypes.string,
  labelClasses: PropTypes.string,
  labelValue: PropTypes.string,
  helperText: PropTypes.string,
  emptyLabel: PropTypes.string,
  startTimeError: PropTypes.bool,
  endTimeError: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  isWideOvel: PropTypes.bool,
};

TimeRangePickerComponent.defaultProps = {
  popoverIdRef: 'timeRangePickerPropoverRef',
  startIdRef: 'timeRangePickerStartRef',
  endIdRef: 'timeRangePickerEndRef',
  startLabelValue: 'from',
  endLabelValue: 'to',
  isDisabledStartTime: false,
  isDisabledEndTime: false,
  isDisabled: false,
  minDate: undefined,
  maxDate: undefined,
  onClickOutside: undefined,
  translationPath: 'home.timeRangePickerComponent.',
  displayFormat: 'hh:mm a',
  locale: 'en',
  idRef: 'timeRangePickerRef',
  labelClasses: 'texts-form',
  emptyLabel: 'all',
  labelValue: undefined,
  startTimeError: undefined,
  endTimeError: undefined,
  helperText: undefined,
  isSubmitted: false,
  isWideOvel: false,
};
