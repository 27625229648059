import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';
import './Assets/Theme/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { store } from './Stores/Store';
import { InitGlobalEventHandler } from './Helpers/GlobalEventHandler';

ReactDOM.render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
    <ToastContainer />
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
InitGlobalEventHandler();
