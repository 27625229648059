export const TotalToursEnum = [

  {
    key: 0,
    value: 'This-Week',
  },
  {
    key: 1,
    value: 'This-Month',
  },
   {
    key: 2,
    value: 'This-Year',
  },
]
