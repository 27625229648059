import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DefaultImagesEnum } from '../../Enums';
import { DialogComponent } from './../DialogComponent/DialogComponent';
import { LoadableImageComponant } from '../LoadableImageComponant/LoadableImageComponant';
import {
  getDownloadableLink,
  getTranslate,
  useEventListener,
  useLocalStorage,
} from '../../Helpers';
import { ButtonBase } from '@material-ui/core';
import './FacebookGalleryComponent.scss';

export const FacebookGalleryComponent = ({
  data,
  titleText,
  translationPath,
  imageInput,
  defaultImage,
  isOpen,
  onOpenChanged,
  keyRef,
  altInput,
  alt,
}) => {
  const [activeImage, setActiveImage] = useState(null);
  const [currentDirection] = useLocalStorage('localization', { isRtl: false });
  const thumbnailWrapperRef = useRef(null);
  const scrollTimer = useRef(null);
  const [scrollCurrentItem, setScrollCurrentItem] = useState(0);
  const [navigators, setNavigators] = useState({
    previous: false,
    next: false,
  });
  const dataReturn = (dataItem, dataPath) => {
    if (!dataPath) return dataItem;
    if (!dataPath.includes('.')) return dataItem[dataPath] || dataItem[dataPath] + '';
    let a = dataItem;
    dataPath.split('.').map((item) => {
      a = a[item];
      return item;
    });
    return a;
  };
  const activeImageHandler = useCallback(
    (item) => () => {
      setActiveImage(item);
    },
    []
  );
  const getIsScrollable = useCallback(
    (direction) => {
      if (!thumbnailWrapperRef.current) return false;
      const isRtl = currentDirection.isRtl;
      const element = thumbnailWrapperRef.current;
      const maxScrollLeft = Math.round(element.scrollWidth - element.clientWidth);
      if (
        (direction === 'previous' &&
          ((!isRtl && (element.scrollLeft <= 0 || scrollCurrentItem <= 0)) ||
            (isRtl &&
              (Math.abs(element.scrollLeft) >= maxScrollLeft || scrollCurrentItem <= 0)))) ||
        (direction === 'next' &&
          ((!isRtl &&
            (element.scrollLeft >= maxScrollLeft || scrollCurrentItem >= data.length - 1)) ||
            (isRtl &&
              (Math.abs(element.scrollLeft) >= maxScrollLeft ||
                scrollCurrentItem >= data.length - 1))))
      )
        return false;
      return true;
    },
    [currentDirection.isRtl, data.length, scrollCurrentItem]
  );
  const toHandler = (direction) => () => {
    const element = thumbnailWrapperRef.current;
    if (!getIsScrollable(direction)) return;
    setScrollCurrentItem((item) => {
      if (direction === 'next') item += 1;
      else item -= 1;
      element.scrollTo({
        left: element.firstChild?.childNodes[item].offsetLeft - 17,
        behavior: 'smooth',
      });
      return item;
    });
  };
  useEffect(() => {
    if (scrollTimer.current) clearTimeout(scrollTimer.current);
    scrollTimer.current = setTimeout(() => {
      setNavigators({
        next: !getIsScrollable('next'),
        previous: !getIsScrollable('previous'),
      });
    }, 100);
  }, [getIsScrollable, data]);
  useEffect(() => {
    setScrollCurrentItem(0);
  }, [currentDirection.isRtl]);
  useEffect(() => {
    if (data && data.length > 0) setActiveImage(data[0]);
  }, [data]);
  useEffect(() => {
    return () => {
      if (scrollTimer.current) clearTimeout(scrollTimer.current);
    };
  }, []);
  useEventListener('resize', () => {
    setNavigators({
      next: !getIsScrollable('next'),
      previous: !getIsScrollable('previous'),
    });
  });
  return (
    <DialogComponent
      titleText={titleText}
      //   maxWidth='sm'
      dialogContent={
        <div className="facebook-gallery-wrapper">
          {activeImage && (
            <div className="facebook-gallery-active-wrapper">
              <div className="facebook-gallery-active-image-wrapper">
                <LoadableImageComponant
                  classes="facebook-gallery-active-image"
                  alt={
                    (altInput && dataReturn(activeImage, altInput)) ||
                    getTranslate()(`${translationPath}${alt}`)
                  }
                  src={
                    (imageInput &&
                      dataReturn(activeImage, imageInput) &&
                      getDownloadableLink(dataReturn(activeImage, imageInput))) ||
                    defaultImage
                  }
                />
              </div>
            </div>
          )}
          {data && data.length > 0 && (
            <div className="w-100 d-flex-center flex-wrap mb-2">
              <ButtonBase
                className="btns-icon theme-solid mx-2 mb-2"
                disabled={navigators.previous}
                onClick={toHandler('previous')}
              >
                <span className="mdi mdi-chevron-left" />
              </ButtonBase>
              <ButtonBase
                className="btns-icon theme-solid mx-2 mb-2"
                disabled={navigators.next}
                onClick={toHandler('next')}
              >
                <span className="mdi mdi-chevron-right" />
              </ButtonBase>
            </div>
          )}
          {data && data.length > 0 && (
            <div
              className="facebook-gallery-thumbnail-wrapper"
              ref={thumbnailWrapperRef}
              onScroll={() => {
                if (scrollTimer.current) clearTimeout(scrollTimer.current);
                scrollTimer.current = setTimeout(() => {
                  setNavigators({
                    next: !getIsScrollable('next'),
                    previous: !getIsScrollable('previous'),
                  });
                }, 100);
              }}
            >
              <div className="facebook-gallery-thumbnail-items-wrapper">
                {data.map((image, index) => (
                  <ButtonBase
                    onClick={activeImageHandler(image)}
                    className={`facebook-gallery-thumbnail-item${
                      (activeImage &&
                        dataReturn(activeImage, altInput) === dataReturn(image, altInput) &&
                        ' active-image') ||
                      ''
                    }`}
                    key={`${keyRef}${index + 1}`}
                  >
                    <LoadableImageComponant
                      classes="facebook-gallery-thumbnail-image"
                      alt={
                        (altInput && dataReturn(activeImage, altInput)) ||
                        getTranslate()(`${translationPath}${alt}`)
                      }
                      src={
                        (imageInput &&
                          dataReturn(image, imageInput) &&
                          getDownloadableLink(dataReturn(image, imageInput))) ||
                        defaultImage
                      }
                    />
                  </ButtonBase>
                ))}
              </div>
            </div>
          )}
        </div>
      }
      isOpen={isOpen}
      saveType="button"
      translationPath={translationPath}
      onCloseClicked={onOpenChanged}
      onCancelClicked={onOpenChanged}
    />
  );
};

FacebookGalleryComponent.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  defaultImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(Object.values(DefaultImagesEnum).map((item) => item.defaultImg)),
  ]),
  onOpenChanged: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  altInput: PropTypes.string,
  imageInput: PropTypes.string,
  translationPath: PropTypes.string,
  keyRef: PropTypes.string,
  isOpen: PropTypes.bool,
  alt: PropTypes.string,
};
FacebookGalleryComponent.defaultProps = {
  defaultImage: DefaultImagesEnum.buildings.defaultImg,
  titleText: undefined,
  imageInput: null,
  translationPath: '',
  keyRef: 'imageGalleryRef',
  altInput: null,
  alt: null,
};
