import { TripsManagementView, TripsView } from '../../Views/Home';

export const TripsRoutes = [
  {
    path: '/add',
    name: 'home.trips.tripsManagementView.add-new-trip',
    component: TripsManagementView,
    layout: '/home/trips',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'home.trips.tripsView.location',
        isDisabled: false,
        route: '/home/trips/view',
      },
      {
        name: 'home.trips.tripsManagementView.add-new-trip',
        isDisabled: true,
        route: '/home/trips/add',
      },
    ],
  },
  {
    path: '/edit',
    name: 'home.trips.tripsManagementView.edit-location',
    component: TripsManagementView,
    layout: '/home/trips',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'home.trips.tripsView.location',
        isDisabled: false,
        route: '/home/trips/view',
      },
      {
        name: 'home.trips.tripsManagementView.edit-location',
        isDisabled: true,
        route: '/home/trips/edit',
      },
    ],
  },
  {
    path: '/view',
    name: 'home.trips.tripsView.location',
    component: TripsView,
    layout: '/home/trips',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'home.trips.tripsView.location',
        isDisabled: false,
        route: '/home/trips/view',
      },
    ],
  },
];
