import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getErrorByName, getTranslate } from '../../../../../../Helpers';
import { Inputs, SelectComponet } from '../../../../../../Componentes';
import './TourDiscountsFeesComponent.scss';

export const TourDiscountsFeesComponent = ({
  state,
  onDiscountFeesChange,
  translationPath,
  isSubmitted,
  schema,
}) => {
  const [selectedDiscountType, setSelectedDiscountType] = useState(1);
  const [discountTypes] = useState([
    { key: 1, value: '%' },
    { key: 2, value: '$' },
  ]);
  const discountTypeInit = useCallback(() => {
    if (state.discountValue !== null && selectedDiscountType !== 2) setSelectedDiscountType(2);
    else if (
      (state.discountPercentage !== null || state.discountLimit !== null) &&
      selectedDiscountType !== 1
    )
      setSelectedDiscountType(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiscountType]);
  useEffect(() => {
    discountTypeInit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.discountValue, state.discountLimit, state.discountPercentage]);
  return (
    <div className="tour-discounts-fees-wrapper">
      <span className="d-flex mb-2 fw-bold c-black-light">
        {getTranslate()(`${translationPath}tour-discounts-and-fees`)}
      </span>
      <span className="d-flex mb-3">
        {getTranslate()(`${translationPath}tour-discounts-and-fees-description`)}
      </span>
      <div className="form-wrapper">
        <div className="form-item">
          <Inputs
            idRef="tourNameRef"
            labelValue={`${(selectedDiscountType === 1 && 'discount-percentage') || 'discount'}`}
            translationPath={translationPath}
            isWithError
            helperText={
              getErrorByName(
                schema,
                (selectedDiscountType === 1 && 'discountPercentage') || 'discountValue'
              ).message
            }
            inputPlaceholder="add-number"
            isSubmitted={isSubmitted}
            error={
              getErrorByName(
                schema,
                (selectedDiscountType === 1 && 'discountPercentage') || 'discountValue'
              ).error
            }
            type="number"
            value={
              (selectedDiscountType === 1 &&
                state.discountPercentage !== null &&
                state.discountPercentage) ||
              (state.discountValue !== null && state.discountValue) ||
              ''
            }
            onInputChanged={(event) => {
              onDiscountFeesChange(
                (selectedDiscountType === 1 && 'discountPercentage') || 'discountValue',
                event.target.value
              );
            }}
          />
          <div className="on-input-select">
            <SelectComponet
              idRef="discountTypeRef"
              data={discountTypes}
              valueInput="key"
              textInput="value"
              value={selectedDiscountType}
              onSelectChanged={(event) => {
                setSelectedDiscountType(+event.target.value);
                if (+event.target.value === 1) onDiscountFeesChange('discountValue', null);
                if (+event.target.value === 2) {
                  onDiscountFeesChange('discountPercentage', null);
                  onDiscountFeesChange('discountLimit', null);
                }
              }}
            />
          </div>
        </div>
        {selectedDiscountType === 1 && (
          <div className="form-item">
            <Inputs
              idRef="maxDiscountRef"
              labelValue="max-discount"
              translationPath={translationPath}
              isWithError
              helperText={getErrorByName(schema, 'discountLimit').message}
              inputPlaceholder="add-number"
              type="number"
              overInputText="AED"
              isSubmitted={isSubmitted}
              error={getErrorByName(schema, 'discountLimit').error}
              value={state.discountLimit || ''}
              onInputChanged={(event) => {
                onDiscountFeesChange('discountLimit', event.target.value);
              }}
            />
          </div>
        )}
        <div className="d-flex w-100 item-3">
        </div>
      </div>
    </div>
  );
};

TourDiscountsFeesComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  onDiscountFeesChange: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
};
