import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  getTranslate,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
  useEventListener,
} from '../../../../../Helpers';
import {
  MasonryComponent,
  NoSearchResultComponent,
  SlideImagesComponent,
} from '../../../../../Componentes';
import { ActionsEnum } from '../../../../../Enums';
import { getLanguage } from 'react-switch-lang';
import moment from 'moment';
import './PendingToursCardComponent.scss';
import { Button } from '@material-ui/core';
import { PendingTourDetailsComponent } from './../PendingTourDetailsComponent/PendingTourDetailsComponent';

export const PendingToursCardComponent = ({
  data,
  filter,
  translationPath,
  onLoadMore,
  onActionClicked,
  isLoading,
}) => {
  const onScrollHandler = useCallback(() => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.scrollHeight - 5 &&
      data.result.length < data.totalCount &&
      isLoading !== true
    )
      onLoadMore();
  }, [data.result.length, data.totalCount, isLoading, onLoadMore]);
  const onCardClicked = useCallback(
    (item) => () => {
      sideMenuComponentUpdate(
        <PendingTourDetailsComponent data={item} translationPath={translationPath} />
      );
      sideMenuIsOpenUpdate(true);
    },
    [translationPath]
  );
  useEffect(() => {
    return () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    };
  }, []);
  useEventListener('scroll', onScrollHandler);
  const actionClicked = useCallback(
    (actionKey, item, index) => () => {
      if (onActionClicked) onActionClicked(actionKey, item, index);
    },
    [onActionClicked]
  );
  useEffect(() => {
    if (
      data.result.length < data.totalCount &&
      document.body.scrollHeight <= document.body.clientHeight &&
      isLoading !== true
    )
      onScrollHandler();
  }, [data.result.length, data.totalCount, isLoading, onScrollHandler]);
  return (
    <div className="pending-tour-card-component-wrapper">
      {(data && data.totalCount === 0 && filter.search && <NoSearchResultComponent />) || (
        <MasonryComponent>
          {data.result &&
            data.result.map((item, index) => (
              <div
                className="pending-tour-card-wrapper"
                onClick={onCardClicked && onCardClicked(item, index)}
                key={`pendingTourCardItemRef${index + 1}`}
              >
                <div className="cards-wrapper">
                  <div className="cards-header">
                    <SlideImagesComponent
                      data={
                        (item.tourDetailsDto &&
                          item.tourDetailsDto.tourSummaryTrips &&
                          item.tourDetailsDto.tourSummaryTrips) ||
                        []
                      }
                      imageInput="tripCoverImage"
                      alt="trip-image"
                      titleText="trip-gallery"
                      translationPath={translationPath}
                      withViewGallery
                    />
                    <div className="fw-bold">
                      <div className="item-wrapper">
                        <span className="item-header">
                          <span className="mdi mdi-calendar px-1" />
                        </span>
                        <span className="item-body px-0">
                          {(item.startTourDate &&
                            ((moment(item.startTourDate).isSame(item.endTourDate, 'day') && (
                              <span>
                                {moment(item.startTourDate)
                                  .locale(getLanguage())
                                  .format('DD/MM/YYYY')}
                              </span>
                            )) || (
                              <span>
                                <span>
                                  {moment(item.startTourDate)
                                    .locale(getLanguage())
                                    .format('DD/MM/YYYY')}{' '}
                                  -{' '}
                                </span>
                                <span>
                                  {moment(item.endTourDate)
                                    .locale(getLanguage())
                                    .format('DD/MM/YYYY')}
                                </span>
                              </span>
                            ))) ||
                            'N/A'}
                        </span>
                      </div>
                      <div className="item-wrapper">
                        <span className="item-header">
                          <span className="mdi mdi-clock-time-five px-1" />
                        </span>
                        <span className="item-body px-0">
                          {(item.startTourDate && (
                            <>
                              <span>
                                {moment(item.startTourDate).locale(getLanguage()).format('hh:mm a')}
                              </span>
                              <span>-</span>
                              <span>
                                {moment(item.endTourDate).locale(getLanguage()).format('hh:mm a')}
                              </span>
                            </>
                          )) ||
                            'N/A'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="cards-body">
                    <div className="item-wrapper c-secondary">
                      <span className="item-header px-1">
                        <span className="px-1">{getTranslate()(`${translationPath}tour`)}:</span>
                        <span>
                          {(item.tourDetailsDto && item.tourDetailsDto.tourName) || 'N/A'}
                        </span>
                        <span className="px-1">({item.reservationTourId || 'N/A'})</span>
                      </span>
                    </div>
                    <div className="item-wrapper flex-nowrap px-2 fa-start">
                      <span className="item-header">
                        <span>{getTranslate()(`${translationPath}client`)}:</span>
                      </span>
                      <span className="item-body px-0">
                        {(item.client && (
                          <span>
                            <span className="px-2">{item.client.fullName}</span>
                            <span className="c-secondary">({item.client.userName})</span>
                          </span>
                        )) || <span className="px-2">N/A</span>}
                      </span>
                    </div>

                    <div className="item-wrapper actions">
                      <Button
                        className="btns theme-outline"
                        onClick={actionClicked(ActionsEnum.edit.key, item, index)}
                      >
                        <span className={ActionsEnum.edit.icon} />
                      </Button>
                    </div>
                  </div>
                  <div className="cards-footer">
                    <div className="item-wrapper flex-nowrap mb-2">
                      <span className="item-header mb-0">
                        <span
                          className={`icon-box mdi mdi-face-agent ${
                            (item.agent && 'bg-success') || 'bg-warning'
                          }`}
                        />
                      </span>
                      <span className="item-body px-0">
                        <span className="px-2 fw-bold">
                          {getTranslate()(`${translationPath}agent`)}:
                        </span>
                        {(item.agent && (
                          <span>
                            <span>{item.agent.fullName}</span>
                            <span className="px-2 c-secondary">({item.agent.userName})</span>
                          </span>
                        )) || <span>N/A</span>}
                      </span>
                    </div>
                    {(item.carDrivers &&
                      item.carDrivers.length > 0 &&
                      item.carDrivers.map((subItem, subIndex) => (
                        <div
                          key={`driversRef${(index + 1) * (subIndex + 1)}`}
                          className="item-wrapper flex-nowrap mb-2"
                        >
                          <span className="item-header mb-0">
                            <span
                              className={`icon-box mdi mdi-card-account-details ${
                                (subItem.car && subItem.driver && 'bg-success') || 'bg-warning'
                              }`}
                            />
                          </span>
                          <span className="item-body mb-0 flex-column fa-start px-0">
                            <div
                              className={`d-inline-flex-v-center${
                                (!subItem.driver && ' c-warning') || ''
                              }`}
                            >
                              <span className="px-2 fw-bold">
                                {getTranslate()(`${translationPath}driver`)}:
                              </span>
                              {(subItem.driver && (
                                <span className="d-inline-flex-v-center flex-wrap">
                                  <span>{subItem.driver.fullName}</span>
                                  <span className="px-2 c-secondary">
                                    ({subItem.driver.userName})
                                  </span>
                                </span>
                              )) || <span>N/A</span>}
                            </div>
                          </span>
                        </div>
                      ))) || (
                      <div className="item-wrapper flex-nowrap mb-2">
                        <span className="item-header mb-0">
                          <span className="icon-box mdi mdi-card-account-details bg-warning" />
                        </span>
                        <span className="item-body mb-0 flex-column px-0">
                          <div className="w-100 c-warning">
                            <span className="px-2">
                              {getTranslate()(`${translationPath}driver`)}:
                            </span>
                            <span>N/A</span>
                          </div>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </MasonryComponent>
      )}
    </div>
  );
};

PendingToursCardComponent.propTypes = {
  filter: PropTypes.instanceOf(Object).isRequired,
  translationPath: PropTypes.string.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onActionClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
