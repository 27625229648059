import React from 'react';
import NoSearchResult from '../../Assets/Images/defaults/NoContent.png';
import './NoContent.scss';
import { getTranslate } from '../../Helpers';
import { Grid } from '@material-ui/core';

export const NoContentComponent = () => {
  const translationPath = 'NotFoundViews.';

  return (
    navigator.onLine && (
      <Grid container className='no-content-result'>
        <Grid item className='no-content-text'>
          <h1 className='no-content-title'>{getTranslate()(`${translationPath}No-Content`)}</h1>
          <h3 className='no-content-result-subtitle'>
            {getTranslate()(`${translationPath}This-page-is-empty`)}
          </h3>
        </Grid>
        <Grid item>
          <img
            src={NoSearchResult}
            alt={getTranslate()(`${translationPath}No-Content`)}
            className='no-content-img'
          />
        </Grid>
      </Grid>
    )
  );
};
