import React, { useEffect, useCallback } from 'react';
import { Divider, Button } from '@material-ui/core';
import { getTranslate } from ' ./../../src/Helpers';
import './CarsSlider.scss';
import { GetCarById } from './../../../../../Serviecs/Cars/CarsService';
import DialogDeleteTypeCars from './../../../../../Views/Home/Cars/DialogCars/DialogDeleteTypeCars';
import DialogAddEditCar from './../../../../../Views/Home/Cars/DialogCars/DialogAddEditCar';
import   { Spinner }     from '../../../../../Componentes'              ;
import IconButton from '@material-ui/core/IconButton';
const CarsSlider = ({ openDleteDialogslider, openEditDialogslider, setSearch }) => {
  useEffect(() => {}, [setSearch]);
  const [res, setres] = React.useState();
  const [openDialogDelete, setopenDialogDelete] = React.useState(false);
  const [carsid, setcarsid] = React.useState();
  const [isEdit, setisEdit] = React.useState(false);
  const [openDialogAddEditCar, setDialogAddEditCar] = React.useState(false);
  const [ParkingId, setParkingId] = React.useState();
  const [carNumber, setcarNumber] = React.useState();
  const [carColor, setcarColor] = React.useState();
  const [carTypeID, setcarTypeID] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [indexcar, setindexcar] = React.useState();
  const [status, setstatus] = React.useState(false);
  const [driverId, setdriverId] = React.useState();
  const [driverNAME, setdriverNAME] = React.useState();
  const [statecar, setstatecar] = React.useState();

  const translationPath = 'Cars.CarsSlider.';

  const GetAllGetCarByIdApi = useCallback(async (id) => {
    setLoading(true) ;
    const AllCars = await GetCarById(id);
    setres(AllCars.result);
    setLoading(false) ;
  }, []);

  useEffect(() => {
    GetAllGetCarByIdApi(setSearch.carTypesId);
  }, [ setSearch, GetAllGetCarByIdApi]);

  return (
    <form
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      className='advance-search-wrapper slider'>
<Spinner isActive={loading} />
      <div className='d-flex-v-center-h-between '>
        <p className='textslabelmain labelcard '>{setSearch.typeName}</p>
        <div type='submit' className='btns theme-solid mb-2 mx-2 '>
          <Button
            title={getTranslate()(`${translationPath}Delete`)}
            onClick={openDleteDialogslider}
            style={{ color: '#bf3c3c' }}>
            <span className='mdi mdi-delete hoverdelteslidericon' />
          </Button>
        </div>
      </div>

      <div className='d-flex-v-center-h-between'>
        <p className='texts primary-bold px-2 '>
          <span
            className={setSearch.status === 'Active' ? 'state-online ' : 'state-offline  '}
          /> <span className={setSearch.status !== 'Active' ? 'state-off ' : '  '}>&nbsp; {setSearch.status.toUpperCase() === 'active'.toUpperCase()? getTranslate()(`${translationPath}Active`): getTranslate()(`${translationPath}InActive`)}</span> 
        </p>
      </div>
      <div className='mdi mdi-car-convertible titles '>
        <p className='textslabel'>
          <span className='labelcard'>{getTranslate()(`${translationPath}CarModel`)}</span> :{' '}
          {setSearch.carModel}
        </p>
      </div>
      <div className='mdi mdi-car-seat titles '>
        {' '}
        <p className='textslabel'>
          {' '}
          <span className='labelcard'>{getTranslate()(`${translationPath}noOfSets`)}</span> :{' '}
          {setSearch.noOfSets}
        </p>{' '}
      </div>
      <div className='mdi mdi-map-marker-question titles '>
        {' '}
        <p className='textslabel'>
          {' '}
          <span className='labelcard'>
            {getTranslate()(`${translationPath}distence1KPrice`)}
          </span> : {setSearch.distence1KPrice}
        </p>{' '}
      </div>
      <div className=' mdi mdi-car-multiple titles'>
        {' '}
        <p className='textslabel'>
          {' '}
          <span className='labelcard'>{getTranslate()(`${translationPath}noOfCars`)}</span> :{' '}
          {setSearch.noOfCars}{' '}
        </p>{' '}
      </div>
      <Divider />
      <div className='conterofcar'>
        {setSearch &&
          setSearch.carTypesId &&
          res &&
          res.map((v, index) => {
            return (
              <>
                <div className='d-flex-v-center-h-between ' style={{ paddingTop: '13px' }}>
                  <p className='textslabelmain' key={index} >
                    {' '}
                    {getTranslate()(`${translationPath}Car`)} {index + 1}
                  </p>
                  <div type='submit' className='btns theme-solid  '>
                    <p
                      className='textsdletemain'
                      title={getTranslate()(`${translationPath}Delete`)}
                      onClick={() => {
                        setcarsid(v.carsId);
                        setopenDialogDelete(true);
                      }}>
                      <IconButton size='small' color='primary'>
                        {' '}
                        <span className='mdi mdi-delete hoverdelteslidericon' />{' '}
                      </IconButton>
                    </p>
                  </div>
                </div>
                <div className='d-flex-v-center-h-between states'>
                  <p className='texts primary-bold px-2 ' key={index}>
                    {' '}
                    <span
                      className={v.status === 'Active' ? 'state-online ' : 'state-offline '}
                    />{' '}
                  <span className={v.status.toUpperCase() !== 'active'.toUpperCase()? 'state-off ' : '' }>  {v.status.toUpperCase() === 'active'.toUpperCase()? getTranslate()(`${translationPath}Active`): getTranslate()(`${translationPath}InActive`)}</span>
                  </p> 
                </div>
                <div className='d-flex-v-center'>
                  <span className=' mdi mdi-cup-water px-2' />
                  <span className='labelcard c-black'>
                    {getTranslate()(`${translationPath}CarColor`) + ' : '}
                  </span>

                  <span
                    key={index}
                    className='mdi mdi-36px mdi-car-convertible px-2 '
                    style={{ color: v.carColor }}
                  />
                </div>
                <div className=' mdi mdi-car-settings titles'>
                  {' '}
                  <p className='textslabel'>
                    {' '}
                    <span className='labelcard'>
                      {getTranslate()(`${translationPath}Platenumber`) + ' : '}
                    </span>{' '}
                    &nbsp;
                    <span className='PlateNum' key={index}>
                      <span className='PlateNumCUSTOME'>{v.carNumber}</span>
                    </span>
                  </p>{' '}
                </div>
                <div className=' mdi mdi-car-brake-parking titles'>
                  {' '}
                  <p className='textslabel' key={index} >
                    <span className='labelcard'>
                      {getTranslate()(`${translationPath}Parking`) + ' : '}
                    </span>{' '}
                    {v.parkingName}{' '}
                  </p>
                </div>
                <div className=' mdi mdi-steering titles'>
                  {' '}
                  <p className='textslabel' key={index} >
                    <span className='labelcard'>
                      {getTranslate()(`${translationPath}users`) + ' : '}
                    </span>{' '}
                    {v.user.fullName}{' '}
                  </p>
                </div>
                <div className='d-flex-v-center-h-between'>
                  <p className='textslabelmain'></p>

                  <div type='submit' className='btns theme-solid mb-2 mx-2 iconslideredit '>
                    <p className='iconslideredit' title={getTranslate()(`${translationPath}Edit`)}>
                      <IconButton
                        size='small'
                        color='primary'
                        onClick={() => {
                          setcarTypeID(v.carTypesId);
                          setcarNumber(v.carNumber);
                          setcarColor(v.carColor);
                          setcarsid(v.carsId);
                          setParkingId(v.parkingId);
                          setstatus(v.status);
                          setindexcar(index + 1);
                          setisEdit(true);
                          setDialogAddEditCar(true);
                          setstatecar(setSearch.status);
                          setdriverId(v.driverId);
                          setdriverNAME(v.user.fullName);
                        }}>
                        {' '}
                        <span className='mdi mdi-border-color iconedit ' />{' '}
                      </IconButton>
                    </p>
                  </div>
                </div>
                <Divider />
              </>
            );
          })}
      </div>
      <DialogDeleteTypeCars
        open={openDialogDelete}
        close={() => setopenDialogDelete(false)}
        delteid={carsid}
        reloadData={openEditDialogslider}
      />

      <DialogAddEditCar
        open={openDialogAddEditCar}
        close={() => setDialogAddEditCar(false)}
        carsid={carsid}
        ParkingId={ParkingId}
        carNumber={carNumber}
        statecar={statecar}
        carColor={carColor}
        driverId={driverId}
        driverNAME={driverNAME}
        isEdit={isEdit}
        status={status}
        indexcar={indexcar}
        carTypeID={carTypeID}
        setisEdit={() => setisEdit(false)}
        reloadData={openEditDialogslider}
      />
    </form>
  );
};
export { CarsSlider };
