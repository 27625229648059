import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { lookupTypesGet, lookupItemsGet } from '../../../../../Serviecs';
import { getTranslate } from '../../../../../Helpers';
import './LookupDialog.scss';
import { Inputs, AutocompleteComponent, CheckboxesComponent } from '../../../../../Componentes';

export const LookupsItemCreateDialog = (props) => {
  const [responseType, setResponseType] = useState([]);
  const [responseItem, setResponseItem] = useState([]);
  const [searchedItem, setSearchedItem] = useState(null);
  const [lookupItemSearched, setLookupItemSearched] = useState(null);
  const [parentLookupItemName, setParentLookupItemName] = useState('');
  const [parentLookupTypeName, setParentLookupTypeName] = useState('');
  const time = useRef(null);
  const timeItem = useRef(null);

  const loadType = useCallback(async () => {
    setResponseType(
      await lookupTypesGet({
        pageIndex: 1,
        pageSize: 20,
        searchedItem,
      })
    );
  }, [searchedItem]);
  const loadItem = useCallback(
    async (parentLookupTypeName) => {
      setResponseItem(
        await lookupItemsGet({
          pageIndex: 1,
          pageSize: 20,
          searchedItem: lookupItemSearched || '',
          lookupTypeName: parentLookupTypeName || props.state.parentLookupTypeName || '',
        })
      );
    },
    [lookupItemSearched, props.state.parentLookupTypeName]
  );

  const validate = () =>
    (!props.hasParent && props.state.lookupItemName && props.state.lookupItemName !== '') ||
    (props.hasParent &&
      props.state.lookupItemName &&
      props.state.lookupItemName !== '' &&
      props.state.parentLookupItemId);

  // const clearAllFields = () => {
  //   props.setState({
  //     id: 'parentLookupTypeName',
  //     value: '',
  //   });
  //   props.setState({
  //     id: 'parentLookupTypeId',
  //     value: null,
  //   });
  //   props.setState({
  //     id: 'parentLookupItemName',
  //     value: '',
  //   });
  //   props.setState({
  //     id: 'parentLookupItemId',
  //     value: null,
  //   });
  // };
  const searchHandler = (event) => {
    const value = event.target.value;
    if (time.current) clearTimeout(time.current);
    time.current = setTimeout(() => {
      setSearchedItem(value);
    }, 700);
  };
  const searchLookupItemHandler = (event) => {
    const value = event.target.value;
    if (timeItem.current) clearTimeout(timeItem.current);
    timeItem.current = setTimeout(() => {
      setLookupItemSearched(value);
    }, 700);
  };
  useEffect(() => {
    loadType();
  }, [loadType, searchedItem]);
  useEffect(() => {
    loadItem();
  }, [loadItem, lookupItemSearched]);
  useEffect(() => {
    return () => {
      if (time.current) clearTimeout(time.current);
      if (timeItem.current) clearTimeout(timeItem.current);
    };
  }, [time]);
  return (
    <Dialog
      className='lookupItem'
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'>
      <DialogTitle id='alert-dialog-slide-title'>
        {getTranslate()('Lookups.CreateLookupItemDialog.LookupItem')}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} className='mb-3'>
            <Inputs
              inputPlaceholder='LookupName'
              labelValue='LookupName'
              idRef='lookupItemNameRef'
              value={props.state.lookupItemName}
              onInputChanged={(event) =>
                props.setState({ id: 'lookupItemName', value: event.target.value })
              }
              translationPath='Lookups.CreateLookupItemDialog.'
            />
            {/* <FormControl className='input-wrapper flex-wrap'>
              <label htmlFor='lookupItemName' className='texts-form'>
                {getTranslate()('Lookups.CreateLookupItemDialog.LookupName')}
              </label>
              <TextField
                required
                className='inputs theme-form-builder'
                id='lookupItemName'
                value={props.state && props.state.lookupItemName ? props.state.lookupItemName : ''}
                onChange={(e) => props.setState(e.target)}
              />
            </FormControl> */}
          </Grid>
          <div className='w-100 mb-3'>
            <Inputs
              inputPlaceholder='description'
              labelValue='description'
              idRef='descriptionRef'
              value={props.state.description}
              onInputChanged={(event) =>
                props.setState({ id: 'description', value: event.target.value })
              }
              rows={4}
              multiline
              translationPath='Lookups.CreateLookupItemDialog.'
            />
          </div>
          <div className='w-100 mb-2'>
            <Inputs
              inputPlaceholder='code'
              labelValue='code'
              idRef='lookupItemCodeRef'
              value={props.state.lookupItemCode}
              onInputChanged={(event) =>
                props.setState({ id: 'lookupItemCode', value: event.target.value })
              }
              translationPath='Lookups.CreateLookupItemDialog.'
            />
          </div>
          <Grid item xs={12} className='mb-3'>
            <CheckboxesComponent
              idRef='hasParentRef'
              checked={props.hasParent}
              onSelectedCheckboxChanged={async (e) => {
                props.setHasParent(e.target.checked);
                props.setState({ id: 'parentLookupTypeName', value: null });
                props.setState({ id: 'parentLookupTypeId', value: null });
                props.setState({
                  id: 'parentLookupItemId',
                  value: null,
                });
                props.setState({
                  id: 'parentLookupItemName',
                  value: null,
                });
              }}
              label={getTranslate()('Lookups.CreateLookupItemDialog.HasParent')}
            />
          </Grid>

          {props.hasParent && (
            <>
              <Grid item xs={12} className='mb-3'>
                <AutocompleteComponent
                  idRef='lookupItemSearchRef'
                  translationPath='Lookups.CreateLookupItemDialog.'
                  labelValue='LookupType'
                  options={(responseType && responseType.result) || []}
                  getOptionSelected={(option) =>
                    option && option.lookupTypeName === props.state.parentLookupTypeName
                  }
                  value={props.state.parentLookupTypeName}
                  inputValue={parentLookupTypeName || props.state.parentLookupTypeName || ''}
                  onChange={async (e, value) => {
                    if (!value) return;
                    props.setState({ id: 'parentLookupTypeName', value: value.lookupTypeName });
                    props.setState({ id: 'parentLookupTypeId', value: value.lookupTypeId });
                    props.setState({
                      id: 'parentLookupItemId',
                      value: null,
                    });
                    props.setState({
                      id: 'parentLookupItemName',
                      value: null,
                    });
                    setLookupItemSearched(null);
                    setParentLookupItemName('');
                    loadItem(value.lookupTypeName);
                  }}
                  onInputChange={(e, value) => {
                    setParentLookupTypeName(value);
                  }}
                  onKeyUp={searchHandler}
                  getOptionLabel={(option) =>
                    (option.lookupTypeName && option.lookupTypeName) || ''
                  }
                  multiple={false}
                  withoutSearchButton
                />
              </Grid>
              <Grid item xs={12} className='mb-3'>
                <AutocompleteComponent
                  idRef='parentLookupItemRef'
                  translationPath='Lookups.CreateLookupItemDialog.'
                  labelValue='ParentLookupItem'
                  options={(responseItem && responseItem.result) || []}
                  getOptionSelected={(option) =>
                    option && option.lookupItemName === props.state.parentLookupItemName
                  }
                  value={props.state.parentLookupItemName}
                  inputValue={parentLookupItemName || props.state.parentLookupItemName || ''}
                  onChange={async (e, value) => {
                    if (!value) return;
                    props.setState({
                      id: 'parentLookupItemId',
                      value: value.lookupItemId,
                    });
                    props.setState({
                      id: 'parentLookupItemName',
                      value: value.lookupItemName,
                    });
                  }}
                  onInputChange={(e, value) => {
                    setParentLookupItemName(value);
                  }}
                  onKeyUp={searchLookupItemHandler}
                  getOptionLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ''
                  }
                  multiple={false}
                  withoutSearchButton
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setOpen(false);
            props.setState({ reset: true });
          }}
          className='btns theme-solid bg-cancel'>
          {getTranslate()('Lookups.CreateLookupItemDialog.Cancel')}
        </Button>
        <Button
          disabled={!validate()}
          className='btns theme-solid'
          onClick={() => {
            props.setOpen(false);
            props.isEdit ? props.onSave(props.state) : props.onCreate(props.state);
            props.setState({ reset: true });
          }}>
          {getTranslate()('Lookups.CreateLookupItemDialog.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
