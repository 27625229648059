import React, { useState, useEffect, useCallback } from 'react';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';
import { Spinner } from '../../../Componentes';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { PageContentSlider } from './PageContetnUtilities/PageContentSlider/PageContentSlider';
import { GetAllContentPages } from '../../../Serviecs/PageContentServices/PageContentServices';
import { useEventListener } from '../../../Helpers';
import { getTranslate } from '../../../Helpers';
import {
  PageContentDeleteDialog,
  PageContentDialog,
} from './PageContetnUtilities/PageContentDialogs';
import {
  IconButton,
  TextField,
  Button,
  FormControl,
  Grid,
  Typography,
  CardContent,
  Card,
} from '@material-ui/core';
import './PageContentView.scss';
import { useTitle } from '../../../Hooks/useTitle';
import {
  NoDataFoundComponent,
  NoSearchResultComponent,
  NoContentComponent,
} from '../../../Componentes';

const PageContentView = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [searchedItem, setSearchedItem] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [openAddEdit, setOpenAddEdit] = useState(false);
  const [pageContentInfo, setPageContentInfo] = useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [page, setPage] = useState(0);
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [responseResult, setResponseResult] = useState(() => ({ result: [], totalCount: 0 }));
  const [rowsPerPage] = useState(10);
  const [reloaded, setReloaded] = React.useState(false);
  let time = setTimeout(() => {}, 300);
  useTitle(getTranslate()(`SideMenuView.MobilePages.PageContent`));

  const translationPath = 'PageContents.';

  const GetContentPages = useCallback(
    async (pageIndex, pageSize, searchedItem) => {
      setLoading(true);
      const res = await GetAllContentPages(pageIndex, pageSize, searchedItem);
      setResponse(res);
      if (res && res.totalCount === 0) {
        setisFirstLoad(false);
      }

      if (reloaded) setResponseResult({ result: [], totalCount: 0 });
      else {
        if (page === 0) setResponseResult(res);
        else {
          setResponseResult((item) => ({
            result: item.result.concat(res.result),
            totalCount: res.totalCount,
          }));
        }
      }
      setLoading(false);
    },
    [page, reloaded]
  );

  const loadMoreData = useCallback(() => {
    if (
      response &&
      response.result &&
      response.totalCount > response.result.length &&
      (page + 1) * rowsPerPage <= response.result.length
    )
      setPage(page + 1);
  }, [page, response, rowsPerPage]);

  const scrollHandler = () => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - 5
    )
      loadMoreData();
  };

  useEventListener('scroll', scrollHandler);

  useEffect(() => {
    if (document.body.scrollHeight <= document.body.clientHeight && !loading) loadMoreData();
    return () => {};
  }, [loadMoreData, loading]);

  useEffect(() => {
    GetContentPages(page + 1, rowsPerPage, '');
  }, [GetContentPages, page, rowsPerPage]);

  const HandleSliderSidePanel = useCallback(
    (item) => {
      sideMenuComponentUpdate(
        <PageContentSlider
          item={item}
          reloadData={() => GetContentPages(page + 1, rowsPerPage, '')}
          setOpenAddEdit={setOpenAddEdit}
          setIsEdit={setIsEdit}
          setPageContentInfo={setPageContentInfo}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [GetContentPages, page, rowsPerPage]
  );

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  return (
    <div className='view-wrapper pageContentView'>
      <Spinner isActive={loading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <Button
              className='btns theme-solid'
              onClick={() => {
                setIsEdit(false);
                setOpenAddEdit(true);
              }}>
              {getTranslate()(`${translationPath}AddNewPage`)}
            </Button>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <FormControl className='input-wrapper'>
                <Button className='btns-icon theme-transparent mx-2'>
                  <span className='mdi mdi-magnify' />
                </Button>
                <TextField
                  className='inputs theme-solid'
                  value={searchedItem}
                  onKeyUp={() => {
                    time = setTimeout(() => {
                      GetContentPages(page + 1, rowsPerPage, searchedItem);
                    }, 500);
                  }}
                  onKeyDown={() => {
                    clearTimeout(time);
                  }}
                  onChange={(e) => setSearchedItem(e.target.value)}
                  label={getTranslate()(`${translationPath}Search`)}
                  placeholder={getTranslate()(`${translationPath}search-by-page-name`)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {searchedItem && (
                          <IconButton
                            onClick={() => {
                              setSearchedItem('');
                              GetContentPages(page + 1, rowsPerPage, '');
                            }}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className='section px-2'>
        <NoDataFoundComponent />
        {response &&
          response.result &&
          response.result.length === 0 &&
          searchedItem.length === 0 &&
          !isFirstLoad && <NoContentComponent />}
        <Grid container spacing={3} className='pageWrapper'>
          {responseResult &&
          responseResult.totalCount === 0 &&
          searchedItem.length !== 0 &&
          !isFirstLoad ? (
            <NoSearchResultComponent />
          ) : (
            <>
              {responseResult &&
                responseResult.result &&
                responseResult.result.map((item, i) => (
                  <Grid item lg={6} sm={12} xl={4} xs={12} key={i} className='pageCard'>
                    <Card onClick={() => HandleSliderSidePanel(item)}>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={11}>
                            <Grid item xs={12} className='pageName'>
                              <Typography>{item.pageName}</Typography>
                            </Grid>
                            <Grid item xs={12} className='pageContent'>
                              <span className='mdi mdi-format-list-text' />
                              <Typography className='contentTag'>
                                {getTranslate()(`${translationPath}Title`)}
                              </Typography>
                              <Typography>{item.contentTitle}</Typography>
                            </Grid>
                            <Grid item xs={12} className='pageContent'>
                              <span className='mdi mdi-text' />
                              <Typography className='contentTag'>
                                {getTranslate()(`${translationPath}Body`)}
                              </Typography>
                              <Typography className='textFading'>
                                <span dangerouslySetInnerHTML={{ __html: item.contentBody }} />
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={1} className='pageActions'>
                            <Button
                              className='mdi mdi-file-document-edit-outline mb-2'
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setIsEdit(true);
                                setPageContentInfo(item);
                                setOpenAddEdit(true);
                              }}
                            />
                            <Button
                              className='mdi mdi-delete-outline'
                              onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpenDelete(true);
                                setPageContentInfo(item);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </div>
      <PageContentDeleteDialog
        setReloaded={setReloaded}
        open={openDelete}
        setOpen={setOpenDelete}
        reloadData={() => GetContentPages(page + 1, rowsPerPage, '')}
        item={pageContentInfo}
      />
      <PageContentDialog
        setReloaded={setReloaded}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        open={openAddEdit}
        setOpen={setOpenAddEdit}
        reloadData={() => GetContentPages(page + 1, rowsPerPage, '')}
        item={pageContentInfo}
      />
    </div>
  );
};

export { PageContentView };
