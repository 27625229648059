import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import { getTranslate, GetParams, getHistory, getErrorByName } from '../../../../Helpers';
import { VerifyApplicationUserCode } from '../../../../Serviecs';
import Joi from 'joi';
import { Inputs, Spinner } from '../../../../Componentes';
import '../../LoginViews/LoginView/LoginView.scss';

const translationPath = 'login.';

const VerificationCodeView = (props) => {
  const [verificationCode, setVerificationCode] = useState();
  const [identityVerificationId, setIdentityVerificationId] = useState();
  const [emailOptions, setEmailOptions] = useState();
  const [phoneOptions, setPhoneOptions] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const schema = Joi.object({
    verificationCode: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}this-field-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ verificationCode });

  useEffect(() => {
    setIdentityVerificationId(GetParams('identityVerificationId').split('?')[0]);
    setEmailOptions(GetParams('identityVerificationId').split('?email=')[1].split('?phone')[0]);
    setPhoneOptions(
      GetParams('identityVerificationId').split('?phone=')[1].split('?setIsEmail')[0]
    );
    setIsEmail(GetParams('identityVerificationId').split('?isEmail=')[1]);
  }, [props.match]);

  const handleCodeSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const options = await VerifyApplicationUserCode({
      identityVerificationId: +identityVerificationId,
      code: verificationCode,
    });
    if (options) {
      getHistory().push(
        `/account/NewPasswordView?identityVerificationId=${options.identityVerificationId}`
      );
      setIsSubmitted(true);
    }
    setLoading(false);
  };

  return (
    <>
      <div className='forget-password-text'>
        <Spinner isActive={loading} />
        <Typography className='forget-password'>
          {getTranslate()(`${translationPath}we-need-to-verify-your-identity`)}
        </Typography>
        <Typography className='verification-text'>
          {getTranslate()(`${translationPath}we-just-sent-a-code-to`)}
          {isEmail === 'true' ? emailOptions : phoneOptions}
          {getTranslate()(`${translationPath}pleaseWrite-it-down-here-when-you-get-it`)}
        </Typography>
      </div>
      <form noValidate className='form-wrapper' onSubmit={handleCodeSubmit}>
        <Inputs
          helperText={getErrorByName(schema, 'verificationCode').message}
          error={getErrorByName(schema, 'verificationCode').error}
          isWithError
          isSubmitted={isSubmitted}
          fieldClasses='inputs theme-underline mb-4'
          label={getTranslate()(`${translationPath}code`)}
          name='Code'
          idRef='verificationCode'
          value={verificationCode}
          onInputChanged={(e) => setVerificationCode(e.target.value)}
        />
        <div className='forget-password-radio'></div>
        <div className='d-flex-v-center-h-between'>
          <Button
            onClick={() => getHistory().push('/account/login')}
            className='btns cancel-forget-passwrod '>
            {getTranslate()(`${translationPath}cancel`)}
          </Button>
          <Button className='btns submit-forget-password' type='submit'>
            {getTranslate()(`${translationPath}next`)}
          </Button>
        </div>
      </form>
    </>
  );
};

export { VerificationCodeView };
