import config from '../../Assets/Configration/Configration.json';
import { showError } from '../../Helpers';
import { HttpServices } from '../HttpServices/HttpServices';

export const GetAllContentPages = async (pageIndex, pageSize, searchedItem) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/PagesContents/GetAllContentPages?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchedItem}`
    );
    return result;
  } catch (e) {
    showError('Error Retriving Pages');
  }
};

export const CreateContentPages = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/PagesContents/CreateContentPage`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Creating Pages');
  }
};

export const EditContentPages = async (pageContentId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/PagesContents/${pageContentId}`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Editing Pages');
  }
};

export const DeleteContentPages = async (pageContentId) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/PSTViewing/PagesContents/${pageContentId}`
    );
    return result;
  } catch (e) {
    showError('Error Deleting Pages');
  }
};
