import {
  HomeRoutes,
  TripsRoutes,
  RolesRoutes,
  ToursRoutes,
  LookupsRoutes,
  PromoCodeRoutes,
  MobilePagesRoutes,
  FormBuilderRoutes,
  OffersRoutes,
  SectionsRoutes,
} from '../HomeRoutes';

export const BreadCrumbRoutes = [
  ...HomeRoutes,
  ...TripsRoutes,
  ...RolesRoutes,
  ...ToursRoutes,
  ...LookupsRoutes,
  ...PromoCodeRoutes,
  ...MobilePagesRoutes,
  ...FormBuilderRoutes,
  ...OffersRoutes,
  ...SectionsRoutes
];
