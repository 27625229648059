import React, { useEffect } from 'react';
import {
  EditaParking,
  countryservis,
  ActivateParking,
} from '../../../../Serviecs/Parking/ParkingService';
import { getTranslate, showSuccess } from '../../../../Helpers';
import { Spinner, Inputs, SelectComponet } from '../../../../Componentes';
import {
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  Grid,
  FormControl,
  Typography,
  Divider,
  Popover,
} from '@material-ui/core';
import Joi from 'joi';
import './Dialog.scss';
import { GoogleMapsComponent } from '../../../../Componentes';
import { getErrorByName } from '../../../../Helpers';
import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const translationPath = 'Parking.EditParkingDialog.';
const DialogEditParking = (props) => {
  const [ResponseCountry, setResponseCountry] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState('');
  const Getcountry = async () => {
    const res = await countryservis();
    setResponseCountry(res && res.result ? res.result : []);
  };

  useEffect(() => {
    Getcountry();
  }, []);

  useEffect(() => {
    props.Parkingstae.Status === 'Active' ? setValue('true') : setValue('false');
  }, [props.Parkingstae.Status]);
  console.log(props.Parkingstae, 'props.Parkingstae.parkingNumber');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const schema = Joi.object({
    parkingNumber: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrorparkingnumber`),
      }),
    city: Joi.string()
      .min(1)
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}ValidationErrorparkingcity`),
      }),
    parkingName: Joi.string()
      .min(1)
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}ValidationErrorparkingname`),
      }),
    address: Joi.string()
      .min(1)
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}ValidationErrorparkingaddres`),
      }),
    longitude: Joi.number()
      .min(1)
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrornumber`),
      }),
    latitude: Joi.number()
      .min(1)
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrornumber`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate(props.Parkingstae);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Dialog open={props.open} onClose={props.close} keepMounted className="sliderDialogs">
      <Spinner isActive={loading} />
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          props.close();
        }}
      >
        <div className="colse-button">
          <Button
            className="MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid bg-danger mx-2 mb-2"
            tabindex="0"
            title={getTranslate()(`${translationPath}Cancel`)}
            type="button"
            onClick={() => {
              props.close();
            }}
          >
            <span className="MuiButton-label">
              <span className="mdi mdi-close"></span>
            </span>
            <span class="MuiTouchRipple-root"></span>
          </Button>
        </div>
        <DialogTitle className="dialogTitle">
          <Typography> {getTranslate()(`${translationPath}EditParking`)}</Typography>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={8}>
              <Grid container justify="center">
                <FormControl className="input-wrapper flex-wrap textField">
                  <Typography> {getTranslate()(`${translationPath}parkingNumber`)}</Typography>

                  <Inputs
                    idRef="parkingNumber"
                    translationPath={translationPath}
                    isWithError
                    type="number"
                    helperText={getErrorByName(schema, 'parkingNumber').message}
                    inputPlaceholder="parkingNumber"
                    error={getErrorByName(schema, 'parkingNumber').error}
                    value={props.Parkingstae.parkingNumber}
                    onInputChanged={(e) =>
                      props.setParkingstae({ ...props.Parkingstae, parkingNumber: e.target.value })
                    }
                  />
                </FormControl>

                <FormControl className="input-wrapper flex-wrap textField">
                  <Typography> {getTranslate()(`${translationPath}parkingName`)}</Typography>

                  <Inputs
                    idRef="parkingName"
                    translationPath={translationPath}
                    isWithError
                    helperText={getErrorByName(schema, 'parkingName').message}
                    inputPlaceholder="parkingName"
                    error={getErrorByName(schema, 'parkingName').error}
                    value={props.Parkingstae.parkingName}
                    onInputChanged={(e) =>
                      props.setParkingstae({ ...props.Parkingstae, parkingName: e.target.value })
                    }
                  />
                </FormControl>
                <FormControl className="input-wrapper flex-wrap textField" size="small">
                  <Typography> {getTranslate()(`${translationPath}city`)}</Typography>

                  <SelectComponet
                    idRef="lookupItemName"
                    data={ResponseCountry}
                    valueInput="lookupItemId"
                    textInput="lookupItemName"
                    isWithError
                    // helperText={getErrorByName(schema, 'color').message}
                    emptyItem={{
                      value: '',
                      text: getTranslate()(`${translationPath}Selectcity`),
                    }}
                    //error={getErrorByName(schema, 'color').error}
                    value={props.Parkingstae.cityId}
                    onSelectChanged={(e) =>
                      props.setParkingstae({ ...props.Parkingstae, cityId: e.target.value })
                    }
                  />
                </FormControl>
                <FormControl className="input-wrapper flex-wrap textField">
                  <Typography>{getTranslate()(`${translationPath}address`)}</Typography>

                  <Inputs
                    idRef="address"
                    translationPath={translationPath}
                    isWithError
                    helperText={getErrorByName(schema, 'address').message}
                    inputPlaceholder="address"
                    error={getErrorByName(schema, 'address').error}
                    value={props.Parkingstae.address}
                    onInputChanged={(e) =>
                      props.setParkingstae({ ...props.Parkingstae, address: e.target.value })
                    }
                  />
                </FormControl>

                <Grid container justify="center" spacing={1}>
                  <Grid item xs={6}>
                    <Grid item xs={12} className="textField  ">
                      <Typography> {getTranslate()(`${translationPath}latitude`)}</Typography>
                    </Grid>
                    <FormControl className="input-wrapper inputmap">
                      <Inputs
                        idRef="latitude"
                        translationPath={translationPath}
                        isWithError
                        type="number"
                        helperText={getErrorByName(schema, 'latitude').message}
                        inputPlaceholder="latitude"
                        error={getErrorByName(schema, 'latitude').error}
                        value={props.Parkingstae.latitude}
                        onInputChanged={(e) =>
                          props.setParkingstae({ ...props.Parkingstae, latitude: e.target.value })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} className="textField ">
                      <Typography> {getTranslate()(`${translationPath}longitude`)}</Typography>
                    </Grid>
                    <FormControl className="input-wrapper inputmap">
                      <Inputs
                        idRef="longitude"
                        translationPath={translationPath}
                        isWithError
                        type="number"
                        helperText={getErrorByName(schema, 'longitude').message}
                        inputPlaceholder="longitude"
                        error={getErrorByName(schema, 'longitude').error}
                        value={props.Parkingstae.longitude}
                        onInputChanged={(e) =>
                          props.setParkingstae({ ...props.Parkingstae, longitude: e.target.value })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className="mb-3">
                    <Typography> {getTranslate()(`${translationPath}Status`)}</Typography>
                    <FormControl variant="outlined" className="input-wrapper " size="medium">
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        row
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label={getTranslate()(`${translationPath}Active`)}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label={getTranslate()(`${translationPath}inActive`)}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <div
                    className=" mapButton-in-Parking "
                    title={getTranslate()(`${translationPath}title-map`)}
                  >
                    <Button onClick={handleClick}>
                      <div type="submit" className="btns theme-solid  mx-2 ">
                        <p className="Titlemap mdi mdi-Description">
                          <RoomTwoToneIcon style={{ marginBottom: -7 }} />{' '}
                          {getTranslate()(`${translationPath}OpenMaps`)}{' '}
                        </p>
                      </div>
                    </Button>
                  </div>

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Grid item xs={12}>
                      <GoogleMapsComponent
                        locations={
                          (props.Parkingstae.latitude && [
                            {
                              latitude: props.Parkingstae.latitude,
                              longitude: props.Parkingstae.longitude,
                            },
                          ]) ||
                          []
                        }
                        center={
                          { lat: props.Parkingstae.latitude, lng: props.Parkingstae.longitude } ||
                          undefined
                        }
                        onSearchLocationChanged={(searchValue) => {
                          props.setParkingstae({
                            ...props.Parkingstae,
                            latitude: searchValue.lat,
                            longitude: searchValue.lng,
                          });
                        }}
                        onClick={(location) => {
                          props.setParkingstae({
                            ...props.Parkingstae,
                            latitude: location.lat,
                            longitude: location.lng,
                          });
                        }}
                      />
                    </Grid>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>{' '}
        <Grid container justify="center">
          {' '}
          <Button
            onClick={() => {
              props.close();
            }}
            color="primary"
            className="btns bg-cancel"
          >
            {getTranslate()(`${translationPath}Cancel`)}
          </Button>
        </Grid>
        <div className="doneBtnContainer">
          <Grid container justify="center">
            <Grid item xs={12} className="doneBtn">
              <Button
                disabled={
                  props.Parkingstae.parkingid === '' ||
                  props.Parkingstae.parkingNumbe === '' ||
                  props.Parkingstae.parkingName === '' ||
                  props.Parkingstae.cityId === '' ||
                  props.Parkingstae.address === '' ||
                  props.Parkingstae.longitude === '' ||
                  props.Parkingstae.longitude === ''
                }
                onClick={async () => {
                  setLoading(true);
                  await EditaParking(+props.Parkingstae.parkingid, {
                    parkingNumber: +props.Parkingstae.parkingNumber,
                    parkingName: props.Parkingstae.parkingName,
                    cityId: +props.Parkingstae.cityId,
                    address: props.Parkingstae.address,
                    latitude: +props.Parkingstae.latitude,
                    longitude: +props.Parkingstae.longitude,
                  });
                  await ActivateParking(+props.Parkingstae.parkingid, value);
                  props.reloadData();
                  props.close();
                  setLoading(false);
                  showSuccess(getTranslate()(`${translationPath}NotificationEditsave`));
                }}
                variant="contained"
                className="btns theme-solid"
              >
                {getTranslate()(`${translationPath}Done`)}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </Dialog>
  );
};
export default DialogEditParking;
