import { LoginStates } from '../../States';

const initialState = {
  loginResponse: localStorage.getItem('session')
      ? JSON.parse(localStorage.getItem('session'))
      : null,
};

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LoginStates.LOGIN:
    case LoginStates.RESET:
    case LoginStates.UPDATE:
    case LoginStates.LOGOUT:
      return { ...state, loginResponse: null };
    case LoginStates.LOGIN_SUCCESS:
    case LoginStates.LOGIN_FAIL:
      return { ...state, loginResponse: action.payload };
    default:
      return { ...state, LoginResponse: null };
  }
};
