import React from 'react';
import { DeleteOrganizationUser } from '../../../../../../../Serviecs';
import { getTranslate, showSuccess, showError } from '../../../../../../../Helpers';
import { PropTypes } from 'prop-types';
import { DialogComponent, Spinner } from '../../../../../../../Componentes';
const translationPath = 'Administration.Users.UsersDeleteDialog.';
const UserDeleteDialog = ({ activeUserItem, isOpen, isOpenChanged, reloadData }) => {
  const [loading, setLoading] = React.useState(false);
  const deleteHandler = async () => {
    setLoading(true);
    const res = await DeleteOrganizationUser(activeUserItem.id);
    if (res) {
      reloadData();
      showSuccess(getTranslate()(`${translationPath}Notif_DeleteUsers`));
    } else showError(getTranslate()(`${translationPath}user-delete-failed`));
    setLoading(false);
    isOpenChanged();
  };
  return (
    <DialogComponent
      titleText='confirm-message'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <Spinner isActive={loading} />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>{`${getTranslate()(`${translationPath}DeleteText`)} ${
            activeUserItem.fullName
          }`}</span>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mb-0 mx-0 br-0'
      isOpen={isOpen}
      onSaveClicked={deleteHandler}
      onCloseClicked={isOpenChanged}
      onCancelClicked={isOpenChanged}
      translationPath={translationPath}
    />
  );
};
export { UserDeleteDialog };
UserDeleteDialog.propTypes = {
  activeUserItem: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};
