import React from 'react';
import { getDownloadableLink, getTranslate } from '../../../../../../../Helpers';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import './MobilePointsToursView.scss';
import { LoadableImageComponant } from '../../../../../../../Componentes';

export const MobilePointsToursView = ({ translationPath, tours }) => {
  return (
    <div className='mobile-points-tours-wrapper'>
      <div className='mobile-points-tours-title'>
        {getTranslate()(`${translationPath}recommended-tours`)}
      </div>
      <div className='mobile-points-tours-content'>
        {tours &&
          tours.result.map((item, i) => (
            <div className='mobile-points-card' key={i}>
              <div className='mobile-card-title'>{item.tourName}</div>
              <div className='rating-wrapper'>
                {getTranslate()(`${translationPath}price`)}:
                <div className='tour-price'>
                  {item.totalFromPriceAfterDiscount}
                  {'  AED'}
                </div>
              </div>
              <div className='mobile-card-body'>
                <div className='mobile-card-timeline'>
                  <Timeline
                    className='timeline-wrapper'
                    align={localStorage.getItem('localization').isRtl ? 'right' : 'left'}>
                    {item.tourSummaryTrips.map((el, i) => (
                      <TimelineItem key={i}>
                        <TimelineSeparator>
                          <TimelineDot />
                          {i > 0 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          <div className='mobile-card-timeline-body'>
                            <div className='item-card-image mt-2'>
                              <LoadableImageComponant
                                src={getDownloadableLink(el.tripCoverImage)}
                                alt={el.tripName}
                                classes='img-center'
                              />
                            </div>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
