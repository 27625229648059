import React,{ useState,useEffect,useCallback} from 'react'
import { Grid } from 'react-bootstrap';
import {Doughnut} from 'react-chartjs-2';
import './TotalChartViewComponent.scss'
import {GetUserTypeStatistics} from './../../../../../Serviecs'
import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';
import { getTranslate } from ' ./../../src/Helpers';

export const TotalUsers=()=>{
  const kFormatter=(num)=> {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}
  const translationPath = 'TotalUsers.';
  const [isLoading, setIsLoading] = useState(false);
    const [chartData,setChartData] =useState({
    labels: [ 'Agent Users', 'Drivers Users','Other Users'],
      totalUsers:'',
    datasets:[
      {

        data:[],
        backgroundColor:[
          '#580DBF',
          '#79D2DE',
          '#EC6666'
        ]
      }
    ]
})
const GetAllUser = useCallback(async () => {
  setIsLoading(true);
  const Status = await GetUserTypeStatistics();
  if (Status) {
    setChartData((items) => {
      const newObj={
        totalAgentUsers: Status.totalAgentUsers,
        totalDriversUsers: Status.totalDriversUsers,
        otherUsers: Status.otherUsers
      }
      items.totalUsers=Status.totalUsers
      items.datasets[0].data=Object.values(newObj)
      return items
    });
    setIsLoading(false);
  }
}, []);

useEffect(() => {
  GetAllUser();
}, [GetAllUser]);

    return (

      <div className='Total'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='titleDiv'>
      <p className='titleParagraph'>{getTranslate()(`${translationPath}Total Users`)}</p>
      </div>

    
      <Grid row>
      
      <Grid md={2} className='chartGrid'>
        
      <div className='ChartDiv'> 
      <p className='percentage-number'>{kFormatter(chartData.totalUsers)}</p>
        <Doughnut   
      
      data={chartData}
    
      options={{
                legend:{
          display:false,
        },
        
        responsive: true
        
      }}
    />
      
      </div>
    
      </Grid>
      <Grid >
      <Grid>
      <Grid className='list'>
      
      <ul> 
      {chartData && chartData.datasets[0].backgroundColor.map((Color, index) =>(
        <li style={{color:`${Color}`,margin: '0 0 3px 0 ' }} key={index}></li>
        ))}
      </ul>
     
      </Grid>
      <Grid className='btns theme-transparent c-black-liGht legend'> 
      <ul>
      {chartData.labels.map((label, index) =>(
        <li key={index}>{getTranslate()(`${translationPath}${label}`)}</li>
    ) )}
    </ul>
    </Grid>

      <Grid  className='btns theme-transparent c-black-liGht legend'>
      <ul>
      {chartData.datasets &&chartData.datasets[0].data&&
        chartData.datasets[0].data.map((items, index) => <li key={index}>{items}</li>)}
   
      </ul>
       
    </Grid>
      
    
       
      </Grid>
      
      </Grid>
      
      
      </Grid>
      
    </div>

      
        

     
        
      
    )
}
