import WomanImg from '../Assets/Images/defaults/woman.svg';
import ManImg from '../Assets/Images/defaults/man.svg';
import CorporateImg from '../Assets/Images/defaults/corporate.svg';
import BuildingsImg from '../Assets/Images/defaults/buildings.png';

import BoySkyImg from '../Assets/Images/defaults/boy-sky.svg';
import BoyGreenLightImg from '../Assets/Images/defaults/boy-green-light.svg';
import BoyRedLightImg from '../Assets/Images/defaults/boy-red-light.svg';
import BoyVioletImg from '../Assets/Images/defaults/boy-violet.svg';
import TripImg from '../Assets/Images/defaults/buildings.png';


export const DefaultImagesEnum = {
  woman: {
    key: 'woman',
    defaultImg: WomanImg,
  },
  man: {
    key: 'man',
    defaultImg: ManImg,
  },
  corporate: {
    key: 'corporate',
    defaultImg: CorporateImg,
  },
  trip: {
    key: 'trip',
    defaultImg: TripImg,
  },
  buildings: {
    key: 'buildings',
    defaultImg: BuildingsImg,
  },
  boys: {
    key: 'boys',
    defaultImgs: [BoySkyImg, BoyGreenLightImg, BoyRedLightImg, BoyVioletImg],
  },
};
