import {
  SliderView,
  PageContentView,
  ParkingView,
  AdsView,
  CarsView,
  UserView,
  TrackingView,
  AgentsView,
  DriversView,
  ContactSupportView,
  PendingToursView,
  DashboardView,
  ReportBasketView,
  UserManagementView,
  InvoiceView,
} from '../../Views/Home';
import { LookupsLayout } from '../../Layouts/Home/LookupsLayout/LookupsLayout';
import { FormBuilderRoutes } from './FormBuilderRoutes';
import { FormBuilderLayout } from '../../Layouts/Home/FormBuilder/FormBuilderLayout';
import { RolesLayout } from '../../Layouts/Home/Administration/RolesLayout';
import { RolesRoutes } from './RolesRoutes';
import { LookupsRoutes } from './LookupsRoutes';
import { TripsLayout } from '../../Layouts/Home/TripsLayout/TripsLayout';
import { ToursLayout } from '../../Layouts/Home/ToursLayout/ToursLayout';
import { SectionsLayout } from '../../Layouts/Home/SectionsLayout/SectionsLayout';
import { PageErrorLayout } from '../../Layouts/PageErrorLayout/PageErrorLayout';
import { PromoCodeLayout } from '../../Layouts/Home/PromoCodeLayout/PropmoCodeLayout';
import { MobilePagesLayout } from '../../Layouts/Home/MobilePagesLayout/MobilePagesLayout';
import { PromoCodeRoutes } from './PromoCodeRoutes';
import { OffersLayout } from '../../Layouts/Home/OffersLayout/OffersLayout';

export const HomeRoutes = [
  {
    id: 1,
    path: '/Slider',
    name: 'SideMenuView.MobilePages.Slider',
    component: SliderView,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 1,
    icon: 'icons i-slider-gray',
    iconActive: 'icons i-slider-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'Slider',
        isDisabled: false,
        route: '/home/Slider',
      },
    ],
  },
  {
    id: 4,
    path: '/PageContent',
    name: 'SideMenuView.MobilePages.PageContent',
    component: PageContentView,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 1,
    icon: 'mdi mdi-page-previous-outline',
    iconActive: 'mdi mdi-page-previous c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'PageContent',
        isDisabled: false,
        route: '/home/PageContent',
      },
    ],
  },
  {
    id: 2,
    path: '/Parking',
    name: 'SideMenuView.MobilePages.Parking',
    component: ParkingView,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 3,
    order: 1,
    icon: 'mdi mdi-car-brake-parking',
    iconActive: 'mdi mdi-car-brake-parking c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'Parking',
        isDisabled: false,
        route: '/home/Parking',
      },
    ],
  },
  {
    id: 3,
    path: '/Cars',
    name: 'SideMenuView.MobilePages.Cars',
    component: CarsView,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 3,
    order: 2,
    icon: 'mdi mdi-car-hatchback',
    iconActive: 'mdi mdi-car-hatchback c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'Cars',
        isDisabled: false,
        route: '/home/Cars',
      },
    ],
  },
  {
    id: 4,
    path: '/trips',
    name: 'home.trips.tripsView.location',
    component: TripsLayout,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 5,
    // order: 4,
    icon: 'mdi mdi-airplane',
    iconActive: 'mdi mdi-airplane c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'home.trips.tripsView.location',
        isDisabled: false,
        route: '/home/trips',
      },
    ],
  },
  {
    id: 6,
    path: '/tours',
    name: 'home.tours.toursView.tours',
    component: ToursLayout,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 5,
    // order: 4,
    icon: 'mdi mdi-van-passenger',
    iconActive: 'mdi mdi-van-passenger c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'home.tours.toursView.tours',
        isDisabled: false,
        route: '/home/tours',
      },
    ],
  },
  {
    id: 6,
    path: '/offered-tours',
    name: 'home.tours.offeredToursView.offered-tours',
    component: OffersLayout,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 5,
    // order: 4,
    icon: 'mdi mdi-hand-heart-outline',
    iconActive: 'mdi mdi-hand-heart-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'home.tours.offeredToursView.offered-tours',
        isDisabled: false,
        route: '/home/offered-tours',
      },
    ],
  },
  {
    id: 16,
    path: '/view',
    name: 'home.tours.pendingToursView.Upcoming-tours',
    component: PendingToursView,
    layout: '/home/UpcomingTours',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 5,
    // order: 4,
    icon: 'mdi mdi-map-clock',
    iconActive: 'mdi mdi-map-clock c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'home.tours.pendingToursView.Upcoming-tours',
        isDisabled: false,
        route: '/home/pending-tours/view',
      },
    ],
  },
  {
    id: 5,
    path: '/AdsView',
    name: 'SideMenuView.MobilePages.Advertisements',
    component: AdsView,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 1,
    icon: 'mdi mdi-align-horizontal-left',
    iconActive: 'mdi mdi-align-horizontal-left c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'Ads',
        isDisabled: false,
        route: '/home/AdsView',
      },
    ],
  },
  {
    id: 17,
    path: '/FormBuilder',
    name: 'SideMenuView.FormBuilder',
    component: FormBuilderLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 10,
    order: 1,
    icon: 'mdi mdi-desktop-mac-dashboard',
    iconActive: 'mdi mdi-desktop-mac-dashboard',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    Routes: FormBuilderRoutes,
    breadcrumbs: [
      {
        name: 'SideMenuView.FormBuilder',
        isDisabled: false,
        route: '/home/FormBuilder/Form',
      },
    ],
  },
  {
    id: 6,
    path: '/add',
    name: 'Administration.Users.UsersManagementView.add-new-user',
    component: UserManagementView,
    layout: '/home/Users',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Users',
        isDisabled: false,
        route: '/home/Users',
      },
      {
        name: 'Administration.Users.UsersManagementView.add-new-user',
        isDisabled: false,
        route: '/home/Users/add',
      },
    ],
  },
  {
    id: 6,
    path: '/edit',
    name: 'Administration.Users.UsersManagementView.edit-user',
    component: UserManagementView,
    layout: '/home/Users',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Users',
        isDisabled: false,
        route: '/home/Users',
      },
      {
        name: 'Administration.Users.UsersManagementView.edit-user',
        isDisabled: false,
        route: '/home/Users/edit',
      },
    ],
  },
  {
    id: 6,
    path: '/Users',
    name: 'SideMenuView.Administration.Users',
    component: UserView,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 1,
    icon: 'mdi mdi-badge-account-outline',
    iconActive: 'mdi mdi-badge-account',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Users',
        isDisabled: false,
        route: '/home/Users',
      },
    ],
  },
  {
    id: 7,
    path: '/Roles',
    name: 'SideMenuView.Administration.Roles',
    component: RolesLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'mdi mdi-checkbox-multiple-marked',
    iconActive: 'mdi mdi-checkbox-multiple-marked c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    Routes: RolesRoutes,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Roles',
        isDisabled: false,
        route: '/home/Roles/View',
      },
    ],
  },
  {
    id: 8,
    path: '/ErrorPage',
    name: 'ErrorPage',
    component: PageErrorLayout,
    layout: '/ErrorPage',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 0,
    order: 1,
    icon: '',
    iconActive: '',
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [],
  },
  {
    id: 10,
    path: '/Tracking',
    name: 'SideMenuView.tracking',
    component: TrackingView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 5,
    order: 5,
    icon: 'mdi mdi-map-clock-outline',
    iconActive: 'mdi mdi-map-clock-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SideMenuView.tracking',
        isDisabled: false,
        route: '/home/Tracking',
      },
    ],
  },
  {
    id: 9,
    path: '/Agents',
    name: 'SideMenuView.Agents',
    component: AgentsView,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 9,
    order: 1,
    icon: 'mdi mdi-briefcase-variant-outline',
    iconActive: 'mdi mdi-briefcase-variant-outline',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SideMenuView.Agents',
        isDisabled: false,
        route: '/home/Agents',
      },
    ],
  },
  {
    id: 10,
    path: '/Drivers',
    name: 'SideMenuView.Drivers',
    component: DriversView,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 9,
    order: 1,
    icon: 'mdi mdi-steering',
    iconActive: 'mdi mdi-steering',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SideMenuView.Drivers',
        isDisabled: false,
        route: '/home/Drivers',
      },
    ],
  },

  {
    id: 20,
    path: '/lookups',
    name: 'SideMenuView.Lookups.Lookup',
    component: LookupsLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 4,
    icon: 'mdi mdi-format-list-bulleted',
    iconActive: 'mdi mdi-format-list-bulleted c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    Routes: LookupsRoutes,
    breadcrumbs: [
      {
        name: 'SideMenuView.Lookups.Lookup',
        isDisabled: false,
        route: '/home/lookups',
      },
    ],
  },
  {
    id: 21,
    path: '/ContactSupport',
    name: 'SideMenuView.MobilePages.ContactSupport',
    component: ContactSupportView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 11,
    order: 1,
    icon: 'mdi mdi-face-agent',
    iconActive: 'mdi mdi-face-agent c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SideMenuView.MobilePages.ContactSupport',
        isDisabled: true,
        route: '/home/ContactSupportView',
      },
    ],
  },
  {
    id: 31,
    path: '/PromoCode',
    name: 'PromoCode.PromoCode',
    component: PromoCodeLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 12,
    order: 1,
    icon: 'mdi mdi-code-equal',
    iconActive: 'mdi mdi-code-equal c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    Routes: PromoCodeRoutes,
    breadcrumbs: [
      {
        name: 'PromoCode.PromoCode',
        isDisabled: true,
        route: '/home/PromoCode',
      },
    ],
  },
  {
    id: 32,
    path: '/car-activities',
    name: 'home.mobilePages.carActivitiesView.car-activities',
    component: MobilePagesLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 3,
    order: 5,
    icon: 'mdi mdi-car-settings',
    iconActive: 'mdi mdi-car-settings c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'home.mobilePages.carActivitiesView.car-activities',
        isDisabled: false,
        route: '/home/car-activities',
      },
    ],
  },
  {
    id: 33,
    path: '/Invoice',
    name: 'SideMenuView.MobilePages.Invoice',
    component: InvoiceView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 12,
    order: 2,
    icon: 'mdi mdi-receipt',
    iconActive: 'mdi mdi-receipt c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SideMenuView.MobilePages.Invoice',
        isDisabled: true,
        route: '/home/Invoice',
      },
    ],
  },
  {
    id: 34,
    path: '/ReportBasketView',
    name: 'ReportBasketView.ReportBasketView',
    component: ReportBasketView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],

    groupId: 5,
    order: 4,
    icon: 'mdi mdi-basket-fill',
    iconActive: 'mdi mdi-basket-fill c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'ReportBasketView.ReportBasketView',
        isDisabled: false,
        route: '/home/ReportBasketView',
      },
    ],
  },
  {
    id: 36,
    path: '/sections',
    name: 'SectionView.sections',
    component: SectionsLayout,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 5,
    icon: 'mdi mdi-monitor-cellphone',
    iconActive: 'mdi mdi-monitor-cellphone c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SectionView.sections',
        isDisabled: false,
        route: '/home/sections',
      },
    ],
  },
  {
    id: 35,
    path: '',
    name: 'dashboard.dashboard',
    component: DashboardView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 1,
    order: 1,
    icon: 'mdi mdi-view-dashboard-outline',
    iconActive: 'mdi mdi-view-dashboard',
    isDisabled: true,
    showInMenu: true,
    isExact: false,
    dontShow: true,
  },
];
