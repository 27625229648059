export const MobileDesignTypeEnum = [
  {
    key: 'Shifted Cards',
    value: 1,
  },
  {
    key: 'Points Cards',
    value: 2,
  },
  {
    key: 'Rectangle Cards',
    value: 3,
  },
];
