import React from 'react';
import {TotalTours} from './TotalTours'
import {TotalDrivers} from './TotalDrivers'
import {TotalUsers} from './TotalUsers'
import {TotalCars} from './TotalCars';
import Grid from '@material-ui/core/Grid';


export const TotalChartViewComponent=()=> {
    

    return (
        <div className="TotalSection">
        <Grid container spacing={2} >

        <Grid  item xs={12} sm={8} md={6} lg={3}>
        <TotalTours/>
      </Grid>

      <Grid  item xs={12} sm={8} md={6} lg={3}>
     <TotalDrivers/>
    </Grid>

    <Grid  item xs={12} sm={8} md={6} lg={3}>
   <TotalCars/>
  </Grid>

  <Grid  item xs={12} sm={8} md={6} lg={3}>
  <TotalUsers/>
</Grid>

</Grid>
</div>
    )
}
