import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { IconButton } from '@material-ui/core';
import {
  WebsiteRectangleBestTours,
  PointsToursView,
  ShiftedCardsView,
  MobilePointsToursView,
  MobileRectangleCardView,
} from './PreviewDialogComponents';
import './SectionViewPreviewDialog.scss';
const translationPath = 'SectionView.';

export const SectionViewPreviewDialog = ({
  openPreview,
  handleClose,
  isMobile,
  isWebsite,
  tours,
  activeItem,
}) => {
  return (
    <div className='section-view-preview-wrapper'>
      <Dialog className='preview-dialog' onClose={handleClose} open={openPreview}>
        {isWebsite && (
          <div className='preview-dialog-website'>
            {activeItem.webDesign === 1 ? (
              <PointsToursView translationPath={translationPath} tours={tours} />
            ) : (
              <WebsiteRectangleBestTours translationPath={translationPath} tours={tours} />
            )}
          </div>
        )}
        {isMobile && (
          <div className='preview-dialog-mobile'>
            {activeItem.mobileDesign === 1 ? (
              <ShiftedCardsView translationPath={translationPath} tours={tours} />
            ) : activeItem.mobileDesign === 2 ? (
              <MobilePointsToursView translationPath={translationPath} tours={tours} />
            ) : (
              <MobileRectangleCardView translationPath={translationPath} tours={tours} />
            )}
          </div>
        )}
        <div className='dialog-close-button'>
          <IconButton onClick={handleClose}>
            <div className='mdi mdi-close' />
          </IconButton>
        </div>
      </Dialog>
    </div>
  );
};
