export const PublishTypeEnum = [
  {
    key: 'select',
    value: null,
  },
  {
    key: 'Web',
    value: 1,
  },
  {
    key: 'Mobile',
    value: 2,
  },
  {
    key: 'Both',
    value: 3,
  },
];
export const PublishTypeEnumvalue = {
  select: {
    key: 'select',
    value: null,
  },
  Web: {
    key: 'Web',
    value: 1,
  },
  Mobile: {
    key: 'Mobile',
    value: 2,
  },
  Both: {
    key: 'Both',
    value: 3,
  },
};
