import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import { getDownloadableLink, getTranslate } from '../../../../../Helpers';
import { GetAllBasketTourByUserId } from '../../../../../Serviecs';
import { LoadableImageComponant, Spinner } from '../../../../../Componentes';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import './AgentsDetailsComponent.scss';
import { LoadableImageEnum } from '../../../../../Enums';

export const AgentsDetailsComponent = ({
  activeItem,
  filter,
  onDeleteClicked,
  translationPath,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [basketInfo, setBasketInfo] = useState();

  const getAllBasketTours = useCallback(async () => {
    setIsLoading(true);
    const response = await GetAllBasketTourByUserId(
      activeItem.userId,
      filter.basketTourStartDate,
      filter.basketTourStartEnd
    );
    setBasketInfo(response);

    setIsLoading(false);
  }, [activeItem.userId, filter.basketTourStartDate, filter.basketTourStartEnd]);

  useEffect(() => {
    getAllBasketTours();
  }, [getAllBasketTours]);

  return (
    <div className='agents-details-wrapper'>
      <Spinner isActive={isLoading} />
      {basketInfo && (
        <>
          <div className='d-flex-v-center-h-between'>
            <span className='fz-16px texts-truncate'>{activeItem.fullName}</span>
            <span className='fz-14px texts-truncate'>( {activeItem.userName} )</span>
          </div>
          <div className='w-100 mb-3'></div>
          <div className='w-100'>
            {basketInfo &&
              basketInfo.map((item) => (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {item.tourName}
                      </Grid>
                      <Grid item xs={6} className='tour-address'>
                        <span className='mdi mdi-map-marker' />
                        <Tooltip title={item.tourAbout}>
                          <p>{item.tourAbout}</p>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6}>
                        <span className='mdi mdi-clock-time-five' />
                        {item.tourDurationFormat}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Timeline
                      className='timeline-wrapper'
                      align={
                        localStorage.getItem('localization') &&
                        localStorage.getItem('localization').isRtl
                          ? 'right'
                          : 'left'
                      }>
                      {item.tourSummaryTrips &&
                        item.tourSummaryTrips.map((el) => (
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineDot />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <Typography>{el.tripName || 'N/A'}</Typography>
                              <div className='basket-info-container'>
                                <div className='item-wrapper'>
                                  <span className='item-header'>
                                    <span className='mdi mdi-clock-time-five' />
                                    <span>
                                      {getTranslate()(`${translationPath}Duration`)}&nbsp;:&nbsp;
                                    </span>
                                  </span>
                                  <span className='item-body'>
                                    {el.tripDuration +
                                      '  ' +
                                      getTranslate()(`${translationPath}minutes`) || 'N/A'}
                                  </span>
                                </div>
                                <div className='item-wrapper mb-3'>
                                  <span className='item-header'>
                                    <span className='mdi mdi-map-marker' />
                                    <span>{getTranslate()(`${translationPath}address`)}</span>
                                  </span>
                                  <Tooltip title={el.address}>
                                    <span>{el.address || 'N/A'}</span>
                                  </Tooltip>
                                </div>
                              </div>
                              <LoadableImageComponant
                                classes='item-wrapper trip-cover-image'
                                type={LoadableImageEnum.div.key}
                                alt={getTranslate()(`${translationPath}trip-cover-image`)}
                                src={
                                  (el.tripCoverImage && getDownloadableLink(el.tripCoverImage)) ||
                                  el.buildings.defaultImg
                                }
                              />
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                    </Timeline>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
          <div className='basket-divider'>
            <Divider />
          </div>
        </>
      )}
    </div>
  );
};

AgentsDetailsComponent.propTypes = {
  activeItem: PropTypes.instanceOf(Object).isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
};
