import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  getTranslate,
  showSuccess,
  showError,
  getHistory,
  getErrorByName,
} from '../../../../Helpers';
import config from '../../../../Assets/Configration/Configration';
import { LoginActions, RememberMeAction } from '../../../../Stores/Actions';
import { bindActionCreators } from 'redux';
import Joi from 'joi';
import './LoginView.scss';
import { Inputs } from '../../../../Componentes';
import { useTitle } from '../../../../Hooks/useTitle';

const translationPath = 'login.';

const LoginView = ({
  loginResponse,
  setLoginAction,
  RememberMeReducer,
  ResetRememberMeAction,
  RememberMeAction,
}) => {
  useTitle(getTranslate()(`${translationPath}login`));
  const [loginDto, setLoginDto] = useState({
    identity: '',
    password: '',
    rememberMe: false,
    organizationId: config.organizationsId,
    applicationId: config.applicationId,
  });
  const [preventViewError, setPreventViewError] = useState(false);
  const [animationStartClasses, setAnimationStartClasses] = useState('');
  const [animationStart, setAnimationStart] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const schema = Joi.object({
    identity: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}identity-is-required`),
      }),
    password: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}password-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate(loginDto);

  const validationHandler = () => {
    if (!loginDto.password || !loginDto.identity) return false;
    if (!loginDto.password) return false;
    return true;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validationHandler()) {
      if (preventViewError);
      setPreventViewError(false);
      setLoginAction(loginDto);
      setIsSubmitted(true);
    } else {
      if (schema.error) {
        showError(getTranslate()('shared.please-fix-all-errors'));
        return;
      }
    }
  };
  const loginResponseHandler = useCallback(() => {
    if (loginResponse && !animationStart) {
      if (loginResponse.token) {
        localStorage.setItem('session', JSON.stringify(loginResponse));
        showSuccess(getTranslate()('loginView.login-successfully'));
        if (loginDto.rememberMe) {
          localStorage.setItem('RemeberMe', JSON.stringify(loginDto));
          RememberMeAction(loginDto);
        }
        setAnimationStartClasses(' in-animate');
        setAnimationStart(true);
        setTimeout(() => {
          getHistory().push('/home');
        }, 305);
      } else if (!preventViewError) {
        setPreventViewError(true);
        showError(getTranslate()('shared.please-fix-all-errors'));
      }
    }
  }, [RememberMeAction, animationStart, loginDto, loginResponse, preventViewError]);
  useEffect(() => {
    loginResponseHandler();
  }, [loginResponse, loginResponseHandler]);

  useEffect(() => {
    if (RememberMeReducer.rememberMe) {
      setLoginDto({ ...RememberMeReducer });
    }
  }, [RememberMeReducer]);
  const controlsHandler = useCallback(
    (input, process) => (event) => {
      if (input === 'rememberMe' && event.target[process] === false) {
        localStorage.setItem('RemeberMe', '{}');
        ResetRememberMeAction({});
      }
      setLoginDto({ ...loginDto, [input]: event.target[process] });
    },
    [setLoginDto, loginDto, ResetRememberMeAction]
  );

  return (
    <form noValidate className='form-wrapper' onSubmit={handleSubmit}>
      <Inputs
        helperText={getErrorByName(schema, 'identity').message}
        error={getErrorByName(schema, 'identity').error}
        isWithError
        idRef='identity'
        isSubmitted={isSubmitted}
        fieldClasses='inputs theme-underline mb-2'
        label={getTranslate()(`${translationPath}identity`)}
        inputPlaceholder={getTranslate()(`${translationPath}ex-desc`)}
        name='identity'
        value={loginDto.identity}
        onInputChanged={controlsHandler('identity', 'value')}
      />
      <Inputs
        helperText={getErrorByName(schema, 'password').message}
        error={getErrorByName(schema, 'password').error}
        isWithError
        idRef='password'
        isSubmitted={isSubmitted}
        fieldClasses='inputs theme-underline mb-2'
        type='password'
        label={getTranslate()(`${translationPath}password`)}
        name='password'
        value={loginDto.password}
        onInputChanged={controlsHandler('password', 'value')}
      />
      <div className='d-flex-v-center-h-between mb-3'>
        <FormControlLabel
          className='form-control-label'
          control={
            <Checkbox
              className='checkbox-wrapper'
              checkedIcon={<span className='mdi mdi-check' />}
              indeterminateIcon={<span className='mdi mdi-minus' />}
              checked={loginDto.rememberMe}
              value={loginDto.rememberMe}
              onChange={controlsHandler('rememberMe', 'checked')}
            />
          }
          label={getTranslate()(`${translationPath}remember-me`)}
        />
        <Link className='links' to='/account/IdentityVerificationView'>
          {getTranslate()(`${translationPath}forgot-password`)}
        </Link>
      </div>
      <div className='d-flex-v-center-h-end'>
        <div className={`animated-btn-wrapper${animationStartClasses}`}>
          <Button className='btns theme-solid bg-primary' type='submit'>
            <span>{getTranslate()(`${translationPath}start`)}</span>
          </Button>
          <span className='mdi mdi-chevron-double-right animated-icon' />
        </div>
      </div>
    </form>
  );
};
const mapStateToProps = (state) => {
  const {
    LoginReducer: { loginResponse },
    RememberMeReducer,
  } = state;
  return {
    loginResponse,
    RememberMeReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setLoginAction: bindActionCreators(LoginActions.login, dispatch),
    RememberMeAction: bindActionCreators(RememberMeAction.StoreRememberMe, dispatch),
    ResetRememberMeAction: bindActionCreators(RememberMeAction.ResetRememberMe, dispatch),
  };
}
LoginView.propTypes = {
  loginResponse: PropTypes.shape(undefined),
};
LoginView.defaultProps = {
  loginResponse: null,
};
const store = connect(mapStateToProps, mapDispatchToProps)(LoginView);

export { store as LoginView };
