import React, { useState, useCallback } from 'react';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';
import { Tables, NoSearchResultComponent } from '../../../../Componentes';
import { TableActions } from '../../../../Enums';
import { AdsDeleteDialog } from '../AdsViewUtilities/AdsViewDialogs/AdsDeleteDialog';
import { AdsViewSlide } from '../AdsViewUtilities/AdsViewSlide';
import { getTranslate } from '../../../../Helpers';

const AdsListView = (props) => {
  const [deleteDialog, setDeleteDialog] = useState(false);

  const translationPath = 'Advertisements.';

  const handlePageChange = async (e, newPage) => {
    props.setPage(newPage);
    props.reloadData(newPage + 1, props.rowsPerPage);
  };
  const handlePageRowChange = async (e) => {
    props.setRowsPerPage(parseInt(e.target.value, 10));
    props.setPage(0);
    props.reloadData(1, parseInt(e.target.value, 10));
  };

  const HandleSliderSidePanel = useCallback(
    (item) => {
      sideMenuComponentUpdate(
        <AdsViewSlide
          item={item}
          reloadData={props.reloadData}
          setIsEdit={props.setIsEdit}
          setAdsInfo={props.setAdsInfo}
          setAdsDialog={props.setAdsDialog}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [props.reloadData, props.setAdsDialog, props.setAdsInfo, props.setIsEdit]
  );

  const focusedRowChanged = (activeRow) => {
    const item = props.response.result[activeRow];
    if (!item) return;
    HandleSliderSidePanel(item);
  };

  return (
    <div className='mx-3'>
      {props.response &&
      props.response.totalCount === 0 &&
      props.searchedItem.length !== 0 &&
      !props.isFirstLoad ? (
        <NoSearchResultComponent />
      ) : (
        props.response && (
          <Tables
            headerData={[
              {
                id: 1,
                isSortable: true,
                label: `${getTranslate()(`${translationPath}ID`)}`,
                input: 'advertisementId',
                isDate: false,
              },
              {
                id: 2,
                isSortable: true,
                label: `${getTranslate()(`${translationPath}AdName`)}`,
                input: 'advertisementName',
                isDate: false,
              },
              {
                id: 3,
                isSortable: true,
                label: `${getTranslate()(`${translationPath}AdType`)}`,
                input: 'advertisementType',
                isDate: false,
              },
              {
                id: 4,
                isSortable: true,
                label: `${getTranslate()(`${translationPath}FromDate`)}`,
                input: 'advertisementFromtDate',
                isDate: true,
              },
              {
                id: 5,
                isSortable: true,
                label: `${getTranslate()(`${translationPath}ToDate`)}`,
                input: 'advertisementToDate',
                isDate: true,
              },
            ]}
            data={
              props.response && props.response.result && Array.isArray(props.response.result)
                ? props.response.result
                : []
            }
            activePage={props.page}
            totalItems={props.response && props.response.totalCount ? props.response.totalCount : 0}
            activePageChanged={handlePageChange}
            itemsPerPage={props.rowsPerPage}
            itemsPerPageChanged={handlePageRowChange}
            focusedRowChanged={focusedRowChanged}
            actionsOptions={{
              actions: [
                {
                  enum: TableActions.edit.key,
                  isDiabled: false,
                  externalComponent: null,
                },
                {
                  enum: TableActions.delete.key,
                  isDiabled: false,
                  externalComponent: null,
                },
              ],
              classes: '',
              isDisabled: false,
              onActionClicked: (key, item) => {
                if (key === 'delete') {
                  props.setAdsInfo(item);
                  setDeleteDialog(true);
                } else if (key === 'edit') {
                  props.setAdsInfo(item);
                  props.setIsEdit(true);
                  props.setAdsDialog(true);
                }
              },
            }}
          />
        )
      )}

      <AdsDeleteDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        item={props.adsInfo}
        reloadData={props.reloadData}
      />
    </div>
  );
};

export { AdsListView };
