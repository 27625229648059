export const TableActions = {
  add: {
    key: 'add', // must be unique
    icon: 'table-action-icon mdi mdi-plus',
    buttonClasses: 'table-action-btn btns-icon theme-solid',
  },
  edit: {
    key: 'edit', // must be unique
    icon: 'table-action-icon  mdi mdi-file-document-edit-outline',
    buttonClasses: 'table-action-btn  btns-icon theme-solid',
  },
  view: {
    key: 'view', // must be unique
    icon: 'table-action-icon mdi mdi-eye-outline',
    buttonClasses: 'table-action-btn btns-icon theme-solid',
  },
  delete: {
    key: 'delete', // must be unique
    icon: 'table-action-icon mdi mdi-trash-can-outline',
    buttonClasses: 'table-action-btn btns-icon theme-solid',
  },
  check: {
    key: 'check',
    icon: 'table-action-icon mdi mdi-check ',
    buttonClasses: 'table-action-btn btns-icon theme-solid',
  },

  close: {
    key: 'close',
    icon: 'table-action-icon mdi mdi-close',
    buttonClasses: 'table-action-btn btns-icon theme-solid',
  },
  phone: {
    key: 'phone',
    icon: 'table-action-icon mdi mdi-phone',
    buttonClasses: 'table-action-btn btns-icon theme-action active',
  },
  email: {
    key: 'email',
    icon: 'table-action-icon icons i-email',
    buttonClasses: 'table-action-btn btns-icon theme-action active',
  },
  whatsapp: {
    key: 'whatsapp',
    icon: 'table-action-icon mdi mdi-whatsapp',
    buttonClasses: 'table-action-btn btns-icon theme-action active',
  },
  account: {
    key: 'account',
    icon: 'table-action-icon icons i-account',
    buttonClasses: 'table-action-btn btns-icon theme-action active',
  },

  // buttonWithText: {
  //   key: 'buttonWithText',
  //   buttonClasses: 'btns',
  // },
  // textOnly: {
  //   key: 'textOnly',
  // },
  externalComponent: {
    key: 'externalComponent',
  },
};
