import React, { useCallback } from 'react';
import { Tables, NoSearchResultComponent } from '../../../../../Componentes';
import { TableActions } from '../../../../../Enums';
import { CarsSlider } from './../CarsSlider/CarsSlider';
import { getTranslate } from ' ./../../src/Helpers';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';

const CarsList = (props) => {
  const handlePageChange = async (e, newPage) => {
    props.setPage(newPage);
    props.reloadData(newPage + 1, props.rowsPerPage);
  };
  const handlePageRowChange = async (e) => {
    props.setRowsPerPage(parseInt(e.target.value, 10));
    props.setPage(0);
    props.reloadData(1, parseInt(e.target.value, 10));
  };

  const openEditDialogslider = useCallback(() => {
    props.reloadData();
  }, [props]);

  const openDleteDialogslider = useCallback(() => {
    props.openDialogDelete();
  }, [props]);

  const CarsSliderClicked = useCallback(
    (item) => {
      sideMenuComponentUpdate(
        <CarsSlider
          setSearch={item}
          openEditDialogslider={openEditDialogslider}
          openDleteDialogslider={openDleteDialogslider}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [openDleteDialogslider, openEditDialogslider]
  );

  const focusedRowChanged = (activeRow) => {
    const item = props.Response.result[activeRow];
    if (!item) return;
    CarsSliderClicked(item);
    props.setcarsid(item.carTypesId);
    props.setstatecar(item.status);
    props.setCarNAME(item.typeName)
  };
  const translationPath = 'Cars.CarsList.';
  return (
    <div className='mx-3'>
      {props.Response &&
      props.Response.totalCount === 0 &&
      props.search.length !== 0 &&
      !props.isFirstLoad ? (
        <NoSearchResultComponent />
      ) : (
        <>
          {props.Response && props.Response.totalCount !== 0 && (
            <Tables
              headerData={[
                {
                  id: 1,
                  isSortable: true,
                  label: getTranslate()(`${translationPath}typeName`),
                  input: 'typeName',
                  isDate: false,
                },
                {
                  id: 2,
                  isSortable: true,
                  label: getTranslate()(`${translationPath}noOfSets`),
                  input: 'noOfSets',
                  isDate: false,
                },
                {
                  id: 3,
                  isSortable: true,
                  label: getTranslate()(`${translationPath}CarModel`),
                  input: 'carModel',
                  isDate: false,
                },
                {
                  id: 4,
                  isSortable: true,
                  label: getTranslate()(`${translationPath}distence1KPrice`),
                  input: 'distence1KPrice',
                  isDate: false,
                },
                {
                  id: 5,
                  isSortable: true,
                  label: getTranslate()(`${translationPath}noOfCars`),
                  input: 'noOfCars',
                  isDate: false,
                },
                {
                  id: 5,
                  isSortable: true,
                  label: getTranslate()(`${translationPath}status`),
                  input: 'status',
                  isDate: false,
                },
              ]}
              data={
                props.Response && props.Response.result && Array.isArray(props.Response.result)
                  ? props.Response.result
                  : []
              }
              activePage={props.page}
              totalItems={
                props.Response && props.Response.totalCount ? props.Response.totalCount : 0
              }
              activePageChanged={handlePageChange}
              itemsPerPage={props.rowsPerPage}
              focusedRowChanged={focusedRowChanged}
              itemsPerPageChanged={handlePageRowChange}
              actionsOptions={{
                actions: [
                  {
                    enum: TableActions.edit.key,
                    isDiabled: false,
                    externalComponent: null,
                  },
                  {
                    enum: TableActions.delete.key,
                    isDiabled: false,
                    externalComponent: null,
                  },
                  {
                    enum: TableActions.add.key,
                    isDiabled: false,
                    externalComponent: null,
                  },
                ],
                classes: '',
                isDisabled: false,
                onActionClicked: (key, item, index) => {
                  if (key === 'delete') {
                    props.openDialogDelete();
                    props.setcarsid(item.carTypesId);
                  } else if (key === 'edit') {
                    props.setIsEdit(true);
                    props.setCarDetails(item);
                    props.setstatecar(item.status);
                    props.setDialogAddCar(true);
                  } else if (key === 'add') {
                    props.setIsEdit(false);
                    props.openDialogAddEditCar();
                    props.setcarsid(item.carTypesId);
                  }
                },
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
export default CarsList;
