import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TimeRangePickerComponent,
  DateRangePickerComponent,
  DialogComponent,
  Inputs,
  SelectComponet,
} from '../../../../../../../../Componentes';
import {
  getErrorByName,
  getTranslate,
  showError,
  DateTimeIntersected,
  showWarn,
} from '../../../../../../../../Helpers';
import Joi from 'joi';
import moment from 'moment';
import { Button, FormControlLabel, Switch } from '@material-ui/core';
import './TimeManagementDialog.scss';
import { TimeConfirmPopover } from './TimeConfirmPopover/TimeConfirmPopover';
export const TimeManagementDialog = ({
  activeTimeItem,
  tripDates, // use it to identify not available dates (disabledDated)
  activeItemIndex, // use it to skip current item when identity not available dates
  onDataSave,
  isOpen,
  isOpenChanged,
  translationPath,
  weekDays,
  isView,
}) => {
  const [saveDisable, setSaveDisable] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const timeDefault = {
    tripDateRange: {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
    isRepeated: false,
    tripTimes: [],
  };

  const reducer = useCallback((state, action) => {
    if (action.parentId) {
      if (action.id === 'splice')
        if (action.childId)
          state[action.parentId][action.parentIndex][action.childId].splice(
            action.childIndex,
            0,
            action.value
          );
        else state[action.parentId].splice(action.parentIndex, 0, { ...action.value });
      else if (action.id === 'push')
        (action.childId &&
          state[action.parentId][action.parentIndex][action.childId].push(action.value)) ||
          state[action.parentId].push(action.value);
      else if (action.id === 'remove')
        (action.childId &&
          state[action.parentId][action.parentIndex][action.childId].splice(
            action.childIndex,
            1
          )) ||
          state[action.parentId].splice(action.parentIndex, 1);
      else if (action.parentIndex || action.parentIndex === 0)
        (action.childId &&
          (state[action.parentId][action.parentIndex][action.childId][action.id] = action.value)) ||
          (state[action.parentId][action.parentIndex][action.id] = action.value);
      else if (action.id)
        (action.childId && (state[action.parentId][action.childId][action.id] = action.value)) ||
          (state[action.parentId][action.id] = action.value);
      else
        (action.childId && (state[action.parentId][action.childId] = action.value)) ||
          (state[action.parentId] = action.value);
      return { ...state };
    } else if (action.id === 'edit')
      return {
        ...action.value,
      };
    else if (action.id) return { ...state, [action.id]: action.value };
    else if (action.value)
      return {
        ...action.value,
      };
    else return { ...action };
  }, []);
  const [state, setState] = useReducer(reducer, timeDefault);
  const tripTimeDefault = {
    dayOfWeek: [],
    dateRange: {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
    timeRange: {
      startTime: null,
      endTime: null,
    },
    adultPrice: 0.0,
    childPrice: 0.0,
    infantPrice: 0.0,
  };

  const [showIsRepeated, setShowIsRepeated] = useState(false);
  const isRepeatedRef = useRef(null);
  const [attachedWith, setAttachedWith] = useState(null);

  const dateRangeValidation = (() => {
    
   const testBoolean= (tripDates)&&  tripDates.map((item) => {
        if (
          (moment(item.tripStartDate).format('YYYY-MM-DD') <=
            moment(state.tripDateRange.startDate).format('YYYY-MM-DD') &&
            moment(state.tripDateRange.startDate).format('YYYY-MM-DD') <=
              moment(item.tripEndDate).format('YYYY-MM-DD')) ||
          (moment(item.tripStartDate).format('YYYY-MM-DD') <=
            moment(state.tripDateRange.endDate).format('YYYY-MM-DD') &&
            moment(state.tripDateRange.endDate).format('YYYY-MM-DD') <=
              moment(item.tripEndDate).format('YYYY-MM-DD')) ||
          (moment(state.tripDateRange.startDate).format('YYYY-MM-DD') <
            moment(item.tripStartDate).format('YYYY-MM-DD') &&
            moment(item.tripEndDate).format('YYYY-MM-DD') <
              moment(state.tripDateRange.endDate).format('YYYY-MM-DD'))
        )return true
        return false;
      });
      const test = testBoolean.some(element => element === true)
      test && showWarn(getTranslate()(`${translationPath}intersects`))
     return test
  });
  const checkIntersectedHandler = (value, helpers) => {
    if (!value.startTime || !value.endTime) return helpers.error('state.required');
    const itemIndex = helpers.state.path.find((item) => typeof item === 'number');
    let intersectedItem = null;
    if (state.isRepeated)
      intersectedItem = state.tripTimes.find(
        (item, index) =>
          index !== itemIndex &&
          item.dayOfWeek.findIndex(
            (element) =>
              state.tripTimes[itemIndex].dayOfWeek.findIndex(
                (subElement) => subElement.lookupItemId === element.lookupItemId
              ) !== -1
          ) !== -1
      );
    else
      intersectedItem = state.tripTimes.find(
        (item, index) =>
          index !== itemIndex &&
          DateTimeIntersected(
            state.tripTimes[itemIndex].dateRange.startDate,
            state.tripTimes[itemIndex].dateRange.endDate,
            item.dateRange.startDate,
            item.dateRange.endDate,
            'day' // to ignore time
          )
      );
    if (
      intersectedItem &&
      DateTimeIntersected(
        intersectedItem.timeRange.startTime,
        intersectedItem.timeRange.endTime,
        state.tripTimes[itemIndex].timeRange.startTime,
        state.tripTimes[itemIndex].timeRange.endTime,
        'm' // to ignore seconds
      )
    )
      return helpers.error('state.intersected');
    return value;
  };
  const schema = Joi.object({
    tripDateRange: Joi.object({
      startDate: Joi.any(),
      endDate: Joi.any(),
    })
      .custom((value, helpers) => {
        if (!value || !value.startDate || !value.endDate)
          return helpers.error('state.tripDateRequired');
        return value;
      })
      .messages({
        'state.tripDateRequired': getTranslate()(`${translationPath}trip-date-is-required`),
      })
      .options({
        abortEarly: false,
        allowUnknown: true,
      }),
    isRepeated: Joi.boolean(),
    tripTimes: Joi.array()
      .items({
        dayOfWeek: Joi.array()
          .items(Joi.object())
          .custom(
            (value, helpers) =>
              (!state.isRepeated && value && value.length > 0 && helpers.error('state.invalid')) ||
              (state.isRepeated &&
                (!value || value.length === 0) &&
                helpers.error('state.empty')) ||
              value
          )
          .messages({
            'state.invalid': getTranslate()(`${translationPath}day-of-week-is-not-valid`),
            'state.empty': getTranslate()(`${translationPath}trip-days-is-required`),
          }),
        dateRange: Joi.object({
          startDate: Joi.any(),
          endDate: Joi.any(),
        })
          .custom((value, helpers) => {
            if (!state.isRepeated && (!value || !value.startDate || !value.endDate))
              return helpers.error('state.dateRequired');
            return value;
          })
          .messages({
            'state.dateRequired': getTranslate()(`${translationPath}date-range-is-required`),
          })
          .options({ allowUnknown: true }),
        timeRange: Joi.object({
          startTime: Joi.any(),
          endTime: Joi.any(),
        })
          .custom(checkIntersectedHandler)
          .messages({
            'state.required': getTranslate()(`${translationPath}time-range-is-required`),
            'state.intersected': getTranslate()(`${translationPath}time-range-is-not-available`),
          }),
        adultPrice: Joi.number(),
        childPrice: Joi.number(),
        infantPrice: Joi.number(),
      })
      .min(1)
      .options({
        abortEarly: false,
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const handleClose = useCallback(() => {
    setAttachedWith(null);
  }, []);
  const getTimeInit = (time, initTime) => {
    return (
      (time &&
        ((moment(moment(time).format(initTime)).isAfter(moment()) &&
          moment(moment(time).format(initTime)).toDate()) ||
          moment(moment(time).format('YYYY-MM-DD') + moment().format('THH:mm:ssZ')).toDate())) ||
      (moment(moment().format(initTime)).isAfter(moment()) &&
        moment(moment().format(initTime)).toDate()) ||
      moment().toDate()
    );
  };
  const getIsChangedTripTime = useCallback(
    () =>
      state.tripTimes.length > 1 ||
      (state.tripTimes.length === 1 &&
        state.tripTimes[0] &&
        Object.keys(state.tripTimes[0]).findIndex(
          (key) =>
            (typeof tripTimeDefault[key] !== 'object' &&
              tripTimeDefault[key] !== state.tripTimes[0][key]) ||
            (typeof tripTimeDefault[key] === 'object' &&
              ((key === 'dateRange' &&
                (state.tripTimes[0][key].startDate || state.tripTimes[0][key].endDate)) ||
                (key === 'timeRange' &&
                  (state.tripTimes[0][key].startTime || state.tripTimes[0][key].endTime))))
        ) !== -1),
    [state.tripTimes, tripTimeDefault]
  );
  const isRepeatedChanged = useCallback(() => {
    setState({
      value: {
        ...state,
        isRepeated: !state.isRepeated,
        tripTimes:
          (state.tripDateRange &&
            state.tripDateRange.startDate &&
            state.tripDateRange.endDate && [{ ...tripTimeDefault }]) ||
          [],
      },
    });
    if (attachedWith) setAttachedWith(null);
  }, [attachedWith, state, tripTimeDefault]);
  const isRepeatedClicked = useCallback(() => {
    if (getIsChangedTripTime()) setAttachedWith(isRepeatedRef.current);
    else isRepeatedChanged();
  }, [getIsChangedTripTime, isRepeatedChanged]);
  const removeTimeHandler = useCallback(
    (index) => () => {
      setState({ parentId: 'tripTimes', id: 'remove', parentIndex: index });
    },
    []
  );
  const addTimeHandler = useCallback(
    (item, index) => () => {
      if (state.isRepeated && item.dayOfWeek.length === 0) {
        showError(getTranslate()(`${translationPath}please-select-at-least-a-day`));
        return;
      }
      if (
        !state.isRepeated &&
        state.tripTimes.findIndex(
          (element) => !element.dateRange.startDate || !element.dateRange.endDate
        ) !== -1
      ) {
        showError(getTranslate()(`${translationPath}please-select-date-range`));
        return;
      }
      if (
        !state.isRepeated &&
        state.tripTimes.findIndex(
          (element) => !element.timeRange.startTime || !element.timeRange.endTime
        ) !== -1
      ) {
        showError(getTranslate()(`${translationPath}please-select-time-range`));
        return;
      }
      setState({
        parentId: 'tripTimes',
        id: 'splice',
        parentIndex: index + 1,
        value: tripTimeDefault,
      });
    },
    [state.isRepeated, state.tripTimes, translationPath, tripTimeDefault]
  );
  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    if (onDataSave) onDataSave(state);
  };
  const timeInit = useCallback(() => {
    setState({ value: activeTimeItem });
    setShowIsRepeated(
      activeTimeItem.tripDateRange &&
        activeTimeItem.tripDateRange.startDate &&
        activeTimeItem.tripDateRange.endDate &&
        moment(activeTimeItem.tripDateRange.endDate).diff(
          activeTimeItem.tripDateRange.startDate,
          'days'
        ) >= 7
    );
  }, [activeTimeItem]);

  useEffect(() => {
    if (activeTimeItem) timeInit();
  }, [activeTimeItem, timeInit]);

  useEffect(() => {
    if(!isView)
    setSaveDisable(dateRangeValidation)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tripDateRange.startDate, state.tripDateRange.endDate, ]);

  return (
    <DialogComponent
      titleText={
        (isView && 'trip-times') || (activeTimeItem && 'edit-trip-times') || 'add-trip-times'
      }
      saveText={'save-changes'}
      dialogContent={
        <div className='time-management-dialog'>
          <div className='trip-date-wrapper'>
            <div className='trip-date-section'>
              <DateRangePickerComponent
                ranges={[state.tripDateRange]}
                minDate={new Date()}
                emptyLabel='select-trip-start-and-end-date'
                translationPath={translationPath}
                labelValue='trip-date-range'
                helperText={getErrorByName(schema, 'tripDateRange').message}
                isSubmitted={isSubmitted}
                startDateError={getErrorByName(schema, 'tripDateRange').error}
                endDateError={getErrorByName(schema, 'tripDateRange').error}
                isDisabled={isView}
                onDateChanged={(selectedDate) => {
                  setState({
                    parentId: 'tripDateRange',
                    value: {
                      startDate: moment(
                        moment(selectedDate.selection.startDate).format('YYYY-MM-DDT12:00:00Z')
                      ).toDate(),
                      endDate: moment(
                        moment(selectedDate.selection.endDate).format('YYYY-MM-DDT12:00:00Z')
                      ).toDate(),
                      key: 'selection',
                    },
                  });
                  setShowIsRepeated(
                    selectedDate.selection &&
                      selectedDate.selection.startDate &&
                      selectedDate.selection.endDate &&
                      moment(selectedDate.selection.endDate).diff(
                        selectedDate.selection.startDate,
                        'days'
                      ) >= 7
                  );
                  if (state.tripTimes.length === 0)
                    setState({ parentId: 'tripTimes', id: 'push', value: tripTimeDefault });
                }}
              />
            </div>
            {showIsRepeated && (
              <div className='is-repeated-section'>
                <FormControlLabel
                  ref={isRepeatedRef}
                  control={<Switch checked={state.isRepeated} onClick={isRepeatedClicked} />}
                  label={getTranslate()(`${translationPath}is-repeated`)}
                  disabled={isView}
                />
                <TimeConfirmPopover
                  translationPath={translationPath}
                  isRepeatedChanged={isRepeatedChanged}
                  handleClose={handleClose}
                  attachedWith={attachedWith}
                />
              </div>
            )}
          </div>
          <div className='trip-times-wrapper'>
            {state.tripTimes &&
              state.tripTimes.map((item, index) => (
                <div key={`tripTimeKey${index + 1}`} className='trip-time-item'>
                  <div className='w-100 px-2 mb-2 fw-bold'>
                    {`${getTranslate()(`${translationPath}trip-time`)} #${index + 1}`}
                  </div>
                  <div className='sections-wrapper'>
                    {(!state.isRepeated && (
                      <div className='section'>
                        <DateRangePickerComponent
                          idRef={`dateRangePickerRef${index + 1}`}
                          popoverIdRef={`dateRangePickerPropoverRef${index + 1}`}
                          startIdRef={`dateRangePickerStartRef${index + 1}`}
                          endIdRef={`dateRangePickerEndRef${index + 1}`}
                          ranges={[item.dateRange]}
                          isDisabled={isView}
                          minDate={
                            (state.tripDateRange.startDate &&
                              moment(state.tripDateRange.startDate).toDate()) ||
                            undefined
                          }
                          maxDate={
                            (state.tripDateRange.endDate &&
                              moment(state.tripDateRange.endDate).toDate()) ||
                            undefined
                          }
                          emptyLabel='start-and-end-date'
                          translationPath={translationPath}
                          labelValue='date-range'
                          helperText={
                            getErrorByName(schema, `tripTimes.${index}.dateRange`).message
                          }
                          isSubmitted={isSubmitted}
                          startDateError={
                            getErrorByName(schema, `tripTimes.${index}.dateRange`).error
                          }
                          endDateError={
                            getErrorByName(schema, `tripTimes.${index}.dateRange`).error
                          }
                          onDateChanged={(selectedDate) => {
                            setState({
                              parentId: 'tripTimes',
                              parentIndex: index,
                              id: 'dateRange',
                              value: {
                                startDate: moment(
                                  moment(selectedDate.selection.startDate).format(
                                    'YYYY-MM-DDT12:00:00Z'
                                  )
                                ).toDate(),
                                endDate: moment(
                                  moment(selectedDate.selection.endDate).format(
                                    'YYYY-MM-DDT12:00:00Z'
                                  )
                                ).toDate(),
                                key: 'selection',
                              },
                            });
                            
                            // if (
                            //   !item.timeRange ||
                            //   !item.timeRange.startTime ||
                            //   !item.timeRange.endTime
                            // )
                            setState({
                              parentId: 'tripTimes',
                              parentIndex: index,
                              id: 'timeRange',
                              value: {
                                startTime: getTimeInit(
                                  selectedDate.selection.startDate,
                                  'YYYY-MM-DDT09:00:00Z'
                                ),
                                endTime: getTimeInit(
                                  selectedDate.selection.startDate,
                                  'YYYY-MM-DDT18:00:00Z'
                                ),
                              },
                            });
                            // }
                          }}
                        />
                      </div>
                    )) || (
                      <div className='section'>
                        <SelectComponet
                          idRef={`weekDayRef${index + 1}`}
                          data={weekDays || []}
                          // valueInput="key"
                          textInput='lookupItemName'
                          value={item.dayOfWeek}
                          multiple
                          isDisabled={isView}
                          getIsChecked={(option) =>
                            item.dayOfWeek.findIndex(
                              (element) =>element&&element.lookupItemId&&  element.lookupItemId === option.lookupItemId
                            ) !== -1
                          }
                          renderValue={(selected) =>
                            selected.map((element) =>element  &&element.lookupItemName && element.lookupItemName).join(', ')
                          }
                          onSelectChanged={(event) => {
                            setState({
                              parentId: 'tripTimes',
                              parentIndex: index,
                              id: 'dayOfWeek',
                              value: event.target.value,
                            });
                            if (
                              !item.timeRange ||
                              !item.timeRange.startTime ||
                              !item.timeRange.endTime
                            )
                              setState({
                                parentId: 'tripTimes',
                                parentIndex: index,
                                id: 'timeRange',
                                value: {
                                  startTime: getTimeInit(
                                    state.tripDateRange.startDate,
                                    'YYYY-MM-DDT09:00:00Z'
                                  ),
                                  endTime: getTimeInit(
                                    state.tripDateRange.startDate,
                                    'YYYY-MM-DDT18:00:00Z'
                                  ),
                                },
                              });
                          }}
                          labelValue='trip-days'
                          translationPath={translationPath}
                        />
                      </div>
                    )}
                    <div className='section'>
                      <TimeRangePickerComponent
                        idRef={`timeRangePickerRef${index + 1}`}
                        popoverIdRef={`timeRangePickerPropoverRef${index + 1}`}
                        startIdRef={`timeRangePickerStartRef${index + 1}`}
                        endIdRef={`timeRangePickerEndRef${index + 1}`}
                        range={item.timeRange}
                        minDate={
                          (item.dateRange &&
                            item.dateRange.startDate &&
                            moment(item.dateRange.startDate)) ||
                          moment()
                        }
                        emptyLabel='start-and-end-time'
                        translationPath={translationPath}
                        isDisabled={isView}
                        labelValue='time-range'
                        helperText={getErrorByName(schema, `tripTimes.${index}.timeRange`).message}
                        isSubmitted={isSubmitted}
                        startTimeError={
                          getErrorByName(schema, `tripTimes.${index}.timeRange`).error
                        }
                        endTimeError={getErrorByName(schema, `tripTimes.${index}.timeRange`).error}
                        rangeChanged={(range) => {
                          setState({
                            parentId: 'tripTimes',
                            parentIndex: index,
                            id: 'timeRange',
                            value: range,
                          });
                        }}
                      />
                    </div>
                    <div className='section mxw-100px'>
                      <Inputs
                        inputPlaceholder='adult-price'
                        labelValue='adult-price'
                        idRef={`adultPriceRef${index + 1}`}
                        value={item.adultPrice}
                        type='number'
                        overInputText='AED'
                        isDisabled={isView}
                        onInputChanged={(event) => {
                          const floatHandler = () => {
                            const price = event.target.value.toString().split('.');
                            if (price.length === 2 && price[1].length > 3)
                              return Number(event.target.value).toFixed(3);
                            else return Number(event.target.value);
                          };
                          setState({
                            parentId: 'tripTimes',
                            parentIndex: index,
                            id: 'adultPrice',
                            value: floatHandler(),
                          });
                        }}
                        translationPath={translationPath}
                      />
                    </div>
                    <div className='section mxw-100px'>
                      <Inputs
                        inputPlaceholder='child-price'
                        labelValue='child-price'
                        idRef={`childPriceRef${index + 1}`}
                        value={item.childPrice}
                        type='number'
                        overInputText='AED'
                        isDisabled={isView}
                        onInputChanged={(event) => {
                          const floatHandler = () => {
                            const price = event.target.value.toString().split('.');
                            if (price.length === 2 && price[1].length > 3)
                              return Number(event.target.value).toFixed(3);
                            else return Number(event.target.value);
                          };
                          setState({
                            parentId: 'tripTimes',
                            parentIndex: index,
                            id: 'childPrice',
                            value: floatHandler(),
                          });
                        }}
                        translationPath={translationPath}
                      />
                    </div>
                    <div className='section mxw-100px'>
                      <Inputs
                        inputPlaceholder='infant-price'
                        labelValue='infant-price'
                        idRef={`infantPriceRef${index + 1}`}
                        value={item.infantPrice}
                        isDisabled={isView}
                        type='number'
                        overInputText='AED'
                        onInputChanged={(event) => {
                          const floatHandler = () => {
                            const price = event.target.value.toString().split('.');
                            if (price.length === 2 && price[1].length > 3)
                              return Number(event.target.value).toFixed(3);
                            else return Number(event.target.value);
                          };
                          setState({
                            parentId: 'tripTimes',
                            parentIndex: index,
                            id: 'infantPrice',
                            value: floatHandler(),
                          });
                        }}
                        translationPath={translationPath}
                      />
                    </div>
                    {!isView && (
                      <div className='d-inline-flex-center fas-center flex-nowrap px-2 pt-2'>
                        {state.tripTimes.length > 1 && (
                          <Button
                            className='btns-icon theme-solid bg-danger mx-1'
                            onClick={removeTimeHandler(index)}>
                            <span className='mdi mdi-minus' />
                          </Button>
                        )}
                        <Button
                          className='btns-icon theme-solid mx-1'
                          onClick={addTimeHandler(item, index)}>
                          <span className='mdi mdi-plus' />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      }
      // saveClasses='btns theme-solid bg-danger w-100 mb-0 mx-0 br-0'
      isOpen={isOpen}
      onSubmit={saveHandler}
      saveIsDisabled={saveDisable||isView}
      onCloseClicked={isOpenChanged}
      onCancelClicked={isOpenChanged}
      translationPath={translationPath}
    />
  );
};

TimeManagementDialog.propTypes = {
  onDataSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  activeTimeItem: PropTypes.instanceOf(Object),
  translationPath: PropTypes.string.isRequired,
  tripDates: PropTypes.instanceOf(Array).isRequired,
  weekDays: PropTypes.instanceOf(Array),
  activeItemIndex: PropTypes.number,
  isView: PropTypes.bool,
};
TimeManagementDialog.defaultProps = {
  activeTimeItem: null,
  activeItemIndex: null,
  weekDays: undefined,
  isView: undefined,
};
