import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { TourStatusEnum } from '../../../../../Enums';
import { getTranslate } from './../../../../../Helpers';
import './AgentsDetailsComponent.scss';
import { Calendar, CollapseComponent, Spinner } from '../../../../../Componentes';
import moment from 'moment';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { GetAllBookedTourByAgent } from '../../../../../Serviecs';

export const AgentsDetailsComponent = ({ activeItem, translationPath, fromDate, toDate }) => {
  const [tours, setTours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openedTours, setOpenedTours] = useState([]);
  const [currentActiveDate, setCurrentActiveDate] = useState(moment());
  const getIsOpenTour = (item) => {
    return openedTours.includes(item.tourId);
  };
  const toggleTourHandler = useCallback(
    (item) => (event) => {
      event.preventDefault();
      event.stopPropagation();
      setOpenedTours((items) => {
        const itemIndex = items.findIndex((element) => element === item.tourId);
        if (itemIndex !== -1) items.splice(itemIndex, 1);
        else items.push(item.tourId);
        return [...items];
      });
    },
    []
  );
  const getAgentToursById = useCallback(async () => {
    setIsLoading(true);
    const response = await GetAllBookedTourByAgent(activeItem.userId, { fromDate, toDate });
    setTours(response || []);
    setIsLoading(false);
  }, [activeItem.userId, fromDate, toDate]);
  useEffect(() => {
    getAgentToursById();
  }, [activeItem, getAgentToursById]);
  return (
    <div className="agents-details-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="d-flex-v-center-h-between">
        <span className="fz-16px texts-truncate px-2">{activeItem.fullName}</span>
        {/* <Button className='btns-icon px-2' onClick={onDeleteClicked}>
          <span className='mdi mdi-trash-can-outline c-danger'></span>
        </Button> */}
      </div>
      <div className="w-100 px-2 mb-3">
        {(activeItem.isBusy && (
          <span className={`fw-bold ${TourStatusEnum.booked.color}`}>
            {getTranslate()(`${translationPath}booked`)}
          </span>
        )) || (
          <span className={`fw-bold ${TourStatusEnum.notBooked.color}`}>
            {getTranslate()(`${translationPath}not-booked`)}
          </span>
        )}
      </div>
      <div className="px-2 mb-3">
        <Calendar
          selectedDateChanged={(selectedDate) => {
            setCurrentActiveDate(selectedDate);
          }}
          selectedDate={currentActiveDate}
          withNumber
          onlyColorChanged={false}
          events={{ dates: tours.map((item) => moment(item.startTourDateTime)) }}
          wrapperClasses=" small-calender w-100"
        />
      </div>
      <p className="px-2">{getTranslate()(`${translationPath}assigned-tours`)}</p>
      <div className="tours-collapses-wrapper mb-3">
        {tours &&
          (
            (tours.filter((item) => moment(item.startTourDateTime).isSame(currentActiveDate, 'day'))
              .length > 0 &&
              tours.filter((item) =>
                moment(item.startTourDateTime).isSame(currentActiveDate, 'day')
              )) ||
            tours
          ).map((item, index) => (
            <div className="w-100" key={`bookedToursRef${index + 1}`}>
              <ButtonBase
                className="d-flex-v-center-h-between flex-wrap px-0 py-2 p-relative separator-h"
                onClick={toggleTourHandler(item)}
              >
                <div className="spacetop"/>
                <span className="fw-bold px-2 tourNm">{item.tourName}</span>
                <span
                  className={`mdi mdi-chevron-${(getIsOpenTour(item) && 'right') || 'left'} px-2`}
                />
                <div className="w-100 d-flex-v-center-h-between">
                  <div className="d-inline-flex-center">
                    <span className="mdi mdi-map-marker px-2" />
                    <span>{item.pickupLocationAddress || 'N/A'}</span>
                  </div>
                </div>
                <div className="w-100 d-flex-v-center-h-between flex-wrap">
                  <div className="d-inline-flex-center">
                    <span className="mdi mdi-calendar px-2" />
                    {(moment(item.startTourDateTime).isSame(item.endTourDateTime, 'day') && (
                      <span>{moment(item.startTourDateTime).format('DD/MM/YYYY')}</span>
                    )) || (
                      <span className="d-inline-flex-center">
                        <span>{moment(item.startTourDateTime).format('DD/MM/YYYY')}</span>
                        <span>-</span>
                        <span>{moment(item.endTourDateTime).format('DD/MM/YYYY')}</span>
                      </span>
                    )}
                  </div>
                  <span className="d-inline-flex-center">
                    <span className="mdi mdi-clock-time-five px-2" />
                    <span>{moment(item.startTourDateTime).format('hh:mm a')}</span>
                    <span>-</span>
                    <span>{moment(item.endTourDateTime).format('hh:mm a')}</span>
                  </span>
                </div>
              </ButtonBase>
              <CollapseComponent
                isOpen={getIsOpenTour(item)}
                classes=""
                component={
                  <Timeline
                    className="timeline-wrapper"
                    align={localStorage.getItem('localization').isRtl ? 'right' : 'left'}
                  >
                    {item.tour &&
                      item.tour.tourSummaryTrips &&
                      item.tour.tourSummaryTrips.map((subItem, subIndex) => (
                        <TimelineItem
                          key={`tripDetailsTimelineKey${subItem.tripId}${subIndex + 1}`}
                        >
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>

                          <TimelineContent>
                            <div className="cards-body">
                              <div className="item-wrapper">
                                <span className="item-header">
                                  <span className="mdi mdi-clock-time-five px-2" />
                                  <span>{getTranslate()(`${translationPath}Duration`)}&nbsp;:&nbsp;</span>
                                </span>
                                <span className="item-body">
                                  <span>{subItem.tripDurationFormat}</span>
                                </span>
                              </div>
                              <div className="item-wrapper">
                                <span className="item-header">
                                  <span className="mdi mdi-map-marker px-2" />
                                  <span>{getTranslate()(`${translationPath}address`)}&nbsp;:&nbsp;</span>
                                </span>
                                <span className="item-body">{subItem.address}</span>
                              </div>
                            </div>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                  </Timeline>
                }
              />
               <div className="space"/>
            </div>
          ))}
      </div>
      {/* <div className="w-100 p-3 d-flex-center separator-h">
        <ButtonBase className="btns theme-outline">
          <span>{getTranslate()(`${translationPath}edit`)}</span>
        </ButtonBase>
      </div> */}
    </div>       
  );
};

AgentsDetailsComponent.propTypes = {
  activeItem: PropTypes.instanceOf(Object).isRequired,
  fromDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]).isRequired,
  toDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]).isRequired,
  // onDeleteClicked: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
};
