import React, { useEffect } from 'react';
import { AddScreensDialog } from './AddScreensDialog';
import {
  CreateSlider,
  UpdateSlider,
} from '../../../../../Serviecs/SliderViewServices/SliderViewServices';
import {
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  FormControl,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import Joi from 'joi';
import './SliderDialogs.scss';
import { Inputs, RadiosGroupComponent, CheckboxesComponent } from './../../../../../Componentes';
import { getTranslate, getErrorByName, showError } from '../../../../../Helpers';
import Popover from '@material-ui/core/Popover';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DateRange } from 'react-date-range';
import moment from 'moment';

const AddSliderDialog = (props) => {
  const [radioValue, setRadioValue] = React.useState('1');
  const [sliderName, setSliderName] = React.useState('');
  const [isDefault, setIsDefault] = React.useState(false);
  const [sliderInfo, setSliderInfo] = React.useState({});
  const [screenDialog, setScreenDialog] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const translationPath = 'SliderView.';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dateState, setDateState] = React.useState([
    {
      startDate: moment().toDate(),
      endDate: moment().add(7, 'd').toDate(),
      key: 'selection',
    },
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const schema = Joi.object({
    sliderName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}slider-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ sliderName });

  const handleNextBtn = async () => {
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    setIsSubmitted(true);

    if (props.isEdit) {
      const info = await UpdateSlider(props.item.sliderId, {
        sliderName: sliderName,
        startDate: moment(dateState[0].startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(dateState[0].endDate).format('YYYY-MM-DD HH:mm:ss'),
        isDefault: isDefault,
        sliderStatus: +radioValue,
      });
      if (info) {
        setSliderInfo(info);
        setScreenDialog(true);
        props.setOpen(false);
      } // else showError(getTranslate()(`${translationPath}___DATE_INTERSECTION_ERROR___`));
    } else {
      const info = await CreateSlider({
        sliderName: sliderName,
        startDate: moment(dateState[0].startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(dateState[0].endDate).format('YYYY-MM-DD HH:mm:ss'),
        isDefault: isDefault,
        sliderStatus: +radioValue,
      });
      if (info) {
        setSliderInfo(info);
        setScreenDialog(true);
        props.setOpen(false);
      } //else showError(getTranslate()(`${translationPath}___DATE_INTERSECTION_ERROR___`));
    }
  };

  useEffect(() => {
    setSliderName('');
    if (props.isEdit) {
      setSliderName(props.item.sliderName);
      setRadioValue(props.item.sliderStatus + '');
      setIsDefault(props.item.isDefault);
      setDateState([
        {
          startDate: moment(props.item.startDate, 'YYYY-MM-DDTHH:mm:ss').toDate(),
          endDate: moment(props.item.endDate, 'YYYY-MM-DDTHH:mm:ss').toDate(),
          key: 'selection',
        },
      ]);
    } else {
      setDateState([
        {
          startDate: moment().toDate(),
          endDate: moment().add(7, 'd').toDate(),
          key: 'selection',
        },
      ]);
      setSliderName('');
      setRadioValue('1');
      setIsDefault(false);
    }
  }, [
    props,
    props.isEdit,
    props.item.endDate,
    props.item.isDefault,
    props.item.sliderInfo,
    props.item.sliderName,
    props.item.sliderStatus,
    props.item.startDate,
  ]);

  return (
    <>
      <Dialog open={props.open} className="sliderDialogs">
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <DialogTitle className="dialogTitle">
            <Typography>
              {props.isEdit
                ? `${getTranslate()(`${translationPath}EditSlider`)}`
                : `${getTranslate()(`${translationPath}AddNewSlider`)}`}
            </Typography>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid container justify="center">
              <Grid item xs={8} className="mb-3">
                <Inputs
                  helperText={getErrorByName(schema, 'sliderName').message}
                  error={getErrorByName(schema, 'sliderName').error}
                  isWithError
                  isSubmitted={isSubmitted}
                  idRef="Slider Name"
                  labelValue={getTranslate()(`${translationPath}SliderName`)}
                  value={sliderName}
                  inputPlaceholder={getTranslate()(`${translationPath}SliderName`)}
                  onInputChanged={(e) => setSliderName(e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <FormControl className="input-wrapper flex-wrap textField selectData">
                  <Typography>{getTranslate()(`${translationPath}Date`)}</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={
                      dateState &&
                      dateState[0] &&
                      `From: ${moment(dateState[0].startDate).format('DD/MM/YYYY')}, To: ${moment(
                        dateState[0].endDate
                      ).format('DD/MM/YYYY')}`
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <DateRangeRoundedIcon />
                        </InputAdornment>
                      ),
                    }}
                    onClick={(e) => handleClick(e)}
                  />
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <DateRange
                      months={2}
                      direction="horizontal"
                      ranges={dateState}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      onChange={(item) => setDateState([item.selection])}
                    />
                  </Popover>
                </FormControl>
              </Grid>
              <Grid item xs={8} className="mb-3">
                <FormControl className="input-wrapper flex-wrap textField activeField">
                  <RadiosGroupComponent
                    data={[
                      { value: '1', label: `${getTranslate()(`${translationPath}Active`)}` },
                      { value: '0', label: `${getTranslate()(`${translationPath}Inactive`)}` },
                    ]}
                    idRef="Actions"
                    onSelectedRadioChanged={(e) => setRadioValue(e.target.value)}
                    value={radioValue}
                    title={getTranslate()(`${translationPath}Actions`)}
                    name="Active"
                    labelInput="label"
                    valueInput="value"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} className="mb-3">
                <CheckboxesComponent
                  idRef="defaultRef"
                  checked={isDefault}
                  onSelectedCheckboxChanged={() => setIsDefault(!isDefault)}
                  label={getTranslate()(`${translationPath}Default`)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justify="space-evenly">
              <Grid item>
                <Button
                  onClick={() => {
                    props.setOpen(false);
                    setIsSubmitted(false);
                    props.setIsEdit(false);
                    if (!props.isEdit) {
                      setSliderName('');
                      setRadioValue('1');
                      setIsDefault(false);
                    }
                  }}
                  color="primary"
                  className="btns"
                >
                  {getTranslate()(`${translationPath}Cancel`)}
                </Button>
              </Grid>
              <Grid item className="nextDialog">
                <Button
                  disabled={sliderName === '' || radioValue === ''}
                  onClick={handleNextBtn}
                  variant="contained"
                  color="secondary"
                  className="btns theme-solid"
                >
                  {getTranslate()(`${translationPath}Next`)}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <AddScreensDialog
        setReloaded={props.setReloaded}
        item={props.item}
        isEdit={props.isEdit}
        reloadData={props.reloadData}
        open={screenDialog}
        openBack={props.setOpen}
        setOpen={setScreenDialog}
        sliderInfo={sliderInfo}
      />
    </>
  );
};

export { AddSliderDialog };
