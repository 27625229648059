import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';
import { getTranslate, showError } from '../../Helpers';

const GetAllRoles = async (pageIndex, pageSize, searchedItem) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Authorization/Roles/${pageIndex}/${pageSize}?search=${searchedItem}`
    );
    return result;
  } catch (e) {
    return;
  }
};
const GetAllRoleTypes = async () => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Authorization/Roles/GetAllRolesType`
    );
    return result;
  } catch (e) {
    return;
  }
};

const DeleteRoleByRolesId = async (rolesId) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/Authorization/Roles/${rolesId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const GetAllPermissionsByRoleId = async (rolesId, pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Authorization/Roles/GetAllPermissionsByRolesId/${rolesId}/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const GetAllApplicationService = async (pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PreSale/ApplicationService/GetAllApplicationService/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const GetAllComponentsByAppServiceId = async (appId, pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Authorization/Components/GetAllComponentsByApplicationServicesId/${appId}/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const GetAllPermissionsByComponentsId = async (comId, pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Authorization/Permissions/GetAllPermissionsByComponentsId/${comId}/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const GetAllAccessTypes = async (pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Authorization/AccessTypes/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const PostRole = async (body) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/Authorization/Roles`, body);
    return result;
  } catch (e) {
    showError(getTranslate()('Administration.Role.AddRole.NotificationErrorAdd'));
  }
};

const AssignPermissionsToRole = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Authorization/Roles/AssignPermissionsToRole`,
      body
    );
    return result;
  } catch (e) {
    showError(getTranslate()('Administration.Role.AddRole.NotificationErrorUpdate'));
  }
};

const RemovePermissionsFromRole = async (body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Authorization/Roles/RemovePermissionsFromRole`,
      body
    );
    return result;
  } catch (e) {
    showError(getTranslate()('Administration.Role.NotificationErrorViewRole'));
  }
};

const EditRoleName = async (roleId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Authorization/Roles/${roleId}`,
      body
    );
    return result;
  } catch (e) {
    showError(getTranslate()('Administration.Role.NotificationErrorViewRole'));
  }
};

export {
  GetAllRoles,
  GetAllRoleTypes,
  GetAllPermissionsByRoleId,
  GetAllApplicationService,
  GetAllComponentsByAppServiceId,
  GetAllPermissionsByComponentsId,
  GetAllAccessTypes,
  PostRole,
  AssignPermissionsToRole,
  RemovePermissionsFromRole,
  DeleteRoleByRolesId,
  EditRoleName,
};
