import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { getTranslate, getHistory, getErrorByName } from '../../../../Helpers';
import { GetApplicationUserForgotPasswordOptions } from '../../../../Serviecs';
import { organizationsId, applicationId } from '../../../../Assets/Configration/Configration.json';
import { Inputs } from '../../../../Componentes';
import Joi from 'joi';
import '../../LoginViews/LoginView/LoginView.scss';

const translationPath = 'login.';

const IdentityVerificationView = () => {
  const [accountIdentity, setAccountIdentity] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const schema = Joi.object({
    accountIdentity: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}identity-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ accountIdentity });

  const handleForgetPasswordSubmit = async (event) => {
    event.preventDefault();
    const options = await GetApplicationUserForgotPasswordOptions(
      accountIdentity,
      applicationId,
      organizationsId
    );
    if (options) {
      getHistory().push(
        `/account/ResetPasswordOptionsView?email=${options.email}?phone=${options.phone}?userId=${options.userId}`
      );
      setIsSubmitted(true);
    }
  };

  return (
    <>
      <div className='forget-password-text'>
        <Typography className='forget-password'>
          {getTranslate()(`${translationPath}please-enter-your-identity`)}
        </Typography>
      </div>
      <form noValidate className='form-wrapper' onSubmit={handleForgetPasswordSubmit}>
        <Inputs
          helperText={getErrorByName(schema, 'accountIdentity').message}
          error={getErrorByName(schema, 'accountIdentity').error}
          isWithError
          isSubmitted={isSubmitted}
          label={getTranslate()(`${translationPath}identity`)}
          inputPlaceholder={getTranslate()(`${translationPath}ex-desc`)}
          name='identity'
          idRef='accountIdentity'
          fieldClasses='inputs theme-underline mb-4'
          value={accountIdentity}
          onInputChanged={(e) => setAccountIdentity(e.target.value)}
        />
        <div className='d-flex-v-center-h-between'>
          <Button
            onClick={() => getHistory().push('/account/login')}
            className='btns cancel-forget-passwrod '>
            {getTranslate()(`${translationPath}cancel`)}
          </Button>
          <Button className='btns submit-forget-password' type='submit'>
            {getTranslate()(`${translationPath}submit`)}
          </Button>
        </div>
      </form>
    </>
  );
};

export { IdentityVerificationView };
