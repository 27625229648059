import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ButtonBase } from '@material-ui/core';
import { getTranslate } from ' ./../../src/Helpers';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';
import {
  Spinner,
  // ViewTypes,
  Inputs,
  OrderByComponent,
  DateRangePickerComponent,
  TimeRangePickerComponent,
} from '../../../Componentes';
import { DefaultImagesEnum } from '../../../Enums';
import { useTitle } from '../../../Hooks/useTitle';
import { AGENTS } from '../../../Assets/JSON/StaticLookupsIds.json';
import {
  NoDataFoundComponent,
  NoSearchResultComponent,
  NoContentComponent,
} from '../../../Componentes';
import { AgentsCardComponent, AgentsDetailsComponent } from './AgentsUtilities';
import { GetAllAgentBookedTour } from '../../../Serviecs';
import moment from 'moment';

const translationPath = 'DriversAndAgents.';
export const AgentsView = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [isOpenManagement, setIsOpenManagement] = useState(false);
  const filterDefault = {
    pageSize: 10,
    pageIndex: 0,
    userTypeId: AGENTS,
    search: '',
  };
  const filterByDefault = {
    // status: -1,
    sort: {
      orderBy: {
        key: 0,
        value: 'order-by',
      },
      column: {
        key: 0,
        value: 'default',
      },
    },
    dateRange: {
      startDate: moment().toDate(),
      endDate: moment().add(1, 'month').toDate(),
      key: 'selection',
    },
  };
  // const timeRangeDefault = {
  //   startTime: null,
  //   endTime: null,
  // };
  const [filter, setFilter] = useState(filterDefault);
  const [filterBy, setFilterBy] = useState(filterByDefault);
  const [agents, setAgents] = useState({ result: [], totalCount: 0 });
  const [timeRange, setTimeRange] = useState({
    startTime: filterByDefault.dateRange.startDate,
    endTime: filterByDefault.dateRange.endDate,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [, setActiveItem] = useState(null);
  // const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const searchTimer = useRef(null);

  useTitle(getTranslate()(`${translationPath}agents`));
  const getRandomDefaultImage = useCallback(
    () => DefaultImagesEnum.boys.defaultImgs[Math.floor(Math.random() * 4)],
    []
  );

  const getAllAgents = useCallback(async () => {
    setIsLoading(true);
    const response = await GetAllAgentBookedTour(filter.pageIndex, filter.pageSize, {
      orderType: filterBy.sort.orderBy.key || null,
      columnName: filterBy.sort.column.key || null,
      search: filter.search,
      fromDate: moment(filterBy.dateRange.startDate).format('YYYY-MM-DDTHH:mm:ss'),
      toDate: moment(filterBy.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss'),
    });
    if (response && response.result)
      response.result.map((item) => (item.defaultImage = getRandomDefaultImage()));
    if (filter.pageIndex === 0) {
      setIsFirstLoad(false);
      setAgents({
        result: (response && response.result) || [],
        totalCount: (response && response.totalCount) || 0,
      });
    } else {
      setAgents((item) => ({
        result: (item.result && item.result.concat((response && response.result) || [])) || [],
        totalCount: (response && response.totalCount) || 0,
      }));
    }
    setIsLoading(false);
  }, [
    filter.pageIndex,
    filter.pageSize,
    filter.search,
    filterBy.dateRange.endDate,
    filterBy.dateRange.startDate,
    filterBy.sort.column.key,
    filterBy.sort.orderBy.key,
    getRandomDefaultImage,
  ]);
  const onLoadMoreHandler = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: item.pageIndex + 1 }));
  }, []);
  const searchHandler = (e) => {
    const value = e.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((items) => ({ ...items, pageIndex: 0, search: value }));
    }, 700);
  };
  // const deleteHandler = () => {};
  // const onDeleteClicked = () => {
  //   setIsOpenConfirm(true);
  // };
  // const onActionClicked = useCallback(
  //   (actionEnum, item) => async (event) => {
  //     event.stopPropagation();
  //     // setActiveUserItem(item);
  //     if (actionEnum === ActionsEnum.delete.key) {
  //       setActiveItem(item);
  //       setIsOpenConfirm(true);
  //     }
  //   },
  //   []
  // );
  const onCardClicked = (item) => {
    setActiveItem(item);
    sideMenuComponentUpdate(
      <AgentsDetailsComponent
        activeItem={item}
        fromDate={filterBy.dateRange.startDate || ''}
        toDate={filterBy.dateRange.endDate || ''}
        // onDeleteClicked={onDeleteClicked}
        translationPath={translationPath}
      />
    );
    sideMenuIsOpenUpdate(true);
  };
  const getIsChangedFilter = () => {
    return (
      !moment(filterBy['dateRange']['startDate']).isSame(
        filterByDefault['dateRange']['startDate'],
        'minute'
      ) ||
      !moment(filterBy['dateRange']['endDate']).isSame(
        filterByDefault['dateRange']['endDate'],
        'minute'
      ) ||
      Object.keys(filterByDefault['sort']['column']).findIndex(
        (key) => filterBy['sort']['column'][key] !== filterByDefault['sort']['column'][key]
      ) !== -1 ||
      Object.keys(filterByDefault['sort']['orderBy']).findIndex(
        (key) => filterBy['sort']['orderBy'][key] !== filterByDefault['sort']['orderBy'][key]
      ) !== -1
      // filterBy.status !== filterByDefault.status
    );
  };
  const resetFilterHandler = () => {
    setFilterBy(filterByDefault);
    setFilter((items) => ({ ...items, pageIndex: 0 }));
  };
  useEffect(() => {
    getAllAgents();
  }, [getAllAgents, filter]);

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  return (
    <div className='agents-view view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            {/* <Button className="btns theme-solid" onClick={() => setIsOpenManagement(true)}>
              {getTranslate()(`${translationPath}AddNewAgent`)}
            </Button> */}
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <Inputs
                idRef='agentsSearchRef'
                variant='outlined'
                fieldClasses='inputs theme-solid'
                translationPath={translationPath}
                label='filter'
                beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                onKeyUp={searchHandler}
                inputPlaceholder='search-agents'
              />
            </div>
            {/* <div>
              <ViewTypes
                // onTypeChanged={onTypeChanged}
                activeTypes={[ViewTypesEnum.cards.key]}
                className="mb-3"
              />
            </div> */}
          </div>
        </div>
        <div className='second-filter-section pt-2 c-black-light px-2'>
          <div className='section'>
            <span className='text-nowrap px-2'>
              {getTranslate()(`${translationPath}filter-by`)}
            </span>
            <div className='w-100 px-2'>
              <OrderByComponent
                selectedOrder={filterBy.sort}
                selectedOrderChanged={(selectedOrder) => {
                  if (filterBy.sort.column.key !== 0)
                    setFilter((items) => ({ ...items, pageIndex: 0 }));
                  setFilterBy((items) => ({
                    ...items,
                    sort: {
                      ...items.sort,
                      orderBy: selectedOrder,
                    },
                  }));
                }}
                selectedColumnChanged={(selectedColumn) => {
                  setFilter((items) => ({ ...items, pageIndex: 0 }));
                  setFilterBy((items) => ({
                    ...items,
                    sort: {
                      ...items.sort,
                      column: selectedColumn,
                      orderBy: {
                        ...items.sort.orderBy,
                        key: (selectedColumn.key !== 0 && items.sort.orderBy.key) || 0,
                      },
                    },
                  }));
                }}
                columnsKeyValues={[
                  { key: 'UserName', value: 'username' },
                  { key: 'FirstName', value: 'first-name' },
                  { key: 'SecondName', value: 'second-name' },
                  { key: 'LastName', value: 'last-name' },
                ]}
                translationPath={translationPath}
              />
            </div>
          </div>
          <div className='section px-2'>
            <DateRangePickerComponent
              isWideOvel
              ranges={[filterBy.dateRange]}
              onDateChanged={(selectedDate) => {
                setFilter((items) => ({ ...items, pageIndex: 0 }));
                setFilterBy((items) => ({
                  ...items,
                  dateRange: {
                    ...items.dateRange,
                    startDate: selectedDate.selection.startDate,
                    endDate: selectedDate.selection.endDate,
                  },
                }));
              }}
            />
          </div>
          <div className='section px-2'>
            <TimeRangePickerComponent
              range={timeRange}
              isWideOvel
              onClickOutside={() => {
                setFilter((items) => ({ ...items, pageIndex: 0 }));
                setFilterBy((items) => ({
                  ...items,
                  dateRange: {
                    ...items.dateRange,
                    startDate: moment(timeRange.startTime).toDate(),
                    endDate: moment(timeRange.endTime).toDate(),
                  },
                }));
              }}
              rangeChanged={(range) => {
                setTimeRange({
                  startTime: moment(range.startTime).toDate(),
                  endTime: moment(range.endTime).toDate(),
                });
              }}
              maxDate={timeRange.endTime}
              minDate={timeRange.startTime}
            />
          </div>
          {/* <div className="section px-2">
            <SelectComponet
              idRef="agentStatusRef"
              data={Object.values(TourStatusEnum).map((item) => ({
                key: item.key,
                value: item.value,
              }))}
              emptyItem={{
                value: -1,
                text: 'all',
              }}
              valueInput="key"
              textInput="value"
              classes="theme-solid filter-changes"
              paddingReverse="5rem"
              value={filterBy.status}
              overInputText="status"
              onSelectChanged={(event) => {
                setFilter((items) => ({ ...items, pageIndex: 0 }));
                setFilterBy((items) => ({ ...items, status: event.target.value }));
              }}
              overInputTextIcon="mdi mdi-circle-medium px-1"
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div> */}
          <div className='section px-2'>
            <ButtonBase
              className='btns theme-solid'
              disabled={!getIsChangedFilter()}
              onClick={resetFilterHandler}>
              <span>{getTranslate()(`${translationPath}reset`)}</span>
            </ButtonBase>
          </div>
        </div>
      </div>
      <NoDataFoundComponent />
      {agents.result.length === 0 && !filter.search && !isFirstLoad && <NoContentComponent />}
      {agents && agents.totalCount === 0 && filter.search.length !== 0 && !isFirstLoad && (
        <NoSearchResultComponent />
      )}
      <AgentsCardComponent
        data={agents}
        // filter={filter}
        onLoadMore={onLoadMoreHandler}
        // onActionClicked={onActionClicked}
        fromDate={filterBy.dateRange.startDate || ''}
        toDate={filterBy.dateRange.endDate || ''}
        onCardClicked={onCardClicked}
        translationPath={translationPath}
        isLoading={isLoading}
      />
      {/* {isOpenManagement && (
        <UserManagementDialog
          activeUserItem={null}
          isOpen={isOpenManagement}
          isOpenChanged={() => {
            setIsOpenManagement(false);
          }}
          reloadData={() => {
            setFilter((items) => ({ ...items, pageIndex: 0 }));
            setIsOpenManagement(false);
          }}
        />
      )} */}
      {/* <DialogComponent
        titleText="confirm-message"
        saveText="confirm"
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <span className="mdi mdi-close-octagon c-danger mdi-48px" />
            <span>{getTranslate()(`${translationPath}delete-description`)}</span>
          </div>
        }
        saveClasses="btns theme-solid bg-danger w-100 mb-0 mx-0 br-0"
        isOpen={isOpenConfirm}
        onSaveClicked={deleteHandler}
        translationPath={translationPath}
        onCloseClicked={() => setIsOpenConfirm(false)}
        onCancelClicked={() => setIsOpenConfirm(false)}
      /> */}
    </div>
  );
};
