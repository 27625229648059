import React, { useState, useEffect, useCallback } from 'react';
import { getLanguage } from 'react-switch-lang';
import { SliderSidePanel } from './SliderViewUtilities/SliderSidePanel';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { Spinner } from '../../../Componentes';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { AddScreensDialog } from './SliderViewUtilities/SliderViewDialogs/AddScreensDialog';
import { getDownloadableLink } from '../../../Helpers';
import { DefaultImagesEnum, LoadableImageEnum } from '../../../Enums';
import { GetSliderScreens } from '../../../Serviecs/SliderViewServices/SliderViewServices';
import { useEventListener } from '../../../Helpers';
import { NoSearchResultComponent, NoDataFoundComponent } from '../../../Componentes';
import {
  IconButton,
  TextField,
  Button,
  FormControl,
  Grid,
  Typography,
  CardContent,
  Card,
} from '@material-ui/core';
import { AddSliderDialog } from './SliderViewUtilities/SliderViewDialogs';
import { DeleteSliderDialog } from './SliderViewUtilities/SliderViewDialogs/DeleteSliderDialog';
import './SliderView.scss';
import { getTranslate } from '../../../Helpers';
import { useTitle } from '../../../Hooks/useTitle';
import { LoadableImageComponant, NoContentComponent } from '../../../Componentes';
import moment from 'moment';

const SliderView = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [searchedItem, setSearchedItem] = useState('');
  const [addDialog, setAddDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [sliderInfo, setSliderInfo] = React.useState({});
  const [page, setPage] = useState(0);
  const [addScreen, setAddScreen] = useState(false);
  const [screenDialog, setScreenDialog] = useState(false);
  const [responseResult, setResponseResult] = useState(() => ({ result: [], totalCount: 0 }));
  const [rowsPerPage] = useState(10);
  const [reloaded, setReloaded] = React.useState(false);
  const [isFirstLoad, setisFirstLoad] = useState(true);
  let time = setTimeout(() => {}, 300);
  const translationPath = 'SliderView.';

  useTitle(getTranslate()(`SideMenuView.MobilePages.Slider`));

  const GetSlider = useCallback(
    async (pageIndex, pageSize, searchedItem) => {
      setLoading(true);
      const res = await GetSliderScreens(pageIndex, pageSize, searchedItem);
      setResponse(res);
      if (res && res.totalCount === 0) {
        setisFirstLoad(false);
      }

      if (reloaded) setResponseResult({ result: [], totalCount: 0 });
      else {
        if (page === 0) setResponseResult(res);
        else {
          setResponseResult((item) => ({
            result: item.result.concat(res.result),
            totalCount: res.totalCount,
          }));
        }
      }
      setLoading(false);
    },
    [page, reloaded]
  );

  const loadMoreData = useCallback(() => {
    if (
      response &&
      response.result &&
      response.totalCount > response.result.length &&
      (page + 1) * rowsPerPage <= response.result.length
    )
      setPage(page + 1);
  }, [page, response, rowsPerPage]);

  const scrollHandler = () => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - 5
    )
      loadMoreData();
  };

  useEventListener('scroll', scrollHandler);

  useEffect(() => {
    if (document.body.scrollHeight <= document.body.clientHeight && !loading) loadMoreData();
    return () => {};
  }, [loadMoreData, loading]);

  useEffect(() => {
    GetSlider(page + 1, rowsPerPage, '');
  }, [GetSlider, page, rowsPerPage]);

  const HandleSliderSidePanel = useCallback(
    (item) => {
      sideMenuComponentUpdate(
        <SliderSidePanel
          item={item}
          reloadData={() => GetSlider(page + 1, rowsPerPage, '')}
          setAddDialog={setAddDialog}
          setIsEdit={setIsEdit}
          setSliderInfo={setSliderInfo}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [GetSlider, page, rowsPerPage]
  );

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  return (
    <div className='view-wrapper sliderView'>
      <Spinner isActive={loading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <Button
              className='btns theme-solid'
              onClick={() => {
                setIsEdit(false);
                setAddDialog(true);
              }}>
              {getTranslate()(`${translationPath}AddNewSlider`)}
            </Button>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <FormControl className='input-wrapper'>
                <Button className='btns-icon theme-transparent mx-2'>
                  <span className='mdi mdi-magnify' />
                </Button>
                <TextField
                  className='inputs theme-solid'
                  value={searchedItem}
                  onKeyUp={() => {
                    time = setTimeout(() => {
                      GetSlider(page + 1, rowsPerPage, searchedItem);
                    }, 500);
                  }}
                  onKeyDown={() => {
                    clearTimeout(time);
                  }}
                  onChange={(e) => setSearchedItem(e.target.value)}
                  label={getTranslate()(`${translationPath}Search`)}
                  variant='outlined'
                  placeholder={getTranslate()(`${translationPath}search-by-slider-name`)}
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {searchedItem && (
                          <IconButton
                            onClick={() => {
                              setSearchedItem('');
                              GetSlider(page + 1, rowsPerPage, '');
                            }}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className='section px-2'>
        <NoDataFoundComponent />
        {responseResult &&
          responseResult.result &&
          responseResult.result.length === 0 &&
          searchedItem.length === 0 &&
          !isFirstLoad && <NoContentComponent />}
        <Grid container spacing={3} className='slideWrapper'>
          {responseResult &&
          responseResult.totalCount === 0 &&
          searchedItem.length !== 0 &&
          !isFirstLoad ? (
            <NoSearchResultComponent />
          ) : (
            <>
              {responseResult &&
                responseResult.result &&
                responseResult.result.map((item, i) => (
                  <Grid item lg={6} sm={12} xl={4} xs={12} key={i} className='sliderCard'>
                    <Card onClick={() => HandleSliderSidePanel(item)}>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={11} container spacing={1}>
                            <Grid item xs={12} className='sliderName'>
                              <Typography>{item.sliderName}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={
                                item.sliderStatus !== 1 ? 'inactiveSlider' : 'sliderStatus'
                              }>
                              <Typography>
                                {item.sliderStatus === 1
                                  ? getTranslate()(`${translationPath}Active`)
                                  : getTranslate()(`${translationPath}Inactive`)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <div>
                                <WatchLaterIcon />
                                <span>{getTranslate()(`${translationPath}ViewFrom`)}:</span>
                                <span className='px-2'>
                                  {(item.startDate &&
                                    moment(item.startDate).format('YYYY-MM-DD')) ||
                                    'N/A'}
                                </span>
                                <span>{getTranslate()(`${translationPath}To`)}:</span>
                                <span className='px-2'>
                                  {(item.endDate && moment(item.endDate).format('YYYY-MM-DD')) ||
                                    'N/A'}
                                </span>
                              </div>
                              <div>
                                <WatchLaterIcon />
                                {item.screens.length}
                                {'  '}
                                {getTranslate()(`${translationPath}Slides`)}
                              </div>
                            </Grid>
                            <Grid container className='sliderImages'>
                              <Grid item xs={12}>
                                <div className='slide-images mx-2'>
                                  {Array.isArray(item.screens) &&
                                    item.screens.map((el, index) => (
                                      <LoadableImageComponant
                                        classes='slide-image'
                                        key={`tripImageSliderRef${index + 1}`}
                                        type={LoadableImageEnum.div.key}
                                        alt={el.screenTitle}
                                        src={
                                          (el.publicPreviewScreenImage &&
                                            getDownloadableLink(el.publicPreviewScreenImage)) ||
                                          DefaultImagesEnum.buildings.defaultImg
                                        }
                                        style={{
                                          zIndex: item.screens.length - index,
                                          transform: `translateX(${
                                            (getLanguage() === 'ar' &&
                                              -(index * (100 / item.screens.length))) ||
                                            index * (100 / item.screens.length)
                                          }%)`,
                                        }}
                                      />
                                    ))}
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                {Array.isArray(item.screens) &&
                                  item.screens.map(
                                    (el, index) =>
                                      index <= 3 && (
                                        <div key={index}>
                                          <div className='slidesDesc'>
                                            <Typography>
                                              {index + 1}
                                              {' -  '}
                                              {el.screenTitle}
                                            </Typography>
                                          </div>
                                          {index >= 3 && (
                                            <div className='viewAllImg'>
                                              <Button>
                                                {getTranslate()(`${translationPath}ViewAll`)}
                                              </Button>
                                            </div>
                                          )}
                                        </div>
                                      )
                                  )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={1} className='sliderActions'>
                            <Button
                              className='mdi mdi-plus-circle-outline'
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setIsEdit(false);
                                setAddScreen(true);
                                setSliderInfo(item);
                                setScreenDialog(true);
                              }}
                            />
                            <Button
                              className='mdi mdi-file-document-edit-outline'
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setIsEdit(true);
                                setSliderInfo(item);
                                setAddDialog(true);
                              }}
                            />
                            <Button
                              className='mdi mdi-delete-outline'
                              onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSliderInfo(item);
                                setDeleteDialog(true);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </div>
      <DeleteSliderDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        item={sliderInfo}
        setReloaded={setReloaded}
        reloadData={() => GetSlider(page + 1, rowsPerPage, '')}
      />
      <AddSliderDialog
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        open={addDialog}
        item={sliderInfo}
        setReloaded={setReloaded}
        setOpen={setAddDialog}
        reloadData={() => GetSlider(page + 1, rowsPerPage, '')}
      />
      <AddScreensDialog
        setAddScreen={setAddScreen}
        addScreen={addScreen}
        setReloaded={setReloaded}
        open={screenDialog}
        setOpen={setScreenDialog}
        sliderInfo={sliderInfo}
        item={sliderInfo}
        reloadData={() => GetSlider(page + 1, rowsPerPage, '')}
      />
    </div>
  );
};

export { SliderView };
