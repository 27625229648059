import { Log } from '../Helpers';

export const InitGlobalEventHandler = () => {
  window.onerror = (msg, url, lineNo, columnNo, error) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // window.location = '/home/ErrorPage';
    } else {
      window.location = '/home/ErrorPage';
    }

    var message = [
      'Message: ' + msg,
      'URL: ' + url,
      'Line: ' + lineNo,
      'Column: ' + columnNo,
      'Error object: ' + JSON.stringify(error),
    ].join(' - ');

    // console.log(message)
    Log(message);
  };
};
