import React, { useState, useEffect, useCallback } from 'react';
import { Tables } from '../../../../../Componentes';
import { GetCarActivitiesStatistics } from './../../../../../Serviecs';
import './CarActivity.scss';
import { getTranslate } from ' ./../../src/Helpers';
import { TotalToursEnum } from '../../../../../Enums';
import { SelectComponet } from '../../../../../Componentes';
import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';
import moment from 'moment';

export const CarsActivity = () => {
  const [isLoading, setIsLoading] = useState(true);
  const translationPath = 'CarActivity.';
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dropDownValue, setDropDownValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState({});
  const [dateRange, setDateRange] = useState({
    pageSize: 5,
    pageIndex: 1,
    fromDate: moment().add(-7, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });

  const GetCarActivities = useCallback(async () => {
    setIsLoading(true);
    const Status = await GetCarActivitiesStatistics(dateRange);
    if (Status) {
      setTableData(Status);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownValue]);

  useEffect(() => {
    GetCarActivities();
  }, [GetCarActivities]);

  const dropDwonDateRange = (value) => {
    setDropDownValue(value);
    switch (value) {
      case 0:
        setDateRange((items)=>({
          ...items,
          fromDate: moment().add(-7, 'd').format('YYYY-MM-DD'),
          endDate:  moment().format('YYYY-MM-DD'),
        }));
        break;
      case 1:
        setDateRange((items) =>({
          ...items,
          fromDate: moment().add(-1, 'M').format('YYYY-MM-DD'),
          endDate:moment().format('YYYY-MM-DD'),
        }));
        break;
      case 2:
        setDateRange((items) =>({
          ...items,
          fromDate: moment().add(-1, 'y').format('YYYY-MM-DD'),
          endDate:moment().format('YYYY-MM-DD'),
        }));
        break;
        
    default:
    }
  };

  const openHandler = () => {
    setIsOpen(true);
  };
  const closeHandler = () => {
    setIsOpen(false);
  };

  const handlePageChange = async (e, newPage) => {
    setPage(newPage);
    setDateRange({ pageIndex: newPage + 1, pageSize: rowsPerPage });
  };
  const handlePageRowChange = async (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
    setDateRange({ pageIndex: page + 1, pageSize: parseInt(e.target.value, 10) });
  };

  return (
    <div className='CarActivity-Table'>
    <Spinner isActive={isLoading} isAbsolute />
      <div className='CarActivityTitle'>
        <p className='CarActivityPargraph'>
          {getTranslate()(`${translationPath}Last Cars Activity`)}
        </p>
        <div className='CarActivity-select'>
        <SelectComponet
        isOpen={isOpen}
        onOpen={openHandler}
        onClose={closeHandler}
        initialSelectValue={0}
        data={TotalToursEnum}
        value={dropDownValue}
        textInput='value'
        valueInput='key'
        translationPathForData={translationPath}
        onSelectChanged={(event) => {
          dropDwonDateRange(event.target.value);
        }}
      />  
      </div>
      </div>
      <Tables
        data={tableData ? tableData.result : []}
        activePage={page}
        totalItems={tableData && tableData.totalCount ? tableData.totalCount : 0}
        activePageChanged={handlePageChange}
        itemsPerPage={rowsPerPage}
        itemsPerPageChanged={handlePageRowChange}
        headerData={[
          {
            id: 1,
            isSortable: true,
            label: `${translationPath}Car Name`,
            input: 'carName',
            isDate: false,
          },
          {
            id: 2,
            isSortable: true,
            label: `${translationPath}Plate Num`,
            input: 'plateNumber',
            isDate: false,
          },
          {
            id: 3,
            isSortable: true,
            label: `${translationPath}Activity Type`,
            input: 'activity',
            isDate: false,
          },
          {
            id: 4,
            isSortable: true,
            label: `${translationPath}Date`,
            input: 'activityDate',
            isDate: true,
          },
        ]}
        responsiveWrapperClasses='theme-dashboard'
      />
    </div>
  );
};
