import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { getTranslate } from "../../../../../Helpers";
const translationPath = "Lookups.DeleteDialog.";

export const LookupsDeleteDialog = (props) => (
  <Dialog
    open={props.open}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    onClose={() => {
      props.setOpen(false);
    }}
  >
    <form
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
        props.setOpen(false);
        props.onDelete(props.row);
      }}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {getTranslate()(`${translationPath}DeleteLookupItem`)} {props.name}
      </DialogTitle>
      <DialogContent>
        {getTranslate()(`${translationPath}ConfirmText`)} {props.name}?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setOpen(false);
            props.onCancel();
          }}
          className="btns theme-solid bg-cancel"
        >
          {getTranslate()(`${translationPath}Cancel`)}
        </Button>
        <Button className="btns theme-solid" type="submit">
          {getTranslate()(`${translationPath}Delete`)}
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);
