import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import { getTranslate } from '../../Helpers';

const RadiosGroupComponent = ({
  data,
  name,
  title,
  ariaLabel,
  translationPath,
  translationPathForData,
  value,
  onSelectedRadioChanged,
  key,
  isDisabledInput,
  isDisabled,
  valueInput,
  labelInput,
  wrapperClasses,
  radioClasses,
  titleClasses,
  idRef
}) => (
  <FormControl className={`radio-groups-wrapper ${wrapperClasses}`} component="fieldset">
    {title && (
      <FormLabel id={idRef} className={titleClasses} component="legend">{getTranslate()(`${translationPath}${title}`)}</FormLabel>
    )}
    <RadioGroup
      aria-label={
        ariaLabel ? getTranslate()(`${translationPathForData}${ariaLabel}`) : 'Radio Group'
      }
      id={idRef}
      name={name}
      className="radio-group-wrapper"
      value={value}
      onChange={onSelectedRadioChanged}
    >
      {data.map((item, index) => (
        <FormControlLabel
          key={`${key}${index + 1}`}
          disabled={isDisabledInput ? item[isDisabledInput] : isDisabled}
          value={valueInput ? item[valueInput] : item}
          control={(
            <Radio
              className={`radio-wrapper ${radioClasses}`}
              checkedIcon={<span className="mdi mdi-checkbox-blank-circle radio-icon" />}
              icon={<span className="mdi mdi-checkbox-blank-circle radio-icon" />}
            />
          )}
          label={labelInput ? getTranslate()(`${translationPathForData}${item[labelInput]}`) : item}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

RadiosGroupComponent.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  name: PropTypes.string.isRequired,
  idRef: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  title: PropTypes.string,
  translationPath: PropTypes.string,
  translationPathForData: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onSelectedRadioChanged: PropTypes.func,
  key: PropTypes.string,
  isDisabledInput: PropTypes.string,
  isDisabled: PropTypes.bool,
  valueInput: PropTypes.string,
  labelInput: PropTypes.string,
  wrapperClasses: PropTypes.string,
  radioClasses: PropTypes.string,
  titleClasses: PropTypes.string,
};
RadiosGroupComponent.defaultProps = {
  title: '',
  ariaLabel: null,
  translationPath: '',
  translationPathForData: '',
  value: undefined,
  onSelectedRadioChanged: () => {},
  key: 'radioGroups',
  isDisabledInput: null,
  isDisabled: false,
  valueInput: undefined,
  labelInput: null,
  wrapperClasses: '',
  radioClasses: '',
  titleClasses: '',
};
export { RadiosGroupComponent };
