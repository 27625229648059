/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GetTripById } from '../../../../../Serviecs';
import {
  Spinner,
  CollapseComponent,
  GoogleMapsComponent,
  SlideImagesComponent,
} from '../../../../../Componentes';
import { getTranslate } from '../../../../../Helpers';
import Rating from '@material-ui/lab/Rating';
import { Button } from '@material-ui/core';

export const TripDetailsComponent = ({ tripId, translationPath, onDeleteClicked, onMapClick }) => {
  const [trip, setTrip] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [features, setFeatures] = useState(null);
  const [isOpen, setIsOpen] = useState({
    description: false,
    notes: false,
    features: [],
  });
  const toggleFeaturesHandler = useCallback(
    (index) => () => {
      const itemIndex = isOpen.features.findIndex((item) => item === index);
      setIsOpen((items) => {
        if (itemIndex !== -1) items.features.splice(itemIndex, 1);
        else items.features.push(index);
        return { ...items };
      });
    },
    [isOpen.features]
  );
  const getIsOpenFeature = (index) => isOpen.features.includes(index);
  const getTripDetails = useCallback(async () => {
    setIsLoading(true);
    const res = await GetTripById(tripId);
    setTrip(res);
    setFeatures(
      (res &&
        res.featureInformations &&
        res.featureInformations.reduce((total, item) => {
          (total[item['parentLookupItemId']] = total[item['parentLookupItemId']] || []).push(item);
          return total;
        }, {})) ||
        null
    );
    setIsLoading(false);
  }, [tripId]);
  useEffect(() => {
    getTripDetails();
  }, [getTripDetails, tripId]);
  return (
    (trip && (
      <div className='trip-details-wrapper view-wrapper'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className='d-flex-v-center-h-between'>
          <span className='fz-16px texts-truncate px-2'>{trip.tripName}</span>
          <Button className='btns-icon px-2' onClick={() => onDeleteClicked(trip)}>
            <span className='mdi mdi-trash-can-outline c-danger'></span>
          </Button>
        </div>
        <div className='w-100'>
          <span className='fz-16px px-2'>
            <Rating
              name='trip-rating'
              defaultValue={5}
              value={(trip.totalTripReview && trip.totalTripReview.rating) || 0}
              precision={0.5}
              size='small'
              icon={<span className='mdi mdi-star' />}
              disabled
              emptyIcon={<span className='mdi mdi-star-outline' />}
            />
          </span>
          <span className='fz-16px px-2'>{`${
            (trip.totalTripReview && trip.totalTripReview.reviews) || 0
          } ${getTranslate()(`${translationPath}reviews`)}`}</span>
        </div>
        <SlideImagesComponent
          data={(trip && trip.items && trip.items.itemImages && trip.items.itemImages) || []}
          imageInput='fileId'
          alt='trip-image'
          titleText='trip-gallery'
          translationPath={translationPath}
          withViewGallery
        />
        <div className='w-100 mb-2'>
          <span className='mdi mdi-home px-2' />
          <span>{getTranslate()(`${translationPath}type`)}:</span>
          <span className='px-2 d-inline-flex texts-truncate'>
            {trip.items && trip.items.forms && trip.items.forms.formsName}
          </span>
        </div>
        <div className='w-100 mb-2'>
          <span className='mdi mdi-clock-time-five px-2' />
          <span>{getTranslate()(`${translationPath}time`)}:</span>
          <span className='px-2 d-inline-flex texts-truncate'>
            <span>{trip.tripDuration}</span>
            <span className='px-2'>{getTranslate()(`${translationPath}min`)}</span>
          </span>
        </div>
        <div className='w-100 mb-2'>
          <span className='mdi mdi-cash-multiple px-2' />
          <span>{getTranslate()(`${translationPath}price`)}:</span>
          <span className='px-2 d-inline-flex texts-truncate'>{trip.tripPrice}</span>
        </div>
        <div className='w-100 mb-2'>
          <span className='mdi mdi-map-marker px-2' />
          <span>
            {getTranslate()(`${translationPath}address`)}:
            <span className='px-2'>{trip.address}</span>
          </span>
        </div>
        <div className='w-100 mb-2'>
          <GoogleMapsComponent
            locations={[
              {
                latitude: trip.latitude,
                longitude: trip.longitude,
              },
            ]}
            center={(trip.latitude && { lat: trip.latitude, lng: trip.longitude }) || undefined}
            onClick={onMapClick(trip)}
          />
        </div>
        <Button
          className='d-flex-v-center-h-between px-0 py-2 separator-h'
          onClick={() => setIsOpen((items) => ({ ...items, description: !items.description }))}>
          <span className='px-2'>{getTranslate()(`${translationPath}description`)}</span>
          <span className={`mdi mdi-chevron-${(isOpen.description && 'right') || 'left'} px-2`} />
        </Button>
        <CollapseComponent
          isOpen={isOpen.description}
          classes=''
          component={<div className='px-2 mb-2'>{trip.description}</div>}
        />
        <Button
          className='d-flex-v-center-h-between px-0 py-2 separator-h'
          onClick={() => setIsOpen((items) => ({ ...items, notes: !items.notes }))}>
          <span className='px-2'>{getTranslate()(`${translationPath}notes`)}</span>
          <span className={`mdi mdi-chevron-${(isOpen.notes && 'right') || 'left'} px-2`} />
        </Button>
        <CollapseComponent
          isOpen={isOpen.notes}
          classes=''
          component={<div className='px-2 mb-2'>{trip.notes}</div>}
        />
        {features &&
          Object.values(features).map((item, index) => (
            <div key={`featuresInfoRef${index + 1}`}>
              <Button
                className='d-flex-v-center-h-between px-0 py-2 separator-h'
                onClick={toggleFeaturesHandler(index)}>
                <span className='px-2'>{item[0].parentLookupItemName}</span>
                <span
                  className={`mdi mdi-chevron-${
                    (getIsOpenFeature(index) && 'right') || 'left'
                  } px-2`}
                />
              </Button>
              <CollapseComponent
                isOpen={getIsOpenFeature(index)}
                classes=''
                component={item.map((subItem, subIndex) => (
                  <div
                    key={`featuresInfoItemsRef${subIndex * (index + 0.5)}`}
                    className='px-2 mb-2'>
                    {subItem.lookupItemName}
                  </div>
                ))}
              />
            </div>
          ))}
      </div>
    )) ||
    null
  );
};

TripDetailsComponent.propTypes = {
  tripId: PropTypes.number.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  onMapClick: PropTypes.func.isRequired,
  translationPath: PropTypes.string,
};
TripDetailsComponent.defaultProps = {
  translationPath: 'home.trips.tripsView.tripDetailsComponent.',
};
