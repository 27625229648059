import React,{useState, useEffect, useCallback} from 'react'
import { SelectComponet } from '../../../../../Componentes';
import system from './../../../../../Assets/Images/DashboardImages/System.png'
import gmail from './../../../../../Assets/Images/DashboardImages/Gmail.png'
import twitter from './../../../../../Assets/Images/DashboardImages/twitter.png'
import facebook from './../../../../../Assets/Images/DashboardImages/facebook.png'
 import './TotalSourceComponent.scss'
 import { TotalToursEnum } from '../../../../../Enums';import { getTranslate } from ' ./../../src/Helpers';
 import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';
 import { GetSocialMediaStatistics } from './../../../../../Serviecs';
import moment from 'moment';

export const TotalSourceComponent = () => {
    const translationPath = 'TotalSource.';
    const [dropDownValue, setDropDownValue] = useState(0);
    const [dateRange, setDateRange] = useState({
      startDate: moment().add(-7, 'd').format('YYYY-MM-DD'),
      endDate:moment().format('YYYY-MM-DD') ,
    });
    const [state,setState] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const openHandler = () => {
        setIsOpen(true);
      };
      const closeHandler = () => {
        setIsOpen(false);
      };

      const dropDwonDateRange = (value) => {
        setDropDownValue(value);
        switch (value) {
          case 0:
            setDateRange((items)=>({
              ...items,
              startDate: moment().add(-7, 'd').format('YYYY-MM-DD'),
              endDate:  moment().format('YYYY-MM-DD'),
            }));
            break;
          case 1:
            setDateRange((items) =>({
              ...items,
              startDate: moment().add(-1, 'M').format('YYYY-MM-DD'),
              endDate:moment().format('YYYY-MM-DD'),
            }));
            break;
          case 2:
            setDateRange((items) =>({
              ...items,
              startDate: moment().add(-1, 'y').format('YYYY-MM-DD'),
              endDate:moment().format('YYYY-MM-DD'),
            }));
            break;
            
        default:
        }
      };
      const GetSocialMedia = useCallback(async () => {
        setIsLoading(true);
        const Status = await GetSocialMediaStatistics(dateRange);
        if (Status) {
          setState(Status);
          setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dropDownValue]);
    
      useEffect(() => {
        GetSocialMedia();
      }, [GetSocialMedia]);
    
    return (
        <div className='TotalSource'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className="TotalSourceTitle"> 
        <p className="TotalSourcePargraph">{getTranslate()(`${translationPath}Users Source`)}</p>
        <div className='TotalSource-select'>
        <SelectComponet
        isOpen={isOpen}
        onOpen={openHandler}
        onClose={closeHandler}
        initialSelectValue={0}
        data={TotalToursEnum}
        value={dropDownValue}
        textInput='value'
        valueInput='key'
        translationPathForData={translationPath}
        onSelectChanged={(event) => {
          dropDwonDateRange(event.target.value);
        }}
      />  
      </div>
        </div>
        {! isLoading && 
        <div className='SoialMedia'>
        <div>
        <p className='UsersNum'>{state[0].registeredUsersCount}</p>
        <div className='SoialImg'> 
        <img src={facebook} alt="BigCo Inc. logo"/>
        </div>
        <p className='Soial'> {state[0].socialMediaName} </p>
        </div>
        <div>
        <p className='UsersNum'>{state[1].registeredUsersCount}</p>
        <div className='SoialImg'> 
        <img src={gmail} alt="BigCo Inc. logo"/>
        </div>
        <p className='Soial'>{state[1].socialMediaName} </p>
        </div>
        <div>
        <p className='UsersNum'>{state[2].registeredUsersCount}</p>
        <div className='SoialImg'> 
        <img src={twitter} alt="BigCo Inc. logo"/>
        </div>
        <p className='Soial'> {state[2].socialMediaName}  </p>
        </div>
        <div>
        <p className='UsersNum'>{state[3].registeredUsersCount}</p>
        <div className='SoialImg'> 
        <img src={system} alt="BigCo Inc. logo"/>
        </div>
        <p className='Soial'>{state[3].socialMediaName}</p>
        </div>
        </div>
        }
        </div>
    
    )
}
