import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './TimeComponent.scss';
import {
  DialogComponent,
  NoContentComponent,
  Spinner,
  Tables,
} from '../../../../../../Componentes';
import { TableActions } from '../../../../../../Enums';
import { Button } from '@material-ui/core';
import { DateTimeReservedBySame, getTranslate } from '../../../../../../Helpers';
import { lookupItemsGetId } from '../../../../../../Serviecs';
import Lookups from '../../../../../../Assets/JSON/StaticLookupsIds.json';
import { TimeManagementDialog } from './TimeDialogs';
import moment from 'moment';

export const TimeComponent = ({ state, activeItem, tripDateChanged, translationPath }) => {
  //   const [times, setTimes] = useState({ result: [], totalCount: 0 });
  //   const [isLoading, setIsLoading] = useState(false);
  const [activeTimeItem, setActiveTimeItem] = useState(null);
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [isView, setIsView] = useState(false);
  const [weekDays, setWeekDays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenTimeManagementDialog, setIsOpenTimeManagementDialog] = useState(false);
  const [filter, setFilter] = useState({ page: 0, rowsPerPage: 10 });
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const itemsPerPageChanged = useCallback((event, newItemsPerPage) => {
    setFilter((items) => ({ ...items, pageIndex: 0, rowsPerPage: newItemsPerPage.props.value }));
  }, []);
  const activePageChanged = useCallback((e, newPage) => {
    setFilter((items) => ({ ...items, pageIndex: newPage }));
  }, []);
  const getDialogConvertedTimes = useCallback(
    (dataToConvert) => {
      const finalResult = [];
      if (
        dataToConvert.isRepeated ||
        (dataToConvert.tripTimes.length > 0 && dataToConvert.tripTimes[0].dayOfWeekId !== null)
      ) {
        dataToConvert.tripTimes.forEach((item) => {
          const dayObject = weekDays.find((element) => element.lookupItemId === item.dayOfWeekId);
          const sameTimeIndex = finalResult.findIndex(
            (element) =>
              item.adultPrice === element.adultPrice &&
              item.childPrice === element.childPrice &&
              item.infantPrice === element.infantPrice &&
              DateTimeReservedBySame(
                moment(item.tripOpenTime, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                moment(item.tripCloseTime, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                element.timeRange.startTime,
                element.timeRange.endTime,
                'm'
              )
          );
          if (sameTimeIndex !== -1) finalResult[sameTimeIndex].dayOfWeek.push(dayObject);
          else
            finalResult.push({
              dayOfWeek: [dayObject],
              adultPrice: item.adultPrice,
              childPrice: item.childPrice,
              infantPrice: item.infantPrice,
              dateRange: {
                startDate: null,
                endDate: null,
                key: 'selection',
              },
              timeRange: {
                startTime: moment(item.tripOpenTime, 'YYYY-MM-DDTHH:mm:ss').format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                endTime: moment(item.tripCloseTime, 'YYYY-MM-DDTHH:mm:ss').format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
              },
            });
        });
      } else {
        const sortedByDate = dataToConvert.tripTimes.sort(
          (a, b) => new Date(a.tripTimeDate) - new Date(b.tripTimeDate)
        );
        sortedByDate.forEach((item) => {
          const sameTimeIndex = finalResult.findIndex(
            (element) =>
              !element.dateRange.endDate &&
              item.adultPrice === element.adultPrice &&
              item.childPrice === element.childPrice &&
              item.infantPrice === element.infantPrice &&
              DateTimeReservedBySame(
                moment(item.tripOpenTime, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                moment(item.tripCloseTime, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                element.timeRange.startTime,
                element.timeRange.endTime,
                'm'
              )
          );
          if (sameTimeIndex !== -1 && !finalResult[sameTimeIndex].dateRange.endDate)
            finalResult[sameTimeIndex].dateRange.endDate = moment(item.tripTimeDate).toDate();
          else
            finalResult.push({
              dayOfWeek: [],
              adultPrice: item.adultPrice,
              childPrice: item.childPrice,
              infantPrice: item.infantPrice,
              dateRange: {
                startDate: moment(item.tripTimeDate).toDate(),
                endDate: null,
                key: 'selection',
              },
              timeRange: {
                startTime: moment(item.tripOpenTime, 'YYYY-MM-DDTHH:mm:ss').format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                endTime: moment(item.tripCloseTime, 'YYYY-MM-DDTHH:mm:ss').format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
              },
            });
        });
      }
      return finalResult;
    },
    [weekDays]
  );
  const getConvertSavedToDialog = useCallback(
    (dataToConvert) => ({
      tripDateRange: {
        startDate: moment(dataToConvert.tripStartDate).toDate(),
        endDate: moment(dataToConvert.tripEndDate).toDate(),
        key: 'selection',
      },
      isRepeated:
        dataToConvert.isRepeated ||
        (dataToConvert.tripTimes.length > 0 && dataToConvert.tripTimes[0].dayOfWeekId !== null),
      tripTimes: getDialogConvertedTimes(dataToConvert),
    }),
    [getDialogConvertedTimes]
  );
  const deleteHandler = () => {
    if (tripDateChanged) {
      tripDateChanged(null, activeItemIndex);
    }
    setIsOpenConfirm(false);
    setActiveItemIndex(null);
  };
  const onActionClicked = useCallback(
    (actionEnum, item, index) => {
      if (actionEnum === TableActions.edit.key) {
        setIsOpenTimeManagementDialog(true);
        setActiveTimeItem(getConvertSavedToDialog(item));
        setActiveItemIndex(index);
      } else if (actionEnum === TableActions.view.key) {
        setIsOpenTimeManagementDialog(true);
        setActiveTimeItem(getConvertSavedToDialog(item));
        setActiveItemIndex(index);
        setIsView(true);
      } else if (actionEnum === TableActions.delete.key) {
        setIsOpenConfirm(true);
        setActiveItemIndex(index);
      }
    },
    [getConvertSavedToDialog]
  );
  const getTripTimes = (dataToConvert) => {
    const finalResult = [];
    dataToConvert.tripTimes.forEach((item) => {
      ((dataToConvert.isRepeated && item.dayOfWeek) || [0, 1]).forEach((element) => {
        finalResult.push({
          dayOfWeekId: (dataToConvert.isRepeated && element.lookupItemId) || null,
          tripTimeDate:
            (!dataToConvert.isRepeated &&
              ((element === 0 && moment(item.dateRange.startDate).format('YYYY-MM-DDT00:00:00')) ||
                moment(item.dateRange.endDate).format('YYYY-MM-DDT00:00:00'))) ||
            null,
          tripOpenTime: moment(item.timeRange.startTime).format('YYYY-MM-DDTHH:mm:ss'),
          tripCloseTime: moment(item.timeRange.endTime).format('YYYY-MM-DDTHH:mm:ss'),
          childPrice: item.childPrice,
          adultPrice: item.adultPrice,
          infantPrice: item.infantPrice,
        });
      });
    });
    return finalResult;
  };
  const getConvertedDateToSave = useCallback(
    (dataToConvert) => ({
      tripStartDate: dataToConvert.tripDateRange.startDate,
      tripEndDate: dataToConvert.tripDateRange.endDate,
      isRepeated: dataToConvert.isRepeated,
      tripTimes: getTripTimes(dataToConvert),
    }),
    []
  );
  const getDayOfWeeks = async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({ lookupTypeId: Lookups.WeekDays });
    setWeekDays(res);
    setIsLoading(false);
  };
  const addNewTime = () => {
    setIsOpenTimeManagementDialog(true);
  };
  useEffect(() => {
    getDayOfWeeks();
  }, []);
  return (
    <div className="time-component-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="header-section">
        <div className="filter-section px-2">
          <div className="section">
            <Button className="btns theme-solid" onClick={addNewTime}>
              {getTranslate()(`${translationPath}add-new-times`)}
            </Button>
          </div>
          {/* <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <Inputs
                idRef='tripsSearchRef'
                variant='outlined'
                fieldClasses='inputs theme-solid'
                translationPath={translationPath}
                label='filter'
                beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                onKeyUp={searchHandler}
                inputPlaceholder='search-trips'
              />
            </div>
            <div>
              <ViewTypes
                onTypeChanged={onTypeChanged}
                activeTypes={[ViewTypesEnum.tableView.key]}
                className='mb-3'
              />
            </div>
          </div> */}
        </div>
      </div>
      {/* <Spinner isActive={isLoading} isAbsolute /> */}
      {(state.tripDates && state.tripDates.length === 0 && <NoContentComponent />) || (
        <div className="w-100 pb-3 px-3">
          <Tables
            headerData={[
              {
                id: 1,
                isSortable: true,
                label: `${translationPath}start-date`,
                input: 'tripStartDate',
                isDate: true,
              },
              {
                id: 2,
                isSortable: true,
                label: `${translationPath}end-date`,
                input: 'tripEndDate',
                isDate: true,
              },
              {
                id: 3,
                isSortable: true,
                label: `${translationPath}is-repeated`,
                component: (row) => (
                  <span>
                    {(row.isRepeated && 'true') ||
                      (row.tripTimes.length > 0 &&
                        row.tripTimes[0].dayOfWeekId !== null &&
                        'true') ||
                      'false'}
                  </span>
                ),
              },
            ]}
            data={state.tripDates}
            activePage={filter.page}
            totalItems={state.tripDates.length}
            activePageChanged={activePageChanged}
            itemsPerPage={filter.rowsPerPage}
            itemsPerPageChanged={itemsPerPageChanged}
            actionsOptions={{
              actions: [
                {
                  enum: TableActions.view.key,
                  isDisabled: false,
                  externalComponent: null,
                },
                {
                  enum: TableActions.edit.key,
                  isDisabled: false,
                  externalComponent: null,
                },
                {
                  enum: TableActions.delete.key,
                  isDisabled: false,
                  externalComponent: null,
                },
              ],
              onActionClicked: onActionClicked,
              classes: '',
              isDisabled: false,
            }}
            // focusedRowChanged={focusedRowChanged}
          />
        </div>
      )}
      {isOpenTimeManagementDialog && (
        <TimeManagementDialog
          activeTimeItem={activeTimeItem}
          activeItemIndex={activeItemIndex}
          tripDates={state.tripDates}
          weekDays={weekDays}
          isOpen={isOpenTimeManagementDialog}
          translationPath={translationPath}
          isView={isView}
          isOpenChanged={() => {
            setIsOpenTimeManagementDialog(false);
            setActiveTimeItem(null);
            setActiveItemIndex(null);
            setIsView(false);
          }}
          onDataSave={(savedData) => {
            if (tripDateChanged)
              tripDateChanged(getConvertedDateToSave(savedData), activeItemIndex);
            setFilter((item) => ({ ...item, pageIndex: 0 }));
            setActiveTimeItem(null);
            setActiveItemIndex(null);
            setIsOpenTimeManagementDialog(false);
          }}
        />
      )}
      <DialogComponent
        titleText="confirm-message"
        saveText="confirm"
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <span className="mdi mdi-close-octagon c-danger mdi-48px" />
            <span>{getTranslate()(`${translationPath}delete-description`)}</span>
          </div>
        }
        saveClasses="btns theme-solid bg-danger w-100 mb-0 mx-0 br-0"
        isOpen={isOpenConfirm}
        onSaveClicked={deleteHandler}
        translationPath={translationPath}
        onCloseClicked={() => {
          setIsOpenConfirm(false);
          setActiveItemIndex(null);
        }}
        onCancelClicked={() => {
          setIsOpenConfirm(false);
          setActiveItemIndex(null);
        }}
      />
    </div>
  );
};

TimeComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  tripDateChanged: PropTypes.func.isRequired,
  translationPath: PropTypes.string,
};
TimeComponent.defaultProps = {
  translationPath: 'home.trips.tripsManagementView.timeComponent.',
};
