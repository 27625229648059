import config from './../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';

export const GetAllPendingReservationTour = async ({ pageSize, pageIndex, search }) => {
  try {
    const queryList = [];
    (pageSize || pageSize === 0) && queryList.push(`pageSize=${pageSize}`);
    (pageIndex || pageIndex === 0) && queryList.push(`pageIndex=${pageIndex + 1}`);
    search && queryList.push(`search=${search}`);

    const result = await HttpServices.get(
      `${
        config.serverAddress
      }/PSTViewing/TourTransaction/GetAllPendingReservationTour?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const GetAllUpComingReservationTour = async ({ pageSize, pageIndex, search }) => {
  try {
    const queryList = [];
    (pageSize || pageSize === 0) && queryList.push(`pageSize=${pageSize}`);
    (pageIndex || pageIndex === 0) && queryList.push(`pageIndex=${pageIndex + 1}`);
    search && queryList.push(`search=${search}`);

    const result = await HttpServices.get(
      `${
        config.serverAddress
      }/PSTViewing/TourTransaction/GetAllUpComingReservationTour?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const UpdateReservationTour = async (reservationTourId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/TourTransaction/UpdateReservationTour/${reservationTourId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
