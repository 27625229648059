import React from 'react';
import { withRouter } from 'react-router';
import { translate, setTranslations, setDefaultLanguage, setLanguage } from 'react-switch-lang';
import config from '../Assets/Configration/Configration.json';
import en from '../Assets/I18n/en.json';
import ar from '../Assets/I18n/ar.json';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ProgressEnum } from '../Enums';
import { LoggerActions } from '../Stores/Actions';

let setRender = null;
let setReRenderSideMenu = null;
let logoutAction = null;

let render = true;
const render2 = true;
export function storeRenderCallback(RendercallBack) {
  setRender = RendercallBack;
}

export function reRender() {
  if (setRender) {
    setRender(render);
    if (setReRenderSideMenu) setReRenderSideMenu(!render2);
    setTimeout(() => {
      if (setReRenderSideMenu) setReRenderSideMenu(render2);
    }, 100);
    render = !render;
  }
}
export const languageChange = (currentLanguage) => {
  const isRtl = currentLanguage === 'ar';
  const direction = currentLanguage === 'ar' ? 'rtl' : '';
  localStorage.setItem('localization', JSON.stringify({ currentLanguage, isRtl }));
  document.body.setAttribute('class', direction);
  document.body.setAttribute('dir', direction);
  document.documentElement.lang = currentLanguage;
  setLanguage(currentLanguage);
  reRender();
};
export const getErrorByName = (schemaObject, fieldName) => {
  if (!schemaObject.error || !schemaObject.error.details)
    return {
      message: undefined,
      error: undefined,
    };
    const item = schemaObject.error.details.find((item) => {
      return (
        (!fieldName.includes('.') && item.path.includes(fieldName)) ||
        (fieldName.includes('.') &&
        item.path.length >= fieldName.split('.').length &&
        item.path.slice(0, fieldName.split('.').length).join('.') === fieldName)
        );
      });
  if (!item)
    return {
      message: undefined,
      error: undefined,
    };
  return {
    message: item.message,
    error: true,
  };
};
export function localizationInit() {
  setTranslations({ en, ar });
  setDefaultLanguage('en');

  if (localStorage.getItem('localization'))
    languageChange(JSON.parse(localStorage.getItem('localization')).currentLanguage);
  // @todo store in redux LocalizationActions.localizationChange(lang.lang_key);
  else {
    localStorage.setItem('localization', JSON.stringify({ currentLanguage: 'en', isRtl: false }));
    languageChange('en');
    // @todo store in redux LocalizationActions.localizationChange("en");
  }
}
localizationInit();
// variable which will point to react-router history
let globalHistory = null;
let mytranslate = null;
let loading = null;
let loginDialog = null;
let contactDialog = null;
let mapDialog = null;
let look = [];
let setSideMenuIsOpen = null;
let sideMenuIsOpen = false;
let setSideMenuComponent = null;
let sideMenuComponent = null;
let dispatchLog = null;

// component which we will mount on top of the app
class Meddilware extends React.Component {
  componentWillMount() {
    dispatchLog = this.props.LoggerActions;
    const { history, t } = this.props;
    globalHistory = history;
    mytranslate = t;
  }

  componentWillReceiveProps(nextProps) {
    globalHistory = nextProps.history;
    look = [];
    mytranslate = nextProps.t;
    if (typeof loading === 'function') loading(false);
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  const { LoggerReducer: loggerResponse } = state;
  return {
    loggerResponse,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    LoggerActions: bindActionCreators(LoggerActions.logRequest, dispatch),
  };
}

const _meddilware = withRouter(translate(Meddilware));
const store = connect(mapStateToProps, mapDispatchToProps)(_meddilware);

export { store as Meddilware };

// export react-router history
export function getHistory() {
  return globalHistory;
}

// export react-router history
export function Log(data) {
  if (dispatchLog) dispatchLog(data);
}

export function setLogoutAction(callback) {
  logoutAction = callback;
}

export function LogoutAction() {
  return logoutAction;
}
export function setLoading(callback) {
  loading = callback;
}

export function changeLoading(flag) {
  loading(flag);
}

export function setloginDialog(callback) {
  loginDialog = callback;
}

export function ShowloginDialog() {
  loginDialog(true);
}
export function setSideMenuIsOpenCallback(callback) {
  setSideMenuIsOpen = callback;
}

export function sideMenuIsOpenUpdate(state = null) {
  setSideMenuIsOpen(state !== null ? state : !sideMenuIsOpen);
  sideMenuIsOpen = state !== null ? state : !sideMenuIsOpen;
}
export function getSideMenuStatus() {
  return sideMenuIsOpen;
}
export function sideMenuComponentCallback(callback) {
  setSideMenuComponent = callback;
}
export function setReRenderSideMenuCallback(callback) {
  setReRenderSideMenu = callback;
}
export function sideMenuComponentUpdate(component) {
  setSideMenuComponent(component);
  sideMenuComponent = component;
}
export function getSideMenuComponent() {
  return sideMenuComponent;
}

// export function getSideMenuComponent() {
//   return sideMenuIsOpen;
// }

export function setContactDialogs(callback) {
  contactDialog = callback;
}

export function setMapDialog(callback) {
  mapDialog = callback;
}

export function ShowMapDialog() {
  mapDialog(true);
}

export function ShowContactDialog() {
  contactDialog(true);
}

export function getLook() {
  return look;
}

export function getTranslate() {
  return mytranslate;
}
export const getBgProgressColor = (value) => {
  if (value >= 90) return ProgressEnum['100-90'];
  if (value >= 70) return ProgressEnum['90-70'];
  if (value >= 40) return ProgressEnum['70-40'];
  if (value >= 0) return ProgressEnum['40-0'];
  return null;
};
export function getDownloadableLink(fileId) {
  const session = JSON.parse(localStorage.getItem('session'));
  const userId = session ? session.userId : '';
  const fileToken = session ? session.fileToken : '';
  return `${config.serverAddress}/FileManager/File/DownloadFile/${userId}/${config.applicationId}/${fileToken}/${fileId}?sourceId=${config.source}`;
}

// interceptors for any request
// axios.interceptors.request.use(
//   (configurations) => {
//     const configurationsLocal = configurations;
//     if (
//       localStorage.getItem('session') &&
//       JSON.parse(localStorage.getItem('session')).token !== ''
//     ) {
//       configurationsLocal.headers.Authorization = `Bearer ${
//         JSON.parse(localStorage.getItem('session')).token
//       }`;
//     }
//     return configurationsLocal;
//   },
//   (error) => Promise.reject('request', error)
// );

// interceptors for handle any  response
// axios.interceptors.response.use(
//   (response) =>
//     // Do something with response data

//     response,
//   (error) => {
//     // Do something with response error
//     if (error.response.status === 401) {
//       const obj = JSON.parse(localStorage.getItem('session'));
//       const token = obj.token;
//       const refreshTokenId = obj.refreshToken;
//       obj.token = '';
//       localStorage.setItem('session', JSON.stringify(obj));
//       RefreshToken({ refreshTokenId, token }).then((result) => {
//         if (result) {
//           localStorage.setItem('session', JSON.stringify(result));
//         }
//       });
//     }

//     // else if(error.response.status === 404)
//     // getHistory().push("/error");

//     // Trow errr again (may be need for some other catch)
//     return Promise.reject(error);
//   }
// );
