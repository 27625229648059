import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';
import { showError } from '../../Helpers';

export const GetAllInvoices = async (pageIndex, pageSize, body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/BasketTours/GetAllInvoices?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Retriving Invoices');
  }
};

export const GetInvoicesByPaymentOrder = async (paymentOrderId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/BasketTours/GetInvoicesByPaymentOrder/${paymentOrderId}`
    );
    return result;
  } catch (e) {
    showError('Error Retriving Invoices');
  }
};

export const DownloadInvoicesByPaymentOrder = async (paymentOrderId) => {
  try {
    const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/BasketTours/DownloadInvoicesByPaymentOrder/${paymentOrderId}`,{responseType: 'blob'}
    );
    return result;
  } catch (e) {
    showError('Error Retriving Invoices');
  }
};
