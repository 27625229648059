import { SectionsView } from '../../Views/Home';
import {
  SectionViewManagement,
  SectionViewReOrder,
} from '../../Views/Home/SectionView/SectionViewUtilities';

export const SectionsRoutes = [
  {
    path: '/add',
    name: 'SectionView.add-new-section',
    component: SectionViewManagement,
    layout: '/home/sections',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SectionView.sections',
        isDisabled: false,
        route: '/home/sections/view',
      },
      {
        name: 'SectionView.add-new-section',
        isDisabled: true,
        route: '/home/sections/add',
      },
    ],
  },
  {
    path: '/edit',
    name: 'home.tours.toursManagementView.edit-tour',
    component: SectionViewManagement,
    layout: '/home/sections',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SectionView.sections',
        isDisabled: false,
        route: '/home/sections/view',
      },
      {
        name: 'SectionView.edit-section',
        isDisabled: true,
        route: '/home/sections/edit',
      },
    ],
  },
  {
    path: '/re-order',
    name: 'home.tours.toursManagementView.edit-tour',
    component: SectionViewReOrder,
    layout: '/home/sections',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SectionView.sections',
        isDisabled: false,
        route: '/home/sections/view',
      },
      {
        name: 'SectionView.re-order',
        isDisabled: true,
        route: '/home/sections/re-order',
      },
    ],
  },
  {
    path: '/view',
    name: 'SectionView.sections',
    component: SectionsView,
    layout: '/home/sections',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 5,
    order: 8,
    icon: 'mdi mdi-calendar-star',
    iconActive: 'mdi mdi-calendar-star c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SectionView.sections',
        isDisabled: false,
        route: '/home/sections',
      },
    ],
  },
];
