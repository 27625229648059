import React, { useCallback, useEffect } from 'react';
import { Button, Grid, Typography, CardContent, Card } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { getTranslate } from ' ./../../src/Helpers';
import './CarsCard.scss';
import { CarsSlider } from './../CarsSlider/CarsSlider';
import AirlineSeatLegroomExtraIcon from '@material-ui/icons/AirlineSeatLegroomExtra';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';
import CommuteIcon from '@material-ui/icons/Commute';
import { getDownloadableLink } from '../../../../../Helpers';
import { useEventListener } from '../../../../../Helpers';
import { LoadableImageComponant, NoSearchResultComponent } from '../../../../../Componentes';

const CarsCard = (props) => {
  const openDleteDialogslider = useCallback(() => {
    props.openDialogDelete();
  }, [props]);

  const openEditDialogslider = useCallback(() => {
    props.reloadData();
  }, [props]);

  const CarsSliderClicked = useCallback(
    (item) => {
      sideMenuComponentUpdate(
        <CarsSlider
          setSearch={item}
          openDleteDialogslider={openDleteDialogslider}
          openEditDialogslider={openEditDialogslider}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [openDleteDialogslider, openEditDialogslider]
  );

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const openslid = (CarDetails) => {
    CarsSliderClicked(CarDetails);
  };

  const loadMoreData = useCallback(() => {
    if (
      props.Response &&
      props.Response.result &&
      props.Response.totalCount > props.Response.result.length &&
      (props.page + 1) * props.rowsPerPage <= props.Response.result.length
    )
      props.setPage(props.page + 1);
  }, [props]);

  const scrollHandler = () => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - 5
    )
      loadMoreData();
  };

  useEventListener('scroll', scrollHandler);
  useEffect(() => {
    if (document.body.scrollHeight <= document.body.clientHeight && !props.isLoading)
      loadMoreData();
    return () => {};
  }, [loadMoreData, props.isLoading]);

  const translationPath = 'Cars.CarsCard.';
  return (
    <>
      <div className='section px-2 sliderView'>
        <Grid container spacing={3} className='slideWrapper'>
          {props.Response &&
          props.Response.totalCount === 0 &&
          props.search.length !== 0 &&
          !props.isFirstLoad ? (
            <NoSearchResultComponent />
          ) : (
            <>
              {props.Response &&
                props.Response.result &&
                props.Response.result.map((item, i) => (
                  <Grid item lg={3} sm={12} xl={3} xs={12} key={i} className='sliderCard'>
                    <Card
                      onClick={() => {
                        openslid(item);
                        props.setcarsid(item.carTypesId);
                        props.setCarNAME(item.typeName);
                      }}>
                      <CardContent>
                        <Grid
                          item
                          xs={12}
                          container
                          justify='center'
                          alignItems='center'
                          spacing={1}>
                          <LoadableImageComponant
                            classes={'imgcar'}
                            alt={'imgcar'}
                            src={getDownloadableLink(item.imageFileId)}
                          />
                        </Grid>
                        <Grid container>
                          <Grid item xs={11} container spacing={1}>
                            <Grid item xs={12} className='sliderName'>
                              <Typography className='labelcard'>
                                <p className='mainTitle'>
                                  {item.typeName}</p>
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={
                                item.status.toUpperCase() === 'active'.toUpperCase()
                                  ? 'active-slider'
                                  : 'inactiveSlider'
                              }>
                              <Typography className='labelcard '>{item.status.toUpperCase() === 'active'.toUpperCase()? getTranslate()(`${translationPath}Active`): getTranslate()(`${translationPath}InActive`)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <div>
                                <DriveEtaIcon className='iconcarcard' />
                                <span className='labelcard'>
                                  {getTranslate()(`${translationPath}CarModel`)}
                                </span>
                                {'  '}:{'  '}
                                {item.carModel}
                              </div>
                              <div>
                                <AirlineSeatLegroomExtraIcon className='iconcarcard' />
                                <span className='labelcard'>
                                  {getTranslate()(`${translationPath}noOfSets`)}
                                </span>
                                {'  '}: {'  '} {item.noOfSets}
                              </div>
                              <div>
                                <NotListedLocationIcon className='iconcarcard' />
                                <span className='labelcard'>
                                  {getTranslate()(`${translationPath}distence1KPrice`)}
                                </span>
                                {'  '}:{'  '}
                                {item.distence1KPrice}
                              </div>
                              <div>
                                <CommuteIcon className='iconcarcard' />
                                <span className='labelcard'>
                                  {getTranslate()(`${translationPath}noOfCars`)}
                                </span>
                                {'  '} : {'  '} {item.noOfCars}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid item xs={0} sm={-5} className='sliderActions'>
                            <Button
                              title='Add Cars'
                              className='mdi mdi-car-2-plus'
                              onClick={(e) => {
                                props.setIsEdit(false);
                                props.openDialogAddEditCar();
                                props.setcarsid(item.carTypesId);
                              }}
                            />
                            <Button
                              className='mdi mdi-file-document-edit-outline'
                              title='Edit This Car'
                              onClick={(e) => {
                                props.setIsEdit(true);
                                props.setstatecar(item);
                                props.setCarDetails(item);
                                props.setDialogAddCar(true);
                              }}
                            />
                            <Button
                              className='mdi mdi-delete-outline'
                              title='Delete This Car'
                              onClick={(e) => {
                                props.openDialogDelete();
                                props.setcarsid(item.carTypesId);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </div>
    </>
  );
};
export default CarsCard;
