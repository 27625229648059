import PickFormBuilder from "../../Views/formbuilder/PickFormBuilder";
import FormEditBuilder from "../../Views/formbuilder";

export const FormBuilderRoutes = [
  {
    id: 18,
    path: "/Form",
    name: "SideMenuView.FormBuilder",
    component: PickFormBuilder,
    layout: "/home/FormBuilder",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 10,
    order: 1,
    icon: "icons i-box-circle-white",
    iconActive: "icons i-contacts-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "SideMenuView.FormBuilder",
        isDisabled: false,
        route: "/home/FormBuilder/Form",
      },
    ],
  },
  {
    id: 19,
    path: "/FormEdit",
    name: "SideMenuView.FormBuilder",
    component: FormEditBuilder,
    layout: "/home/FormBuilder",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 10,
    order: 1,
    icon: "icons i-box-circle-white",
    iconActive: "icons i-box-circle-blue",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "SideMenuView.FormBuilder",
        isDisabled: false,
        route: "/home/FormBuilder/Form",
      },
      {
        name: "SideMenuView.FormBuilder",
        isDisabled: false,
        route: "/home/FormBuilder/FormEdit",
      },
    ],
  },
];
