import React, { useEffect, useRef, useState } from 'react';
import './ScreenDialog.scss';
import {
  CreateScreen,
  UpdateScreen,
  DeleteScreen,
  UploadSlidePicture,
} from '../../../../../Serviecs/SliderViewServices/SliderViewServices';
import { showSuccess } from '../../../../../Helpers';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Joi from 'joi';
import { getDownloadableLink } from '../../../../../Helpers';
import { DeleteSlider } from '../../../../../Serviecs/SliderViewServices/SliderViewServices';
import { getTranslate, showError, getErrorByName } from '../../../../../Helpers';
import { CropperComponent, Inputs } from './../../../../../Componentes';
import {
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Dialog,
  FormControl,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

const AddScreensDialog = (props) => {
  const [screenName, setScreenName] = useState('');
  const [screenDescription, setScreenDescription] = useState('');
  const [result, setResult] = useState('');
  const [slideIndex, setSlideIndex] = useState(0);
  const [editImage, setEditImage] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [doneBtn, setDoneBtn] = useState(false);
  const [viewUploadeImage, setviewUploadeImage] = useState('');

  const hiddenFileInput = useRef(null);
  const translationPath = 'SliderView.';

  const schema = Joi.object({
    screenName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}screen-name-is-required`),
      }),
    screenDescription: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}screen-description-is-required`),
      }),
    uploadImage: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}image-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ screenName, screenDescription, uploadImage });

  useEffect(() => {
    setIsSubmitted(false);
    setEditImage(false);
    if (props.isEdit) {
      if (props.item.screens[slideIndex]) {
        setEditImage(true);
        setScreenName(props.item.screens[slideIndex].screenTitle);
        setScreenDescription(props.item.screens[slideIndex].screenDescription);
        setUploadImage('Edit Image');
        setResult(props.item.screens[slideIndex].publicPreviewScreenImage);
      } else {
        setScreenName('');
        setUploadImage('');
        setResult('');
        setScreenDescription('');
      }
    } else {
      setEditImage(false);
      setUploadImage('');
      setScreenName('');
      setScreenDescription('');
      setResult('');
    }
    props.setReloaded(false);
  }, [
    props,
    props.isEdit,
    props.item.endDate,
    props.item.screens,
    props.item.startDate,
    slideIndex,
  ]);

  const selectedFileHandler = async (e) => {
    setResult('');
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    setUploadImage(e.target.files[0].name);
    setResult(await UploadSlidePicture(formData));
  };

  const viewimage = (e) => {
    selectedFileHandler(e);
    const files = e.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      const dataURL = e.target.result;
      setviewUploadeImage(dataURL);
    };
    setEditImage(true);
  };

  const handleNextBtn = async () => {
    setEditImage(false);
    setIsDisabled(false);
    setDoneBtn(false);
    setIsSubmitted(false);
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }

    if (props.isEdit) {
      if (props.item.screens[slideIndex]) {
        await UpdateScreen(props.item.screens[slideIndex].screenId, {
          screenTitle: screenName,
          screenDescription: screenDescription,
          screenImage: croppedImage ? croppedImage.uuid : result,
          publicPreviewScreenImage: result.uuid ? result.uuid : result,
          sliderId: props.sliderInfo.sliderId,
        });
        if (!props.item.screens[slideIndex + 1]) {
          setEditImage(false);
          setScreenName('');
          setScreenDescription('');
          setResult('');
          setUploadImage('Upload Image');
        }
      } else {
        await CreateScreen({
          screenTitle: screenName,
          screenDescription: screenDescription,
          screenImage: croppedImage.uuid,
          publicPreviewScreenImage: result.uuid ? result.uuid : result,
          sliderId: props.sliderInfo.sliderId,
        });
        setEditImage(false);
        setCroppedImage(null);
        setScreenName('');
        setScreenDescription('');
        setResult('');
      }
      setSlideIndex(slideIndex + 1);
    } else {
      await CreateScreen({
        screenTitle: screenName,
        screenDescription: screenDescription,
        screenImage: croppedImage.uuid,
        publicPreviewScreenImage: result.uuid ? result.uuid : result,
        sliderId: props.sliderInfo.sliderId,
      });
      setScreenName('');
      setCroppedImage(null);
      setScreenDescription('');
      setResult('');
    }
  };

  const handleDoneBtn = async () => {
    setIsSubmitted(false);
    setIsDisabled(true);
    setUploadImage('Upload Image');
    props.setOpen(false);
    props.reloadData();
    setSlideIndex(0);
    setCroppedImage(null);
    setEditImage(false);
    setScreenName('');
    setScreenDescription('');
    setResult('');
    props.setReloaded(false);
    if (!props.addScreen) {
      showSuccess(`${getTranslate()(`${translationPath}SliderCreatedSuccessfully`)}`);
    }
  };

  const handleCancelBtn = async () => {
    setIsSubmitted(false);
    let response;
    if (!props.isEdit && !props.addScreen) {
      response = await DeleteSlider(props.sliderInfo.sliderId);
      if (response) showError(`${translationPath}${response}`);
      else {
        showSuccess(`${translationPath}slider-deleted-successfully`);
        props.reloadData();
      }
    }
    props.setOpen(false);
    setIsDisabled(true);
    setSlideIndex(0);
    setCroppedImage(null);
    setEditImage(false);
    setScreenName('');
    setScreenDescription('');
    setResult('');
    if (response) return;
    props.reloadData();
  };

  const handleDeleteBtn = async () => {
    setIsSubmitted(false);
    setIsDisabled(true);
    const result = await DeleteScreen(props.item.screens[slideIndex].screenId);
    if (result) showError(`${translationPath}${result}`);
    else {
      showSuccess(`${translationPath}slider-screen-deleted-successfully`);
      setSlideIndex(slideIndex + 1);
      setCroppedImage(null);
    }
  };

  return (
    <Dialog
      open={props.open}
      className='sliderDialogs'
      maxWidth={editImage && result ? 'md' : 'sm'}>
      <form noValidate onSubmit={(e) => e.preventDefault()}>
        <DialogTitle className='dialogTitle'>
          <Typography>
            {!props.addScreen ? props.sliderInfo.sliderName + '/' + screenName : 'Add New Screen'}
          </Typography>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid container justify='center'>
            <Grid container item xs={editImage && result ? 6 : 12} justify='center'>
              <Grid item xs={8} className='mb-3'>
                <FormControl className='input-wrapper flex-wrap textField'>
                  <Inputs
                    helperText={getErrorByName(schema, 'screenName').message}
                    error={getErrorByName(schema, 'screenName').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    inputProps={{ maxLength: 50 }}
                    idRef='screenNameRef'
                    value={screenName}
                    onInputChanged={(e) => setScreenName(e.target.value)}
                    labelValue={getTranslate()(`${translationPath}Title`)}
                    inputPlaceholder={getTranslate()(`${translationPath}Title`)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} className='mb-3'>
                <FormControl className='input-wrapper flex-wrap textField'>
                  <Inputs
                    helperText={getErrorByName(schema, 'screenDescription').message}
                    error={getErrorByName(schema, 'screenDescription').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    idRef='screenDescriptionRef'
                    value={screenDescription}
                    onInputChanged={(e) => setScreenDescription(e.target.value)}
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 300 }}
                    labelValue={getTranslate()(`${translationPath}SubTitle`)}
                    inputPlaceholder={getTranslate()(`${translationPath}SubTitle`)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} className='mb-3'>
                <FormControl className='input-wrapper flex-wrap textField'>
                  <Typography>{getTranslate()(`${translationPath}UploadImage`)}</Typography>
                  <TextField
                    helperText={
                      (isSubmitted && getErrorByName(schema, 'uploadImage').message) || ''
                    }
                    error={isSubmitted ? getErrorByName(schema, 'uploadImage').error : false}
                    id='uploadImage'
                    disabled
                    value={uploadImage}
                    size='small'
                    variant='outlined'
                    fullWidth
                    placeholder={
                      props.isEdit
                        ? `${getTranslate()(`${translationPath}EditImage`)}`
                        : `${getTranslate()(`${translationPath}UploadImage`)}`
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {result && (
                            <>
                              <IconButton
                                onClick={() => {
                                  setIsSubmitted(true);
                                  setUploadImage('');
                                  setResult('');
                                  setEditImage(false);
                                  setCroppedImage(null);
                                }}>
                                <ClearRoundedIcon />
                              </IconButton>
                            </>
                          )}
                          {!result && (
                            <>
                              <Button
                                className='btns'
                                onClick={() => hiddenFileInput.current.click()}>
                                {getTranslate()(`${translationPath}BrowseImage`)}
                              </Button>
                              <input
                                type='file'
                                accept='image/*'
                                ref={hiddenFileInput}
                                onChange={(e) => viewimage(e)}
                                id='upload-photo'
                              />
                            </>
                          )}
                        </InputAdornment>
                      ),
                    }}></TextField>
                  {!result && (
                    <span className='imageUpload'>
                      {getTranslate()(`${translationPath}NoImageUploaded`)}
                    </span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={6} container>
              {editImage && (
                <CropperComponent
                  cropperImage={
                    props.isEdit
                      ? result.uuid
                        ? viewUploadeImage
                        : getDownloadableLink(result)
                      : viewUploadeImage
                  }
                  initialAspectRatio={6 / 19}
                  cropperImageChanged={(croppedImage) => {
                    setCroppedImage(croppedImage);
                    setEditImage(false);
                    setDoneBtn(true);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item xs={8} justify='space-around'>
            <Grid item className='cancelBtn'>
              <Button className='btns' onClick={handleCancelBtn}>
                {getTranslate()(`${translationPath}Cancel`)}
              </Button>
            </Grid>
            {doneBtn && (
              <>
                <Grid item>
                  <Button
                    disabled={
                      croppedImage === null ||
                      screenName === '' ||
                      result === '' ||
                      screenDescription === ''
                    }
                    onClick={handleNextBtn}
                    variant='contained'
                    className='btns theme-solid'>
                    {getTranslate()(`${translationPath}Save&Continue`)}
                  </Button>
                </Grid>
                {props.isEdit && (
                  <Grid item xs={12} className='deleteBtn'>
                    <Button className='btns' onClick={handleDeleteBtn}>
                      {getTranslate()(`${translationPath}Delete`)}
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogActions>
        <div
          className={
            props.isEdit
              ? screenName === '' ||
                croppedImage === null ||
                result === '' ||
                screenDescription === ''
                ? 'DisableddoneBtn'
                : 'doneBtnContainer'
              : isDisabled
              ? 'DisableddoneBtn'
              : 'doneBtnContainer'
          }>
          <Grid container justify='center'>
            <Grid item xs={12} className='doneBtn'>
              <Button
                disabled={
                  props.isEdit
                    ? screenName === '' ||
                      result === '' ||
                      croppedImage === null ||
                      screenDescription === ''
                    : isDisabled
                }
                className='btns theme-solid'
                onClick={handleDoneBtn}>
                {getTranslate()(`${translationPath}Done`)}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </Dialog>
  );
};

export { AddScreensDialog };
