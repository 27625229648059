import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';
import { showError } from '../../Helpers';

export const GetAllAdvertisements = async (pageIndex, pageSize, searchedItem) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Advertisement/GetAllAdvertisements?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchedItem}`
    );
    return result;
  } catch (e) {
    showError('Error Retriving Advertisements');
  }
};

export const GetAllTours = async (pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Tours/GetAllTours?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const CreateAdvertisements = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Advertisement/CreateAdvertisement`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Creating Advertisement');
  }
};

export const EditAdvertisements = async (advertisementId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Advertisement/${advertisementId}`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Editing Advertisements');
  }
};

export const DeleteAdvertisements = async (advertisementId) => {
  // try {
  const result = await HttpServices.delete(
    `${config.serverAddress}/PSTViewing/Advertisement/${advertisementId}`
  )
    .then(() => {
      return;
    })
    .catch((err) => {
      return (
        (err.response && err.response.data && err.response.data.Message) ||
        'advertisement-delete-failed'
      );
    });
  return result;
  // } catch (e) {
  // showError('Error Deleting Advertisements');
  // }
};

export const UploadAdsImage = async (body) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/FileManager/File/Upload`, body);
    return result;
  } catch (e) {
    showError('Error Uploading Advertisement Image');
  }
};
