import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';
import { showError } from '../../Helpers';

export const GetAllAgents = async (pageIndex, pageSize, searchedItem) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Screen/GetAllSliders?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchedItem}`
    );
    return result;
  } catch (e) {
    showError('Error Retriving Agents');
  }
};
export const GetAllAgentBookedTour = async (
  pageIndex,
  pageSize,
  // { orderType, columnName, fromDate, toDate, search }
  body
) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Tours/GetAllAgentBookedTour?pageIndex=${
        pageIndex + 1
      }&pageSize=${pageSize}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
export const GetAllBookedTourByAgent = async (
  userId,
  // { fromDate, toDate }
  body
) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Tours/GetAllBookedTourByAgent/${userId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
