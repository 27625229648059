export const DashboardTabEnum = {

   All: {
      value: 0
    },
    Sales:{
      value: 1
    },
    Tours:{
      value: 2
    },
    Users:{
      value: 3
    },
    Cars:{
      value: 4
    },
}
