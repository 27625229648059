import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getErrorByName, getTranslate } from '../../../../../../Helpers';
import {
  DateRangePickerComponent,
  Inputs,
  SelectComponet,
  TimeRangePickerComponent,
} from '../../../../../../Componentes';
import { ButtonBase, FormControlLabel, Switch } from '@material-ui/core';
import moment from 'moment';
import './OfferFormComponent.scss';

export const OfferFormComponent = ({
  state,
  schema,
  onStateChanged,
  isSubmitted,
  isNext,
  translationPath,
}) => {
  const [selectedDiscountType, setSelectedDiscountType] = useState(1);
  const [discountTypes] = useState([
    { key: 1, value: '%' },
    { key: 2, value: '$' },
  ]);
  const discountTypeInit = useCallback(() => {
    if (state.discountValue !== null && selectedDiscountType !== 2) setSelectedDiscountType(2);
    else if (
      (state.discountPercentage !== null || state.discountLimit !== null) &&
      selectedDiscountType !== 1
    )
      setSelectedDiscountType(1);
  }, [selectedDiscountType, state.discountLimit, state.discountPercentage, state.discountValue]);
  useEffect(() => {
    discountTypeInit();
  }, [state.discountValue, state.discountLimit, state.discountPercentage, discountTypeInit]);
  return (
    <div className={`offer-form-component-wrapper${(!isNext && ' is-next') || ''}`}>
      <div className='form-section'>
        <span className='d-flex mb-2 fw-bold'>
          {getTranslate()(`${translationPath}offer-information`)}
        </span>
        <span className='d-flex mb-3 c-gray-secondary'>
          {getTranslate()(`${translationPath}offer-information-description`)}
        </span>
        <div className='form-wrapper'>
          <div className='form-item'>
            <Inputs
              idRef='nameRef'
              labelValue='offer-name'
              translationPath={translationPath}
              isWithError
              helperText={getErrorByName(schema, 'name').message}
              inputPlaceholder='offer-name'
              isSubmitted={isSubmitted}
              error={getErrorByName(schema, 'name').error}
              value={state.name || ''}
              onInputChanged={(event) => {
                if (onStateChanged) onStateChanged('name', event.target.value);
              }}
            />
          </div>
          <div className='form-item'>
            <DateRangePickerComponent
              ranges={[
                {
                  startDate:
                    (state.startDateTime &&
                      moment(state.startDateTime).toDate()) ||
                    null,
                  endDate:
                    (state.endDateTime &&
                      moment(state.endDateTime).toDate()) ||
                    null,
                  key: 'selection',
                },
              ]}
              labelValue='offer-date-range'
              emptyLabel='select-date'
              minDate={moment().toDate()}
              helperText={getErrorByName(schema, 'startDateTime').message}
              isSubmitted={isSubmitted}
              startDateError={getErrorByName(schema, 'startDateTime').error}
              endDateError={getErrorByName(schema, 'endDateTime').error}
              translationPath={translationPath}
              onDateChanged={(selectedDate) => {
                if (!onStateChanged) return;
                onStateChanged(
                  'startDateTime',
                  (selectedDate.selection.startDate &&
                    moment(
                      (moment(selectedDate.selection.startDate).isAfter() &&
                        selectedDate.selection.startDate) ||
                        moment().add(10, 'minute')
                    ).format('YYYY-MM-DDTHH:mm:ssZ')) ||
                    null
                );
                onStateChanged(
                  'endDateTime',
                  (selectedDate.selection.endDate &&
                    moment(
                      (moment(selectedDate.selection.endDate).isAfter() &&
                        selectedDate.selection.endDate) ||
                        moment().add(10, 'minute')
                    ).format('YYYY-MM-DDTHH:mm:ssZ')) ||
                    null
                );
              }}
            />
          </div>
          <div className='form-item'>
            <TimeRangePickerComponent
              range={{
                startTime: state.startDateTime,
                endTime: state.endDateTime,
              }}
              isWideOvel
              rangeChanged={(range) => {
                if (!onStateChanged) return;
                onStateChanged(
                  'startDateTime',
                  (range.startTime && moment(range.startTime).format('YYYY-MM-DDTHH:mm:ssZ')) || null
                );
                onStateChanged(
                  'endDateTime',
                  (range.endTime && moment(range.endTime).format('YYYY-MM-DDTHH:mm:ssZ')) || null
                );
              }}
              maxDate={state.endDateTime}
              minDate={state.startDateTime}
            />
          </div>
          <div className='form-item'>
            <Inputs
              idRef='descriptionRef'
              labelValue='description'
              translationPath={translationPath}
              isWithError
              helperText={getErrorByName(schema, 'description').message}
              inputPlaceholder='description'
              isSubmitted={isSubmitted}
              error={getErrorByName(schema, 'description').error}
              value={state.description || ''}
              onInputChanged={(event) => {
                if (onStateChanged) onStateChanged('description', event.target.value);
              }}
              multiline
              rows={4}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={state.isHot}
                  classes={{
                    checked: 'c-secondary',
                    track: (state.isHot && 'bg-secondary') || undefined,
                  }}
                  onClick={() => {
                    if (onStateChanged) onStateChanged('isHot', !state.isHot);
                  }}
                />
              }
              label={getTranslate()(`${translationPath}is-hot`)}
            />
          </div>
        </div>
      </div>
      <div className='form-section'>
        <span className='d-flex mb-2 fw-bold'>{getTranslate()(`${translationPath}discounts`)}</span>
        <span className='d-flex mb-3 c-gray-secondary'>
          {getTranslate()(`${translationPath}discounts-description`)}
        </span>
        <div className='form-wrapper'>
          <div className='form-item p-relative'>
            <Inputs
              idRef='tourDiscountRef'
              labelValue={`${(selectedDiscountType === 1 && 'discount-percentage') || 'discount'}`}
              translationPath={translationPath}
              isWithError
              helperText={
                getErrorByName(
                  schema,
                  (selectedDiscountType === 1 && 'discountPercentage') || 'discountValue'
                ).message
              }
              inputPlaceholder='add-number'
              isSubmitted={isSubmitted}
              error={
                getErrorByName(
                  schema,
                  (selectedDiscountType === 1 && 'discountPercentage') || 'discountValue'
                ).error
              }
              type='number'
              value={
                (selectedDiscountType === 1 ? state.discountPercentage : state.discountValue) || ''
              }
              onInputChanged={(event) => {
                const floatHandler = () => {
                  const price = event.target.value.toString().split('.');
                  if (price.length === 2 && price[1].length > 3)
                    return Number(event.target.value).toFixed(3);
                  else return Number(event.target.value);
                };
                onStateChanged(
                  (selectedDiscountType === 1 && 'discountPercentage') || 'discountValue',
                  floatHandler()
                );
              }}
            />
            <div className='on-input-select'>
              <SelectComponet
                idRef='discountTypeRef'
                data={discountTypes}
                valueInput='key'
                textInput='value'
                value={selectedDiscountType}
                onSelectChanged={(event) => {
                  setSelectedDiscountType(+event.target.value);
                  if (+event.target.value === 1) onStateChanged('discountValue', null);
                  if (+event.target.value === 2) {
                    onStateChanged('discountPercentage', null);
                    onStateChanged('discountLimit', null);
                  }
                }}
              />
            </div>
          </div>
          {selectedDiscountType === 1 && (
            <div className='form-item'>
              <Inputs
                idRef='maxDiscountRef'
                labelValue='max-discount'
                translationPath={translationPath}
                isWithError
                helperText={getErrorByName(schema, 'discountLimit').message}
                inputPlaceholder='add-number'
                type='number'
                overInputText='AED'
                isSubmitted={isSubmitted}
                error={getErrorByName(schema, 'discountLimit').error}
                value={state.discountLimit || ''}
                onInputChanged={(event) => {
                  const floatHandler = () => {
                    const price = event.target.value.toString().split('.');
                    if (price.length === 2 && price[1].length > 3)
                      return Number(event.target.value).toFixed(3);
                    else return Number(event.target.value);
                  };
                  onStateChanged('discountLimit', floatHandler());
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className='form-item'>
        <ButtonBase type='submit' className='btns theme-solid'>
          <span>{getTranslate()(`${translationPath}next`)}</span>
        </ButtonBase>
      </div>
    </div>
  );
};

OfferFormComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isNext: PropTypes.bool.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
};
