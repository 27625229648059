import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AutocompleteComponent,
  DateRangePickerComponent,
  DialogComponent,
  NoContentComponent,
  NoDataFoundComponent,
  SelectComponet,
  Spinner,
} from '../../../../Componentes';
import { ActionsEnum } from '../../../../Enums';
import {
  getTranslate,
  showError,
  showSuccess,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from '../../../../Helpers';
import { useTitle } from '../../../../Hooks/useTitle';
import {
  GetAllCarCativities,
  DeleteCarActivity,
  lookupItemsGetId,
  SearchCars,
} from '../../../../Serviecs';
import {
  CarActivitiesCardComponent,
  CarActivityDetailsComponent,
  CarActivityManagementDialog,
} from './CarActivitiesUtilities';
import Lookups from '../../../../Assets/JSON/StaticLookupsIds.json';
import './CarActivitiesView.scss';

const translationPath = 'home.mobilePages.carActivitiesView.';
export const CarActivitiesView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenManagement, setIsOpenManagement] = useState(false);
  const [activityTypes, setActivityTypes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [cars, setCars] = useState([]);
  const [carNumber, setCarNumber] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const filterDefault = {
    pageSize: 10,
    pageIndex: 0,
    carsId: null,
  };
  const filterByDefault = {
    ActivityTypeId: '',
    ActivityId: '',
    dateRange: {
      startDate: null,
      endDate: null,
    },
  };
  const [filter, setFilter] = useState(filterDefault);
  const [filterBy, setFilterBy] = useState(filterByDefault);
  const [carActivities, setCarActivities] = useState({ result: [], totalCount: 0 });
  const searchTimer = useRef(null);
  useTitle(getTranslate()(`${translationPath}car-activities`));
  const onActionClicked = useCallback(
    (actionEnum, item) => async (event) => {
      event.stopPropagation();
      if (actionEnum === ActionsEnum.delete.key) {
        setActiveItem(item);
        setIsOpenConfirm(true);
      }
      if (actionEnum === ActionsEnum.edit.key) {
        setActiveItem(item);
        setIsOpenManagement(true);
      }
    },
    []
  );
  const getActivityTypes = useCallback(async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: Lookups.ActivityType,
    });
    setActivityTypes(res);
    setIsLoading(false);
  }, []);
  const getActivities = useCallback(async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: Lookups.Activity,
      lookupParentId: (filterBy.ActivityTypeId !== -1 && filterBy.ActivityTypeId) || null,
    });
    setActivities(res);
    setIsLoading(false);
  }, [filterBy.ActivityTypeId]);
  const getAllCarActivities = useCallback(async () => {
    setIsLoading(true);
    const response = await GetAllCarCativities({
      ...filter,
      ...filterBy,
    });
    if (filter.pageIndex === 0) {
      setCarActivities({
        result: (response && response.result) || [],
        totalCount: (response && response.totalCount) || 0,
      });
    } else {
      setCarActivities((item) => ({
        result: (item.result && item.result.concat((response && response.result) || [])) || [],
        totalCount: (response && response.totalCount) || 0,
      }));
    }
    setIsLoading(false);
  }, [filter, filterBy]);
  const getSearchCars = useCallback(async () => {
    setIsLoading(true);
    const res = await SearchCars(1, 10, { carNumber });
    setCars((res && res.result) || []);
    setIsLoading(false);
  }, [carNumber]);
  const searchHandler = (e) => {
    const value = e.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setCarNumber(value);
    }, 700);
  };
  useEffect(() => {
    getSearchCars();
  }, [getSearchCars, carNumber]);
  const onDeleteClicked = () => {
    setIsOpenConfirm(true);
  };
  const onCardClicked = (item) => {
    setActiveItem(item);
    sideMenuComponentUpdate(
      <CarActivityDetailsComponent
        activeItem={item}
        onDeleteClicked={onDeleteClicked}
        translationPath={translationPath}
      />
    );
    sideMenuIsOpenUpdate(true);
  };
  const deleteHandler = useCallback(async () => {
    setIsLoading(true);
    const res = await DeleteCarActivity(activeItem.carActivityId);
    if (res !== undefined) {
      showSuccess(getTranslate()(`${translationPath}car-activity-deleted-successfully`));
      setFilter((items) => ({ ...items, pageIndex: 0 }));
    } else showError(getTranslate()(`${translationPath}car-activity-delete-failed`));
    setIsOpenConfirm(false);
    setIsLoading(false);
  }, [activeItem]);
  const getIsChangedFilter = () =>
    Object.entries(filterByDefault).findIndex(
      (item) =>
        (typeof item[1] === 'object' &&
          item[1] !== null &&
          Object.entries(item[1]).findIndex((subItem) => subItem[1] !== filterBy[subItem[0]]) !==
            -1) ||
        item[1] !== filterBy[item[0]]
    ) !== -1;
  const onLoadMoreHandler = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: item.pageIndex + 1 }));
  }, []);
  const resetFilterHandler = () => {
    setFilter((items) => ({ ...items, pageIndex: 0 }));
    setFilterBy(filterByDefault);
  };
  useEffect(() => {
    getAllCarActivities();
  }, [getAllCarActivities, filter]);
  useEffect(() => {
    getActivityTypes();
  }, [getActivityTypes]);
  useEffect(() => {
    getActivities();
  }, [getActivities, filterBy.ActivityTypeId]);
  useEffect(() => {
    return () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    };
  }, []);
  const addNewCarActivity = () => {
    setActiveItem(null);
    setIsOpenManagement(true);
  };
  return (
    <div className='car-activities-view view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <Button className='btns theme-solid' onClick={addNewCarActivity}>
              {getTranslate()(`${translationPath}add-new-car-activity`)}
            </Button>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <AutocompleteComponent
                idRef='carsIdRef'
                inputPlaceholder='search-car-activities'
                label='filter'
                variant='outlined'
                translationPath={translationPath}
                value={
                  (filter.carsId && cars.find((item) => item.carsId === filter.carsId)) || null
                }
                // overInputTextIcon="mdi mdi-car"
                inputClasses='inputs theme-solid'
                options={cars}
                getOptionLabel={(option) =>
                  `${(option.carTypes && option.carTypes.typeName) || ''} ${
                    option.carTypes && option.carTypes.carModel
                  } ${option.carNumber || ''}`
                }
                multiple={false}
                onKeyUp={searchHandler}
                onChange={(event, newValue) => {
                  setFilter((items) => ({
                    ...items,
                    pageIndex: 0,
                    carsId: (newValue && newValue.carsId) || null,
                  }));
                }}
              />
              {/* <Inputs
                idRef="carActivitySearchRef"
                variant="outlined"
                fieldClasses="inputs theme-solid"
                translationPath={translationPath}
                label="filter"
                beforeIconClasses="mdi mdi-magnify mdi-24px c-gray-primary"
                onKeyUp={searchHandler}
                inputPlaceholder="search-car-activities"
              /> */}
            </div>
          </div>
        </div>
        <div className='second-filter-section pt-2 c-black-light px-2'>
          <div className='section'>
            <span className='text-nowrap px-2'>
              {getTranslate()(`${translationPath}filter-by`)}
            </span>
            <div className='w-100 px-2'>
              <SelectComponet
                idRef='parentActivitiesRef'
                data={activityTypes}
                emptyItem={{
                  value: '',
                  text: 'all-parent-activities',
                }}
                valueInput='lookupItemId'
                textInput='lookupItemName'
                classes='theme-solid filter-changes'
                paddingReverse='2.5rem'
                value={filterBy.ActivityTypeId}
                onSelectChanged={(event) => {
                  setFilter((items) => ({ ...items, pageIndex: 0 }));
                  setFilterBy((items) => ({
                    ...items,
                    ActivityTypeId: event.target.value,
                    ActivityId: '',
                  }));
                }}
                overInputTextIcon='mdi mdi-bullhorn-outline px-1'
                translationPath={translationPath}
              />
            </div>
          </div>
          <div className='section'>
            <div className='w-100 px-2'>
              <SelectComponet
                idRef='activitiesRef'
                data={activities}
                emptyItem={{
                  value: '',
                  text: 'activities',
                }}
                valueInput='lookupItemId'
                textInput='lookupItemName'
                classes='theme-solid filter-changes'
                paddingReverse='2.5rem'
                value={filterBy.ActivityId}
                onSelectChanged={(event) => {
                  setFilter((items) => ({ ...items, pageIndex: 0 }));
                  setFilterBy((items) => ({ ...items, ActivityId: event.target.value }));
                }}
                overInputTextIcon='mdi mdi-bullhorn-outline px-1'
                translationPath={translationPath}
              />
            </div>
          </div>
          <div className='section px-2'>
            <DateRangePickerComponent
              isWideOvel
              ranges={[filterBy.dateRange]}
              onDateChanged={(selectedDate) => {
                setFilter((items) => ({ ...items, pageIndex: 0 }));
                setFilterBy((items) => ({
                  ...items,
                  dateRange: selectedDate.selection,
                }));
              }}
            />
          </div>
          <div className='section px-2'>
            <Button
              className='btns theme-solid'
              disabled={!getIsChangedFilter()}
              onClick={resetFilterHandler}>
              <span>{getTranslate()(`${translationPath}reset`)}</span>
            </Button>
          </div>
        </div>
      </div>
      <NoDataFoundComponent />
      {carActivities.result.length === 0 && !filter.search && <NoContentComponent />}
      <CarActivitiesCardComponent
        data={carActivities}
        filter={filter}
        onLoadMore={onLoadMoreHandler}
        onActionClicked={onActionClicked}
        onCardClicked={onCardClicked}
        translationPath={translationPath}
        isLoading={isLoading}
      />
      {isOpenManagement && (
        <CarActivityManagementDialog
          activeItem={activeItem}
          isOpen={isOpenManagement}
          isOpenChanged={() => {
            setIsOpenManagement(false);
            setActiveItem(null);
          }}
          reloadData={() => {
            setFilter((item) => ({ ...item, pageIndex: 0 }));
            setActiveItem(null);
            setIsOpenManagement(false);
          }}
          translationPath={translationPath}
        />
      )}
      <DialogComponent
        titleText='confirm-message'
        saveText='confirm'
        saveType='button'
        maxWidth='sm'
        dialogContent={
          <div className='d-flex-column-center'>
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span>{getTranslate()(`${translationPath}delete-description`)}</span>
          </div>
        }
        saveClasses='btns theme-solid bg-danger w-100 mb-0 mx-0 br-0'
        isOpen={isOpenConfirm}
        onSaveClicked={deleteHandler}
        translationPath={translationPath}
        onCloseClicked={() => setIsOpenConfirm(false)}
        onCancelClicked={() => setIsOpenConfirm(false)}
      />
    </div>
  );
};
