import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { getDownloadableLink, getTranslate } from '../../../../../../../Helpers';
import './MobileRectangleCardView.scss';
import { LoadableImageComponant } from '../../../../../../../Componentes';

export const MobileRectangleCardView = ({ translationPath, tours }) => {
  const [rating, setRating] = useState(2);

  return (
    <div className='mobile-rectangle-wrapper'>
      <div className='mobile-rectangle-title'>
        {getTranslate()(`${translationPath}recommended-tours`)}
      </div>
      <div className='card-content-wrapper w-100'>
        <div className='mobile-rectangle-content'>
          {tours &&
            tours.result.map((item, i) => (
              <div className='mobile-rectangle-card' key={i}>
                <div className='bottom-card'>
                  <div className='bottom-image'>
                    <LoadableImageComponant
                      src={getDownloadableLink(item.galleryList[0])}
                      alt={item.tourName}
                      classes='img-center'
                    />
                  </div>
                  <div className='bottom-footer'>
                    <div className='card-cart-button'>
                      <IconButton>
                        <span className='mdi mdi-cart-outline' />
                      </IconButton>
                    </div>
                    <div className='points-card-description'>
                      {getTranslate()(`${translationPath}price`)}:
                      <div className='tour-price'>
                        {item.totalFromPriceAfterDiscount}
                        {'  AED'}
                      </div>
                    </div>
                    <div className='rating-wrapper'>
                      <div className='rating-amount'>
                        16 {getTranslate()(`${translationPath}reviews`)}
                      </div>
                      <Rating
                        value={rating}
                        onChange={(event, newValue) => {
                          setRating(newValue);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
