/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback } from 'react';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import { getTranslate, getDownloadableLink, useEventListener } from '../../../../../Helpers';
import { ActionsEnum, DefaultImagesEnum, LoadableImageEnum } from '../../../../../Enums';
import { Button } from '@material-ui/core';
import './TripsCardComponent.scss';
import { LoadableImageComponant, NoSearchResultComponent } from '../../../../../Componentes';

export const TripsCardComponent = ({
  data,
  filter,
  translationPath,
  onLoadMore,
  onCardClicked,
  onActionClicked,
  isLoading,
  isFirstLoad,
}) => {
  const onScrollHandler = useCallback(() => {
    if (
      data &&
      data.result &&
      document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.scrollHeight - 5 &&
      data.result.length < data.totalCount &&
      isLoading !== true
    )
      onLoadMore();
  }, [data, isLoading, onLoadMore]);
  useEventListener('scroll', onScrollHandler);
  useEffect(() => {
    if (
      data &&
      data.result &&
      data.result.length < data.totalCount &&
      document.body.scrollHeight <= document.body.clientHeight &&
      isLoading !== true
    )
      onScrollHandler();
  }, [data, isLoading, onScrollHandler]);
  return (
    <div className='trip-card-conponent-wrapper'>
      {data && data.totalCount === 0 && filter.search && !isFirstLoad ? (
        <NoSearchResultComponent />
      ) : (
        <>
          {data &&
            data.result &&
            data.result.map((item, index) => (
              <div className='trip-card-wrapper' key={`card${index + 1}`}>
                <a onClick={onCardClicked && onCardClicked(item, index)} className='cards-wrapper'>
                  <div className='cards-header'>
                    <div className='item-wrapper'>
                      <span className='item-header'>{item.tripName}</span>
                    </div>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <Rating
                          name='trip-rating'
                          defaultValue={5}
                          value={item.tripRating}
                          precision={0.5}
                          size='small'
                          icon={<span className='mdi mdi-star' />}
                          disabled
                          emptyIcon={<span className='mdi mdi-star-outline' />}
                        />
                      </span>
                      <span className='item-body'>
                        {`${item.numberOfReview} ${getTranslate()(`${translationPath}reviews`)}`}
                      </span>
                    </div>
                  </div>
                  <div className='cards-body'>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-home px-2' />
                        <span>{getTranslate()(`${translationPath}type`)}:</span>
                      </span>
                      <span className='item-body'>{item.tripTypeName}</span>
                    </div>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-clock-time-five px-2' />
                        <span>{getTranslate()(`${translationPath}time`)}:</span>
                      </span>
                      <span className='item-body'>
                        <span>{item.tripDuration}</span>
                        <span className='px-2'>{getTranslate()(`${translationPath}min`)}</span>
                      </span>
                    </div>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-cash-multiple px-2' />
                        <span>{getTranslate()(`${translationPath}price`)}:</span>
                      </span>
                      <span className='item-body'>{item.tripPrice}</span>
                    </div>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-map-marker px-2' />
                        <span>{getTranslate()(`${translationPath}address`)}:</span>
                        <span className='item-body fw-normal mb-0 px-2'>{item.address}</span>
                      </span>
                    </div>
                    <div className='item-wrapper actions'>
                      <Button
                        className='btns theme-outline'
                        onClick={onActionClicked(ActionsEnum.reportEdit.key, item, index)}>
                        <span className='mdi mdi-file-document-edit' />
                      </Button>
                      <Button
                        className='btns theme-outline'
                        onClick={onActionClicked(ActionsEnum.delete.key, item, index)}>
                        <span className='mdi mdi-trash-can-outline' />
                      </Button>
                    </div>
                  </div>
                  <div className='cards-footer'>
                    <LoadableImageComponant
                      classes='item-wrapper trip-cover-image'
                      type={LoadableImageEnum.div.key}
                      alt={getTranslate()(`${translationPath}trip-cover-image`)}
                      src={
                        (item.coverTripImageId && getDownloadableLink(item.coverTripImageId)) ||
                        DefaultImagesEnum.buildings.defaultImg
                      }
                    />
                  </div>
                </a>
              </div>
            ))}
        </>
      )}
    </div>
  );
};
TripsCardComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onCardClicked: PropTypes.func.isRequired,
  onActionClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  translationPath: PropTypes.string,
};
TripsCardComponent.defaultProps = {
  translationPath: 'home.trips.tripsView.TripsCardComponent.',
};
