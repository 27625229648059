import React, { useEffect, useRef, useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';
import {
  getDownloadableLink,
  getHistory as history,
  getTranslate,
  setLogoutAction,
} from '../../../../Helpers';
import { UserMenuView } from './UserMenuView';
import { useOnClickOutside } from '../../../../Hubs';
// import { NotificationsView } from './NotificationsView';
import { EventsView } from './EventsView';
import { CollapseComponent } from '../../../../Componentes';
import { LoginActions } from '../../../../Stores/Actions';
import { bindActionCreators } from 'redux';

const FirstLettersExp = /\b(\w)/gm;

const Header = ({ headerHeightChanged, loginResponse, LogoutAction }) => {
  const headerRef = useRef(null);
  const userProfileRef = useRef(null);
  const notificationsRef = useRef(null);
  const eventsRef = useRef(null);
  const [imageReq, setImageReq] = useState(null);
  setLogoutAction(LogoutAction);
  // eslint-disable-next-line no-unused-vars
  const [notificationsNumber, setNotificationsNumber] = useState(0);
  const [isOpenMenu, setIsOpenMenu] = useState({
    userProfile: false,
    notifications: false,
    events: false,
  });
  const userProfileClicked = () => {
    setIsOpenMenu({ ...isOpenMenu, userProfile: !isOpenMenu.userProfile });
  };
  const notificationsClicked = () => {
    setIsOpenMenu({ ...isOpenMenu, notifications: !isOpenMenu.notifications });
  };
  const eventsClicked = () => {
    setIsOpenMenu({ ...isOpenMenu, events: !isOpenMenu.events });
  };
  const logoutClicked = () => {
    localStorage.removeItem('session');
    // dispatch(LoginActions.logout());
    LogoutAction();
    setTimeout(() => {
      history().push('/account/login');
    }, 100);
  };
  setLogoutAction(logoutClicked);
  useOnClickOutside(userProfileRef, () =>
    setIsOpenMenu((item) =>
      item.userProfile
        ? {
            ...item,
            userProfile: false,
          }
        : item
    )
  );
  useOnClickOutside(notificationsRef, () =>
    setIsOpenMenu((item) =>
      item.notifications
        ? {
            ...item,
            notifications: false,
          }
        : item
    )
  );
  useOnClickOutside(eventsRef, () =>
    setIsOpenMenu((item) =>
      item.events
        ? {
            ...item,
            events: false,
          }
        : item
    )
  );
  useEffect(() => {
    headerHeightChanged(headerRef.current.clientHeight);
  }, [headerHeightChanged]);
  useEffect(() => {
    if (loginResponse) setImageReq(loginResponse);
  }, [loginResponse]);
  return (
    <div className='header-wrapper' ref={headerRef}>
      <div className='section w-100'>
        <span
          role='img'
          aria-label={getTranslate()('home.headerView.PIS-logo')}
          className='img-logo'
        />
        <div className='d-flex-center'>
          <span className='separator-v p-25px mx-3' />
          <Input
            id='headerSearchRef'
            className='inputs theme-transparent'
            placeholder={getTranslate()('home.headerView.search-anything')}
            startAdornment={
              <InputAdornment position='start'>
                <span className='mdi mdi-magnify' />
              </InputAdornment>
            }
          />
        </div>
      </div>
      <div className='section'>
        <div className='p-relative' ref={eventsRef}>
          <ButtonBase
            className={`btns-icon${isOpenMenu.events ? ' btns-active' : ''}`}
            onClick={eventsClicked}>
            <span className='mdi mdi-alarm' />
          </ButtonBase>
          <EventsView
            isOpen={isOpenMenu.events}
            top={headerRef.current ? headerRef.current.clientHeight : 60}
          />
        </div>
        <div className='p-relative' ref={notificationsRef}>
          <ButtonBase
            className={`btns-icon${isOpenMenu.notifications ? ' btns-active' : ''}`}
            onClick={notificationsClicked}>
            <Badge badgeContent={notificationsNumber} className='badges' max={99}>
              <span className='mdi mdi-bell-ring-outline' />
            </Badge>
          </ButtonBase>
        </div>
        <div className='p-relative' ref={userProfileRef}>
          <ButtonBase
            className={`btns-icon${isOpenMenu.userProfile ? ' btns-active' : ''}`}
            onClick={userProfileClicked}>
            {loginResponse && loginResponse.fullName && (!imageReq || !imageReq.profileImg) && (
              <Avatar className='avatars-wrapper theme-small'>
                {loginResponse.fullName.match(FirstLettersExp).join('')}
              </Avatar>
            )}
            {imageReq && imageReq.profileImg && (
              <img
                src={getDownloadableLink(imageReq.profileImg)}
                alt={getTranslate()('home.headerView.user-image')}
              />
            )}
          </ButtonBase>
          <CollapseComponent
            isOpen={isOpenMenu.userProfile}
            top={headerRef.current ? headerRef.current.clientHeight : 60}
            component={<UserMenuView logout={logoutClicked} />}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const {
    LoginReducer: { loginResponse },
  } = state;
  return {
    loginResponse,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    LogoutAction: bindActionCreators(LoginActions.logout, dispatch),
  };
}
const view = connect(mapStateToProps, mapDispatchToProps)(Header);
Header.propTypes = {
  headerHeightChanged: PropTypes.func.isRequired,
  loginResponse: PropTypes.shape(undefined),
};
Header.defaultProps = {
  loginResponse: null,
};
export { view as Header };
