import { CarTypeStatus } from '../../States';

const initialState = {
  carTypeResponse: null,
};

export const CarTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CarTypeStatus.ERROR:
    case CarTypeStatus.REQUEST:
      return initialState;

    case CarTypeStatus.SUCCESS:
      return { ...state, carTypeResponse: action.payload };

    case CarTypeStatus.RESET:
      return initialState;

    default:
      return state;
  }
};
