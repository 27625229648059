import React from 'react';
import PropTypes from 'prop-types';
import {
 Stepper, StepLabel, Step, StepButton
} from '@material-ui/core';
import { ProgressCircularComponent } from './ProgressCircularComponent';
import { getBgProgressColor } from '../../Helpers';

export const StepperComponent = ({
  activeStep,
  steps,
  onStepperClick,
  wrapperClasses,
  stepClasses,
  stepLabelClasses,
  stepButtonClasses,
  progressTextClasses,
  progressValueInput,
  labelInput,
}) => (
  <Stepper
    alternativeLabel
    nonLinear
    activeStep={activeStep}
    onClick={onStepperClick}
    className={wrapperClasses}
  >
    {steps.map((item, index) => (
      <Step className={stepClasses} key={`${item[labelInput]}${index + 1}`}>
        {progressValueInput && (
          <ProgressCircularComponent
            value={item[progressValueInput]}
            position="absolute"
            left="50%"
            bottom={-1}
          />
        )}
        {!onStepperClick && (
          <StepLabel className={stepLabelClasses}>
            {item[labelInput]}
            {progressValueInput && (
              <span
                className={`${progressTextClasses} ${
                  getBgProgressColor(item[progressValueInput]).textColor
                }`}
              >
                {`${item[progressValueInput]}%`}
              </span>
            )}
          </StepLabel>
        )}
        {onStepperClick && (
          <StepButton className={stepButtonClasses} onClick={onStepperClick(index)}>
            <span>{item[labelInput]}</span>
          </StepButton>
        )}
      </Step>
    ))}
  </Stepper>
);

StepperComponent.propTypes = {
  activeStep: PropTypes.number.isRequired,
  labelInput: PropTypes.string.isRequired,
  steps: PropTypes.instanceOf(Array).isRequired,
  onStepperClick: PropTypes.func,
  wrapperClasses: PropTypes.string,
  stepClasses: PropTypes.string,
  stepLabelClasses: PropTypes.string,
  stepButtonClasses: PropTypes.string,
  progressTextClasses: PropTypes.string,
  progressValueInput: PropTypes.string,
};
StepperComponent.defaultProps = {
  onStepperClick: undefined,
  progressValueInput: undefined,
  wrapperClasses: 'stepper-wrapper',
  stepClasses: 'step-wrapper',
  stepLabelClasses: 'step-label-wrapper',
  stepButtonClasses: 'step-button-wrapper',
  progressTextClasses: 'step-progress-text-wrapper',
};
