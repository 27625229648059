import React, { useState } from 'react';
import { SwitchRoute } from '../../Componentes/Route/SwitchRoute';
import { AccountRoutes } from '../../Routes';
import { IconButton, Divider } from '@material-ui/core';
import defaultLogo from '../../Assets/Images/defaults/logo.png';
import { getTranslate, languageChange } from '../../Helpers';
import { SelectComponet } from '../../Componentes';
import { getLanguages, getLanguage } from 'react-switch-lang';

const translationPath = 'login.';

const AccountLayout = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openHandler = () => {
    setIsOpen(true);
  };
  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <div className='account-layout-wrapper'>
      <div className='login-wrapper LoginViewWrapper'>
        <div className='login-content-wrapper'>
          <div className='box-section-wrapper'>
            <div className='box-content'>
              <div className='d-flex-v-center-h-between'>
                <div className='logo-wrapper'>
                  <img
                    src={defaultLogo}
                    className='logo'
                    alt={getTranslate()(`${translationPath}company-logo`)}
                  />
                </div>
                <div className='px-2 select-language'>
                  <IconButton onClick={() => setIsOpen(!isOpen)}>
                    <span className='mdi mdi-menu-down'></span>
                  </IconButton>
                  <Divider className='language-divider' orientation='vertical' flexItem />
                  <div className='login-select'>
                    <SelectComponet
                      classes='theme-underline'
                      isOpen={isOpen}
                      onOpen={openHandler}
                      onClose={closeHandler}
                      data={getLanguages()}
                      value={getLanguage()}
                      onSelectChanged={(event) => languageChange(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <SwitchRoute routes={AccountRoutes} />
              <div className='curve-edge' />
              <div className='curve-edge-reverced' />
            </div>
          </div>
        </div>
        <div className='light-shadow' />
        <div className='city-wrapper'>
          <div className='city' />
        </div>
      </div>
    </div>
  );
};

export { AccountLayout };
