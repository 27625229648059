import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Meddilware, storeRenderCallback } from './Helpers';
import { SwitchRoute } from './Componentes/Route/SwitchRoute';
import { AppRoutes } from './Routes';
import 'react-phone-input-2/lib/material.css';
import '@mdi/font/css/materialdesignicons.min.css';
import 'moment/locale/ar';
import moment from 'moment';

const App = () => {
  const [, setRender] = useState(false);
  storeRenderCallback(setRender);
  moment.locale('en');
  return (
    <Router>
      <Meddilware />
      <SwitchRoute routes={AppRoutes} />
    </Router>
  );
};
export default App;
