import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Spinner, LoadableImageComponant, Inputs } from '../../../Componentes';
import { getTranslate, getDownloadableLink } from '../../../Helpers';
import { useTitle } from '../../../Hooks/useTitle';
import Avatar from '@material-ui/core/Avatar';
import { GetAllContactSupport, GetContactType } from '../../../Serviecs';
import { ContactSupportDeleteDialog } from './ContactSupportUtilities/ContactSupportDeleteDialog';
import {
  NoDataFoundComponent,
  NoSearchResultComponent,
  NoContentComponent,
  SelectComponet,
} from '../../../Componentes';
import {
  Grid,
  Button,
  Typography,
  CardContent,
  Card,
  Chip,
  TextField,
  FormControl,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { ContactSupportEnum } from '../../../Enums';
import Popover from '@material-ui/core/Popover';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import moment from 'moment';
import './ContactSupportView.scss';

const ContactSupportView = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [searchedItem, setSearchedItem] = useState('');
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [nameFilter, setNameFilter] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [activeUserItem, setActiveUserItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [contactType, setContactType] = useState();
  const [contactTypeSelect, setContactTypeSelect] = useState(0);
  const [responseResult, setResponseResult] = useState(() => ({ result: [], totalCount: 0 }));
  const [reloaded, setReloaded] = useState(false);
  const [orderBy, setOrderBy] = useState();
  const [dateChanged, setDateChanged] = useState(false);
  const cardContainer = useRef(null);
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const [sentObject, setSentObject] = useState({
    fromDate: null,
    toDate: null,
    typeId: null,
    orderBy: null,
    orderColumn: null,
  });
  const time = useRef(null);
  const translationPath = 'ContactSupport.';

  useEffect(() => {
    if (orderBy) setSentObject((items) => ({ ...items, orderColumn: orderBy }));
    if (nameFilter) setSentObject((items) => ({ ...items, orderBy: nameFilter }));
    if (contactTypeSelect && contactTypeSelect !== 0)
      setSentObject((items) => ({ ...items, typeId: contactTypeSelect }));
    if (contactTypeSelect === 0) setSentObject((items) => ({ ...items, typeId: null }));
    if (dateState && dateState[0] && dateChanged)
      setSentObject((items) => ({
        ...items,
        fromDate: dateState[0].startDate,
        endDate: dateState[0].endDate,
      }));
  }, [contactTypeSelect, dateChanged, dateState, nameFilter, orderBy]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOrderClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleOrderClose = () => {
    setAnchorE2(null);
  };
  const open = Boolean(anchorEl);
  const openOrderBy = Boolean(anchorE2);
  useTitle(getTranslate()(`SideMenuView.MobilePages.ContactSupport`));

  const GetLookupItem = async () => {
    const result = await GetContactType(1139);
    setContactType(result);
  };

  const GetContactSupport = useCallback(async () => {
    setLoading(true);
    const res = await GetAllContactSupport(page + 1, rowsPerPage, searchedItem, sentObject);
    setResponse(res);
    if (res && res.totalCount === 0) {
      setisFirstLoad(false);
    }

    if (reloaded) setResponseResult({ result: [], totalCount: 0 });
    else {
      if (page === 0) setResponseResult(res);
      else {
        setResponseResult((item) => ({
          result: item.result.concat(res.result),
          totalCount: res.totalCount,
        }));
      }
    }
    setLoading(false);
  }, [page, reloaded, rowsPerPage, searchedItem, sentObject]);

  const searchHandler = (e) => {
    const value = e.target.value;
    if (time.current) clearTimeout(time.current);
    time.current = setTimeout(() => {
      setSearchedItem(value);
      setActiveUserItem(null);
    }, 700);
  };

  useEffect(() => {
    GetContactSupport();
  }, [GetContactSupport, searchedItem]);

  useEffect(() => {
    return () => {
      if (time.current) clearTimeout(time.current);
    };
  }, []);

  useEffect(() => {
    GetLookupItem();
  }, []);

  const loadMoreData = useCallback(() => {
    if (
      responseResult &&
      responseResult.result &&
      responseResult.totalCount > responseResult.result.length &&
      (page + 1) * rowsPerPage <= responseResult.result.length
    )
      setPage(page + 1);
  }, [page, responseResult, rowsPerPage]);

  const scrollHandler = (e) => {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) loadMoreData();
  };

  useEffect(() => {
    if (
      cardContainer &&
      cardContainer.current &&
      cardContainer.current.children[0].scrollHeight <= cardContainer.current.clientHeight &&
      !loading
    ) {
      loadMoreData();
    }
    return () => {};
  }, [loadMoreData, loading, responseResult.result]);

  const handleClearFilter = () => {
    setSentObject({});
    setOrderBy(null);
    setContactTypeSelect(0);
    setDateChanged(false);
  };

  return (
    <div className="view-wrapper contact-support-wrapper">
      <Spinner isActive={loading} />
      <div className="header-section">
        <div className="filter-section px-2">
          <div className="section">
            <Grid item xs={2} className="filter-by">
              <Typography>{getTranslate()(`${translationPath}filter-by`)}</Typography>
            </Grid>
            <Grid container spacing={1} className="filter-section">
              <Grid item lg={3} sm={5} xl={3} xs={11}>
                <FormControl className="input-wrapper flex-wrap inputs theme-solid">
                  <TextField
                    variant="outlined"
                    value={nameFilter === 1 ? 'A - Z' : 'Z - A'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="mdi mdi-account" />
                          {getTranslate()(`${translationPath}order-by-filter`)}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" className="mdi-icons-color">
                          <span className="mdi mdi-chevron-down" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={(e) => handleOrderClick(e)}
                  />
                  <Popover
                    className="order-popover"
                    open={openOrderBy}
                    anchorEl={anchorE2}
                    onClose={handleOrderClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div className="order-lable">
                      <Typography>{getTranslate()(`${translationPath}order-by`)}</Typography>
                      <SelectComponet
                        idRef="nameFilterRef"
                        data={[
                          { id: 1, name: 'A - Z' },
                          { id: 2, name: 'Z - A' },
                        ]}
                        translationPath={translationPath}
                        textInput="name"
                        value={nameFilter}
                        valueInput="id"
                        onSelectChanged={(e) => setNameFilter(e.target.value)}
                      />
                    </div>
                    <div className="order-lable">
                      <Typography>{getTranslate()(`${translationPath}name-order`)}</Typography>
                      <SelectComponet
                        idRef="orderByRef"
                        data={ContactSupportEnum}
                        translationPath={translationPath}
                        textInput="value"
                        value={orderBy}
                        valueInput="key"
                        onSelectChanged={(e) => setOrderBy(e.target.value)}
                      />
                    </div>
                  </Popover>
                </FormControl>
              </Grid>
              <Grid item lg={3} sm={5} xl={3} xs={11}>
                <FormControl className="input-wrapper flex-wrap inputs theme-solid">
                  <TextField
                    variant="outlined"
                    value={
                      dateState &&
                      dateState[0] &&
                      `From: ${moment(dateState[0].startDate).format('DD/MM/yyyy')}, To: ${moment(
                        dateState[0].endDate
                      ).format('DD/MM/yyyy')}`
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="mdi mdi-calendar-range" />
                          {getTranslate()(`${translationPath}date`)}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" className="mdi-icons-color">
                          <span className="mdi mdi-chevron-down" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={(e) => handleClick(e)}
                  />
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <DateRange
                      months={2}
                      direction="horizontal"
                      ranges={dateState}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      onChange={(item) => {
                        setDateState([item.selection]);
                        setDateChanged(true);
                      }}
                    />
                  </Popover>
                </FormControl>
              </Grid>
              <Grid item lg={3} sm={5} xl={3} xs={11}>
                <div className="clear-filter">
                  <SelectComponet
                    initialSelectValue={0}
                    emptyItem={{
                      value: 0,
                      text: 'all',
                    }}
                    idRef="contactTypeRef"
                    data={contactType || []}
                    translationPath={translationPath}
                    textInput="lookupItemName"
                    value={contactTypeSelect}
                    valueInput="lookupItemId"
                    onSelectChanged={(e) => setContactTypeSelect(e.target.value)}
                  />
                  {orderBy || contactTypeSelect || dateChanged ? (
                    <IconButton onClick={handleClearFilter}>
                      <span className="mdi mdi-close" />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="section px-2">
            <div className="d-flex-column p-relative search-section">
              <Inputs
                idRef="tripsSearchRef"
                variant="outlined"
                fieldClasses="inputs theme-solid"
                translationPath={translationPath}
                label="search"
                inputPlaceholder='search-by-message-title'
                beforeIconClasses="mdi mdi-magnify mdi-24px c-gray-primary"
                onKeyUp={searchHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section px-2">
        <NoDataFoundComponent />
        {responseResult &&
        responseResult.result &&
        responseResult.result.length === 0 &&
        searchedItem.length === 0 &&
        !isFirstLoad ? (
          <NoContentComponent />
        ) : (
          response &&
          response.result &&
          response.result.length === 0 &&
          searchedItem.length === 0 &&
          !isFirstLoad && <NoContentComponent />
        )}
        <Grid container spacing={3}>
          {response && response.totalCount === 0 && searchedItem.length !== 0 && !isFirstLoad ? (
            <NoSearchResultComponent />
          ) : (
            <>
              {responseResult && responseResult.result && (
                <Grid
                  container
                  spacing={2}
                  className="card-section-container"
                  onScroll={scrollHandler}
                  ref={cardContainer}
                >
                  <Grid item lg={4} sm={12} xl={3} xs={12} className="contacts-card-container">
                    {responseResult &&
                      responseResult.result &&
                      responseResult.result.map((item, i) => (
                        <Card
                          style={{ '--card-color': item.color }}
                          key={i}
                          className="card-section"
                          onClick={() => setActiveUserItem(item)}
                        >
                          <Grid container>
                            <Grid item xs={11}>
                              <Grid container>
                                <Grid item>
                                  {item.profileImg ? (
                                    <LoadableImageComponant
                                      classes={'contact-image'}
                                      alt={item.fullName}
                                      src={getDownloadableLink(item.profileImg)}
                                    />
                                  ) : (
                                    <Avatar
                                      className="contact-image"
                                      alt={item.fullName}
                                      src={getDownloadableLink(item.profileImg)}
                                    />
                                  )}
                                </Grid>
                                <Grid item>
                                  <div className="contact-detailes">
                                    <Typography className="contact-name">
                                      {item.fullName}
                                    </Typography>
                                    <Tooltip title={item.type}>
                                      <Chip
                                        label={item.type}
                                        size="small"
                                        style={{ backgroundColor: item.color, color: 'white' }}
                                      />
                                    </Tooltip>
                                    <Tooltip title={item.complainName}>
                                      <Chip
                                        label={item.complainName}
                                        size="small"
                                        variant="outlined"
                                      />
                                    </Tooltip>
                                  </div>
                                  <Typography className="card-content">{item.content}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={1} className="card-actions">
                              <Button
                                className="mdi mdi-delete-outline"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setActiveUserItem(item);
                                  setIsOpenConfirm(true);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      ))}
                  </Grid>
                  {response && response.result && response.result.length > 0 && (
                    <Grid item lg={8} sm={12} xl={9} xs={12}>
                      <Card className="support-section">
                        <CardContent>
                          {activeUserItem && (
                            <>
                              <div className="support-detailes">
                                <Typography className="support-subject">
                                  {getTranslate()(`${translationPath}subject`)}
                                </Typography>
                                <Typography>{activeUserItem.complainName}</Typography>
                              </div>
                              <div>{activeUserItem.createdOn.split('T')[0]}</div>
                              <div className="support-contact-name">{activeUserItem.fullName}</div>
                              <div className="support-chips">
                                <Chip
                                  label={activeUserItem.type}
                                  size="small"
                                  style={{ backgroundColor: activeUserItem.color, color: 'white' }}
                                />
                                <Chip
                                  label={activeUserItem.complainName}
                                  size="small"
                                  variant="outlined"
                                />
                              </div>

                              <div className="support-body">
                                <div className="w-100 pt-3">
                                  <span>{activeUserItem.title}</span>
                                </div>
                                <Typography>{activeUserItem.content}</Typography>
                              </div>
                            </>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
      <ContactSupportDeleteDialog
        activeUserItem={activeUserItem}
        isOpen={isOpenConfirm}
        setReloaded={setReloaded}
        isOpenChanged={() => {
          setIsOpenConfirm(false);
        }}
        reloadData={() => {
          setActiveUserItem(null);
          GetContactSupport(page + 1, rowsPerPage, {});
          setIsOpenConfirm(false);
        }}
      />
    </div>
  );
};

export { ContactSupportView };
