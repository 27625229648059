import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { Button } from '@material-ui/core';
import { getTranslate } from '../../Helpers';

export const DatePickerComponent = ({
  value,
  inputVariant,
  label,
  idRef,
  isRequired,
  isDisabled,
  format,
  helperText,
  maxData,
  minDate,
  wrapperClasses,
  dialogClasses,
  //   pickerClasses,
  inputClasses,
  onDateChanged,
  locale,
  labelClasses,
  translationPath,
  labelValue,
  placeholder,
  buttonOptions,
  autoOk,
  clearable,
  isTimePicker,
  startAdornment,
  error,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      className={wrapperClasses}
      utils={MomentUtils}
      locale={locale}
    >
      <>
        {labelValue && (
          <label htmlFor={idRef} className={labelClasses}>
            {getTranslate()(`${translationPath}${labelValue}`)}
          </label>
        )}
        <div className="d-flex">
          {!isTimePicker && (
            <KeyboardDatePicker
              required={isRequired}
              id={idRef}
              label={(label && getTranslate()(`${translationPath}${label}`)) || undefined}
              placeholder={
                (placeholder && getTranslate()(`${translationPath}${placeholder}`)) || undefined
              }
              format={format}
              value={value}
              className={inputClasses}
              disabled={isDisabled}
              autoOk={autoOk}
              //   InputLabelProps={{  }}
              invalidDateMessage={
                (helperText && getTranslate()(`${translationPath}${helperText}`)) || undefined
              }
              open={isOpen}
              maxDate={maxData}
              minDate={minDate}
              clearable={clearable}
              inputVariant={inputVariant}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              error={error}
              DialogProps={{
                className: dialogClasses,
              }}
              onChange={onDateChanged}
            />
          )}
          {isTimePicker && (
            <KeyboardTimePicker
              required={isRequired}
              id={idRef}
              label={(label && getTranslate()(`${translationPath}${label}`)) || undefined}
              // format={format}
              value={value}
              error={error}
              placeholder={
                (placeholder && getTranslate()(`${translationPath}${placeholder}`)) || undefined
              }
              className={inputClasses}
              disabled={isDisabled}
              autoOk={autoOk}
              //   InputLabelProps={{  }}
              invalidDateMessage={
                (helperText && getTranslate()(`${translationPath}${helperText}`)) || undefined
              }
              InputProps={{
                startAdornment,
              }}
              open={isOpen}
              maxDate={maxData}
              minDate={minDate}
              clearable={clearable}
              inputVariant={inputVariant}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              onClick={() => setIsOpen(true)}
              onKeyPress={(event) => (event.key === 'Enter' && setIsOpen(true)) || undefined}
              DialogProps={{
                className: dialogClasses,
              }}
              onChange={onDateChanged}
            />
          )}
          {buttonOptions && (
            <Button
              className={`ml-2 ${buttonOptions.className}`}
              onClick={() => {
                setIsOpen(true);
              }}
              disabled={buttonOptions.isDisabled}
              required={buttonOptions.isRequired}
            >
              <span className={buttonOptions.iconClasses} />
            </Button>
          )}
        </div>
      </>
    </MuiPickersUtilsProvider>
  );
};

DatePickerComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
  maxData: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
  onDateChanged: PropTypes.func,
  inputVariant: PropTypes.string,
  label: PropTypes.string,
  idRef: PropTypes.string,
  format: PropTypes.string,
  helperText: PropTypes.string,
  wrapperClasses: PropTypes.string,
  //   pickerClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  dialogClasses: PropTypes.string,
  translationPath: PropTypes.string,
  labelValue: PropTypes.string,
  placeholder: PropTypes.string,
  locale: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoOk: PropTypes.bool,
  clearable: PropTypes.bool,
  isTimePicker: PropTypes.bool,
  error: PropTypes.bool,
  startAdornment: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
  buttonOptions: PropTypes.shape({
    className: PropTypes.string,
    iconClasses: PropTypes.string,
    onActionClicked: PropTypes.func,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
};
DatePickerComponent.defaultProps = {
  value: undefined,
  maxData: undefined,
  minDate: undefined,
  onDateChanged: undefined,
  inputVariant: 'standard',
  label: null,
  format: 'DD/MM/YYYY',
  helperText: null,
  isRequired: false,
  isDisabled: false,
  autoOk: false,
  isTimePicker: false,
  clearable: true,
  translationPath: '',
  labelValue: null,
  placeholder: undefined,
  error: undefined,
  startAdornment: <span className="mdi mdi-clock-time-five c-gray-primary px-1" />,
  labelClasses: 'texts-form',
  wrapperClasses: 'datepicker-wrapper',
  inputClasses: 'inputs theme-solid-border picker-wrapper hide-button',
  dialogClasses: 'picker-dialog-wrapper',
  idRef: 'datepickerRef',
  locale: 'en',
  buttonOptions: {
    className: 'btns-icon theme-solid bg-secondary',
    iconClasses: 'icons i-calendar',
    isDisabled: false,
    isRequired: false,
  },
};
