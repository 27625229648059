import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Locations} from './Locations';
import {SoldTours} from './SoldTours'
import {TotalSoldTours} from './TotalSoldTours'


export const LocationsAndSoldTours=()=> {
    

    return (
        <div className="LocationsSection">
        <Grid container spacing={2} >

        <Grid  item xs={12} sm={8} md={8} lg={4}>
   <Locations/>
      </Grid>

      <Grid  item xs={12} sm={8} md={8} lg={4}>
<SoldTours/>
    </Grid>

    <Grid  item xs={12} sm={8} md={8} lg={4}>
<TotalSoldTours/>
  </Grid>


</Grid>
</div>
    )
}
