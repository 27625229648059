import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Spinner, Inputs } from '../../../../Componentes';
import {
  CreatePublicPromoCode,
  CreatePrivatePromoCode,
  GeneratePromoCode,
  GetPromoCodeById,
  UpdatePrivatePromoCode,
  UpdatePublicPromoCode,
} from '../../../../Serviecs';
import {
  getHistory,
  getTranslate,
  getErrorByName,
  showError,
  showSuccess,
  GetParams,
} from '../../../../Helpers';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import ChipInput from 'material-ui-chip-input';
import moment from 'moment';
import Joi from 'joi';
import '../PromoCodeView.scss';

const AddNewPromoCode = (props) => {
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [promoInfo, setPromoInfo] = useState(false);
  const [generatedPromo, setGeneratedPromo] = useState('');
  const [promoCode, setPromocode] = useState('');
  const [publicButton, setPublicButoon] = useState(true);
  const [privateButton, setPrivateButoon] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [percentage, setPercentage] = useState('');
  const [maxLimit, setMaxLimit] = useState('');
  const [promoUsers, setPromoUsers] = useState([]);
  const [numberOfUse, setNumberOfUse] = useState('');
  const [isMultipleUse, setIsMultipleUse] = useState(false);
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const translationPath = 'PromoCode.';
  const open = Boolean(anchorEl);

  const GetPromoInfo = async (promoId) => {
    setLoading(true);
    const res = await GetPromoCodeById(promoId);
    setPromoInfo(res);
    setLoading(false);
  };

  useEffect(() => {
    if (GetParams('id')) {
      GetPromoInfo(+GetParams('id').split('?')[0]);
      setIsEdit(true);
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      setPromocode(promoInfo.promoCodeName);
      setIsMultipleUse(promoInfo.isMultipleUse);
      setNumberOfUse(promoInfo.numberOfUse);
      setPercentage(promoInfo.discountPercentage);
      setMaxLimit(promoInfo.discountLimit);
      setDateState([
        {
          startDate: new Date(promoInfo.startDate),
          endDate: new Date(promoInfo.endDate),
          key: 'selection',
        },
      ]);
      promoInfo &&
        promoInfo.promoCodeUsers &&
        setPromoUsers(promoInfo.promoCodeUsers.map((el) => el.email));
      if (promoInfo.type === 1) {
        setPrivateButoon(true);
        setPublicButoon(false);
      } else {
        setPrivateButoon(false);
        setPublicButoon(true);
      }
    }
  }, [isEdit, promoInfo]);

  const schema = Joi.object({
    percentage: Joi.number()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}promocode-name-is-required`),
      }),
    maxLimit: Joi.number()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}maxlimit-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ percentage, maxLimit });

  const handelGenerateClick = async () => {
    if (!generatedPromo.promoCode) {
      setLoading(true);
      const res = await GeneratePromoCode();
      setGeneratedPromo(res);
      setLoading(false);
    } else {
      setGeneratedPromo('');
      setPromocode('');
    }
  };

  const handlePublicClick = () => {
    setPublicButoon(true);
    setPrivateButoon(false);
  };
  const handlePrivateClick = () => {
    setPrivateButoon(true);
    setPublicButoon(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelCancelPromoCode = () => {
    getHistory().push('/home/PromoCode');
  };
  const handelSavePromoCode = async () => {
    setIsSubmitted(true);
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    if (isEdit) {
      if (privateButton) {
        const result = await UpdatePrivatePromoCode(+GetParams('id').split('?')[0], {
          isMultipleUse: isMultipleUse,
          numberOfUse: isMultipleUse ? numberOfUse : 0,
          promoCodeName: generatedPromo.promoCode ? generatedPromo.promoCode : promoCode,
          startDate: dateState[0].startDate,
          endDate: dateState[0].endDate,
          discountPercentage: percentage,
          discountLimit: maxLimit,
          emails: promoUsers || [],
        });
        if (result) {
          showSuccess(getTranslate()(`${translationPath}promo-code-updated-succefully`));
          handelCancelPromoCode();
        }
      } else {
        const result = await UpdatePublicPromoCode(+GetParams('id').split('?')[0], {
          isMultipleUse: isMultipleUse,
          numberOfUse: isMultipleUse ? numberOfUse : 0,
          promoCodeName: generatedPromo.promoCode ? generatedPromo.promoCode : promoCode,
          startDate: dateState[0].startDate,
          endDate: dateState[0].endDate,
          discountPercentage: percentage,
          discountLimit: maxLimit,
        });
        if (result) {
          showSuccess(getTranslate()(`${translationPath}promo-code-updated-succefully`));
          handelCancelPromoCode();
        } else showError(getTranslate()(`${translationPath}promo-code-updating-failed`));
      }
    } else {
      const result = publicButton
        ? await CreatePublicPromoCode({
            isMultipleUse: isMultipleUse,
            numberOfUse: isMultipleUse ? numberOfUse : 0,
            promoCodeName: generatedPromo.promoCode ? generatedPromo.promoCode : promoCode,
            startDate: dateState[0].startDate,
            endDate: dateState[0].endDate,
            discountPercentage: percentage,
            discountLimit: maxLimit,
          })
        : await CreatePrivatePromoCode({
            isMultipleUse: isMultipleUse,
            numberOfUse: isMultipleUse ? numberOfUse : 0,
            promoCodeName: generatedPromo.promoCode ? generatedPromo.promoCode : promoCode,
            startDate: dateState[0].startDate,
            endDate: dateState[0].endDate,
            discountPercentage: percentage,
            discountLimit: maxLimit,
            emails: promoUsers || [],
          });
      if (result) {
        showSuccess(getTranslate()(`${translationPath}promo-code-created-succefully`));
        handelCancelPromoCode();
      } else showError(getTranslate()(`${translationPath}promo-code-creating-failed`));
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleAddChip = (chip) => {
    if (validateEmail(chip) === true) {
      promoUsers.push(chip);
    } else {
      showError(getTranslate()(`${translationPath}you-must-enter-an-email`));
    }
  };
  const handleDeleteChip = (chip, index) => {
    if (index > -1) {
      promoUsers.splice(index, 1);
      setPromoUsers([...promoUsers]);
    }
  };

  return (
    <div className="view-wrapper promo-code-wrapper">
      <Spinner isActive={loading} />
      <div className="header-section">
        <div className="filter-section px-2">
          <div className="section save-promo-code">
            <Button className="btns theme-solid" onClick={handelSavePromoCode}>
              {getTranslate()(`${translationPath}save-promo-code`)}
            </Button>
            <Button className="btns theme-solid bg-cancel" onClick={handelCancelPromoCode}>
              {getTranslate()(`${translationPath}cancel`)}
            </Button>
          </div>
        </div>
      </div>
      <div className="section px-2">
        <Grid container spacing={2} justify="center">
          <Grid item>
            <Grid item className="add-promo-name">
              <Typography>{getTranslate()(`${translationPath}promo-type`)}</Typography>
              {getTranslate()(`${translationPath}choose-promo-type-to-continue`)}
            </Grid>
            <Grid container className="add-promo-actions add-new-promo-name" spacing={3}>
              <Grid
                item
                className={publicButton ? 'public-button' : isEdit ? 'disable-button' : ''}
              >
                <Button onClick={handlePublicClick} disabled={isEdit && promoInfo.type === 1}>
                  <Grid>
                    <Grid item>
                      <span className="mdi mdi-earth" />
                    </Grid>
                    <Grid item>
                      <Typography>{getTranslate()(`${translationPath}public`)}</Typography>
                      {getTranslate()(`${translationPath}generate-for-all-users`)}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid
                item
                className={privateButton ? 'public-button' : isEdit ? 'disable-button' : ''}
              >
                <Button onClick={handlePrivateClick} disabled={isEdit && promoInfo.type === 2}>
                  <Grid>
                    <Grid item>
                      <span className="mdi mdi-account-supervisor-circle-outline" />
                    </Grid>
                    <Grid item>
                      <Typography>{getTranslate()(`${translationPath}private`)}</Typography>
                      {getTranslate()(`${translationPath}for-groups-and-companies`)}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
            <div className="new-promo-description">
              <Grid item>
                <Typography>{getTranslate()(`${translationPath}promo-code`)}</Typography>
                <div className="promo-generate">
                  <Inputs
                    isDisabled={isEdit}
                    idRef="promo-generate"
                    isSubmitted={isSubmitted}
                    value={generatedPromo.promoCode ? generatedPromo.promoCode : promoCode}
                    inputPlaceholder={getTranslate()(`${translationPath}promo-code`)}
                    onInputChanged={(e) => {
                      setPromocode(e.target.value);
                    }}
                  />
                  <Button
                    disabled={isEdit}
                    className="btns theme-solid"
                    onClick={handelGenerateClick}
                  >
                    {!generatedPromo.promoCode
                      ? getTranslate()(`${translationPath}generate`)
                      : getTranslate()(`${translationPath}clear-code`)}
                  </Button>
                </div>
              </Grid>
              {privateButton && (
                <Grid item>
                  <Typography>
                    {getTranslate()(`${translationPath}who-can-use-this-promo`)}
                  </Typography>
                  <ChipInput
                    className="inputs theme-solid"
                    variant="outlined"
                    size="small"
                    value={promoUsers}
                    onAdd={(chip) => handleAddChip(chip)}
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  />
                </Grid>
              )}
              <Grid item container spacing={2}>
                <Grid item lg={6} sm={12} xl={6} xs={12}>
                  <Typography>{getTranslate()(`${translationPath}percentage`)}</Typography>
                  <Inputs
                    idRef="percentage"
                    type="number"
                    helperText={getErrorByName(schema, 'percentage').message}
                    error={getErrorByName(schema, 'percentage').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    value={percentage}
                    onInputChanged={(e) => setPercentage(e.target.value)}
                    inputPlaceholder={getTranslate()(`${translationPath}percentage`)}
                  />
                </Grid>
                <Grid item lg={6} sm={12} xl={6} xs={12}>
                  <Typography>{getTranslate()(`${translationPath}max-limit`)}</Typography>
                  <Inputs
                    idRef="max-limit"
                    type="number"
                    helperText={getErrorByName(schema, 'maxLimit').message}
                    error={getErrorByName(schema, 'maxLimit').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    value={maxLimit}
                    onInputChanged={(e) => setMaxLimit(e.target.value)}
                    inputPlaceholder={getTranslate()(`${translationPath}max-limit`)}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <FormControlLabel
                  className="is-multible"
                  control={
                    <Checkbox
                      checked={isMultipleUse}
                      onClick={() => setIsMultipleUse(!isMultipleUse)}
                    />
                  }
                  label={getTranslate()(`${translationPath}is-multible-use`)}
                />
              </Grid>
              {isMultipleUse && (
                <Grid item>
                  <Typography>{getTranslate()(`${translationPath}number-of-use`)}</Typography>
                  <Inputs
                    idRef="number-of-use"
                    type="number"
                    helperText={getErrorByName(schema, 'numberOfUse').message}
                    error={getErrorByName(schema, 'numberOfUse').error}
                    isWithError
                    isSubmitted={isSubmitted}
                    value={numberOfUse}
                    onInputChanged={(e) => setNumberOfUse(e.target.value)}
                    inputPlaceholder={getTranslate()(`${translationPath}number-of-use`)}
                  />
                </Grid>
              )}
              <Grid item>
                <Typography>{getTranslate()(`${translationPath}date`)}</Typography>
                <FormControl className="inputs theme-solid">
                  <TextField
                    size="small"
                    variant="outlined"
                    value={
                      dateState &&
                      dateState[0] &&
                      `From: ${moment(dateState[0].startDate).format('DD/MM/yyyy')}, To: ${moment(
                        dateState[0].endDate
                      ).format('DD/MM/yyyy')}`
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="mdi mdi-calendar-range" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={(e) => handleClick(e)}
                  />
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <DateRange
                      months={2}
                      direction="horizontal"
                      ranges={dateState}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      onChange={(item) => {
                        setDateState([item.selection]);
                      }}
                    />
                  </Popover>
                </FormControl>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export { AddNewPromoCode };
