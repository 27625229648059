import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';
import { showError } from '../../Helpers';

export const GetAllSections = async (pageIndex, pageSize, body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/ApplicationSection/GetAllSections?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Retriving Sections');
  }
};

export const GetAllToursBySectionId = async (pageIndex, pageSize, sectionId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/ApplicationSection/GetAllToursBySectionId?pageIndex=${pageIndex}&pageSize=${pageSize}&sectionId=${sectionId}`
    );
    return result;
  } catch (e) {
    showError('Error Retriving Section');
  }
};

export const DeleteSection = async (sectionId) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/PSTViewing/ApplicationSection/DeleteSection/${sectionId}`
    );
    return result;
  } catch (e) {
    showError('Error Deleting Section');
  }
};

export const CreateSection = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/ApplicationSection/CreateSection`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

export const UpdateSection = async (sectionId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/ApplicationSection/UpdateSection/${sectionId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetSectionInfoById = async (sectionId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/ApplicationSection/GetSectionInfoById?sectionId=${sectionId}`
    );
    return result;
  } catch (e) {
    showError('Error Retriving Section');
  }
};

export const GetAllToursPreviewBySectionId = async (sectionId, pageIndex, pageSize, body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/ApplicationSection/GetAllToursPreviewBySectionId/${sectionId}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Retriving Section Tours');
  }
};

export const GetDefaultCarType = async () => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/CarTypes/GetDefaultCarType`
    );
    return result;
  } catch (e) {
    //
  }
};

export const UpdateSectionsOrder = async (body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/ApplicationSection/UpdateSectionsOrder`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Updating Section Order');
  }
};
