import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import { Spinner } from '../../../../../../Componentes';
import { getTranslate, GetParams } from '../../../../../../Helpers';
import {
  GetAllApplicationService,
  GetAllComponentsByAppServiceId,
  GetAllPermissionsByRoleId,
} from '../../../../../../Serviecs/Roleservices/roleServices';
import { NoDataFoundComponent } from '../../../../../../Componentes';
import './RolesView.scss';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const RolesView = (props) => {
  const [value, setValue] = React.useState(0);
  const [roleId, setRoleId] = React.useState('');
  const [expanded, setExpanded] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [appService, setAppService] = React.useState({});
  const [components, setComponents] = React.useState({});
  const [componentId, setComponentId] = React.useState('');
  const [rolePermissions, setRolePermissions] = React.useState({});

  useEffect(() => {
    setRoleId(GetParams('id'));
  }, [props.match]);

  const GetRolePermissions = async (roleId, pageIndex, PageSize) => {
    setRolePermissions(await GetAllPermissionsByRoleId(roleId, pageIndex, PageSize));
  };
  const GetAppService = async (pageIndex, PageSize) => {
    setAppService(await GetAllApplicationService(pageIndex, PageSize));
  };
  const GetComponents = async (appId, pageIndex, PageSize) => {
    setLoading(true);
    const res = await GetAllComponentsByAppServiceId(appId, pageIndex, PageSize);
    setComponents(res);
    if (res && res.result && res.result[0]) setComponentId(res.result[0].componentsId);

    setLoading(false);
  };

  useEffect(() => {
    GetAppService(1, 10);
  }, [setAppService]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const permissions = (comId) => (
    <Grid container>
      {rolePermissions &&
        rolePermissions.result &&
        // eslint-disable-next-line array-callback-return
        rolePermissions.result.map((role, i) => {
          if (role.permissions.componentsId === comId) {
            return (
              <Grid
                item
                xs={3}
                key={i}
                className={
                  role.accessTypes.accessTypesId === 1
                    ? 'globalCheckBox'
                    : role.accessTypes.accessTypesId === 2
                    ? 'localCheckBox'
                    : role.accessTypes.accessTypesId === 3
                    ? 'deepCheckBox'
                    : role.accessTypes.accessTypesId === 4
                    ? 'basicCheckBox'
                    : ''
                }>
                <FormControlLabel
                  key={i}
                  className='form-control-label'
                  control={
                    <Checkbox
                      key={i}
                      className='checkbox-wrapper'
                      checkedIcon={<span className='mdi mdi-check' />}
                      indeterminateIcon={<span className='mdi mdi-minus' />}
                    />
                  }
                  label={role.permissions.permissionsName}
                  checked={!!role.accessTypes}
                />
              </Grid>
            );
          }
        })}
    </Grid>
  );

  const tabs = (
    <div className='roleTabs'>
      {components && components.result && components.result[0] ? (
        <>
          <Spinner isActive={Loading} />
          {!appService && <NoDataFoundComponent />}
          <AppBar position='static' color='default'>
            <Tabs
              variant='scrollable'
              scrollButtons='auto'
              value={value}
              onChange={handleTabChange}
              indicatorColor='primary'>
              {components &&
                components.result &&
                components.result.map((com, i) => (
                  <Tab
                    key={i}
                    label={com.componentsName}
                    {...a11yProps(i)}
                    onClick={() => setComponentId(com.componentsId)}
                  />
                ))}
            </Tabs>
          </AppBar>
          <Paper>
            <TabPanel value={value} index={value}>
              {permissions(componentId)}
            </TabPanel>
          </Paper>
        </>
      ) : (
        <Typography>{getTranslate()('Administration.Roles.Roles.NoData')}</Typography>
      )}
    </div>
  );

  const collapsePanels = (
    <>
      {appService &&
        appService.result &&
        appService.result.map((panel, i) => (
          <ExpansionPanel
            key={i}
            expanded={expanded === panel.applicationServicesId}
            onChange={(e, isExpanded) => {
              setExpanded(isExpanded ? panel.applicationServicesId : false);
            }}>
            <ExpansionPanelSummary
              key={i}
              onClick={async () => {
                GetRolePermissions(roleId, 1, 100);
                await GetComponents(panel.applicationServicesId, 1, 10);
                setValue(0);
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'>
              <Typography key={i}>{panel.applicationServicesName}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails key={i}>{tabs}</ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
    </>
  );

  const colorCheckbox = (
    <div className='colorCheckBoxes'>
      <FormControlLabel
        className='form-control-label globalCheckBox'
        control={
          <Checkbox
            className='checkbox-wrapper'
            checkedIcon={<span className='mdi mdi-check' />}
            indeterminateIcon={<span className='mdi mdi-minus' />}
            checked
          />
        }
        label={getTranslate()('Administration.Roles.AddRole.Global')}
      />
      <FormControlLabel
        className='form-control-label localCheckBox'
        control={
          <Checkbox
            className='checkbox-wrapper'
            checkedIcon={<span className='mdi mdi-check' />}
            indeterminateIcon={<span className='mdi mdi-minus' />}
            checked
          />
        }
        label={getTranslate()('Administration.Roles.AddRole.Local')}
      />
      <FormControlLabel
        className='form-control-label deepCheckBox'
        control={
          <Checkbox
            className='checkbox-wrapper'
            checkedIcon={<span className='mdi mdi-check' />}
            indeterminateIcon={<span className='mdi mdi-minus' />}
            checked
          />
        }
        label={getTranslate()('Administration.Roles.AddRole.Deep')}
      />
      <FormControlLabel
        className='form-control-label basicCheckBox'
        control={
          <Checkbox
            className='checkbox-wrapper'
            checkedIcon={<span className='mdi mdi-check' />}
            indeterminateIcon={<span className='mdi mdi-minus' />}
            checked
          />
        }
        label={getTranslate()('Administration.Roles.AddRole.Basic')}
      />
    </div>
  );

  return (
    <div className='RoleView'>
      <div className='paperWraper'>
        <Grid container item xs={12} justify='center' alignItems='center'>
          <Grid item lg={7} sm={12} xl={7} xs={12}>
            <Grid>{colorCheckbox}</Grid>
            <Grid>{collapsePanels}</Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export { RolesView };
