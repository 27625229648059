import React, { useState, useEffect, useCallback } from 'react';
import { Tables } from '../../../../../Componentes';
import './UpComing.scss';
import { getTranslate } from ' ./../../src/Helpers';
import { GetUpcomingToursStatistics } from './../../../../../Serviecs';
import moment from 'moment';
import { TotalToursEnum } from '../../../../../Enums';
import { SelectComponet } from '../../../../../Componentes';
import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';

export const UpComingTours = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dropDownValue, setDropDownValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState({});
  const [dateRange, setDateRange] = useState({
    pageSize: 5,
    pageIndex: 1,
    fromDate: moment().add(-7, 'd').format('MM-DD-YYYY'),
    toDate: moment().format('MM-DD-YYYY'),
  });
  const translationPath = 'Upcoming.';

  const GetUpcomingTours = useCallback(async () => {
    setIsLoading(true);
    const Status = await GetUpcomingToursStatistics(dateRange);
    if (Status) {
      setTableData(Status);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownValue]);

  useEffect(() => {
    GetUpcomingTours();
  }, [GetUpcomingTours]);

  const dropDwonDateRange = (value) => {
    setDropDownValue(value);
    switch (value) {
      case 0:
        setDateRange((items) => ({
          ...items,
          fromDate: moment().add(-7, 'd').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
        }));
        break;
      case 1:
        setDateRange((items) => ({
          ...items,
          fromDate: moment().add(-1, 'M').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
        }));
        break;
      case 2:
        setDateRange((items) => ({
          ...items,
          fromDate: moment().add(-1, 'y').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
        }));
        break;

      default:
    }
  };

  const openHandler = () => {
    setIsOpen(true);
  };
  const closeHandler = () => {
    setIsOpen(false);
  };

  const handlePageChange = async (e, newPage) => {
    setPage(newPage);
    setDateRange({ pageIndex: newPage + 1, pageSize: rowsPerPage });
  };
  const handlePageRowChange = async (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
    setDateRange({ pageIndex: page + 1, pageSize: parseInt(e.target.value, 10) });
  };

  return (
    <div className='UpComing-Table'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='UpComingTitle'>
        <p className='UpComingPargraph'>
          {getTranslate()(`${translationPath}Upcoming Tours For Drivers & Agents`)}
        </p>
        <div className='Upcoming-select'>
          <SelectComponet
            isOpen={isOpen}
            onOpen={openHandler}
            onClose={closeHandler}
            initialSelectValue={0}
            data={TotalToursEnum}
            value={dropDownValue}
            textInput='value'
            valueInput='key'
            translationPathForData={translationPath}
            onSelectChanged={(event) => {
              dropDwonDateRange(event.target.value);
            }}
          />
        </div>
      </div>
      <Tables
        data={tableData ? tableData.result : []}
        activePage={page}
        totalItems={tableData && tableData.totalCount ? tableData.totalCount : 0}
        activePageChanged={handlePageChange}
        itemsPerPage={rowsPerPage}
        itemsPerPageChanged={handlePageRowChange}
        headerData={[
          {
            id: 1,
            isSortable: true,
            label: `${translationPath}Driver Name`,
            component: (item) => (
              <span>
                {(item &&
                  item.driverCarList &&
                  item.driverCarList.map((el) => (el.driverName && el.driverName) || 'N/A ')) }
              </span>
            ),
          },
          {
            id: 2,
            isSortable: true,
            label: `${translationPath}Agent Name`,
            input: 'agentName',
            isDate: false,
          },
          {
            id: 3,
            isSortable: true,
            label: `${translationPath}Car`,
            component: (item) => (
              <span>  {(item &&
                item.driverCarList &&
                item.driverCarList.map((el) => (el.carNumber && el.carNumber) || 'N/A ')) }
           </span>
            ),
          },
          {
            id: 4,
            isSortable: true,
            label: `${translationPath}Tour Name`,
            input: 'tourName',
            isDate: false,
          },
          {
            id: 5,
            isSortable: true,
            label: `${translationPath}Start Date`,
            input: 'startDate',
            isDate: true,
          },
          {
            id: 5,
            isSortable: true,
            label: `${translationPath}End Date`,
            input: 'endDate',
            isDate: true,
          },
        ]}
        responsiveWrapperClasses='theme-dashboard'
      />
    </div>
  );
};
