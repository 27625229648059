import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import {
  getTranslate,
  showError,
  getHistory,
  getErrorByName,
  GetParams,
} from '../../../../Helpers';
import { Inputs, Spinner } from '../../../../Componentes';
import Joi from 'joi';
import { ApplicationUserResetPassword } from '../../../../Serviecs';
import { LoginActions } from '../../../../Stores/Actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import '../../LoginViews/LoginView/LoginView.scss';

const translationPath = 'login.';

const NewPasswordView = (props) => {
  const [identityVerificationId, setIdentityVerificationId] = useState();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIdentityVerificationId(GetParams('identityVerificationId').split('?')[0]);
  }, [props.match]);

  const schema = Joi.object({
    newPassword: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}password-is-required`),
      }),
    confirmPassword: Joi.string()
      .valid(Joi.ref('newPassword'))
      .required()
      .messages({
        'any.only': getTranslate()(`${translationPath}re-enter-password-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ newPassword, confirmPassword });

  useEffect(() => {
    if (props.loginResponse && props.loginResponse.token) {
      localStorage.setItem('session', JSON.stringify(props.loginResponse));
      getHistory().push('/home');
    }
  }, [props.loginResponse]);

  const handleResetPassword = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    const options = await ApplicationUserResetPassword({
      identityVerificationId: +identityVerificationId,
      newPassword: newPassword,
    });
    if (options) {
      setIsSubmitted(true);
      props.ResetAction(options);
    }
    setLoading(false);
  };

  return (
    <>
      <div className='forget-password-text'>
        <Spinner isActive={loading} />
        <Typography className='forget-password'>
          {getTranslate()(`${translationPath}new-password`)}
        </Typography>
        <Typography className='verification-text'>
          {getTranslate()(`${translationPath}make-sure-to-not-enter-a-strong-password`)}
        </Typography>
      </div>
      <form noValidate className='form-wrapper' onSubmit={handleResetPassword}>
        <Inputs
          helperText={getErrorByName(schema, 'newPassword').message}
          error={getErrorByName(schema, 'newPassword').error}
          isWithError
          isSubmitted={isSubmitted}
          type='password'
          fieldClasses='inputs theme-underline mb-4'
          label={getTranslate()(`${translationPath}new-password`)}
          name='Code'
          idRef='newPassword'
          value={newPassword}
          onInputChanged={(e) => setNewPassword(e.target.value)}
        />
        <Inputs
          helperText={getErrorByName(schema, 'confirmPassword').message}
          error={getErrorByName(schema, 'confirmPassword').error}
          isWithError
          isSubmitted={isSubmitted}
          type='password'
          fieldClasses='inputs theme-underline mb-4'
          label={getTranslate()(`${translationPath}re-enter-new-password`)}
          name='Code'
          idRef='confirmPassword'
          value={confirmPassword}
          onInputChanged={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
        <div className='d-flex-v-center-h-between'>
          <Button
            onClick={() => {
              getHistory().push('/account/login');
            }}
            className='btns cancel-forget-passwrod '>
            {getTranslate()(`${translationPath}cancel`)}
          </Button>
          <Button className='btns submit-forget-password' type='submit'>
            {getTranslate()(`${translationPath}login`)}
          </Button>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    LoginReducer: { loginResponse },
  } = state;
  return {
    loginResponse,
  };
};

const dispatchReset = (dispatch) => {
  return {
    ResetAction: bindActionCreators(LoginActions.reset, dispatch),
  };
};

const store = connect(mapStateToProps, dispatchReset)(NewPasswordView);

export { store as NewPasswordView };
