import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GetReadyTourById } from '../../../../../Serviecs';
import { Spinner, LoadableImageComponant } from '../../../../../Componentes';
import { getTranslate, getDownloadableLink } from '../../../../../Helpers';
import Rating from '@material-ui/lab/Rating';
import { Button } from '@material-ui/core';
import { DefaultImagesEnum, LoadableImageEnum } from '../../../../../Enums';
import { getLanguage } from 'react-switch-lang';
import './ToursDetailsComponent.scss';
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
} from '@material-ui/lab';

export const TourDetailsComponent = ({ tourId, translationPath, onDeleteClicked }) => {
  const [tour, setTour] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getTourDetails = useCallback(async () => {
    setIsLoading(true);
    const res = await GetReadyTourById(tourId);
    setTour(res);
    setIsLoading(false);
  }, [tourId]);
  useEffect(() => {
    getTourDetails();
  }, [getTourDetails, tourId]);
  return (
    <div className='tour-details-wrapper view-wrapper c-black-light'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='d-flex-v-center-h-between'>
        <span className='fz-16px texts-truncate px-2'>{tour.tourName}</span>
        <Button className='btns-icon px-2' onClick={() => onDeleteClicked(tour)}>
          <span className='mdi mdi-trash-can-outline c-danger'></span>
        </Button>
      </div>
      {/* <div className="w-100">
        <span className="fz-16px px-2">
          <Rating
            name="tour-rating"
            defaultValue={0}
            precision={0.5}
            size="small"
            icon={<span className="mdi mdi-star" />}
            disabled
            emptyIcon={<span className="mdi mdi-star-outline" />}
          />
        </span>
        <span className="fz-16px px-2">{`${360} ${getTranslate()(
          `${translationPath}reviews`
        )}`}</span>
      </div> */}

      {/* <div className="d-flex-v-center mb-2">
        <span className="mdi mdi-home px-2" />
        <span>{getTranslate()(`${translationPath}type`)}:</span>
        <span className="px-2 texts-truncate">{tour.tourType}</span>
      </div> */}
      {/* <div className="d-flex-v-center mb-2">
        <span className="mdi mdi-clock-time-five px-2" />
        <span>{getTranslate()(`${translationPath}total-time`)}:</span>
        <span className="px-2 texts-truncate">
          <span>{tour.tourDurationFormat}</span>
        </span>
      </div> */}
      <div className='d-flex-v-center mb-2'>
        <span className='mdi mdi-cash-multiple px-2' />
        <span>{getTranslate()(`${translationPath}total-price`)}:</span>
        <span className='px-2 texts-truncate'>{tour.totalFromPrice}</span>
      </div>
      <div className='d-flex-v-center mb-2'>
        <span className='mdi mdi-cash-multiple px-2' />
        <span>{getTranslate()(`${translationPath}total-price-after-discount`)}:</span>
        <span className='px-2 texts-truncate'>{tour.totalFromPriceAfterDiscount}</span>
      </div>
      <div className='trips-timeline-details-wrapper'>
        <Timeline
          className='timeline-wrapper'
          align={
            localStorage.getItem('localization') && localStorage.getItem('localization').isRtl
              ? 'right'
              : 'left'
          }>
          {tour.tourTrips &&
            tour.tourTrips.map((subItem, index) => (
              <TimelineItem key={`tripDetailsTimelineKey${index + 1}`}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                  <div className='cards-header'>
                    <div className='item-wrapper'>
                      <span className='item-header'>{subItem.trip && subItem.trip.tripName}</span>
                    </div>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <Rating
                          name='trip-rating'
                          defaultValue={5}
                          value={
                            (subItem.trip &&
                              subItem.trip.totalTripReview &&
                              subItem.trip.totalTripReview.rating) ||
                            0
                          }
                          precision={0.5}
                          size='small'
                          icon={<span className='mdi mdi-star' />}
                          disabled
                          emptyIcon={<span className='mdi mdi-star-outline' />}
                        />
                      </span>
                      <span className='item-body'>
                        {`${
                          (subItem.trip &&
                            subItem.trip.totalTripReview &&
                            subItem.trip.totalTripReview.reviews) ||
                          0
                        } ${getTranslate()(`${translationPath}reviews`)}`}
                      </span>
                    </div>
                  </div>
                  <div className='cards-body'>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-home px-2' />
                        <span>{getTranslate()(`${translationPath}type`)}:</span>
                      </span>
                      <span className='item-body'>
                        {(subItem.trip &&
                          subItem.trip.items &&
                          subItem.trip.items.forms &&
                          subItem.trip.items.forms.formsName) ||
                          'N/A'}
                      </span>
                    </div>
                    {/* <div className="item-wrapper">
                      <span className="item-header">
                        <span className="mdi mdi-clock-time-five px-2" />
                        <span>{getTranslate()(`${translationPath}time`)}:</span>
                      </span>
                      <span className="item-body">
                        <span>{subItem.trip &&
                          subItem.trip.coverTripImageId &&}</span>
                      </span>
                    </div> */}
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-clock-time-five px-2' />
                        <span>{getTranslate()(`${translationPath}duration`)}:</span>
                      </span>
                      <span className='item-body'>
                        {(subItem.trip && subItem.trip.tripDuration) || 'N/A'}
                      </span>
                    </div>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-cash-multiple px-2' />
                        <span>{getTranslate()(`${translationPath}price`)}:</span>
                      </span>
                      <span className='item-body'>
                        {(subItem.trip && subItem.trip.tripPrice) || 'N/A'}
                      </span>
                    </div>
                    <div className='item-wrapper mb-3'>
                      <span className='item-header'>
                        <span className='mdi mdi-map-marker px-2' />
                        <span>{getTranslate()(`${translationPath}address`)}:</span>
                      </span>
                      <span className='item-body'>
                        {(subItem.trip && subItem.trip.address) || 'N/A'}
                      </span>
                    </div>
                  </div>
                  <div className='cards-footer'>
                    {subItem.trip && subItem.trip.items && subItem.trip.items.itemImages && (
                      <div className='slide-images mx-2'>
                        {subItem.trip.items.itemImages.map((image, index) => (
                          <LoadableImageComponant
                            classes='slide-image'
                            key={`tourImageSliderRef${index + 1}`}
                            type={LoadableImageEnum.div.key}
                            alt={image.fileName || getTranslate()(`${translationPath}trip-image`)}
                            src={
                              (image.fileId && getDownloadableLink(image.fileId)) ||
                              DefaultImagesEnum.buildings.defaultImg
                            }
                            style={{
                              zIndex: subItem.trip.items.itemImages.length - index,
                              transform: `translateX(${
                                (getLanguage() === 'ar' &&
                                  -(index * (100 / subItem.trip.items.itemImages.length))) ||
                                index * (100 / subItem.trip.items.itemImages.length)
                              }%)`,
                            }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      </div>
    </div>
  );
};

TourDetailsComponent.propTypes = {
  tourId: PropTypes.number.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  translationPath: PropTypes.string,
};
TourDetailsComponent.defaultProps = {
  translationPath: 'home.tours.toursView.tourDetailsComponent.',
};
