import { combineReducers } from 'redux';
import { LoginReducer } from './Account/LoginReducer';
import { RememberMeReducer } from './Account/RememberMeReaducer';
import { LoggerReducer } from './Logger/LoggerReducer';
import { CarTypeReducer } from './CarType/CarTypeReducer';
import formbuilder from './FormBuilder';

export const Reducers = combineReducers({
  LoginReducer,
  RememberMeReducer,
  formbuilder,
  LoggerReducer,
  CarTypeReducer,
});
