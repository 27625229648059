import React, { useState } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { Inputs } from '../../../../Componentes';

export default function PriceAndPercentage({
 type, onChange, currency, value
}) {
  const [salePrice, setSalePrice] = useState(value && value.salePrice ? value.salePrice : 0);
  const [agencyFee, setAgencyFee] = useState(value && value.agencyFee ? value.agencyFee : 0);
  const [persantageFee, setPersantageFee] = useState(
    value && value.persantageFee ? value.persantageFee : 0
  );
  const [rentPerSq, setRentPerSq] = useState(value && value.rentPerSq ? value.rentPerSq : 0);
  const [rentPerYear, setRentPerYear] = useState(
    value && value.rentPerYear ? value.rentPerYear : 0
  );
  const [rentPerMonth, setRentPerMonth] = useState(
    value && value.rentPerMonth ? value.rentPerMonth : 0
  );
  const [commission, setcommission] = useState(value && value.commission ? value.commission : 0);
  const [commissionPer, setCommissionPer] = useState(
    value && value.commissionPer ? value.commissionPer : 0
  );
  const [deposit, setDeposit] = useState(value && value.deposit ? value.deposit : 0);
  const [depositPer, setDepositPer] = useState(value && value.depositPer ? value.depositPer : 0);

  return (
    <>
      {type === 'Sale Type' && (
        <Grid container>
          <Grid item sm={12} className="mb-2 px-2">
            <Inputs
              idRef="priceRef1"
              startAdornment={(
                <InputAdornment position="start" className="px-2">
                  {currency}
                </InputAdornment>
              )}
              labelValue="Selling Price"
              value={salePrice}
              onInputChanged={(e) => {
                if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                  setSalePrice(Number(e.target.value));
                  setAgencyFee(0);
                  setPersantageFee(0);
                  onChange({ salePrice, agencyFee, persantageFee });
                }
              }}
            />
          </Grid>
          <Grid container alignItems="flex-end">
            <Grid item sm={8} className="mb-2 px-2">
              <Inputs
                idRef="priceRef2"
                startAdornment={(
                  <InputAdornment position="start" className="px-2">
                    {currency}
                  </InputAdornment>
                )}
                endAdornment={(
                  <InputAdornment className="px-2" position="end">
                    OR
                  </InputAdornment>
                )}
                labelValue="Agency Fee"
                value={agencyFee}
                onInputChanged={(e) => {
                  if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                    const number = Number(e.target.value);
                    if (number >= salePrice) {
                      setAgencyFee(salePrice);
                      setPersantageFee((salePrice / salePrice) * 100);
                      onChange({
                        salePrice,
                        agencyFee: salePrice,
                        persantageFee: (salePrice / salePrice) * 100,
                      });
                    } else {
                      setAgencyFee(number);
                      setPersantageFee(Math.ceil((number / salePrice) * 100));
                      onChange({
                        salePrice,
                        agencyFee: number,
                        persantageFee: Math.ceil((number / salePrice) * 100),
                      });
                    }
                  }
                }}
              />
            </Grid>
            <Grid item sm={4} className="px-2 mb-2">
              <Inputs
                idRef="priceRef3"
                endAdornment={(
                  <InputAdornment className="px-2" position="end">
                    %
                  </InputAdornment>
                )}
                labelValue=""
                value={persantageFee}
                onInputChanged={(e) => {
                  if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                    const number = Number(e.target.value);
                    if (number >= 100) {
                      setAgencyFee(salePrice);
                      setPersantageFee(100);
                      onChange({ salePrice, agencyFee: salePrice, persantageFee: 100 });
                    } else {
                      setAgencyFee((number / 100) * salePrice);
                      setPersantageFee(number);
                      onChange({
                        salePrice,
                        agencyFee: (number / 100) * salePrice,
                        persantageFee: number,
                      });
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {type === 'Rent Type' && (
        <Grid container>
          <Grid item md={12} className="px-2 mb-2">
            <Inputs
              idRef="priceRef4"
              startAdornment={(
                <InputAdornment position="start" className="px-2">
                  {currency}
                </InputAdornment>
              )}
              labelValue="Rent per Sq.ft"
              value={rentPerSq}
              onInputChanged={(e) => {
                if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                  setRentPerSq(Number(e.target.value));
                  setRentPerYear(0);
                  setRentPerMonth(0);
                  setcommission(0);
                  setCommissionPer(0);
                  setDeposit(0);
                  setDepositPer(0);
                  onChange({
                    rentPerSq: Number(e.target.value),
                    rentPerYear: 0,
                    rentPerMonth: 0,
                    commission: 0,
                    commissionPer: 0,
                    deposit: 0,
                    depositPer: 0,
                  });
                }
              }}
            />
          </Grid>

          <Grid item md={12} className="px-2 mb-2">
            <Inputs
              idRef="priceRef5"
              startAdornment={(
                <InputAdornment position="start" className="px-2">
                  {currency}
                </InputAdornment>
              )}
              labelValue="Rent per Year"
              value={rentPerYear}
              onInputChanged={(e) => {
                if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                  setRentPerSq(0);
                  setRentPerYear(Number(e.target.value));
                  setRentPerMonth(Number(e.target.value) / 12);
                  setcommission(0);
                  setCommissionPer(0);
                  setDeposit(0);
                  setDepositPer(0);
                  onChange({
                    rentPerSq: 0,
                    rentPerYear: Number(e.target.value),
                    rentPerMonth: Number(e.target.value) / 12,
                    commission: 0,
                    commissionPer: 0,
                    deposit: 0,
                    depositPer: 0,
                  });
                }
              }}
            />
          </Grid>
          <Grid item md={12} className="px-2 mb-2">
            <Inputs
              idRef="priceRef6"
              startAdornment={(
                <InputAdornment position="start" className="px-2">
                  {currency}
                </InputAdornment>
              )}
              labelValue="Rent per Month"
              value={rentPerMonth}
              onInputChanged={(e) => {
                if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                  setRentPerSq(0);
                  setRentPerYear(Number(e.target.value) * 12);
                  setRentPerMonth(Number(e.target.value));
                  setcommission(0);
                  setCommissionPer(0);
                  setDeposit(0);
                  setDepositPer(0);
                  onChange({
                    rentPerSq,
                    rentPerYear,
                    rentPerMonth,
                    commission,
                    commissionPer,
                    deposit,
                    depositPer,
                  });
                }
              }}
            />
          </Grid>

          <Grid container alignItems="flex-end">
            <Grid item sm={8} className="px-2 mb-2">
              <Inputs
                idRef="priceRef7"
                startAdornment={(
                  <InputAdornment position="start" className="px-2">
                    {currency}
                  </InputAdornment>
                )}
                endAdornment={(
                  <InputAdornment position="end" className="px-2">
                    OR
                  </InputAdornment>
                )}
                labelValue="Commission"
                value={commission}
                onInputChanged={(e) => {
                  if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                    const number = Number(e.target.value);
                    if (number >= rentPerYear) {
                      setcommission(rentPerYear);
                      if (rentPerYear !== 0) setCommissionPer((rentPerYear / rentPerYear) * 100);

                      onChange({
                        rentPerSq: 0,
                        rentPerYear,
                        rentPerMonth,
                        commission: rentPerYear,
                        commissionPer: 100,
                        deposit,
                        depositPer,
                      });
                    } else {
                      setcommission(number);
                      setCommissionPer(Math.ceil((number / rentPerYear) * 100));
                      onChange({
                        rentPerSq: 0,
                        rentPerYear,
                        rentPerMonth,
                        commission: number,
                        commissionPer: Math.ceil((number / rentPerYear) * 100),
                        deposit,
                        depositPer,
                      });
                    }
                  }
                }}
              />
            </Grid>
            <Grid item sm={4} className="px-2 mb-2">
              <Inputs
                idRef="priceRef8"
                endAdornment={(
                  <InputAdornment position="end" className="px-2">
                    %
                  </InputAdornment>
                )}
                labelValue=""
                value={commissionPer}
                onInputChanged={(e) => {
                  if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                    const number = Number(e.target.value);
                    if (number >= 100) {
                      setcommission(rentPerYear);
                      setCommissionPer(100);
                      onChange({
                        rentPerSq,
                        rentPerYear,
                        rentPerMonth,
                        commission: rentPerYear,
                        commissionPer: 100,
                        deposit,
                        depositPer,
                      });
                    } else {
                      setcommission((number / 100) * rentPerYear);
                      setCommissionPer(number);

                      onChange({
                        rentPerSq,
                        rentPerYear,
                        rentPerMonth,
                        commission: rentPerYear,
                        commissionPer: number,
                        deposit,
                        depositPer,
                      });
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          {/*  */}
          <Grid container alignItems="flex-end">
            <Grid item sm={8} className="px-2 mb-2">
              <Inputs
                idRef="priceRef9"
                startAdornment={(
                  <InputAdornment position="start" className="px-2">
                    {currency}
                  </InputAdornment>
                )}
                endAdornment={(
                  <InputAdornment position="end" className="px-2">
                    OR
                  </InputAdornment>
                )}
                labelValue="Deposit"
                value={deposit}
                onInputChanged={(e) => {
                  if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                    const number = Number(e.target.value);
                    if (rentPerSq === 0) {
                      setDeposit(number);
                      setDepositPer((number / rentPerYear) * 100);
                      onChange({
                        rentPerSq,
                        rentPerYear,
                        rentPerMonth,
                        commission,
                        commissionPer,
                        deposit: number,
                        depositPer: (number / rentPerYear) * 100,
                      });
                    } else {
                      setDeposit(number);
                      setDepositPer('AED');
                      onChange({
                        rentPerSq,
                        rentPerYear,
                        rentPerMonth,
                        commission,
                        commissionPer,
                        deposit: number,
                        depositPer: 'AED',
                      });
                    }
                  }
                }}
              />
            </Grid>
            <Grid item sm={4} className="px-2 mb-2">
              <Inputs
                idRef="priceRef10"
                endAdornment={(
                  <InputAdornment position="end" className="px-2">
                    %
                  </InputAdornment>
                )}
                labelValue=""
                value={depositPer}
                onInputChanged={(e) => {
                  if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                    const number = Number(e.target.value);
                    if (rentPerSq === 0) {
                      setDeposit((number / 100) * rentPerYear);
                      setDepositPer(number);
                      onChange({
                        rentPerSq,
                        rentPerYear,
                        rentPerMonth,
                        commission,
                        commissionPer,
                        deposit: (number / 100) * rentPerYear,
                        depositPer: number,
                      });
                    } else {
                      setDepositPer('AED');

                      onChange({
                        rentPerSq,
                        rentPerYear,
                        rentPerMonth,
                        commission,
                        commissionPer,
                        deposit,
                        depositPer: 'AED',
                      });
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
