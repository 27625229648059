import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import { Spinner, Tables, Inputs } from '../../../../Componentes';
import { getTranslate, GetParams, showSuccess ,showError} from '../../../../Helpers';

import { TableActions } from '../../../../Enums';
import {
  lookupItemsDelete,
  lookupItemsGet,
  lookupItemsPost,
  lookupItemsPut,
} from '../../../../Serviecs';
import { LookupsDeleteDialog, LookupsItemCreateDialog } from '../LookupsUtilities';
import './LookupsItemView.scss';
import { useCallback } from 'react';

const LookupsItemView = (props) => {
  const [hasParent, setHasParent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openCreateItemDialog, setOpenCreateItemDialog] = useState(false);
  const [selectedLookupItem, setSelectedLookupItem] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [response, setResponse] = useState([]);
  const [typeName] = useState(GetParams('name'));
  const [typeId] = useState(GetParams('id') ? GetParams('id') : 0);
  const [typeEditable] = useState(GetParams('isEdit') === 'true');
  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 10,
    searchedItem: '',
  });
  const [isEdit, setIsEdit] = useState(false);
  const time = useRef(null);

  const reducer = (state, action) => {
    if (action.reset) {
      setHasParent(false);
      return {
        lookupTypeId: state.lookupTypeId,
        lookupItemName: (props.item && props.item.lookupItemName) || '',
        parentLookupTypeName: (props.item && props.item.parentLookupTypeName) || '',
        parentLookupItemName: (props.item && props.item.parentLookupItemName) || '',
        description: (props.item && props.item.description) || '',
        lookupItemCode: (props.item && props.item.lookupItemCode) || '',
      };
    }
    return { ...state, [action.id]: action.value };
  };
  const [state, setState] = React.useReducer(
    reducer,
    props.item
      ? {
          ...props.item,
          parentLookupTypeName: props.item.parentLookupTypeName || '',
          description: props.item.description || '',
          lookupItemCode: props.item.lookupItemCode || '',
        }
      : {}
  );

  const loadItem = useCallback(async () => {
    setLoading(true);
    const resp = await lookupItemsGet({
      pageIndex: filter.page + 1,
      pageSize: filter.rowsPerPage,
      lookupTypeName: typeName,
      searchedItem: filter.searchedItem,
    });
    setResponse(resp);
    setLoading(false);
  }, [filter.page, filter.rowsPerPage, filter.searchedItem, typeName]);

  useEffect(() => {
    loadItem();
  }, [loadItem, filter, typeName]);
  const searchHandler = (e) => {
    const value = e.target.value;
    if (time.current) clearTimeout(time.current);
    time.current = setTimeout(() => {
      setFilter((items) => ({ ...items, searchedItem: value }));
    }, 700);
  };
  const handlePageChange = (e, newPage) => {
    setFilter((items) => ({ ...items, page: newPage }));
  };
  useEffect(() => {
    return () => {
      if (time.current) clearTimeout(time.current);
    };
  }, [time]);
  useEffect(() => {
    setState({
      id: 'lookupTypeId',
      value: parseInt(typeId),
    });
  }, [typeId]);
  const handlePageRowChange = async (e) => {
    setFilter((items) => ({ ...items, page: 0, rowsPerPage: parseInt(e.target.value, 10) }));
  };

  return (
    <>
      <div className='lookups-items-view view-wrapper'>
        <Spinner isActive={loading} />
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section'>
              <Button
                className='btns theme-solid bg-secondary mx-2'
                disabled={!typeEditable}
                onClick={() => {
                  setIsEdit(false);
                  setOpenCreateItemDialog(true);
                  setSelectedLookupItem('');
                }}>
                {getTranslate()('home.lookupsItemView.add-item')}
              </Button>
            </div>
            <div className='section px-2'>
              <div className='d-flex-column p-relative'>
                <Inputs
                  idRef='lookupItemSearchRef'
                  variant='outlined'
                  fieldClasses='inputs theme-solid'
                  translationPath={'Lookups.'}
                  label='filter'

                  beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                  onKeyUp={searchHandler}
                  inputPlaceholder='search-by-lookup-name'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='px-3'>
          <Tables
            headerData={[
              {
                id: 1,
                isSortable: true,
                label: 'Lookups.LookupItems.LookupName',
                input: 'lookupItemName',
                isDate: false,
              },
              {
                id: 2,
                isSortable: true,
                label: 'Lookups.LookupItems.LookupParentName',
                input: 'parentLookupItemName',
                isDate: false,
              },
              {
                id: 3,
                isSortable: true,
                label: 'Lookups.LookupItems.LookupParentType',
                input: 'parentLookupTypeName',
                isDate: false,
              },
              {
                id: 4,
                isSortable: true,
                label: 'Lookups.LookupItems.description',
                input: 'description',
                isDate: false,
              },
              {
                id: 4,
                isSortable: true,
                label: 'Lookups.LookupItems.code',
                input: 'lookupItemCode',
                isDate: false,
              },
            ]}
            data={response && response.result ? response.result : []}
            activePage={filter.page}
            totalItems={response && response.totalCount ? response.totalCount : 0}
            activePageChanged={handlePageChange}
            itemsPerPage={filter.rowsPerPage}
            itemsPerPageChanged={handlePageRowChange}
            actionsOptions={{
              actions: [
                {
                  enum: TableActions.edit.key,
                  isDisabled: false,
                  externalComponent: null,
                },
                {
                  enum: TableActions.delete.key,
                  isDisabled: false,
                  externalComponent: null,
                },
              ],
              classes: '',
              isDisabled: GetParams('isEdit') === 'false',
              isReverceDisabled: true,
              actionsIsDisabledInput: 'isEditable',
              onActionClicked: (key, item) => {
                if (key === TableActions.edit.key) {
                  setIsEdit(true);
                  setSelectedLookupItem(item);
                  // setState((items) => ({
                  //   ...items,
                  //   parentLookupItemName: item.parentLookupItemName || '',
                  // }));
                  setOpenCreateItemDialog(true);
                }
                if (key === TableActions.delete.key) {
                  setSelectedLookupItem(item);
                  setOpenDeleteDialog(true);
                }
                for (const propsrty in item)
                  setState({ id: propsrty, value: item[propsrty] || '' });

                if (item && item.parentLookupItemId) setHasParent(true);
              },
            }}
          />
        </div>
      </div>
      {selectedLookupItem && (
        <>
          <LookupsDeleteDialog
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            name={selectedLookupItem.lookupItemName}
            row={selectedLookupItem}
            onCancel={() => props.setState({ reset: true })}
            onDelete={async (e) => {
              await lookupItemsDelete({ lookupItemId: e.lookupItemId });
              setFilter((items) => ({ ...items, page: 0 }));
              setState({ reset: true });
            }}
          />
        </>
      )}
      <LookupsItemCreateDialog
        isEdit={isEdit}
        hasParent={hasParent}
        setHasParent={setHasParent}
        setState={setState}
        state={state}
        open={openCreateItemDialog}
        setOpen={setOpenCreateItemDialog}
        item={selectedLookupItem}
        selectedType={typeName}
        selectedTypeId={typeId}
        onSave={async (e) => {
          const response = await lookupItemsPut({ body: e, lookupItemId: e.lookupItemId });
          if (response) {
            showSuccess(getTranslate()('Lookups.LookupItems.lookup-item-updated-successfully'));
            setFilter((items) => ({ ...items, page: 0 }));
          } else showError(getTranslate()('Lookups.LookupItems.lookup-item-updating-failed'));
        }}
        onCreate={async (e) => {
          const response = await lookupItemsPost({ body: e });
          if (response) {
            showSuccess(getTranslate()('Lookups.LookupItems.lookup-item-created-successfully'));
            setFilter((items) => ({ ...items, page: 0 }));
          } else showError(getTranslate()('Lookups.LookupItems.lookup-item-creating-failed'));
        }}
      />
    </>
  );
};

export { LookupsItemView };
