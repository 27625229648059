import React, { useState, useEffect, useRef } from 'react';
import image from '../../Assets/Images/defaults/loader.gif';
import PropTypes from 'prop-types';
import { LoadableImageEnum } from '../../Enums';
import './LoadableImageStyle.scss';
export const LoadableImageComponant = ({ src, alt, type, classes, ...props }) => {
  const [state, setState] = useState(false);
  const mounted = useRef(true);
  useEffect(() => {
    if (type === LoadableImageEnum.div.key) {
      const bgImg = new Image();
      bgImg.src = src;
      bgImg.onload = function () {
        if (mounted.current) setState(true);
      };
    }
  }, [mounted, src, type]);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  return (
    <>
      {type === LoadableImageEnum.image.key && (
        <img
          src={src}
          alt={alt}
          className={`loadable-image-componant ${classes} ${state ? ' show' : ' hidden'}`}
          onLoad={() => {
            setState(true);
          }}
          {...props}
        />
      )}
      {type === LoadableImageEnum.div.key && state && (
        <div
          className={`loadable-image-componant ${classes}`}
          aria-label={alt}
          {...props}
          style={{
            ...props.style,
            backgroundImage: `url(${src})`,
          }}
        />
      )}
      <div
        className={`loadable-image-componant image-loader ${classes} ${
          !state ? ' show' : ' hidden'
        }`}
        aria-label={alt}
        {...props}
        style={{
          ...props.style,
          backgroundImage: `url(${image})`,
        }}
      />
    </>
  );
};
LoadableImageComponant.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(LoadableImageEnum).map((item) => item.key)),
  classes: PropTypes.string,
  props: PropTypes.instanceOf(Object),
};
LoadableImageComponant.defaultProps = {
  type: LoadableImageEnum.image.key,
  classes: undefined,
  props: undefined,
};
