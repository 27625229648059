import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from '../../../../Helpers';
import { useTitle } from '../../../../Hooks/useTitle';
import { GetAllPendingReservationTour } from '../../../../Serviecs';
import { NoContentComponent, Spinner } from '../../../../Componentes';
import { PendingToursCardComponent, PendingToursManagementDialog } from '../PendingToursUtilities';

export const PendingToursListView = ({
  pendingTours,
  activeView,
  pendingToursChanged,
  filter,
  translationPath,
  onFilterChanged,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pendingToursLocal, setPendingToursLocal] = useState({
    result: [],
    totalCount: 0,
  });
  const [isOpenManagement, setIsOpenManagement] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  useTitle(getTranslate()(`${translationPath}pending-tours`));
  const onActionClicked = (actionKey, item) => {
    setActiveItem(item);
    setIsOpenManagement(true);
  };
  const getPendingTours = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllPendingReservationTour(filter);

    if (filter.pageIndex === 0) {
      setPendingToursLocal({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else
      setPendingToursLocal((item) => ({
        result: item.result.concat((res && res.result) || []),
        totalCount: (res && res.totalCount) || 0,
      }));

    setIsLoading(false);
  }, [filter]);
  const onLoadMoreHandler = useCallback(() => {
    if (onFilterChanged) onFilterChanged({ ...filter, pageIndex: filter.pageIndex + 1 });
  }, [filter, onFilterChanged]);
  useEffect(() => {
    if (pendingToursChanged) pendingToursChanged(pendingToursLocal);
  }, [pendingToursChanged, pendingToursLocal]);
  useEffect(() => {
    getPendingTours();
  }, [getPendingTours, filter]);
  return (
    activeView === 'Pending' && (
      <div className='pending-tours-wrapper'>
        <Spinner isActive={isLoading} />
        {((pendingTours && !Array.isArray(pendingTours.result)) ||
          pendingTours.result.length === 0) &&
          !filter.search && <NoContentComponent />}
        <PendingToursCardComponent
          data={pendingTours}
          filter={filter}
          onLoadMore={onLoadMoreHandler}
          onActionClicked={onActionClicked}
          translationPath={translationPath}
          isLoading={isLoading}
        />
        {isOpenManagement && (
          <PendingToursManagementDialog
            activeItem={activeItem}
            isOpen={isOpenManagement}
            isOpenChanged={() => {
              setIsOpenManagement(false);
              setActiveItem(null);
            }}
            reloadData={() => {
              if (onFilterChanged) onFilterChanged({ ...filter, pageIndex: 0 });
              setActiveItem(null);
              setIsOpenManagement(false);
            }}
            translationPath={translationPath}
          />
        )}
      </div>
    )
  );
};

PendingToursListView.propTypes = {
  filter: PropTypes.instanceOf(Object).isRequired,
  pendingTours: PropTypes.shape({
    result: PropTypes.array,
    totalCount: PropTypes.number,
  }).isRequired,
  activeView: PropTypes.string.isRequired,
  pendingToursChanged: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
};
