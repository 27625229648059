import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadableImageComponant } from '../LoadableImageComponant/LoadableImageComponant';
import { ActionsEnum, DefaultImagesEnum, LoadableImageEnum } from '../../Enums';
import { getDownloadableLink, getTranslate } from '../../Helpers';
import { getLanguage } from 'react-switch-lang';
import './SlideImagesComponent.scss';
import { ButtonBase } from '@material-ui/core';
import { FacebookGalleryComponent } from '../FacebookGalleryComponent/FacebookGalleryComponent';

export const SlideImagesComponent = ({
  data,
  translationPath,
  imageInput,
  defaultImage,
  withViewGallery,
  keyRef,
  altInput,
  alt,
  titleText,
}) => {
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const dataReturn = (dataItem, dataPath) => {
    if (!dataPath) return dataItem;
    if (!dataPath.includes('.')) return dataItem[dataPath] || dataItem[dataPath] + '';
    let a = dataItem;
    dataPath.split('.').map((item) => {
      a = a[item];
      return item;
    });
    return a;
  };
  return (
    data &&
    data.length > 0 && (
      <div className={`slide-images${withViewGallery && ' with-gallery'}`}>
        {data.map((image, index) => (
          <LoadableImageComponant
            classes='slide-image'
            key={`${keyRef}${index + 1}`}
            type={LoadableImageEnum.div.key}
            alt={getTranslate()(
              `${translationPath}${(altInput && dataReturn(image, altInput)) || alt}`
            )}
            src={
              (imageInput &&
                dataReturn(image, imageInput) &&
                getDownloadableLink(dataReturn(image, imageInput))) ||
              defaultImage
            }
            style={{
              zIndex: data.length - index,
              transform: `translateX(${
                (getLanguage() === 'ar' && -(index * (100 / data.length))) ||
                index * (100 / data.length)
              }%)`,
            }}
          />
        ))}
        {withViewGallery && (
          <ButtonBase
            className={`${ActionsEnum.viewOutline.buttonClasses} gallery-view-btn`}
            onClick={() => setIsOpenGallery((item) => !item)}>
            <span className={`${ActionsEnum.viewOutline.icon}`} />
          </ButtonBase>
        )}
        {withViewGallery && isOpenGallery && (
          <FacebookGalleryComponent
            isOpen={isOpenGallery}
            onOpenChanged={() => setIsOpenGallery((item) => !item)}
            titleText={titleText}
            data={data}
            imageInput={imageInput}
            translationPath={translationPath}
            defaultImage={defaultImage}
            keyRef={keyRef}
            altInput={altInput}
            alt={alt}
          />
        )}
      </div>
    )
  );
};

SlideImagesComponent.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  defaultImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(Object.values(DefaultImagesEnum).map((item) => item.defaultImg)),
  ]),
  altInput: PropTypes.string,
  imageInput: PropTypes.string,
  translationPath: PropTypes.string,
  keyRef: PropTypes.string,
  withViewGallery: PropTypes.bool,
  alt: PropTypes.string,
  titleText: PropTypes.string,
};
SlideImagesComponent.defaultProps = {
  defaultImage: DefaultImagesEnum.buildings.defaultImg,
  imageInput: null,
  translationPath: '',
  keyRef: 'imageSliderRef',
  altInput: null,
  withViewGallery: false,
  alt: null,
  titleText: undefined,
};
