import React, { useEffect, useCallback, useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { getTranslate } from ' ./../../src/Helpers';
import { GetAllParking, SearchParking } from './../../../Serviecs/Parking/ParkingService';
import DialogAddParking from './DialogParking/DialogAddParking';
import { SliderParking } from './ParkingList/ParkingUtilties/ParkingCradSlider/SliderParking';
import DialogEditParking from './DialogParking/DialogEditParking';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';
import DialogDeleteParking from '../Parking/DialogParking/DialogDeleteParking';
import { Tables, Spinner } from '../../../Componentes';
import { TableActions } from '../../../Enums';
import { Grid, TextField, FormControl } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { useTitle } from '../../../Hooks/useTitle';
import {
  NoDataFoundComponent,
  NoSearchResultComponent,
  NoContentComponent,
} from '../../../Componentes';

const ParkingView = () => {
  const [loading, setLoading] = useState(false);
  const [openAddDialog, setopenAddDialog] = useState(false);
  const [openEditDialog, setopenEditDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setsearch] = useState('');
  const [res, setres] = useState({});
  const [delteid, setdelteid] = useState('');
  const [NameofBrak, setNameofBrak] = useState('');
  const [openDialogDelete, setDialogDelete] = useState(false);
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [Parkingstae, setParkingstae] = useState(() => ({
    parkingid: ' ',
    parkingName: '',
    parkingNumber: '',
    cityId: '',
    latitude: '',
    longitude: '',
    Status: '',
    address: '',
  }));
  let time = setTimeout(() => {}, 300);
  const translationPath = 'Parking.';

  useTitle(getTranslate()(`SideMenuView.MobilePages.Parking`));

  const GetAllParkingApi = useCallback(async (pageIndex, pageSize) => {
    setLoading(true);
    const AllParkingres = await GetAllParking({ pageIndex, pageSize });
    setres(AllParkingres);
    if (AllParkingres && AllParkingres.totalCount === 0) {
      setisFirstLoad(false);
    }
    setLoading(false);
    if (AllParkingres && AllParkingres.totalCount === 0) {
      setisFirstLoad(false);
    }
  }, []);

  useEffect(() => {
    GetAllParkingApi(1, 10);
  }, [GetAllParkingApi, setres]);

  const handleCarsTypesApiSearch = async (e) => {
    const AllParkingres = await SearchParking(page + 1, rowsPerPage, e);
    setres(AllParkingres);
  };

  const openEditDialogslider = useCallback(() => {
    setopenEditDialog(true);
  }, []);

  const openDleteDialogslider = useCallback(() => {
    setDialogDelete(true);
  }, []);

  const EditCradClicked = useCallback(
    (item) => {
      sideMenuComponentUpdate(
        <SliderParking
          setSearch={item}
          openEditDialogslider={openEditDialogslider}
          openDleteDialogslider={openDleteDialogslider}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [openDleteDialogslider, openEditDialogslider]
  );

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const handlePageChange = async (e, newPage) => {
    setPage(newPage);
    GetAllParkingApi(newPage + 1, rowsPerPage);
  };
  const handlePageRowChange = async (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
    GetAllParkingApi(page + 1, parseInt(e.target.value, 10));
  };

  const focusedRowChanged = (activeRow) => {
    const item = res.result[activeRow];
    if (!item) return;
    setdelteid(item.parkingId);
    setNameofBrak(item.parkingName);
    EditCradClicked(item);
    setParkingstae({
      parkingid: item.parkingId,
      parkingName: item.parkingName,
      parkingNumber: item.parkingNumber,
      cityId: item.cityId,
      latitude: item.latitude,
      longitude: item.longitude,
      Status: item.status,
      address: item.address,
    });
  };
  return (
    <>
      <div className='view-wrapper'>
        <Spinner isActive={loading} />
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section'>
              <Button
                className='btns theme-solid   btcolor '
                onClick={() => setopenAddDialog(true)}>
                {getTranslate()(`${translationPath}Addparking`)}
              </Button>
            </div>
            <div className='section px-2'>
              <div className='d-flex-column p-relative'>
                <FormControl className='input-wrapper'>
                  <Button className='btns-icon theme-transparent mx-2'>
                    <span className='mdi mdi-magnify' />
                  </Button>

                  <TextField
                    className='inputs theme-solid'
                    value={search}
                    onKeyUp={() => {
                      time = setTimeout(() => {
                        setPage(0);
                        handleCarsTypesApiSearch(search);
                      }, 700);
                    }}
                    onKeyDown={() => {
                      clearTimeout(time);
                    }}
                    onChange={(e) => {
                      setsearch(e.target.value);
                    }}
                    label={getTranslate()(`${translationPath}Search`)}
                    placeholder={getTranslate()(`${translationPath}search-by-parking-name`)}
                    variant='outlined'
                    size='small'
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {search && (
                            <IconButton
                              onClick={() => {
                                setsearch('');

                                GetAllParkingApi(page + 1, rowsPerPage);
                              }}>
                              <ClearIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <Grid container justify='center' alignContent='center'>
          <Grid item xs={12} className='mx-3'>
            {res && res.totalCount === 0 && search.length !== 0 && isFirstLoad ? (
              <NoSearchResultComponent />
            ) : (
              <>
                {res && res.totalCount !== 0 && (
                  <div className='mx-3'>
                    <Tables
                      headerData={[
                        {
                          id: 1,
                          isSortable: true,
                          label: getTranslate()(`${translationPath}parkingName`),
                          input: 'parkingName',
                          isDate: false,
                        },
                        {
                          id: 2,
                          isSortable: true,
                          label: getTranslate()(`${translationPath}parkingNumber`),
                          input: 'parkingNumber',
                          isDate: false,
                        },
                        {
                          id: 3,
                          isSortable: true,
                          label: getTranslate()(`${translationPath}cityId`),
                          input: 'cityName',
                          isDate: false,
                        },
                        {
                          id: 4,
                          isSortable: true,
                          label: getTranslate()(`${translationPath}latitude`),
                          input: 'latitude',
                          isDate: false,
                        },
                        {
                          id: 5,
                          isSortable: true,
                          label: getTranslate()(`${translationPath}longitude`),
                          input: 'longitude',
                          isDate: false,
                        },
                        {
                          id: 6,
                          isSortable: true,
                          label: getTranslate()(`${translationPath}Status`),
                          input: 'status',
                          isDate: false,
                        },
                      ]}
                      data={
                        res && res.result && res.result && Array.isArray(res.result)
                          ? res.result
                          : []
                      }
                      activePage={page}
                      totalItems={res && res.totalCount ? res.totalCount : 0}
                      activePageChanged={handlePageChange}
                      itemsPerPage={rowsPerPage}
                      focusedRowChanged={focusedRowChanged}
                      itemsPerPageChanged={handlePageRowChange}
                      actionsOptions={{
                        actions: [
                          {
                            enum: TableActions.edit.key,
                            isDiabled: false,
                            externalComponent: null,
                          },
                          {
                            enum: TableActions.delete.key,
                            isDiabled: false,
                            externalComponent: null,
                          },
                        ],
                        classes: '',
                        isDisabled: false,
                        onActionClicked: (key, item, index) => {
                          if (key === 'delete') {
                            setdelteid(item.parkingId);
                            setDialogDelete(true);
                          } else if (key === 'edit') {
                            setopenEditDialog(true);
                            setParkingstae({
                              parkingid: item.parkingId,
                              parkingName: item.parkingName,
                              parkingNumber: item.parkingNumber,
                              cityId: item.cityId,
                              latitude: item.latitude,
                              longitude: item.longitude,
                              Status: item.status,
                              address: item.address,
                            });
                          }
                        },
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <NoDataFoundComponent />
        {res && res.result && res.result.length === 0 && search.length === 0 && !isFirstLoad && (
          <NoContentComponent />
        )}
      </div>
      <DialogDeleteParking
        open={openDialogDelete}
        reloadData={async () => await GetAllParkingApi(page + 1, rowsPerPage)}
        delteid={delteid}
        NameofBrak={NameofBrak}
        close={() => setDialogDelete(false)}
      />
      <DialogAddParking
        open={openAddDialog}
        close={() => setopenAddDialog(false)}
        reloadData={async () => await GetAllParkingApi(page + 1, rowsPerPage)}
      />
      <DialogEditParking
        open={openEditDialog}
        close={() => setopenEditDialog(false)}
        Parkingstae={Parkingstae}
        setParkingstae={setParkingstae}
        reloadData={async () => await GetAllParkingApi(page + 1, rowsPerPage)}
      />
    </>
  );
};
export { ParkingView };
