export const OfferedToursStatusEnum = {
  active: {
    key: 1,
    value: 'active',
  },
  inactive: {
    key: 2,
    value: 'inactive',
  },
  expired: {
    key: 3,
    value: 'expired',
  },
};
