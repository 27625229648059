import config from '../../Assets/Configration/Configration';
import { HttpServices } from '../HttpServices/HttpServices';

export const GetAllOnGoingReservationTour = async (search = '') => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/TourTransaction/GetAllOnGoingReservationTour?search=${search}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetReservationTourById = async (reservationTourId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/TourTransaction/GetReservationTourById/${reservationTourId}`
    );
    return result;
  } catch (e) {
    return;
  }
};
