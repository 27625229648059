export const FilterTypeEnum = [
  {
    key: 'select',
    value: null,
  },
  {
    key: 'Highest Rating',
    value: 1,
  },
  {
    key: 'Most Viewed',
    value: 2,
  },
  {
    key: 'Best Seller',
    value: 3,
  },
  {
    key: 'Near By',
    value: 4,
  },
  {
    key: 'Customize',
    value: 5,
  },
];
