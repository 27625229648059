import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';

export const uploadFile = async ({ file }) => {
  const body = new FormData();
  body.append('file', file);

  try {
    const result = await HttpServices.post(`${config.serverAddress}/FileManager/File/Upload`, body);
    return result;
  } catch (e) {
    if (e.response && e.response) {
      throw e.response;
    } else {
      throw e;
    }
  }
};
