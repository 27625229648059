import { RememberMeStatus } from '../../States';

function StoreRememberMe(payload) {
  return { type: RememberMeStatus.StoreRememberMe, payload };
}

function ResetRememberMe(payload) {
  return { type: RememberMeStatus.ResetRememberMe, payload };
}
function getRememberMeError(payload) {
  return { type: RememberMeStatus.RememberMeError, payload };
}

export const RememberMeAction = {
  StoreRememberMe,
  ResetRememberMe,
  getRememberMeError
};
