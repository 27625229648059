export const GoogleMapTravelModeEnum = {
    DRIVING: {
      key: 'DRIVING', // must be unique
    },
    WALKING: {
      key: 'WALKING',
    },
    BICYCLING: {
      key: 'BICYCLING',
    },
    TRANSIT: {
      key: 'TRANSIT',
    },
  };
  