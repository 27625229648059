import React, { useState ,useCallback ,useEffect } from 'react'; 
import { Tables } from '../../../../../Componentes';
import { TableActions } from '../../../../../Enums';
import PropTypes from 'prop-types';
import UserSlider from '../UserUtilties/UserSlider/UserSlider';
import { getTranslate, sideMenuIsOpenUpdate, sideMenuComponentUpdate, } from '../../../../../Helpers';
const UserListView = ({
  data,
  filter,
  actionClicked,
  translationPath,
  activePageChanged,
  itemsPerPageChanged,
}) => {
  const [currentActions, setCurrentActions] = useState(() => []);
  const getUserActions = (userStatus) =>
    (userStatus === 'Pending' && [
      { enum: TableActions.check.key },
      { enum: TableActions.close.key },
      { enum: TableActions.delete.key },
    ]) ||
    (userStatus === 'Canceled' && [
      { enum: TableActions.check.key },
      { enum: TableActions.delete.key },
    ]) || [
      // { enum: TableActions.add.key, isDisabled: false },
      { enum: TableActions.edit.key, isDisabled: false },
      { enum: TableActions.delete.key },
    ];
  const focusedRowChanged = (activeRow) => {
    const item = data.result[activeRow];
    if (!item) return;
    setCurrentActions(getUserActions(item.userStatus));
    DriversSliderClicked(item);
  };
 
  const DriversSliderClicked = useCallback((item) => {
    sideMenuComponentUpdate(<UserSlider item={item} />);
    sideMenuIsOpenUpdate(true);
  }, []);

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  return (
    <div className='w-100 px-3'>
      {data && data.result && data && data.result.length !== 0 && (
        <Tables
          headerData={[
            {
              id: 1,
              isSortable: true,
              label: getTranslate()(`${translationPath}Name`),
              input: 'fullName',
              isDate: false,
            },
            {
              id: 2,
              isSortable: true,
              label: getTranslate()(`${translationPath}Username`),
              input: 'userName',
              isDate: false,
            },
            {
              id: 3,
              isSortable: true,
              label: getTranslate()(`${translationPath}Mobile`),
              input: 'phoneNumber',
              isDate: false,
            },
            {
              id: 4,
              isSortable: true,
              label: getTranslate()(`${translationPath}Email`),
              input: 'email',
              isDate: false,
            },
            {
              id: 5,
              isSortable: true,
              label: getTranslate()(`${translationPath}Status`),
              input: 'userStatus',
              isDate: false,
            },
          ]}
          data={(data && data.result) || []}
          activePage={filter.pageIndex}
          totalItems={(data && data.totalCount) || 0}
          activePageChanged={activePageChanged}
          itemsPerPage={filter.pageSize}
          itemsPerPageChanged={itemsPerPageChanged}
          focusedRowChanged={focusedRowChanged}
          actionsOptions={{
            actions: currentActions,
            classes: '',
            isDisabled: false,
            onActionClicked: actionClicked,
          }}
        />
      )}
    </div>
  );
};
export { UserListView };
UserListView.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  actionClicked: PropTypes.func.isRequired,
  activePageChanged: PropTypes.func.isRequired,
  itemsPerPageChanged: PropTypes.func.isRequired,
  translationPath: PropTypes.string,
};
UserListView.defaultProps = {
  translationPath: '',
};
