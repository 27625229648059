import React, { useState, useRef, useEffect, useCallback } from 'react';
import { getTranslate, showSuccess, showError } from '../../../../../../../Helpers';
import { Spinner, AutocompleteComponent, DialogComponent } from '../../../../../../../Componentes';
import { GetAllRoles } from '../../../../../../../Serviecs/Roleservices/roleServices';
import {
  AssignRolesToUser,
  RemoveRolesFromUser,
  GetAllRolesByUserId,
} from '../../../../../../../Serviecs/UsersServices/userServices';
// import './AssignRolesDialog.scss';
import PropTypes from 'prop-types';
const translationPath = 'Administration.Users.AssignRoleDialog.';
const AssignRoleDialog = ({ userId, userFullName, isOpen, isOpenChanged, reloadData }) => {
  const [roles, setRoles] = useState({ result: [], totalCount: 0 });
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [savedRoles, setSavedRoles] = useState({
    result: [],
    totalCount: 0,
  });
  const [optionsData, setOptionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const searchTimer = useRef(null);
  const getRoles = useCallback(async () => {
    setIsLoading(true);
    const response = await GetAllRoles(1, 10, search);
    setRoles({
      result: (response && response.result) || [],
      totalCount: (response && response.totalCount) || 0,
    });
    setIsLoading(false);
  }, [search]);
  const getUserRoles = useCallback(async () => {
    setIsLoading(true);
    const response = await GetAllRolesByUserId(userId, 1, 9999999);
    setSavedRoles({
      result: (response && response.result) || [],
      totalCount: (response && response.totalCount) || 0,
    });
    setSelectedRoles((response && response.result.map((item) => item.roles)) || []);
    setIsLoading(false);
  }, [userId]);
  const saveHandler = async (event) => {
    event.preventDefault();
    const notSavedRoles = selectedRoles.filter(
      (item) => savedRoles.result.findIndex((element) => element.rolesId === item.rolesId) === -1
    );
    if (notSavedRoles.length === 0) {
      if (isOpenChanged) isOpenChanged();
      return;
    }
    setIsLoading(true);
    const assignedRoles = notSavedRoles.map((el) => ({
      usersId: userId,
      rolesId: el.rolesId,
    }));
    const response = await AssignRolesToUser(assignedRoles);
    setIsLoading(false);
    if (response) {
      showSuccess(getTranslate()(`${translationPath}user-roles-saved-successfully`));
      getUserRoles();
      if (reloadData) reloadData();
      if (isOpenChanged) isOpenChanged();
    } else showError(getTranslate()(`${translationPath}user-roles-save-failed`));
  };
  const deleteHandler = (savedDeletedRoles) => {
    setIsLoading(true);
    savedDeletedRoles.map(async (item) => {
      const response = await RemoveRolesFromUser(item.userRolesId);
      if (response) {
        setSelectedRoles((items) => {
          const deletedIndex = items.findIndex((element) => element.rolesId === item.rolesId);
          if (deletedIndex > -1) items.splice(deletedIndex, 1);
          return [...items];
        });
        setSavedRoles((items) => {
          const deletedIndex = items.result.findIndex(
            (element) => element.rolesId === item.rolesId
          );
          if (deletedIndex > -1) {
            items.result.splice(deletedIndex, 1);
            --items.totalCount;
          }
          return { ...items };
        });
        if (reloadData) reloadData();
      }
    });
    setIsLoading(false);
  };
  const searchHandler = (e) => {
    const value = e.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setSearch(value);
    }, 700);
  };
  useEffect(() => {
    getRoles();
  }, [getRoles, search]);
  useEffect(() => {
    setOptionsData(
      selectedRoles.concat(
        roles.result.filter(
          (item) =>
            selectedRoles.findIndex((selectedItem) => selectedItem.rolesId === item.rolesId) === -1
        )
      )
    );
  }, [roles.result, selectedRoles]);
  useEffect(() => {
    if (userId) getUserRoles();
  }, [getUserRoles, userId]);
  useEffect(() => {
    return () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    };
  }, []);
  return (
    <DialogComponent
      titleText={`${getTranslate()(`${translationPath}assign-roles-to`)} ${userFullName}`}
      saveText={getTranslate()(`${translationPath}save-changes`)}
      dialogContent={
        <div className="asign-role-dialog view-wrapper">
          <Spinner isActive={isLoading} />
          <div className="form-item">
            <AutocompleteComponent
              idRef="userRoleRef"
              labelValue="user-roles"
              inputPlaceholder="user-roles"
              translationPath={translationPath}
              value={selectedRoles}
              options={optionsData}
              chipsLabel={(option) => (option.rolesName && option.rolesName) || ''}
              getOptionLabel={(option) => (option.rolesName && option.rolesName) || ''}
              withoutSearchButton
              onKeyUp={searchHandler}
              onChange={(event, newValue) => {
                if (
                  newValue.length > selectedRoles.length ||
                  !savedRoles ||
                  !savedRoles.result ||
                  savedRoles.result.length === 0
                )
                  setSelectedRoles(newValue);
                else {
                  const deletedItems = selectedRoles.filter(
                    (item) =>
                      newValue.findIndex((newItem) => newItem.rolesId === item.rolesId) === -1
                  );
                  const localDeletedItems = deletedItems.filter(
                    (deletedItem) =>
                      savedRoles.result.findIndex(
                        (item) => deletedItem.rolesId === item.rolesId
                      ) === -1
                  );
                  const savedDeletedItems = savedRoles.result.filter(
                    (item) =>
                      deletedItems.findIndex(
                        (deletedItem) => deletedItem.rolesId === item.rolesId
                      ) !== -1
                  );
                  console.log(
                    savedRoles.result,
                    deletedItems,
                    localDeletedItems,
                    savedDeletedItems
                  );
                  if (localDeletedItems.length > 0)
                    setSelectedRoles((items) =>
                      items.filter(
                        (selectedItems) =>
                          localDeletedItems.findIndex(
                            (localDeletedItem) => localDeletedItem.rolesId === selectedItems.rolesId
                          ) === -1
                      )
                    );
                  deleteHandler(savedDeletedItems);
                }
              }}
            />
          </div>
        </div>
      }
      isOpen={isOpen}
      onSubmit={saveHandler}
      onCloseClicked={() => {
        setSelectedRoles((savedRoles && savedRoles.result.map((item) => item.roles)) || []);
        isOpenChanged();
      }}
      onCancelClicked={() => {
        setSelectedRoles((savedRoles && savedRoles.result.map((item) => item.roles)) || []);
        isOpenChanged();
      }}
      // translationPath={translationPath}
    />
  );
};
export { AssignRoleDialog };
AssignRoleDialog.propTypes = {
  userFullName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  reloadData: PropTypes.func,
};
AssignRoleDialog.defaultProps = {
  reloadData: undefined,
};
