import React, { useReducer, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { duplicatePhoneRole, PhoneValidationRole } from '../../../../Rule/PhoneRule';
import { PhonesComponent, Inputs } from '../../../../Componentes';
import { getTranslate } from '../../../../Helpers';

export const RepeatedItemDialog = (props) => {
  const [listItem, setListItem] = useState(
    new Array(
      props.initialState && props.initialState.others ? props.initialState.others.length : 1
    ).fill(0)
  );
  const [helperText, setHelperText] = useState('');
  const reducer = (state, action) => ({ ...state, [action.id]: action.value });
  const [state, setState] = useReducer(reducer, props.initialState);
  const getPhoneItem = (item, index, loopValue) => (
    <>
      <PhonesComponent
        idRef={`phonesRef${index + 1}`}
        isValid={() =>
          !duplicatePhoneRole(props.type, item, props.itemList, props.selectedValues)
          || PhoneValidationRole(
            state && state.others && state.others[index] ? state.others[index] : ''
          )}
        labelValue={`${index + 2}- ${props.label}`}
        isDisabled={item.data.isReadonly}
        // country={country}
        // required={isRequired}
        value={
          state === null || state === undefined || !state.others || !state.others[index]
            ? item.data.defaultCountryCode
            : state.others[index]
        }
        onInputChanged={(value) => {
          if (value.length > 14) return;
          if (state && state.others) {
            state.others[index] = value;
            setState({ id: 'others', value: [...state.others] });
          } else setState({ id: 'others', value: [value] });
        }}
        buttonOptions={
          (!item.data.isReadonly && {
            className: `btns-icon theme-solid ${
              index === listItem.length - 1 ? 'bg-blue-lighter' : 'bg-danger'
            }`,
            iconClasses: index === listItem.length - 1 ? 'mdi mdi-plus' : 'mdi mdi-minus',
            isDisabled: !(
              state
              && state.others
              && state.others[index]
              && state.others[index].length >= 9
            ),
            isRequired: false,
            onActionClicked:
              index === listItem.length - 1
                ? () => {
                    // const itemRegex = new RegExp(props.item.data.regExp);
                    if (
                      state
                      && state.others
                      && state.others[index]
                      && state.others[index].length >= 9
                    )
                      setListItem([...listItem, listItem[listItem.length - 1] + 1]);
                    // if (
                    //   props.type === 'email'
                    //   && state.others
                    //   && state.others[index]
                    //   && itemRegex.test(state.others[index])
                    // )
                    //   setListItem([...listItem, listItem[listItem.length - 1] + 1]);
                  }
                : () => {
                    const listItemIndex = listItem.findIndex((f) => f === loopValue);
                    listItem.splice(listItemIndex, 1);
                    state.others.splice(listItemIndex, 1);
                    setState({ id: 'others', value: [...state.others] });
                    const newlist = [];
                    listItem.map((v, loopIndex) => newlist.push(loopIndex + 2));
                    setListItem([...newlist]);
                  },
          })
          || undefined
        }
      />
    </>
  );

  const getEmailItem = (item, index, loopValue) => (
    <Inputs
      idRef={item.data.id || `emailRef${index + 1}`}
      isDisabled={item.data.isReadonly}
      labelValue={`${index + 2}- ${props.label}`}
      value={state && state.others && state.others[index] ? state.others[index] : ''}
      helperText={helperText}
      error={helperText !== ''}
      onInputChanged={(e) => {
        setHelperText('');
        //  setError(index, '');
        const itemRegex = new RegExp(item.data.regExp);
        if (!itemRegex.test(e.target.value)) setHelperText(item.data.errorMsg);
        //   setError(index, item.data.errorMsg);

        if (state && state.others && state.others[index]) {
          state.others[index] = e.target.value;
          setState({ id: 'others', value: [...state.others] });
        } else if (state && state.others && state.others.length !== 0)
          setState({ id: 'others', value: [...state.others, e.target.value] });
        else setState({ id: 'others', value: [e.target.value] });
      }}
      buttonOptions={
        (!item.data.isReadonly && {
          className: `btns-icon theme-solid ${
            index === listItem.length - 1 ? 'bg-blue-lighter' : 'bg-danger'
          }`,
          iconClasses: index === listItem.length - 1 ? 'mdi mdi-plus' : 'mdi mdi-minus',
          isDisabled: !(
            state
            && state.others
            && state.others[index]
            && state.others[index].length >= 9
          ),
          isRequired: false,
          onActionClicked:
            index === listItem.length - 1
              ? () => {
                  const itemRegex = new RegExp(props.item.data.regExp);
                  if (
                    state
                    && state.others
                    && state.others[index]
                    && itemRegex.test(state.others[index])
                  )
                    setListItem([...listItem, listItem[listItem.length - 1] + 1]);
                }
              : () => {
                  const listItemIndex = listItem.findIndex((f) => f === loopValue);
                  listItem.splice(listItemIndex, 1);
                  state.others.splice(listItemIndex, 1);
                  setState({ id: 'others', value: [...state.others] });
                  const newlist = [];
                  listItem.map((v, loopIndex) => newlist.push(loopIndex + 2));
                  setListItem([...newlist]);
                },
        })
        || undefined
      }
    />
  );
  return (
    <>
      <Dialog className="dialog w-100" open={props.open} maxWidth="sm">
        <form
          noValidate
          onSubmit={(event) => {
            event.preventDefault();
            props.onChange(state);
            props.closeDialog();
          }}
        >
          <DialogTitle>{props.label}</DialogTitle>
          <DialogContent>
            {listItem.map((value, index) => (
              <div className="w-100 mb-2" key={`phones${index + 1}`}>
                {props.type === 'phone' && getPhoneItem(props.item, index, value)}
                {props.type === 'email' && getEmailItem(props.item, index, value)}
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              className="btns theme-solid bg-cancel"
              onClick={() => {
                props.closeDialog();
              }}
            >
              {getTranslate()('shared.cancel')}
            </Button>
            {!props.item.data.isReadonly && (
              <Button className="btns theme-solid" type="submit">
                {getTranslate()('shared.save')}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
