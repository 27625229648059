import config from '../../Assets/Configration/Configration.json';
import { HttpServices } from '../HttpServices/HttpServices';
import { showError } from '../../Helpers';

export const GetAllContactSupport = async (pageIndex, pageSize, searchedItem, body) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/ContactSupport/GetAllContactSupport?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchedItem}`,
      body
    );
    return result;
  } catch (e) {
    showError('Error Retriving Complaints');
  }
};

export const DeleteContactSupport = async (contactSupportId) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/PSTViewing/ContactSupport/${contactSupportId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetContactType = async (lookupTypeId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem/${lookupTypeId}`
    );
    return result;
  } catch (e) {
    return;
  }
};
