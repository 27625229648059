import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './OrderByComponent.scss';
import { getTranslate } from '../../Helpers';
import { ButtonBase } from '@material-ui/core';
import { SelectComponet } from '../Controls';
import { PopoverComponent } from '../PopoverComponent/PopoverComponent';

export const OrderByComponent = ({
  selectedOrder,
  selectedOrderChanged,
  selectedColumnChanged,
  columnsKeyValues,
  orderByKeyValues,
  orderByLabelValue,
  translationPath,
  translationPathShared,
  emptyOrderItem,
  emptyColumnItem,
  popoverIdRef,
  columnNameRef,
  columnLabelValue,
  orderByRef,
  defaultOrder,
  defaultColumn,
}) => {
  const [attachedWith, setAttachedWith] = useState(null);
  const displayRef = useRef(null);
  const handleClose = () => {
    setAttachedWith(null);
  };
  const openHandler = () => {
    setAttachedWith(displayRef.current);
  };
  const onSelectOrderChanged = (event) => {
    const selectedItem = orderByKeyValues.find((item) => item.key === +event.target.value);
    if (selectedOrderChanged) selectedOrderChanged(selectedItem || defaultOrder);
  };
  const onSelectColumnChanged = (event) => {
    const selectedItem = columnsKeyValues.find((item) => item.key === event.target.value);
    if (selectedColumnChanged) selectedColumnChanged(selectedItem || defaultColumn);
  };
  return (
    <div className='order-by-wrapper'>
      <ButtonBase className='display-wrapper' ref={displayRef} onClick={openHandler}>
        <div className='d-inline-flex-center'>
          <span className='mdi mdi-sort px-2' />
          <span>
            {(selectedOrder &&
              selectedOrder.column &&
              selectedOrder.column.key &&
              selectedOrder.column.value &&
              getTranslate()(`${translationPath}${selectedOrder.column.value}`)) ||
              getTranslate()(`${translationPathShared}order-by`)}
            :
          </span>
        </div>
        <span className='c-secondary fw-bold px-2'>
          {(selectedOrder &&
            selectedOrder.orderBy.key === 1 &&
            getTranslate()(`${translationPathShared}a-z`)) ||
            (selectedOrder.orderBy.key === 2 && getTranslate()(`${translationPathShared}z-a`)) ||
            getTranslate()(`${translationPathShared}default`)}
        </span>
        <span className={`c-secondary px-2 mdi mdi-chevron-${(attachedWith && 'up') || 'down'}`} />
      </ButtonBase>
      <PopoverComponent
        idRef={popoverIdRef}
        handleClose={handleClose}
        attachedWith={attachedWith}
        component={
          <div className='w-100 p-2'>
            <div className='d-inline-flex w-50 px-2 mb-2'>
              <SelectComponet
                idRef={columnNameRef}
                data={columnsKeyValues}
                translationPathForData={translationPath}
                translationPath={translationPathShared}
                textInput='value'
                labelValue={columnLabelValue}
                value={selectedOrder.column.key}
                valueInput='key'
                emptyItem={emptyColumnItem}
                onSelectChanged={onSelectColumnChanged}
              />
            </div>
            <div className='d-inline-flex w-50 px-2 mb-2'>
              <SelectComponet
                idRef={orderByRef}
                data={orderByKeyValues}
                translationPathForData={translationPathShared}
                translationPath={translationPathShared}
                isDisabled={!selectedOrder || !selectedOrder.column || !selectedOrder.column.key}
                textInput='value'
                emptyItem={emptyOrderItem}
                labelValue={orderByLabelValue}
                value={selectedOrder.orderBy.key}
                valueInput='key'
                onSelectChanged={onSelectOrderChanged}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

OrderByComponent.propTypes = {
  selectedOrder: PropTypes.shape({
    column: PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    }),
    orderBy: PropTypes.shape({ key: PropTypes.number, value: PropTypes.string }),
  }).isRequired,
  selectedOrderChanged: PropTypes.func.isRequired,
  selectedColumnChanged: PropTypes.func.isRequired,
  columnsKeyValues: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  orderByKeyValues: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      value: PropTypes.string,
    })
  ),
  defaultOrder: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }),
  defaultColumn: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }),
  emptyOrderItem: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    text: PropTypes.string,
  }),
  emptyColumnItem: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    text: PropTypes.string,
  }),
  orderByLabelValue: PropTypes.string,
  translationPath: PropTypes.string,
  translationPathShared: PropTypes.string,
  popoverIdRef: PropTypes.string,
  columnNameRef: PropTypes.string,
  orderByRef: PropTypes.string,
  columnLabelValue: PropTypes.string,
};
OrderByComponent.defaultProps = {
  orderByKeyValues: [
    {
      key: 1,
      value: 'a-z',
    },
    {
      key: 2,
      value: 'z-a',
    },
  ],
  orderByLabelValue: 'order-by',
  translationPath: '',
  translationPathShared: 'home.orderByComponent.',
  popoverIdRef: 'propoverRef',
  columnNameRef: 'columnNameRef',
  orderByRef: 'orderByRef',
  columnLabelValue: 'field-name',
  defaultOrder: {
    key: 0,
    value: 'order-by',
  },
  defaultColumn: {
    key: 0,
    value: 'default',
  },
  emptyOrderItem: {
    value: 0,
    text: 'select-order-by',
  },
  emptyColumnItem: {
    value: 0,
    text: 'select-field-name',
  },
};
