import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getTranslate } from '../../Helpers';
import './DialogComponent.scss';

export const DialogComponent = ({
  isOpen,
  wrapperClasses,
  titleClasses,
  contentClasses,
  footerClasses,
  closeClasses,
  nextPreviousWrapperClasses,
  previousClasses,
  nextClasses,
  cancelWrapperClasses,
  cancelClasses,
  saveWrapperClasses,
  saveClasses,
  titleTextClasses,
  titleText,
  saveText,
  closeIsDisabled,
  previousIsDisabled,
  nextIsDisabled,
  cancelIsDisabled,
  saveIsDisabled,
  dialogTitle,
  dialogContent,
  dialogActions,
  onCloseClicked,
  onNextClicked,
  onPreviousClicked,
  onCancelClicked,
  onSaveClicked,
  onSubmit,
  translationPath,
  translationPathShared,
  maxWidth,
  saveType,
  nextType,
  cancelType,
  previousType,
}) => {
  return (
    <Dialog
      className={wrapperClasses}
      onClose={onCloseClicked || onCancelClicked}
      open={isOpen}
      maxWidth={maxWidth}>
      <form className='w-100' noValidate onSubmit={onSubmit}>
        <DialogTitle className={titleClasses}>
          {(!dialogTitle && (
            <>
              <span className={titleTextClasses}>
                {getTranslate()(`${translationPath}${titleText}`)}
              </span>
              {onCloseClicked && (
                <Button
                  className={closeClasses}
                  onClick={onCloseClicked}
                  disabled={closeIsDisabled}>
                  <span className='mdi mdi-close'></span>
                </Button>
              )}
            </>
          )) ||
            dialogTitle}
        </DialogTitle>
        <DialogContent className={contentClasses}>{dialogContent || undefined}</DialogContent>
        <DialogActions className={footerClasses}>
          {dialogActions ||
            ((onNextClicked || onPreviousClicked) && (
              <div className={nextPreviousWrapperClasses}>
                {(onPreviousClicked || previousType === 'submit') && (
                  <Button
                    className={previousClasses}
                    type={previousType}
                    onClick={onPreviousClicked}
                    disabled={previousIsDisabled}>
                    <span>{getTranslate()(`${translationPathShared}back`)}</span>
                  </Button>
                )}
                {(onNextClicked || nextType === 'submit') && (
                  <Button
                    className={nextClasses}
                    type={nextType}
                    onClick={onNextClicked}
                    disabled={nextIsDisabled}>
                    <span>{getTranslate()(`${translationPathShared}next`)}</span>
                  </Button>
                )}
              </div>
            ))}
          {dialogActions ||
            ((onCancelClicked || cancelType === 'submit') && (
              <div className={cancelWrapperClasses}>
                <Button
                  className={cancelClasses}
                  type={cancelType}
                  onClick={onCancelClicked}
                  disabled={cancelIsDisabled}>
                  <span>{getTranslate()(`${translationPathShared}cancel`)}</span>
                </Button>
              </div>
            ))}
          {dialogActions ||
            ((onSaveClicked || saveType === 'submit') && (
              <div className={saveWrapperClasses}>
                <Button
                  className={saveClasses}
                  type={saveType}
                  onClick={onSaveClicked}
                  disabled={saveIsDisabled}>
                  <span>{getTranslate()(`${translationPath}${saveText}`)}</span>
                </Button>
              </div>
            ))}
        </DialogActions>
      </form>
    </Dialog>
  );
};

DialogComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  wrapperClasses: PropTypes.string,
  titleClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  footerClasses: PropTypes.string,
  closeClasses: PropTypes.string,
  nextPreviousWrapperClasses: PropTypes.string,
  previousClasses: PropTypes.string,
  nextClasses: PropTypes.string,
  cancelWrapperClasses: PropTypes.string,
  cancelClasses: PropTypes.string,
  saveWrapperClasses: PropTypes.string,
  saveClasses: PropTypes.string,
  titleTextClasses: PropTypes.string,
  titleText: PropTypes.string,
  saveText: PropTypes.string,
  closeIsDisabled: PropTypes.bool,
  previousIsDisabled: PropTypes.bool,
  nextIsDisabled: PropTypes.bool,
  cancelIsDisabled: PropTypes.bool,
  saveIsDisabled: PropTypes.bool,
  dialogTitle: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
  dialogContent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
  dialogActions: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
  onCloseClicked: PropTypes.func,
  onNextClicked: PropTypes.func,
  onPreviousClicked: PropTypes.func,
  onCancelClicked: PropTypes.func,
  onSaveClicked: PropTypes.func,
  onSubmit: PropTypes.func,
  translationPath: PropTypes.string,
  translationPathShared: PropTypes.string,
  maxWidth: PropTypes.string,
  saveType: PropTypes.string,
  cancelType: PropTypes.string,
  nextType: PropTypes.string,
  previousType: PropTypes.string,
};
DialogComponent.defaultProps = {
  wrapperClasses: 'dialog-wrapper',
  titleClasses: 'dialog-title-wrapper',
  contentClasses: 'dialog-content-wrapper',
  footerClasses: 'dialog-footer-wrapper',
  closeClasses: 'btns-icon theme-solid bg-danger mx-2 mb-2',
  nextPreviousWrapperClasses: 'd-flex-v-center-h-between flex-wrap px-2 pt-2',
  previousClasses: 'btns theme-outline mx-2 mb-2',
  nextClasses: 'btns theme-solid bg-secondary mx-2 mb-2',
  cancelWrapperClasses: 'd-flex-center px-2 pt-2',
  cancelClasses: 'btns theme-outline mx-2 mb-2',
  saveWrapperClasses: 'd-flex-center w-100 mx-0',
  saveClasses: 'btns theme-solid bg-primary w-100 mb-0 mx-0 br-0',
  titleTextClasses: 'dialog-title-text',
  titleText: undefined,
  saveText: undefined,
  closeIsDisabled: false,
  previousIsDisabled: false,
  nextIsDisabled: false,
  cancelIsDisabled: false,
  saveIsDisabled: false,
  dialogTitle: undefined,
  dialogContent: undefined,
  dialogActions: undefined,
  onCloseClicked: undefined,
  onNextClicked: undefined,
  onPreviousClicked: undefined,
  onCancelClicked: undefined,
  onSaveClicked: undefined,
  onSubmit: undefined,
  translationPath: '',
  translationPathShared: 'home.dialogComponent.',
  maxWidth: 'md',
  saveType: 'submit',
  cancelType: undefined,
  nextType: undefined,
  previousType: undefined,
};
