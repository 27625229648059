import PlatinumImg from '../Assets/Images/user-account-types/curve-platinum.svg';
import SilverImg from '../Assets/Images/user-account-types/curve-silver.svg';
import GoldImg from '../Assets/Images/user-account-types/curve-gold.svg';

export const UserAccountTypeEnum = {
  normal: {
    value: 'normal',
    curvedImg: null,
  },
  platinum: {
    value: 'platinum',
    curvedImg: PlatinumImg,
  },
  silver: {
    value: 'silver',
    curvedImg: SilverImg,
  },
  gold: {
    value: 'gold',
    curvedImg: GoldImg,
  },
};
