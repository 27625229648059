import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Inputs, NoDataFoundComponent, ViewTypes } from '../../../Componentes';
import { getTranslate } from '../../../Helpers';
import { PendingToursListView } from './PendingToursListView/PendingToursListView';
import { ReservationToursListView } from './ReservationToursListView/ReservationToursListView';
import './PendingToursView.scss';
import { ViewTypesEnum } from '../../../Enums';
const translationPath = 'home.tours.pendingToursView.';
export const PendingToursView = () => {
  const [activeActionType, setActiveActionType] = useState(ViewTypesEnum.cards.key);
  //   const [activeItem, setActiveItem] = useState({});
  const [activeView, setActiveView] = useState('Reserved');
  const [pendingTours, setPendingTours] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: 10,
    pageIndex: 0,
    search: '',
  });
  //   const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const searchTimer = useRef(null);

  const searchHandler = (e) => {
    const value = e.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((items) => ({ ...items, pageIndex: 0, search: value }));
    }, 700);
  };
  const onTypeChanged = useCallback((activeType) => {
    setActiveActionType(activeType);
  }, []);

  useEffect(() => {
    return () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    };
  }, []);
  return (
    <div className='pending-tours-view view-wrapper'>
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section px-2'>
            <div className='view-changers-wrapper'>
              <ButtonBase
                className={`btns-view${(activeView === 'Reserved' && ' active-view') || ''}`}
                onClick={() => {
                  setFilter((items) => ({ ...items, pageIndex: 0 }));
                  setActiveView('Reserved');
                }}>
                <span>{getTranslate()(`${translationPath}reserved-tours`)}</span>
              </ButtonBase>
              <ButtonBase
                className={`btns-view${(activeView === 'Pending' && ' active-view') || ''}`}
                onClick={() => {
                  setFilter((items) => ({ ...items, pageIndex: 0 }));
                  setActiveView('Pending');
                }}>
                <span>{getTranslate()(`${translationPath}pending-tours`)}</span>
                {pendingTours.totalCount > 0 && (
                  <span className='px-1 c-red'>{pendingTours.totalCount}</span>
                )}
              </ButtonBase>
            </div>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <Inputs
                idRef='pendingtoursSearchRef'
                variant='outlined'
                fieldClasses='inputs theme-solid'
                translationPath={translationPath}
                label='filter'
                beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                onKeyUp={searchHandler}
                inputPlaceholder={`search-by-${
                  (activeView === 'Pending' && 'pending-tour') || 'tour-reservation'
                }-number`}
              />
            </div>
            {activeView === 'Reserved' && (
              <div>
                <ViewTypes
                  onTypeChanged={onTypeChanged}
                  activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
                  className='mb-3'
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <NoDataFoundComponent />
      <PendingToursListView
        filter={filter}
        activeView={activeView}
        pendingTours={pendingTours}
        pendingToursChanged={(newValue) => setPendingTours(newValue)}
        translationPath={translationPath}
        onFilterChanged={(filterDto) => setFilter(filterDto)}
      />
      {activeView === 'Reserved' && (
        <ReservationToursListView
          filter={filter}
          activeActionType={activeActionType}
          translationPath={translationPath}
          onFilterChanged={(filterDto) => setFilter(filterDto)}
        />
      )}
    </div>
  );
};
