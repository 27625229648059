/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getTranslate, useEventListener, getDownloadableLink } from '../../../../../Helpers';
// import { Button } from '@material-ui/core';
import './DriversCardComponent.scss';
import {
  LoadableImageComponant,
  // CollapseComponent,
  // Spinner,
} from '../../../../../Componentes';
// import { GetAllBookedTourByDriver } from '../../../../../Serviecs';
import { LoadableImageEnum, TourStatusEnum } from '../../../../../Enums';
import moment from 'moment';

export const DriversCardComponent = ({
  data,
  onLoadMore,
  translationPath,
  isLoading,
  // onActionClicked,
  onCardClicked,
  fromDate,
  toDate,
}) => {
  // const [isLoadingTours, setIsLoadingTours] = useState(null);
  // const [tours, setTours] = useState([]);
  // const [openedCard, setOpenedCard] = useState(null);
  const onScrollHandler = useCallback(() => {
    if (
      data &&
      data.result &&
      document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.scrollHeight - 5 &&
      data.result.length < data.totalCount &&
      isLoading !== true
    )
      onLoadMore();
  }, [data, isLoading, onLoadMore]);
  useEventListener('scroll', onScrollHandler);
  useEffect(() => {
    if (
      data &&
      data.result &&
      data.result.length < data.totalCount &&
      document.body.scrollHeight <= document.body.clientHeight &&
      isLoading !== true
    )
      onScrollHandler();
  }, [data, isLoading, onScrollHandler]);
  // const getIsOpenCard = (item) => {
  //   return openedCards.includes(item.id);
  // };
  // const getAgentToursById = useCallback(
  //   async (activeItem) => {
  //     setIsLoadingTours(activeItem.userId);
  //     const response = await GetAllBookedTourByDriver(activeItem.userId, { fromDate, toDate });
  //     setTours(response || []);
  //     setIsLoadingTours(null);
  //   },
  //   [fromDate, toDate]
  // );
  // const openCardHandler = useCallback(
  //   (item) => (event) => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     if (!openedCard || openedCard !== item.userId) {
  //       setOpenedCard(item.userId);
  //       getAgentToursById(item);
  //     } else setOpenedCard(null);
  //   },
  //   [getAgentToursById, openedCard]
  // );
  const onClickHandler = useCallback(
    (item, index) => (event) => {
      event.preventDefault();
      if (onCardClicked) onCardClicked(item, index);
    },
    [onCardClicked]
  );
  return (
    <div className="drivers-card-component-wrapper">
      {/* <MasonryComponent customMonitor={tours} executeDelay={270}> */}
      {data.result.map((item, index) => (
        <a
          className="drivers-card-wrapper"
          onClick={onClickHandler(item, index)}
          key={`driverCardRef${index + 1}`}
        >
          {/* <Spinner
              isActive={(isLoadingTours && item.userId === isLoadingTours) || false}
              isAbsolute
            /> */}
          <div className="cards-wrapper">
            <div className="cards-header">
              <div className="item-wrapper p-relative">
                <LoadableImageComponant
                  type={LoadableImageEnum.div.key}
                  classes="cover-image"
                  alt={getTranslate()(`${translationPath}driver-image`)}
                  src={
                    (item.profileImg && getDownloadableLink(item.profileImg)) || item.defaultImage
                  }
                />
                {(item.isBusy && (
                  <span className={`status-icon ${TourStatusEnum.booked.bgColor}`} />
                )) || <span className={`status-icon ${TourStatusEnum.notBooked.bgColor}`} />}
              </div>
              <div className="d-flex-column">
                <div className="item-wrapper px-2">
                  <span className="item-header">{item.fullName}</span>
                </div>
                <div className="item-wrapper px-2">
                  <span className="item-header c-secondary">({item.username})</span>
                </div>
                <div className="item-wrapper px-2">
                  <span className="item-header">
                    <span>{getTranslate()(`${translationPath}booked-tours`)}:</span>
                    <span>{item.bookedTours || 0}</span>
                  </span>
                </div>
              </div>
              {/* <div className='item-wrapper actions'>
                  <Button
                    className='btns theme-outline'
                    onClick={onActionClicked(ActionsEnum.delete.key, item, index)}>
                    <span className={ActionsEnum.delete.icon} />
                  </Button>
                </div> */}
            </div>
            {/* <CollapseComponent
                isOpen={
                  (openedCard &&
                    openedCard === item.userId &&
                    tours.length > 0 &&
                    !isLoadingTours) ||
                  false
                }
                component={
                  <div className="cards-body px-3">
                    {tours.map((tour, index) => (
                      <div
                        key={`toursRef${index + 1}`}
                        className="item-wrapper bg-blue-lighter c-primary mb-3"
                      >
                        <div className="w-100 d-flex-center">
                          <span className="item-header">{tour.tourName}</span>
                        </div>
                        <div className="w-100 d-flex-v-center">
                          <span className="item-header">
                            <span className="mdi mdi-map-marker px-2" />
                          </span>
                          <span className="item-body">{tour.pickupLocationAddress || 'N/A'}</span>
                        </div>
                        <div className="w-100 d-flex-v-center-h-between">
                          <div className="d-inline-flex-center">
                            <span className="mdi mdi-calendar px-2" />
                            {(moment(tour.startTourDateTime).isSame(
                              tour.endTourDateTime,
                              'day'
                            ) && (
                              <span>{moment(tour.startTourDateTime).format('DD/MM/YYYY')}</span>
                            )) || (
                              <span>
                                <span>
                                  {moment(tour.startTourDateTime).format('DD/MM/YYYY')} -{' '}
                                </span>
                                <span>{moment(tour.endTourDateTime).format('DD/MM/YYYY')}</span>
                              </span>
                            )}
                          </div>
                          <span className="d-inline-flex-center">
                            <span className="mdi mdi-clock-time-five px-2" />
                            <span>{moment(tour.startTourDateTime).format('hh:mm a')} - </span>
                            <span>{moment(tour.endTourDateTime).format('hh:mm a')}</span>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                }
                classes=""
              /> */}
            {/* <div className="cards-footer">
                <Button
                  className="btns-icon theme-solid bg-secondary"
                  disabled={item.bookedTours === 0}
                  onClick={openCardHandler(item)}
                >
                  <span
                    className={`mdi mdi-chevron-${
                      (openedCard && openedCard === item.userId && 'up') || 'down'
                    }`}
                  />
                </Button>
              </div> */}
          </div>
        </a>
      ))}
      {/* </MasonryComponent> */}
    </div>
  );
};
DriversCardComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  // onActionClicked: PropTypes.func.isRequired,
  fromDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]).isRequired,
  toDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]).isRequired,
  isLoading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  translationPath: PropTypes.string,
};
DriversCardComponent.defaultProps = {
  data: { result: [], totalCount: 0 },
  isLoading: false,
  onPageChanged: undefined,
  translationPath: '',
};
