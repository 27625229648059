import React, { useState } from 'react';
import { DeletePromoCode } from '../../../../Serviecs';
import { getTranslate, showSuccess, showError } from '../../../../Helpers';
import { DialogComponent, Spinner } from '../../../../Componentes';
const translationPath = 'PromoCode.';

const PromoCodeDeleteDialog = (props) => {
  const [loading, setLoading] = useState(false);

  const deleteHandler = async () => {
    setLoading(true);
    const res = await DeletePromoCode(props.activeUserItem.promoCodeId);
    if (res) {
      props.reloadData();
      showSuccess(getTranslate()(`${translationPath}promo-delete-successfully`));
    } else showError(getTranslate()(`${translationPath}promo-delete-failed`));
    setLoading(false);
    props.isOpenChanged();
  };
  return (
    <DialogComponent
      titleText='confirm-message'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <Spinner isActive={loading} />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>{`${getTranslate()(`${translationPath}DeleteText`)} ${
            props.activeUserItem && props.activeUserItem.promoCodeName
          }`}</span>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mb-0 mx-0 br-0'
      isOpen={props.isOpen}
      onSaveClicked={deleteHandler}
      onCloseClicked={props.isOpenChanged}
      onCancelClicked={props.isOpenChanged}
      translationPath={translationPath}
    />
  );
};
export { PromoCodeDeleteDialog };
