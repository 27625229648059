import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { getDownloadableLink, getTranslate } from '../../../../../../../Helpers';
import './ShiftedCardsView.scss';
import { LoadableImageComponant } from '../../../../../../../Componentes';

export const ShiftedCardsView = ({ translationPath, tours }) => {
  const [rating, setRating] = useState(2);

  return (
    <div className='shifted-cards-wrapper'>
      <div className='shifted-cards-title px-2'>
        {getTranslate()(`${translationPath}recommended-tours`)}
      </div>
      <div className='shifted-cards-content px-2'>
        {tours &&
          tours.result.map((item, i) => (
            <div className='shifted-card' key={i}>
              <div className='top-card'>
                <div className='top-card-title'>{item.tourName}</div>
                <div className='favourite-heart'>
                  <IconButton>
                    <span className='mdi mdi-heart-outline' />
                  </IconButton>
                </div>
              </div>
              <div className='bottom-card'>
                <div className='bottom-image'>
                  <LoadableImageComponant
                    src={getDownloadableLink(item.galleryList[0])}
                    alt={item.tourName}
                    classes='img-center'
                  />
                </div>
                <div className='bottom-footer'>
                  <div className='points-card-description'>
                    {getTranslate()(`${translationPath}price`)}:
                    <div className='tour-price'>
                      {item.totalFromPriceAfterDiscount}
                      {'  AED'}
                    </div>
                  </div>
                  <div className='card-cart-button'>
                    <IconButton>
                      <span className='mdi mdi-cart-outline' />
                    </IconButton>
                  </div>
                  <div className='rating-wrapper'>
                    <div className='rating-amount'>
                      16 {getTranslate()(`${translationPath}reviews`)}
                    </div>
                    <Rating
                      value={rating}
                      onChange={(event, newValue) => {
                        setRating(newValue);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
