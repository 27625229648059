import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ContactTypeEnum, ActionsEnum, LoadableImageEnum } from '../../../../../../Enums';
import { LoadableImageComponant } from '../../../../../../Componentes';
import { getTranslate, useEventListener, getDownloadableLink ,sideMenuIsOpenUpdate,sideMenuComponentUpdate,} from '../../../../../../Helpers';
import UserSlider from '../../UserUtilties/UserSlider/UserSlider';
import { Button } from '@material-ui/core';
import { getLanguage } from 'react-switch-lang';
import moment from 'moment';
import './UserCardsComponent.scss';

export function UserCardsComponent({
  data,
  // page,
  // rowsPerPage,
  onLoadMore,
  translationPath,
  isLoading,
  actionClicked,
}) {
  const getUserActions = (userStatus) =>
    (userStatus === 'Pending' && [ActionsEnum.check, ActionsEnum.close, ActionsEnum.delete]) ||
    (userStatus === 'Canceled' && [ActionsEnum.check, ActionsEnum.delete]) || [
      // ActionsEnum.add,
      ActionsEnum.edit,
      ActionsEnum.delete,
    ];
  const onScrollHandler = useCallback(() => {
    if (
      data &&
      data.result &&
      document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.scrollHeight - 5 &&
      data.result.length < data.totalCount &&
      isLoading !== true
    )
      onLoadMore();
  }, [data, isLoading, onLoadMore]);
  useEventListener('scroll', onScrollHandler);
  useEffect(() => {
    if (
      data &&
      data.result &&
      data.result.length < data.totalCount &&
      document.body.scrollHeight <= document.body.clientHeight &&
      isLoading !== true
    )
      onScrollHandler();
  }, [data, isLoading, onScrollHandler]);

 
  const DriversSliderClicked = useCallback((item) => {
    sideMenuComponentUpdate(<UserSlider item={item} />);
    sideMenuIsOpenUpdate(true);
  }, []);

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
 
 
  return (
    <div className="users-card-component-wrapper">
      {data.result.map((item, index) => (
        <div className="users-card-wrapper" key={`userCardRef${index + 1}`}>
          <div className="cards-wrapper" onClick={()=>DriversSliderClicked(item)}>
            <div className="cards-header">
              <div className="item-wrapper">
                <LoadableImageComponant
                  type={LoadableImageEnum.div.key}
                  classes="user-cover-image"
                  alt={getTranslate()(`${translationPath}user-image`)}
                  src={
                    (item.profileImg && getDownloadableLink(item.profileImg)) ||
                    ContactTypeEnum.man.defaultImg
                  }
                />
              </div>
              <div className="d-flex-column">
                <div className="item-wrapper px-2">
                  <span className="item-header">{item.fullName}</span>
                </div>
                <div className="item-wrapper">
                  <span className="item-header px-2">
                    {getTranslate()(`${translationPath}Username`)}:
                  </span>
                  <span className="item-body">{item.userName}</span>
                </div>
                {item.userTypes && item.userTypes.length > 0 && (
                  <div className="item-wrapper">
                    <span className="item-body c-secondary">
                      {item.userTypes &&
                        item.userTypes.map((subItem, subIndex) => (
                          <span key={`userRolesRef${(subIndex + 1) * (index + 1)}`}>
                            {subItem.rolesName}
                            {subIndex < item.userTypes.length - 1 && (
                              <span className="pr-1">,</span>
                            )}
                          </span>
                        ))}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="cards-body">
              <div className="item-wrapper">
                <span className="item-header">
                  <span className="mdi mdi-phone px-2" />
                  <span>{getTranslate()(`${translationPath}Mobile`)}:</span>
                </span>
                <span className="item-body">{item.phoneNumber}</span>
              </div>
              <div className="item-wrapper flex-nowrap">
                <div className="texts-truncate d-flex">
                  <span className="item-header">
                    <span className="mdi mdi-email-outline px-2" />
                    <span>{getTranslate()(`${translationPath}Email`)}:</span>
                  </span>
                  <span
                    className="item-body texts-truncate d-inline-block"
                    title={item.email || 'N/A'}
                  >
                    {item.email || 'N/A'}
                  </span>
                </div>
              </div>
              <div className="item-wrapper">
                <span className="item-header">
                  <span className="mdi mdi-whatsapp px-2" />
                  <span>{getTranslate()(`${translationPath}whatsapp`)}:</span>
                </span>
                <span className="item-body">{item.whatsAppNumber || 'N/A'}</span>
              </div>
              <div className="item-wrapper">
                <span className="item-header">
                  <span className="mdi mdi-map-marker px-2" />
                  <span>{getTranslate()(`${translationPath}nationality`)}:</span>
                </span>
                <span className="item-body">{item.nationalityName || 'N/A'}</span>
              </div>
              <div className="item-wrapper">
                <span className="item-header">
                  <span className="mdi mdi-calendar-blank px-2" />
                  <span>{getTranslate()(`${translationPath}register`)}:</span>
                </span>
                <span className="item-body">
                  {(item.createdOn &&
                    moment(item.createdOn).locale(getLanguage()).format('DD/MM/YYYY')) ||
                    'N/A'}
                </span>
              </div>
              <div className="item-wrapper">
                <span className="item-header">
                  <span className="mdi mdi-account-check px-2" />
                  <span>{getTranslate()(`${translationPath}Status`)}:</span>
                </span>
                <span className="item-body">{item.userStatus || 'N/A'}</span>
              </div>
              <div className="item-wrapper mb-3">
                <span className="item-header">
                  <span className="mdi mdi-handshake-outline px-2" />
                  <span>{getTranslate()(`${translationPath}data-source`)}:</span>
                </span>
                <span className="item-body">{item.dataSource || 'N/A'}</span>
              </div>
            </div>
            <div className="item-wrapper actions">
              {getUserActions(item.userStatus).map((action, actionIndex) => (
                <Button
                  className="btns theme-outline"
                  key={`userCardActionsRef${(index + 1) * (actionIndex + 1)}`}
                  onClick={actionClicked(action.key, item, index)}
                >
                  <span className={action.icon} />
                </Button>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

UserCardsComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  actionClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  translationPath: PropTypes.string,
};
UserCardsComponent.defaultProps = {
  data: { result: [], totalCount: 0 },
  isLoading: false,
  onPageChanged: undefined,
  translationPath: '',
};
