import React, { useReducer, useEffect } from 'react';
import { AddParking } from '../../../../Serviecs/Parking/ParkingService';
import { countryservis } from '../../../../Serviecs/Parking/ParkingService';
import {
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  Grid,
  FormControl,
  Typography,
  Divider,
  Popover,
} from '@material-ui/core';
import './Dialog.scss';
import { GoogleMapsComponent } from '../../../../Componentes';
import { getTranslate, showSuccess } from '../../../../Helpers';
import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone';
import { Spinner, Inputs, SelectComponet } from '../../../../Componentes';
import Joi from 'joi';
import { getErrorByName } from '../../../../Helpers';

const reducer = (state, action) => {
  switch (action.type) {
    case 'parkingNumber':
      return {
        ...state,
        parkingNumber: +action.value,
      };
    case 'parkingName':
      return {
        ...state,
        parkingName: action.value,
      };
    case 'cityId':
      return {
        ...state,
        cityId: +action.value,
      };
    case 'address':
      return {
        ...state,
        address: action.value,
      };
    case 'latitude':
      return {
        ...state,
        latitude: +action.value,
      };
    case 'longitude':
      return {
        ...state,
        longitude: +action.value,
      };
    case 'reset':
      return {
        parkingNumber: '',
        parkingName: '',
        cityId: '',
        address: '',
        latitude: 24.466667,
        longitude: 54.366669,
      };
    default:
      return state;
  }
};

const DialogAddParking = (props) => {
  const [ResponseCountry, setResponseCountry] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const translationPath = 'Parking.AddParkingDialog.';
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [state, dispatch] = useReducer(reducer, {
    parkingNumber: '',
    parkingName: '',
    cityId: '',
    address: '',
    latitude: 24.466667,
    longitude: 54.366669,
  });

  const Getcountry = async () => {
    const res = await countryservis();
    setResponseCountry(res && res.result ? res.result : []);
  };

  useEffect(() => {
    Getcountry();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const schema = Joi.object({
    parkingNumber: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrorparkingnumber`),
      }),
    city: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}ValidationErrorparkingcity`),
      }),
    parkingName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}ValidationErrorparkingname`),
      }),
    address: Joi.string()

      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}ValidationErrorparkingaddres`),
      }),
    longitude: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrornumber`),
      }),
    latitude: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}ValidationErrornumber`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate(state);
  return (
    <Dialog open={props.open} onClose={props.close} className="sliderDialogs">
      <Spinner isActive={loading} />
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          props.close();
          setIsSubmitted(true);
        }}
      >
        {' '}
        <div className="colse-button">
          <Button
            className="MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid bg-danger mx-2 mb-2"
            tabindex="0"
            title={getTranslate()(`${translationPath}Cancel`)}
            type="button"
            onClick={() => {
              setIsSubmitted(true);
              props.close();
              setIsSubmitted(true);
              dispatch({ type: 'reset' });
            }}
          >
            <span className="MuiButton-label">
              <span className="mdi mdi-close"></span>
            </span>
            <span class="MuiTouchRipple-root"></span>
          </Button>
        </div>
        <DialogTitle className="dialogTitle">
          <Typography> {getTranslate()(`${translationPath}Addparking`)}</Typography>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={8}>
              <Grid container justify="center">
                <FormControl className="input-wrapper flex-wrap textField">
                  <Typography> {getTranslate()(`${translationPath}parkingNumber`)}</Typography>

                  <Inputs
                    idRef="parkingNumber"
                    translationPath={translationPath}
                    isWithError
                    isSubmitted={isSubmitted}
                    type="number"
                    helperText={getErrorByName(schema, 'parkingNumber').message}
                    inputPlaceholder="parkingNumber"
                    error={getErrorByName(schema, 'parkingNumber').error}
                    value={state.parkingNumber}
                    onInputChanged={(e) =>
                      dispatch({ type: 'parkingNumber', value: e.target.value })
                    }
                  />
                </FormControl>
                <FormControl className="input-wrapper flex-wrap textField">
                  <Typography> {getTranslate()(`${translationPath}parkingName`)}</Typography>

                  <Inputs
                    idRef="parkingName"
                    translationPath={translationPath}
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, 'parkingName').message}
                    inputPlaceholder="parkingName"
                    error={getErrorByName(schema, 'parkingName').error}
                    value={state.parkingName}
                    onInputChanged={(e) => dispatch({ type: 'parkingName', value: e.target.value })}
                  />
                </FormControl>
                <FormControl className="input-wrapper flex-wrap textField" size="small">
                  <Typography> {getTranslate()(`${translationPath}city`)}</Typography>

                  <SelectComponet
                    idRef="lookupItemName"
                    data={ResponseCountry}
                    valueInput="lookupItemId"
                    textInput="lookupItemName"
                    isWithError
                    // helperText={getErrorByName(schema, 'color').message}
                    emptyItem={{
                      value: '0',
                      text: getTranslate()(`${translationPath}Selectcity`),
                    }}
                    //error={getErrorByName(schema, 'color').error}
                    value={state.cityId}
                    onSelectChanged={(e) => dispatch({ type: 'cityId', value: e.target.value })}
                  />
                </FormControl>
                <FormControl className="input-wrapper flex-wrap textField">
                  <Typography>{getTranslate()(`${translationPath}address`)}</Typography>
                  <Inputs
                    idRef="address"
                    translationPath={translationPath}
                    isWithError
                    helperText={getErrorByName(schema, 'address').message}
                    inputPlaceholder="address"
                    error={getErrorByName(schema, 'address').error}
                    value={state.address}
                    onInputChanged={(e) => dispatch({ type: 'address', value: e.target.value })}
                  />
                </FormControl>
                <Grid container justify="center" spacing={1} className="inputmap">
                  <Grid item xs={6}>
                    <Grid item xs={12} className="textField  ">
                      <Typography> {getTranslate()(`${translationPath}latitude`)}</Typography>
                    </Grid>
                    <FormControl className="input-wrapper">
                      <Inputs
                        idRef="latitude"
                        translationPath={translationPath}
                        isWithError
                        type="number"
                        helperText={getErrorByName(schema, 'latitude').message}
                        inputPlaceholder="latitude"
                        error={getErrorByName(schema, 'latitude').error}
                        value={state.latitude}
                        onInputChanged={(e) =>
                          dispatch({ type: 'latitude', value: e.target.value })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} className="textField">
                      <Typography> {getTranslate()(`${translationPath}longitude`)}</Typography>
                    </Grid>
                    <FormControl className="input-wrapper">
                      <Inputs
                        idRef="longitude"
                        translationPath={translationPath}
                        type="number"
                        isWithError
                        style={{
                          textAlign: 'center',
                        }}
                        helperText={getErrorByName(schema, 'longitude').message}
                        inputPlaceholder="longitude"
                        error={getErrorByName(schema, 'longitude').error}
                        value={state.longitude}
                        onInputChanged={(e) =>
                          dispatch({ type: 'longitude', value: e.target.value })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <div
                    className=" mapButton-in-Parking "
                    title={getTranslate()(`${translationPath}title-map`)}
                  >
                    <Button onClick={handleClick}>
                      <div type="submit" className="btns theme-solid  mx-2 ">
                        <p className="Titlemap mdi mdi-Description">
                          <RoomTwoToneIcon style={{ marginBottom: -7 }} />{' '}
                          {getTranslate()(`${translationPath}OpenMaps`)}{' '}
                        </p>
                      </div>
                    </Button>
                  </div>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                      Width: 600,
                    }}
                  >
                    <Typography>
                      <Grid item xs={12}>
                        <GoogleMapsComponent
                          locations={
                            (state.latitude && [
                              {
                                latitude: state.latitude,
                                longitude: state.longitude,
                              },
                            ]) ||
                            []
                          }
                          center={
                            (state.latitude && { lat: state.latitude, lng: state.longitude }) ||
                            undefined
                          }
                          onSearchLocationChanged={(searchValue) => {
                            dispatch({ type: 'latitude', value: searchValue.lat });
                            dispatch({ type: 'longitude', value: searchValue.lng });
                          }}
                          onClick={(location) => {
                            dispatch({ type: 'latitude', value: location.lat });
                            dispatch({ type: 'longitude', value: location.lng });
                          }}
                        />
                      </Grid>
                    </Typography>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Grid container justify="center">
          {' '}
          <Button
            onClick={() => {
              props.close();
            }}
            color="primary"
            className="btns bg-cancel"
          >
            {getTranslate()(`${translationPath}Cancel`)}
          </Button>
        </Grid>
        <div
          className={
            state.longitude === '' ||
            state.longitude === '' ||
            state.address === '' ||
            state.cityId === '' ||
            state.cityId === 0 ||
            state.parkingName === '' ||
            state.parkingNumber === ''
              ? 'DisableddoneBtn'
              : 'doneBtnContainer'
          }
        >
          <Grid container justify="center">
            <Grid item xs={12} className="doneBtn">
              <Button
                disabled={
                  state.longitude === '' ||
                  state.longitude === '' ||
                  state.address === '' ||
                  state.cityId === '' ||
                  state.cityId === 0 ||
                  state.parkingName === '' ||
                  state.parkingNumber === ''
                    ? true
                    : false
                }
                onClick={async () => {
                  setLoading(true);
                  await AddParking(state);
                  props.reloadData();
                  setIsSubmitted(true);
                  props.close();
                  dispatch({ type: 'reset' });
                  setLoading(false);
                  showSuccess(getTranslate()(`${translationPath}NotificationAddsave`));
                }}
                variant="contained"
                className="btns theme-solid"
              >
                {getTranslate()(`${translationPath}Done`)}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </Dialog>
  );
};
export default DialogAddParking;
