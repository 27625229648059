import { AccountLayout } from '../../Layouts/Account/AccountLayout';
import { HomeLayout } from '../../Layouts/Home/HomeLayout';
import { NotFoundLayout } from '../../Layouts/NotFoundLayout/NotFoundLayout';

export const AppRoutes = [
  {
    path: '/account',
    name: 'Account',
    // eslint-disable-next-line no-undef
    component: AccountLayout,
    layout: '',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeLayout,
    layout: '',
    default: false,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/error',
    name: 'Error',
    component: NotFoundLayout,
    layout: '',
    default: false,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
];
