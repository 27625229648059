import config from '../../Assets/Configration/Configration';
import { HttpServices } from '../HttpServices/HttpServices';
import { getTranslate, showError } from '../../Helpers';

export const GetAllCarTypes = async (pageIndex, pageSize, search) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/CarTypes/GetAllCarTypes/?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
    );
    return result;
  } catch (e) {
    return;
  }
};
export const CarTypes = async (payload) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/PSTViewing/CarTypes`, payload);
    return result;
  } catch (e) {
    return;
  }
};
export const DelteCarTypes = async (payload) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/PSTViewing/CarTypes/${payload}`,
      payload
    );
    return result;
  } catch (e) {
    return;
  }
};

export const CreateCarsDto = async (payload) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/PSTViewing/Cars`, payload);
    return result;
  } catch (e) {
    return false;
  }
};
export const SearchCars = async (pageIndex = 1, pageSize = 100, payload) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/Cars/SearchCars?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      payload
    );
    return result;
  } catch (e) {
    return;
  }
};

export const EditCarTypes = async (payload, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/CarTypes/${payload}`,
      body,
      payload
    );
    return result;
  } catch (e) {
    return;
  }
};

export const GetCarById = async (carId) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Cars/GetCarsByCarTypeId/${carId}`,
      carId
    );
    return result;
  } catch (e) {
    return;
  }
};

export const UploadPicture = async (body) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/FileManager/File/Upload`, body);
    return result;
  } catch (e) {
    return;
  }
};

export const DelteCar = async (payload) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/PSTViewing/Cars/${payload}`,
      payload
    );
    return result;
  } catch (e) {
    return;
  }
};

export const EditCar = async (payload, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Cars/${payload}`,
      body,
      payload
    );
    return result;
  } catch (e) {
    showError(getTranslate()(`Cars.DialogAddEditCar.NotificationerorrEdit`));
  }
};

export const ActivateCar = async (payload, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/Cars/ActivateCar/${payload}?isActive=${body}`,
      payload
    );
    return result;
  } catch (e) {
    return false;
  }
};

export const ActivateTypeCar = async (payload, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/CarTypes/ActivateCarType/${payload}?isActive=${body}`,
      payload
    );
    return result;
  } catch (e) {
    return;
  }
};

export const PassengerType = async (body) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem?lookupTypeName=PassengerType`,

      body
    );
    return result;
  } catch (e) {
    return;
  }
};

export const ApplicationUserSearch = async (payload) => {
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/Identity/Account/ApplicationUserSearch`,
      payload
    );
    return result;
  } catch (e) {
    return;
  }
};
