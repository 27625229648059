import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ConvertJson from '../../../../FormBuilder/Utilities/FormRender/ConvertJson';
import { ConvertJsonToForm, getTranslate } from '../../../../../../Helpers';
import { formByIdGet } from '../../../../../../Serviecs';
import { Spinner } from '../../../../../../Componentes';
import './ItemsComponent.scss';
import { OnchangeDistrictRule } from '../../../../../../Rule';

export const ItemsComponent = ({
  cityData,
  city,
  itemJson,
  tripType,
  onItemDateChanged,
  translationPath,
}) => {
  const [itemData, setItemData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsError, setItemsError] = useState({});
  const getFormData = useCallback(async () => {
    setIsLoading(true);
    const res = await formByIdGet({
      formname: tripType && tripType.formsName,
    });
    const converted =
      (res && res.formsContent && ConvertJsonToForm(JSON.parse(res.formsContent))) || [];
    if (cityData && city) {
      const cityIndex = converted.findIndex((item) => item.field.id === 'city');
      if (cityIndex !== -1) {
        converted[cityIndex].data.enum = cityData;
        converted[cityIndex].data.valueToEdit = city;
      }
      setItemData((items) => {
        items[cityIndex] = city;
        return items;
      });
    }
    // console.log((res && res.formsContent && ConvertJsonToForm(JSON.parse(res.formsContent))) || []);
    setFormData(converted);
    setIsLoading(false);
  }, [city, cityData, tripType]);
  const setError = (i, newError) => {
    itemsError[`${i}`] = newError;
    setItemsError({ ...itemsError });
  };
  // useEffect(() => {
  //   if (onItemDateChanged) onItemDateChanged(itemData);
  // }, [itemData, onItemDateChanged]);
  useEffect(() => {
    if (tripType && tripType.formsName) getFormData();
  }, [getFormData, tripType, city]);
  const updateData = useCallback(
    (itemJsonLocal) => {
      Object.entries(itemJsonLocal).forEach((items) => {
        const itemIndex = formData.findIndex((item) => item.field.id === items[0]);
        if (itemIndex !== -1)
          setItemData((item) => {
            item[itemIndex] = items[1];
            return item;
          });
      });
    },
    [formData]
  );
  const updateCityHandler = useCallback(() => {
    let cityIndex = -1;
    setFormData((items) => {
      cityIndex = items.findIndex((item) => item.field.id === 'city');
      if (cityIndex !== -1) {
        items[cityIndex].data.enum = cityData;
      }
      return [...items];
    });
    // setTimeout(() => {
    //   if (cityIndex !== -1)
    //     setItemData((items) => {
    //       items[cityIndex] = city;
    //       console.log('setItemData', city);
    //       return [...items];
    //     });
    //     // if (onItemDateChanged) onItemDateChanged(city, formData[cityIndex]);
    // });
    // setItemData(itemData);
  }, [cityData]);
  useEffect(() => {
    if (itemJson) updateData(itemJson);
  }, [itemJson, updateData]);
  useEffect(() => {
    // console.log(city, cityData);
    updateCityHandler();
  }, [city, cityData, updateCityHandler]);
  return (
    <div className='json-form-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      {/* {console.log('from item data', formData)} */}
      {(tripType &&
        tripType.formsName &&
        formData.map((item, index) => (
          <div className='form-item' key={'form' + index}>
            {item && (
              <ConvertJson
                item={item}
                setData={(indexlocal, value) => {
                  if (isNaN(indexlocal) || indexlocal < 0) return;
                  // console.log('District',value);
                  setItemData((items) => {
                    items[indexlocal] = value;
                    return items;
                  });
                  if (item.field.id === 'city' && value);
                  OnchangeDistrictRule(
                    item.field.id,
                    (districtIndex, newValue) => {
                      if (isNaN(districtIndex) || districtIndex < 0) return;
                      setItemData((items) => {
                        items[districtIndex] = newValue;
                        return [...items];
                      });
                      if (onItemDateChanged) onItemDateChanged(newValue, item);
                    },
                    item,
                    formData,
                    value
                  );
                  if (onItemDateChanged) onItemDateChanged(value, item);
                }}
                itemValue={itemData[index]}
                index={index}
                setError={setError}
                itemList={formData}
                selectedValues={itemData}
              />
            )}
          </div>
        ))) ||
        ((!tripType || !tripType.formsName) && (
          <div className='notification-form-wrapper'>
            {getTranslate()(`${translationPath}notification-form-description`)}
          </div>
        ))}
    </div>
  );
};

ItemsComponent.propTypes = {
  tripType: PropTypes.instanceOf(Object),
  itemJson: PropTypes.instanceOf(Object),
  onItemDateChanged: PropTypes.func.isRequired,
  translationPath: PropTypes.string,
};
ItemsComponent.defaultProps = {
  tripType: undefined,
  itemJson: undefined,
  translationPath: '',
};
