import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getErrorByName, getTranslate } from '../../../../../../Helpers';
import { AutocompleteComponent, Spinner } from '../../../../../../Componentes';
import Lookups from '../../../../../../Assets/JSON/StaticLookupsIds.json';
import { lookupItemsGetId } from '../../../../../../Serviecs';

export const TourPoliciesComponent = ({
  state,
  onPoliciesChange,
  translationPath,
  isSubmitted,
  schema,
}) => {
  //   const searchTimer = useRef(null);
  //   const [tourPoliciesSearch, setTourPoliciesSearch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingPolicies, setBookingPolicies] = useState([]);
  const [bookingPolicyIds, setBookingPolicyIds] = useState([]);

  //   const searchHandler = (event) => {
  //     const value = event.target.value;
  //     if (searchTimer.current) clearTimeout(searchTimer.current);
  //     searchTimer.current = setTimeout(() => {
  //       setTourPoliciesSearch(value);
  //     }, 700);
  //   };
  const getTourPolicies = useCallback(async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: Lookups.TourPolicies,
    });
    setBookingPolicies(res || []);
    setIsLoading(false);
  }, []);
  const updateSelectedPolicies = useCallback(() => {
    setBookingPolicyIds(
      bookingPolicies.filter((item) => state.bookingPolicyIds.includes(item.lookupItemId))
    );
  }, [bookingPolicies, state.bookingPolicyIds]);
  useEffect(() => {
    getTourPolicies();
  }, [getTourPolicies]);
  useEffect(() => {
    updateSelectedPolicies();
  }, [updateSelectedPolicies, state.bookingPolicyIds]);
  //   useEffect(() => {
  //     return () => {
  //       if (searchTimer.current) clearTimeout(searchTimer.current);
  //     };
  //   }, []);

  return (
    <div className="tour-policies-wrapper p-relative">
      <Spinner isActive={isLoading} isAbsolute />
      <span className="d-flex mb-2 fw-bold c-black-light">
        {getTranslate()(`${translationPath}tour-policies`)}
      </span>
      {/* <span className="d-flex mb-3">
        {getTranslate()(`${translationPath}tour-policies-description`)}
      </span> */}
      <div className="d-flex mb-3 px-5">
        <AutocompleteComponent
          idRef="bookingPolicyIdsRef"
          labelValue="booking-policies"
          inputPlaceholder="booking-policies"
          translationPath={translationPath}
          value={bookingPolicyIds}
          options={bookingPolicies}
          getOptionSelected={(option) =>
            state.bookingPolicyIds &&
            state.bookingPolicyIds.findIndex((item) => item === option.lookupItemId) !== -1
          }
          chipsLabel={(option) => option.lookupItemName || ''}
          getOptionLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          helperText={getErrorByName(schema, 'bookingPolicyIds').message}
          error={getErrorByName(schema, 'bookingPolicyIds').error}
          isWithError
          isSubmitted={isSubmitted}
          //   onKeyUp={searchHandler}
          onChange={(event, newValue) => {
            onPoliciesChange(
              'bookingPolicyIds',
              newValue.map((item) => item.lookupItemId)
            );
          }}
        />
      </div>
    </div>
  );
};

TourPoliciesComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  onPoliciesChange: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
};
