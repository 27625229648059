/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback } from 'react';
import { getTranslate, useEventListener } from '../../../../Helpers';
import { Button, Divider } from '@material-ui/core';
import moment from 'moment';
import './InvoiceCard.scss';
import {DownloadInvoicesByPaymentOrder}from "../../../../Serviecs"

export const InvoiceCard = ({
  data,
  page,
  rowsPerPage,
  setPage,
  translationPath,
  onCardClicked,
  isLoading,
  setIsLoading
}) => {
  const loadMoreData = useCallback(() => {
    if (
      data &&
      data.result &&
      data.totalCount > data.result.length &&
      (page + 1) * rowsPerPage <= data.result.length
    )
      setPage(page + 1);
  }, [data, page, rowsPerPage, setPage]);

  const scrollHandler = () => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - 5
    )
      loadMoreData();
  };

  useEventListener('scroll', scrollHandler);

  useEffect(() => {
    if (document.body.scrollHeight < document.body.clientHeight && !isLoading) loadMoreData();
    return () => {};
  }, [loadMoreData, isLoading]);

  const onClickHandler = useCallback(
    (item, index) => (event) => {
      event.preventDefault();
      if (onCardClicked) onCardClicked(item, index);
    },
    [onCardClicked]
  );

  return (
    <div className='invoice-card-component-wrapper'>
      {data &&
        data.result.map((item, index) => (
          <a
            className='invoice-card-wrapper'
            onClick={onClickHandler(item, index)}
            key={`agentCardRef${index + 1}`}>
            <div className='cards-wrapper'>
              <div className='cards-header'>
                <div className='item-wrapper p-relative invoice-header-wrapper'>
                  <span className='item-header'>{item.invoiceNumber || 'N/A'}</span>
                  <span className='item-header'>
                    {getTranslate()(`${translationPath}total`)}
                    {item.totalPrice || 'N/A'}
                  </span>
                  <div className='download-button'>
                    <Button
                      className='mdi mdi-download'
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsLoading(true);
                        const data= await DownloadInvoicesByPaymentOrder(item.paymentOrderId);
                        setIsLoading(false);
                        try {
                          const url = window.URL.createObjectURL(data);
                          const link = document.createElement('a');
                          link.href = url;
                          link.target = "_blank"
                          document.body.appendChild(link);
                          link.click();
                          link.download = item.invoiceNumber+".pdf";
                          document.body.append(link);
                          link.remove();
                        } catch (error) {
                         return
                        }
                      }}></Button>
                  </div>
                  <Divider />
                </div>
              </div>
              <div className='invoice-detailes-container'>
                <div className='d-flex-column invoice-info-container'>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>
                      {getTranslate()(`${translationPath}invoice-to`)}:
                    </span>
                  </div>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>
                      {getTranslate()(`${translationPath}phone`)}:
                    </span>
                  </div>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>
                      {getTranslate()(`${translationPath}whatsapp`)}:
                    </span>
                  </div>
                </div>
                <div className='d-flex-column invoice-info-content-container'>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>{item.invoiceToName || 'N/A'}</span>
                  </div>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>{item.invoiceToPhone || 'N/A'}</span>
                  </div>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>{item.invoiceToWhatsApp || 'N/A'}</span>
                  </div>
                </div>
              </div>

              <div className='item-wrapper px-2 payment-info'>
                <span className='item-header px-3'>
                  {getTranslate()(`${translationPath}payment-info`)}
                </span>
              </div>

              <div className='invoice-detailes-container'>
                <div className='d-flex-column invoice-info-container'>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>
                      {getTranslate()(`${translationPath}payment-method`)}
                    </span>
                  </div>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>
                      {getTranslate()(`${translationPath}payment-date`)}:
                    </span>
                  </div>
                </div>
                <div className='d-flex-column invoice-info-content-container'>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>{item.paymentMethod || 'N/A'}</span>
                  </div>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>
                      {moment(item.paymentDate).format('DD/MM/yyyy') || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        ))}
    </div>
  );
};
InvoiceCard.defaultProps = {
  data: { result: [], totalCount: 0 },
  isLoading: false,
  onPageChanged: undefined,
  translationPath: '',
};
