import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { getTranslate } from '../../Helpers';
import { UploadScreenImage } from '../../Serviecs';
import image from '../../Assets/Images/defaults/loader.gif';
import Cropper from 'cropperjs';
import './CropperComponent.scss';

export const CropperComponent = ({
  cropperImage,
  cropperImageChanged,
  isAsync,
  translationPath,
  alt,
  aspectRatio,
  initialAspectRatio,
  minCropBoxWidth,
}) => {
  const [state, setState] = useState(false);
  const imageRef = useRef(null);
  const cropperRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const croppingClicked = () => {
    // if (cropper && cropper.current && cropper.current.getCroppedCanvas())
    if (cropperRef.current) {
      setIsLoading(true);
      cropperRef.current.getCroppedCanvas().toBlob(async (blobElement) => {
        const formData = new FormData();
        formData.append('file', blobElement, `${blobElement.size}.png`);
        const response = await UploadScreenImage(formData);
        setIsLoading(false);
        if (cropperImageChanged) cropperImageChanged(response);
      });
    }
  };
  const cropperHandler = useCallback(() => {
    if (cropperRef.current) cropperRef.current.replace(cropperImage);
    else {
      setIsLoading(true);
      cropperRef.current = new Cropper(imageRef.current, {
        aspectRatio: aspectRatio,
        minCropBoxWidth: minCropBoxWidth,
        dragMode: 'move',
        initialAspectRatio: initialAspectRatio,
        cropBoxResizable: true,
        ready: () => {
          setIsLoading(false);
        },
      });
    }
  }, [aspectRatio, cropperImage, initialAspectRatio, minCropBoxWidth]);
  useEffect(() => {
    if (imageRef.current && state) cropperHandler();
  }, [cropperHandler, cropperImage, state]);
  return (
    (cropperImage && (
      <div className='cropper-wrapper'>
        <div className='cropper-image-wrapper'>
          <img
            ref={imageRef}
            src={cropperImage}
            className={`cropper-image${(!state && ' hidden') || ''}`}
            onLoad={() => {
              setState(true);
            }}
            //   style={{ height: 350, width: 300 }}
            alt={getTranslate()(`${translationPath}${alt}`)}
          />
        </div>
        {!state && <img src={image} alt={getTranslate()(`${translationPath}${alt}`)} />}
        <ButtonBase
          disabled={isLoading || isAsync || !state}
          onClick={croppingClicked}
          className='btns theme-solid'>
          {getTranslate()(`${translationPath}done`)}
          {(isLoading || isAsync || !state) && <span className='mdi mdi-sync mdi-spin mx-2' />}
        </ButtonBase>
      </div>
    )) ||
    null
  );
};
CropperComponent.propTypes = {
  cropperImageChanged: PropTypes.func.isRequired,
  cropperImage: PropTypes.string.isRequired,
  alt: PropTypes.string,
  translationPath: PropTypes.string,
  isAsync: PropTypes.bool,
  aspectRatio: PropTypes.number,
  initialAspectRatio: PropTypes.number,
  minCropBoxWidth: PropTypes.number,
};
CropperComponent.defaultProps = {
  alt: 'cropper-image',
  translationPath: 'home.cropperComponent.',
  aspectRatio: undefined,
  initialAspectRatio: 19 / 6,
  minCropBoxWidth: 100,
  isAsync: false,
};
