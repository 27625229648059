import React from 'react';
import { PropTypes } from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getBgProgressColor } from '../../Helpers';

const ProgressComponet = ({
 value, progressText, inSameLine, isTextColored, textClasses
}) => (
  <div className={`progresses-wrapper${inSameLine ? ' nowrap' : ''}`}>
    {progressText && (
    <span
      className={`progresses-text ${
            isTextColored ? getBgProgressColor(value).textColor : ''
          } ${textClasses}`}
    >
      {progressText}
    </span>
      )}
    <LinearProgress
      className={`progresses ${getBgProgressColor(value).className}`}
      variant="determinate"
      value={value}
    />
  </div>
  );
ProgressComponet.propTypes = {
  value: PropTypes.number.isRequired,
  progressText: PropTypes.string,
  inSameLine: PropTypes.bool,
  isTextColored: PropTypes.bool,
  textClasses: PropTypes.string,
};
ProgressComponet.defaultProps = {
  progressText: null,
  inSameLine: false,
  isTextColored: false,
  textClasses: '',
};
export { ProgressComponet };
