import React, { useEffect, useRef } from 'react';
import defaultImage from '../../Assets/Images/defaults/mobilePreview.png';

export const CanvasComponent = ({ width, height, url, title, description }) => {
  const canvas = useRef(null);

  useEffect(() => {
    if (canvas.current) {
      const img = new Image();
      const defaultImg = new Image();
      defaultImg.src = defaultImage;
      img.src = url;

      const context = canvas.current.getContext('2d');
      img.onload = () => {
        const wrapText = (text, x, y, maxWidth, fontSize) => {
          const words = text.split(' ');
          const lineHeight = fontSize * 1.286;
          let line = '';

          context.font = fontSize + ' ';
          context.textBaseline = 'top';

          for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const descriptionWidth = metrics.width;

            if (n < 15)
              if (descriptionWidth > maxWidth) {
                if (words[n].length > 15) {
                  const firstWord = words[n].substr(0, 15);
                  const secondWord = words[n].substr(15, 30);
                  line = firstWord + ' ' + secondWord;
                }
                context.fillText(line, x, y);
                if (n < words.length - 1) {
                  line = words[n] + ' ';
                  y += lineHeight;
                }
              } else {
                line = testLine;
              }
          }
          context.fillText(line, x, y);
        };
        const canvasWidth = (canvas.current && canvas.current.width / 2) || 270;
        context.drawImage(img, 0, 0, width, height);
        context.drawImage(defaultImg, 0, 0, width, height);
        context.fillStyle = '#fff';
        context.font = 'bold 9px Poppins';
        context.textAlign = 'center';
        wrapText(
          description,
          canvasWidth,
          (canvas.current && canvas.current.height - 80) || 400,
          170,
          9
        );
        setTimeout(() => {
          context.fillText(
            title,
            canvasWidth,
            (canvas.current && canvas.current.height - 100) || 400
          );
        }, 1);
      };
    }
  }, [description, height, title, url, width]);

  return (
    <div className="canvasComponent">
      <div className="mx-2 canvas-boxShadow">
        <canvas
          ref={canvas}
          width={width}
          height={height}
          style={{ borderRadius: 17, boxShadow: '3px 3px 6px #00000033' }}
        />
      </div>
    </div>
  );
};
