import React from 'react';
import { MenuItem } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { GetAllAccessTypes } from '../../../../../../Serviecs/Roleservices/roleServices';
import './DropDownRole.scss';

const DropDownRole = (props) => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [accessType, setAccessTypes] = React.useState({});

  const GetAccessTypes = async (pageIndex, PageSize) => {
    setAccessTypes(await GetAllAccessTypes(pageIndex, PageSize));
  };

  const handleToggle = async () => {
    await GetAccessTypes(1, 5);
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='DropDownRole'>
      <IconButton disabled={props.disabled} size='small' onClick={handleToggle} ref={anchorRef}>
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        <ClickAwayListener onClickAway={handleClose}>
          <Menu
            anchorEl={anchorRef.current}
            open={open}
            onClick={(e) => props.sendSelected(e.target.value)}>
            {accessType &&
              accessType.result &&
              accessType.result.map((acc, i) => (
                <MenuItem key={i} value={acc.accessTypesId}>
                  {acc.accessTypesName}
                </MenuItem>
              ))}
          </Menu>
        </ClickAwayListener>
      </IconButton>
    </div>
  );
};

export { DropDownRole };
