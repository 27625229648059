import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AutocompleteComponent,
  DatePickerComponent,
  DialogComponent,
  Inputs,
  SelectComponet,
  Spinner,
  UploaderComponent,
} from '../../../../../../Componentes';
import { getErrorByName, getTranslate, showError, showSuccess } from '../../../../../../Helpers';
import {
  UpdateCarActivity,
  CreateCarActivity,
  lookupItemsGetId,
  SearchCars,
} from '../../../../../../Serviecs';
import Joi from 'joi';
import './CarActivityManagementDialog.scss';
import { CarStatusEnum } from '../../../../../../Enums';
import Lookups from '../../../../../../Assets/JSON/StaticLookupsIds.json';
import moment from 'moment';

export const CarActivityManagementDialog = ({
  activeItem,
  isOpen,
  isOpenChanged,
  reloadData,
  translationPath,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    else if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  };
  const stateDefault = {
    carsId: null,
    activityTypeId: null,
    checkoutBasketTourId: null, // not saved yet
    activityDateTime: null,
    activityValue: '',
    note: '',
    activityId: null,
    activityAttachment: [],
    carActivityStatus: '',
  };
  const [state, setState] = useReducer(reducer, stateDefault);
  const searchCarsTimer = useRef(null);
  const [activityTypes, setActivityTypes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [cars, setCars] = useState([]);
  const [carNumber, setCarNumber] = useState(null);
  const [tours] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const schema = Joi.object({
    carsId: Joi.number().allow(null),
    checkoutBasketTourId: Joi.number().allow(null),
    // .required()
    // .messages({
    //   'number.base': getTranslate()(`${translationPath}tour-name-is-required`),
    // }),
    activityDateTime: Joi.any()
      .required()
      .messages({
        'any.empty': getTranslate()(`${translationPath}date-time-are-required`),
      }),
    activityValue: Joi.string().messages({
      'string.empty': getTranslate()(`${translationPath}activity-value-is-required`),
    }),
    note: Joi.string().messages({
      'string.empty': getTranslate()(`${translationPath}notes-are-required`),
    }),
    activityTypeId: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}activity-type-is-required`),
      }),
    activityId: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}activity-is-required`),
      }),
    activityAttachment: Joi.array().min(0),
    carActivityStatus: Joi.number()
      .required()
      .messages({
        'number.base': getTranslate()(`${translationPath}activity-status-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const getActivityTypes = useCallback(async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: Lookups.ActivityType,
    });
    setActivityTypes(res);
    setIsLoading(false);
  }, []);
  const getSearchCars = useCallback(async () => {
    setIsLoading(true);
    const res = await SearchCars(1, 10, { carNumber });
    setCars((res && res.result) || []);
    setIsLoading(false);
  }, [carNumber]);
  const searchCarsHandler = (e) => {
    const value = e.target.value;
    if (searchCarsTimer.current) clearTimeout(searchCarsTimer.current);
    searchCarsTimer.current = setTimeout(() => {
      setCarNumber(value);
    }, 700);
  };
  const getActivities = useCallback(async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: Lookups.Activity,
    });
    setActivities(res);
    if (
      activeItem &&
      activeItem.activityId &&
      res.find((item) => item.lookupItemId === activeItem.activityId)
    )
      setState({
        id: 'activityTypeId',
        value:
          (res &&
            res.find((item) => item.lookupItemId === activeItem.activityId).parentLookupItemId) ||
          null,
      });
    setIsLoading(false);
  }, [activeItem]);
  const getEditInit = useCallback(() => {
    if (activeItem.carNumber !== null) setCarNumber(activeItem.carNumber);
    setState({
      id: 'edit',
      value: {
        carsId: activeItem.carsId,
        activityTypeId: null,
        checkoutBasketTourId: activeItem.checkoutBasketTourId,
        activityDateTime:
          (activeItem.activityDateTime && moment(activeItem.activityDateTime)) || null,
        activityValue: activeItem.activityValue || '',
        note: activeItem.note || '',
        activityId: activeItem.activityId,
        activityAttachment: activeItem.activityAttachments,
        carActivityStatus: activeItem.carActivityStatus,
      },
    });
  }, [activeItem]);
  useEffect(() => {
    if (activeItem && activeItem.carActivityId) getEditInit();
  }, [activeItem, getEditInit]);
  console.log(schema);
  useEffect(() => {
    getSearchCars();
  }, [getSearchCars, carNumber]);
  useEffect(() => {
    getActivityTypes();
  }, [getActivityTypes]);
  useEffect(() => {
    getActivities();
  }, [getActivities]);
  useEffect(() => {
    return () => {
      if (searchCarsTimer.current) clearTimeout(searchCarsTimer.current);
    };
  }, []);
  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    setIsLoading(true);
    let response = null;
    if (activeItem && activeItem.carActivityId)
      response = await UpdateCarActivity(activeItem.carActivityId, state);
    else response = await CreateCarActivity(state);
    setIsLoading(false);
    if (response) {
      if (activeItem && activeItem.carActivityId)
        showSuccess(getTranslate()(`${translationPath}car-activity-updated-successfully`));
      else showSuccess(getTranslate()(`${translationPath}car-activity-saved-successfully`));
      if (reloadData) reloadData();
    } else if (activeItem && activeItem.carActivityId)
      showError(getTranslate()(`${translationPath}car-activity-updating-failed`));
    else showError(getTranslate()(`${translationPath}car-activity-saving-failed`));
  };
  return (
    <DialogComponent
      titleText={(activeItem && 'edit-car-activity') || 'add-new-car-activity'}
      saveText={(activeItem && 'edit-car-activity') || 'add-car-activity'}
      dialogContent={
        <div className="car-activity-management-dialog view-wrapper">
          <Spinner isActive={isLoading} />
          <div className="form-item">
            <AutocompleteComponent
              idRef="activityTypeRef"
              labelValue="activity-type"
              inputPlaceholder="activity-type"
              translationPath={translationPath}
              value={
                (state.activityTypeId &&
                  activityTypes.find((item) => state.activityTypeId === item.lookupItemId)) ||
                null
              }
              options={activityTypes}
              getOptionLabel={(option) => option.lookupItemName || ''}
              multiple={false}
              withoutSearchButton
              helperText={getErrorByName(schema, 'activityTypeId').message}
              error={getErrorByName(schema, 'activityTypeId').error}
              isWithError
              isSubmitted={isSubmitted}
              onChange={(event, newValue) => {
                setState({
                  id: 'activityTypeId',
                  value: (newValue && newValue.lookupItemId) || null,
                });
              }}
            />
          </div>
          <div className="form-item">
            <UploaderComponent
              idRef="evidenceRef"
              labelValue="evidence-file"
              multiple
              accept="*"
              translationPath={translationPath}
              initUploadedFiles={
                (state.activityAttachment &&
                  state.activityAttachment.map((item) => ({
                    uuid: item.fileId,
                    fileName: item.fileName,
                  }))) ||
                []
              }
              uploadedChanged={(files) => {
                setState({
                  id: 'activityAttachment',
                  value:
                    (files &&
                      files.map((item) => ({ fileId: item.uuid, fileName: item.fileName }))) ||
                    [],
                });
              }}
            />
          </div>
          <div className="form-item">
            <AutocompleteComponent
              idRef="activityRef"
              labelValue="activity"
              inputPlaceholder="activity"
              translationPath={translationPath}
              value={
                (state.activityId !== null &&
                  activities.find((item) => state.activityId === item.lookupItemId)) ||
                null
              }
              options={
                (state.activityTypeId !== null &&
                  activities.filter((item) => item.parentLookupItemId === state.activityTypeId)) ||
                []
              }
              getOptionLabel={(option) => option.lookupItemName || ''}
              multiple={false}
              withoutSearchButton
              helperText={getErrorByName(schema, 'activityId').message}
              error={getErrorByName(schema, 'activityId').error}
              isWithError
              isSubmitted={isSubmitted}
              onChange={(event, newValue) => {
                setState({ id: 'activityId', value: (newValue && newValue.lookupItemId) || null });
              }}
            />
          </div>
          <div className="form-item">
            <Inputs
              inputPlaceholder="value"
              labelValue="value"
              idRef="activityValueRef"
              value={state.activityValue || ''}
              isWithError
              isSubmitted={isSubmitted}
              helperText={getErrorByName(schema, 'activityValue').message}
              error={getErrorByName(schema, 'activityValue').error}
              onInputChanged={(event) =>
                setState({ id: 'activityValue', value: event.target.value })
              }
              translationPath={translationPath}
            />
          </div>
          <div className="form-item px-0">
            <div className="form-item mxw-50">
              <DatePickerComponent
                idRef="dateRef"
                labelValue="date"
                value={state.activityDateTime}
                helperText={getErrorByName(schema, 'activityDateTime').message}
                error={getErrorByName(schema, 'activityDateTime').error}
                onDateChanged={(value) => setState({ id: 'activityDateTime', value })}
              />
            </div>
            <div className="form-item mxw-50">
              <DatePickerComponent
                idRef="timeRef"
                labelValue="time"
                isTimePicker
                value={state.activityDateTime}
                helperText={getErrorByName(schema, 'activityDateTime').message}
                error={getErrorByName(schema, 'activityDateTime').error}
                onDateChanged={(value) => setState({ id: 'activityDateTime', value })}
                buttonOptions={{
                  className: 'btns-icon theme-solid bg-secondary',
                  iconClasses: 'mdi mdi-clock-time-five',
                  isDisabled: false,
                  isRequired: false,
                }}
              />
            </div>
            <div className="form-item w-100">
              <SelectComponet
                idRef="activityStatusRef"
                labelValue="activity-status"
                isWithError
                helperText={getErrorByName(schema, 'carActivityStatus').message}
                error={getErrorByName(schema, 'carActivityStatus').error}
                data={Object.values(CarStatusEnum).map((item) => ({
                  key: item.key,
                  value: item.value,
                }))}
                emptyItem={{
                  value: '',
                  text: 'select-activity-status',
                }}
                isSubmitted={isSubmitted}
                valueInput="key"
                textInput="value"
                value={state.carActivityStatus}
                onSelectChanged={(event) =>
                  setState({
                    id: 'carActivityStatus',
                    value: event.target.value,
                  })
                }
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
          </div>
          <div className="form-item">
            <Inputs
              inputPlaceholder="notes"
              labelValue="notes"
              idRef="noteRef"
              value={state.note || ''}
              isWithError
              isSubmitted={isSubmitted}
              rows={4}
              multiline
              helperText={getErrorByName(schema, 'note').message}
              error={getErrorByName(schema, 'note').error}
              onInputChanged={(event) => setState({ id: 'note', value: event.target.value })}
              translationPath={translationPath}
            />
          </div>
          <div className="form-item">
            <AutocompleteComponent
              idRef="carsIdRef"
              labelValue="car-number"
              inputPlaceholder="car-number"
              translationPath={translationPath}
              value={(state.carsId && cars.find((item) => item.carsId === state.carsId)) || null}
              options={cars}
              getOptionLabel={(option) =>
                `${(option.carTypes && option.carTypes.typeName) || ''} ${
                  option.carTypes && option.carTypes.carModel
                } ${option.carNumber || ''}`
              }
              multiple={false}
              withoutSearchButton
              helperText={getErrorByName(schema, 'carsId').message}
              error={getErrorByName(schema, 'carsId').error}
              isWithError
              onKeyUp={searchCarsHandler}
              isSubmitted={isSubmitted}
              onChange={(event, newValue) => {
                setState({ id: 'carsId', value: (newValue && newValue.carsId) || null });
              }}
            />
          </div>
          <div className="form-item">
            <AutocompleteComponent
              idRef="tourIdRef"
              labelValue="tour-name"
              inputPlaceholder="tour-name"
              translationPath={translationPath}
              value={state.tourId}
              options={tours}
              getOptionLabel={(option) => option.lookupItemName || ''}
              multiple={false}
              withoutSearchButton
              helperText={getErrorByName(schema, 'tourId').message}
              error={getErrorByName(schema, 'tourId').error}
              isWithError
              isSubmitted={isSubmitted}
              onChange={(event, newValue) => {
                setState({ id: 'tourId', value: (newValue && newValue.lookupItemId) || null });
              }}
            />
          </div>
        </div>
      }
      isOpen={isOpen}
      onSubmit={saveHandler}
      onCloseClicked={isOpenChanged}
      onCancelClicked={isOpenChanged}
      translationPath={translationPath}
    />
  );
};
CarActivityManagementDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  translationPath: PropTypes.string,
};
CarActivityManagementDialog.defaultProps = {
  activeItem: undefined,
  translationPath: '',
};
