import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Button } from '@material-ui/core';
import {
  Spinner,
  ViewTypes,
  NoDataFoundComponent,
  Inputs,
  NoSearchResultComponent,
} from '../../../../Componentes';
import {
  AdvanceSearchUser,
  UserCardsComponent,
  UserDeleteDialog,
  UserCancelDialog,
  UserListView,
} from './UserUtilties';
import { ViewTypesEnum, ActionsEnum, TableActions } from '../../../../Enums';
import { ActiveOrganizationUser, OrganizationUserSearch } from '../../../../Serviecs';
import {
  getTranslate,
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  showSuccess,
  showError,
  getHistory,
} from '../../../../Helpers';
import { useTitle } from '../../../../Hooks/useTitle';

const translationPath = 'Administration.Users.Users.';

const UserView = () => {
  const [viewTypes, setViewTypes] = useState(ViewTypesEnum.cards.key);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [users, setUsers] = useState({ result: [], totalCount: 0 });
  const [activeUserItem, setActiveUserItem] = useState(null);
  const [filter, setFilter] = useState({
    pageSize: 10,
    pageIndex: 0,
    name: null,
    userStatusId: 0,
    userName: null,
    phoneNumber: null,
    email: null,
  });
  const searchTimer = useRef(null);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenDeactive, setIsOpenDeactive] = useState(false);
  // const [isOpenRole, setIsOpenRole] = useState(false);
  // const [isOpenManagement, setIsOpenManagement] = useState(false);

  useTitle(getTranslate()(`${translationPath}users`));

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    const res = await OrganizationUserSearch({ ...filter });
    if (res && res.totalCount === 0) {
      setisFirstLoad(false);
    }
    if (filter.pageIndex === 0 || viewTypes === ViewTypesEnum.tableView.key) {
      setUsers(res);
    } else {
      setUsers((item) => ({
        result: item.result.concat(res.result),
        totalCount: res.totalCount,
      }));
    }
    setIsLoading(false);
  }, [filter, viewTypes]);
  const itemsPerPageChanged = useCallback((event, newItemsPerPage) => {
    setFilter((items) => ({ ...items, pageIndex: 0, pageSize: newItemsPerPage.props.value }));
  }, []);
  const addNewUser = () => {
    getHistory().push(`/home/Users/add`);
  };
  const activateUser = useCallback(async (id) => {
    setIsLoading(true);
    const res = await ActiveOrganizationUser(id);
    if (res) {
      showSuccess(getTranslate()(`${translationPath}user-activated-successfully`));
      setFilter((item) => ({ ...item, pageIndex: 0 }));
      setActiveUserItem(null);
    } else showError(getTranslate()(`${translationPath}user-activate-failed`));
    setIsLoading(false);
  }, []);

  const onSearchChanged = useCallback((searchDto) => {
    setFilter((items) => ({
      ...items,
      ...searchDto,
      pageIndex: 0,
      userTypeId: searchDto.userTypeId || null,
    }));
  }, []);

  const advancedSearchClicked = useCallback(() => {
    sideMenuComponentUpdate(<AdvanceSearchUser onSearchChanged={onSearchChanged} />);
    sideMenuIsOpenUpdate(true);
  }, [onSearchChanged]);
  const searchHandler = (e) => {
    const value = e.target.value;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((items) => ({ ...items, name: value, pageIndex: 0 }));
    }, 700);
  };
  const onTypeChanged = (activeType) => {
    setViewTypes(activeType);
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };

  const actionClicked = useCallback(
    (actionEnum, item) => async (event) => {
      event.stopPropagation();
      setActiveUserItem(item);
      localStorage.setItem('activeUserItem', JSON.stringify(item));
      if (actionEnum === ActionsEnum.delete.key || actionEnum === TableActions.delete.key)
        setIsOpenConfirm(true);
      else if (actionEnum === ActionsEnum.edit.key || actionEnum === TableActions.edit.key)
        getHistory().push(`/home/Users/edit?id=${item.id}`);
      // else if (actionEnum === ActionsEnum.add.key || actionEnum === TableActions.add.key)
      //   setIsOpenRole(true);
      else if (actionEnum === ActionsEnum.check.key || actionEnum === TableActions.check.key) {
        activateUser(item.id);
      } else if (actionEnum === ActionsEnum.close.key || actionEnum === TableActions.close.key)
        setIsOpenDeactive(true);
    },
    [activateUser]
  );
  const activePageChanged = useCallback((e, newPage) => {
    setFilter((item) => ({ ...item, pageIndex: newPage }));
  }, []);
  const onLoadMoreHandler = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: item.pageIndex + 1 }));
  }, []);
  useEffect(() => {
    getUsers();
  }, [getUsers, filter]);

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  return (
    <>
      <div className='view-wrapper'>
        <Spinner isActive={isLoading} />
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section'>
              <Button className='btns theme-solid' onClick={addNewUser}>
                {getTranslate()(`${translationPath}add-new-user`)}
              </Button>
            </div>
            <div className='section px-2'>
              <div className='d-flex-column p-relative'>
                <div className='advance-search-btn'>
                  <Button className='btns theme-transparent' onClick={advancedSearchClicked}>
                    <span>{getTranslate()(`${translationPath}advance-search`)}</span>
                  </Button>
                </div>
                <Inputs
                  idRef='usersSearchRef'
                  variant='outlined'
                  fieldClasses='inputs theme-solid'
                  translationPath={translationPath}
                  label='filter'
                  beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                  onKeyUp={searchHandler}
                  inputPlaceholder='search-users'
                />
              </div>
              <div>
                <ViewTypes
                  onTypeChanged={onTypeChanged}
                  activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
                  className='mb-3'
                />
              </div>
            </div>
          </div>
        </div>
        <NoDataFoundComponent />
        {users && users.totalCount === 0 && !isFirstLoad && <NoSearchResultComponent />}
        {viewTypes === ViewTypesEnum.tableView.key && (
          <UserListView
            data={users}
            actionClicked={(actionEnum, item, focusedRow, event) =>
              actionClicked(actionEnum, item)(event)
            }
            activePageChanged={activePageChanged}
            itemsPerPageChanged={itemsPerPageChanged}
            filter={filter}
          />
        )}

        {viewTypes !== ViewTypesEnum.tableView.key && (
          <UserCardsComponent
            data={users}
            onLoadMore={onLoadMoreHandler}
            isLoading={isLoading}
            actionClicked={actionClicked}
            translationPath={translationPath}
          />
        )}
      </div>
      {/* {isOpenManagement && (
        <UserManagementDialog
          activeUserItem={activeUserItem}
          isOpen={isOpenManagement}
          isOpenChanged={() => {
            setIsOpenManagement(false);
            setActiveUserItem(null);
          }}
          reloadData={() => {
            setFilter((item) => ({ ...item, pageIndex: 0 }));
            setActiveUserItem(null);
            setIsOpenManagement(false);
          }}
        />
      )} */}
      {activeUserItem && (
        <>
          {/* <AssignRoleDialog
            activeUserItem={activeUserItem}
            isOpen={isOpenRole}
            isOpenChanged={() => {
              setIsOpenRole(false);
              setActiveUserItem(null);
            }}
            reloadData={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setActiveUserItem(null);
              setIsOpenRole(false);
            }}
          /> */}
          <UserDeleteDialog
            activeUserItem={activeUserItem}
            isOpen={isOpenConfirm}
            isOpenChanged={() => {
              setIsOpenConfirm(false);
              setActiveUserItem(null);
            }}
            reloadData={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setActiveUserItem(null);
              setIsOpenConfirm(false);
            }}
          />
          <UserCancelDialog
            activeUserItem={activeUserItem}
            isOpen={isOpenDeactive}
            isOpenChanged={() => {
              setIsOpenDeactive(false);
              setActiveUserItem(null);
            }}
            reloadData={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setActiveUserItem(null);
              setIsOpenDeactive(false);
            }}
          />
        </>
      )}
    </>
  );
};
export { UserView };
