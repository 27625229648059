import React from 'react';
import { Button, DialogTitle, DialogActions, Dialog, Typography } from '@material-ui/core';
import { DeleteAdvertisements } from '../../../../../Serviecs/AdsViewServices/AdsViewServices';
import { showSuccess, getTranslate, showError } from '../../../../../Helpers';

const AdsDeleteDialog = (props) => {
  const translationPath = 'Advertisements.';

  const handleDeleteButton = async () => {
    props.setOpen(false);
    const response = await DeleteAdvertisements(props.item.advertisementId);
    if (response) showError(`${getTranslate()(`${translationPath}${response}`)}`);
    else {
      showSuccess(`${getTranslate()(`${translationPath}AdvertisementDeletedSuccessfully`)}`);
      props.setReloaded(true);
      props.reloadData();
    }
  };

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle className='sliderTitle'>
          <Typography style={{ fontSize: 16 }}>
            {getTranslate()(`${translationPath}AreYouSureYouWantToDelete`)}
            {'  '}
            {props.item.advertisementName}?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => props.setOpen(false)} color='primary' className='btns bg-cancel'>
            {getTranslate()(`${translationPath}Cancel`)}
          </Button>
          <Button
            style={{ width: 90, height: 30 }}
            className='btns theme-solid'
            onClick={handleDeleteButton}>
            {getTranslate()(`${translationPath}Confirm`)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { AdsDeleteDialog };
