import React, { useState, useEffect, useCallback } from 'react';
import './NewUsersComponent.scss';
import { getTranslate } from ' ./../../src/Helpers';
import { GetRecentlyRegisteredUsers } from './../../../../../Serviecs';
import { LoadableImageComponant } from './../../../../../Componentes/LoadableImageComponant/LoadableImageComponant';
import { getDownloadableLink } from './../../../../../Helpers';
import { ContactTypeEnum } from './../../../../../Enums/ContactTypeEnum';
import { LoadableImageEnum } from './../../../../../Enums/LoadableImageEnum';
import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';
import moment from 'moment';

export const NewUserComponent = () => {
  const [page] = useState({
    pageSize: 5,
    pageIndex: 1,
  });
  const [newUser, setNewUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const translationPath = 'NewUsers.';

  const GetUsers = useCallback(async () => {
    setIsLoading(true);
    const Status = await GetRecentlyRegisteredUsers(page);
    if (Status) {
      setNewUser(Status.result);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetUsers();
  }, [GetUsers]);

  return (
    <div className='newUser'>
    <Spinner isActive={isLoading} isAbsolute />
      <div className='NewUserTitle'>
        <p className='NewUserPargraph'>{getTranslate()(`${translationPath}New Users`)}</p>
      </div>
      <hr />
      <div>
        {newUser &&
          newUser.map((user, index) => (
            <div key={index} className='User'>
              <LoadableImageComponant
                classes={'loadable-user-image'}
                type={LoadableImageEnum.div.key}
                src={
                  (user && user.profileImg !== null && getDownloadableLink(user.profileImg)) ||
                  ContactTypeEnum.man.defaultImg
                }
                alt={user.firstName}
              />
              <div className='UserInfo'>
                <p className='UsersName'>
                  {`${user.firstName} ${user.secondName ? user.secondName : ''}`}
                </p>
                <p> {`${moment((user.createdOn), "YYYYMMDD").fromNow()}`}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
