import { lookupItemsGetId, getLocationByText } from '../Serviecs';
// import {reRender} from "../Helpers";

export async function OnchangeCountryRule(id, setData, item, itemList, v) {
  if (!item.data.specialKey) return;

  if (item.data.specialKey !== 'country') return;

  resetAllValue(id, setData, item, itemList);

  localStorage.setItem('CurrentAddress', v.lookupItemName);
  const currentIndex = itemList.indexOf(itemList.find((f) => f.field.id.toLowerCase() === id));
  const i1 = itemList.indexOf(
    itemList.find(
      (f) => f.field.id.toLowerCase() === itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (i1 === -1) return;
  const result = await lookupItemsGetId({
    lookupTypeId: itemList[i1].data.lookup,
    lookupParentId: v.lookupItemId,
  });
  itemList[i1].data.enum = [];
  result.map((items) => itemList[i1].data.enum.push(items));

  if (!itemList[currentIndex].data.effectOnMap) {
    setData(i1, null);
    return;
  }
  const i2 = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === itemList[currentIndex].data.effectOnMap)
  );
  if (i2 === -1) return;
  const data = await getCurrentLocation();
  setData(i2, undefined);
  setTimeout(() => {
    setData(i2, data);
  }, 100);
}

export async function OnchangeCityRule(id, v, itemList, setData, item) {
  if (!item.data.specialKey) return;
  if (item.data.specialKey !== 'country') return;
  const cname = localStorage.getItem('CurrentAddress')
    ? localStorage.getItem('CurrentAddress').split(' ')[0]
    : 'United Arab Emirates';
  localStorage.setItem('CurrentAddress', `${cname} ${v.lookupItemName}`);
  const currentIndex = itemList.indexOf(itemList.find((f) => f.field.id.toLowerCase() === id));
  resetAllValue(id, setData, itemList[currentIndex], itemList);
  const i1 = itemList.indexOf(
    itemList.find(
      (f) => f.field.id.toLowerCase() === itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (i1 === -1) return;
  const result = await lookupItemsGetId({
    lookupTypeId: itemList[i1].data.lookup,
    lookupParentId: v.lookupItemId,
  });
  itemList[i1].data.enum = [];
  result.map((items) => itemList[i1].data.enum.push(items));

  if (!itemList[currentIndex].data.effectOnMap) return;
  const i2 = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === itemList[currentIndex].data.effectOnMap)
  );
  if (i2 === -1) return;
  const data = await getCurrentLocation();
  setData(i2, undefined);
  setTimeout(() => {
    setData(i2, data);
  }, 100);
}

export async function OnchangeDistrictRule(id, setData, item, itemList, v) {
  if (!item.data.specialKey) return;
  if (item.data.specialKey !== 'city') return;

  const currentIndex = itemList.indexOf(itemList.find((f) => f.field.id.toLowerCase() === id));

  resetAllValue(id, setData, itemList[currentIndex], itemList);

  const i1 = itemList.indexOf(
    itemList.find(
      (f) => f.field.id.toLowerCase() === itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (i1 === -1) return;
  const result = await lookupItemsGetId({
    lookupTypeId: itemList[i1].data.lookup,
    lookupParentId: v.lookupItemId,
  });
  itemList[i1].data.enum = [];
  result.map((items) => itemList[i1].data.enum.push(items));
  if (v && v.addressType) v.addressType = item.data.addressType;
  setData(currentIndex, undefined);
  setTimeout(() => {
    setData(currentIndex, v);
  }, 100);
}

export async function OnchangeCommunityRule(id, setData, item, itemList, v) {
  if (!item.data.specialKey) return;
  if (item.data.specialKey !== 'district') return;

  const currentIndex = itemList.indexOf(itemList.find((f) => f.field.id.toLowerCase() === id));
  resetAllValue(id, setData, itemList[currentIndex], itemList);
  const i1 = itemList.indexOf(
    itemList.find(
      (f) => f.field.id.toLowerCase() === itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (i1 === -1) return;
  const result = await lookupItemsGetId({
    lookupTypeId: itemList[i1].data.lookup,
    lookupParentId: v.lookupItemId,
  });
  itemList[i1].data.enum = [];
  result.map((items) => itemList[i1].data.enum.push(items));
  v.addressType = item.data.addressType;
  setData(currentIndex, undefined);
  setTimeout(() => {
    setData(currentIndex, v);
  }, 100);
}

export async function OnchangeSubCommunityRule(id, setData, item, itemList, v) {
  if (!item.data.specialKey) return;
  if (item.data.specialKey !== 'community') return;
  const currentIndex = itemList.indexOf(itemList.find((f) => f.field.id.toLowerCase() === id));
  if (!itemList[currentIndex].data.hasEffectOn || itemList[currentIndex].data.hasEffectOn === '')
    return;
  resetAllValue(id, setData, itemList[currentIndex], itemList);
  const i1 = itemList.indexOf(
    itemList.find(
      (f) => f.field.id.toLowerCase() === itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (i1 === -1) return;
  const result = await lookupItemsGetId({
    lookupTypeId: itemList[i1].data.lookup,
    lookupParentId: v.lookupItemId,
  });
  itemList[i1].data.enum = [];
  result.map((items) => itemList[i1].data.enum.push(items));
  v.addressType = item.data.addressType;
  setData(currentIndex, undefined);
  setTimeout(() => {
    setData(currentIndex, v);
  }, 100);
}

export async function getCurrentLocation() {
  if (!localStorage.getItem('CurrentAddress')) return { latitude: 25.2048, longitude: 55.2708 };
  const data = await getLocationByText(localStorage.getItem('CurrentAddress'));
  try {
    const obje = data.Response.View[0].Result[0].Location.DisplayPosition;
    return { latitude: obje.Latitude, longitude: obje.Longitude };
  } catch (e) {
    return { latitude: 25.2048, longitude: 55.2708 };
  }
}

function resetAllValue(id, setData, item, itemList) {
  try {
    const i = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === item.data.hasEffectOn.toLowerCase())
    );
    if (i !== -1) resetAllValue(i, setData, itemList[i], itemList);
    setData(i, '');
  } catch (e) {
    if (id !== -1) setData(id, '');
  }
}
