import config from '../../Assets/Configration/Configration';
import { HttpServices } from '../HttpServices/HttpServices';

export const GetTourStatus = async () => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetTourStatus`
      );
      return result;
    } catch (e) {
      return;
    }
  };

  export const GetToursStatistics = async () => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetToursStatistics`
      );
      return result;
    } catch (e) {
      return;
    }
  };

  export const GetUserTypeStatistics = async () => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetUserTypeStatistics`
      );
      return result;
    } catch (e) {
      return;
    }
  };

  export const GetDriversStatistics = async () => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetDriversStatistics`
      );
      return result;
    } catch (e) {
      return;
    }
  };

  export const GetCarsStatistics = async () => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetCarsStatistics`
      );
      return result;
    } catch (e) {
      return;
    }
  };

  export const GetTripStatistics = async () => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetTripStatistics`
      );
      return result;
    } catch (e) {
      return;
    }
  };


  export const GetTotalSoldToursStatistics = async (dateUnit) => {
    
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetTotalSoldToursStatistics/${dateUnit}`
      );
      return result;
    } catch (e) {
      return;
    }
  
  };

  export const GetTopSoldTours = async ({numOfTours,startDate,endDate}) => {
    
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetTopSoldTours/${numOfTours}/${startDate}/${endDate}`
      );
      return result;
    } catch (e) {
      return;
    }
  
  };
  export const GetTotalSales = async () => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetTotalSales`
      );
      return result;
    } catch (e) {
      return;
    }
  };
  
  export const GetCarActivitiesStatistics = async ({pageSize,pageIndex,fromDate,toDate}) => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetCarActivitiesStatistics?pageIndex=${pageIndex}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}`
      );
      return result;
    } catch (e) {
      return;
    }

  };

  export const GetUpcomingToursStatistics = async ({pageSize,pageIndex,fromDate,toDate}) => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetUpcomingToursStatistics${fromDate}/${toDate}?pageIndex=${pageIndex}&pageSize=${pageSize}`
      );
      return result;
    } catch (e) {
      return;
    }

  };
  
  export const GetTotalSalesStatistics = async (dateUnit) => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/PSTViewing/Dashboard/GetTotalSalesStatistics/${dateUnit}`
      );
      return result;
    } catch (e) {
      return;
    }
  };

  export const GetRecentlyRegisteredUsers = async ({pageIndex,pageSize}) => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/Identity/Dashboard/GetRecentlyRegisteredUsers?pageIndex=${pageIndex}&pageSize=${pageSize}`
      );
      return result;
    } catch (e) {
      return;
    }

  };

  export const GetUserEngagementStatistics = async () => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/Identity/Dashboard/GetUserEngagementStatistics`
      );
      return result;
    } catch (e) {
      return;
    }

  };
  export const GetSocialMediaStatistics = async ({startDate,endDate}) => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/Identity/Dashboard/GetSocialMediaStatistics?startDate=${startDate}&endDate=${endDate}`
      );
      return result;
    } catch (e) {
      return;
    }

  };
  
  export const GetPSTVisits = async () => {
    try {
      const result = await HttpServices.get(
        `${config.serverAddress}/Identity/Dashboard/GetPSTVisits`
      );
      return result;
    } catch (e) {
      return;
    }

  };
  
