import React, { useState, useCallback, useEffect } from 'react';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';
import { getDownloadableLink } from '../../../../Helpers';
import { Button, Grid, Typography, CardContent, Card } from '@material-ui/core';
import { AdsDeleteDialog } from '../AdsViewUtilities/AdsViewDialogs/AdsDeleteDialog';
import { AdsViewSlide } from '../AdsViewUtilities/AdsViewSlide';
import { useEventListener } from '../../../../Helpers';
import { getTranslate } from '../../../../Helpers';
import { LoadableImageComponant, NoSearchResultComponent } from '../../../../Componentes';
import '../AdsView.scss';

const AdsCardView = (props) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [adsInfo, setAdsInfo] = useState({});

  const translationPath = 'Advertisements.';

  const HandleSliderSidePanel = useCallback(
    (item) => {
      sideMenuComponentUpdate(
        <AdsViewSlide
          item={item}
          reloadData={props.reloadData}
          setIsEdit={props.setIsEdit}
          setAdsInfo={props.setAdsInfo}
          setAdsDialog={props.setAdsDialog}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [props.reloadData, props.setAdsDialog, props.setAdsInfo, props.setIsEdit]
  );

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const loadMoreData = useCallback(() => {
    if (
      props.response &&
      props.response.result &&
      props.response.totalCount > props.response.result.length &&
      (props.page + 1) * props.rowsPerPage <= props.response.result.length
    )
      props.setPage(props.page + 1);
  }, [props]);

  const scrollHandler = () => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - 5
    )
      loadMoreData();
  };

  useEventListener('scroll', scrollHandler);

  useEffect(() => {
    if (document.body.scrollHeight <= document.body.clientHeight && !props.isLoading)
      loadMoreData();
    return () => {};
  }, [loadMoreData, props.isLoading]);

  return (
    <div className='section px-2'>
      <Grid container spacing={3} className='slideWrapper adsView'>
        {props.response &&
        props.response.totalCount === 0 &&
        props.searchedItem.length !== 0 &&
        !props.isFirstLoad ? (
          <NoSearchResultComponent />
        ) : (
          <>
            {props.response &&
              props.response.result &&
              props.response.result.map((item, i) => (
                <Grid item lg={6} sm={12} xl={4} xs={12} key={i} className='sliderCard'>
                  <Card onClick={() => HandleSliderSidePanel(item)}>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={11} container spacing={1}>
                          <Grid item xs={12} className='sliderName'>
                            <Typography>{item.advertisementName}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={item.isActive !== true ? 'inactiveSlider' : 'sliderStatus'}>
                            <Typography>
                              {item.isActive === true ? 'Active' : 'Inactive'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className='pageContent'>
                            <span className='mdi mdi-clock' />
                            <Typography className='contentTag'>
                              {getTranslate()(`${translationPath}ID`)}
                            </Typography>
                            <Typography>{item.advertisementId}</Typography>
                          </Grid>
                          <Grid item xs={12} className='pageContent'>
                            <span className='mdi mdi-bullhorn' />
                            <Typography className='contentTag'>
                              {getTranslate()(`${translationPath}AdType`)}
                            </Typography>
                            <Typography>
                              {item.advertisementType === 0 ? 'Link' : 'Tour'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className='pageContent'>
                            <span className='mdi mdi-calendar-month' />
                            <Typography className='contentTag'>
                              {getTranslate()(`${translationPath}ViewFrom`)}
                            </Typography>
                            <Typography>
                              {item.advertisementFromtDate.split('T')[0] +
                                `  ,${getTranslate()(`${translationPath}ViewFrom`)} ` +
                                item.advertisementToDate.split('T')[0]}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <LoadableImageComponant
                              classes={'adImage'}
                              alt={'Ad'}
                              src={getDownloadableLink(item.advertisementImageFileId)}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={1} className='sliderActions'>
                          <Button
                            className='mdi mdi-file-document-edit-outline'
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              props.setAdsInfo(item);
                              props.setIsEdit(true);
                              props.setAdsDialog(true);
                            }}
                          />
                          <Button
                            className='mdi mdi-delete-outline'
                            onClick={async (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDeleteDialog(true);
                              setAdsInfo(item);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </>
        )}
      </Grid>
      <AdsDeleteDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        item={adsInfo}
        setReloaded={props.setReloaded}
        reloadData={props.reloadData}
      />
    </div>
  );
};

export { AdsCardView };
