import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { CollapseComponent, LoadableImageComponant } from '../../../../../../Componentes';
import { getDownloadableLink, getTranslate } from '../../../../../../Helpers';
import { DefaultImagesEnum } from '../../../../../../Enums';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import moment from 'moment';
import './ReservationToursListDetailsComponent.scss';

export const ReservationToursListDetailsComponent = ({ data, translationPath }) => {
  const [isOpen, setIsOpen] = useState([]);
  const toggleHandler = useCallback(
    (index) => () => {
      const itemIndex = isOpen.findIndex((item) => item === index);
      setIsOpen((items) => {
        if (itemIndex !== -1) items.splice(itemIndex, 1);
        else items.push(index);
        return [...items];
      });
    },
    [isOpen]
  );
  const getIsOpen = (index) => isOpen.includes(index);
  return (
    <div className='reservation-tours-list-details-wrapper'>
      {data && (
        <div className='item-header d-flex-center mb-3'>
          <span className='mdi mdi-calendar px-2' />
          {moment(data[0].startTourDate).format('DDD,MMM/YYYY') || 'N/A'}
        </div>
      )}
      {
        <div className='total-tours'>
          <span>
            {data && data.length} {getTranslate()(`${translationPath}reserved-tours`)}
          </span>
        </div>
      }
      {data &&
        data.map((item, index) => (
          <div key={`toursReservationDetailsRef${index + 1}`}>
            <Button
              className='d-flex-v-center-h-between px-0 py-2 separator-h'
              onClick={toggleHandler(index)}>
              <span className='px-2'>
                {(item.tourDetailsDto && item.tourDetailsDto.tourName) || 'N/A'}
              </span>
              <span className={`mdi mdi-chevron-${(getIsOpen(index) && 'right') || 'left'} px-2`} />
            </Button>
            <CollapseComponent
              isOpen={getIsOpen(index)}
              classes=''
              component={
                <div className='trips-timeline-details-wrapper'>
                  <div className='item-wrapper'>
                    <span className='item-header'>
                      <span className='mdi mdi-cash-multiple px-2' />
                      {item.tourDetailsDto.totalFromPriceAfterDiscount || 'N/A'}
                    </span>
                  </div>
                  <div className='item-wrapper'>
                    <span className='item-header'>
                      <span className='mdi mdi-clock-time-five px-2' />
                      {(item.startTourDate && moment(item.startTourDate).format('HH:mm a')) ||
                        'N/A'}
                      <span className='px-1'>-</span>
                      {(item.endTourDate && moment(item.endTourDate).format('HH:mm a')) || 'N/A'}
                    </span>
                  </div>
                  <div className='item-wrapper'>
                    <span className='item-header'>
                      <span className='mdi mdi-calendar px-2' />
                      {(item.startTourDate && moment(item.startTourDate).format('YYYY-MM-DD')) ||
                        'N/A'}
                      <span className='px-1'>-</span>
                      {(item.endTourDate && moment(item.endTourDate).format('YYYY-MM-DD')) || 'N/A'}
                    </span>
                  </div>
                  <div className='item-wrapper'>
                    <span className='item-header fa-start'>
                      <span className='mdi mdi-information-variant px-2' />
                      <span>{item.tourDetailsDto.tourAbout || 'N/A'}</span>
                    </span>
                  </div>
                  <Timeline
                    className='timeline-wrapper'
                    align={localStorage.getItem('localization').isRtl ? 'right' : 'left'}>
                    {item.tourDetailsDto &&
                      item.tourDetailsDto.tourSummaryTrips &&
                      item.tourDetailsDto.tourSummaryTrips.map((subItem, index) => (
                        <TimelineItem key={`tripDetailsTimelineKey${index + 1}`}>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>

                          <TimelineContent>
                            <div className='cards-body'>
                              <div className='trip-image'>
                                <LoadableImageComponant
                                  classes='slide-image'
                                  alt={
                                    subItem.tripCoverImage ||
                                    getTranslate()(`${translationPath}trip-image`)
                                  }
                                  src={
                                    (subItem.tripCoverImage &&
                                      getDownloadableLink(subItem.tripCoverImage)) ||
                                    DefaultImagesEnum.buildings.defaultImg
                                  }
                                />
                              </div>
                              <div className='item-wrapper'>
                                <span className='item-header'>{subItem.tripName}</span>
                              </div>
                              <div className='item-wrapper'>
                                <span className='item-header'>
                                  <span className='mdi mdi-clock-time-five px-2' />
                                  {subItem.tripDurationFormat || 'N/A'}
                                </span>
                                <span className='item-header'>
                                  <span className='mdi mdi-cash-multiple px-2' />
                                  <span>{subItem.tripPrice || 'N/A'}</span>
                                </span>
                              </div>
                            </div>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                  </Timeline>
                </div>
              }
            />
          </div>
        ))}
    </div>
  );
};
ReservationToursListDetailsComponent.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  translationPath: PropTypes.string.isRequired,
};
