import React from 'react';
import PropTypes from 'prop-types';
import { getErrorByName, getTranslate } from '../../../../../../Helpers';
import { Inputs } from '../../../../../../Componentes';

export const TourInformationComponent = ({
  state,
  onInformationChange,
  translationPath,
  isSubmitted,
  schema,
}) => {
  return (
    <div className="tour-information-wrapper">
      <span className="d-flex mb-2 fw-bold c-black-light">
        {getTranslate()(`${translationPath}tour-information`)}
      </span>
      <span className="d-flex mb-3">
        {getTranslate()(`${translationPath}tour-information-description`)}
      </span>
      <div className="d-flex mb-3 px-5">
        <Inputs
          idRef="tourNameRef"
          labelValue="tour-name"
          isRequired
          translationPath={translationPath}
          isWithError
          helperText={getErrorByName(schema, 'tourName').message}
          inputPlaceholder="tour-name"
          isSubmitted={isSubmitted}
          error={getErrorByName(schema, 'tourName').error}
          value={state.tourName}
          onInputChanged={(event) => {
            onInformationChange('tourName', event.target.value);
          }}
        />
      </div>
      <div className="d-flex mb-3 px-5">
        <Inputs
          idRef="tourAboutRef"
          labelValue="tour-description"
          isRequired
          translationPath={translationPath}
          isWithError
          helperText={getErrorByName(schema, 'tourAbout').message}
          inputPlaceholder="tour-description"
          isSubmitted={isSubmitted}
          error={getErrorByName(schema, 'tourAbout').error}
          value={state.tourAbout}
          onInputChanged={(event) => {
            onInformationChange('tourAbout', event.target.value);
          }}
        />
      </div>
    </div>
  );
};

TourInformationComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  onInformationChange: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
};
