import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteComponent, DialogComponent, Spinner } from '../../../../../Componentes';
import {
  UpdateReservationTour,
  SearchCars,
  ApplicationUserSearch,
  ActiveOrganizationUser,
} from '../../../../../Serviecs';
import { getErrorByName, getTranslate, showError, showSuccess } from '../../../../../Helpers';
import Lookups from '../../../../../Assets/JSON/StaticLookupsIds.json';
import './PendingToursManagementDialog.scss';
import Joi from 'joi';
export const PendingToursManagementDialog = ({
  activeItem,
  isOpen,
  isOpenChanged,
  reloadData,
  translationPath,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const reducer = useCallback((state, action) => {
    if (action.parentId) {
      state[action.parentId][action.index][action.id] = action.value;
      return { ...state };
    } else if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    else if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  }, []);
  const [agents, setAgents] = useState([]);
  const [carsDrivers, setCarsDrivers] = useState([]);
  const [agentSearch, setAgentSearch] = useState(null);
  const agentsSearchTimer = useRef(null);
  const driversSearchTimer = useRef(null);
  const carsSearchTimer = useRef(null);
  const [state, setState] = useReducer(reducer, {
    agentId: null,
    carDriver: [],
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const schema = Joi.object({
    agentId: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}agent-is-required`),
        'string.base': getTranslate()(`${translationPath}agent-is-required`),
      }),
    carDriver: Joi.array()
      .items({
        driverId: Joi.string()
          .required()
          .messages({
            'string.empty': getTranslate()(`${translationPath}driver-is-required`),
            'string.base': getTranslate()(`${translationPath}driver-is-required`),
          }),
        carId: Joi.number()
          .required()
          .messages({
            'number.base': getTranslate()(`${translationPath}car-is-required`),
          }),
      })
      .min(1)
      .messages({
        'array.min': getTranslate()(`${translationPath}drivers-&-cars-must-be-at-least-one`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const getUserById = useCallback(async (userId, index) => {
    const response = await ActiveOrganizationUser(userId);
    setIsLoading(false);
    if (!index && index !== 0) setAgents((response && [response]) || []);
    else
      setCarsDrivers((items) => {
        items[index].drivers = (response && [response]) || [];
        return [...items];
      });
  }, []);
  const getAllAgents = useCallback(async () => {
    setIsLoading(true);
    const response = await ApplicationUserSearch({
      name: agentSearch,
      userTypeId: Lookups.AGENTS,
      pageIndex: 0,
      pageSize: 10,
    });
    if (state.agentId && (!response || response.result.length === 0)) getUserById(state.agentId);
    else {
      setAgents((response && response.result) || []);
      setIsLoading(false);
    }
  }, [agentSearch, getUserById, state.agentId]);
  const getAllDrivers = useCallback(
    async (driverName, index, driverId) => {
      setIsLoading(true);
      const driversResponse = await ApplicationUserSearch({
        name: driverName,
        userTypeId: Lookups.DRIVER,
        pageIndex: 0,
        pageSize: 10,
      });
      if (driverId && (!driversResponse || driversResponse.result.length === 0))
        getUserById(driverId, index);
      else {
        setIsLoading(false);
        setCarsDrivers((items) => {
          items[index].drivers = (driversResponse && driversResponse.result) || [];
          return [...items];
        });
      }
    },
    [getUserById]
  );
  const getAllCars = useCallback(async (carTypesId, carNumber, index) => {
    setIsLoading(true);
    const carsResponse = await SearchCars(1, 10, { carTypesId, carNumber });
    setCarsDrivers((items) => {
      items[index].cars = (carsResponse && carsResponse.result) || [];
      return [...items];
    });
    setIsLoading(false);
  }, []);
  const getAllCarsDrivers = useCallback(
    async (carsDriversItems) => {
      carsDriversItems.map((items, localIndex) => {
        getAllCars(items.carTypeId, items.carNumber, localIndex);
        getAllDrivers(items.driverName, localIndex, items.driverId);
        return undefined;
      });
    },
    [getAllCars, getAllDrivers]
  );
  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error && getErrorByName(schema, 'carDriver').error) {
      showError(getErrorByName(schema, 'carDriver').message);
      return;
    }
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    setIsLoading(true);
    const res = await UpdateReservationTour(activeItem.reservationTourId, state);
    setIsLoading(false);
    if (res) {
      showSuccess(getTranslate()(`${translationPath}pending-tour-updated-successfully`));
      reloadData();
    } else showError(getTranslate()(`${translationPath}pending-tour-updating-failed`));
  };
  const agentsSearchHandler = (event) => {
    const value = event.target.value;
    if (state.agentId)
      setState({
        id: 'agentId',
        value: null,
      });
    if (agentsSearchTimer.current) clearTimeout(agentsSearchTimer.current);
    agentsSearchTimer.current = setTimeout(() => {
      setAgentSearch(value);
    }, 1000);
  };
  const carsSearchHandler = (carTypeId, index) => (event) => {
    const value = event.target.value;
    if (state.carDriver[index].carId)
      setState({
        parentId: 'carDriver',
        index,
        id: 'carId',
        value: null,
      });
    if (carsSearchTimer.current) clearTimeout(carsSearchTimer.current);
    carsSearchTimer.current = setTimeout(() => {
      getAllCars(carTypeId, value, index);
    }, 1000);
  };
  const driversSearchHandler = (index) => (event) => {
    const value = event.target.value;
    if (state.carDriver[index].driverId)
      setState({
        parentId: 'carDriver',
        index,
        id: 'driverId',
        value: null,
      });
    if (driversSearchTimer.current) clearTimeout(driversSearchTimer.current);
    driversSearchTimer.current = setTimeout(() => {
      getAllDrivers(value, index);
    }, 1000);
  };
  useEffect(() => {
    getAllAgents();
  }, [agentSearch, getAllAgents]);
  useEffect(() => {
    if (activeItem) {
      setAgentSearch((activeItem.agent && activeItem.agent.fullName) || null);
      setCarsDrivers(
        (activeItem.carDrivers &&
          activeItem.carDrivers.length > 0 &&
          activeItem.carDrivers.map((items) => ({
            carTypeId: items.car && items.car.carTypeId,
            cars: [],
            drivers: [],
          }))) ||
          []
      );
      getAllCarsDrivers(
        (activeItem.carDrivers &&
          activeItem.carDrivers.length > 0 &&
          activeItem.carDrivers.map((items) => ({
            carTypeId: (items.car && items.car.carTypeId) || null,
            carNumber: (items.car && items.car.carNumber) || null,
            driverName: (items.driver && items.driver.fullName) || null,
            driverId: (items.driver && items.driver.userId) || null,
          }))) ||
          []
      );
      setState({
        id: 'edit',
        value: {
          agentId: (activeItem.agent && activeItem.agent.userId) || null,
          carDriver:
            (activeItem.carDrivers &&
              activeItem.carDrivers.map((items) => ({
                driverId: (items.driver && items.driver.userId) || null,
                carId: (items.car && items.car.carId) || null,
              }))) ||
            null,
        },
      });
    }
  }, [activeItem, getAllCarsDrivers]);
  useEffect(() => {
    return () => {
      if (agentsSearchTimer.current) clearTimeout(agentsSearchTimer.current);
      if (driversSearchTimer.current) clearTimeout(driversSearchTimer.current);
      if (carsSearchTimer.current) clearTimeout(carsSearchTimer.current);
    };
  }, []);
  return (
    <DialogComponent
      titleText="edit-pending-tour"
      saveText="save-changes"
      dialogContent={
        <div className="pending-tours-management-dialog view-wrapper">
          <Spinner isActive={isLoading} />
          <div className="form-item">
            <AutocompleteComponent
              idRef="agentRef"
              labelValue="agent"
              inputPlaceholder="agent"
              translationPath={translationPath}
              value={(state.agentId && agents.find((item) => item.id === state.agentId)) || null}
              options={agents}
              // chipsLabel={(option) => option.fullName || ''}
              renderOption={(option) =>
                `${option.fullName || ''}${(option.userName && '(' + option.userName + ')') || ''}`
              }
              getOptionLabel={(option) => option.fullName || ''}
              getOptionSelected={(option) => option.id === state.agentId}
              multiple={false}
              onKeyUp={agentsSearchHandler}
              withoutSearchButton
              helperText={getErrorByName(schema, 'agentId').message}
              error={getErrorByName(schema, 'agentId').error}
              isWithError
              isSubmitted={isSubmitted}
              onChange={(event, newValue) => {
                if (!newValue || newValue.id !== state.agentId)
                  setState({ id: 'agentId', value: (newValue && newValue.id) || null });
              }}
            />
          </div>
          <div className="w-100 px-2">
            <span className="fw-bold">{getTranslate()(`${translationPath}cars-&-drivers`)}</span>
          </div>
          {carsDrivers.map((items, index) => (
            <div className="w-100" key={`carsDriversRef${index + 1}`}>
              <div className="form-item">
                <AutocompleteComponent
                  idRef={`carRef${index + 1}`}
                  labelValue="car-number"
                  inputPlaceholder="car-number"
                  translationPath={translationPath}
                  value={
                    (state.carDriver[index].carId &&
                      items.cars.find((item) => item.carsId === state.carDriver[index].carId)) ||
                    null
                  }
                  options={items.cars}
                  renderOption={(option) =>
                    `${(option.carTypes && option.carTypes.typeName) || ''} ${
                      option.carTypes && option.carTypes.carModel
                    } ${(option.carNumber && '(' + option.carNumber + ')') || ''}`
                  }
                  getOptionLabel={(option) => option.carNumber || ''}
                  getOptionSelected={(option) => option.carsId === state.carDriver[index].carId}
                  multiple={false}
                  withoutSearchButton
                  helperText={getErrorByName(schema, `carDriver.${index}.carId`).message}
                  error={getErrorByName(schema, `carDriver.${index}.carId`).error}
                  isWithError
                  onKeyUp={carsSearchHandler(items.carTypeId, index)}
                  isSubmitted={isSubmitted}
                  onChange={(event, newValue) => {
                    // console.log(newValue,state.carDriver[index].carId)
                    if (!newValue || newValue.carsId !== state.carDriver[index].carId)
                      setState({
                        parentId: 'carDriver',
                        index,
                        id: 'carId',
                        value: (newValue && newValue.carsId) || null,
                      });
                  }}
                />
              </div>
              <div className="form-item">
                <AutocompleteComponent
                  idRef={`driverRef${index + 1}`}
                  labelValue="driver"
                  inputPlaceholder="driver"
                  translationPath={translationPath}
                  value={
                    (state.carDriver[index].driverId &&
                      items.drivers.find((item) => item.id === state.carDriver[index].driverId)) ||
                    null
                  }
                  options={items.drivers}
                  // chipsLabel={(option) => option.fullName || ''}
                  renderOption={(option) =>
                    `${option.fullName || ''}${
                      (option.userName && '(' + option.userName + ')') || ''
                    }`
                  }
                  getOptionLabel={(option) => option.fullName || ''}
                  getOptionSelected={(option) => option.id === state.carDriver[index].driverId}
                  multiple={false}
                  onKeyUp={driversSearchHandler(index)}
                  withoutSearchButton
                  helperText={getErrorByName(schema, `carDriver.${index}.driverId`).message}
                  error={getErrorByName(schema, `carDriver.${index}.driverId`).error}
                  isWithError
                  isSubmitted={isSubmitted}
                  onChange={(event, newValue) => {
                    if (!newValue || newValue.id !== state.carDriver[index].driverId)
                      setState({
                        parentId: 'carDriver',
                        index,
                        id: 'driverId',
                        value: (newValue && newValue.id) || null,
                      });
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      }
      isOpen={isOpen}
      onSubmit={saveHandler}
      onCloseClicked={isOpenChanged}
      onCancelClicked={isOpenChanged}
      translationPath={translationPath}
    />
  );
};

PendingToursManagementDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
};
