import { ButtonBase } from '@material-ui/core';
import Joi from 'joi';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Spinner } from '../../../../Componentes';
import {
  getErrorByName,
  getHistory,
  GetParams,
  getTranslate,
  showError,
  showSuccess,
} from '../../../../Helpers';
import { GetOfferById, UpdateOffer, CreateOffer } from '../../../../Serviecs';
import { OfferFormComponent, OfferToursComponent } from './OfferedToursManagementUtilities';
import moment from 'moment';
import './OfferedToursManagementView.scss';
const translationPath = 'home.tours.offeredToursManagementView.';
export const OfferedToursManagementView = () => {
  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    search: '',
    typeId: null,
  });
  const [isNext, setIsNext] = useState(true);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    else if (action.id === 'edit') {
      return action.value;
    }
  }, []);
  const [selectedTourTypes, setSelectedTourTypes] = useState([]);
  const [state, setState] = useReducer(reducer, {
    name: '',
    description: '',
    tourIds: [],
    formIds: [],
    startDateTime: null,
    endDateTime: null,
    discountPercentage: null,
    discountValue: null,
    discountLimit: null,
    isHot: false,
  });
  const [notCompletlyLoaded, setNotCompletlyLoaded] = useState([]);
  const [droppedTours, setDroppedTours] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const schema = Joi.object({
    name: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${translationPath}offer-name-is-required`),
      }),
    description: Joi.string().allow(''),
    tourIds: Joi.array()
      .custom((value, helpers) => {
        if ((!value || value.length === 0) && (!state.formIds || state.formIds.length === 0))
          return helpers.error('state.tours');
        return value;
      })
      .messages({
        'state.tours': getTranslate()(`${translationPath}please-select-at-least-one-tour`),
      }),
    formIds: Joi.array()
      .custom((value, helpers) => {
        if ((!value || value.length === 0) && (!state.tourIds || state.tourIds.length === 0))
          return helpers.error('state.tours');
        return value;
      })
      .messages({
        'state.tours': getTranslate()(`${translationPath}please-select-at-least-one-tour`),
      }),
    startDateTime: Joi.date()
      .required()
      .messages({
        'date.base': getTranslate()(`${translationPath}offer-date-range-is-required`),
      }),
    endDateTime: Joi.date()
      .required()
      .messages({
        'date.base': getTranslate()(`${translationPath}offer-date-range-is-required`),
      }),
    discountPercentage: Joi.any()
      .custom((value, helpers) => {
        if (!value && !state.discountValue) return helpers.error('state.discountPercentage');
        if (value && +value > 100) return helpers.error('number.max');
        return value;
      })
      .messages({
        'number.max': getTranslate()(`${translationPath}must-be-less-than-or-equal-to-100`),
        'state.discountPercentage': getTranslate()(
          `${translationPath}discount-percentage-is-required`
        ),
      }),
    discountValue: Joi.any()
      .custom((value, helpers) => {
        if (!value && !state.discountPercentage) return helpers.error('state.discountValue');
        return value;
      })
      .messages({
        'state.discountValue': getTranslate()(`${translationPath}discount-value-is-required`),
      }),
    discountLimit: Joi.any()
      .custom((value, helpers) => {
        if (!value && !state.discountValue) return helpers.error('state.discountLimit');
        return value;
      })
      .messages({
        'state.discountLimit': getTranslate()(`${translationPath}max-discount-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const saveHandler = async () => {
    setIsSubmitted(true);
    if (schema.error && getErrorByName(schema, 'tourIds').error) {
      showError(getErrorByName(schema, 'tourIds').message);
      return;
    }
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    setIsLoading(true);
    let response = null;
    if (id) response = await UpdateOffer(id, state);
    else response = await CreateOffer(state);
    setIsLoading(false);
    if (response && response.offerId) {
      if (id) showSuccess(getTranslate()(`${translationPath}offer-updated-successfully`));
      else showSuccess(getTranslate()(`${translationPath}offer-saved-successfully`));
      getHistory().push('/home/offered-tours/view');
    } else if (id){
      showError(
        getTranslate()(
          `${translationPath}${'___DATE_INTERSECTION_ERROR___' 
          }`
        )
      );}
    else{
    showError(
      getTranslate()(
        `${translationPath}${ '___DATE_INTERSECTION_ERROR___'
        }`
      )
    );
}  };
  const submitHandler = (event) => {
    event.preventDefault();
    if (isNext) {
      let hasError = false;
      if (schema.error) {
        if (getErrorByName(schema, 'name').error) hasError = true;
        if (
          getErrorByName(schema, 'startDateTime').error ||
          getErrorByName(schema, 'endDateTime').error
        )
          hasError = true;
        if (getErrorByName(schema, 'discountPercentage').error) hasError = true;
      }
      if (hasError) {
        setIsSubmitted(true);
        showError(getTranslate()('shared.please-fix-all-errors'));
        return;
      }
      setIsSubmitted(false);
      setIsNext(false);
    } else saveHandler();
  };
  const getEditInit = useCallback(async () => {
    if (id === null) return;
    setIsLoading(true);
    const editOffer = JSON.parse(localStorage.getItem('offeredToursById'));
    const res = await GetOfferById(id, 0, editOffer.tourCount);
    const tourIds = (res && res.result && res.result.map((item) => item.tourId)) || [];
    const formIds =
      (editOffer && editOffer.forms && editOffer.forms.map((item) => item && item.formsId)) || [];
    // (editOffer && [...new Set()]) || [];
    const forms = (editOffer && editOffer.forms && editOffer.forms.map((item) => item)) || [];
    // (editOffer && [...new Set(editOffer.forms.map((item) => item))]) || [];

    setDroppedTours((res && res.result) || []);
    setSelectedTourTypes(forms || []);
    setNotCompletlyLoaded(tourIds || []);
    //   &&
    //   res.offerTours.map((item) => ({
    //     offerId: item.offerId,
    //     tripName: item.trip.tripName,
    //     tripPrice: item.trip.tripPrice,
    //     tripDuration: item.trip.tripDuration,
    //     coverTripImageId: item.trip.coverTripImageId,
    //     tripFeatures: item.trip.featureInformations,
    //     address: item.trip.address,
    //     typeName: item.trip.items.forms.formsName,
    //   }))
    // setFilter((items) => ({ ...items, tourIds }));
    setState({
      id: 'edit',
      value: {
        name: editOffer.name || '',
        description: editOffer.description || '',
        tourIds,
        formIds,
        startDateTime: moment(editOffer.startDateTime).toDate(),
        endDateTime: moment(editOffer.endDateTime).toDate(),
        discountPercentage: editOffer.discountPercentage,
        discountValue: editOffer.discountValue || null,
        discountLimit: editOffer.discountLimit || null,
        isHot: editOffer.isHot,
      },
    });
    setIsLoading(false);
  }, [id]);
  useEffect(() => {
    if (id !== null);
    getEditInit();
  }, [getEditInit, id]);
  useEffect(() => {
    const editId = GetParams('id');
    if (editId !== null) setId(+editId);
  }, []);
  return (
    <div className='offered-tours-management-view view-wrapper'>
      <Spinner isActive={isLoading} />
      <form noValidate onSubmit={submitHandler} className='form-content-wrapper'>
        <div className='header-section'>
          <div className='section px-2'>
            <ButtonBase type='submit' className='btns theme-solid mx-2 mb-2'>
              <span>
                {getTranslate()(`${translationPath}${(isNext && 'next') || 'save-offer'}`)}
              </span>
            </ButtonBase>
            <ButtonBase
              className='btns theme-solid bg-cancel mb-2 mx-2'
              onClick={() => getHistory().push('/home/offered-tours/view')}>
              <span>{getTranslate()(`${translationPath}cancel`)}</span>
            </ButtonBase>
          </div>
        </div>
        <OfferFormComponent
          state={state}
          onStateChanged={(stateId, stateValue) => setState({ id: stateId, value: stateValue })}
          isSubmitted={isSubmitted}
          schema={schema}
          isNext={isNext}
          translationPath={translationPath}
        />
        <OfferToursComponent
          offerId={id}
          state={state}
          onStateChanged={(stateId, stateValue) => {
            setState({ id: stateId, value: stateValue });
          }}
          onNextChanged={(newValue) => setIsNext(newValue)}
          filter={filter}
          selectedTourTypes={selectedTourTypes}
          onSelectedTourTypesChanged={(newValue) => setSelectedTourTypes(newValue)}
          setFilter={setFilter}
          notCompletlyLoaded={notCompletlyLoaded}
          notCompletlyLoadedChanged={(newValue) => setNotCompletlyLoaded(newValue)}
          droppedTours={droppedTours}
          onDroppedToursChanged={(droppedToursValue) => setDroppedTours(droppedToursValue)}
          isNext={isNext}
          isLoading={isLoading}
          translationPath={translationPath}
        />
      </form>
    </div>
  );
};
