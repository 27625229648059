import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getDownloadableLink,
  getTranslate,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from '../../../../Helpers';
import { GetAllUpComingReservationTour } from '../../../../Serviecs';
import { useTitle } from '../../../../Hooks/useTitle';
import {
  LoadableImageComponant,
  Calendar,
  NoContentComponent,
  Spinner,
} from '../../../../Componentes';
import { PendingToursCardComponent, PendingToursManagementDialog } from '../PendingToursUtilities';
import { DefaultImagesEnum, LoadableImageEnum, ViewTypesEnum } from '../../../../Enums';
import { getLanguage } from 'react-switch-lang';
import moment from 'moment';
import './ReservationToursListView.scss';
import { ButtonBase } from '@material-ui/core';
import { ReservationToursListDetailsComponent } from './ReservationToursListUtilities';

export const ReservationToursListView = ({
  filter,
  translationPath,
  onFilterChanged,
  activeActionType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenManagement, setIsOpenManagement] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [reservedAndPendingTours, setReservedAndPendingTours] = useState({
    result: [],
    totalCount: 0,
  });
  useTitle(getTranslate()(`${translationPath}reserved-tours`));
  const onActionClicked = (actionKey, item) => {
    setActiveItem(item);
    setIsOpenManagement(true);
  };
  const getPendingTours = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllUpComingReservationTour(filter);

    if (filter.pageIndex === 0) {
      setReservedAndPendingTours({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else
      setReservedAndPendingTours((item) => ({
        result: item.result.concat((res && res.result) || []),
        totalCount: (res && res.totalCount) || 0,
      }));

    setIsLoading(false);
  }, [filter]);
  const eventsClickHandler = useCallback(
    (events) => () => {
      sideMenuComponentUpdate(
        <ReservationToursListDetailsComponent data={events} translationPath={translationPath} />
      );
      sideMenuIsOpenUpdate(true);
    },
    [translationPath]
  );
  const onLoadMoreHandler = useCallback(() => {
    if (onFilterChanged) onFilterChanged({ ...filter, pageIndex: filter.pageIndex + 1 });
  }, [filter, onFilterChanged]);
  useEffect(() => {
    getPendingTours();
  }, [getPendingTours, filter]);
  useEffect(() => {
    return () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    };
  }, []);
  return (
    <div className='reservation-tours-list-wrapper'>
      <Spinner isActive={isLoading} />

      {((reservedAndPendingTours && !Array.isArray(reservedAndPendingTours.result)) ||
        reservedAndPendingTours.result.length === 0) &&
        !filter.search && <NoContentComponent />}
      {(activeActionType === ViewTypesEnum.cards.key && (
        <PendingToursCardComponent
          data={reservedAndPendingTours}
          filter={filter}
          onLoadMore={onLoadMoreHandler}
          onActionClicked={onActionClicked}
          translationPath={translationPath}
          isLoading={isLoading}
        />
      )) ||
        (reservedAndPendingTours && reservedAndPendingTours.result.length > 0 && (
          <div className='event-calender'>
            <Calendar
              selectedDateChanged={(selectedDate) => {
                // setCurrentActiveDate(selectedDate);
              }}
              // selectedDate={currentActiveDate}
              dayRender={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                const events =
                  isInCurrentMonth &&
                  reservedAndPendingTours &&
                  reservedAndPendingTours.result &&
                  reservedAndPendingTours.result.filter((item) => moment(item).isSame(day, 'day'));
                if (!events || events.length === 0)
                  return <span className='normal-day-wrapper'>{dayComponent.props.children}</span>;
                return (
                  <ButtonBase className='events-wrapper' onClick={eventsClickHandler(events)}>
                    <span className='day-label'>{dayComponent.props.children}</span>
                    <div className='images-more-wrapper'>
                      {events && events.length > 0 && (
                        <div className='slide-images'>
                          {events
                            .filter((event, index) => index < 4)
                            .map((event, index) => (
                              <LoadableImageComponant
                                classes='slide-image'
                                key={`tourImageSliderRef${index + 1}`}
                                type={LoadableImageEnum.div.key}
                                alt={getTranslate()(`${translationPath}tour-image`)}
                                src={
                                  (event.tourDetailsDto.tourSummaryTrips.length > 0 &&
                                    event.tourDetailsDto.tourSummaryTrips[0].tripCoverImage &&
                                    getDownloadableLink(
                                      event.tourDetailsDto.tourSummaryTrips[0].tripCoverImage
                                    )) ||
                                  DefaultImagesEnum.buildings.defaultImg
                                }
                                style={{
                                  zIndex: events.length - index,
                                  transform: `translateX(${
                                    (getLanguage() === 'ar' && -(index * (100 / events.length))) ||
                                    index * (100 / events.length)
                                  }%)`,
                                }}
                              />
                            ))}
                        </div>
                      )}
                      {events && events.length > 4 && (
                        <div>{`+${events.length - 4} ${getTranslate()(
                          `${translationPath}more`
                        )}`}</div>
                      )}
                    </div>
                  </ButtonBase>
                );
              }}
              onlyColorChanged={false}
              wrapperClasses=' full-callender w-100'
            />
          </div>
        ))}

      {isOpenManagement && (
        <PendingToursManagementDialog
          activeItem={activeItem}
          isOpen={isOpenManagement}
          isOpenChanged={() => {
            setIsOpenManagement(false);
            setActiveItem(null);
          }}
          reloadData={() => {
            if (onFilterChanged) onFilterChanged({ ...filter, pageIndex: 0 });
            setIsOpenManagement(false);
            setActiveItem(null);
          }}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};

ReservationToursListView.propTypes = {
  filter: PropTypes.instanceOf(Object).isRequired,
  translationPath: PropTypes.string.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  activeActionType: PropTypes.oneOf(Object.values(ViewTypesEnum).map((items) => items.key))
    .isRequired,
};
