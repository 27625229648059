import React from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from '../../../../../Helpers';
import { Button } from '@material-ui/core';
import { GoogleMapsComponent } from '../../../../../Componentes';
import './MapDetailsComponent.scss';
const translationPath = 'home.trips.tripsView.mapDetailsComponent.';
export const MapDetailsComponent = ({ activeItem, onCloseClicked, isOpenDetailsMap }) => {
  return (
    <div className="map-details-component-wrapper">
      <div className="map-details-wrapper">
        <div className="map-details-header">
          <span className="texts-large">{getTranslate()(`${translationPath}map-view`)}</span>
          <span>
            <span className="texts-large mdi mdi-map-marker" />
            <span className="texts-large px-2">{getTranslate()(`${translationPath}address`)}:</span>
            <span>{activeItem.address}</span>
          </span>
          <Button className="btns-icon theme-transparent p-absolute-tr-0" onClick={onCloseClicked}>
            <span className="mdi mdi-close" />
          </Button>
        </div>
        <div className="map-details-body">
          {isOpenDetailsMap && (
            <GoogleMapsComponent
              locations={[
                {
                  latitude: activeItem.latitude,
                  longitude: activeItem.longitude,
                },
              ]}
              center={
                (activeItem.latitude && { lat: activeItem.latitude, lng: activeItem.longitude }) ||
                undefined
              }
            />
            // <MapComponent
            //   height={700}
            //   anchor={[activeItem.latitude, activeItem.longitude]}
            //   center={[activeItem.latitude, activeItem.longitude]}
            // />
          )}
        </div>
      </div>
    </div>
  );
};

MapDetailsComponent.propTypes = {
  activeItem: PropTypes.instanceOf(Object).isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  isOpenDetailsMap: PropTypes.bool.isRequired,
};
