import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { getDownloadableLink } from '../../../../Helpers';
import { DeleteSliderDialog } from '../SliderViewUtilities/SliderViewDialogs/DeleteSliderDialog';
import { getTranslate } from '../../../../Helpers';
import { LoadableImageComponant, CanvasComponent } from '../../../../Componentes';
import './SliderSidePanel.scss';

const SliderSidePanel = ({ item, reloadData, setAddDialog, setIsEdit, setSliderInfo }) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const translationPath = 'SliderView.';

  return (
    <form
      className='advance-search-wrapper sliderSidePanel'
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}>
      <Grid container spacing={1}>
        <Grid item xs={12} className='sliderName'>
          <Typography>{item.sliderName}</Typography>
          <Button onClick={() => setDeleteDialog(true)}>
            {getTranslate()(`${translationPath}Delete`)}
          </Button>
        </Grid>
        <Grid item xs={12} className={item.sliderStatus !== 1 ? 'inactiveSlider' : 'sliderStatus'}>
          <Typography>
            {item.sliderStatus === 1
              ? getTranslate()(`${translationPath}Active`)
              : getTranslate()(`${translationPath}Inactive`)}
          </Typography>
        </Grid>
        <Grid item xs={12} className='pageContent'>
          <span className='mdi mdi-calendar-month' />
          <Typography className='contentTag'>
            {getTranslate()(`${translationPath}From`)}:
          </Typography>
          <Typography>
            {item.startDate.split('T')[0] +
              `   ,${getTranslate()(`${translationPath}To`)}` +
              item.endDate.split('T')[0]}
          </Typography>
        </Grid>
        <Grid item xs={12} className='pageContent'>
          <span className='mdi mdi-clock' />
          <Typography className='contentTag'>
            {item.screens.length + `  ${getTranslate()(`${translationPath}Slides`)}`}
          </Typography>
        </Grid>
        <Grid item xs={12} className='slideImgContainer'>
          {Array.isArray(item.screens) &&
            item.screens.map((el, index) => (
              <div className='displayImg' key={`slideImgContainer${index+1}`} onClick={() => {}}>
                <React.Fragment key={index}>
                  <LoadableImageComponant
                    alt={el.screenTitle}
                    src={getDownloadableLink(el.publicPreviewScreenImage)}
                    classes={'slideImg'}>
                    {el.name}
                  </LoadableImageComponant>
                  <div className='slideInfo'>
                    <Typography className='texts-truncate'>
                      {index + 1 + ' - ' + el.screenTitle}
                    </Typography>
                    <Typography className='sliderDesc'>{el.screenDescription}</Typography>
                  </div>
                </React.Fragment>
              </div>
            ))}
        </Grid>
        <Grid item xs={12}>
          <Button
            className='btns theme-solid w-100 editBtn'
            onClick={() => {
              setIsEdit(true);
              setAddDialog(true);
              setSliderInfo(item);
            }}>
            {getTranslate()(`${translationPath}Edit`)}
          </Button>
        </Grid>
        <Grid item xs={12} className='previewSlider'>
          <Typography>{getTranslate()(`${translationPath}Preview`)}</Typography>
          <div className='previewImg'>
            {Array.isArray(item.screens) &&
              item.screens.map((el, index) => (
                <React.Fragment key={index}>
                  <CanvasComponent
                    width={270}
                    height={400}
                    url={getDownloadableLink(el.screenImage)}
                    title={el.screenTitle}
                    description={el.screenDescription}
                  />
                </React.Fragment>
              ))}
          </div>
        </Grid>
      </Grid>
      <DeleteSliderDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        item={item}
        reloadData={reloadData}
      />
    </form>
  );
};
export { SliderSidePanel };
