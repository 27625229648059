import config from '../../Assets/Configration/Configration';
import { HttpServices } from '../HttpServices/HttpServices';

export const GetAllCarCativities = async (queries, body = null) => {
  const queryList = [];
  (queries.pageSize || queries.pageSize === 0) && queryList.push(`pageSize=${queries.pageSize}`);
  (queries.pageIndex || queries.pageIndex === 0) &&
    queryList.push(`pageIndex=${queries.pageIndex + 1}`);
  queries.dateRange &&
    queries.dateRange.startDate &&
    queryList.push(`StartDate=${queries.dateRange.startDate}`);
  queries.dateRange &&
    queries.dateRange.endDate &&
    queryList.push(`EndDate=${queries.dateRange.endDate}`);
  queries.ActivityId && queryList.push(`ActivityId=${queries.ActivityId}`);
  queries.ActivityTypeId && queryList.push(`ActivityTypeId=${queries.ActivityTypeId}`);
  queries.carsId && queryList.push(`CarsId=${queries.carsId}`);
  try {
    const result = await HttpServices.post(
      `${config.serverAddress}/PSTViewing/CarActivity/GetAllActivities?${queryList.join('&')}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
export const CreateCarActivity = async (body) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/PSTViewing/CarActivity`, body);
    return result;
  } catch (e) {
    return;
  }
};
export const UpdateCarActivity = async (carActivityId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/PSTViewing/CarActivity/${carActivityId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};
export const DeleteCarActivity = async (tripId) => {
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/PSTViewing/CarActivity/${tripId}`
    );
    return result;
  } catch (e) {
    return;
  }
};
