/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getTranslate, useEventListener } from '../../../../../../Helpers';
import { getLanguage } from 'react-switch-lang';
import { Button } from '@material-ui/core';
import { ActionsEnum, CarStatusEnum } from '../../../../../../Enums';
import './CarActivitiesCardComponent.scss';
import moment from 'moment';
export const CarActivitiesCardComponent = ({
  data,
  onLoadMore,
  translationPath,
  isLoading,
  onActionClicked,
  onCardClicked,
}) => {
  const onClickHandler = useCallback(
    (item, index) => (event) => {
      event.preventDefault();
      if (onCardClicked) onCardClicked(item, index);
    },
    [onCardClicked]
  );
  const onScrollHandler = useCallback(() => {
    if (
      data &&
      data.result &&
      document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.scrollHeight - 5 &&
      data.result.length < data.totalCount &&
      isLoading !== true
    )
      onLoadMore();
  }, [data, isLoading, onLoadMore]);
  useEventListener('scroll', onScrollHandler);
  useEffect(() => {
    if (
      data &&
      data.result &&
      data.result.length < data.totalCount &&
      document.body.scrollHeight <= document.body.clientHeight &&
      isLoading !== true
    )
      onScrollHandler();
  }, [data, isLoading, onScrollHandler]);
  return (
    <div className="car-activities-card-component-wrapper">
      {data.result.map((item, index) => (
        <a
          className="car-activities-card-wrapper"
          onClick={onClickHandler(item, index)}
          key={`carActivitiesCardRef${index + 1}`}
        >
          <div className="cards-wrapper">
            <div className="cards-header">
              <div className="item-wrapper">
                <div
                  className={`status-wrapper ${
                    (CarStatusEnum.busy.key === item.carActivityStatus &&
                      CarStatusEnum.busy.bgColor) ||
                    CarStatusEnum.ready.bgColor
                  }`}
                >
                  <span className="item-header">{item.activityName}</span>
                  <span className="item-header px-2">
                    {getTranslate()(
                      `${translationPath}${
                        (CarStatusEnum.busy.key === item.carActivityStatus &&
                          CarStatusEnum.busy.value) ||
                        CarStatusEnum.ready.value
                      }`
                    )}
                  </span>
                </div>
              </div>
              <div className="item-wrapper py-2 d-flex-center">
                <span className="item-header c-secondary">
                  <span className="px-1">{getTranslate()(`${translationPath}car`)}:</span>
                  {((item.carTypeName || item.carNumber) && (
                    <span>{`${item.carTypeName}-${item.carNumber}`}</span>
                  )) || <span>N/A</span>}
                </span>
              </div>
            </div>
            <div className="cards-body px-2">
              <div className="item-wrapper">
                <span className="item-header">
                  <span className="mdi mdi-calendar px-2" />
                  <span>{getTranslate()(`${translationPath}date`)}:</span>
                </span>
                <span className="item-body">
                  {(item.activityDateTime &&
                    moment(item.activityDateTime).locale(getLanguage()).format('DD, MMMM YYYY')) ||
                    'N/A'}
                </span>
              </div>
              <div className="item-wrapper">
                <span className="item-header">
                  <span className="mdi mdi-calendar px-2" />
                  <span>{getTranslate()(`${translationPath}value`)}:</span>
                </span>
                <span className="item-body">{item.activityValue || 'N/A'}</span>
              </div>
              <div className="item-wrapper">
                <span className="item-header">
                  <span className="mdi mdi-card-account-details-outline px-2" />
                  <span>{getTranslate()(`${translationPath}name`)}:</span>
                </span>
                <span className="item-body">{item.fullName || 'N/A'}</span>
              </div>
              <div className="item-wrapper">
                <span className="item-header">
                  <span className="mdi mdi-newspaper-variant-outline px-2" />
                  <span>{getTranslate()(`${translationPath}evidence`)}:</span>
                </span>
                <span className="item-body">
                  {(item.activityAttachments && item.activityAttachments.length) || 'N/A'}
                </span>
              </div>
              <div className="item-wrapper">
                <span className="item-header">
                  <span className="mdi mdi-note-text-outline px-2" />
                  <span>{getTranslate()(`${translationPath}notes`)}:</span>
                </span>
                <span className="item-body">{item.note || 'N/A'}</span>
              </div>
              <div className="item-wrapper actions">
                <Button
                  className="btns theme-outline"
                  onClick={onActionClicked(ActionsEnum.edit.key, item, index)}
                >
                  <span className={ActionsEnum.edit.icon} />
                </Button>
                <Button
                  className="btns theme-outline"
                  onClick={onActionClicked(ActionsEnum.delete.key, item, index)}
                >
                  <span className={ActionsEnum.delete.icon} />
                </Button>
              </div>
            </div>
          </div>
          {/* <div className="attached-wrapper">
            <div className="attached-boxes-wrapper">
              <div className="attached-box-item">
                <span className="attached-box-text">
                  <span className="fw-bold px-1">
                    {getTranslate()(`${translationPath}start-tour`)}:
                  </span>
                  <span>{moment().locale(getLanguage()).format('hh:mm a')}</span>
                </span>
              </div>
              <div className="attached-box-item">
                <span className="attached-box-text">
                  <span className="fw-bold px-1">
                    {getTranslate()(`${translationPath}end-tour`)}:
                  </span>
                  <span>{moment().locale(getLanguage()).format('hh:mm a')}</span>
                </span>
              </div>
              <div className="attached-box-item">
                <span className="attached-box-text">
                  <span className="fw-bold px-1">
                    {getTranslate()(`${translationPath}start-tour`)}:
                  </span>
                  <span>{moment().locale(getLanguage()).format('hh:mm a')}</span>
                </span>
              </div>
              <div className="attached-box-item">
                <span className="attached-box-text">
                  <span className="fw-bold px-1">
                    {getTranslate()(`${translationPath}end-tour`)}:
                  </span>
                  <span>{moment().locale(getLanguage()).format('hh:mm a')}</span>
                </span>
              </div>
            </div>
          </div> */}
        </a>
      ))}
    </div>
  );
};

CarActivitiesCardComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  onActionClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  translationPath: PropTypes.string,
};
CarActivitiesCardComponent.defaultProps = {
  data: { result: [], totalCount: 0 },
  isLoading: false,
  onPageChanged: undefined,
  translationPath: '',
};
