export const BasketReportEnum = [
  {
    key: 'FirstName',
    value: 'FirstName',
  },
  {
    key: 'SecondName',
    value: 'SecondName',
  },
  {
    key: 'LastName',
    value: 'LastName',
  },
  {
    key: 'UserName',
    value: 'UserName',
  },
];
