export const ProgressEnum = {
  '100-90': {
    value: '100-90',
    className: 'bg-primary',
    circularColor: 'c-primary',
    textColor: 'c-primary',
  },
  '90-70': {
    value: '90-70',
    className: 'bg-success',
    circularColor: 'c-success',
    textColor: 'c-success',
  },
  '70-40': {
    value: '70-40',
    className: 'bg-warning',
    circularColor: 'c-warning',
    textColor: 'c-warning',
  },
  '40-0': {
    value: '40-0',
    className: 'bg-danger',
    circularColor: 'c-danger',
    textColor: 'c-danger',
  },
};
