import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { PageContentDeleteDialog } from '../../PageContetnUtilities/PageContentDialogs/PageContentDeleteDialog';
import '../../PageContentView.scss';
import { getTranslate } from '../../../../../Helpers';

export const PageContentSlider = ({
  item,
  reloadData,
  setOpenAddEdit,
  setIsEdit,
  setPageContentInfo,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const translationPath = 'PageContents.';

  return (
    <form
      className='advance-search-wrapper sliderSidePanel'
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}>
      <Grid container>
        <Grid item xs={12} className='sliderName'>
          <Typography>{item.pageName}</Typography>
          <Button onClick={() => setOpenDelete(true)}>
            {getTranslate()(`${translationPath}Delete`)}
          </Button>
        </Grid>
        <Grid item xs={12} className='pageContent'>
          <span className='mdi mdi-format-list-text' />
          <Typography className='contentTag'>
            {getTranslate()(`${translationPath}Title`)}
          </Typography>
          <Typography>{item.contentTitle}</Typography>
        </Grid>
        <Grid item xs={12} className='pageContent'>
          <span className='mdi mdi-text' />
          <div className='bodySection'>
            <Typography className='contentTag'>
              {getTranslate()(`${translationPath}Body`)}
            </Typography>
            <Typography className='textFading body-content-height'>
              <span dangerouslySetInnerHTML={{ __html: item.contentBody }} />
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button
            className='btns theme-solid w-100 editBtn'
            onClick={() => {
              setOpenAddEdit(true);
              setIsEdit(true);
              setPageContentInfo(item);
            }}>
            {getTranslate()(`${translationPath}Edit`)}
          </Button>
        </Grid>
      </Grid>
      <PageContentDeleteDialog
        open={openDelete}
        setOpen={setOpenDelete}
        reloadData={reloadData}
        item={item}
      />
    </form>
  );
};
