import React, { useState, useEffect, useCallback } from 'react';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from ' ./../../src/Helpers';
import { Spinner, GoogleMapsComponent, Tables } from '../../../Componentes';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { GetAllOnGoingReservationTour, GetReservationTourById } from './../../../Serviecs';
import { getTranslate } from '../../../Helpers';
import { GoogleMapPremadeComponentsEnum, ReservedTourStatus } from '../../../Enums';
import { IconButton, TextField, Button, FormControl, ButtonBase, Tooltip } from '@material-ui/core';
import { useTitle } from '../../../Hooks/useTitle';
import { TrackingHub } from '../../../Hubs';
import { NoDataFoundComponent, NoSearchResultComponent } from '../../../Componentes';
import { ShowAllDriversDialog } from './TrackingViewUtilities/ShowAllDriversDialog';
import './TrackingView.scss';
const translationPath = 'trackingView.';

let currentHubContext = null;
let externalDriverLocation = null;
let externalReRender = false;
/* eslint-disable no-unused-vars */
let externalSetRerender = false;
/* eslint-enable no-unused-vars */

const reRenderFunction = () => {
  externalSetRerender = !externalReRender;
};

const TrackingView = () => {
  const [loading, setLoading] = useState(false);
  const [searchedItem, setSearchedItem] = useState('');
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [responseResult] = useState(() => ({ result: [], totalCount: 0 }));
  const [page] = useState(0);
  const [rowsPerPage] = useState(10);
  const [response, setResponse] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [hubContext, setHubContext] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [activeUserItem, setActiveUserItem] = useState();
  const [driverInfo, setDriverInfo] = useState();
  const [polyLine, setPolyLine] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [clientLocation, setClientLocation] = useState([]);
  const [agentLocation, setAgentLocation] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [counter, setCounter] = useState(0);
  const [endLocation, setEndLocation] = useState([]);
  let time = setTimeout(() => {}, 300);

  useTitle(getTranslate()(`SideMenuView.tracking`));

  useEffect(() => {
    externalSetRerender = setReRender;
    externalReRender = reRender;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentHubContext === null) {
      currentHubContext = TrackingHub();
      setHubContext(currentHubContext);
    }
    return () => {
      currentHubContext = null;
      setHubContext(null);
    };
  }, []);

  const GetAllOnGoing = useCallback(async () => {
    setLoading(true);
    const allLocations = await GetAllOnGoingReservationTour(searchedItem);
    setResponse(allLocations);

    if (allLocations && allLocations.length === 0) {
      setisFirstLoad(false);
    }

    const driver = [];
    const client = [];
    const agent = [];

    if (allLocations && allLocations[0]) {
      allLocations.map((el) =>
        el.carDrivers.map((item) =>
          driver.push({
            userId: item.driver.userId,
            latitude: item.driver.locations.latitude,
            longitude: item.driver.locations.longitude,
            data: {
              id: el.reservationTourId,
              onPopoverButtonClicked: () => {
                setPolyLine(true);
              },
            },
            premadeComponent: GoogleMapPremadeComponentsEnum.CarMarker.key,
          })
        )
      );
      externalDriverLocation = driver;

      allLocations.map((item) => {
        item.client &&
          client.push({
            userId: item.client.userId,
            latitude: item.client.locations.latitude,
            longitude: item.client.locations.longitude,
            data: {
              id: item.reservationTourId,
              onPopoverButtonClicked: () => {
                setPolyLine(true);
              },
            },
            premadeComponent: GoogleMapPremadeComponentsEnum.CarMarker.key,
          });
        item.agent &&
          agent.push({
            userId: item.agent.userId,
            latitude: item.agent.locations.latitude,
            longitude: item.agent.locations.longitude,
            data: {
              id: item.reservationTourId,
              onPopoverButtonClicked: () => {
                setPolyLine(true);
              },
            },
            premadeComponent: GoogleMapPremadeComponentsEnum.CarMarker.key,
          });
        return null;
      });
      setClientLocation(client);
      setAgentLocation(agent);
    }

    setLoading(false);
  }, [searchedItem]);

  const responseChangeHandler = async () => {
    const allLocations = await GetAllOnGoingReservationTour();
    setResponse(allLocations);
  };

  const driverLocationsChangeHandler = (data) => {
    const newDriverLocation = externalDriverLocation;

    setEndLocation(
      newDriverLocation &&
        newDriverLocation.map((item) =>
          item.userId === data.userId ? [item.latitude, item.longitude] : []
        )
    );
    newDriverLocation &&
      newDriverLocation.map((item) =>
        item.userId === data.userId
          ? ((item.latitude = data.location.latitude), (item.longitude = data.location.longitude))
          : null
      );
    externalDriverLocation = newDriverLocation;

    if (clientLocation && clientLocation[0]) {
      const newClientLocations = data.userId === clientLocation[0].userId ? data.location : [];
      setClientLocation(newClientLocations);
    }

    if (agentLocation && agentLocation[0]) {
      const newAgentLocations = data.userId === agentLocation[0].userId ? data.location : [];
      setAgentLocation(newAgentLocations);
    }
    reRenderFunction();
  };

  useEffect(() => {
    if (hubContext !== null) {
      hubContext.start().then(() => {
          hubContext.invoke('GetConnectionId').then(data=>console.log(data)).catch(e=>console.log(e));
          hubContext.on('StoreLocationListener', (data) => driverLocationsChangeHandler(data));
          hubContext.on('ReservationTourListener', (data) => responseChangeHandler(data));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubContext]);

  useEffect(() => {
    GetAllOnGoing();
  }, [GetAllOnGoing, searchedItem]);

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const focusedRowChanged = async (activeRow) => {
    setLoading(true);
    setRoutes([]);
    setPolyLine(false);
    setCounter(0);

    const polyLine = [];
    const result = await GetReservationTourById(response[activeRow].reservationTourId);
    setDriverInfo(result);

    if (result && result.tourDetailsDto && result.tourDetailsDto.tourSummaryTrips) {
      result.tourDetailsDto.tourSummaryTrips.map(() => {
        if (counter + 1 < result.tourDetailsDto.tourSummaryTrips.length) {
          polyLine.push({
            origin: {
              lat: result.tourDetailsDto.tourSummaryTrips[counter].tripLatitude,
              lng: result.tourDetailsDto.tourSummaryTrips[counter].tripLongitude,
            },
            destination: {
              lat: result.tourDetailsDto.tourSummaryTrips[counter + 1].tripLatitude,
              lng: result.tourDetailsDto.tourSummaryTrips[counter + 1].tripLongitude,
            },
          });
          setCounter(counter + 1);
        } else {
          polyLine.push({
            origin: {
              lat: result.tourDetailsDto.tourSummaryTrips[counter].tripLatitude,
              lng: result.tourDetailsDto.tourSummaryTrips[counter].tripLongitude,
            },
            destination: {
              lat: result.tourDetailsDto.tourSummaryTrips[counter].tripLatitude,
              lng: result.tourDetailsDto.tourSummaryTrips[counter].tripLongitude,
            },
          });
          setCounter(counter + 1);
        }
        return null;
      });
    }
    setCounter(0);
    setRoutes(polyLine);
    setPolyLine(true);
    setLoading(false);
  };

  const handleShowDrivers = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAll(true);
    setActiveUserItem(data);
  };

  return (
    <div className='view-wrapper tracking-view-wrapper'>
      <Spinner isActive={loading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section select-all-cities'></div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <FormControl className='input-wrapper'>
                <Button className='btns-icon theme-transparent mx-2'>
                  <span className='mdi mdi-magnify' />
                </Button>
                <TextField
                  className='inputs theme-solid'
                  // value={searchedItem}
                  onKeyUp={(e) => {
                    const value = e.target.value;
                    time = setTimeout(() => {
                      setSearchedItem(value);
                    }, 500);
                  }}
                  onKeyDown={() => {
                    clearTimeout(time);
                  }}
                  label={getTranslate()(`${translationPath}Search`)}
                  placeholder={getTranslate()(`${translationPath}search-by-basket-order-number`)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {searchedItem && (
                          <IconButton
                            onClick={() => {
                              setSearchedItem('');
                            }}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className='section px-2'>
        <NoDataFoundComponent />
        {responseResult &&
        responseResult.totalCount === 0 &&
        searchedItem.length !== 0 &&
        !isFirstLoad ? (
          <NoSearchResultComponent />
        ) : (
          <>
            <div className='tracking-google-map'>
              <GoogleMapsComponent
                setPolyLine={setPolyLine}
                setRoutes={setRoutes}
                driverInfo={driverInfo}
                setDriverInfo={setDriverInfo}
                translationPath={translationPath}
                locations={externalDriverLocation && externalDriverLocation}
                agentLocation={agentLocation}
                clientLocation={clientLocation}
                endLocation={endLocation}
                routes={
                  polyLine && [
                    {
                      origin: {
                        lat: driverInfo && driverInfo.pickUpLocation.latitude,
                        lng: driverInfo && driverInfo.pickUpLocation.longitude,
                      },
                      destination: {
                        lat: routes && routes[0].destination.lat,
                        lng: routes && routes[0].destination.lng,
                      },
                    },
                    ...routes,
                    {
                      origin: {
                        lat: routes && routes[routes.length - 1].destination.lat,
                        lng: routes && routes[routes.length - 1].destination.lng,
                      },
                      destination: {
                        lat: driverInfo && driverInfo.dropOffLocation.latitude,
                        lng: driverInfo && driverInfo.dropOffLocation.longitude,
                      },
                    },
                  ]
                }
              />
            </div>
            <div className={`map-table-wrapper${(showTable && ' is-open ') || ''}`}>
              <div className='map-table'>
                <ButtonBase className='table-tag-btn' onClick={() => setShowTable(!showTable)}>
                  <span className={`middle-ico mdi mdi-menu-${(showTable && 'down') || 'up'}`} />
                  <span className='background-ico mdi mdi-circle-half' />
                </ButtonBase>
                {response && (
                  <Tables
                    headerData={[
                      {
                        id: 1,
                        isSortable: true,
                        label: getTranslate()(`${translationPath}reservationTourId`),
                        input: 'reservationTourId',
                        isDate: false,
                      },
                      {
                        id: 2,
                        isSortable: true,
                        label: getTranslate()(`${translationPath}startTourDate`),
                        isDate: true,
                        input: 'startTourDate',
                        dateFormat: 'hh:mm a',
                      },
                      {
                        id: 3,
                        isSortable: true,
                        label: getTranslate()(`${translationPath}endTourDate`),
                        input: 'endTourDate',
                        isDate: true,
                        dateFormat: 'hh:mm a',
                      },
                      {
                        id: 4,
                        isSortable: true,
                        label: getTranslate()(`${translationPath}client`),
                        input:
                          response && response[0] && response[0].client ? 'client.fullName' : 'N/A',
                        isDate: false,
                      },
                      {
                        id: 5,
                        isSortable: true,
                        label: getTranslate()(`${translationPath}agent`),
                        input:
                          response && response[0] && response[0].agent ? 'agent.fullName' : 'N/A',
                        isDate: false,
                      },
                      {
                        id: 6,
                        isSortable: true,
                        label: getTranslate()(`${translationPath}carDrivers`),
                        component: (row) => (
                          <div className='tour-driver'>
                            <div>{row.carDrivers[0].driver.fullName}</div>
                            {row.carDrivers.length > 0 && (
                              <Tooltip title={getTranslate()(`${translationPath}show-all`)}>
                                <IconButton
                                  className='mdi mdi-eye'
                                  onClick={(e) => handleShowDrivers(e, row.carDrivers)}
                                />
                              </Tooltip>
                            )}
                          </div>
                        ),
                      },
                      {
                        id: 7,
                        isSortable: true,
                        label: getTranslate()(`${translationPath}tourStatus`),
                        component: (row) => (
                          <div>
                            {ReservedTourStatus.map((item) =>
                              item.value === row.tourStatus
                                ? getTranslate()(`${translationPath}${item.key}`)
                                : ''
                            )}
                          </div>
                        ),
                      },
                    ]}
                    tableOption={{ dateFormat: 'hh:MM tt' }}
                    data={response && Array.isArray(response) ? response : []}
                    activePage={page}
                    totalItems={response && response.length ? response.length : 0}
                    itemsPerPage={rowsPerPage}
                    actionsOptions={{
                      actions: [],
                    }}
                    focusedRowChanged={focusedRowChanged}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <ShowAllDriversDialog
        activeUserItem={activeUserItem}
        isOpen={showAll}
        isOpenChanged={() => {
          setShowAll(false);
        }}
      />
    </div>
  );
};

export { TrackingView };
