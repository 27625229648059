import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  getHistory,
  getTranslate,
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
} from '../../../Helpers';
import {
  NoDataFoundComponent,
  NoSearchResultComponent,
  NoContentComponent,
} from '../../../Componentes';
import { useTitle } from '../../../Hooks/useTitle';
import { Spinner, Inputs } from '../../../Componentes';
import { PromoCodeSidePanel, PromoCodeDeleteDialog } from './PromoCodeViewUtilities';
import {
  GetActivePromoCodes,
  GetAllPromoCodes,
  ActivatePromoCode,
  DeactivatePromoCode,
} from '../../../Serviecs';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, Button, Typography, Card, Divider } from '@material-ui/core';
import './PromoCodeView.scss';

const PromoCodeView = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [responseResult, setResponseResult] = useState(() => ({ result: [], totalCount: 0 }));
  const [, setAllResponse] = useState({});
  const [allResponseResult, setAllResponseResult] = useState(() => ({ result: [], totalCount: 0 }));
  const [searchedItem, setSearchedItem] = useState('');
  const [isFirstLoad, setisFirstLoad] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(12);
  const [allRowsPerPage] = useState(12);
  const [allPage, setAllPage] = useState(0);
  const [activeUserItem, setActiveUserItem] = useState(null);
  const [reloaded, setReloaded] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isActiveExpanded, setIsActiveExpanded] = useState(true);
  const [isPromoExpanded, setIsPromoExpanded] = useState(true);
  const cardContainer = useRef(null);
  const allCardContainer = useRef(null);
  const time = useRef(null);
  const translationPath = 'PromoCode.';

  useTitle(getTranslate()(`PromoCode.PromoCode`));

  const GePromoActiveCodeResponse = useCallback(async () => {
    setLoading(true);
    const res = await GetActivePromoCodes(page + 1, rowsPerPage);
    setResponse(res);
    if (res && res.totalCount === 0) {
      setisFirstLoad(false);
    }

    if (reloaded) setResponseResult({ result: [], totalCount: 0 });
    else {
      if (page === 0) setResponseResult(res);
      else {
        setResponseResult((item) => ({
          result: item.result.concat(res.result),
          totalCount: res.totalCount,
        }));
      }
    }
    setLoading(false);
  }, [page, reloaded, rowsPerPage]);

  useEffect(() => {
    GePromoActiveCodeResponse();
  }, [GePromoActiveCodeResponse]);

  const searchHandler = (e) => {
    const value = e.target.value;
    if (time.current) clearTimeout(time.current);
    time.current = setTimeout(() => {
      setAllPage(0);
      setSearchedItem(value);
      setActiveUserItem(null);
    }, 700);
  };

  useEffect(() => {
    return () => {
      if (time.current) clearTimeout(time.current);
    };
  }, []);

  const loadMoreData = useCallback(() => {
    if (
      responseResult &&
      responseResult.result &&
      responseResult.totalCount > responseResult.result.length &&
      (page + 1) * rowsPerPage <= responseResult.result.length
    )
      setPage(page + 1);
  }, [page, responseResult, rowsPerPage]);

  const scrollHandler = (e) => {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) loadMoreData();
  };

  useEffect(() => {
    if (
      cardContainer &&
      cardContainer.current &&
      cardContainer.current.scrollHeight <= cardContainer.current.clientHeight &&
      !loading
    ) {
      loadMoreData();
    }
    return () => {};
  }, [loadMoreData, loading, responseResult.result]);

  const HandleSidePanel = useCallback((item) => {
    sideMenuComponentUpdate(<PromoCodeSidePanel item={item} />);
    sideMenuIsOpenUpdate(true);
  }, []);

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const GetAllPromo = useCallback(async () => {
    setLoading(true);
    const res = await GetAllPromoCodes(allPage + 1, allRowsPerPage, searchedItem);
    setAllResponse(res);
    if (res && res.totalCount === 0) {
      setisFirstLoad(false);
    }

    if (reloaded) setAllResponseResult({ result: [], totalCount: 0 });
    else {
      if (allPage === 0) setAllResponseResult(res);
      else {
        setAllResponseResult((item) => ({
          result: item.result.concat(res.result),
          totalCount: res.totalCount,
        }));
      }
    }
    setLoading(false);
  }, [allPage, allRowsPerPage, reloaded, searchedItem]);

  useEffect(() => {
    GetAllPromo();
  }, [GetAllPromo, searchedItem]);

  const loadAllMoreData = useCallback(() => {
    if (
      allResponseResult &&
      allResponseResult.result &&
      allResponseResult.totalCount > allResponseResult.result.length &&
      (allPage + 1) * allRowsPerPage <= allResponseResult.result.length
    )
      setAllPage(allPage + 1);
  }, [allPage, allResponseResult, allRowsPerPage]);

  const scrollAllHandler = (e) => {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) loadAllMoreData();
  };

  useEffect(() => {
    if (
      allCardContainer &&
      allCardContainer.current &&
      allCardContainer.current.scrollHeight <= allCardContainer.current.clientHeight &&
      !loading
    ) {
      loadAllMoreData();
    }
    return () => {};
  }, [loadAllMoreData, loading]);

  const checkStatus = (item) => {
    if (item.type === 2) {
      if (item.status === 1) {
        return (
          getTranslate()(`${translationPath}public`) + getTranslate()(`${translationPath}active`)
        );
      } else {
        return (
          getTranslate()(`${translationPath}public`) + getTranslate()(`${translationPath}expired`)
        );
      }
    } else {
      if (item.status === 1) {
        return (
          getTranslate()(`${translationPath}private`) + getTranslate()(`${translationPath}active`)
        );
      } else {
        return (
          getTranslate()(`${translationPath}private`) + getTranslate()(`${translationPath}expired`)
        );
      }
    }
  };
  const checkColor = (item) => {
    if (item.type === 2) {
      if (item.status === 1) {
        return 'public-active-color';
      } else {
        return 'public-expired-color';
      }
    } else {
      if (item.status === 1) {
        return 'private-active-color';
      } else {
        return 'private-expired-color';
      }
    }
  };

  const handelPromoActivate = async (item) => {
    await ActivatePromoCode(item.promoCodeId);
    setLoading(true);
    const res = await GetAllPromoCodes(1, allRowsPerPage);
    setAllResponseResult(res);
    setLoading(false);
  };

  const handelPromoDeactivate = async (item) => {
    await DeactivatePromoCode(item.promoCodeId);
    setLoading(true);
    const res = await GetAllPromoCodes(allPage + 1, allRowsPerPage);
    setAllResponseResult(res);
    setLoading(false);
  };

  return (
    <div className='view-wrapper'>
      <Spinner isActive={loading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section add-button'>
            <Button
              className='btns theme-solid'
              onClick={() => getHistory().push(`/home/PromoCode/AddNewPromoCode`)}>
              {getTranslate()(`${translationPath}add-new-promo-code`)}
            </Button>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative search-section'>
              <Inputs
                idRef='tripsSearchRef'
                variant='outlined'
                fieldClasses='inputs theme-solid'
                translationPath={translationPath}
                label='search'
                beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                onKeyUp={searchHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='section px-2'>
        <NoDataFoundComponent />

        <Grid container spacing={3}>
          {response &&
          response.totalCount === 0 &&
          allResponseResult &&
          allResponseResult.totalCount === 0 &&
          searchedItem.length !== 0 &&
          !isFirstLoad ? (
            <NoSearchResultComponent />
          ) : (
            <>
              {allResponseResult &&
              allResponseResult.result &&
              allResponseResult.result.length === 0 &&
              searchedItem.length === 0 &&
              !isFirstLoad ? (
                <NoContentComponent />
              ) : (
                <Grid container spacing={2} className='promo-code-wrapper'>
                  <Grid item xs={12} className='promo-wrapper'>
                    <Accordion expanded={isActiveExpanded}>
                      <AccordionSummary
                        onClick={() => {
                          setIsActiveExpanded(!isActiveExpanded);
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'>
                        {getTranslate()(`${translationPath}active-promos`)}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          spacing={3}
                          className='active-promo'
                          ref={cardContainer}
                          onScroll={scrollHandler}>
                          {responseResult &&
                            responseResult.result &&
                            responseResult.result.map((item, i) => (
                              <Grid item lg={4} sm={12} xl={4} xs={12} key={i}>
                                <Card
                                  key={i}
                                  onClick={() => {
                                    setActiveUserItem(item);
                                    HandleSidePanel(item);
                                  }}>
                                  <Grid container className='promo-detailes'>
                                    <Grid item xs={11} className='promo-name'>
                                      <Typography>{item.promoCodeName}</Typography>
                                      {getTranslate()(`${translationPath}used`)}
                                      {item.numberOfUsersUsed}
                                      {getTranslate()(`${translationPath}times`)}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      className={
                                        item.type === 2
                                          ? `promo-status public-promo`
                                          : `promo-status private-promo`
                                      }>
                                      {item.type === 2
                                        ? getTranslate()(`${translationPath}public`)
                                        : getTranslate()(`${translationPath}private`)}
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Grid>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className='promo-wrapper'>
                    <Accordion expanded={isPromoExpanded}>
                      <AccordionSummary
                        onClick={() => {
                          setIsPromoExpanded(!isPromoExpanded);
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'>
                        {getTranslate()(`${translationPath}all-promos`)}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          spacing={3}
                          className='all-promo'
                          ref={allCardContainer}
                          onScroll={scrollAllHandler}>
                          {allResponseResult &&
                            allResponseResult.result &&
                            allResponseResult.result.map((item, i) => (
                              <Grid
                                item
                                lg={4}
                                sm={12}
                                xl={4}
                                xs={12}
                                key={i}
                                className='extended-card'>
                                <Card
                                  key={i}
                                  onClick={() => {
                                    setActiveUserItem(item);
                                    HandleSidePanel(item);
                                  }}>
                                  <Grid container>
                                    <Grid item xs={11}>
                                      <div className='promo-indicator'>
                                        <div className={checkColor(item) + ` promo-code-status`}>
                                          {checkStatus(item)}
                                        </div>
                                      </div>
                                      <Grid item className='promo-name'>
                                        <Typography>{item.promoCodeName}</Typography>
                                      </Grid>
                                      <Grid item className='promo-icon'>
                                        <span className='mdi mdi-clock' />
                                        {getTranslate()(`${translationPath}valid-from`) +
                                          item.startDate.split('T')[1] +
                                          ' - ' +
                                          item.endDate.split('T')[1]}
                                      </Grid>
                                      <Grid item className='promo-icon'>
                                        <span className='mdi mdi-calendar-blank-outline' />
                                        {getTranslate()(`${translationPath}valid-from`) +
                                          item.startDate.split('T')[0] +
                                          getTranslate()(`${translationPath}valid-to`) +
                                          item.endDate.split('T')[0]}
                                      </Grid>
                                      <Grid item className='promo-icon'>
                                        <span className='mdi mdi-account-multiple-outline' />
                                        {getTranslate()(`${translationPath}used`) +
                                          item.promoCodeUsersUsed +
                                          getTranslate()(`${translationPath}times`)}
                                      </Grid>
                                      <Grid item className='promo-icon'>
                                        <span className='mdi mdi-brightness-percent' />
                                        {getTranslate()(`${translationPath}percentage`) +
                                          item.discountPercentage +
                                          '%'}
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={1} className='card-actions'>
                                      <Button
                                        className='mdi mdi-delete-outline'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setActiveUserItem(item);
                                          setIsOpenConfirm(true);
                                        }}
                                      />
                                      <Button
                                        className='mdi mdi-file-document-edit-outline'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          getHistory().push(
                                            `/home/PromoCode/UpdatePromoCode?id=${item.promoCodeId}`
                                          );
                                        }}
                                      />
                                      {item.status === 1 ? (
                                        <Button
                                          className='mdi mdi-close'
                                          onClick={async (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handelPromoDeactivate(item);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          className='mdi mdi-check'
                                          onClick={async (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handelPromoActivate(item);
                                          }}
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Grid>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
      <PromoCodeDeleteDialog
        activeUserItem={activeUserItem}
        isOpen={isOpenConfirm}
        setReloaded={setReloaded}
        isOpenChanged={() => {
          setIsOpenConfirm(false);
        }}
        reloadData={() => {
          setActiveUserItem(null);
          GetAllPromo(allPage + 1, allRowsPerPage);
          GePromoActiveCodeResponse(page + 1, rowsPerPage);
          setIsOpenConfirm(false);
        }}
      />
    </div>
  );
};

export { PromoCodeView };
