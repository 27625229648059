import React, { useEffect } from 'react';
import { Button, TextField, FormControl, IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { Spinner, Tables } from '../../../../../../Componentes';
import { getHistory, getTranslate } from '../../../../../../Helpers';
import { TableActions } from '../../../../../../Enums';
import DeleteRoleDialog from '../RolesDialogs/DeleteRoleDialog';
import { GetAllRoles } from '../../../../../../Serviecs/Roleservices/roleServices';
import { useTitle } from '../../../../../../Hooks/useTitle';
import {
  NoDataFoundComponent,
  NoSearchResultComponent,
  NoContentComponent,
} from '../../../../../../Componentes';

const RolesListView = () => {
  const [rolesResponse, setRolesResponse] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [searchedItem, setSearchedItem] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deletedName, setDeletedName] = React.useState('');
  const [deletedId, setDeletedId] = React.useState(0);
  const [isFirstLoad, setisFirstLoad] = React.useState(true);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  let time = setTimeout(() => {}, 300);
  useTitle(getTranslate()(`SideMenuView.Administration.Roles`));

  const GetMyRoles = async (pageIndex, PageSize, searchedItem) => {
    setLoading(true);
    const res = await GetAllRoles(pageIndex, PageSize, searchedItem);
    setRolesResponse(res);
    if (res && res.totalCount === 0) {
      setisFirstLoad(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    GetMyRoles(page + 1, rowsPerPage, '');
  }, [page, rowsPerPage, setRolesResponse]);

  const handleGroupsSearch = (e) => {
    setSearchedItem(e.target.value);
    if (e.target.value === '') setSearchedItem('');
  };
  const handleClearSearch = () => {
    setSearchedItem('');
    GetMyRoles(page + 1, rowsPerPage, '');
  };

  const handlePageChange = async (e, newPage) => {
    setPage(newPage);
    GetMyRoles(newPage + 1, rowsPerPage, searchedItem);
  };
  const handlePageRowChange = async (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
    GetMyRoles(1, parseInt(e.target.value, 10), searchedItem);
  };
  return (
    <div className='view-wrapper'>
      <Spinner isActive={loading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <Button
              onClick={() => getHistory().push('/home/Roles/AddRole')}
              className='btns theme-solid bg-secondary'>
              {getTranslate()('Administration.Roles.RolesListView.AddNewRole')}
            </Button>
          </div>

          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <FormControl className='input-wrapper'>
                <Button className='btns-icon theme-transparent mx-2'>
                  <span className='mdi mdi-magnify' />
                </Button>
                <TextField
                  className='inputs theme-solid'
                  value={searchedItem}
                  onKeyUp={() => {
                    time = setTimeout(() => {
                      GetMyRoles(page + 1, rowsPerPage, searchedItem);
                    }, 500);
                  }}
                  onKeyDown={() => {
                    clearTimeout(time);
                  }}
                  onChange={handleGroupsSearch}
                  label={getTranslate()('Administration.Roles.RolesListView.SearchRoles')}
                  placeholder={getTranslate()(
                    'Administration.Roles.RolesListView.search-by-role-name'
                  )}
                  variant='outlined'
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {searchedItem && (
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <NoDataFoundComponent />
      {rolesResponse &&
        rolesResponse.result &&
        rolesResponse.result.length === 0 &&
        searchedItem.length === 0 &&
        !isFirstLoad && <NoContentComponent />}
      <div className='mx-3'>
        {rolesResponse &&
        rolesResponse.totalCount === 0 &&
        searchedItem.length !== 0 &&
        !isFirstLoad ? (
          <NoSearchResultComponent />
        ) : (
          <>
            {rolesResponse && rolesResponse.totalCount !== 0 && (
              <Tables
                headerData={[
                  {
                    id: 1,
                    isSortable: true,
                    label: 'Administration.Roles.RolesListView.RolesId',
                    input: 'rolesId',
                    isDate: false,
                  },
                  {
                    id: 2,
                    isSortable: true,
                    label: 'Administration.Roles.RolesListView.RolesName',
                    input: 'rolesName',
                    isDate: false,
                  },
                ]}
                data={
                  rolesResponse && rolesResponse.result && Array.isArray(rolesResponse.result)
                    ? rolesResponse.result
                    : []
                }
                activePage={page}
                totalItems={
                  rolesResponse && rolesResponse.totalCount ? rolesResponse.totalCount : 0
                }
                activePageChanged={handlePageChange}
                itemsPerPage={rowsPerPage}
                itemsPerPageChanged={handlePageRowChange}
                actionsOptions={{
                  actions: [
                    {
                      enum: TableActions.view.key,
                      isDiabled: false,
                      externalComponent: null,
                    },
                    {
                      enum: TableActions.edit.key,
                      isDiabled: false,
                      externalComponent: null,
                    },
                    {
                      enum: TableActions.delete.key,
                      isDiabled: false,
                      externalComponent: null,
                    },
                  ],
                  classes: '',
                  isDisabled: false,
                  onActionClicked: (key, item) => {
                    if (key === 'view') getHistory().push(`./Details?id=${item.rolesId}`);
                    else if (key === 'edit')
                      getHistory().push(`./EditRole?id=${item.rolesId}?name=${item.rolesName}`);
                    else if (key === 'delete') {
                      setDeletedId(item.rolesId);
                      setDeletedName(item.rolesName);
                      setDeleteDialog(true);
                    }
                  },
                }}
              />
            )}
          </>
        )}
      </div>
      <DeleteRoleDialog
        open={deleteDialog}
        close={() => setDeleteDialog(false)}
        deletedId={deletedId}
        name={deletedName}
        reloadData={() => GetMyRoles(page + 1, rowsPerPage, searchedItem)}
      />
    </div>
  );
};
export { RolesListView };
