/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getTranslate, useEventListener, getDownloadableLink } from '../../../../../Helpers';
import './AgentsCardComponent.scss';
import { LoadableImageComponant } from '../../../../../Componentes';
import { LoadableImageEnum } from '../../../../../Enums';

export const AgentsCardComponent = ({
  data,
  page,
  rowsPerPage,
  setPage,
  translationPath,
  isLoading,
  onCardClicked,
}) => {
  const loadMoreData = useCallback(() => {
    if (
      data &&
      data.result &&
      data.totalCount > data.result.length &&
      (page + 1) * rowsPerPage <= data.result.length
    )
      setPage(page + 1);
  }, [data, page, rowsPerPage, setPage]);

  const scrollHandler = () => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - 5
    )
      loadMoreData();
  };

  useEventListener('scroll', scrollHandler);

  useEffect(() => {
    if (document.body.scrollHeight < document.body.clientHeight && !isLoading) loadMoreData();
    return () => {};
  }, [loadMoreData, isLoading]);

  const onClickHandler = useCallback(
    (item, index) => (event) => {
      event.preventDefault();
      if (onCardClicked) onCardClicked(item, index);
    },
    [onCardClicked]
  );

  return (
    <div className='agents-card-component-wrapper'>
      {data.result.map((item, index) => (
        <a
          className='agents-card-wrapper'
          onClick={onClickHandler(item, index)}
          key={`agentCardRef${index + 1}`}>
          <div className='cards-wrapper'>
            <div className='cards-header'>
              <div className='item-wrapper p-relative'>
                <LoadableImageComponant
                  type={LoadableImageEnum.div.key}
                  classes='cover-image'
                  alt={getTranslate()(`${translationPath}agent-image`)}
                  src={
                    (item.userProfileImg && getDownloadableLink(item.userProfileImg)) ||
                    item.defaultImage
                  }
                />
              </div>
              <div className='d-flex-column'>
                <div className='item-wrapper px-2'>
                  <span className='item-header'>{item.fullName}</span>
                </div>
                <div className='item-wrapper px-2'>
                  <span className='item-header user-name c-secondary'>( {item.userName} )</span>
                </div>
                <div className='item-wrapper px-2'>
                  <span className='item-header added-tours'>
                    {getTranslate()(`${translationPath}added-tours`)}: {item.numberOfTourInBasket}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};
AgentsCardComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  onActionClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  translationPath: PropTypes.string,
};
AgentsCardComponent.defaultProps = {
  data: { result: [], totalCount: 0 },
  isLoading: false,
  onPageChanged: undefined,
  translationPath: '',
};
