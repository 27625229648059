import { showError } from '../../Helpers';
import { HttpServices } from '../HttpServices/HttpServices';
import config from '../../Assets/Configration/Configration.json';
export const ApiKey = 'AIzaSyAjUFFn6w4tYi6TOrzVtr4lqR9aG2BHMRI';

export const GetPoints = async (from, to) => {
  try {
    const result = await HttpServices.get(
      `/directions/json?origin=${from}&destination=${to}&key=${ApiKey}&sensor=false&alternatives=true`
    );
    return result;
  } catch (e) {
    showError('Error Retriving Routes');
  }
};

export const GetPlaces = async (placeName) => {
  try {
    const result = await HttpServices.get(
      `/place/autocomplete/json?input=${placeName}&key=${ApiKey}&libraries=places&sessiontoken=1234567890`
    );
    return result;
  } catch (e) {
    showError('Error Retriving Places');
  }
};

export const GetLocationByAddress = async (address) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/PSTViewing/Map/GetLocationByAddress/${address}`
    );
    return result;
  } catch (e) {
    return;
  }
};
