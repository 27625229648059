import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { getTranslate } from ' ./../../src/Helpers';
import './editcard.scss';
import { GoogleMapsComponent } from '../../../../../../Componentes';
const SliderParking = ({ openDleteDialogslider, openEditDialogslider, setSearch }) => {
  useEffect(() => {}, [setSearch]);
  const translationPath = 'Parking.';

  return (
    <div>
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        className='advance-search-wrapper slider'>
        <div className='d-flex-v-center-h-between'>
          <p className='textslabelmain labelcard'> {setSearch.parkingName}</p>
          <div type='submit' className='btns theme-solid mb-2 mx-2 hoverdelteslidericon'>
            <Button
              color='primary'
              title={getTranslate()(`${translationPath}Delete`)}
              onClick={openDleteDialogslider}
              style={{ color: 'red' }}>
              <span className='mdi mdi-delete hoverdelteslidericon' />
            </Button>
          </div>
        </div>
        <div className='mdi mdi-car titles '>
          <p className='textslabel'>
            <span className='labelcard'>{getTranslate()(`${translationPath}parkingNumber`)}</span>{' '}
            :&nbsp;
            {setSearch.parkingNumber}
          </p>
        </div>
        <div className='mdi mdi-home titles '>
          <p className='textslabel'>
            <span className='labelcard'>{getTranslate()(`${translationPath}cityId`)}</span> :&nbsp;
            {setSearch.cityName}
          </p>
        </div>
        <div className=' mdi mdi-map-marker titles  '>
          <p className='textslabel'>
            <span className='labelcard'>{getTranslate()(`${translationPath}address`)}</span> :&nbsp;
            {setSearch.address}
          </p>
        </div>
        <div className=' mdi mdi-longitude titles  '>
          <p className='textslabel'>
            <span className='labelcard'>{getTranslate()(`${translationPath}longitude`)}</span>{' '}
            :&nbsp;
            {setSearch.longitude}
          </p>
        </div>

        <div className=' mdi mdi-latitude titles  '>
          <p className='textslabel'>
            <span className='labelcard'>{getTranslate()(`${translationPath}latitude`)}</span>{' '}
            :&nbsp;
            {setSearch.latitude}
          </p>
        </div>
        <div
          className={
            setSearch.status === 'Active'
              ? ' mdi mdi-alarm-light titles '
              : ' mdi mdi-alarm-light-outline titles  '
          }>
          <p className='textslabel'>
            <span className='labelcard'>{getTranslate()(`${translationPath}Status`)}</span> :&nbsp;
            {setSearch.status === 'Active'
              ? getTranslate()(`${translationPath}Active`)
              : getTranslate()(`${translationPath}Pending`)}
          </p>
        </div>
      </form>
      <div className="GoogleMaps">      <GoogleMapsComponent
        locations={[
          {
            latitude: setSearch.latitude,
            longitude: setSearch.longitude,
          },
        ]}
        center={
          (setSearch.latitude && { lat: setSearch.latitude, lng: setSearch.longitude }) || undefined
        }
      /></div>
      <div className='mb-3 px-3   Buttonedits'>
        <Button
          type='submit'
          className='btns theme-solid w-100 editbutton  '
          onClick={openEditDialogslider}>
          <span>{getTranslate()(`${translationPath}Edit`)}</span>
        </Button>
      </div>
    </div>
  );
};
export { SliderParking };
