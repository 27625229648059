import React from 'react';
import { DialogTitle, DialogActions, Dialog,  Typography ,Button } from '@material-ui/core';
import { getTranslate, showSuccess } from '../../../../Helpers';
import { DelteCar } from './../../../../Serviecs/Cars/CarsService';
import { Spinner } from '../../../../Componentes';
import './../CarsView.scss';
const DialogDeleteParking = (props) => {
  const translationPath = 'Cars.DeleteParkingDialog.';
  const [loading, setLoading] = React.useState(false);
  return (
    <Dialog open={props.open} className='deleteDialog'>
      <Spinner isActive={loading} />

      <DialogTitle className='dialogTitle'>
        <Typography>
        <div  className='Divider' > {getTranslate()(`${translationPath}ConfirmMessage`)} </div> 
          <div className='deletecolse-button'>
            <Button
              onClick={() => props.close()}
              className='MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid bg-danger mx-2 mb-2'
              tabindex='0'
              title={getTranslate()(`${translationPath}Cancel`)}
              type='button'>
              <span className='MuiButton-label'>
                <span className='mdi mdi-close'></span>
              </span>
              <span class='MuiTouchRipple-root'></span>
            </Button>
          </div>
        </Typography>


      </DialogTitle>

      <div class='delete-Dialog-seaction '>
        <div class='d-flex-column-center contener'>
          <span class='mdi mdi-close-octagon c-danger mdi-48px'></span>
          <span>
            {getTranslate()(`${translationPath}AreYouSureYouWonDeleteThisCar`)}{' '}
            <p className='nameofdriver'>{props.CarNAME}</p>
          </span>
        </div>
      </div>

      <DialogActions></DialogActions>
      <div class='d-flex-center px-2 pt-2'>
        <Button
          class='MuiButtonBase-root MuiButton-root MuiButton-text btns theme-outline mx-2 mb-2'
          tabindex='0'
          title={getTranslate()(`${translationPath}Cancel`)}
          onClick={() => props.close()}
          type='button'>
          <span class='MuiButton-label'>
            <span>{getTranslate()(`${translationPath}Cancel`)}</span>
          </span>
          <span class='MuiTouchRipple-root'></span>
        </Button>
      </div>
      <div class='d-flex-center w-100 mx-0'>
        <Button
          onClick={async () => {
            props.close();
            setLoading(true);
            await DelteCar(props.delteid);
            props.reloadData();
            setLoading(false);
            showSuccess(getTranslate()(`${translationPath}NotificationshowDeleteSuccess`));
          }}
          class='MuiButtonBase-root MuiButton-root MuiButton-text btns theme-solid bg-danger w-100 mb-0 mx-0 br-0'
          tabindex='0'
          type='button'>
          <span class='MuiButton-label'>
            <span> {getTranslate()(`${translationPath}Confirm`)}</span>
          </span>
          <span class='MuiTouchRipple-root'></span>
        </Button>
 
      </div>
    </Dialog>
  );
};
export default DialogDeleteParking;
