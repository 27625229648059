import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import Button from '@material-ui/core/Button';
import { getTranslate } from '../../Helpers';
import { getLanguage } from 'react-switch-lang';
import ar from 'react-phone-input-2/lang/ar.json';

export const PhonesComponent = ({
  isValid,
  country,
  onInputChanged,
  onInputBlur,
  idRef,
  isRequired,
  isDisabled,
  value,
  wrapperClasses,
  inputClasses,
  labelClasses,
  errorClasses,
  translationPath,
  inputPlaceholder,
  labelValue,
  buttonOptions,
  error,
  helperText,
  isSubmitted,
  isLoading,
  afterIconClasses,
}) => {
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  const getPhoneLocalization = () => {
    if (getLanguage() === 'en') return undefined;
    if (getLanguage() === 'ar') return ar;
  };
  return (
    <div className={wrapperClasses}>
      {labelValue && (
        <label htmlFor={idRef} className={labelClasses}>
          {getTranslate()(`${translationPath}${labelValue}`)}
        </label>
      )}
      <div className='phone-input-wrapper'>
        <PhoneInput
          isValid={isValid}
          country={country}
          inputProps={{
            required: isRequired,
            id: idRef,
          }}
          containerClass={`${
            (helperText && error && (isSubmitted || isBlurOrChanged) && ' phone-error') || ''
          }`}
          disabled={isDisabled}
          value={value}
          inputClass={inputClasses}
          onBlur={(event) => {
            setIsBlurOrChanged(true);
            if (onInputBlur) onInputBlur(event);
          }}
          onChange={(value, country, event) => {
            setIsBlurOrChanged(true);
            if (value && !value.startsWith(country.dialCode)) {
              if (onInputChanged) onInputChanged(country.dialCode + value, country, event);
              return;
            }
            if (onInputChanged) onInputChanged(value, country, event);
          }}
          enableSearch
          searchPlaceholder={getTranslate()('shared.search')}
          localization={getPhoneLocalization()}
          placeholder={
            (inputPlaceholder && getTranslate()(`${translationPath}${inputPlaceholder}`)) ||
            undefined
          }
        />
        {isLoading && <span className='mdi mdi-refresh mdi-spin loader' />}
        {afterIconClasses && <span className={`mx-2 ${afterIconClasses}`} />}
        {buttonOptions && (
          <Button
            className={`ml-2 ${buttonOptions.className}`}
            onClick={buttonOptions.onActionClicked}
            disabled={buttonOptions.isDisabled}
            required={buttonOptions.isRequired}>
            <span className={buttonOptions.iconClasses} />
          </Button>
        )}
      </div>
      {helperText && error && (isSubmitted || isBlurOrChanged) && (
        <div className={errorClasses}>{helperText}</div>
      )}
    </div>
  );
};

PhonesComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.any]),
  onInputChanged: PropTypes.func,
  onInputBlur: PropTypes.func,
  isValid: PropTypes.func,
  idRef: PropTypes.string.isRequired,
  wrapperClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  errorClasses: PropTypes.string,
  translationPath: PropTypes.string,
  labelValue: PropTypes.string,
  country: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  afterIconClasses: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  buttonOptions: PropTypes.shape({
    className: PropTypes.string,
    iconClasses: PropTypes.string,
    onActionClicked: PropTypes.func,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
};
PhonesComponent.defaultProps = {
  value: undefined,
  onInputChanged: undefined,
  onInputBlur: undefined,
  isValid: undefined,
  inputPlaceholder: undefined,
  afterIconClasses: undefined,
  country: 'ae',
  isRequired: false,
  isDisabled: false,
  translationPath: '',
  labelValue: null,
  buttonOptions: null,
  wrapperClasses: 'phones-wrapper',
  inputClasses: 'inputs',
  labelClasses: 'texts-form',
  errorClasses: 'phone-error-wrapper',
  helperText: undefined,
  error: false,
  isLoading: false,
  isSubmitted: false,
};
