import { HttpServices } from '../HttpServices/HttpServices';
import config from '../../Assets/Configration/Configration.json';

const GetLookupItems = async ({ pageIndex, pageSize, lookupTypeName }) => {
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem/${pageIndex}/${pageSize}?lookupTypeName=${lookupTypeName}`
    );
    return result;
  } catch (e) {
    // if (e.response && e.response) throw e.response;
    // else throw e;
    return;
  }
};

const lookupItemsGet = async ({
  pageIndex,
  pageSize,
  lookupTypeName,
  lookupParentId,
  searchedItem,
}) => {
  try {
    const result = await HttpServices.get(
      `${
        config.serverAddress
      }/Lookups/LookupItem/${pageIndex}/${pageSize}?lookupTypeName=${lookupTypeName}${
        lookupParentId ? `&lookupParentId=${lookupParentId}` : ''
      }&search=${searchedItem}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const getLookupItems = async ({ lookupTypeName, lookupParentId }) => {
  const queryList = [];
  lookupTypeName && queryList.push(`lookupTypeName=${lookupTypeName}`);
  lookupParentId && queryList.push(`lookupParentId=${lookupParentId}`);
  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem?${queryList.join('&')}`
    );
    return result;
  } catch (e) {
    return;
  }
};
const lookupItemsGetId = async (payload) => {
  const { lookupTypeId, lookupParentId } = payload;

  try {
    const result = await HttpServices.get(
      `${config.serverAddress}/Lookups/LookupItem/${lookupTypeId}${
        lookupParentId ? `?lookupParentId=${lookupParentId}` : ''
      }`
    );
    return result;
  } catch (e) {
    return;
  }
};

const lookupItemsPost = async (payload) => {
  const { body } = payload;
  try {
    const result = await HttpServices.post(`${config.serverAddress}/Lookups/LookupItem`, body);
    return result;
  } catch (e) {
    return;
  }
};

const lookupItemsPut = async (payload) => {
  const { body, lookupItemId } = payload;
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Lookups/LookupItem/${lookupItemId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

const lookupItemsDelete = async (payload) => {
  const { lookupItemId } = payload;
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/Lookups/LookupItem/${lookupItemId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const lookupTypesGet = async (payload) => {
  const { pageIndex, pageSize, searchedItem } = payload;
  try {
    const result = await HttpServices.get(
      searchedItem
        ? `${config.serverAddress}/Lookups/LookupType/${pageIndex}/${pageSize}?search=${searchedItem}`
        : `${config.serverAddress}/Lookups/LookupType/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (e) {
    return;
  }
};
const lookupTypesPut = async (payload) => {
  const { body, lookupTypeId } = payload;
  try {
    const result = await HttpServices.put(
      `${config.serverAddress}/Lookups/LookupType/${lookupTypeId}`,
      body
    );
    return result;
  } catch (e) {
    return;
  }
};

const lookupTypesDelete = async (payload) => {
  const { lookupTypeId } = payload;
  try {
    const result = await HttpServices.delete(
      `${config.serverAddress}/Lookups/LookupType/${lookupTypeId}`
    );
    return result;
  } catch (e) {
    return;
  }
};

const lookupTypesPost = async (body) => {
  try {
    const result = await HttpServices.post(`${config.serverAddress}/Lookups/LookupType`, body);
    return result;
  } catch (e) {
    return;
  }
};

export {
  lookupTypesGet,
  lookupTypesPost,
  lookupTypesPut,
  lookupTypesDelete,
  lookupItemsGet,
  lookupItemsPost,
  lookupItemsPut,
  lookupItemsDelete,
  lookupItemsGetId,
  getLookupItems,
  GetLookupItems,
};
