export const MainMenu = [
  {
    groupId: 1,
    order: 1,
    description: '',
    icon: 'icons i-home-blue',
    iconActive: 'icons i-home-white',
    isDisabled: false,
    name: 'dashboard.dashboard',
    roles: [],
  },
  {
    groupId: 2,
    order: 2,
    description: '',
    icon: 'icons i-slider',
    iconActive: 'icons i-slider-white',
    isDisabled: false,
    name: 'SideMenuView.MobilePages.MobilePages',
    roles: [],
  },
  {
    groupId: 9,
    order: 3,
    description: '',
    icon: 'mdi mdi-account-supervisor',
    iconActive: 'mdi mdi-account-supervisor',
    isDisabled: false,
    name: 'SideMenuView.DriversAndAgents',
    roles: [],
  },
  {
    groupId: 5,
    order: 3,
    description: '',
    icon: 'mdi mdi-airplane',
    iconActive: 'mdi mdi-airplane c-white',
    isDisabled: false,
    name: 'home.trips.tripsView.trips-and-tours',
    roles: [],
  },
  {
    groupId: 4,
    order: 12,
    description: '',
    icon: 'icons i-people-blue',
    iconActive: 'icons i-people-white',
    isDisabled: false,
    name: 'SideMenuView.Administration.Administration',
    roles: [],
  },
  {
    groupId: 3,
    order: 9,
    description: '',
    icon: 'mdi mdi-car-hatchback ',
    iconActive: 'mdi mdi-car-hatchback',
    isDisabled: false,
    name: 'SideMenuView.Cars.CarsOption',
    roles: [],
  },
  {
    groupId: 12,
    order: 9,
    description: '',
    icon: 'mdi mdi-currency-usd',
    iconActive: 'mdi mdi-currency-usd',
    isDisabled: false,
    name: 'SideMenuView.financial',
    roles: [],
  },
  {
    groupId: 10,
    order: 10,
    description: '',
    icon: 'icons i-box-circle-blue',
    iconActive: 'icons i-box-circle-white',
    isDisabled: false,
    name: 'SideMenuView.forms',
    roles: [],
  },
  {
    groupId: 11,
    order: 11,
    description: '',
    icon: 'mdi mdi-card-account-phone ',
    iconActive: 'mdi mdi-card-account-phone ',
    isDisabled: false,
    name: 'SideMenuView.helpandsupport.helpandsupport',
    roles: [],
  },
];
