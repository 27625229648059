import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Tables } from '../../../../../Componentes';
import { getTranslate } from '../../../../../Helpers';
import { OfferedToursStatusEnum, TableActions } from '../../../../../Enums';
export const OfferedToursListComponent = ({
  data,
  filter,
  translationPath,
  activePageChanged,
  itemsPerPageChanged,
  onActionClicked,
}) => {
  const defaultTableActions = [
    { enum: TableActions.view.key },
    { enum: TableActions.edit.key },
    { enum: TableActions.delete.key },
  ];
  const [tableActions, setTableActions] = useState(defaultTableActions);
  const focusedRowChanged = useCallback(
    (activeRow) => {
      const item = data.result[activeRow];
      if (!item) return;
      setTableActions(() => {
        if (OfferedToursStatusEnum.active.key === item.offerStatus)
          return [
            {
              enum: TableActions.close.key,
            },
            ...defaultTableActions,
          ];
        else if (OfferedToursStatusEnum.inactive.key === item.offerStatus)
          return [
            {
              enum: TableActions.check.key,
            },
            ...defaultTableActions,
          ];
        else return [...defaultTableActions];
      });
    },
    [data.result, defaultTableActions]
  );
  return (
    <div className='offered-tours-list-wrapper px-3 mb-3'>
      <Tables
        headerData={[
          {
            id: 1,
            isSortable: true,
            label: getTranslate()(`${translationPath}offer-name`),
            input: 'name',
            isDate: false,
          },
          {
            id: 2,
            isSortable: true,
            label: getTranslate()(`${translationPath}from-date`),
            input: 'startDateTime',
            isDate: true,
          },
          {
            id: 3,
            isSortable: true,
            label: getTranslate()(`${translationPath}to-date`),
            input: 'endDateTime',
            isDate: true,
          },
          {
            id: 5,
            isSortable: true,
            label: getTranslate()(`${translationPath}status`),
            component: (row) =>
              (OfferedToursStatusEnum.active.key === row.offerStatus && (
                <span className='c-success'>
                  {getTranslate()(`${translationPath}${OfferedToursStatusEnum.active.value}`)}
                </span>
              )) ||
              (OfferedToursStatusEnum.inactive.key === row.offerStatus && (
                <span className='c-warning'>
                  {getTranslate()(`${translationPath}${OfferedToursStatusEnum.inactive.value}`)}
                </span>
              )) || (
                <span className='c-warning'>
                  {getTranslate()(`${translationPath}${OfferedToursStatusEnum.expired.value}`)}
                </span>
              ),
          },
        ]}
        data={(data && data.result) || []}
        activePage={filter.pageIndex}
        totalItems={(data && data.totalCount) || 0}
        activePageChanged={activePageChanged}
        itemsPerPage={filter.pageSize}
        itemsPerPageChanged={itemsPerPageChanged}
        focusedRowChanged={focusedRowChanged}
        actionsOptions={{
          actions: tableActions,
          classes: '',
          isDisabled: false,
          onActionClicked,
        }}
      />
    </div>
  );
};
OfferedToursListComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  activePageChanged: PropTypes.func.isRequired,
  itemsPerPageChanged: PropTypes.func.isRequired,
  onActionClicked: PropTypes.func.isRequired,
  translationPath: PropTypes.string,
};
OfferedToursListComponent.defaultProps = {
  translationPath: '',
};
