import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { lookupTypesPost, lookupTypesPut, lookupTypesGet } from '../../../Serviecs';
import { showSuccess, getTranslate, showError } from '../../../Helpers';
import { LookupsTypesComponent, LookupsTypesCreateDialog } from '..';
import { NoDataFoundComponent, Spinner,NoContentComponent } from '../../../Componentes';


const translationPath = 'Lookups.LookupItems.';
const Lookups = () => {
  const [selectedUpdatedType, setSelectedUpdatedType] = useState(() => ({}));
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [response, setResponse] = React.useState({});
  // const [loading, setLoading] = React.useState(false);
  const [searchedItem, setSearchedItem] = React.useState('');
  const [isFirstLoad, setisFirstLoad] = React.useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isEdit, setIsEdit] = React.useState(false);

  const effectGetLookupsType = useCallback(async () => {
    setIsLoading(true);
    const resp = await lookupTypesGet({
      pageIndex: page + 1,
      pageSize: rowsPerPage,
      searchedItem: searchedItem,
    });
    setResponse(resp);
    if (resp && resp.totalCount === 0) {
      setisFirstLoad(false);
    }
    setIsLoading(false);
  }, [page, rowsPerPage, searchedItem]);
  useEffect(() => {
    effectGetLookupsType();
  }, [effectGetLookupsType, page, rowsPerPage, searchedItem]);

  return (
    <div className='view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='d-flex-column'>
        <div>
          <Grid container>
            <LookupsTypesComponent
              setIsEdit={setIsEdit}
              setResponse={setResponse}
              setSearchedItem={setSearchedItem}
              setRowsPerPage={setRowsPerPage}
              response={response}
              setPage={setPage}
              searchedItem={searchedItem}
              page={page}
              isFirstLoad={isFirstLoad}
              rowsPerPage={rowsPerPage}
              setOpenEditDialog={setOpenCreateDialog}
              setSelectedUpdatedType={setSelectedUpdatedType}
            />
          </Grid>
          <LookupsTypesCreateDialog
            isEdit={isEdit}
            open={openCreateDialog}
            setOpen={setOpenCreateDialog}
            item={selectedUpdatedType}
            onSave={async (e) => {
              const response = await lookupTypesPut({ body: e, lookupTypeId: e.lookupTypeId });
              if (response) {
                effectGetLookupsType();
                showSuccess(getTranslate()(`${translationPath}NotificationLookupadd`));
              } else showError(getTranslate()('Lookups.LookupItems.NotificationLookupaddErorr'));
            }}
            onCreate={async (e) => {
              const response = await lookupTypesPost(e);
              if (response) {
                effectGetLookupsType();
                showSuccess(getTranslate()(`${translationPath}NotificationLookupadd`));
              } else showError(getTranslate()('Lookups.LookupItems.NotificationLookupaddErorr'));
            }}
          />
        </div>
        {response &&
          response.result &&
          response.result.length === 0 &&
          searchedItem.length === 0 && <NoContentComponent />}
        <NoDataFoundComponent />
      </div>
    </div>
  );
};

export { Lookups };
