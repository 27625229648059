import React, { useEffect, useRef } from 'react';
import { Button } from '@material-ui/core';
import { Tables, NoSearchResultComponent, Inputs } from '../../../../../Componentes';
import { getTranslate, getHistory } from '../../../../../Helpers';
import { TableActions } from '../../../../../Enums';
import { LookupsDeleteDialog } from '../../..';
import './LookupsTypesComponent.scss';
import { lookupTypesDelete } from '../../../../../Serviecs';
import { useTitle } from '../../../../../Hooks/useTitle';

export const LookupsTypesComponent = (props) => {
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [selectedLookup, setSelectedLookup] = React.useState();
  const [currentActions, setCurrentActions] = React.useState(() => []);

  let time = useRef(null);

  useTitle(getTranslate()(`SideMenuView.Lookups.Lookup`));

  const handlePageChange = async (e, newPage) => {
    props.setPage(newPage);
  };
  const handlePageRowChange = async (e) => {
    props.setRowsPerPage(parseInt(e.target.value, 10));
    props.setPage(0);
  };
  const searchHandler = (e) => {
    const value = e.target.value;
    if (time.current) clearTimeout(time.current);
    time.current = setTimeout(() => {
      props.setSearchedItem(value);
    }, 700);
  };
  useEffect(() => {
    return () => {
      if (time.current) clearTimeout(time.current);
    };
  }, [time]);
  const focusedRowChanged = (activeRow) => {
    const item = props.response.result[activeRow];
    if (!item) return;
    setCurrentActions([
      {
        enum: TableActions.view.key,
        isDisabled: false,
        externalComponent: null,
      },
      {
        enum: TableActions.edit.key,
        isDisabled: !item.isEditable,
        externalComponent: null,
      },
      {
        enum: TableActions.delete.key,
        isDisabled: !item.isEditable,
        externalComponent: null,
      },
    ]);
  };

  return (
    <div className='lookups-types-component view-wrapper'>
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <Button
              className='btns theme-solid bg-secondary mx-2'
              onClick={() => {
                props.setIsEdit(false);
                props.setOpenEditDialog(true);
              }}>
              {getTranslate()('Lookups.AddType')}
            </Button>
          </div>
          <div className='section px-2'>
            <div className='d-flex-column p-relative'>
              <Inputs
                idRef='lookupTypeSearchRef'
                variant='outlined'
                fieldClasses='inputs theme-solid'
                translationPath={'Lookups.'}
                label='filter'
                beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                onKeyUp={searchHandler}
                inputPlaceholder='search-by-lookup-name'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='px-3'>
        {props.response && props.response.totalCount === 0 && !props.isFirstLoad ? (
          <NoSearchResultComponent />
        ) : (
          <>
            {props.response && props.response.result && props.response.totalCount !== 0 && (
              <Tables
                headerData={[
                  {
                    id: 1,
                    isSortable: true,
                    label: 'Lookups.LookupName',
                    input: 'lookupTypeName',
                    isDate: false,
                  },
                  {
                    id: 2,
                    isSortable: true,
                    label: 'Lookups.Description',
                    input: 'lookupTypeDescription',
                    isDate: false,
                  },
                ]}
                data={props.response && props.response.result ? props.response.result : []}
                activePage={props.page}
                totalItems={
                  props.response && props.response.totalCount ? props.response.totalCount : 0
                }
                activePageChanged={handlePageChange}
                focusedRowChanged={focusedRowChanged}
                itemsPerPage={props.rowsPerPage}
                itemsPerPageChanged={handlePageRowChange}
                actionsOptions={{
                  actions: currentActions,
                  classes: '',
                  onActionClicked: (key, item) => {
                    if (key === TableActions.view.key) {
                      getHistory().push(
                        `/home/lookups/lookup-item?id=${item.lookupTypeId}&name=${item.lookupTypeName}&isEdit=${item.isEditable}`
                      );
                    }
                    if (key === TableActions.edit.key) {
                      props.setIsEdit(true);
                      props.setOpenEditDialog(true);
                      props.setSelectedUpdatedType(item);
                    }
                    if (key === TableActions.delete.key) {
                      setOpenDeleteDialog(true);
                      setSelectedLookup(item);
                    }
                  },
                }}
              />
            )}
          </>
        )}
        {selectedLookup && (
          <LookupsDeleteDialog
            onCancel={() => {}}
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            name={selectedLookup.lookupTypeName}
            row={selectedLookup}
            onDelete={async (e) => {
              await lookupTypesDelete({ lookupTypeId: e.lookupTypeId });
              props.setPage(0);
            }}
          />
        )}
      </div>
    </div>
  );
};
