import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { getDownloadableLink, getTranslate } from '../../../../../../Helpers';
import { getLanguage } from 'react-switch-lang';
import moment from 'moment';
import { getMimeTypeHandler } from '../../../../../../Utils';
import './CarActivityDetailsComponent.scss';
import '../../../../../../Componentes/GalleryComponent/GalleryComponent.scss';

export const CarActivityDetailsComponent = ({ activeItem, onDeleteClicked, translationPath }) => {
  return (
    <div className="car-activity-details-wrapper">
      <div className="d-flex-v-center-h-end">
        <Button className="btns-icon px-2" onClick={onDeleteClicked}>
          <span className="mdi mdi-trash-can-outline c-danger"></span>
        </Button>
      </div>
      {activeItem && activeItem.activityIcon && activeItem.activityIcon.toLowerCase() !== 'n/a' && (
        <div className="text-center w-100 px-2 mb-2">
          <span className={`mdi mdi-${activeItem.activityIcon} c-secondary mdi-24px`} />
        </div>
      )}
      <div className="text-center w-100 px-2 mb-2">
        <span className="fw-bold c-secondary">{activeItem && activeItem.activityName}</span>
      </div>
      <div className="w-100 px-2 mb-2 c-black-light">
        <div className="item-wrapper mb-3">
          <span className="item-header fw-bold c-secondary">
            <span className="mdi mdi-car px-2" />
            <span className="px-1">{getTranslate()(`${translationPath}car`)}:</span>
          </span>
          <span className="item-body fw-bold c-secondary">
            {(activeItem &&
              (activeItem.carTypeName || activeItem.carNumber) &&
              `${activeItem.carTypeName}-${activeItem.carNumber}`) ||
              'N/A'}
          </span>
        </div>
        <div className="item-wrapper">
          <span className="item-header">
            <span className="mdi mdi-calendar px-2" />
            <span>{getTranslate()(`${translationPath}date`)}:</span>
          </span>
          <span className="item-body">
            {(activeItem &&
              activeItem.activityDateTime &&
              moment(activeItem.activityDateTime).locale(getLanguage()).format('DD, MMMM YYYY')) ||
              'N/A'}
          </span>
        </div>
        <div className="item-wrapper">
          <span className="item-header">
            <span className="mdi mdi-calendar px-2" />
            <span>{getTranslate()(`${translationPath}value`)}:</span>
          </span>
          <span className="item-body">{activeItem.activityValue || 'N/A'}</span>
        </div>
        <div className="item-wrapper">
          <span className="item-header">
            <span className="mdi mdi-card-account-details-outline px-2" />
            <span>{getTranslate()(`${translationPath}name`)}:</span>
          </span>
          <span className="item-body">{activeItem.fullName || 'N/A'}</span>
        </div>
        <div className="item-wrapper">
          <span className="item-header">
            <span className="mdi mdi-note-text-outline px-2" />
            <span>{getTranslate()(`${translationPath}notes`)}:</span>
          </span>
          <span className="item-body">{(activeItem && activeItem.note) || 'N/A'}</span>
        </div>
        <div className="item-wrapper">
          <span className="item-header">
            <span className="mdi mdi-newspaper-variant-outline px-2" />
            <span>{getTranslate()(`${translationPath}evidence`)}:</span>
          </span>
          <span className="item-body">
            {(activeItem &&
              activeItem.activityAttachments &&
              activeItem.activityAttachments.length) ||
              'N/A'}
          </span>
        </div>
        {activeItem && activeItem.activityAttachments && (
          <div className="gallery-wrapper px-0 d-flex-center flex-wrap">
            {activeItem.activityAttachments.map((item, index) => {
              const getMimiTypeObj = getMimeTypeHandler(item.fileName);
              return (
                <a
                  className={`gallery-item-wrapper${getMimiTypeObj.isFile ? ' is-file' : ''}`}
                  key={`evidenceRef${item.fileId}${index + 1}`}
                  href={getDownloadableLink(item.fileId)}
                  download
                >
                  <div className="gallery-item-box">
                    <img
                      src={
                        item&&item.fileId
                          ? getDownloadableLink(item&&item.fileId)
                          : getMimiTypeObj.image
                      }
                      alt={item.fileName}
                      className={`gallery-image${!getMimiTypeObj.isImage ? ' is-default' : ''}`}
                    />
                  </div>
                </a>
              );
            })}
          </div>
        )}
      </div>
      {/* <div className="w-100 c-secondary">
        <div className="d-flex-center mb-3">
          <div className="d-inline-flex-center texts-truncate px-1">
            <span className="fw-bold px-1">{getTranslate()(`${translationPath}start-tour`)}:</span>
            <span>{moment().locale(getLanguage()).format('hh:mm a')}</span>
          </div>
          <div className="d-inline-flex-center texts-truncate px-1 separator-v s-gray-secondary">
            <span className="fw-bold px-1">{getTranslate()(`${translationPath}end-tour`)}:</span>
            <span>{moment().locale(getLanguage()).format('hh:mm a')}</span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

CarActivityDetailsComponent.propTypes = {
  activeItem: PropTypes.instanceOf(Object).isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
};
