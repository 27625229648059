import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Tooltip } from '@material-ui/core';
import { getTranslate } from '../../../../Helpers';
import { SlideImagesComponent, Spinner } from '../../../../Componentes';
import { DownloadInvoicesByPaymentOrder, GetInvoicesByPaymentOrder } from '../../../../Serviecs';
import moment from 'moment';
import './InvoiceDetailes.scss';

export const InvoiceDetailes = ({ activeItem, translationPath }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceInfo, setInvoiceInfo] = useState();

  const GetAllInvoiceInfo = useCallback(async () => {
    setIsLoading(true);
    const response = await GetInvoicesByPaymentOrder(activeItem.paymentOrderId);
    setInvoiceInfo(response);

    setIsLoading(false);
  }, [activeItem.paymentOrderId]);

  useEffect(() => {
    GetAllInvoiceInfo();
  }, [GetAllInvoiceInfo]);

  return (
    <div className="invoice-details-wrapper">
      <Spinner isActive={isLoading} />
      {activeItem && (
        <>
          <div className="cards-header">
            <div className="item-wrapper p-relative invoice-header-wrapper">
              <span className="item-header">{activeItem.invoiceNumber}</span>
              <span className="item-header">
                {getTranslate()(`${translationPath}total`)}
                {activeItem.totalPrice}
              </span>
            </div>
          </div>
          <div className="invoice-detailes-container">
            <div className="d-flex-column invoice-info-container">
              <div className="item-wrapper px-2">
                <span className="item-header">
                  {getTranslate()(`${translationPath}invoice-to`)}:
                </span>
              </div>
              <div className="item-wrapper px-2">
                <span className="item-header">{getTranslate()(`${translationPath}phone`)}:</span>
              </div>
              <div className="item-wrapper px-2">
                <span className="item-header">{getTranslate()(`${translationPath}whatsapp`)}:</span>
              </div>
            </div>
            <div className="d-flex-column invoice-info-content-container">
              <div className="item-wrapper px-2 invoice-to-name texts-truncate">
                <span className="item-header">{activeItem.invoiceToName || 'N/A'}</span>
              </div>
              <div className="item-wrapper px-2">
                <span className="item-header">{activeItem.invoiceToPhone || 'N/A'}</span>
              </div>
              <div className="item-wrapper px-2">
                <span className="item-header">{activeItem.invoiceToWhatsApp || 'N/A'}</span>
              </div>
            </div>
          </div>

          <div className="item-wrapper px-2 payment-info">
            <span className="item-header px-3">
              {getTranslate()(`${translationPath}payment-info`)}
            </span>
          </div>

          <div className="invoice-detailes-container">
            <div className="d-flex-column invoice-info-container">
              <div className="item-wrapper px-2">
                <span className="item-header">
                  {getTranslate()(`${translationPath}payment-method`)}
                </span>
              </div>
              <div className="item-wrapper px-2">
                <span className="item-header">
                  {getTranslate()(`${translationPath}payment-date`)}:
                </span>
              </div>
            </div>
            <div className="d-flex-column invoice-info-content-container">
              <div className="item-wrapper px-2">
                <span className="item-header">{activeItem.paymentMethod || 'N/A'}</span>
              </div>
              <div className="item-wrapper px-2">
                <span className="item-header">
                  {moment(activeItem.paymentDate).format('DD/MM/yyyy') || 'N/A'}
                </span>
              </div>
            </div>
          </div>

          <div className="divider-wrapper">
            <Divider />
          </div>

          <div className="invoice-tours-wrapper">
            {invoiceInfo &&
              invoiceInfo.tourCarPassengers &&
              invoiceInfo.tourCarPassengers.map((item) => (
                <div>
                  <div className="invoice-images">
                    <SlideImagesComponent
                      data={
                        (item && item.tour && item.tour.tour && item.tour.tour.tourSummaryTrips) ||
                        []
                      }
                      imageInput="tripCoverImage"
                      alt="trip-image"
                      titleText="trip-gallery"
                      translationPath={translationPath}
                      withViewGallery
                    />
                    <div className="invoice-name-wrapper">
                      <div className="invoice-tour-name">
                        {getTranslate()(`${translationPath}tour-name`)}
                      </div>
                      <Tooltip title={item.tour.tour.tourName}>
                        <div className="texts-truncate tour-name">{item.tour.tour.tourName}</div>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="invoice-detailes-info invoice-pickup invoice-divider">
                    <span className="mdi mdi-map-marker" />
                    <Tooltip title={item.pickUpAddress} className="texts-truncate">
                      <p>{item.pickUpAddress}</p>
                    </Tooltip>
                  </div>
                  <div className="invoice-detailes-info invoice-dropoff invoice-divider ">
                    <span className="mdi mdi-map-marker" />
                    <Tooltip title={item.dropOffAddress} className="texts-truncate">
                      <p>{item.dropOffAddress}</p>
                    </Tooltip>
                  </div>
                  <div className="invoice-detailes-info invoice-time">
                    <div className="invoice-time-info invoice-divider">
                      <span className="mdi mdi-calendar" />
                      <p>{moment(item.startTourDateTime).format('DD/MM/yyyy') || 'N/A'}</p>
                    </div>
                    <div className="invoice-time-info invoice-divider">
                      <span className="mdi mdi-clock" />
                      <p>
                        {moment(item.startTourDateTime).format('h:mm') || 'N/A'}
                        {' - '}
                        {moment(item.endTourDateTime).format('h:mm') || 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className="invoice-detailes-info invoice-car-wrapper">
                    <div className="invoice-car-info invoice-divider">
                      <span className="mdi mdi-bag-suitcase" />
                      <p>
                        {item.passengers}
                        {getTranslate()(`${translationPath}passenger`)}
                      </p>
                    </div>
                    <div className="invoice-car-info invoice-divider">
                      <span className="mdi mdi-car" />
                      <Tooltip title={item.carTypeName}>
                        <p className="texts-truncate invoice-car">{item.carTypeName}</p>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="divider-wrapper">
                    <Divider />
                  </div>
                </div>
              ))}
          </div>

          <div className="download-button">
            <Button
              className="w-100 btns theme-solid"
              onClick={async () => {
                setIsLoading(true);
                const data = await DownloadInvoicesByPaymentOrder(activeItem.paymentOrderId);
                setIsLoading(false);
                try {
                  const url = window.URL.createObjectURL(data);
                  const link = document.createElement('a');
                  link.href = url;
                  link.target = '_blank';
                  document.body.appendChild(link);
                  link.click();
                  link.download = activeItem.invoiceNumber + '.pdf';
                  document.body.append(link);
                }
                catch (error) {
                  return
                }

              }}
            >
              {getTranslate()(`${translationPath}download`)}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

InvoiceDetailes.propTypes = {
  activeItem: PropTypes.instanceOf(Object).isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
};
