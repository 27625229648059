import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';
import { getTranslate } from '../../Helpers';
import './TabsComponent.scss';

export const TabsComponent = ({
  iconInput,
  data,
  currentTab,
  onTabChanged,
  wrapperClasses,
  tabsAriaLabel,
  labelInput,
  translationPath,
  varient,
  orientation,
  iconOnly,
}) => (
  <Tabs
    value={currentTab}
    onChange={onTabChanged}
    variant={varient}
    orientation={orientation}
    scrollButtons="on"
    indicatorColor="primary"
    textColor="primary"
    className={`tabs-wrapper ${wrapperClasses} ${iconOnly ? 'icon-only' : ''}`}
    aria-label={tabsAriaLabel}
  >
    {data.map((item, index) => (
      <Tab
        key={`tabRef${index + 1}`}
        label={
          labelInput &&
          !iconOnly && <span>{getTranslate()(`${translationPath}${item[labelInput]}`)}</span>
        }
        icon={<span className={(iconInput && item[iconInput]) || undefined} />}
      />
    ))}
  </Tabs>
);

TabsComponent.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  onTabChanged: PropTypes.func.isRequired,
  currentTab: PropTypes.number,
  iconInput: PropTypes.string,
  labelInput: PropTypes.string,
  wrapperClasses: PropTypes.string,
  tabsAriaLabel: PropTypes.string,
  translationPath: PropTypes.string,
  varient: PropTypes.string,
  orientation: PropTypes.string,
  iconOnly: PropTypes.bool,
};
TabsComponent.defaultProps = {
  currentTab: 0,
  iconInput: undefined,
  labelInput: undefined,
  wrapperClasses: '',
  translationPath: '',
  tabsAriaLabel: 'tabs',
  varient: 'scrollable',
  orientation: undefined, // 'vertical',undefined (for horizontal)
  iconOnly: false,
};
