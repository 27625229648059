import React, { useReducer } from 'react';
import {
 Dialog, DialogContent, DialogTitle, Grid
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Map from 'pigeon-maps';
import Marker from 'pigeon-marker';
import { getTranslate } from '../../../../Helpers';

export function MapDialog(props) {
  const reducer = (state, action) => ({ ...state, [action.id]: action.value });
  const [state, setState] = useReducer(reducer, props.initialState);
  // const ref = useRef(null);

  //
  // useEffect(() => {
  //     const width = ref.current ? ref.current.offsetWidth : 500;
  //     setMapState({...mapState ,width })
  // }, [mapState]);
  return (
    <>
      <Dialog className="dialog" open={props.open} maxWidth="lg">
        <form
          noValidate
          onSubmit={(event) => {
            event.preventDefault();
            props.onChange(state);
            props.closeDialog();
          }}
        >
          <DialogTitle>Map</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                {/* <div className="position-relative w-100" ref={ref}> */}
                {/*    <ReactMapGL */}
                {/*        { ...mapState} */}
                {/*        mapboxApiAccessToken={"pk.eyJ1IjoiYWFsdGFpciIsImEiOiJjazN5N2dsZjYwNXpqM2ttd2M0aWduNnBzIn0.E-Rp5C6-pYJo9Gut3tvGEA"} */}
                {/*         onClick={(e)=>{ */}
                {/*            if( Array.isArray(e.lngLat)) */}
                {/*                   { */}
                {/*                        setState({id:"latitude",value:e.lngLat[1]}) */}
                {/*                        setState({id:"longitude",value:e.lngLat[0]}) */}
                {/*                   } */}
                {/*         } */}

                {/*         } */}

                {/*        onViewportChange={(e)=>{setMapState(e)}} */}

                {/*    > */}

                {/*        <Marker     latitude={state.latitude}                   longitude={state.longitude}         > */}
                {/*            <RoomIcon /> */}
                {/*        </Marker> */}

                {/*    </ReactMapGL> */}
                {/* </div> */}
                <Map
                  center={[state.latitude, state.longitude]}
                  zoom={12}
                  style={{ width: '100%' }}
                  height={320}
                  onClick={(e) => {
                    if (Array.isArray(e.latLng)) {
                      setState({ id: 'latitude', value: e.latLng[0] });
                      setState({ id: 'longitude', value: e.latLng[1] });
                    }
                  }}
                >
                  <Marker anchor={[state.latitude, state.longitude]} payload="1" />
                </Map>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              className="btns theme-solid bg-cancel"
              onClick={() => {
                props.closeDialog();
              }}
            >
              {getTranslate()('shared.cancel')}
            </Button>
            <Button className="btns theme-solid" type="submit">
              {getTranslate()('shared.save')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
//  const googleComponant =   GoogleApiWrapper({ apiKey: 'AIzaSyBVJ175WsWgBrNgHXmqojYaU7roFSUe3ME'}) (MapDialog)  ;
// export {googleComponant as MapDialog }
