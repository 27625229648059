/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getTranslate, getDownloadableLink, useEventListener } from '../../../../../Helpers';
import { ActionsEnum, DefaultImagesEnum, LoadableImageEnum } from '../../../../../Enums';
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
} from '@material-ui/lab';
import { Button } from '@material-ui/core';
import './ToursCardComponent.scss';
import {
  LoadableImageComponant,
  NoSearchResultComponent,
  MasonryComponent,
} from './../../../../../Componentes';

export const ToursCardComponent = ({
  data,
  filter,
  translationPath,
  onLoadMore,
  onCardClicked,
  onActionClicked,
  isLoading,
  isFirstLoad,
}) => {
  const onScrollHandler = useCallback(() => {
    if (
      document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.scrollHeight - 5 &&
      data.result.length < data.totalCount &&
      isLoading !== true
    )
      onLoadMore();
  }, [data.result.length, data.totalCount, isLoading, onLoadMore]);
  useEventListener('scroll', onScrollHandler);
  useEffect(() => {
    if (
      data.result.length < data.totalCount &&
      document.body.scrollHeight <= document.body.clientHeight &&
      isLoading !== true
    )
      onScrollHandler();
  }, [data.result.length, data.totalCount, isLoading, onScrollHandler]);

  return (
    <div className='tour-card-component-wrapper'>
      {data && data.totalCount === 0 && !isFirstLoad && filter.search ? (
        <NoSearchResultComponent />
      ) : (
        <MasonryComponent>
          {data.result &&
            data.result.map((item, index) => (
              <div className='tour-card-wrapper' key={`tourCardItemRef${index + 1}`}>
                <a onClick={onCardClicked && onCardClicked(item, index)} className='cards-wrapper'>
                  <div className='cards-header'>
                    <div className='item-wrapper'>
                      <span className='item-header'>{item.tourName}</span>
                    </div>
                  </div>
                  <div className='cards-body'>
                    {/* <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-home px-2' />
                        <span>{getTranslate()(`${translationPath}type`)}:</span>
                      </span>
                      <span className='item-body'>{item.tourType}</span>
                    </div> */}
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-road-variant px-2' />
                        <span>{getTranslate()(`${translationPath}total-destination`)}:</span>
                      </span>
                      <span className='item-body'>
                        <span>{item.totalDestinations}</span>
                      </span>
                    </div>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-clock-time-five px-2' />
                        <span>{getTranslate()(`${translationPath}total-time`)}:</span>
                      </span>
                      <span className='item-body'>
                        <span>{item.totalDuration}</span>
                      </span>
                    </div>
                    <div className='item-wrapper'>
                      <span className='item-header'>
                        <span className='mdi mdi-cash-multiple px-2' />
                        <span>{getTranslate()(`${translationPath}total-price`)}:</span>
                      </span>
                      <span className='item-body'>{item.discountPrice}</span>
                    </div>
                    <div className='item-wrapper actions'>
                      <Button
                        className='btns theme-outline'
                        onClick={onActionClicked(ActionsEnum.reportEdit.key, item, index)}>
                        <span className='mdi mdi-file-document-edit' />
                      </Button>
                      <Button
                        className='btns theme-outline'
                        onClick={onActionClicked(ActionsEnum.delete.key, item, index)}>
                        <span className='mdi mdi-trash-can-outline' />
                      </Button>
                    </div>
                  </div>
                  <div className='cards-footer'>
                    <Timeline
                      className='timeline-wrapper'
                      align={localStorage.getItem('localization').isRtl ? 'right' : 'left'}>
                      {item.tripsTours &&
                        item.tripsTours.map((subItem, index) => (
                          <TimelineItem key={`tripTimelineKey${index + 1}`}>
                            <TimelineSeparator>
                              <TimelineDot />
                              <TimelineConnector />
                            </TimelineSeparator>

                            <TimelineContent>
                              <LoadableImageComponant
                                classes='item-wrapper tour-cover-image mb-3'
                                type={LoadableImageEnum.div.key}
                                alt={getTranslate()(`${translationPath}tour-cover-image`)}
                                src={
                                  (subItem.coverTripImageId &&
                                    getDownloadableLink(subItem.coverTripImageId)) ||
                                  DefaultImagesEnum.buildings.defaultImg
                                }
                              />
                              <div className='item-wrapper'>
                                <span className='item-header'>{subItem.tripName}</span>
                              </div>
                              <div className='d-flex-v-center-h-between flex-wrap'>
                                <span>
                                  <span className='item-header'>
                                    <span className='mdi mdi-clock-time-five' />
                                    <span className='px-2'>
                                      {getTranslate()(`${translationPath}time`)}:
                                    </span>
                                  </span>
                                  <span className='item-body'>{subItem.tripDuration}</span>
                                </span>
                                <span>
                                  <span className='item-header'>
                                    <span className='mdi mdi-cash-multiple' />
                                    <span className='px-2'>
                                      {getTranslate()(`${translationPath}price`)}:
                                    </span>
                                  </span>
                                  <span className='item-body'>{subItem.tripPrice}</span>
                                </span>
                              </div>
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                    </Timeline>
                  </div>
                </a>
              </div>
            ))}
        </MasonryComponent>
      )}
    </div>
  );
};
ToursCardComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onCardClicked: PropTypes.func.isRequired,
  onActionClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  translationPath: PropTypes.string,
};

ToursCardComponent.defaultProps = {
  translationPath: 'home.tours.toursView.toursCardComponent.',
};
