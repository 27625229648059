import React, { useCallback, useEffect, useRef, useState } from 'react';
import CarMark from '../../Assets/Images/defaults/CarMark.png';
import PinPoint from '../../Assets/Images/defaults/PinPoint.png';
import GoogleMapReact from 'google-map-react';
import Popover from '@material-ui/core/Popover';
import { PropTypes } from 'prop-types';
import { Typography, Grid, Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { ApiKey, GetLocationByAddress, GetReservationTourById } from '../../Serviecs';
import { GoogleMapPremadeComponentsEnum, GoogleMapTravelModeEnum } from '../../Enums';
import { getDownloadableLink, getTranslate } from '../../Helpers';
import moment from 'moment';
import { LoadableImageComponant } from '../LoadableImageComponant/LoadableImageComponant';
import './GoogleMaps.scss';
import { AutocompleteComponent } from '../Controls';

const CarMarker = ({
  data,
  translationPath,
  activeItem,
  index,
  googleRef,
  driverInfo,
  setDriverInfo,
  setRoutes,
  setPolyLine,
  lat,
  lng,
  endLocation,
  setUserInfo,
  setIsClientClicked,
  setIsAgentClicked,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [counter, setCounter] = useState(0);
  const [degree, setDegree] = useState('');
  const [showCar, setShowCar] = useState(true);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (lat === 0 || lng === 0) {
      setShowCar(false);
    } else {
      setShowCar(true);
    }
  }, [lat, lng]);

  const vehicleBearing = (x1, y1, x2, y2) => {
    const getAtan2 = (y, x) => Math.atan2(y, x);

    const radians = getAtan2(y1 - y2, x1 - x2);
    const compassReading = radians * (180 / Math.PI);
    const coordNames = [360, 45, 90, 135, 180, 225, 270, 315, 0];
    let coordIndex = Math.round(compassReading / 45);
    if (coordIndex < 0) coordIndex = coordIndex + 8;
    setDegree(coordNames[coordIndex]);
    return coordNames[coordIndex];
  };

  useEffect(() => {
    vehicleBearing(lat, lng, endLocation[0], endLocation[1]);
  }, [lat, lng, endLocation]);

  const handlePopoverOpen = async (e) => {
    setCounter(0);
    setPolyLine(false);
    setRoutes([]);
    setAnchorEl(e.currentTarget);
    const result = await GetReservationTourById(data.id);
    setDriverInfo(result);
    setUserInfo(result && result.contactReservation);

    const polyLine = [];

    if (result && result.tourDetailsDto && result.tourDetailsDto.tourSummaryTrips) {
      result.tourDetailsDto.tourSummaryTrips.map(() => {
        if (counter + 1 < result.tourDetailsDto.tourSummaryTrips.length) {
          polyLine.push({
            origin: {
              lat: result.tourDetailsDto.tourSummaryTrips[counter].tripLatitude,
              lng: result.tourDetailsDto.tourSummaryTrips[counter].tripLongitude,
            },
            destination: {
              lat: result.tourDetailsDto.tourSummaryTrips[counter + 1].tripLatitude,
              lng: result.tourDetailsDto.tourSummaryTrips[counter + 1].tripLongitude,
            },
          });
          setCounter(counter + 1);
        } else {
          polyLine.push({
            origin: {
              lat: result.tourDetailsDto.tourSummaryTrips[counter].tripLatitude,
              lng: result.tourDetailsDto.tourSummaryTrips[counter].tripLongitude,
            },
            destination: {
              lat: result.tourDetailsDto.tourSummaryTrips[counter].tripLatitude,
              lng: result.tourDetailsDto.tourSummaryTrips[counter].tripLongitude,
            },
          });
          setCounter(counter + 1);
        }
        return null;
      });
    }
    setRoutes(polyLine);
  };
  const handlePopoverClose = () => {
    setCounter(0);
    setAnchorEl(null);
  };

  return (
    <>
      {showCar && (
        <div className='google-maps-wrapper'>
          <img
            className='car-marker'
            style={{ '--car-degree': degree + 'deg' }}
            onClick={(e) => handlePopoverOpen(e)}
            src={CarMark}
            alt='Car-Marker'
          />
          {data && (
            <Popover
              className='hover-popover'
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus>
              <Grid container className='popover-car-wrapper'>
                <Grid item xs={12} className='driver-info-response'>
                  <div className='content-tag-icon'>
                    <span className='mdi mdi-calendar-month' />
                    <Typography className='contentTag'>
                      {driverInfo &&
                        driverInfo.startTourDate &&
                        getTranslate()(`${translationPath}from`) +
                          moment(driverInfo.startTourDate).format('DD/MM/yyy') +
                          getTranslate()(`${translationPath}to`) +
                          moment(driverInfo.endTourDate).format('DD/MM/yyy')}
                    </Typography>
                  </div>
                  <div className='content-tag-icon'>
                    <span className='mdi mdi-clock' />
                    <Typography className='contentTag'>
                      {driverInfo &&
                        driverInfo.startTourDate &&
                        moment(driverInfo.startTourDate).format('hh:mm a') +
                          ' - ' +
                          moment(driverInfo.endTourDate).format('hh:mm a')}
                    </Typography>
                  </div>
                  <div className='content-tag-icon'>
                    <span className='mdi mdi-phone' />
                    <Typography className='contentTag'>
                      {driverInfo &&
                        driverInfo.contactReservation &&
                        driverInfo.contactReservation.map((item, i) =>
                          i === index ? (item.phoneNumber ? item.phoneNumber : 'N/A') : ''
                        )}
                    </Typography>
                  </div>
                </Grid>
                {data.onPopoverButtonClicked && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      data.onPopoverButtonClicked(activeItem, index, googleRef);
                      handlePopoverClose();
                    }}
                    className='btns theme-outline w-100 h-35px'>
                    {getTranslate()(`${translationPath}view-tour-line`)}
                  </Button>
                )}
                <Grid container item spacing={2} className='popover-avatar'>
                  <div className='driver-avatar-wrapper'>
                    {driverInfo &&
                      driverInfo.contactReservation &&
                      driverInfo.contactReservation.map((item) => (
                        <Grid
                          item
                          onClick={() =>
                            item.roleType === 'Client'
                              ? setIsClientClicked(true)
                              : item.roleType === 'Agent'
                              ? setIsAgentClicked(true)
                              : ''
                          }>
                          {item.profileImage ? (
                            <LoadableImageComponant
                              classes={'driver-image'}
                              src={getDownloadableLink(item.profileImage)}
                              alt={item.fullName}
                            />
                          ) : (
                            <Avatar alt='user-image' src='' />
                          )}
                        </Grid>
                      ))}
                  </div>
                </Grid>
              </Grid>
              <div className='arrow-down' />
            </Popover>
          )}
        </div>
      )}
    </>
  );
};

const MapPoints = ({ driverInfo, lat }) => {
  let tripOrder = 0;
  if (driverInfo) {
    if (driverInfo && driverInfo.dropOffLocation.latitude === lat) {
      tripOrder = 'P/D';
    } else {
      driverInfo.tourDetailsDto.tourSummaryTrips.map((item) =>
        item.tripLatitude === lat ? (tripOrder = item.tripOrder) : 'N/A'
      );
    }
  }

  return driverInfo ? (
    <div className='pin-point'>
      <Avatar variant='square'>
        <Typography>{tripOrder === 0 ? 3 : tripOrder}</Typography>
      </Avatar>
    </div>
  ) : (
    <img src={PinPoint} alt='pin-point' width={20} height={30} />
  );
};

export const GoogleMapsComponent = ({
  locations,
  translationPath,
  translationPathShared,
  defaultCenter,
  hoverDistance,
  defaultZoom,
  defaultComponent,
  idRef,
  onClick,
  onChange,
  routes,
  informationInput,
  iconInput,
  titleInput,
  descriptionInput,
  center,
  driverInfo,
  setDriverInfo,
  setRoutes,
  setPolyLine,
  endLocation,
  onSearchLocationChanged,
  searchLocationValue,
  agentLocation,
  clientLocation,
}) => {
  const [google, setGoogle] = useState(null);
  const [address, setAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [searchLocation, setSearchLocation] = useState(null);
  const [premadeChildren, setPremadeChildren] = useState(null);
  const [userInfo, setUserInfo] = useState();
  const [isAgentClicked, setIsAgentClicked] = useState(false);
  const [isClientClicked, setIsClientClicked] = useState(false);
  const locationSearchTimer = useRef(null);

  const calcRoute = useCallback(() => {
    if (google === null) return;
    routes.forEach((route) => {
      const directionsService = new google.DirectionsService();
      directionsService.route(
        { ...route, travelMode: route.travelMode || GoogleMapTravelModeEnum.DRIVING.key },
        (DirectionsResult, DirectionsStatus) => {
          if (DirectionsStatus === 'OK') {
            new google.DirectionsRenderer({
              map: premadeChildren,
              directions: DirectionsResult,
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: '#31aec4',
                strokeWeight: 5,
                strokeOpacity: 1.0,
              },
            });
          }
        }
      );
    });
  }, [google, premadeChildren, routes]);
  const getLocationByAddress = useCallback(async () => {
    setIsLoading(true);
    const response = await GetLocationByAddress(address);
    setAddresses((response && response.results) || []);
    setIsLoading(false);
  }, [address]);
  const handleMap = (map, maps) => {
    setGoogle(maps);
    setPremadeChildren(map);
    // new maps.DirectionsRenderer();
  };

  const getRouteMarkers = (route, index) => {
    const markers = [];
    if (route.origin)
      markers.push(
        <MapPoints
          lat={route.origin.lat}
          lng={route.origin.lng}
          key={`${idRef}originMarker${index + 1}`}
          text={getTranslate()(`${translationPath}${route.text}`)}
          driverInfo={driverInfo}
        />
      );
    if (route.destination)
      markers.push(
        <MapPoints
          lat={route.destination.lat}
          lng={route.destination.lng}
          key={`${idRef}destinationMarker${index + 1}`}
          text={getTranslate()(`${translationPath}${route.text}`)}
          driverInfo={driverInfo}
        />
      );
    return markers;
  };
  const getPremadeComponent = (key, item, index) => {
    if (key === GoogleMapPremadeComponentsEnum.MapPoints.key)
      return (
        <MapPoints
          lat={item.latitude}
          lng={item.longitude}
          key={`${idRef}${index + 1}`}
          text={getTranslate()(`${translationPath}${item.text}`)}
        />
      );
    else if (key === GoogleMapPremadeComponentsEnum.CarMarker.key)
      return (
        <CarMarker
          setPolyLine={setPolyLine}
          setRoutes={setRoutes}
          lat={item.latitude}
          lng={item.longitude}
          key={`${idRef}${index + 1}`}
          idRef={idRef}
          translationPath={translationPath}
          data={item.data}
          informationInput={informationInput}
          text={getTranslate()(`${translationPath}${item.text}`)}
          iconInput={iconInput}
          titleInput={titleInput}
          descriptionInput={descriptionInput}
          activeItem={item}
          index={index}
          googleRef={google}
          driverInfo={driverInfo}
          setDriverInfo={setDriverInfo}
          endLocation={endLocation}
          setUserInfo={setUserInfo}
          setIsClientClicked={setIsClientClicked}
          setIsAgentClicked={setIsAgentClicked}
        />
      );
  };
  const getClientMarker = () => {
    return (
      <div lat={clientLocation[0].latitude} lng={clientLocation[0].longitude}>
        {userInfo &&
          userInfo.map((item) =>
            item.roleType === 'Client' ? (
              item.profileImage ? (
                <LoadableImageComponant
                  classes={'driver-image'}
                  src={getDownloadableLink(item.profileImage)}
                  alt={item.fullName}
                />
              ) : (
                <Avatar alt={item.fullName}></Avatar>
              )
            ) : (
              <></>
            )
          )}
      </div>
    );
  };
  const getAgentMarker = () => {
    return (
      <div lat={agentLocation[0].latitude} lng={agentLocation[0].longitude}>
        {userInfo &&
          userInfo.map((item) =>
            item.roleType === 'Agent' ? (
              item.profileImage ? (
                <LoadableImageComponant
                  classes={'driver-image'}
                  src={getDownloadableLink(item.profileImage)}
                  alt={item.fullName}
                />
              ) : (
                <Avatar alt={item.fullName}></Avatar>
              )
            ) : (
              <></>
            )
          )}
      </div>
    );
  };
  const locationSearchHandler = (event) => {
    const value = event.target.value;
    if (locationSearchTimer.current) clearTimeout(locationSearchTimer.current);
    locationSearchTimer.current = setTimeout(() => {
      setAddress(value);
    }, 1000);
  };
  const onClickHandler = (clickValue) => {
    if (searchLocation) setSearchLocation(null);
    if (onClick) onClick(clickValue);
  };
  useEffect(() => {
    setSearchLocation(searchLocationValue);
  }, [searchLocationValue]);
  useEffect(() => {
    getLocationByAddress();
  }, [address, getLocationByAddress]);
  useEffect(() => {
    if (center && premadeChildren) {
      premadeChildren.setCenter(center);
    }
  }, [center, premadeChildren]);
  useEffect(() => {
    if (routes && google) {
      calcRoute();
    }
  }, [routes, google, premadeChildren, calcRoute]);
  useEffect(() => {
    return () => {
      if (locationSearchTimer.current) clearTimeout(locationSearchTimer.current);
    };
  }, []);

  return (
    <div className='google-maps-wrapper'>
      {(onSearchLocationChanged || searchLocationValue) && (
        <AutocompleteComponent
          idRef={`${idRef}searchLocation`}
          inputPlaceholder='search-location'
          translationPath={translationPathShared}
          isLoading={isLoading}
          value={searchLocation}
          options={addresses}
          filterOptions={(data) => data}
          // chipsLabel={(option) => option.fullName || ''}
          // renderOption={(option) =>
          //   `${option.fullName || ''}${(option.userName && '(' + option.userName + ')') || ''}`
          // }
          getOptionLabel={(option) => option.formatted_address || ''}
          // getOptionSelected={(option) => option.id === state.agentId}
          multiple={false}
          onKeyUp={locationSearchHandler}
          withoutSearchButton
          onChange={(event, newValue) => {
            setSearchLocation(newValue);
            onSearchLocationChanged(
              (newValue && newValue.geometry && newValue.geometry.location) || null,
              newValue
            );
          }}
        />
      )}
      <div className='google-maps'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: ApiKey }}
          // hoverDistance={hoverDistance}
          defaultCenter={defaultCenter}
          defaultZoom={defaultZoom}
          // yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) =>  handleMap(map, maps)}
          onClick={onClickHandler}
          onChange={onChange}
        >
            {clientLocation && clientLocation[0] && isClientClicked && getClientMarker()}
            {agentLocation && agentLocation[0] && isAgentClicked && getAgentMarker()}
            {routes &&
              routes.map((drowRouteMarkers, index) => getRouteMarkers(drowRouteMarkers, index))}
            {locations &&
              locations.map(
                (item, index) =>
                  (item.component && item.component(item, index, google)) ||
                  getPremadeComponent(item.premadeComponent || defaultComponent, item, index)
              )}
        </GoogleMapReact>
      </div>
    </div>
  );
};
GoogleMapsComponent.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      text: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.instanceOf(Object)), // for popover content
      premadeComponent: PropTypes.oneOf(
        Object.values(GoogleMapPremadeComponentsEnum).map((item) => item.key)
      ),
      component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.node]),
    })
  ).isRequired,
  onSearchLocationChanged: PropTypes.func,
  searchLocationValue: PropTypes.instanceOf(Object),
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      origin: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      destination: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      travelMode: PropTypes.oneOf(Object.values(GoogleMapTravelModeEnum).map((item) => item.key)),
      text: PropTypes.string,
    })
  ),
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  defaultComponent: PropTypes.oneOf(
    Object.values(GoogleMapPremadeComponentsEnum).map((item) => item.key)
  ),
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  hoverDistance: PropTypes.number,
  defaultZoom: PropTypes.number,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  informationInput: PropTypes.string,
  iconInput: PropTypes.string,
  titleInput: PropTypes.string,
  descriptionInput: PropTypes.string,
  translationPathShared: PropTypes.string,
  translationPath: PropTypes.string,
  idRef: PropTypes.string,
  withLines: PropTypes.bool,
};
GoogleMapsComponent.defaultProps = {
  defaultCenter: { lat: 24.414833592365972, lng: 54.59777364239554 },
  hoverDistance: 30,
  defaultZoom: 10,
  onClick: undefined,
  onChange: undefined,
  center: undefined,
  onSearchLocationChanged: undefined,
  searchLocationValue: null,
  informationInput: 'information',
  iconInput: 'icon',
  titleInput: 'title',
  descriptionInput: 'description',
  translationPathShared: 'home.googleMapComponent.',
  translationPath: '',
  idRef: 'googleMapRef',
  defaultComponent: GoogleMapPremadeComponentsEnum.MapPoints.key,
};
