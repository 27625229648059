import {
  LoginView,
  IdentityVerificationView,
  ResetPasswordOptionsView,
  VerificationCodeView,
  NewPasswordView,
} from '../../Views/Account';

export const AccountRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    layout: '/account',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/IdentityVerificationView',
    name: 'IdentityVerification',
    component: IdentityVerificationView,
    layout: '/account',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/ResetPasswordOptionsView',
    name: 'ResetPasswordOptions',
    component: ResetPasswordOptionsView,
    layout: '/account',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/VerificationCodeView',
    name: 'VerificationCode',
    component: VerificationCodeView,
    layout: '/account',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/NewPasswordView',
    name: 'NewPassword',
    component: NewPasswordView,
    layout: '/account',
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
];
