import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getErrorByName, getTranslate } from '../../../../../../Helpers';
import { Inputs } from '../../../../../../Componentes';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import './ExtraFeesComponent.scss';
export const ExtraFeesComponent = ({
  state,
  onExtraFeesChange,
  translationPath,
  isSubmitted,
  schema,
}) => {
  const [selectedDiscountType, setSelectedDiscountType] = useState(1);

  useEffect(() => {
    if (state.tourExtraFee.length !== 0) setInputList(state.tourExtraFee);
  }, [state]);

  const [inputList, setInputList] = useState([
    { tourExtraFeesName: '', adultFee: '', childFee: '', infantFee: '' },
  ]);
  const [defultval, setdefultval] = useState(0);
  // handle input change
  const handleInputChange = (e, name, index) => {
    const value = e;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { tourExtraFeesName: '', adultFee: '', childFee: '', infantFee: '' },
    ]);
    setdefultval(0);
  };

  useEffect(() => {
    onExtraFeesChange('tourExtraFee', inputList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputList]);

  const discountTypeInit = useCallback(() => {
    if (state.discountValue !== null && selectedDiscountType !== 2) setSelectedDiscountType(2);
    else if (
      (state.discountPercentage !== null || state.discountLimit !== null) &&
      selectedDiscountType !== 1
    )
      setSelectedDiscountType(1);
  }, [selectedDiscountType, state.discountLimit, state.discountPercentage, state.discountValue]);
  useEffect(() => {
    discountTypeInit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.discountValue, state.discountLimit, state.discountPercentage,]);
  return (
    <div className='tour-discounts-fees-wrapper'>
      <span className='d-flex mb-2 fw-bold c-black-light'>
        {getTranslate()(`${translationPath}Extrafees`)}
      </span>
      <span className='d-flex mb-3'>{getTranslate()(`${translationPath}addExtrafees`)}</span>
      <div className='form-wrapper'>
        {inputList.map((x, i) => {
          return (
            <>
              <div className='form-item'>
                <div className='part-1'>
                  <Inputs
                    idRef='infantFee'
                    translationPath={translationPath}
                    isWithError
                    helperText={getErrorByName(schema, 'extraFeesChild').message}
                    inputPlaceholder='EntertourExtraFeesName'
                    labelValue='PassengersPrice'
                    isSubmitted={isSubmitted}
                    error={getErrorByName(schema, 'extraFeesChild').error}
                    value={x.tourExtraFeesName || ''}
                    onInputChanged={(event) => {
                      handleInputChange(event.target.value, 'tourExtraFeesName', i);
                    }}
                  />
                </div>

                <div className='part-2'>
                  {inputList.length !== 1 && (
                    <IconButton
                      className='Delete-Icon'
                      aria-label='delete'
                      title={getTranslate()(`${translationPath}delete`)}
                      onClick={() => handleRemoveClick(i)}>
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  )}
                </div>

                {inputList.length - 1 === i && (
                  <div className='part-add'>
                    <Tooltip
                      size='small'
                      title={getTranslate()(`${translationPath}add`)}
                      aria-label='add'
                      onClick={handleAddClick}>
                      <Fab
                        className={
                          x.tourExtraFeesName === '' ? 'addIcondisactive' : 'addIconactive'
                        }
                        disabled={x.tourExtraFeesName === '' ? true : false}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                )}
              </div>

              <div
                className={
                  inputList.length !== 1 ? 'd-flex w-100 item-3 line-heder ' : 'd-flex w-100 item-3'
                }>
                <div className='form-item'>
                  <Inputs
                    idRef='infantFee'
                    labelValue='Adults'
                    translationPath={translationPath}
                    isWithError
                    helperText={getErrorByName(schema, 'extraFeesChild').message}
                    inputPlaceholder='Adults'
                    overInputText='AED'
                    isSubmitted={isSubmitted}
                    error={getErrorByName(schema, 'extraFeesChild').error}
                    type='number'
                    value={x.adultFee || defultval}
                    onInputChanged={(event) => {
                      const floatHandler = () => {
                        const price = event.target.value.toString().split('.');
                        if (price.length === 2 && price[1].length > 3)
                          return Number(event.target.value).toFixed(3);
                        else return Number(event.target.value);
                      };
                      handleInputChange(floatHandler(), 'adultFee', i);
                    }}
                  />
                </div>
                <div className='form-item'>
                  <Inputs
                    idRef='infantFee'
                    labelValue='Children'
                    translationPath={translationPath}
                    isWithError
                    helperText={getErrorByName(schema, 'extraFeesChild').message}
                    inputPlaceholder='Children'
                    overInputText='AED'
                    isSubmitted={isSubmitted}
                    error={getErrorByName(schema, 'extraFeesChild').error}
                    type='number'
                    value={x.childFee || defultval}
                    onInputChanged={(event) => {
                      const floatHandler = () => {
                        const price = event.target.value.toString().split('.');
                        if (price.length === 2 && price[1].length > 3)
                          return Number(event.target.value).toFixed(3);
                        else return Number(event.target.value);
                      };
                      handleInputChange(floatHandler(), 'childFee', i);
                    }}
                  />
                </div>
                <div className='form-item'>
                  <Inputs
                    idRef='infantFee'
                    labelValue='Infants'
                    translationPath={translationPath}
                    isWithError
                    helperText={getErrorByName(schema, 'extraFeesChild').message}
                    inputPlaceholder='Infants'
                    overInputText='AED'
                    isSubmitted={isSubmitted}
                    error={getErrorByName(schema, 'extraFeesChild').error}
                    type='number'
                    value={x.infantFee || defultval}
                    onInputChanged={(event) => {
                      const floatHandler = () => {
                        const price = event.target.value.toString().split('.');
                        if (price.length === 2 && price[1].length > 3)
                          return Number(event.target.value).toFixed(3);
                        else return Number(event.target.value);
                      };
                      handleInputChange(floatHandler(), 'infantFee', i);
                    }}
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

ExtraFeesComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  onDiscountFeesChange: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
};
