import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Inputs } from '../../../../../../../Componentes';
import { TripsFeaturesEnum } from '../../../../../../../Enums';
import { getErrorByName, getTranslate, showError, showSuccess } from '../../../../../../../Helpers';
import { featureItemsPost, featureItemsPut } from '../../../../../../../Serviecs';
import Joi from 'joi';

const FeaturesManagementDialog = (props) => {
  const [featureName, setFeatureName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setIsSubmitted(false);
    if (props.isEdit && props.selectedFeature) {
      setFeatureName(props.selectedFeature.lookupItemName);
      setDescription(props.selectedFeature.description);
    } else {
      setFeatureName('');
      setDescription('');
    }
  }, [props.isEdit, props]);

  const schema = Joi.object({
    featureName: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${props.translationPath}feature-name-is-required`),
      }),
    description: Joi.string()
      .required()
      .messages({
        'string.empty': getTranslate()(`${props.translationPath}description-is-required`),
      }),
  })
    .options({
      abortEarly: true,
    })
    .validate({ featureName, description });

  const handelSaveButton = async () => {
    setIsSubmitted(true);
    if (schema.error) {
      showError(getTranslate()('shared.please-fix-all-errors'));
      return;
    }
    props.setOpen(false);
    props.setIsEdit(false);
    props.setLoading(true);

    if (props.isEdit && props.selectedFeature) {
      const result = await featureItemsPut(props.selectedFeature.lookupItemId, {
        lookupItemName: featureName,
        lookupItemCode: '',
        description: description,
        parentLookupItemId:
          props.selectedItem && props.selectedItem[0] && props.selectedItem[0].lookupItemId,
        lookupTypeId: TripsFeaturesEnum && TripsFeaturesEnum.FeatuerDetails.id,
        order: 0,
      });
      if (result) {
        showSuccess(getTranslate()(`${props.translationPath}feature-edited-successfully`));
      } else {
        showError(getTranslate()(`${props.translationPath}feature-edit-failed`));
      }
    } else if (!props.isEdit && !props.isType) {
      const result = await featureItemsPost({
        lookupItemName: featureName,
        description: description,
        lookupItemCode: '',
        parentLookupItemId:
          props.selectedItem && props.selectedItem[0] && props.selectedItem[0].lookupItemId,
        lookupTypeId: TripsFeaturesEnum && TripsFeaturesEnum.FeatuerDetails.id,
        order: 0,
      });
      if (result) {
        showSuccess(getTranslate()(`${props.translationPath}feature-created-successfully`));
      } else {
        showError(getTranslate()(`${props.translationPath}feature-created-failed`));
      }
    } else if (!props.isEdit && props.isType) {
      const result = await featureItemsPost({
        lookupItemName: featureName,
        description: description,
        lookupItemCode: '',
        lookupTypeId: TripsFeaturesEnum && TripsFeaturesEnum.FeatureType.id,
        order: 0,
      });
      if (result) {
        showSuccess(getTranslate()(`${props.translationPath}feature-created-successfully`));
      } else {
        showError(getTranslate()(`${props.translationPath}feature-created-failed`));
      }
    }
    props.reloadData();
    props.setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          props.setOpen(false);
          props.setIsEdit(false);
        }}>
        <DialogTitle>
          {props.isEdit
            ? getTranslate()(`${props.translationPath}edit-feature-item`)
            : props.isType
            ? getTranslate()(`${props.translationPath}add-new-feature-type`)
            : getTranslate()(`${props.translationPath}add-new-feature-item`)}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} className='mb-3'>
              <Inputs
                helperText={getErrorByName(schema, 'featureName').message}
                error={getErrorByName(schema, 'featureName').error}
                isWithError
                isSubmitted={isSubmitted}
                inputPlaceholder='feature-name'
                labelValue='feature-name'
                idRef='lookupItemNameRef'
                value={featureName}
                onInputChanged={(e) => setFeatureName(e.target.value)}
                translationPath={props.translationPath}
              />
            </Grid>
            <div className='w-100 mb-3'>
              <Inputs
                helperText={getErrorByName(schema, 'description').message}
                error={getErrorByName(schema, 'description').error}
                isWithError
                isSubmitted={isSubmitted}
                inputPlaceholder='description'
                labelValue='description'
                idRef='descriptionRef'
                value={description}
                onInputChanged={(e) => setDescription(e.target.value)}
                rows={4}
                multiline
                translationPath={props.translationPath}
              />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.setOpen(false);
              props.setIsEdit(false);
            }}
            className='btns theme-solid bg-cancel'>
            {getTranslate()('Lookups.CreateLookupItemDialog.Cancel')}
          </Button>
          <Button className='btns theme-solid' onClick={handelSaveButton}>
            {props.isEdit
              ? getTranslate()(`${props.translationPath}edit`)
              : getTranslate()(`${props.translationPath}save`)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { FeaturesManagementDialog };
