import { Grid } from '@material-ui/core'
import React,{useState,useEffect,useCallback} from 'react'
import './UserStatsComponent.scss'
import {GetTotalSales} from './../../../../../Serviecs'
import { Spinner } from './../../../../../Componentes/SpinnerComponent/Spinner';
import { getTranslate } from ' ./../../src/Helpers';
import {kFormatter} from './../../../../../Helpers/FormattingHelper'


export const  TotalSales=()=> {
    const translationPath = 'TotalSales.';
  const [isLoading, setIsLoading] = useState(false);
  const [state,setState] =useState();
  const [name, setName] = useState('');

  const GetSales = useCallback(async () => {
    setIsLoading(true);
    const Status = await GetTotalSales();
    if (Status) {
   setName( Status.salesPercentageVariation>0?'green':'red')
      setState(Status);
      setIsLoading(false);
    }
  }, []);
  
  useEffect(() => {
    GetSales();
  }, [GetSales]);

    return (
       
        <div className='Stats'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className='StatsTitleDiv'>
        <p className='titleParagraph'>{getTranslate()(`${translationPath}Total-Sales`)}</p>
        </div>

        <Grid container spacing={2}>

        <Grid xs={6}>
        <div className='statsDiv'>
        <p className='statsData'>
        <span> {state && kFormatter(state.totalSales) }</span> <span> {getTranslate()(`${translationPath}AED`)}</span>
       
        </p>
        <p className='statsPercentage'>
        <span> {state &&  kFormatter(state.salesPercentageVariation)+'%' }</span>  <span> {getTranslate()(`${translationPath}This Week`) } </span> 
       
        </p>

        </div>

        </Grid>

        <Grid xs={6}>
    
        <div className={`${name}stats`}>
        </div>

        </Grid>
        
        </Grid>
            
        </div>
    )
}
