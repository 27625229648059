import { LoggerStatus } from '../../States';

const logRequest = (payload) => {
  return { type: LoggerStatus.REQUEST, payload };
};

const logSuccess = (payload) => {
  return { type: LoggerStatus.SUCCESS, payload };
};

const logError = (payload) => {
  return { type: LoggerStatus.ERROR, payload };
};

const logRest = (payload) => {
  return { type: LoggerStatus.RESET, payload };
};

export const LoggerActions = {
  logRequest,
  logSuccess,
  logError,
  logRest,
};
