import { Avatar } from '@material-ui/core';
import React, { useState } from 'react';
import { DialogComponent, LoadableImageComponant, Spinner } from '../../../../Componentes';
import { getDownloadableLink } from '../../../../Helpers';
import './ShowAllDriversDialog.scss';
const translationPath = 'trackingView.';

export const ShowAllDriversDialog = (props) => {
  const [loading] = useState(false);

  return (
    <DialogComponent
      titleText='show-all-drivers'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='show-all-wrapper'>
          <Spinner isActive={loading} />
          <div className='driver-info-container'>
            {props.activeUserItem &&
              props.activeUserItem.map((item) => (
                <div className='driver-wrapper'>
                  {!item.driver.profileImg ? (
                    <Avatar
                      src={getDownloadableLink(item.driver.profileImg)}
                      alt={item.driver.fullName}
                    />
                  ) : (
                    <LoadableImageComponant
                      classes={'driver-image'}
                      alt={item.driver.fullName}
                      src={getDownloadableLink(item.driver.profileImg)}
                    />
                  )}
                  {item.driver.fullName}
                </div>
              ))}
          </div>
        </div>
      }
      isOpen={props.isOpen}
      onSaveClicked={props.isOpenChanged}
      onCloseClicked={props.isOpenChanged}
      translationPath={translationPath}
    />
  );
};
